import { Component, ElementRef, ViewChild, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import cv from '@techstark/opencv-js';
import { Mat, CascadeClassifier, Rect } from '@techstark/opencv-js';
import { forkJoin } from 'rxjs';

import { FaceRecognitionService } from '../service/face-recognition.service';
import { FaceRecognitionModel } from '../model/face-recognition.model';
import { MessageService } from 'primeng';
import {AuthService} from '../../shared/service/auth/auth.service';

@Component({
  selector: 'app-face-recognition',
  templateUrl: './face-recognition.component.html',
  styleUrls: ['./face-recognition.component.scss'],
})
export class FaceRecognitionComponent implements OnInit, OnChanges {

  @Input()
  mode: 'recognition' | 'detection' | 'snapshoot' = 'recognition';

  @Input()
  model: FaceRecognitionModel;

  @Output() snapshootEvent: EventEmitter<any> = new EventEmitter();

  @Output() recognitionEvent: EventEmitter<any> = new EventEmitter();

  @Output() detectionEvent: EventEmitter<any> = new EventEmitter();

  @Output() takePhotoEvent: EventEmitter<any> = new EventEmitter();

  @Output() resumeVideoEvent: EventEmitter<any> = new EventEmitter();

  msize = new cv.Size(0, 0);
  faceCascade!: CascadeClassifier;
  eyeCascade!: CascadeClassifier;

  modelLoaded = false;

  @ViewChild('video')
  videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('originCanvas')
  originCanvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvas')
  canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('faceCanvas')
  faceCanvasElement!: ElementRef<HTMLCanvasElement>;

  @Input()
  personName = '';

  @Input()
  isPunchInOut;

  @Input()
  takePhoto = false;

  @Input()
  pauseDetectFace = false;

  faceRecognized;
  paused = false;
  stopped = false;

  showFaceCanvas = true;
  detecting = false;
  canvasPhotoDetect;

  lastRecognizedTime = new Date();
  isFar;
  isNear;

  constructor(
    private faceRecognitionService: FaceRecognitionService,
    private authService: AuthService,
    private messageService: MessageService) {}

  ngOnInit(): void {
    forkJoin([this.loadHaarFaceModels(), this.loadHaarEyeModels()]).subscribe(
      () => {
        this.modelLoaded = true;
        if (this.mode === 'recognition' || this.mode === 'detection') {
          this.startVideo();
        }
      }
    );

    setInterval(() => {
      if (this.mode === 'detection' && this.lastRecognizedTime) {
        const now = new Date();
        const diff = now.getTime() - this.lastRecognizedTime.getTime();
        if (diff > 300000) {
          this.stopVideo();
        }
      }
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mode'] && changes['mode'].previousValue !== changes['mode'].currentValue) {
      if (this.mode === 'detection') {

      } else if (this.mode === 'recognition') {
        if (this.videoElement?.nativeElement?.srcObject) {
          this.resumeVideo();
        } else {
          this.startVideo();
        }

      } else if (this.mode === 'snapshoot') {
        if (!this.faceRecognized && changes['mode'].previousValue === 'recognition') {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: `Face cannot be detected, please use another photo`});
          this.resumeVideo();
          // this.mode = changes["mode"].previousValue;
          return;
        }
        const video = this.videoElement.nativeElement;
        const canvas = this.originCanvasElement.nativeElement;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((uint8Array) => {
          const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
          const data = {
            ...this.model,
            embedding: this.faceRecognized.embedding,
            photoBlob: blob
          };
          this.snapshootEvent.emit(data);
        }, 'image/png');
        this.pauseVideo();
      }
    }

    if (changes['pauseDetectFace'] !== undefined && changes['pauseDetectFace'].previousValue !== changes['pauseDetectFace'].currentValue) {
      console.log('personName', this.personName);
      requestAnimationFrame(() => this.detectFace());
    }
    if (changes['personName'] !== undefined && changes['personName'].previousValue !== changes['personName'].currentValue) {
      console.log('personName', this.personName);
      requestAnimationFrame(() => this.detectFace());
    }
  }

  ngOnDestroy() {
    this.stopVideo();
  }

  async startVideo(): Promise<void> {
    this.lastRecognizedTime = new Date();
    this.paused = false;
    this.stopped = false;
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    this.videoElement.nativeElement.srcObject = stream;
    this.videoElement.nativeElement.play();
    requestAnimationFrame(() => this.detectFace());
  }

  async stopVideo(): Promise<void> {
    this.pauseVideo();
    const stream = this.videoElement.nativeElement.srcObject as MediaStream;
    if (stream) {
      this.stopped = true;
      console.log('Stopped');
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      this.videoElement.nativeElement.srcObject = null;
    }
  }

  onResumeVideo() {
    this.resumeVideoEvent.emit('resume');
    this.resumeVideo();
  }
  resumeVideo(): void {
    if (!this.videoElement.nativeElement.srcObject) {
      return;
    }
    this.videoElement.nativeElement.play();
    this.stopped = false;
    this.paused = false;
    this.faceRecognized = null;
    requestAnimationFrame(() => this.detectFace());
  }

  requestAnimationFrame() {
    requestAnimationFrame(() => this.detectFace());
  }

  pauseVideo(): void {
    this.videoElement.nativeElement.pause();
    this.paused = true;
  }

  async detectFace(): Promise<void> {
    if (!this.modelLoaded || this.paused || !this.model) {
      console.log('model not loaded or paused or model not set');
      return;
    }
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    const context: CanvasRenderingContext2D = canvas.getContext('2d', { willReadFrequently: true });
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    this.canvasPhotoDetect = canvas;
    if (canvas.width === 0 || canvas.height === 0) {
      requestAnimationFrame(() => this.detectFace());
      return;
    }
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    try {
      const src = cv.imread(canvas);
      const canvasResult = this.detectHaarFace(src, canvas, context);
      cv.imshow(canvas, src);
      src.delete();
      if (canvasResult) {
        this.lastRecognizedTime = new Date();
        this.showFaceCanvas = true;
        canvasResult.toBlob((blob) => {
          if (this.mode === 'recognition') {
            const checkValidate = false;
            // const checkValidate = this.checkValidateRecognize(blob);
            if (!checkValidate) {
              this.recognize(blob);
            } else {
              this.messageService.clear();
              this.messageService.add({severity: 'error', summary: 'Error', detail: `Face already exists in another account`});
            }
          } else if (this.mode === 'detection') {
            this.detect(blob, canvas);
          }
        }, 'image/png');
      } else {
        this.faceRecognized = null;
        if (this.mode === 'detection') {
          this.showFaceCanvas = false;
        }
        requestAnimationFrame(() => this.detectFace());
      }
    } catch (error) {
      console.log(error);
      this.canvasPhotoDetect = null;
      requestAnimationFrame(() => this.detectFace());
    }
  }

  detectHaarFace(img: Mat, canvas, context: CanvasRenderingContext2D) {
    const gray = new cv.Mat();
    cv.cvtColor(img, gray, cv.COLOR_RGBA2GRAY, 0);
    const faces = new cv.RectVector();
    const minSize = new cv.Size(50, 50); // minimum size of face
    const maxSize = new cv.Size(800, 800);
    // detect faces
    this.faceCascade.detectMultiScale(gray, faces, 1.1, 3, 0, minSize, maxSize);
    let canvasResult;
    for (let i = 0; i < faces.size(); ++i) {
      const face = faces.get(i);
      const roi = gray.roi(face);
      const eyes = new cv.RectVector();
      this.eyeCascade.detectMultiScale(roi, eyes);
      if (eyes.size() >= 2) {
        canvasResult = canvas;
        const point1 = new cv.Point(face.x, face.y);
        const point2 = new cv.Point(face.x + face.width, face.y + face.height);
        const point3 = new cv.Point(face.x, face.y - 10);
        cv.rectangle(img, point1, point2, [0, 0, 0, 255], 4);
        if (this.mode === 'detection' && this.personName) {
          console.log('Recognized User: ', this.personName);
          const textWidth = context.measureText(this.personName).width * 2.85;
          const textHeight = 25;

          const textOrigin = new cv.Point(point3.x, point3.y - textHeight + 5);
          const rectTopLeft = new cv.Point(textOrigin.x, textOrigin.y);
          const rectBottomRight = new cv.Point(textOrigin.x + textWidth, textOrigin.y + textHeight);
          cv.rectangle(img, rectTopLeft, rectBottomRight, [255, 255, 255, 255], -1);
          cv.putText(img, this.personName, point3, 2, 0.8, [0, 0, 0, 255], 2);
        }
        if (this.mode === 'recognition' && (this.isFar || this.isNear)) {
          const text = this.isFar ? 'Face is too far' : 'Face is too near';
          const textWidth = context.measureText(text).width * 3;
          const textHeight = 25;

          const textOrigin = new cv.Point(point3.x, point3.y - textHeight + 5);
          const rectTopLeft = new cv.Point(textOrigin.x, textOrigin.y);
          const rectBottomRight = new cv.Point(textOrigin.x + textWidth, textOrigin.y + textHeight);
          cv.rectangle(img, rectTopLeft, rectBottomRight, [255, 255, 255, 255], -1);
          cv.putText(img, text, point3, 2, 0.8, [0, 0, 0, 255], 2);
        }
        const ctx = canvas.getContext('2d');
        this.drawFaceCanvasFace(face, ctx);
        if (this.mode === 'detection') {
          const extendPixel = 40;
          const imagePortion = ctx.getImageData(face.x - extendPixel, face.y - extendPixel, face.width + 2 * extendPixel, face.height + 2 * extendPixel);
          const canvasFace = document.createElement('canvas');
          canvasFace.width = face.width + 2 * extendPixel;
          canvasFace.height = face.height + 2 * extendPixel;
          const ctx2 = canvasFace.getContext('2d');
          ctx2.putImageData(imagePortion, 0, 0);
          canvasResult = canvasFace;
        }
      }
      eyes.delete();
      roi.delete();
    }
    gray.delete();
    faces.delete();
    if (this.pauseDetectFace) {
      this.pauseVideo();
    }
    return canvasResult;
  }

  recognize(uint8Array) {
    const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
    this.faceRecognitionService.recognize(blob).subscribe(
      (response) => {
        console.log('response recognize', response);
        if (response && response.embedding) {
          if (response.isFar || response.isNear) {
            this.isFar = response.isFar;
            this.isNear = response.isNear;
            this.faceRecognized = null;
          } else {
            this.isFar = false;
            this.isNear = false;
            this.faceRecognized = response;
          }
        } else {
          this.isFar = false;
          this.isNear = false;
          this.faceRecognized = null;
        }
        this.recognitionEvent.emit(this.faceRecognized);
        requestAnimationFrame(() => this.detectFace());
      },
      (error) => {
        console.log(error);
        this.isFar = false;
        this.isNear = false;
        this.faceRecognized = null;
        this.recognitionEvent.emit(this.faceRecognized);
        requestAnimationFrame(() => this.detectFace());
      }
    );
  }

  detect(uint8Array, canvas) {
    if (this.detecting) {
      return;
    }
    this.detecting = true;
    const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
    this.faceRecognitionService.detect(blob).subscribe(
      (response) => {
        this.detecting = false;
        console.log('response detect', response);
        if (response && response.length > 0) {
          if (this.model.adminId) {
            if (response[0].fake_face) {
              this.detectionEvent.emit({
                fakeFace: true
              });
              requestAnimationFrame(() => this.detectFace());
            } else {
              const face = response.find(r => {
                if (r.human_id && Number(r.human_id.split('_')[0]) === this.model.adminId) {
                  return true;
                }
                return false;
              });
              if (face) {
                // this.personName = face.human_name + ', ' + face.percent_match.toFixed(2) + '%';
                this.detectionEvent.emit({
                  face: face,
                  canvas: canvas
                });
              } else {
                // this.personName = '';
                this.detectionEvent.emit(null);
                requestAnimationFrame(() => this.detectFace());
              }
            }
          } else {
            if (response[0].fake_face) {
              this.detectionEvent.emit({
                fakeFace: true
              });
              requestAnimationFrame(() => this.detectFace());
            } else {
              this.detectionEvent.emit({
                faces: response,
                canvas: canvas
              });
            }
          }
        } else {
          // this.personName = '';
          this.detectionEvent.emit(null);
          requestAnimationFrame(() => this.detectFace());
        }

      },
      (error) => {
        console.log(error);
        this.detecting = false;
        requestAnimationFrame(() => this.detectFace());
      }
    );
  }

  async loadHaarFaceModels() {
    return loadDataFile(
      'haarcascade_frontalface_default.xml',
      'assets/models/haarcascade_frontalface_default.xml'
    )
      .then(
        () =>
          new Promise<void>((resolve) => {
            setTimeout(() => {
              // load pre-trained classifiers
              this.faceCascade = new cv.CascadeClassifier();
              this.faceCascade.load('haarcascade_frontalface_default.xml');
              resolve();
            }, 2000);
          })
      )
      .then(() => {
        console.log('=======downloaded Haar-cascade models=======');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async loadHaarEyeModels() {
    return loadDataFile(
      'haarcascade_eye.xml',
      'assets/models/haarcascade_eye.xml'
    )
      .then(
        () =>
          new Promise<void>((resolve) => {
            setTimeout(() => {
              // load pre-trained classifiers
              this.eyeCascade = new cv.CascadeClassifier();
              this.eyeCascade.load('haarcascade_eye.xml');
              resolve();
            }, 2000);
          })
      )
      .then(() => {
        console.log('=======downloaded Haar-cascade models=======');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  drawFaceCanvasFace(face: Rect, ctx) {
    const imagePortion = ctx.getImageData(
      face.x,
      face.y,
      face.width,
      face.height
    );
    // Convert canvas to Blob object
    const canvasFace = this.faceCanvasElement.nativeElement;
    canvasFace.width = face.width;
    canvasFace.height = face.height;
    const ctx2 = canvasFace.getContext('2d');
    ctx2.putImageData(imagePortion, 0, 0);
  }

  checkValidateRecognize(uint8Array) {
    const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
    this.faceRecognitionService.detect(blob).subscribe(
        (response) => {
          if (response && response.length > 0) {
            if (this.authService.getCurrentLoggedInId()) {
              const faceCurrentLoggedIn = response.find(r => r.human_id && Number(r.human_id.split('_')[0]) === this.authService.getCurrentLoggedInId());
              if (faceCurrentLoggedIn) {
                return false;
              } else {
                const face = response.find(r => r.percent_match >= 80);
                return face ?? false;
              }
            }
          } else {
            return false;
          }
        },
        (error) => {
          console.log(error);
        }
    );
    return false;
  }

  getDetectionName() {
    if (!this.personName) {
      return '';
    }
    return this.personName.split(',')[0];
  }

  getMatchingRate() {
    if (!this.personName) {
      return '';
    }
    return this.personName.split(',')[1];
  }

  onTakePhoto() {
    this.pauseVideo();
    if (this.canvasPhotoDetect) {
      this.canvasPhotoDetect.toBlob((uint8Array) => this.takePhotoEvent.emit(uint8Array), 'image/jpeg');
    }

  }
}

export async function loadDataFile(cvFilePath: string, url: string) {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const data = new Uint8Array(buffer);
  try {
    cv.FS_createDataFile('/', cvFilePath, data, true, false, false);
  } catch (error) {}
}
