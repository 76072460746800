import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Company } from '../model/company.model';
import { Page } from '../../shared/model/page.model';
import { environment } from '../../../environments/environment';
import { CompanySetting } from '../model/company-setting.model';
import { Contract } from '../model/contract.model';
import { ServiceType } from '../model/service-type.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CompanyService extends AbstractService<Company> {

    // private docApiUrl = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.COMPANY, '');
    }

    getCompaniesListByPage(params: any): Observable<Page<Company>> {
        return this._http.get<Page<Company>>(this.baseUrlV2 + 'search', { params: params }).pipe(map(resp => resp));
    }
    getCompanyByCompanyId(id: any): Observable<Page<Company>> {
        return this._http.get<Page<Company>>(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
    }

    getCompaniesDropdown(params: any): Observable<any> {
        return this._http.get<any>(this.baseUrlV2 + Constants.URI.COMPANY.DROPDOWNLIST, { params: params }).pipe(map(resp => resp));
    }
    getCompaniesHasAgency(params: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}all`, { params: params }).pipe(map(resp => resp));
    }
    getCompanySetting(id: Number): Observable<CompanySetting> {
        return this._http.get<CompanySetting>(`${this.baseUrlV2}${Constants.URI.COMPANY.COMPANY_OTHER}/${id}`).pipe(map(resp => resp));
    }

    saveCompanySetting(settingData: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}${Constants.URI.COMPANY.COMPANY_OTHER}`, settingData).pipe(map(resp => resp));
    }

    updateCompanySetting(settingData: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}${Constants.URI.COMPANY.COMPANY_OTHER}`, settingData).pipe(map(resp => resp));
    }

    getAdminType(adminType: any): Observable<any> {
        const url = `${environment.v2_server_ip}/${Constants.URI.ADMIN.GROUP}/searchAdminType`;
        return this._http.get<any>(url, { params: {adminType} }).pipe(map(resp => resp));
    }

    companyContract(companyId:Number){
        return this._http.get<Contract>(`${environment.v2_server_backend}/${Constants.ROUTES.COMPANY}/${companyId}/${Constants.URI.COMPANY.COMPANYCONTRACT}`).pipe(map(resp => resp));
    }

    saveCompanyContract(contractData: any, companyId:Number): Observable<any> {
        return this._http.post<any>(`${environment.v2_server_backend}/${Constants.ROUTES.COMPANY}/${companyId}/${Constants.URI.COMPANY.COMPANYCONTRACT}`, contractData).pipe(map(resp => resp));
    }

    getCompanyContract(id: Number): Observable<Contract> {
        return this._http.get<Contract>(`${this.baseUrlV2}${Constants.URI.COMPANY.COMPANYCONTRACT}/${id}`).pipe(map(resp => resp));
    }

    updateCompanyContract(contractData: any, companyId:Number): Observable<any> {
        contractData.licenseeSignature = contractData.licenseeSignature.id;
        contractData.licensorSignature = contractData.licensorSignature.id;
        return this._http.put<any>(`${environment.v2_server_backend}/${Constants.ROUTES.COMPANY}/${companyId}/${Constants.URI.COMPANY.COMPANYCONTRACT}`, contractData).pipe(map(resp => resp));
    }

    generateCompanyContract(contractData): Observable<any> {
        return this._http.post<any>(`${environment.server_ip}/${Constants.URI.CONTRACT.PDFGENEREATE}/${Constants.URI.CONTRACT.COMPANYCONTRACT}`, contractData).pipe(map(resp => resp));
    }

    generateEmployeePDF(contractData): Observable<any> {
        return this._http.post<any>(`${environment.server_ip}/${Constants.URI.CONTRACT.PDFGENEREATE}/${Constants.URI.ABSENCE.ABSENTIES}`, contractData).pipe(map(resp => resp));
    }
    all(): Observable<Company[]> {
        return this._http.get<Company[]>(`${this.baseUrlV2}all`);
    }
    searchChanges(params: any): Observable<Page<any>> {
        return this._http.get<Page<any>>(`${this.baseUrlV2}history`, { params: params }).pipe(map(resp => resp));
    }

    getAllServiceType(): Observable<any> {
        return this._http.get<any>(`${environment.server_ip}/service-type`).pipe(map(resp => resp));
    }

    getServiceTypeByIds(ids): Observable<any> {
        return this._http.post<any>(`${environment.server_ip}/service-type/ids`, ids);
    }

    getCompanyByAgency(agencyId: Number): Observable<Company> {
        return this._http.get<Company>(`${this.baseUrlV2}` + 'agencyId/' + `${agencyId}`).pipe(map(resp => resp));
    }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }

    createHoliDay(holiday: any) {
        return this._http.post<Company>(`${this.baseUrlV2}` + `${holiday.companyId}` + '/holiday', holiday).pipe(map(resp => resp));
    }

    getOnlineUsers(companyId) {
        const params: any = {};
        params['companyId'] = companyId;
        return this._http.get<Company>(`${this.baseUrlV2}user-online/online`, {params: params}).pipe(map(resp => resp));
    }

    companyTrackChanges(options: any) {
        // return this._http.post<Company>(`http://localhost:8003/api/v2/company/history/search`, options ).pipe(map(resp => resp));
        return this._http.post<Company>(`${this.baseUrlV2}history/search`, options ).pipe(map(resp => resp));
    }

    getHoliDay(companyId: any) {
        return this._http.get<Company>(`${this.baseUrlV2}` + `${companyId}` + '/holiday').pipe(map(resp => resp));
    }

    saveUsersOnline(options: any) {
        return this._http.post<Company>(`${this.baseUrlV2}user-online`, options).pipe(map(resp => resp));
    }

    getListPhoneConfigurationHis(companyId: any) {
        // return this.http.get<any>(`http://localhost:8003/api/v2/company/history/get-phone-config/` + `${companyId}`).pipe(map(resp => resp));
        return this._http.get<any>(`${this.baseUrlV2}history/get-phone-config/` + `${companyId}`).pipe(map(resp => resp));
    }

}
