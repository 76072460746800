<p-toast position="bottom-right">{{msgs}}</p-toast>

    <h1 class="m-0 ml-2 my-4">{{'Time History' | translate}}</h1>
    <p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span ><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Time History' | translate}}</span>
		</p-header>
        <div class="ui-g">
            <div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
                <label>{{'company.name' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>	
                	<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [showClear]="true" [options]="allCompanyList" optionLabel="value" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" [disabled]="companyDisabled">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
            </div>
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Date Range' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-calendar-week"></i>
                        </button>
                    </div>
                    <mat-form-field class="w-100">
                        <mat-date-range-input [rangePicker]="picker">
                            <input (keypress)="isDate($event)" dateFormat="MM/dd/yyyy" matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()" style="padding-left: 10px;">
                            <input (keypress)="isDate($event)"dateFormat="MM/dd/yyyy" matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
                        </mat-date-range-input>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Type' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [filter]="true" [showClear]="true" [options]="typeList" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="selectedType">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
					</p-dropdown>
				</div>
            </div>
            <div class="ui-g-12 py-4 text-center">
				<button class="btn btn-danger mx-1" (click)="resetEmployeeSearchForm()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
                <button class="btn btn-primary mx-1" (click)="loadTimeHistoryLazy()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
            </div>
        </div>
    </p-panel>
	<h1 class="m-0 ml-2 my-4">{{'Browse History' | translate}}</h1>
    <p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Employee Time History' | translate}} ({{totalRecords}})</span>
		</p-header>
        <p-toast position="bottom-right">{{msgs}}</p-toast>
        <p-contextMenu #cm [model]="items"></p-contextMenu>
		<p-table #dt [value]="listData" id="employee-table" selectionMode="single" dataKey="id" [lazy]="false" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [(contextMenuSelection)]="selectedEmployee" [contextMenu]="cm" [responsive]="false" [columns]="cols">
			<ng-template pTemplate="caption">
				<div class="d-flex flex-wrap align-items-center justify-content-end">
					<div class="input-group flex-grow-1 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" pInputText placeholder="{{'Filter by Keyword' | translate}}" [(ngModel)]="textSearch" (keyup)="filterEmployees()">
					</div>
				</div>
			</ng-template>
               
                <ng-template pTemplate="header" let-columns>
                    <tr>
						<th>#</th>
                        <th *ngFor="let col of columns" [ngStyle]="{'min-width':col.width > 0 ? col.width + 'px' : '' }">
                            <ng-container [ngSwitch]="col.field">
                                <span *ngSwitchDefault>
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
										<span *ngIf="col.sort">
											<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
												<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
											</span>
										</span>
									</div>
                                </span>
								<span *ngSwitchCase="'payrollWeek'">
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
										<span *ngIf="col.sort">
											<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
												<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
											</span>
										</span>
									</div>
                                </span>
                            </ng-container>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                    <tr [pSelectableRow]="rowData" id="id_employee_list_table_row" [ngClass]="rowData.selected ? 'column-highlighted' : ''">
						<td>{{index + 1}}</td>
                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Name' | translate}}: </span>
								<span *ngIf="rowData.employeeName" pTooltip="{{rowData.employeeName}}">{{rowData.employeeName}}</span>
								<span *ngIf="!rowData.employeeName" class="text-muted">{{'no data' | translate}}</span>
							</span>
                        </td>
                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Week' | translate}}: </span>
								<span *ngIf="rowData.payrollWeek" pTooltip="{{rowData.payrollWeek}}">{{rowData.payrollWeek}}</span>
								<span *ngIf="!rowData.payrollWeek" class="text-muted">{{'no data' | translate}}</span>
							</span>
                        </td>
                        <td [ngSwitch]="rowData.typeEmployee">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Type' | translate}}: </span>
								<span *ngSwitchCase="'full_time'">
									{{'Full Time' | translate}}
								</span>
								<span *ngSwitchCase="'part_time'">
									{{'Part Time' | translate}}
								</span>
                                <span *ngSwitchCase="'intern_paid'">
									{{'intern_paid' | translate}}
								</span>
                                <span *ngSwitchCase="'intern_unpaid'">
									{{'intern_unpaid' | translate}}
								</span>
                                <span *ngSwitchCase="'volunteer'">
									{{'Volunteer' | translate}}
								</span>
								<span *ngSwitchDefault>
									<span *ngIf="rowData.typeEmployee" pTooltip="{{rowData.typeEmployee}}">{{rowData.typeEmployee}}</span>
									<span *ngIf="!rowData.typeEmployee" class="text-muted">{{'no data' | translate}}</span>
								</span>
							</span>
                        </td>
                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Hrs.Worked' | translate}}: </span>
								<span *ngIf="rowData.totalWorkTime" pTooltip="{{hhmm1(rowData.totalWorkTime)}}">{{hhmm1(rowData.totalWorkTime)}}</span>
								<span *ngIf="!rowData.totalWorkTime" class="text-muted">00:00</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Latenesses' | translate}}: </span>
								<span *ngIf="rowData.totalLateTime" pTooltip="{{hhmm1(rowData.totalLateTime)}}">{{hhmm1(rowData.totalLateTime)}}</span>
								<span *ngIf="!rowData.totalLateTime" class="text-muted">00:00</span>
							</span>
						</td>
                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Overtime' | translate}}: </span>
								<span *ngIf="rowData.overTime && rowData.salaryType == 'salary'" pTooltip="{{hhmm1(rowData.overTime)}}" class="text-success">{{hhmm1(rowData.overTime)}}</span>
								<span *ngIf="rowData.overTime && rowData.salaryType != 'salary'" pTooltip="{{hhmm1(rowData.overTime)}}" class="text-danger">{{hhmm1(rowData.overTime)}}</span>
								<span *ngIf="!rowData.overTime && rowData.salaryType == 'salary'" class="text-success" style="opacity: 0.6">00:00</span>
								<span *ngIf="!rowData.overTime && rowData.salaryType != 'salary'" class="text-danger" style="opacity: 0.6">00:00</span>
							</span>
                        </td>
                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Balance' | translate}}: </span>
								<span *ngIf="rowData.balanceHour" pTooltip="{{hhmm1(rowData.balanceHour)}}">{{hhmm1(rowData.balanceHour)}}</span>
								<span *ngIf="!rowData.balanceHour" class="text-muted">00:00</span>
							</span>
						</td>
                    </tr>
                </ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="!loading">
						<td [attr.colspan]="8" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="loading">
						<td [attr.colspan]="8" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>
            </p-table>

    </p-panel>

	<p-overlayPanel #op1 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
		<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op2 appendTo="body">
		<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op3 appendTo="body">
		<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op4 appendTo="body">
		<p-listbox [options]="statusSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>

