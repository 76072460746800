<div *ngIf="stopped">Click below to start</div>
<div class="p-3" [ngClass]="isPunchInOut ? 'card add-shadow' : ''" >
	<ng-container *ngIf="stopped">
		<button class="btn btn-lg btn-white w-100" (click)="startVideo()"><i class="fa-solid fa-solid fa-camera-rotate" style="font-size: 56px; padding: 42px"></i></button>
	</ng-container>
	<ng-container *ngIf="!stopped">
		<ng-container *ngIf="paused">
			<i class="fa-solid fa-refresh cursor-pointer text-primary" style="font-size: 56px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" (click)="onResumeVideo();"></i>
		</ng-container>
		<canvas class="origin-windows" #originCanvas></canvas>
		<canvas class="recognition-windows" #canvas></canvas>
		<video #video autoplay></video>
		<canvas [ngClass]="isPunchInOut ? 'face-windows' : 'face-windows-2'" #faceCanvas [ngStyle]="{'display': showFaceCanvas ? 'block' : 'none'}"></canvas>
	</ng-container>

	<div *ngIf="!modelLoaded" class="d-flex justify-content-center align-items-center w-100">
		<span class="text-muted m-auto"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...'}}</span>
	</div>
	<div *ngIf="personName" class="text-left text-dark" [ngClass]="takePhoto ? 'py-4' : 'pt-2'"  style="font-size: 16px">
		<h5 class="m-0 text-truncate"><b>{{getDetectionName()}}</b></h5>
		<div><b>{{'Certainty'}}: </b>{{getMatchingRate()}}</div>
		<div class="progress" style="height: 10px">
			<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style]="{'width': ((getMatchingRate() || '0%'))}" aria-valuenow="{{getMatchingRate() || '0'}}" aria-valuemin="0" aria-valuemax="100"></div>
		</div>
	</div>
	<div class="ui-g-12 px-0" *ngIf="modelLoaded && takePhoto && !stopped">
		<button class="btn btn-lg w-100" [ngClass]="isPunchInOut ? 'btn-primary' : 'btn-white'" (click)="onTakePhoto()" [disabled]="!canvasPhotoDetect"><i class="fa-solid fa-camera mr-2" [ngClass]="isPunchInOut ? 'text-warning' : 'text-primary'"></i>Capture</button>
	</div>
</div>