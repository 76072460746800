import { Injectable } from '@angular/core';
import { AbstractService } from '../../../shared/service/abstract.service';
import { CompanyAdmin } from '../model/company-admin.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { Page } from '../../../shared/model/page.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompanyAdminService extends AbstractService<CompanyAdmin>{

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.ADMIN, '');
    }

    getCompanyAdminsByPage(params: any): Observable<Page<CompanyAdmin>> {
        return this._http.get<Page<CompanyAdmin>>(this.baseUrl + Constants.URI.ADMIN.GETALLCOMPANYADMIN, { params: params }).pipe(map(resp => resp));
    }

    getCompanyAdminsByCompanyId(params: any): Observable<Page<CompanyAdmin>> {
        // company/company_admin
        return this._http.get<Page<CompanyAdmin>>(this.baseUrlV2 + Constants.URI.ADMIN.GETALLCOMPANYADMIN, { params: params }).pipe(map(resp => resp));
    }


    adminAngency(companyId ?: any) {
        let params = {};
        if(companyId) {
            params= { companyId: companyId }
        }
        return this._http.get<any>(`${this.baseUrlV2}` + `agency_admin`, {params: params}).pipe(map(resp => resp));
    }


    updateAdmin(entity: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}${entity.id}`, entity).pipe(map(res => res));

        // return this._http.put<any>(`http://localhost:8003/api/v2/admin/${entity.id}`, entity).pipe(map(res => res));

    }

    updateAdminPunch(entity: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}/updateAdmin/${entity.id}`, entity).pipe(map(res => res));
        // return this._http.put<any>(`http://localhost:8003/api/v2/admin/updateAdmin/${entity.id}`, entity).pipe(map(res => res));
    }


    getPunchInOutAdmin(companyId: any): Observable<any> {
        // return this._http.get<any>(`http://localhost:8003/api/v2/admin/punchinout-admin/${companyId}`).pipe(map(res => res));

        return this._http.get<any>(`${this.baseUrlV2}punchinout-admin/${companyId}`).map(resp => resp);
    }

    changePassword(entity: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}${Constants.URI.ADMIN.CHANGE_PASSWORD}`, entity).pipe(map(res => res));
    }

    validateCurrentPassword(entity: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'validate-password', entity).pipe(map(res => res));
    }

    getAdminDropDown(companyId?: any) {
        let params
        if(companyId) {
            params= { companyId: companyId }
        }
        return this._http.get<Page<CompanyAdmin>>(this.baseUrlV2 + Constants.URI.ADMIN.DROPDOWNLIST, { params: params }).pipe(map(resp => resp));
    }

    getAllAdminList(options?:any){
        const params = this.createParams(options);
        return this._http.get<any>(`${this.baseUrlV2}user_detail_list`,{params:params}).pipe(map(resp => resp));
    }

    findAdmin(id: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}${id}`).pipe(map(res => res));
    }

    changeFaceDetectionSetting(id, value) {
        return this._http.get<any>(`${this.baseUrlV2}${id}/face-detection/${value}`).map(resp => resp);
        // return this._http.get<any>(`http://localhost:8003/api/v2/admin/${id}/face-detection/${value}`).pipe(map(res => res));
    }

    isAccountNameAvail(accountName: string) {
        return this._http.get<any>(`${this.baseUrlV2}isAccountNameAvail`, {params: {accountName: accountName}}).map(resp => resp);
    }

    getByUsername(userName): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}find-username/${userName}`).map(resp => resp);
    }
}
