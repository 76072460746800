import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreadcrumbService} from '../../../layout/service/breadcrumb.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import {OverlayPanel, Table} from 'primeng';
import {CompanyOtherSettingHistoryService} from '../../service/company-other-setting-history.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DocumentSettingsService} from "../../service/document-settings.service";
import {DocumentsService} from "../../../shared/service/documents-upload/documents.service";
import {DocumentsServicev2} from "../../../shared/service/documents-upload/document.v2.service";


@Component({
    selector: 'app-document-setting-history',
    templateUrl: './document-setting-history.component.html',
    styleUrls: ['./document-setting-history.component.scss']
})
export class DocumentSettingHistoryComponent implements OnInit {
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    size: number;
    page: number;
    clientHistory: any[];
    totalRecords: any;
    rows: number;
    fromDate;
    toDate;
    cols: any[] = [
        {field: 'updateDate', label: 'Updated', sort: 'number'},
        {field: 'userName', label: 'User', sort: 'text'},
        {field: 'oldValue', label: 'Old Value', sort: 'text'},
        {field: 'newValue', label: 'New Value', sort: 'text'},
        {field: 'action', label: 'Actions', sort: 'text'}
    ];
    first = 0;
    rangeDates;
    maxDate: any;
    fieldNames: any[];
    selectedColField = null;
    @Input() settingId: any;
    @Input() historyType: string;
    selectedSortOption: any;
    selectedField: any;
    sortField: any;
    overlayPanel: OverlayPanel;
    loading = false;
 
    constructor(
        private breadcrumbService: BreadcrumbService,
        private historyService: DocumentSettingsService,
        private documentsService: DocumentsService,
        private documentServicev2: DocumentsServicev2,
        private route: ActivatedRoute
    ) {
        this.maxDate = new Date();
    }

    ngOnInit() {
        this.fieldNames = [
            { label: 'Document Name', value: 'documentName' },
            { label: 'Role', value: 'role' },
            { label: 'Required', value: 'isRequired' },
            { label: 'Visible', value: 'isVisible' },
            { label: 'Add Expiration Date', value: 'addExpDate' },
            { label: 'Business Type', value: 'operationalPolicy' },
            { label: 'Information', value: 'information' },
            { label: 'Material', value: 'documentIds' }
        ];
    }

    loadDataSource(event?: any) {
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    }

    filterClientHistory() {
        this.clientHistory = [];
        if (!this.selectedColField) return;
        const options: any = {};
        if (this.selectedColField) {
            options.fieldName = this.selectedColField;
        }
        options.fromDate = this.fromDate ? moment(this.fromDate).startOf('day').utc(true).toDate().getTime() : null;
        options.toDate = this.toDate && this.toDate ? moment(this.toDate).endOf('day').utc(true).toDate().getTime() : null;
        options.documentSettingId = this.settingId;
        this.loading = true;
        this.historyService.getHistory(options).subscribe(
            (data: any) => {
                this.clientHistory = data.data.content;
                this.totalRecords = data.data.content.length;
                this.dataSourceSubject.next(data.data);
                this.loading = false;
            },
            (error: any) => {
                console.error(error);
                this.loading = false;
            }
        );

    }
    reset() {
        this.rangeDates = [];
        this.selectedColField = null;
        this.fromDate = null;
        this.toDate = null;
        this.clientHistory = [];
        this.dataSourceSubject.next([]);
    }

    files = [];
    documentOverview;
    onViewDocuments(data) {
      let ids = data.split(',').map(Number);
      const options = {
        ids: ids
      }
      this.getAllFiles(options);
      this.documentOverview = true;
    }

    getAllFiles(options: any) {
      this.documentServicev2.searchFile(options)
      .subscribe(res => {
          const resObj: any = res;
          this.files = resObj.data.content;
      });
    }

    fileUrl;
    showViewFileDialog;
    onViewDocument(documentId) {
        this.documentsService.getUploadedFile(documentId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data.fileType === 'pdf'
                    || resObj.data.fileType === 'txt'
                    || resObj.data.fileType === 'docx'
                    || resObj.data.fileType === 'doc'
                    || resObj.data.fileType === 'xls'
                    || resObj.data.fileType === 'xlsx'
                ) {
                    window.open(resObj.data.fileUrl);
                } else {
                    this.fileUrl = resObj.data.fileUrl;
                    this.showViewFileDialog = true;
                }
            }
        }, error => {
            // error
        });
    }

    getFileName(url) {
        return url.split('__').pop();
    }

    clearHtmlTag(data) {
        const dom = new DOMParser().parseFromString(
            '<!doctype html><body>' + data,
            'text/html');
        const decodedString = dom.body.textContent;
        return decodedString;
    }
}
