import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformAdminService } from 'app/admin/platform-admin/service/platform-admin.service';
import { CompanyService } from 'app/company/service/company.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { MessageService, OverlayPanel } from 'primeng';
import { JobFollowUpService } from '../../service/job-follow-up.service';
import * as _moment from 'moment';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import moment from 'moment';
import { FollowUpLabelService } from '../../service/follow-up-label.service';
import {TranslatePipe} from '@ngx-translate/core';
import {Table} from "primeng/table";
import { DatePipe } from '@angular/common';
import { FileUtility } from 'app/shared/utility/file.utility';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';


@Component({
  selector: 'app-job-follow-up-list',
  templateUrl: './job-follow-up-list.component.html',
  styleUrls: ['./job-follow-up-list.component.scss']
})
export class JobFollowUpListComponent implements OnInit {
  @ViewChild('dt', {static: true}) table: Table;
  isPlatformAdmin: Boolean = false;
  isCompanyAdmin: Boolean = false;
  allCompanyList: any;
  selectedCompany: any;
  totalJobList: any[];
  loading: Boolean = false;
  allCreatedByList: any[];
  size: number;
  page: number;
  totalRecords: number;
  fromDate: Date;
  toDate: Date;
  selectedCreatedBy: any;
  followUpSelect: any;
  followUp: any;
  startDate: Date;
  endDate: Date;
  textSearch;
  cols: any[] = [
    { label: '#', field: '#'},
    { label: 'Created', field: 'createdAt', sortOptions: '', sort: 'number'},
    { label: 'Creator', field: 'createdByUsr', sort: 'text' },
    { label: 'Bases', field: 'totalJobDetails',  sort: 'number' },
    { label: 'Period', field: 'fromDate', sort: 'number' },
    { label: 'Cycle', field: 'cycle', sort: 'text' },
    { label: 'Updated', field: 'updatedAt', sort: 'number' },
    { label: 'Updated By', field: 'lastModifiedBy', sort: 'text' },
    { label: 'Action', field: 'action' }
  ];

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  numbersSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  selectedField: string;
  selectedSortOption: any;
  showConfirmDialog: boolean;
  jobDetailsId: number;
  selectedValue: any;
  @ViewChild('op', { static: true }) duplicate: OverlayPanel;
  showDuplicateModal: Boolean = false;
  selectedIds = [];
  timeSpent =  new Date();
  isDeleteAble = false;
  isEditAble = false;
  allLabel: any;
  statusLabel: any;
  cycle = [
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Bi-Weekly', value: 'Bi-Weekly' },
    { label: 'Monthly', value: 'Monthly' }
  ];
  selectedCycle: any;
  checkDuplicate = false;

  constructor(private companyService: CompanyService, private authService: AuthService,
    private platformAdminService: PlatformAdminService, private jobFollowUpService: JobFollowUpService,
    private messageService: MessageService, private router: Router,
    private monitoringDetailsService: MonitoringDetailsService,
    private translatePipe: TranslatePipe,
    private followUpLabelService: FollowUpLabelService,
    private exportMonitorService: ExportMonitorService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.isCompanyAdmin = this.authService.isSubCompanyAd() || this.authService.isCompanyAd();
    this.loadAllCompanies();
    this.getCreatedByList();
    this.loadDataSource();
    this.isEditAble = this.authService.isEmployeeRole() ? true : this.authService.getLoggedInUserEditList().includes('1012'); // get id from menu-list.ts
    this.isDeleteAble = this.authService.isEmployeeRole() ? true : this.authService.getLoggedInUserDeleteList().includes('1012');
    this.followUpLabels();
  }

  loadAllCompanies() {
    const options: any = {}
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    this.companyService.getCompaniesDropdown({}).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = resObj.data;
        //this.selectedCompany = this.allCompanyList.find(company => company.key === this.authService.getCurrentCompanyId() )
      }
    });
  }

  getCreatedByList() {
    const options: any = {};
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : null;
    }
    if (this.selectedCompany) options.companyId = this.selectedCompany.key;
    this.allCreatedByList = [];
    this.platformAdminService.getDropdown(options).subscribe((e: any) => {
      if (e.status === 'SUCCESS') {
        e.data.forEach((item) => {
          if (item.name.length > 0) {
            this.allCreatedByList.push({ label: item.name, value: item.userName });
            this.allCreatedByList = this.allCreatedByList.filter(val => !val.label.includes('_')).map(val => {
              return val;
          });
          }
        });
      }
    });
  }

  onCompanyChanges() {
    this.getCreatedByList();
  }

  loadDataSource(event?: any) {
    this.totalJobList = [];
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 9999);
    this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    const options: any = {
      size: this.size,
      page: this.page,
      sortField: 'createdAt',
      sortOrder: 'DESC'
    };

    if (event && event.sortField) {
      const sortOrder = <any>event['sortOrder'];
      const sortField = event['sortField'];
      options.sortField = sortField;
      options.sortOrder = sortOrder == 'ASC' || sortOrder == 1 ? 'ASC' : 'DESC';
    }
    if (this.selectedCompany) options.companyId = this.selectedCompany.key;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.fromDate) options.fromDate = _moment(this.fromDate).utc(true).format('YYYY-MM-DD');
    if (this.toDate) options.toDate = _moment(this.toDate).utc(true).format('YYYY-MM-DD') 
    if (this.selectedCreatedBy) options.createdByUser = this.selectedCreatedBy;
    if (this.followUpSelect) options.followUp = this.followUpSelect;
  

    this.jobFollowUpService.search(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        console.log('resObj.data.content', resObj.data.content);
        this.totalJobList = resObj.data.content;
        this.totalJobList.forEach(ele => {
          ele.showHideActions = (moment(moment().toDate()).diff(ele.createdAt, 'minutes') >= 1440);
          ele.bases = ele.totalJobDetails? ele.totalJobDetails.length: ''
      });
        this.loading = false;
        this.totalRecords = resObj.data.totalElements;
      }
    })
    if(JSON.parse(localStorage.getItem('jobIds'))){
      this.selectedIds = JSON.parse(localStorage.getItem('jobIds'));
    };
  }

  reset() {
    if (this.isPlatformAdmin) this.selectedCompany = null;
    else
      this.selectedCompany = this.allCompanyList.find(company => company.key === this.authService.getCurrentCompanyId() )
    this.fromDate = null;
    this.toDate = null;
    this.selectedCreatedBy = null;
    this.followUpSelect = null;
    this.textSearch = null;
    this.table.reset();
    this.loadDataSource();
  }

  sortingClicked(selectedSortOption) {
    if (this.selectedField === 'totalJobDetails') {
      this.totalJobList.sort((a, b) => this.selectedSortOption === 'ASC' ?
          (a.totalJobDetails.length - b.totalJobDetails.length) : (b.totalJobDetails.length - a.totalJobDetails.length)
      );
    } else if (this.selectedField != 'followUp') {
      const options = {
        sortOrder: selectedSortOption,
        sortField: this.selectedField
      };
      this.loadDataSource(options);
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  viewJob(id: number) {
    if (this.isEditAble) {
      const index = this.selectedIds.indexOf(id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
        localStorage.setItem('jobIds', JSON.stringify(this.selectedIds));
      }
      this.router.navigate([`/app/job-follow-up/view/${id}`])
    } else {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You don not have permissions')});
    }
  }

  deleteJob(data:any) {
    if(data.showHideActions){
      this.messageService.add({ severity: 'info',
          summary: this.translatePipe.transform('Info'),
          detail: this.translatePipe.transform('Sorry you cannot delete the selected record after 24hrs') });
      return;
    }
    if (this.isDeleteAble) {
      this.showConfirmDialog = true;
      this.jobDetailsId = data.id;
      this.timeSpent = new Date();
    } else {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You don not have permissions')});
    }
  }

  accept() {
    this.jobFollowUpService.delete(this.jobDetailsId).subscribe((_response) => {
      this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Deleted'), detail: this.translatePipe.transform('Record deleted Successfully')});
      this.showConfirmDialog = false;
      this.monitoringDetailsService.monitorAction(
        'Deleted Billing Client Log',
        this.timeSpent,
        {
          deleted_billing_client_log_by: this.authService.getCurrentLoggedInName(),
          billing_client_id: this.jobDetailsId.toString(),
        },
        'complete',
        'Billing Client Log',
        0
      );
      this.loadDataSource();
    }, (error) => {
      console.log(error);
    });

  }

  reject() {
    this.showConfirmDialog = false;
  }

  selectedRow(event,data){
    this.startDate = null;
    this.endDate = null;
    this.selectedValue = data;
    this.selectedCycle  = data.cycle;
    this.duplicate.toggle(event)
  }

  duplicateRecord() {
    const payload = this.selectedValue;
    const options: any = {
      periodFromDate: this.startDate,
      periodToDate: this.endDate,
      cycle:this.selectedCycle
    }
    this.jobFollowUpService.checkDuplicateJob(options).subscribe((res: any) => {
      if (res.status != 'EXIST') {
        payload.fromDate = this.startDate;
        payload.toDate = this.endDate;
        payload.cycle = this.selectedCycle;
        payload.id = null;
        if (payload.totalJobDetails.length) {
          payload.totalJobDetails.forEach(e => {
            e.id = null;
            e.totalJobId = null;
            e.followUp = this.statusLabel;
            e.statusDate = this.startDate;
            e.cycle = this.selectedCycle
          })
        }
        this.selectedValue = null;
        this.jobFollowUpService.create(payload).subscribe((resObj: any) => {
          if (resObj.status === 'SUCCESS') {
            this.selectedIds = this.selectedIds ? this.selectedIds : [];
            this.selectedIds.push(resObj.data.id);
            localStorage.setItem('jobIds', JSON.stringify(this.selectedIds));
            this.showDuplicateModal = false;
            this.messageService.add({ severity: 'success',
                summary: this.translatePipe.transform('Added'),
                detail: this.translatePipe.transform('Successfully added') });
            this.loadDataSource();
          }
        }, err => {
          this.messageService.add({ severity: 'error',
              summary: this.translatePipe.transform('Failed'),
              detail: err.message });
        });
      } else {
        if (!this.checkDuplicate) {
          this.checkDuplicate = true;
          this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Information'),
            detail: this.translatePipe.transform('We already have a record with that period and the cycle number, those information cannot be duplicate.')
          });
        }
      }
    })
  }

  getDesiredHighlightClass(data: any) {
    if (this.selectedIds) {
      const desiredClass = this.selectedIds.indexOf(+data.id) > -1
          ? 'column-highlighted'
          : '';
      return desiredClass;
    }
    return '';
  }

  followUpLabels() {
    const options: any = {
      status: 1
    };
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    this.followUpLabelService.getLabelDropdown(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        console.log('res.data', res.data);
        this.allLabel = res.data;
        const status = this.allLabel.filter(l => l.labelName.toLowerCase() === 'waiting');
        this.statusLabel = status && status.length > 0 ? status[0].id : null;
        this.followUp = [];
        res.data.forEach(label => {
          this.followUp.push({
            label: label.labelName,
            value: label.id.toString(),
            color: label.color
          });
        });
        if ( this.followUp.length === 0) {
          this.followUp.push({
            label: 'No Data',
            value: null,
            color: '#c8c8c8'
          });
        }
      }
    });
  }

  checkChange(event) {
    this.checkDuplicate = false;
  }

  exportPdf() {
    if (this.totalJobList && this.totalJobList.length) {
      const dataExport: any = {
        contentList: this.totalJobList.map(value => {
          return {
            createdAt: this.datePipe.transform(value.createdAt, 'M/d/yyyy h:mm a'),
            createdByUsr: value.createdByUsr,
            bases: value.totalJobDetails?.length,
            period: (value.fromDate || value.toDate) ? this.datePipe.transform(value.fromDate, 'M/d/yyyy h:mm a') + ' - ' + this.datePipe.transform(value.toDate, 'M/d/yyyy h:mm a') : '',
            cycle: value.cycle,
            updatedAt: this.datePipe.transform(value.updatedAt, 'M/d/yyyy h:mm a'),
            lastModifiedBy: value.lastModifiedBy
          };
        })
      };

      dataExport.companyId = this.authService.getCurrentCompany();
      dataExport.companyName = this.allCompanyList.find(company => company.key === dataExport.companyId).value;

      dataExport.username = this.authService.getCurrentUsername();
      dataExport.exportPdfTime = this.datePipe.transform(new Date().getTime(), 'MM/dd/yyyy EEE hh:mm a');

      console.log('exportPdf dataExport: ', dataExport)
      this.jobFollowUpService.exportListPdf(dataExport).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Job Follow Up List',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Problem exporting'),
            detail: this.translatePipe.transform('Problem exporting client pdf list')
          });
        }
      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Problem exporting'),
          detail: this.translatePipe.transform('Problem exporting client pdf list')
        });
      });
    }
  }
}
