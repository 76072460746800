import { Injectable } from '@angular/core';
import { AgencyAdmin } from '../../admin/agency-admin/model/agency-admin.model';
import { CrmClient } from '../model/base';
import { AbstractService } from '../../shared/service/abstract.service';
import { Constants } from '../../shared/model/constants';
import { HttpClient } from '@angular/common/http';
import { Response } from '../../shared/model/response';
import { Message } from 'primeng/api';
import { Page } from '../../shared/model/page.model';
import { ClientCustomizeSearchColumn } from '../model/clientCustomizeSearchColumn';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientService extends AbstractService<CrmClient> {

    public subject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public allAdminList = new BehaviorSubject('');
    public allAgencySubAgencyAdminList = new BehaviorSubject('');
    public allAgencyAdminList = new BehaviorSubject('');
    msgs: Message[] = [];
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CRM.CLIENT, '');
    }

    openPhoneModal(phone: string): void {
        this.subject.next(phone);
    }
    allAdminListFun(data: any) {
        this.allAdminList.next(data);
    }
    allAgencySubAgencyAdminListFun(data: any) {
        this.allAgencySubAgencyAdminList.next(data);
    }
    allAgencyAdminListFun(data: any) {
        this.allAdminList.next(data);
    }

    getClientList(): Observable<Response<CrmClient>> {
        return this._http.get<Response<CrmClient>>(this.baseUrlV2).pipe(map(resp => resp));
    }

    getChildClientList(options?: any): Observable<Response<any>> {
       return this._http.post<any>(this.baseUrlV2 + 'search-child-client', options).pipe(map(resp => resp));
    }

    getClientListByCompanyId(options?: any) {
        const params = this.createParams(options);
        return this._http.get(this.baseUrl, { params: params }).pipe(map(resp => resp));
    }

    allByCompanyAndAgency(companyId: number, agencyId: number): Observable<Response<CrmClient>> {
        const params: any = {
            companyId: companyId,
            agencyId: agencyId
        };

        return this._http.get<Response<CrmClient>>(this.baseUrl + 'allByCompanyAndAgency', { params: params }).pipe(map(resp => resp));
    }

    getByPhone(phone: string): Observable<CrmClient> {
        return this._http.get<CrmClient>(`${this.baseUrl}phone/${phone}`)
            .pipe(map(resp => resp));
    }

    getClientListByUsername(options?: any): Observable<Response<CrmClient>> {
        return this._http.get<Response<CrmClient>>(this.baseUrl + 'allByUsername', { params: options }).pipe(map(resp => resp));
    }

    getClientListByAgency(options?: any): Observable<Response<CrmClient>> {
        return this._http.get<Response<CrmClient>>(this.baseUrl + 'getAllClientByAgency', { params: options }).pipe(map(resp => resp));
    }

    getClientListByCompany(options?: any): Observable<Response<CrmClient>> {
        return this._http.get<Response<CrmClient>>(this.baseUrl + 'getAllClientByCompany', { params: options }).pipe(map(resp => resp));
    }

    getClientListByPage(options?: any): Observable<Page<CrmClient>> {
        return this._http.get<Page<CrmClient>>(`${this.baseUrl}getAll`, { params: options }).pipe(map(resp => resp));
    }

    saveMultipleClients(data: CrmClient[]): Observable<Response<CrmClient>> {
        return this._http.post<Response<CrmClient>>(`${this.baseUrlV2}saveMultipleRows`, data).pipe(map(resp => resp));
    }

    importDataFile(formData: FormData) {
        // return this.http.post<any>(`http://localhost:8010/api/v2/crm/client/import-file`, formData).pipe(map(res => res));
        return this.http.post<any>(`${this.baseUrlV2}import-file`, formData).pipe(map(res => res));
    }

    getAdminWhoCreatedClient(): Observable<AgencyAdmin[]> {
        return this._http.get<string[]>(`${this.baseUrl}getalladmincreatedclient`).pipe(map((resp: any) => resp.data));
    }

    getFilteredAdminsWhoCreatedClient(): Observable<AgencyAdmin[]> {
        return this._http.get<string[]>(`${this.baseUrl}getFilteredAdminCreatedClient`).pipe(map((resp: any) => resp.data));
    }

    getFilteredAdminCreatedClientDropDown(): Observable<any> {
        return this._http.get<any>(`${this.baseUrl}getFilteredAdminCreatedClientDropDown`).pipe(map((resp: any) => resp.data));
    }

    getAllByNewAssigneesTransfer(options: any): Observable<Page<CrmClient>> {
        const reqUrl = `${this.baseUrlV2}getAllByNewAssigneesTransfer`;
        return this._http.post<Page<CrmClient>>(reqUrl, options, { params: { page: options.page, size: options.size } }).pipe(map(resp => resp));
    }


    exportNewAssigneesTransfer(data?: any, type?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}report-acceptance/pdf`, data).pipe(map(resp => resp));
    }

    getAllHistoryByNewAssigneesTransfer(options: any): Observable<Page<CrmClient>> {
        const reqUrl = `${this.baseUrlV2}getAllHistoryByNewAssigneesTransfer`;
        return this._http.post<Page<CrmClient>>(reqUrl, options).pipe(map(resp => resp));
    }

    getAllByNotAssignees(options: any): Observable<Page<CrmClient>> {
        const reqUrl = `${this.baseUrl}getAllByNotAssignees`;
        return this._http.post<Page<CrmClient>>(reqUrl, options, { params: { page: options.page, size: options.size } }).pipe(map(resp => resp));
    }

    setMsgs(msgs: Message[]) {
        this.msgs = msgs;
    }

    getMsgs() {
        return this.msgs;
    }

    clearMsgs() {
        this.msgs = [];
    }

    saveClientCustomizeSearchColumn(data: ClientCustomizeSearchColumn): Observable<Response<ClientCustomizeSearchColumn>> {
        return this._http.post<Response<ClientCustomizeSearchColumn>>(`${environment.v2_server_ip}/${Constants.ROUTES.CRM.CLIENT_CUSTOMIZE_SEARCH_COLUMN}`, data)
            .pipe(map(resp => resp));
    }

    updateClientCustomizeSearchColumn(data: ClientCustomizeSearchColumn): Observable<Response<ClientCustomizeSearchColumn>> {
        return this._http.put<Response<ClientCustomizeSearchColumn>>(`${environment.v2_server_ip}/${Constants.ROUTES.CRM.CLIENT_CUSTOMIZE_SEARCH_COLUMN}`, data)
            .pipe(map(resp => resp));
    }

    getCustomizeSearchColumnByUserId(id?: any): Observable<Response<CrmClient>> {
        return this._http.get<Response<CrmClient>>
            (`${environment.v2_server_ip}/${Constants.ROUTES.CRM.CLIENT_CUSTOMIZE_SEARCH_COLUMN}/getCustomizeSearchColumnByUserId/${id}`).pipe(map(resp => resp));
    }

    saveHistoryData(data) {
        return this._http.post(`${this.baseUrlV2}history`, data).pipe(map(resp => resp));
    }

    saveChildCrmClient(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}create-child-client`, data).pipe(map(resp => resp));
    }

    generatePdfFile(options?: any): Observable<any> {
        const params = this.createParams(options);
        return this._http.get<any>(`${environment.server_ip}/pdfgenerate/generateClientPdf`, { params: params }).pipe(map(resp => resp));
    }

    exportClient(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}exportClient`, data).pipe(map(resp => resp));
    }

    exportClientExcel(data?: any): Observable<Blob> {
        return this._http.post(`${this.baseUrlV2}exportClientExcel`, data, { responseType: 'blob' });
    }

    checkFieldValueExists(fieldName: string, fieldValue: string): Observable<boolean> {
        const options = {
            fieldName,
            fieldValue
        };
        const params = this.createParams(options);
        return this._http.get<any>(this.baseUrl + 'checkFieldValue', { params: params }).pipe(map(resp => resp));
    }

    saveColumnSizes(data): Observable<any> {
        return this._http.post<any>(`${environment.server_ip}/clientTableSize`, data).pipe(map(resp => resp));
    }

    getColumnSizes(data): Observable<any> {
        return this._http.get<any>(`${environment.server_ip}/clientTableSize/findById`, { params: data }).pipe(map(resp => resp));
    }

    resetPassword(options): Observable<any> {
        const params = this.createParams(options);
        return this._http.get<any>(`${this.baseUrlV2}resetPassword`, { params: params }).pipe(map(resp => resp));
    }

    getAllAddedByCompany(options?: any): Observable<any> {
        const params = this.createParams(options);
        return this._http.get(this.baseUrlV2 + 'getAllAddedByCompany', { params: params }).pipe(map(res => res));
    }

    validateClientNumber(data?: any): Observable<any> {
        return this._http.post(`${this.baseUrlV2}check-phone-number`, data).pipe(map(res => res));
    }

    getClientDropdown(options: any): Observable<any> {
        const params = this.createParams(options);
        return this._http.get<any>(`${this.baseUrlV2}dropdown`, { params: params }).pipe(map(resp => resp));
        // return this._http.get<any>(`http://localhost:8010/api/v2/crm/client/dropdown`, { params: params }).pipe(map(resp => resp));
    }

    getClientDropdownParking(options: any): Observable<any> {
        const params = this.createParams(options);
        return this._http.get<any>(`${this.baseUrlV2}dropdownClient`, { params: params }).pipe(map(resp => resp));
        // return this._http.get<any>(`http://localhost:8010/api/v2/crm/client/dropdownClient`, { params: params }).pipe(map(resp => resp));
    }

    search(options: any): Observable<any> {
        const params = this.createParams(options);
        return this._http.get<any>(`${this.baseUrlV2}search`, { params: params }).pipe(map(resp => resp));
    }

    updateAgreement(id: any) {
        const url  = this.baseUrlV2 + id + '/agreement';
        return this._http.post<any>(url, null).pipe(map(resp => resp));
      }

      isEmailAvail(email: string) {
        const URL = this.baseUrlV2 + '/email-exist';
        const option = {email: email};
        return this._http.post(URL, option);
      }

    getClientById(id) {
        return this._http.get<any>(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
    }

    updateOnlineStatus(id: any, status: number) {
        const url  = this.baseUrlV2 + id + '/online-status/' + status;
        return this._http.post<any>(url, null).pipe(map(resp => resp));
    }

    updateTrustPayment(id: number, status: string) {
        return this._http.put<any>(`${this.baseUrlV2}update-trust-payment/${id}?status=${status}`, {}).pipe(map(resp => resp));
    }

    updateClient(entity: CrmClient): Observable<CrmClient> {
        // return this._http.put<CrmClient>(`http://localhost:8010/api/v2/crm/client/`, entity).pipe(map(res => res));
        return this._http.put<CrmClient>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
    }

    saveReminder(entity: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8010/api/v2/crm/client/save-reminder`, entity).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}/save-reminder`, entity).pipe(map(res => res));
    }

    searchByIds(options?: any): Observable<Response<any>> {
        return this._http.post<any>(this.baseUrlV2 + 'search-by-ids', options).pipe(map(resp => resp));
    }

    getFileNameSourceList(companyId) {
        return this.http.get<any>(`${this.baseUrlV2}file-name-source-list?companyId=${companyId}`).pipe(map(res => res));
    }

    getAllClient(options?: any): Observable<any> {
        return this._http.post<any>( this.baseUrlV2 + 'search-all-client', options).pipe(map(res => res));
    }

    exportPdfCandidate(options: any): Observable<any> {
        // return this.http.post<any>(`http://localhost:8010/api/v2/crm/client/export-pdf-candidate`, options).pipe(map(res => res));
        return this.http.post<any>(`${this.baseUrlV2}export-pdf-candidate`, options).pipe(map(res => res));
    }
}
