import {BillingInvoiceDetails} from './billing-invoice-details.model';
import { FollowUpBillingInvoice } from './follow-up-billing-invoice.model';

export class PaymentInvoice {
    id: number;
    companyId: number;
    clientId: number;
    invoiceNumber: string;
    fee: number;
    paidFee: number;
    paidFeeTxt: string;
    feeTxt: string;
    balanceTxt: string;
    balance: number;
    minuteFee: number;
    minuteFeeTxt: string;
    taxes: number;
    taxesTxt: string;
    totalAmount: number;
    totalAmountTxt: string;
    status: string;
    createdAt: Date;
    invoiceDate: Date;
    dueDate: Date;
    createdByUsr: string;
    checked = false;
    invoiceDetails: BillingInvoiceDetails[];
    companyName: string;
    clientName: string;
    paymentMethod;
    followUpBillingInvoices: FollowUpBillingInvoice[];
    collectionRepresentative?: any;
    methodContact?: any;
    note?: any;
    appliedCredit?: Boolean;
    reminderCount?: number;
    numOfFollowUpNote?: any;
    index: number;
    availableCredit: any;
    followUpNoteDate?:Date;
    invoiceType?:string;
    userId?: number;
    userRoleId?: number;
    quoteInvoice?: boolean;
    extendDueDateCount?: number;
    disabledExtendDueDate?: boolean;
    apiUsageCost?: number;
    apiUsageStartDate?: Date;
    apiUsageEndDate?: Date;
    dueIn?: number;
    dispatchBaseId?: number;
    inactiveReason?: string;
    prevStage?: string;
}
