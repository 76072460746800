import { User } from './../../../shared/model/user/user.model';
import { Component, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { Note, NoteFolder } from '../../model/note.model';
import { NoteService } from '../../service/note.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';
import { EmployeeService } from 'app/employee/service/employee.service';
import { AutoComplete } from 'primeng/autocomplete';
import { AuthService } from 'app/shared/service/auth/auth.service';
import {TranslatePipe} from '@ngx-translate/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

declare let $: any;

@Component({
  selector: 'app-note-layout',
  templateUrl: './note-layout.component.html',
  styleUrls: ['./note-layout.component.scss']
})
export class NoteLayoutComponent implements OnInit {
  folders: Array<NoteFolder> = [];
  notes: Array<Note> = [];
  allNote: Array<Note> = [];
  activeNote: Note = {} as Note;
  activeFolder: NoteFolder = {} as NoteFolder;
  searchTerm: String;
  showDialogAddFolder: Boolean = false;
  showFormEditName: Boolean = false;
  showDeletionConfirmation: Boolean = false;
  newFolderName: String = '';
  newNoteName: String = '';
  editObj: any = {};
  showDialogAddNote: Boolean = false;
  errorMessage: String = '';
  isCalling: Boolean = false;
  defaultBreadCrumbs: MenuItem[] = [];
  isShowingPopup: Boolean = false;
  showSharePopup: Boolean = false;
  suggestionsForBaseEmail: any[] = [];
  suggestionsForBaseEmailFullList: any[] = [];
  toEmail: any[] = [];
  allowWrite: Boolean = false;
  selectedNoteForShare: any[] = [];
  fullLoading: Boolean = false;
  @ViewChild('item', { static: true }) item: AutoComplete;
  selectedNoteId = '';
  lineBreak = false;
  basicMenutItems = [
    { label: 'Notebook', id: 'notebook', routerLink: ['note'] },
  ];
  tabMenuItems: MenuItem[] = [];
  activeItem: MenuItem;
  emailForm: FormGroup;
  submitted = false;
  requireEmail = false;
  invalidEmail = false;
  initTab() {
    this.tabMenuItems = [];
    this.tabMenuItems.push(...this.basicMenutItems);
  }
  routerEventsSubscribe;

  constructor(
    private noteService: NoteService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService, private employeeService: EmployeeService,
    private authService: AuthService,
    private translatePipe: TranslatePipe,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('/app/note') >= 0) {
          this.defaultBreadCrumbs.push({ label: 'Notebook' });
          this.initTab();
          this.activeItem = this.tabMenuItems[0];
          console.log(this.tabMenuItems);
        }
        this.breadcrumbService.setItems(this.defaultBreadCrumbs);
      }
    });

    this.emailForm = new FormGroup({
        email: new FormControl('', [
            Validators.required
        ])
      });


  }

  get email() {
    return this.emailForm.get('email');
  }
  resetForm() {
    this.emailForm.reset();
    this.submitted = false;
    this.toEmail = null;
  }
  closeDialog() {
    this.resetForm();
    this.showSharePopup = false;
  }
  onDialogHide() {
    this.resetForm();
  }
  ngOnInit(): void {
    this.initTab();
    this.activeItem = this.tabMenuItems[0];
    console.log('Active Item: ', this.activeItem);
    this.folders = [{ id: 0, name: 'All Notes', showAll: true, noteFiles: [], createdAt: '', updatedAt: '' }];
    this.getAllNote();
    this.getShareNote();
    this.getAllEmployee();
    this.activatedRoute.queryParams.subscribe(res => {
      this.selectedNoteId = res.noteId;
    });
  }
  ngOnDestroy() {
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }

  getAllNote() {
    this.noteService.getAllNote().subscribe((res: any) => {
      this.folders = this.folders.concat(res.data);
      this.folders.forEach((folder: NoteFolder) => {
        folder.noteFiles.forEach(e => { e.noteFolderName = folder.name; e.owner = true; });
        this.allNote = this.allNote.concat(folder.noteFiles);
        folder.owner = true;
      });
      this.allNote.forEach(n => {
        n.createdAt = this.formatDate(n.createdAt);
        if (n.updatedAt) {
          n.updatedAt = this.formatDate(n.updatedAt);
        }
      });
      this.changeFolder(this.folders[0]);
      if (this.selectedNoteId) {
        const activeNote = this.allNote.find(x => x.id === this.selectedNoteId);
        if (activeNote) {
          this.changeNote(activeNote);
        }
      }
    });
  }
  changeFolder(folder) {
    this.activeFolder = folder;
    this.notes = folder.showAll ? this.allNote : this.allNote.filter(n => n.noteFolderId === folder.id);
    this.activeNote = this.notes[0] ? this.notes[0] : {} as Note;
    this.searchTerm = '';
  }
  changeNote(note) {
    this.activeNote = note;
  }
  onChangeNoteContent(event) {
    const currentNoteIndex = this.notes.findIndex(x => x.id === this.activeNote.id);
    if (currentNoteIndex < 0) {
      return;
    }
    this.notes[currentNoteIndex].content = event.htmlValue;
    this.editObj = this.notes[currentNoteIndex];
  }
  onSearch(searchTerm) {
    if (!this.activeFolder.showAll) {
      this.changeFolder(this.folders[0]);
    }
    let searchResult = [];
    if (searchTerm) {
      searchResult = this.allNote.filter(it => {
        return it.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
      });
      this.notes = searchResult;
    } else {
      this.notes = this.allNote;
      $('#search_box').focus();
    }
    //this.activeNote = this.notes[0] ? this.notes[0] : {} as Note;
  }

  openFormAddFolder() {
    this.isCalling = false;
    this.errorMessage = '';
    this.showDialogAddFolder = true;
    this.newFolderName = '';
  }
  openFormAddNote() {
    this.isCalling = false;
    this.errorMessage = '';
    this.showDialogAddNote = true;
    this.newNoteName = '';
  }
  addFolder() {
    if (this.newFolderName.length > 60) {
      this.messageService.add({ severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform(`You have exceeded the maximum Folder Name limit of 60 characters`) });
      return;
    }
    const self = this;
    this.errorMessage = '';
    this.isCalling = true;
    this.noteService.addFolder(this.newFolderName).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        const newFolder = { ...res.data, owner: true };
        self.folders.push(newFolder);
        self.changeFolder(newFolder);
        this.messageService.add({ severity: 'info',
            summary: this.translatePipe.transform('Create Folder'),
            detail: this.translatePipe.transform(`Note Folder added successfully`) });
      } else {
        this.messageService.add({ severity: 'error',
            summary: this.translatePipe.transform('Create Folder'),
            detail: this.translatePipe.transform('Failed to create the folder', {
                value: res.message
            })
        });
      }
      this.showDialogAddFolder = false;
      this.isCalling = false;
    }, this.handleError);
  }
  openFormEditName(data) {
    this.errorMessage = '';
    this.editObj = data;
    this.showFormEditName = true;
  }

  openDeletionConfirmationDialog(data, type?: string) {
    const showHideActions = (moment(moment().toDate()).diff(data.createdAt, 'minutes') >= 1440);
    if (showHideActions) {
      this.messageService.add({
        severity: 'info',
          summary: this.translatePipe.transform('Information'),
          detail: this.translatePipe.transform('Sorry you cannot delete the selected', {
              value: type
          })
      });
      return;
    }
    this.editObj = data;
    this.showDeletionConfirmation = true;
  }

  async submitEdit(ignoreContentSync?) {
    this.errorMessage = '';
    this.isCalling = true;
    if (!this.editObj.hasOwnProperty('id')) {
      return;
    }
    const newName = this.editObj.name.trim();
    const isDuplicateName = this.notes.some(note => note.id !== this.editObj.id && note.name === newName);
     if (isDuplicateName) {
        this.messageService.add({ severity: 'error',
            summary: this.translatePipe.transform('Duplicate Name'),
            detail: this.translatePipe.transform(`This folder name already exists. Please choose a different name.`) });
        this.isCalling = false;
        this.editObj = {};
        return;
    }

    if (this.editObj.hasOwnProperty('noteFiles')) {
      this.editObj.content = this.sanitizeHtmlContent(this.editObj.content);
      this.noteService.updateFolder(this.editObj).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          const data = res.data;
          const matchFolder = this.folders.find(x => x.id === data.id);
          matchFolder.name = data.name;
          this.messageService.add({ severity: 'info',
              summary: this.translatePipe.transform('Update Folder'),
              detail: this.translatePipe.transform(`Note Folder Name updated successfully`) });
        } else {
          this.messageService.add({ severity: 'error',
              summary: this.translatePipe.transform('Update Folder'),
              detail: this.translatePipe.transform('Failed to update the folder', {
                  value: res.message
              })
          });
        }
        this.isCalling = false;
        this.showFormEditName = false;
      }, this.handleError);
    } else {
      this.editObj.content = this.sanitizeHtmlContent(this.editObj.content);
      this.noteService.updateNote(this.editObj).subscribe((res: any) => {
        const displayingNotes = this.folders.find(x => x.id === this.editObj.noteFolderId)?.noteFiles;
        const updatedNote = res.data;
        this.synchronizeUpdatedNote(displayingNotes, updatedNote, ignoreContentSync);
        this.synchronizeUpdatedNote(this.allNote, updatedNote, ignoreContentSync);
        this.synchronizeUpdatedNote(this.notes, updatedNote, ignoreContentSync);
        this.isCalling = false;
        this.showFormEditName = false;
      }, this.handleError);
    }
  }

  sanitizeHtmlContent(unsafeHtml: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, unsafeHtml) || '';
  }

  handleError = (response: any) => {
    this.errorMessage = response.error.message;
    this.isCalling = false;
	this.messageService.add({ severity: 'error',
	summary: this.translatePipe.transform('Duplicate Name'),
	detail: this.translatePipe.transform(`This name already exists. Please choose a different name.`) });
  }

  addNote() {
    const self = this;
    this.errorMessage = '';
    this.isCalling = true;
    this.noteService.addNote(this.activeFolder.id, this.activeFolder.name, this.newNoteName).subscribe((res: any) => {
      res.data.createdAt = this.formatDate(res.data.createdAt);
      res.data.noteFolderName = this.activeFolder.name;
      const parentFolder = self.folders.find(x => x.id === self.activeFolder.id);
      if (parentFolder) {
        parentFolder.noteFiles = parentFolder.noteFiles ?? [];
        parentFolder.noteFiles.push(res.data);
        self.changeNote(res.data);
      }
      self.allNote.push({ ...res.data, owner: true });
      self.notes.push({ ...res.data, owner: true });
      this.showDialogAddNote = false;
      this.isCalling = false;
    }, this.handleError);
  }

  onDelete() {
    if (this.editObj.hasOwnProperty('content')) {
      this.deleteNote(this.editObj);
    } else {
      this.deleteFolder(this.editObj);
    }
  }

  deleteNote($event) {
    const noteId = $event.id;
    const self = this;
    this.isCalling = true;
    this.noteService.deleteNote(noteId).subscribe((res: any) => {
      self.allNote = this.allNote.filter(x => x.id !== noteId);
      self.notes = this.notes.filter(x => x.id !== noteId);
      self.activeNote = {} as Note;
      this.isCalling = false;
      this.showDeletionConfirmation = false;
    }, this.handleError);
  }

  deleteFolder($event) {
    const folderId = $event.id;
    const self = this;
    this.isCalling = true;
    this.noteService.deleteFolder(folderId).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({ severity: 'info',
            summary: this.translatePipe.transform('Delete Folder'),
            detail: this.translatePipe.transform(`Note Folder deleted successfully`) });
        self.folders = this.folders.filter(x => x.id !== folderId);
        self.allNote = this.allNote.filter(x => x.noteFolderId !== folderId);
        self.notes = this.notes.filter(x => x.noteFolderId !== folderId);
        self.activeFolder = {} as NoteFolder;
      } else {
        this.messageService.add({ severity: 'error',
            summary: this.translatePipe.transform('Delete Folder'),
            detail: this.translatePipe.transform('Failed to delete the folder', {
                value: res.message
            })
        });
      }
      this.isCalling = false;
      this.showDeletionConfirmation = false;
    }, this.handleError);
  }

  formatDate(datetime: string | Date) {
    return moment(datetime).format('MM/DD/YYYY HH:mm');
  }

  synchronizeUpdatedNote(noteDataSource: Array<Note>, updatedNote: any, ignoreContentSync?) {
    const foundNote = noteDataSource.find(x => x.id === updatedNote.id);
    if (foundNote) {
      foundNote.name = updatedNote.name;
      if (!ignoreContentSync) {
        foundNote.content = updatedNote.content;
      }
      foundNote.updatedAt = this.formatDate(new Date());
    }
  }
  selectedNote;
  openShareDialog($event, type) {
    this.selectedNote = $event;
    if (type === 'note') {
      this.selectedNoteForShare = [$event.id];
    } else {
      this.selectedNoteForShare = ($event.noteFiles || []).map(x => x.id);
    }
    this.showSharePopup = true;
  }
  getAllEmployee() {
    this.employeeService.getAllEmployee().subscribe(res => {
this.suggestionsForBaseEmailFullList = res.data.map(x =>
({ email: x.email, fullName: x.fullName, key: x.email, id: x.id, firstName: x.firstName, lastName: x.lastName })); });
  }
  filterEmailProviderForTo(event) {
    this.suggestionsForBaseEmail = [];
    const query = event.query;

    (this.suggestionsForBaseEmailFullList).forEach((item) => {
      if (item && item.key) {
        if (item.key.toLowerCase().indexOf(query.toLowerCase()) >= 0
          && !this.suggestionsForBaseEmail.find(x => x.key === item.key)
          && !this.toEmail.find(x => x.email === item.key)
        ) {
          this.suggestionsForBaseEmail.push(item);
        }
      }
    });

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (event.query.match(mailformat)) {
      const newItem = {
        key: query,
        email: query
      };

      this.suggestionsForBaseEmail = [];
      this.suggestionsForBaseEmail.push(newItem);
      if (this.toEmail.indexOf(newItem) === -1) {
        this.toEmail.push(newItem);
        this.item.multiInputEL.nativeElement.value = '';
      }

    }

  }
  submitShare() {
    this.submitted = true; 
    const users = [];
    this.requireEmail = false;
    if (this.toEmail && this.toEmail.length > 0) {
        this.invalidEmail = false;
        this.toEmail.forEach(e => {
          if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.key))) {
            this.invalidEmail = true;
            return;
          }
            const matchingUser = this.suggestionsForBaseEmailFullList.find(x => e.key === x.key);
            if (matchingUser) {
            const user = {
                key: matchingUser.key,
                email: matchingUser.email,
                fullName: matchingUser.fullName,
                id: matchingUser.id
            };
            users.push(user);
            } else {
            const user = {key: e.key,
                email: e.email,
                fullName: '',
                id: ''};
            users.push(user);
            }
        });
        if (this.invalidEmail) return;
        let emailList =  this.toEmail.map(item => item.key);

        const request = {
        noteIds: this.selectedNoteForShare,
        permission: this.allowWrite ? 2 : 1,
        users,
        redirectLink: window.location.href + '?noteId=' + this.selectedNoteForShare[0],
        createdByUsr: this.authService.getCurrentUsername(),
        email: emailList? emailList.join(','): '',
        noteName: this.selectedNote.name
        };
        this.fullLoading = true;
        this.showSharePopup = false;
        this.noteService.shareNote(request).subscribe(res => {
        this.fullLoading = false;
        this.messageService.add({ severity: 'info',
            summary: this.translatePipe.transform('Share Note'),
            detail: this.selectedNoteForShare.length > 1 ? (this.translatePipe.transform('Notes shared successfully')) : (this.translatePipe.transform('Note shared successfully'))});

        }, error1 => {
        this.fullLoading = false;
        });
        this.resetForm(); 
    } else {
        this.requireEmail = true;
    }
  }
  getShareNote() {
    this.noteService.getShareNote().subscribe((res: any) => {
      const sharesFolder = res.data.map(x => {
        const noteFiles = x.notes;
        noteFiles.forEach(element => {
          element.noteFolderId = x.user;
        });
        return {
          name: 'shared by ' + x.user,
          noteFiles,
          id: x.user
        };

      });

      this.folders = this.folders.concat(sharesFolder);
      sharesFolder.forEach((folder: NoteFolder) => {
        folder.noteFiles.forEach(e => e.noteFolderName = folder.name);
        this.allNote = this.allNote.concat(folder.noteFiles || []);
      });
      this.allNote.forEach(n => {
        n.createdAt = this.formatDate(n.createdAt);
        if (n.updatedAt) {
          n.updatedAt = this.formatDate(n.updatedAt);
        }
      });
      this.changeFolder(this.folders[0]);
      if (this.selectedNoteId) {
        const activeNote = this.allNote.find(x => x.id === this.selectedNoteId);
        if (activeNote) {
          this.changeNote(activeNote);
        }
      }

    });
  }
  changeEmail() {
    this.invalidEmail = false;
    this.requireEmail = false;
  }
}
