import { Injectable } from '@angular/core';
import { Content } from '../model/content.model';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { ContentType } from '../model/contentType.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {Page} from "../../shared/model/page.model";

@Injectable({ providedIn: 'root' })
export class ContentService extends AbstractService<Content>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CONTENT, '');
  }

  getContentTypeList(): Observable<ContentType> {
    return this._http.get<ContentType>(`${environment.v2_server_backend}/operation/api/v2/operation/content/contentType/dropdown`).pipe(map(resp => resp));
  }

  createContent(entity) {
    return this._http.post(`${environment.v2_server_backend}/operation-service/api/v2/operation/content`, entity).pipe(map(res => res));
    // return this._http.post(`http://localhost:8007/api/v2/operation/content`, entity).pipe(map(res => res));
  }

  getContentid(id) {
    return this._http.get(`${environment.v2_server_backend}/operation-service/api/v2/operation/content/${id}`).pipe(map(res => res));
    // return this._http.get(`http://localhost:8007/api/v2/operation/content/${id}`).pipe(map(res => res));
  }
  getClientHistoryPage(options?: any): Observable<Page<any>> {
    const params = this.createParams(options);
    return this._http.post<Page<any>>(`${environment.v2_server_backend}/operation-service/api/v2/operation/content/history/search`, options).pipe(map(resp => resp));
  }
  updateContent(entity, id) {
    return this._http.put(`${environment.v2_server_backend}/operation-service/api/v2/operation/content`, entity).pipe(map(res => res));
    // return this._http.put(`http://localhost:8007/api/v2/operation/content`, entity).pipe(map(res => res));
  }

  getContentPage(options?: any) {
    const params = this.createParams(options);
    return this._http.get(`${environment.v2_server_backend}/operation-service/api/v2/operation/content/search`, {params: params}).pipe(map(res => res));
  }

  deleteContent(id: Number) {
    return this._http.delete(`${environment.v2_server_backend}/operation-service/api/v2/operation/content/${id}`).pipe(map(data => data));
  }

  createParams(options: any): HttpParams {
    let params = new HttpParams();
    if (options) {
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          params = params.set(key, options[key]);
        }
      }
    }
    return params;
  }


}
