import { CompanyV2 } from "./company.model";

export class CompanyHoliday
{
    id:number;
    companyId:number;
    company:CompanyV2;
    holidayName:string;
    holidayDate:any;
    status:string;
    date: Date;
    holidayTime: number;
    
}
