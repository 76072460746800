import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { AuthService } from './../../../shared/service/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskManagementService, VISIBILITY_MODE, SEARCH_TASK_DATE_TYPE, TICKET_TYPE } from './../../service/task-management.service';
import {Component, ElementRef, OnInit, ViewChild, QueryList, ViewChildren, OnDestroy, HostListener, ChangeDetectorRef} from '@angular/core';
import { DragulaService, DrakeWithModels } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { AutoComplete, ConfirmationService, Editor, MessageService } from 'primeng';
import { BreadcrumbService } from 'app/layout/service/breadcrumb.service';
import { EmployeeService } from 'app/employee/service/employee.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { WindowRefService } from 'app/shared/service/window-ref/window-ref.service';
import { TransactionDetailsService } from 'app/transaction-type/service/transaction-details.service';
import { TransactionTypeService } from 'app/transaction-type/service/transaction-type.service';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import * as _moment from 'moment';
import { CommonCode } from '../../model/type-code.enum';
import * as Quill from 'quill';
const Delta = Quill.import('delta');
import { BrowserNotification } from '../../../shared/model/browser-notification.model';
import { BrowserNotificationService } from '../../../shared/service/browser-notification/browser-notification.service';
import autoScroll from 'dom-autoscroller';
import { ITaskSearchTerm } from 'app/task-management/model/task-model';
import { CheckListModel } from 'app/task-management/model/checklist.model';
import moment from 'moment';
import { FreelancerService } from 'app/crm/service/freelancer.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CompanyAdminService } from 'app/admin/company-admin/service/company-admin.service';

import { FreelancerTicketService } from '../../../crm/service/freelancerticket.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { catchError, debounceTime } from 'rxjs/operators';
import { NotificationType } from 'app/crm/service/notification.service';
import { TaskManagementTopBarComponent } from 'app/task-management/component/task-management-top-bar/task-management-top-bar.component';

declare let $: any;

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  providers: [WindowRefService, TransactionTypeService, TransactionDetailsService, ConfirmationService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }]
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  checkListName: string;
  listItemName: string;
  showItemInput = false;
  checkListData: any;
  selectedCheckList: any;
  thumbnail: File;
  descriptionAdded: any;
  isCompanyAdmin = this.authService.isCompanyAd();
  nameChanged: boolean = false;
  sortType: string;
  updateCheckListName: any;
  listItemNameUp: any;
  cardIsOpened: boolean;
  isContractor: boolean = false;
  notContracted: boolean = false;
  moveCardInfotitle: string;
  commentClicked: boolean = false;

  @ViewChildren('cdkVirtualScrollViewport') cdkVirtualScrollViewports: QueryList<CdkVirtualScrollViewport>;
  @ViewChild(TaskManagementTopBarComponent, { static: false }) taskManagementTopBarComponent: TaskManagementTopBarComponent;
  selectedBucketId: any;
  selectedColumnIndex: any;
  shiftPressed: boolean;
  keyCount: number = 0;
  isCheckLabelName = false;

  subscriptions: Subscription = new Subscription();

  projectFirebaseSubscriptions: Subscription;
  assignedMemberUpdateFirebaseSubscriptions: Subscription;
  bucketTaskOrderFirebaseSubscriptions: Subscription;
  bucketProjectOrderFirebaseSubscriptions: Subscription;
  bucketUpdateFirebaseSubscriptions: Subscription;
  taskFirebaseSubscriptions: Subscription;
  taskUpdateNotifiFirebaseSubscriptions: Subscription;


  viewBoardError = false;
  cardDeletedHistory;

  countDownTime: number = 10;
  countErrorDontPermission: number = 0;
  
  showButtonRefresh = false;
  pasteEvent: boolean = false;

  constructor(
    private dragulaService: DragulaService,
    private taskManagementService: TaskManagementService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    private employeeService: EmployeeService,
    private authService: AuthService,
    private documentsService: DocumentsService,
    private router: Router,
    private monitoringDetailsService: MonitoringDetailsService,
    private browserNotificationService: BrowserNotificationService,
    private contentService: FreelancerService,
    private companyAdminService: CompanyAdminService,
    private ticketService: FreelancerTicketService,
    private db: AngularFireDatabase,
    private cdref: ChangeDetectorRef
  ) {
    this.labelCard = [
      { name: 'Members', code: 1, icon: 'person_outline' },
      { name: 'Label', code: 2, icon: 'label' },
      { name: 'CheckList', code: 3, icon: 'task_alt' },
      { name: 'Due Date', code: 4, icon: 'schedule' },
      { name: 'Attachment', code: 5, icon: 'attachment' },
    ];
    this.initDragula();
  }
  scrollElemnts: any;
  selectLabelCard: any;
  labelCard: any;
  newColumnName = '';
  showDialogAddColumn = false;
  showDialogUpdateColumn = false;
  showConfirmDeleteDialog = false;
  showConfirmDeleteCardDialog = false;
  showDialogUpdateCard = false;
  cardName: string;
  cardNameUpdate: string;
  isShowToolBox = false;
  currentTime: any;
  board: Array<any> = [];
  projectId = '';
  rawProjectId = '';
  selectedColumn: any = {};
  isShowTextArea = false;
  isEditDescriptionMention = false;
  isShowEditName = false;
  selectedCard: any = {
    assignedUsers: [],
  };
  canClose = false;
  loading = false;
  activityData: any[] = [];
  activityCommentData: any[] = [];
  page = 0;
  size = 10;
  commonCode = CommonCode;
  viewMore = 'View More';
  isPlatformAdmin = false;

  @ViewChild('item', { static: true }) item: AutoComplete;
  @ViewChild('op3', { static: true }) panelUnassign: any;
  @ViewChild('opRemoveCard', { static: true }) opRemoveCard: any;
  @ViewChild('opLeaveBoard', { static: true }) opLeaveBoard: any;
  @ViewChild('adminAccess', { static: true }) adminAccess: any;
  @ViewChild('inviteLinkEl') inviteLinkEl: any;
  copyInviteBtn;
  toEmail: any[] = [];
  showInvitePopup = false;
  inviteLink = '';
  loadingInviteLink = false;
  allMember = [];
  guessMembers = [];
  invitedUsers = [];
  suggestionsForBaseEmail: any[] = [];
  suggestionsForBaseEmailFullList: any[] = [];
  selectedMemberCard: any = '';
  showInviteLink = false;
  selectedBucket: any;
  allLabel: any[];
  filteredLabel: any[] = [];
  listLabelColor = ['#61bd4f', '#f2d600', '#ff9f1a', '#eb5a46', '#c377e0', '#0079bf', '#00c2e0', '#51e898', '#ff78cb', '#344563'];
  selectedLabel: any = {};
  showLabelForm = false;
  loadingLabel = false;
  newComment = '';
  allComment: any[] = [];
  allEmployee: any[] = [];
  showTaskDetail = true;
  loadingUpload = false;
  searchTermMember = '';
  background = '';
  moveCardInfo: any = {
    suggested: {},
    listBucket: [],
    position: 1
  };
  boardName = '';
  allProject: any[] = [];
  VISIBILITY_MODE = VISIBILITY_MODE;
  TICKET_TYPE = TICKET_TYPE;
  isAdmin: boolean = this.authService.adminRole();
  allEmployeeUser = [];
  currenBoard: any = null;
  previewImage = '';
  selectUserToInvite: any[] = [];
  boardAdmin;
  allCommentFile: any[] = [];
  copyBtnContent = 'Copy';
  isSuperAdmin: boolean = this.authService.isSuper();
  isSubSuper: boolean = this.authService.isSubSuper();
  editor: any;
  isLoadingActivity: Boolean = false;
  freelancerId: Number;
  showEstTaskDialog = false;
  modeType = 'create';
  pId: string;
  mentionEmployees: any[];
  @ViewChild('commentEditor')
  commentEditor: Editor;
  @ViewChild('descriptionEditor')
  descriptionEditor: Editor;
  @ViewChildren('cardsCnt') autoscroll: QueryList<ElementRef>;
  loggedInMember: any;
  isSubscribe = false;
  unsubBoardSnapshot: any;
  initedSnapshotBoard = false;
  mentionConfig: any = {};
  allTasks: any = [];
  draffContent: any = {};
  isDisableConfirm: Boolean = true;
  changeStatus = '';
  statusAfter = '';
  confirmSpinner: any = {};
  showConfirmTicketDialog: Boolean = false;
  showApproveTicketDialog: Boolean = false;
  showTakebackTicketDialog: Boolean = false;
  showRevertConfirmedTicketDialog = false;
  showRevertCompletedTicketDialog = false;
  memeberForEmail: any[];
  isFreelancer = false;
  commentOnImage: string;
  commentType: string;
  timeSpent = new Date();
  fileType: any;
  dataUrls = [];
  count = 0;
  isAdminBoard: Boolean = false;
  showCompletedTicketDialog = false;
  valueAdded: any;
  showFocusOnDescription = false;
  showFocuOnComment = false;
  selectedColumnToSort: any;
  searchMemberName: string;
  searchMemberName2: string;
  searchLabelName: string = '';

  trackByIdentify = (index: number, item: any): string => item.index;

  checkList = new CheckListModel();

  tasksViewSize = 20;

  // dragula multi selection
  selectedItems;
  mirrorContainer;
  hasMultiple = false;
  scrollSubcription;
  trackingSession = new Date().getTime();

  commentEditorQuill: any;
  addCardText;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const abc = event.target as Element;
    if (abc.localName !== 'img') {
      this.previewImage = '';
    }

    if (this.sortType === 'SelectCards' && (this.selectedColumnIndex >= 0 || this.selectedColumnIndex === 'inx')) {
      if (event.path && event.path.length > 0) {
        let inside = false;
        event.path.forEach(element => {
          if (element instanceof HTMLDivElement) {
            if (element.className && element.className.includes('card bucket-' + this.selectedColumnToSort.id)) {
              inside = true;
            }
          }
        });
        if (!inside) {
          this.board.forEach(bucket => {
            if (this.selectedColumnToSort.id !== bucket.id) {
              bucket.tasks.forEach(t => t.selected = false);
              if (bucket.tasksView) {
                bucket.tasksView.forEach(t => t.selected = false);
              }
              bucket.multiSelect = false;
            }
          });
          this.selectedColumnToSort.multiSelect = false;
          this.selectedColumnToSort.tasks.forEach(t => t.selected = false);
          this.sortType = null;
          this.selectedColumnIndex = null;
        }
      }
    }

  }

  initDragula() {
    this.dragulaService.createGroup('COLUMNS', {
      direction: 'horizontal',
      moves: (el, container, handle) => {
        return handle.classList.contains('group-handle');
      },
      accepts: (el: Element, target: Element, source: Element, sibling: Element): boolean => {
        if (!sibling) {
          return false;
        }
        return !el.contains(target); // elements can not be dropped within themselves
      },
    });

    // Dropping columns
    this.subscriptions.add(this.dragulaService.dropModel('COLUMNS').subscribe((args: any) => {
      const { name, el, target, source, sourceModel, targetModel, item } = args;

      const updatedOrder = targetModel.map((data, index) => {
        index = (index + 1) * 1000;
        return { id: data.id, order: index, name: data.name };
      });

      this.sendBucketProjectOrderChageNotification(this.projectId, updatedOrder.map(b => {
        return {
          bucketId: b.id,
          name: b.name
        }
      }));
      this.taskManagementService.updateBucketsOrder(this.projectId, {
        ids: updatedOrder.map(x => x.id),
        updatedBy: this.authService.getCurrentLoggedInName()
      }).subscribe(res => {
        this.monitoringDetailsService.monitorAction(
          'Moved Bucket',
          new Date(),
          {
            moved_card_by: this.authService.getCurrentLoggedInName(),
          },
          'complete',
          'Task management',
          0
        );
        // clear cache after update new data
        this.taskManagementService.clearCache(this.projectId);
      });
    }));


    // Dropping cards
    this.subscriptions.add(this.dragulaService.dropModel('CARDS')
      .subscribe((args: any) => {
        const { name, el, target, source, sourceModel, targetModel, item } = args;
        const s_dataset = source['dataset'] as DOMStringMap;
        const t_dataset = target['dataset'] as DOMStringMap;
        const bucketNamePrev = this.board.find(x => x.id === Number(s_dataset.id));
        const bucketNameNext = this.board.find(x => x.id === Number(t_dataset.id));
        if (this.hasMultiple) {
          this.hasMultiple = false;
          const sourceOrders = bucketNamePrev.tasks.filter(t => t.selected);
          const targetTaskIdx = targetModel.findIndex(t => Number(t.id) === Number(item.id));
          if (Number(item.bucketId) === Number(bucketNameNext.id)) {
            // internal bucket moving
            const sourceIdxOrders = [];
            for (let i = 0; i < sourceOrders.length; i++) {
              sourceIdxOrders.push(bucketNamePrev.tasks.findIndex(t => t.id === sourceOrders[i].id));
            }
            for (let i = 0; i < sourceIdxOrders.length; i++) {
              console.log('Move from: ' + sourceIdxOrders[i] + ' to ' + targetTaskIdx);
              this.moveElement(bucketNameNext.tasksView, sourceIdxOrders[i] - i, targetTaskIdx);
              this.moveElement(bucketNameNext.tasks, sourceIdxOrders[i] - i, targetTaskIdx);
            }
          } else {
            for (let i = 0; i < sourceOrders.length; i++) {
              bucketNameNext.tasksView.splice(targetTaskIdx + i, 0, sourceOrders[i]);
              bucketNameNext.tasks.splice(targetTaskIdx + i, 0, sourceOrders[i]);
            }
            bucketNamePrev.tasksView = sourceModel.filter(s => !s.selected);
            bucketNamePrev.tasks = bucketNamePrev.tasks.filter(s => !s.selected);
          }

          let cache = [];
          let targetBucketId = Number(bucketNameNext.id);
          for (let i = 0; i < this.board.length; i++) {
            let bucket = Object.assign({}, this.board[i]);
            if ((targetBucketId !== Number(bucketNamePrev.id)) && Number(bucket.id) === Number(bucketNamePrev.id)) {
              bucket.tasksView = [...bucketNamePrev.tasksView];
              bucket.tasksView.forEach(t => t.selected = false);
              bucket.tasks = [...bucketNamePrev.tasks];
              bucket.tasks.forEach(t => t.selected = false);
            }
            if (Number(bucket.id) === targetBucketId) {
              bucket.tasksView = [...bucketNameNext.tasksView];
              bucket.tasksView.forEach(t => {
                t.bucketId = targetBucketId;
                t.selected = false;
              });
              bucket.tasks = [...bucketNameNext.tasks];
              bucket.tasks.forEach(t => {
                t.bucketId = targetBucketId;
                t.selected = false;
              });
            }
            cache.push(bucket);
          }
          this.board = [...cache];
          this.initAutoScroll();
        } else {
          const taskIdx = targetModel.findIndex(t => Number(t.id) === Number(item.id));
          const sourceTaskIdx = bucketNamePrev.tasks.findIndex(t => Number(t.id) === Number(item.id));
          if (Number(item.bucketId) === Number(bucketNameNext.id)) {
            this.moveElement(bucketNameNext.tasks, sourceTaskIdx, taskIdx);
            this.moveElement(bucketNameNext.tasksView, sourceTaskIdx, taskIdx);
          } else {
            bucketNameNext.tasksView.splice(taskIdx, 0, item);
            bucketNameNext.tasks.splice(taskIdx, 0, item);

            bucketNamePrev.tasksView = [...sourceModel];
            const idx = bucketNamePrev.tasks.findIndex(t => Number(t.id) === Number(item.id));
            bucketNamePrev.tasks.splice(idx, 1);
          }
          bucketNamePrev.tasksView.forEach(t => t.selected = false);
          bucketNamePrev.tasks.forEach(t => t.selected = false);
          bucketNameNext.tasksView.forEach(t => t.selected = false);
          bucketNameNext.tasks.forEach(t => t.selected = false);
        }
        this.checkTicketCount()

        const movedIds = bucketNameNext.tasksView.map(t => t.id);
        this.sendBucketTaskOrderChageNotification(bucketNameNext.id, movedIds);
        this.taskManagementService.updateTaskOrder(
          t_dataset.id,
          {
            ids: movedIds,
            updatedBy: this.authService.getCurrentLoggedInName()
          }).subscribe(() => {
            this.board.forEach(bucket => {
              bucket.multiSelect = false;
              this.reset();
              if (bucket.id === Number(t_dataset.id)) {
                this.sendBucketUpdateNotification(bucket.id);
                bucket.tasks.forEach(task => task.bucketId = t_dataset.id);
              }
              if (bucket.id === Number(s_dataset.id) || bucket.id === Number(t_dataset.id)) {
                this.sendBucketUpdateNotification(bucket.id);
                bucket.tasks = [...bucket.tasks];
              }
            });
            this.monitoringDetailsService.monitorAction(
              'Moved Card',
              new Date(),
              {
                moved_card_by: this.authService.getCurrentLoggedInName(),
                task_id: item.id,
                task_name: item.name,
                bucket_prev: bucketNamePrev ? bucketNamePrev.name : '',
                bucket_next: bucketNameNext ? bucketNameNext.name : '',
                type: CommonCode.MOVE_CARD.toString()
              },
              'complete',
              'Task management',
              Number(this.projectId),
            );
          });
      }));

    this.subscriptions.add(this.dragulaService.cloned('CARDS').subscribe((args: any) => {
      const { name, clone, original, cloneType } = args;
      let allow = false;
      (original as Element).childNodes.forEach(c => {
        if (c.nodeName === 'MAT-CHECKBOX') {
          allow = true;
        }
      });
      if (!allow || !(original as Element).classList.contains('selectedItem')) {
        this.board.forEach(bucket => {
          bucket.tasks.forEach(t => t.selected = false);
          bucket.tasksView.forEach(t => t.selected = false);
        });
        return;
      }
      this.mirrorContainer = $('.gu-mirror').first();
      this.mirrorContainer.removeClass('selectedItem');
      this.selectedItems = $('.selectedItem');

      this.hasMultiple = this.selectedItems.length > 1 || (this.selectedItems.length == 1 && !$(original).hasClass('selectedItem'));
      if (this.hasMultiple) {
        $('.gu-transit').addClass('selectedItem');
        this.selectedItems = $('.selectedItem');
        this.mirrorContainer.empty();
        let height = 0;
        let temp = this.mirrorContainer;
        this.selectedItems.each(function (index) {
          let item = $(this);
          if (item.children('mat-checkbox.multi-select-checkbox') && item.children('mat-checkbox.multi-select-checkbox').length > 0) {
            let mirror = item.clone(true);
            mirror.removeClass('selectedItem gu-transit');
            temp.append(mirror);
            temp.css('background-color', 'transparent');
            item.addClass('gu-transit');
            let rect = item[0].getBoundingClientRect();
            height += rect.height;
          }
        });
        this.mirrorContainer = temp;
        this.mirrorContainer.css('height', height + 'px');
      }
    }));

    this.subscriptions.add(this.dragulaService.over("CARDS").subscribe(({ el, source }) => {
      let target = document.querySelectorAll('.selectedItem,.gu-transit')
      for (let i = target.length - 1; i >= 0; i--) {
        if (target[i].className.includes('gu-transit') && i >= 0) {
          this.removeClass(target[i], 'gu-transit');
        }
      }
    }));
  }


  async ngOnInit() {
    this.currentTime = new Date();
    this.board = [];
    if (localStorage.getItem('_user') === 'freelancer') {
      this.isFreelancer = true;
    }
    this.subscriptions.add(this.activatedRoute.params.subscribe(p => {
      const projectId = this.taskManagementService.decodeId(p.id);
      this.initFirebaseEvent(projectId);
      this.getProjectDetail(projectId);
      this.getAllUsersList(projectId);
      this.pId = projectId;
      this.projectId = projectId;
      this.rawProjectId = p.id;
    }));
    this.getAllProject();
    // this.getAllEmployee();
    this.allComment = [];
    this.allCommentFile = [];
    const optionsEmployee = {
      status: 1,
      companyId: this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : null
    };
    this.employeeService.getAllsDropdown(optionsEmployee).subscribe(res => {
      const resObj: any = res;
      this.mentionEmployees = resObj.data;
    });
    this.isContractor = this.authService.isContractorRole();
    if (this.isContractor) this.checkContract();
  }

  hideMentions() {
    $( "mention-list").hide();
  }

  initAutoScroll() {
    setTimeout(() => {
      if (this.scrollSubcription) {
        this.scrollSubcription.destroy(true);
      }
      this.scrollSubcription = autoScroll(
        this.autoscroll.toArray().map(el => {
          return el.nativeElement;
        })
        , {
          margin: 20,
          maxSpeed: 20,
          scrollWhenOutside: true,
          autoScroll: function () {
            return this.down;
          }
        });
    }, 3000);
  }

  urlify(text, type?) {
    // const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    // if (text.search('<a href="') === -1) {
    //   return text.replace(urlRegex, function(url) {
    //     return '<a href="' + url + '" target="_blank">' + url + '</a>';
    //   });
    // }
    // return text;
    const exp = /((href|src)=["']|)(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    if (!type) {
      return text.replace(exp, function () {
        return arguments[1] ?
          arguments[0] :
          '<a target=\'_blank\' href="' + arguments[3] + '">' + arguments[3] + '</a>';
      });
    } else {
      return text.replace(exp, function () {
        return arguments[1] ? arguments[0] : '<a>' + ' [' + arguments[3] + ' ] ' + '</a>';
      });
    }
  }

  ngOnDestroy() {
    // Required to remove any autoscroll items else will drop frames
    if (this.scrollElemnts) {
      this.scrollElemnts.destroy(false);
    }
    // destroy all the subscriptions at once
    this.subscriptions.unsubscribe();
    this.dragulaService.destroy('COLUMNS');
    if (this.projectFirebaseSubscriptions) {
      this.projectFirebaseSubscriptions.unsubscribe();
    }
    if (this.bucketTaskOrderFirebaseSubscriptions) {
      this.bucketTaskOrderFirebaseSubscriptions.unsubscribe();
    }
    if (this.bucketProjectOrderFirebaseSubscriptions) {
      this.bucketProjectOrderFirebaseSubscriptions.unsubscribe();
    }
    if (this.bucketUpdateFirebaseSubscriptions) {
      this.bucketUpdateFirebaseSubscriptions.unsubscribe();
    }
    if (this.taskFirebaseSubscriptions) {
      this.taskFirebaseSubscriptions.unsubscribe();
    }
    if (this.assignedMemberUpdateFirebaseSubscriptions) {
      this.assignedMemberUpdateFirebaseSubscriptions.unsubscribe();
    }
    if (this.taskUpdateNotifiFirebaseSubscriptions) {
      this.taskUpdateNotifiFirebaseSubscriptions.unsubscribe();
    }
    // this.unsubBoardSnapshot();

  }
  openToolBox(item) {
    this.isShowToolBox = !this.isShowToolBox;
    this.selectLabelCard = item;
  }
  onAddCardEvent(event: any, bucketName, tasks) {
    const request = {
      name: event.name.trim(),
      content: '',
      status: '',
      bucketId: event.column,
      userType: this.taskManagementService.getCurrentUserRole(),
      userId: this.authService.getCurrentLoggedInId(),
      projectId: this.projectId, // need project APi to clear cache
      position: tasks && tasks.length > 0 ? tasks.length + 1 : 0
    };
    this.loading = true;
    this.taskManagementService.addCard(request).subscribe((res: any) => {
      this.loading = false;
      const columnIdx = this.board.findIndex(column => column.id === event.column);
      const newTask = { ...res.data, assignedUsers: [], labels: [], bucketName };
      this.sendTaskUpdateNotification(newTask.id);
      this.board[columnIdx].tasks.push(newTask);
      if (!this.board[columnIdx].tasksView) {
        this.board[columnIdx].tasksView = [];
      }
      if (this.board[columnIdx].tasksView.length === this.board[columnIdx].tasks.length - 1) {
        this.board[columnIdx].tasksView.push(newTask);
      }
      this.addCardText = null;
      this.messageService.add({ severity: 'success', summary: 'Created', detail: 'Card created Successfully' });
      // this.taskManagementService.pushEventUpdateTask(this.projectId, newTask);
      this.allTasks.push({
        id: newTask.id,
        name: `${newTask.internalId}-${this.createSlugName(newTask)}`
      });
      this.initMention();
      this.monitoringDetailsService.monitorAction(
        'Added Card',
        this.timeSpent,
        {
          added_card_by: this.authService.getCurrentLoggedInName(),
          task_id: res.data.id,
          task_name: event.name.trim(),
          bucket_name: bucketName,
          type: CommonCode.ADD_CARD.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    }, error => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  /**
   * Event handler when column title is updated
   *
   * @param {*} event
   * @memberof DragulaComponent
   */
  onEditColumnTitleEvent(event: any) {
    this.loading = true;
    this.taskManagementService.updateColumn({ name: event.name.trim(), columnId: event.id }).subscribe((res: any) => {
      this.loading = false;
      const columnIdx = this.board.findIndex(column => column.id === event.id);
      this.board[columnIdx].name = event.name;
    }, err => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message });
    });
  }

  getProjectDetail(pId, type?) {
    this.loading = true;
    this.breadcrumbService.setItems([
        { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' }
    ]);
    this.taskManagementService.getProjectById(pId).subscribe(async (res: any) => {
      this.breadcrumbService.setItems([
          { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' },
          { label: res.data.name, id: 'task_management_name', routerLink: '/app/tm/' + res.data.id }
      ]);
      localStorage.setItem("projectDetail", JSON.stringify({ label: res.data.name, id: 'task_management_name', routerLink: '/app/tm/' + res.data.id }));
      this.initAutoScroll();
      res.data.buckets.forEach(b => {
        b.tasks = b.tasks.filter(t => t.status !== 'DELETED');
      });
      this.boardName = res.data.name;
      this.board = res.data.buckets;
      this.currenBoard = res.data;
      this.background = res.data.background || '';
      const emmployeeIds = res.data.acceptedUsers.filter(x => x.userType === 'EMPLOYEE').map(x => x.id) || [];
      const adminIds = res.data.acceptedUsers.filter(x => x.userType === 'ADMIN').map(x => x.id) || [];
      const guestIds = res.data.acceptedUsers.filter(x => x.userType === 'GUEST').map(x => x.id) || [];
      const freelancerIds = res.data.acceptedUsers.filter(x => x.userType === 'FREELANCER').map(x => x.id) || [];
      this.boardAdmin = res.data.acceptedUsers.find(x => x.role === 'ADMIN');
      if (!this.boardAdmin) {
        this.boardAdmin = res.data.acceptedUsers.find(x => x.role === 'SUB_ADMIN');
      }
      const userAvatars: any = await this.taskManagementService.getMemberAvatar(emmployeeIds, adminIds, guestIds, freelancerIds).toPromise();
      this.allMember = res.data.acceptedUsers;
      this.allEmployeeUser = res.data.acceptedUsers.filter(x => x.userType === 'EMPLOYEE');
      this.allMember.forEach(e => {
        const userAvatar = userAvatars.data.find(x => x.userId === e.id);
        e.avatar = userAvatar?.link;
      });
      this.invitedUsers = res.data.invitedUsers.filter(x => this.boardAdmin && this.boardAdmin.id != x.id);
      let listLabelWithoutInvoiceRef = [];
      if (res.data.labels.length > 0) {
        listLabelWithoutInvoiceRef = res.data.labels.filter(l => l.color !== '#387964');
      }
      this.allLabel = listLabelWithoutInvoiceRef;
      if (!type) {
        this.checkDeadline();
        this.openSlugLink();
        this.handleVisibleMode();
      }
      // if (this.isAdminAccess()) {
      //   this.boardAdmin = true;
      // }
      // init mention config
      this.getAllTasks();
      this.initMention();
      // this.unsubBoardSnapshot = this.taskManagementService.initSnapshotData(
      //   `board-${this.projectId}`,
      //   this.onBoardSnapshotChange.bind(this),
      //   this.onTaskSnapshotChange.bind(this),
      //   this.onBucketSnapshotChange.bind(this));

      if (this.valueAdded) {
        this.newComment = this.valueAdded;
        setTimeout(() => {
          this.commentEditorQuill.setSelection(this.newComment.length);
        }, 100);
      }
      if (this.descriptionAdded) {
        this.draffContent[this.selectedCard.id] = this.descriptionAdded;
        setTimeout(() => {
          this.descriptionEditor.getQuill().setSelection(this.descriptionEditor.value.length);
        }, 100);
      }

      this.board.forEach(bucket => this.reloadTaskView(bucket));
      this.loading = false;
    }, err => this.loading = false);


  }

  showActivity(event?: any) {
    if (event) {
      this.page = this.page + 1;
    } else {
      this.page = 0;
      this.size = 10;
      this.isLoadingActivity = true;
    }
    const options = {
      size: this.size,
      page: this.page,
      sortField: 'createdAt',
      sortOrder: 'DESC',
      projectId: this.projectId,
      process: 'Task management'
    };
    this.monitoringDetailsService.getPageWithFilter(options)
      .subscribe(async (data: any) => {
        const resObj: any = data;
        if (!event) {
          this.activityData = [];
        }
        const countAc = resObj.data.content.length;
        this.viewMore = `View more (${this.page + 1}/${resObj.data.totalPages})`;
        resObj.data.content.forEach(obj => {
          const args = obj.arguments || {};
          if (Number(args.type) === CommonCode.ADD_BOARD) {
            this.activityData.push({
              type: Number(args.type), // New board
              typeName: 'added new board',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: obj.startedByName,
              boardName: args.board_name,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.UPDATE_BOARD) {
            this.activityData.push({
              type: Number(args.type), // Update board
              typeName: 'updated board',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: obj.startedByName,
              boardName: args.board_name,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.UPDATE_BG_BOARD) {
            this.activityData.push({
              type: Number(args.type), // Update bg board
              typeName: 'updated background board for',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: obj.startedByName,
              boardName: args.board_name,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.ADD_MEMBER) {
            const member = this.allMember.find(x => x.id === Number(args.members));
            this.taskManagementService.getTask(args.task_id).subscribe(res => {
              const resObjTask: any = res;
              this.activityData.push({
                type: Number(args.type), // add member
                typeName: 'added',
                createdAt: new Date(obj.processStartTime).getTime(),
                createBy: obj.startedByName,
                taskId: args.task_id,
                taskName: resObjTask.data ? resObjTask.data.name : '',
                internalId: resObjTask.data ? resObjTask.data.internalId : 0,
                memberName: member ? member.name : '',
                userType: args.userType,
                userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
              });
              this.sortActivityLoading();
            });
          } else if (Number(args.type) === CommonCode.DELETE_MEMBER) {
            const member = this.allMember.find(x => x.id === args.members);
            this.taskManagementService.getTask(args.task_id).subscribe(res => {
              const resObjTask: any = res;
              this.activityData.push({
                type: Number(args.type), // add member
                typeName: 'removed',
                createdAt: new Date(obj.processStartTime).getTime(),
                createBy: obj.startedByName,
                taskId: args.task_id,
                taskName: resObjTask.data ? resObjTask.data.name : '',
                internalId: resObjTask.data ? resObjTask.data.internalId : 0,
                memberName: member ? member.name : '',
                userType: args.userType,
                userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
              });
              this.sortActivityLoading();
            });
          } else if (Number(args.type) === CommonCode.ADD_CARD) {
            this.taskManagementService.getTask(args.task_id).subscribe(res => {
              const resObjTask: any = res;
              this.activityData.push({
                type: Number(args.type), // add card
                typeName: 'added',
                createdAt: new Date(obj.processStartTime).getTime(),
                createBy: obj.startedByName,
                taskId: args.task_id,
                taskName: resObjTask.data ? resObjTask.data.name : '',
                internalId: resObjTask.data ? resObjTask.data.internalId : 0,
                bucketName: args.bucket_name,
                userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
              });
              this.sortActivityLoading();
            });
          } else if (Number(args.type) === CommonCode.DELETE_CARD) {
            this.activityData.push({
              type: Number(args.type), // delete member
              typeName: 'removed',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: obj.startedByName,
              taskId: args.task_id,
              taskName: args.task_name,
              bucketName: args.bucket_name,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.UPDATE_CARD) {
            this.taskManagementService.getTask(args.task_id).subscribe(res => {
              const resObjTask: any = res;
              this.activityData.push({
                type: Number(args.type), // add card
                typeName: 'updated',
                createdAt: new Date(obj.processStartTime).getTime(),
                createBy: obj.startedByName,
                taskId: args.task_id,
                taskName: resObjTask.data ? resObjTask.data.name : '',
                internalId: resObjTask.data ? resObjTask.data.internalId : 0,
                bucketName: args.bucket_name,
                userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
              });
              this.sortActivityLoading();
            });
          } else if (Number(args.type) === CommonCode.ADD_BUCKET) {
            this.activityData.push({
              type: Number(args.type), // delete member
              typeName: 'added Bucket',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: obj.startedByName,
              bucketName: args.bucket_name,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.DELETE_BUCKET) {
            this.activityData.push({
              type: Number(args.type), // delete member
              typeName: 'removed Bucket',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: obj.startedByName,
              bucketName: args.bucket_name,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.ADD_MEMBER_BOARD) {
            this.activityData.push({
              type: Number(args.type), // delete member
              typeName: 'added member',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: obj.startedByName,
              bucketName: args.bucket_name,
              memberName: args.memberName,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.MOVE_CARD) {
            this.taskManagementService.getTask(args.task_id).subscribe(res => {
              const resObjTask: any = res;
              this.activityData.push({
                type: Number(args.type), // moved task
                typeName: 'moved',
                createdAt: new Date(obj.processStartTime).getTime(),
                createBy: obj.startedByName,
                bucketPrev: args.bucket_prev,
                bucketNext: args.bucket_next,
                taskId: args.task_id,
                taskName: resObjTask.data ? resObjTask.data.name : '',
                internalId: resObjTask.data ? resObjTask.data.internalId : 0,
                userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
              });
              this.sortActivityLoading();
            });
          } else if (Number(args.type) === CommonCode.ADD_DESCRIPTION_CARD) {
            this.taskManagementService.getTask(args.task_id).subscribe(res => {
              const resObjTask: any = res;
              this.activityData.push({
                type: Number(args.type), // moved task
                typeName: 'add description',
                createdAt: new Date(obj.processStartTime).getTime(),
                createBy: obj.startedByName,
                taskId: args.task_id,
                taskName: resObjTask.data ? resObjTask.data.name : '',
                internalId: resObjTask.data ? resObjTask.data.internalId : 0,
                userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
              });
              this.sortActivityLoading();
            });
          } else if (Number(args.type) === CommonCode.HIDE_BUCKET) {
            this.activityData.push({
              type: Number(args.type), // delete member
              typeName: 'Hide Bucket',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: args.bucket_hided_by,
              bucketName: args.bucket_name,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          } else if (Number(args.type) === CommonCode.UNHIDE_BUCKET) {
            this.activityData.push({
              type: Number(args.type), // delete member
              typeName: 'Un-Hide Bucket',
              createdAt: new Date(obj.processStartTime).getTime(),
              createBy: args.bucket_unhided_by,
              bucketName: args.bucket_names,
              userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
            });
            this.sortActivityLoading();
          }
        });
        /*if (!event) {
          this.getCommentDataByProject(event);
        }*/
        this.isLoadingActivity = false;
      });
  }

  async getCommentDataByProject(viewMore?: any) {
    const resObjCmt: any = await this.taskManagementService.getListCommentOfProject(this.projectId).toPromise();
    resObjCmt.data.forEach(comment => {
      this.taskManagementService.getTask(comment.taskId).subscribe(res => {
        const resObjTask: any = res;
        if (comment.type === CommonCode.ADD_IMAGE) {
          this.activityData.push({
            type: CommonCode.ADD_IMAGE, // moved task
            typeName: 'attached',
            createdAt: comment.createdAt,
            createBy: comment.createdByUsr,
            taskId: comment.taskId,
            taskName: resObjTask.data ? resObjTask.data.name : '',
            internalId: resObjTask.data ? resObjTask.data.internalId : 0,
            content: comment.content,
            userInfo: this.allMember.find(x => x.id === comment.userId) || {}
          });
          this.sortActivityLoading();
        } else if (comment.type === CommonCode.ADD_COMMENT) {
          this.activityData.push({
            type: CommonCode.ADD_COMMENT, // moved task
            typeName: 'added comment',
            createdAt: comment.createdAt,
            createBy: comment.createdByUsr,
            taskId: comment.taskId,
            taskName: resObjTask.data ? resObjTask.data.name : '',
            internalId: resObjTask.data ? resObjTask.data.internalId : 0,
            content: comment.content,
            userInfo: this.allMember.find(x => x.id === comment.userId) || {}
          });
          this.sortActivityLoading();
        }
      });
    });
  }

  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }
  showAddMemberTask(comments?: any[]) {
    const options = {
      size: 10,
      page: 0,
      sortField: 'createdAt',
      sortOrder: 'DESC',
      projectId: this.projectId,
      process: 'Task management'
    };
    this.monitoringDetailsService.getPageWithFilter(options)
      .subscribe((data: any) => {
        const resObj: any = data;
        this.activityData = [];
        resObj.data.content.forEach(obj => {
          const args = obj.arguments || {};
          let findExisted;
          if (comments) {
            findExisted = comments.find(cmt => cmt.monitorId === obj.id);
          } else {
            findExisted = this.allComment.find(cmt => cmt.monitorId === obj.id);
          }

          if (this.selectedCard.id === Number(args.task_id) && !findExisted) {
            if (Number(args.type) === CommonCode.ADD_MEMBER) {
              let member = this.allEmployee.find(x => x.value === Number(args.members)
                && x.type === args.userType);
              if (!member) {
                member = this.allMember.find(x => x.id === Number(args.members)
                  && x.userType === args.userType);
              }
              if (member) {
                let content = '';
                if (member.value === this.authService.getCurrentLoggedInId()) {
                  content = 'joined this card';
                } else {
                  content = 'added <b>' + member.name + '</b> to this card';
                }
                const comment = {
                  monitorId: obj.id,
                  type: CommonCode.ADD_MEMBER_TASK, // add member
                  typeName: 'added',
                  createdAt: new Date(obj.processStartTime).getTime(),
                  createdByUsr: obj.startedByName,
                  memberId: Number(args.members),
                  memberName: member ? member.name : '',
                  content,
                  userType: args.userType,
                  userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
                };

                if (comments) {
                  comments.push(comment);
                } else {
                  this.allComment.push(comment);
                }
              }
            } else if (Number(args.type) === CommonCode.DELETE_MEMBER && !findExisted) {
              let member = this.allEmployee.find(x => x.value === Number(args.members)
                && x.type === args.userType);
              if (!member) {
                member = this.allMember.find(x => x.id === Number(args.members)
                  && x.userType === args.userType);
              }
              if (member) {
                let content = '';
                if (member.value === this.authService.getCurrentLoggedInId()) {
                  content = 'left this card';
                } else {
                  content = 'removed <b>' + member.name + '</b> from this card';
                }
                const comment = {
                  monitorId: obj.id,
                  type: CommonCode.DEL_MEMBER_TASK, // add member
                  typeName: 'removed',
                  createdAt: new Date(obj.processStartTime).getTime(),
                  createdByUsr: obj.startedByName,
                  memberId: Number(args.members),
                  memberName: member ? member.name : '',
                  content,
                  userType: args.userType,
                  userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
                };
                if (comments) {
                  comments.push(comment);
                } else {
                  this.allComment.push(comment);
                }
              }
            } else if (Number(args.type) === CommonCode.ADD_CARD) {
              const content = ' added this task to <b>' + args.bucket_name + '</b>';
              const comment = {
                monitorId: obj.id,
                type: CommonCode.ADD_CARD, // add card
                typeName: 'add card',
                createdAt: new Date(obj.processStartTime).getTime(),
                createdByUsr: obj.startedByName,
                content,
                userType: args.userType,
                userInfo: this.allMember.find(x => x.id === obj.startedById) || {}
              };
              if (comments) {
                comments.push(comment);
              } else {
                this.allComment.push(comment);
              }
            }
          }
        });
        if (comments) {
          console.log(comments);

          this.allComment = comments.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });
        } else {
          this.allComment = this.allComment.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });
        }

      });
  }

  sortActivityLoading() {
    this.activityData = this.activityData.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  }


  checkDeadline() {
    for (const col of this.board) {
      if (col.tasks) {
        for (const item of col.tasks) {
          if (item.deadline) {
            const getTD = new Date(item.deadline);
            const statusDeadline = getTD.valueOf() -
              this.currentTime.valueOf() <= 86400000 && getTD.valueOf() -
              this.currentTime.valueOf() >= 0 ? 'upcoming' : getTD.valueOf() - this.currentTime.valueOf() < 0 ?
              'expired' : 'normal';
            item['statusDeadline'] = statusDeadline;
          }
        }
      }
    }
  }
  addColumn() {
    this.showDialogAddColumn = false;
    this.loading = true;
    this.taskManagementService.addColumn({
      name: this.newColumnName.trim(),
      projectId: this.projectId
    }).subscribe((res: any) => {
      this.newColumnName = '';
      this.loading = false;
      const newColumn = { ...res.data, tasks: [] };
      this.board.push(newColumn);
      this.initAutoScroll();
      this.sendBucketProjectOrderChageNotification(this.projectId, this.board.map(b => {
        return {
          bucketId: b.id,
          name: b.name
        }
      }));
      this.messageService.add({ severity: 'success', summary: 'Created', detail: 'Bucket created Successfully' });
      this.monitoringDetailsService.monitorAction(
        'Added Bucket',
        this.timeSpent,
        {
          added_bucket_by: this.authService.getCurrentLoggedInName(),
          bucket_name: this.newColumnName.trim(),
          type: CommonCode.ADD_BUCKET.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
      this.newColumnName = '';
    }, err => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message });

    });
  }
  confirmDeleteColumn() {
    this.showConfirmDeleteDialog = false;
    this.loading = true;
    this.taskManagementService.deleteColumn(this.selectedColumn.id).subscribe(res => {
      this.loading = false;
      const idx = this.board.findIndex(x => x.id === this.selectedColumn.id);
      this.board.splice(idx, 1);
      this.sendBucketProjectOrderChageNotification(this.projectId, this.board.map(b => {
        return {
          bucketId: b.id,
          name: b.name
        }
      }));
      this.messageService.add({ severity: 'success', summary: 'Deleted', detail: 'Bucket deleted Successfully' });
      this.monitoringDetailsService.monitorAction(
        'Deleted Bucket',
        this.timeSpent,
        {
          deleted_bucket_by: this.authService.getCurrentLoggedInName(),
          bucket_name: this.selectedColumn.name,
          type: CommonCode.DELETE_BUCKET.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    }, err => {
      this.loading = false;
    });
  }
  cancelEditCardName(event) {
    event.stopPropagation();
    this.cardName = '';
  }

  editNameCard(card, event, column) {
    event.stopPropagation();
    this.cardName = card.name;
    this.selectedCard = { ...card };
    this.selectedCard.oldCardName = this.cardName;
    this.selectedColumn = { id: column.id, name: column.name };
  }
  openDeleteColumnDialog(column) {
    this.selectedColumn = column;
    const access = 'delete this bucket';
    if (this.isBoardAdmin(access)) {
      this.showConfirmDeleteDialog = true;
    }
    this.timeSpent = new Date();
  }
  closeTextArea() {
    setTimeout(() => {
      if (this.isEditDescriptionMention) {
        this.isEditDescriptionMention = false;
      } else {
        // this.isShowTextArea = false;
        this.hideMentions()
      }
    }, 100);
  }
  openTextArea(event?) {
    if (this.isSuperAdmin) { return; }
    if (event.target.getAttribute('href') || event.target.getAttribute('src')) { return; }
    this.isShowTextArea = true;
    this.canClose = true;
  }
  confirmDeleteCard() {
    this.loading = true;
    this.showConfirmDeleteCardDialog = false;
    // this.taskManagementService.deleteCard(this.selectedCard.id).subscribe(res => {
    const payLoad = {
      createdByUser: this.authService.getCurrentUsername(),
      id: this.selectedCard.id
    }
    this.taskManagementService.deleteTask(payLoad).subscribe(res => {
      this.loading = false;
      this.board.forEach(column => {
        if (column.id === this.selectedCard.bucketId) {
          const idx = column.tasks.findIndex(x => x.id === this.selectedCard.id);
          column.tasks.splice(idx, 1);
          if (column.tasksView && column.tasksView.length > idx) {
            column.tasksView.splice(idx, 1);
          }
          this.sendBucketUpdateNotification(column.id);
        }
      });
      this.messageService.add({ severity: 'success', summary: 'Deleted', detail: 'Card deleted Successfully' });
      this.monitoringDetailsService.monitorAction(
        'Deleted Card',
        this.timeSpent,
        {
          deleted_card_by: this.authService.getCurrentLoggedInName(),
          task_id: this.selectedCard.id,
          task_name: this.selectedCard.name,
          type: CommonCode.DELETE_CARD.toString(),
          bucket_name: this.selectedColumn.name,
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    }, err => {
      this.loading = false;
    });
  }
  openDeleteCardDialog(card, event, column) {
    const showHideActions = (moment(moment().toDate()).diff(card.createdAt, 'minutes') >= 1440);
    if (showHideActions) {
      this.messageService.add({
        severity: 'info', summary: 'Information',
        detail: `Sorry you cannot delete the selected card after 24hrs.`
      });
      return;
    }
    if (this.isBoardAdmin() || card.userId === this.authService.getCurrentLoggedInId()) {
      event.stopPropagation();
      this.selectedCard = card;
      this.showConfirmDeleteCardDialog = true;
      this.selectedColumn = { id: column.id, name: column.name };
      this.timeSpent = new Date();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `You don't have permission to delete card created by other member`
      });
    }
  }

  openEstimationTaskDialog() {
    const memberAdmin = this.allMember.filter(x => x.userType === 'ADMIN');
    const memberAssign = [...memberAdmin, ...this.selectedCard.assignedUsers];
    const loginUserId = this.authService.getCurrentLoggedInId();
    const member = memberAssign.find(mem => mem.id === loginUserId
      && (mem.userType === 'FREELANCER' || mem.userType === 'ADMIN'));
    if (member || this.isBoardAdmin()) {
      if (this.notContracted) {
        this.messageService.add({
          severity: 'info', summary: 'Information',
          detail: 'Please contact your PM seems like you do not have Any Active Contract.'
        });
        return;
      }
      this.showEstTaskDialog = true;
    } else {
      this.messageService.add({
        severity: 'error', summary: 'Error',
        detail: 'Sorry this Task not assigned to you or you do not have access, please contact board admin.'
      });
    }
  }

  selecteColumn(index, column) {
    this.selectedColumnToSort = column
    this.selectedColumnIndex = index;
  }

  async openEditCardDialog(task, column?, columnindex?) {

    console.log('ajhihi', this.shiftPressed, this.sortType, this.selectedColumnIndex)
    this.keyCount = this.keyCount + 1;
    if (this.shiftPressed && column && this.keyCount < 2) {
      this.selectedColumnToSort = column;
      this.sortCards('SelectCards', 'inx')
      return;
    }
    if (this.shiftPressed && this.sortType === 'SelectCards') {
      this.selectedColumnIndex = columnindex;
      if (this.selectedColumnToSort) {
        this.selectedColumnToSort.multiSelect = true;
      }
    }
    if (this.selectedColumnIndex >= 0 && this.selectedColumnIndex == columnindex && this.sortType === 'SelectCards') {
      task.selected = !task.selected;
      return;
    }
    this.reset();
    this.showDialogUpdateCard = false;
    // if(this.cardIsOpened) return;
    this.cardIsOpened = true;
    this.selectedCard = {};
    this.showDialogUpdateCard = true;
    const data: any = await this.taskManagementService.gettaskDetails(task.id).toPromise();
    const card = data.data;
    this.toggleTaskUrl(card);
    this.nameChanged = false;
    this.timeSpent = new Date();
    this.draffContent = [];
    if (this.valueAdded) {
      this.newComment = this.valueAdded;
      setTimeout(() => {
        this.commentEditorQuill.setSelection(this.newComment.length);
      }, 100);
    }
    // this.showDialogUpdateCard = true;
    this.showTaskDetail = true;
    card.startDate = card.startDate ? new Date(card.startDate) : null;
    card.deadline = card.deadline ? new Date(card.deadline) : null;

    this.selectedCard = { ...card };
    this.loadEstInfo(this.selectedCard.id);
    if (card.userType === 'ADMIN') {
      this.companyAdminService.findAdmin(card.userId).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.selectedCard.createdByName = res.data.fullName;
          this.selectedCard.createdBy = res.data.username;
        }
      });;
    } else if (card.userType === 'FREELANCER') {
      this.contentService.filter({ id: card.userId }).subscribe((res: any) => {
        if (res.status === 'SUCCESS' && res.data.content.length > 0) {
          this.selectedCard.createdByName = res.data.fullName;
          this.selectedCard.createdBy = res.data.content[0].githubId;
        }
      });
    } else if (card.userType === 'EMPLOYEE') {
      this.employeeService.getEmployeeByIds(card.userId).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.selectedCard.createdByName = res.data.fullName;
          this.selectedCard.createdBy = res.data.username;
        }
      });
    }

    if (card.lastModifiedBy) {
      //CHECK ADMIN
      this.companyAdminService.getByUsername(card.lastModifiedBy).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          if (res.data && res.data.id) {
            this.selectedCard.lastModifiedByName = res.data.fullName;
          } else {
            //CHECK EMPLOYEE
            this.employeeService.getByUsername({ usernames: card.lastModifiedBy }).subscribe(res => {
              const resObj: any = res;
              if (resObj.status === 'SUCCESS') {
                if (resObj.data) {
                  this.selectedCard.lastModifiedByName = resObj.data[0]?.fullName;
                } else {
                  //CHECK FREELANCER
                  this.contentService.getByGithubId(card.lastModifiedBy).subscribe((res: any) => {
                    if (res.status === 'SUCCESS') {
                      if (res.data && res.data.id) {
                        this.selectedCard.lastModifiedByName = res.data.fullName;
                      }
                    }
                  });
                }
              }
            });
          }
        }
      });
    }

    this.draffContent[card.id] = this.draffContent[card.id] ? this.draffContent[card.id] : card.content;
    // this.selectedCard.content = this.draffContent[card.id];
    this.cardNameUpdate = this.selectedCard.name;
    this.selectedCard.content = this.selectedCard.content.replace(/<img /g, `<img width="400" `);
    this.selectedCard.viewMode = card.viewMode || VISIBILITY_MODE.EVERYONE;
    this.selectedCard.accessibleUsers = card.accessibleUsers || [];
    this.selectedCard.assignedUsers = card.assignedUsers ? card.assignedUsers : [];
    this.selectedCard.watchCard = card.watchCard ? card.watchCard : [];
    this.selectedBucket = this.board.filter(x => x.id === card.bucketId)[0].name;
    this.selectedBucketId = this.board.filter(x => x.id === card.bucketId)[0];
    this.allLabel.forEach(label => {
      if (this.selectedCard.labels && this.selectedCard.labels.findIndex(x => x.id === label.id) > -1) {
        label.checked = true;
        label.labelChecked = true;
      } else {
        label.checked = false;
        label.labelChecked = false;
      }
    });
    this.allComment = [];
    this.allCommentFile = [];
    this.moveCardInfotitle = null;
    this.taskManagementService.getListComment(this.selectedCard.id).subscribe((res: any) => {
      this.allComment = res.data || [];
      this.allCommentFile = this.allComment.filter(x => (x.type === 'IMAGE_URL' || x.type === 'DOCUMENT'));
      this.allComment.forEach(e => {
        const userInfo = this.allMember.find(x => x.id === e.userId || x.name === e.createdByUsr) || {};
        e.userInfo = userInfo;
      });
      this.showAddMemberTask();
    });
    // handle data move panel
    const idxBucket = this.board.findIndex(x => x.id === this.selectedCard.bucketId);
    let nextBucket = 0;
    if (idxBucket === this.board.length - 1) {
      nextBucket = 0;
    } else {
      nextBucket = idxBucket + 1;
    }

    if (this.board.length > 1) {
      const listBucket = [...this.board] || [];
      const positionList = this.changeTargetBucket(listBucket[0]);
      this.moveCardInfo = {
        suggested: this.board[nextBucket],
        listBucket,
        positionList,
        targetBoard: this.currenBoard,
        targetBucket: listBucket[0],
        targetPosition: positionList[positionList.length - 1].value,
        includeAttachment: true
      };
    }
    this.setWatchCard();
    this.currenBoard.acceptedUsers.forEach(u => {
      if (u.id === this.currenBoard.userId && u.userType === this.currenBoard.userType) {
        u.role = 'ADMIN';
        this.memeberForEmail = [...this.selectedCard.assignedUsers, u];
      }
    });
    this.isAdminBoard = this.isBoardAdminTakeBack();
    this.getCheckList();
  }

  loadEstInfo(taskId) {
    const options: any = {};
    options.page = 0;
    options.size = 9999;
    options.taskId = taskId;
    this.statusAfter = '';
    this.ticketService.searchFreelancerTicket(options).subscribe(response => {
      const resObj: any = response;
      if (resObj.status === 'SUCCESS') {
        if (resObj.data.content && resObj.data.content.length > 0) {
          const estInfoTask = resObj.data.content[0];
          this.statusAfter = estInfoTask.status;
          this.isDisableConfirm = false;
        } else {
          this.isDisableConfirm = true;
        }
      }
    });
  }

  deleteDueDate() {
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.isPlatformAdmin || this.isCompanyAdmin || this.isBoardAdmin()) {
      this.selectedCard.deadline = null;
      this.updateCard(true);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `You don't have permissions!` });
    }
  }

  deleteStartDate() {
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.isPlatformAdmin || this.isCompanyAdmin || this.isBoardAdmin()) {
      this.selectedCard.startDate = null;
      this.updateCard(true);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `You don't have permissions!` });
    }
  }

  onChangeLabel() {
    if (!this.selectedLabel.name || this.selectedLabel.name === '') {
      this.isCheckLabelName = true;
    } else {
      this.isCheckLabelName = false;
    }
  }

  updateCard(isKeppModal?, type?) {
    console.log('updateCard this.selectedCard.id: ', this.selectedCard.id)
    console.log('updateCard isKeppModal: ', isKeppModal)
    console.log('updateCard type: ', type)
    this.loading = true;
    this.cardName = '';
    if (!isKeppModal) {
      this.closeModalTask();
    }
    const content = this.draffContent[this.selectedCard.id]
      ? this.draffContent[this.selectedCard.id] : this.draffContent[this.selectedCard.id] === null ? null
        : this.selectedCard.content;
    this.taskManagementService.updateCard(this.selectedCard.id, {
      name: this.selectedCard.name.trim(),
      content: content ? this.urlify(content.replace(/<img[^>]*>/g, '')) : ' ',
      status: '',
      deadline: this.selectedCard.deadline ? new Date(this.selectedCard.deadline).getTime() : null,
      bucketId: Number(this.selectedCard.bucketId),
      background: this.selectedCard.background,
      assignedUsers: this.selectedCard.assignedUsers ? this.selectedCard.assignedUsers : [],
      viewMode: this.selectedCard.viewMode,
      accessibleUsers: this.selectedCard.accessibleUsers,
      position: this.selectedCard.position,
      projectId: this.currenBoard.id, // need project APi to clear cache
      lastModifiedBy: this.authService.getCurrentLoggedInName(),
      watchCard: this.selectedCard.watchCard ? this.selectedCard.watchCard : [],
      startDate: this.selectedCard.startDate ? new Date(this.selectedCard.startDate).getTime() : null,
      ticketType: this.selectedCard.ticketType,
    }).subscribe((res: any) => {
      this.selectedCard.content = content;
      this.selectedCard.lastModifiedBy = this.authService.getCurrentUsername();
      this.selectedCard.lastModifiedByName = this.authService.getCurrentLoggedInName();
      if (!type) {
        this.messageService.add({
          severity: 'success',
          summary: 'Updated', detail: 'Card updated Successfully'
        });
      }
      this.sendTaskUpdateNotification(this.selectedCard.id, 'update');
      this.board.forEach(column => {
        if (column.id === this.selectedCard.bucketId) {
          const idx = column.tasks.findIndex(x => x.id === this.selectedCard.id);
          column.tasks[idx] = res.data;
          column.tasks[idx].startDate = this.selectedCard.startDate ? new Date(this.selectedCard.startDate).getTime() : '';
          column.tasks[idx].deadline = this.selectedCard.deadline ? new Date(this.selectedCard.deadline).getTime() : '';
          column.tasks[idx].labels = this.selectedCard.labels;
          column.tasks[idx].background = this.selectedCard.background;
          column.tasks[idx].viewMode = this.selectedCard.viewMode;
          column.tasks[idx].accessibleUsers = this.selectedCard.accessibleUsers;
          column.tasks[idx].assignedUsers = this.selectedCard.assignedUsers;
          column.tasks[idx].bucketName = column.name;
          this.checkDeadline();
          // this.taskManagementService.pushEventUpdateTask(this.projectId, { id: this.selectedCard.id, ...column.tasks[idx] });
          if (isKeppModal) {
            this.monitorCardUpdated()
          }
          if (column.tasksView && column.tasksView.length > idx) {
            column.tasksView[idx] = column.tasks[idx];
          }
        }
      });
      this.loading = false;
      this.allProject.forEach(p => {
        if (p.buckets) {
          p.buckets.forEach(b => {
            if (b.tasks) {
              b.tasks.forEach(t => {
                if (t.id === this.selectedCard.id) {
                  t.name = this.selectedCard.name.trim();
                }
              });
            }
          });
        }
      });
      if (type) { this.getProjectDetail(this.pId); }
    }, err => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message });
    });
  }
  changeCardname(event) {
    this.selectedCard.name = event.target.textContent;
    this.nameChanged = true;
  }
  changeCardName() {
    this.selectedCard.oldCardName = this.selectedCard.name;
    this.selectedCard.name = this.cardNameUpdate;
    this.nameChanged = true;
    this.updateCard(true);
  }
  cancelEditCardNameTg() {
    this.cardNameUpdate = this.selectedCard.name;
  }

  changeEditCardName(event) {
    this.selectedCard.name = event.target.value;
  }

  addMemberToTask(member) {
    console.log('addMemberToTask member: ', member)
    console.log('addMemberToTask assignedUsers: ', this.selectedCard.assignedUsers)
    if (this.isSuperAdmin) { return; }
    if (!this.selectedCard.assignedUsers) {
      this.selectedCard.assignedUsers = [];
    }
    if (this.selectedCard.assignedUsers.find(x => x.id === member.id)) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Member has been added to the ticket' });
      return;
    }
    if (member.userType === 'FREELANCER' && this.selectedCard.assignedUsers.find(x => x.userType === 'FREELANCER')) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'We already have someone else assigned to that card, please contact your PM for more details' });
      return;
    }
    this.selectedCard.assignedUsers.push(member);
    const payload = {
      userId: member.id,
      projectId: Number(this.projectId),
      companyId: this.authService.getCurrentCompanyId(),
      taskId: this.selectedCard.id,
      userType: this.getUserType(member.userType),
      ticketLink: window.location.toString()
    }
    this.taskManagementService.assign(payload).subscribe(res => {
      this.checkAssignedUser()
      this.monitoringDetailsService.monitorAction(
        'Added Member to Card',
        new Date(),
        {
          added_member_to_card_by: this.authService.getCurrentLoggedInName(),
          task_id: this.selectedCard.id,
          task_name: this.selectedCard.name,
          members: member.id,
          memberName: member.name,
          userType: member.userType,
          type: CommonCode.ADD_MEMBER.toString(),
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
      const memberFind = this.allEmployee.find(x => x.value === Number(member.id)
        && x.type === member.userType);
      let content = '';
      if (member.id === this.authService.getCurrentLoggedInId()) {
        content = 'joined this card';
      } else {
        content = 'added <b>' + member.name + '</b> to this card';
      }
      this.allComment.push({
        type: CommonCode.ADD_MEMBER_TASK, // add member
        typeName: 'added member',
        createdAt: new Date().getTime(),
        createdByUsr: this.authService.getCurrentLoggedInName(),
        memberId: Number(this.selectedMemberCard.name),
        memberName: memberFind?.name,
        content,
        userType: this.selectedMemberCard.userType,
        userInfo: this.allEmployee.find(x => x.value === this.authService.getCurrentLoggedInId()) || {}
      });
      this.allComment = this.allComment.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
      this.sendTaskUpdateNotification(this.selectedCard.id, 'update');
    });
  }

  addMemberToCheckListItem(member) {
    if (this.isSuperAdmin) {
      return;
    }
    if (!this.selectedCheckList) {
      return;
    }
    this.selectedCheckList.assignedUser = member;
    this.selectedCheckList.assignedUserId = member.id;
    this.taskManagementService.saveChecklist(this.selectedCheckList).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sendTaskUpdateNotification(this.selectedCard.id);
        this.taskManagementService.getCheckListByTask({ taskId: this.selectedCard.id }).subscribe((res: any) => {
          if (res.status === 'SUCCESS') {
            this.checkListData = res.data;
            this.assignMemberChecklist();
          }
        });
      }
    });
  }

  assignMemberChecklist() {
    if (this.checkListData) {
      this.checkListData.forEach(ele => {
        if (ele.assignedUserId) {
          let member = this.allMember.find(m => m.id === ele.assignedUserId);
          ele.assignedUser = member;
        }
      });
    }
  }

  getAllEmployee() {
    this.employeeService.searchEmployeeGet({}).subscribe(res => {
      this.suggestionsForBaseEmailFullList = res.data.content.map(x =>
        ({ email: x.email, fullName: x.fullName, key: x.email, id: x.id, firstName: x.firstName, lastName: x.lastName }));
      this.allEmployee = res.data.content.map(x => ({ label: x.fullName + ` (${x.username})`, value: x.id }));
    });
  }
  filterEmailProviderForTo(event) {
    this.suggestionsForBaseEmail = [];
    const query = event.query;

    (this.suggestionsForBaseEmailFullList).forEach((item) => {
      if (item && item.key) {
        if (item.key.toLowerCase().indexOf(query.toLowerCase()) >= 0
          && !this.suggestionsForBaseEmail.find(x => x.key === item.key)
          && !this.toEmail.find(x => x.email === item.key)
        ) {
          this.suggestionsForBaseEmail.push(item);
        }
      }
    });

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (event.query.match(mailformat)) {
      const newItem = {
        key: query,
        email: query
      };

      this.suggestionsForBaseEmail = [];
      this.suggestionsForBaseEmail.push(newItem);
      if (this.toEmail.indexOf(newItem) === -1) {
        this.toEmail.push(newItem);
        this.item.multiInputEL.nativeElement.value = '';
      }

    }

  }
  submitInvite() {
    const employeeIds = this.selectUserToInvite.filter(x => x.type === 'EMPLOYEE').map(x => x.value) || [];
    const adminIds = this.selectUserToInvite.filter(x => x.type === 'ADMIN').map(x => x.value) || [];
    const guestIds = this.selectUserToInvite.filter(x => x.type === 'GUEST').map(x => x.value) || [];
    const freelancerIds = this.selectUserToInvite.filter(x => x.type === 'FREELANCER').map(x => x.value) || [];
    this.toEmail = this.selectUserToInvite.map(x => x.value);
    this.showInvitePopup = false;
    this.loading = true;
    const users = this.toEmail;
    const request = {
      projectId: this.projectId,
      companyId: this.authService.getCurrentCompanyId(),
      createdByUsrId: this.authService.getCurrentLoggedInId(),
      // "employeeIds": users, // for sending email - optional
      redirectLink: window.location.origin + '/#/app/tm',  // for sending email - optional,
      userType: this.taskManagementService.getCurrentUserRole(),
      employeeIds: employeeIds,
      adminIds: adminIds,
      guestIds: guestIds,
      freelancerIds: freelancerIds
    };
    const userInviteds: any[] = [];
    const userNames: any[] = [];
    this.selectUserToInvite.forEach(user => {
      userInviteds.push(user.value);
      userNames.push(user.fullName);
    });
    this.taskManagementService.invite(request).subscribe(res => {
      this.toEmail = [];
      this.loading = false;
      this.messageService.add({ severity: 'success', summary: 'Sent', detail: 'The invite sent' });
      this.suggestionsForBaseEmailFullList = [];
      this.monitoringDetailsService.monitorAction(
        'Added Member from this board',
        new Date(),
        {
          added_member_from_card_by: this.authService.getCurrentLoggedInName(),
          task_id: this.selectedCard.id,
          task_name: this.selectedCard.name,
          members: userInviteds.toString(),
          memberName: userNames.toString(),
          type: CommonCode.ADD_MEMBER_BOARD.toString(),
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    }, err => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message });
    });
  }
  getShareLink() {
    this.loadingInviteLink = true;
    this.taskManagementService.invite({
      redirectLink: location.origin,
      projectId: this.projectId, companyId: this.authService.getCurrentCompanyId(),
      createdByUsrId: this.authService.getCurrentLoggedInId(),
      userType: this.taskManagementService.getCurrentUserRole()
    }).subscribe((res: any) => {
      this.inviteLink = location.origin + '/#/app/tm?invite-code=' + res.data;
      this.loadingInviteLink = false;
    });
  }

  async unassign() {
    if (this.selectedCard.deadline) {
      const ticketRes: any = await this.ticketService.searchFreelancerTicket({ taskId: this.selectedCard.id }).toPromise();
      if (ticketRes.status === 'SUCCESS' && ticketRes.data.content.length > 0) {
        const freelancer = ticketRes.data.content[0].freelancer;
        if (this.selectedMemberCard.id === freelancer.id) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'User who has already estimated a ticket, removing them can be a sensitive so you can only take back the ticket.' });
          this.panelUnassign.hide();
          return;
        }
      }
    }

    this.taskManagementService.unassign({
      userId: this.selectedMemberCard.id,
      projectId: Number(this.projectId),
      companyId: this.authService.getCurrentCompanyId(),
      taskId: this.selectedCard.id,
      userType: this.getUserType(this.selectedMemberCard.userType)
    }).subscribe(res => {
      this.checkAssignedUser()
      this.monitoringDetailsService.monitorAction(
        'Removed Member from Card',
        new Date(),
        {
          removed_member_from_card_by: this.authService.getCurrentLoggedInName(),
          task_id: this.selectedCard.id,
          task_name: this.selectedCard.name,
          members: this.selectedMemberCard.id,
          memberName: this.selectedMemberCard.name,
          userType: this.selectedMemberCard.userType,
          type: CommonCode.DELETE_MEMBER.toString(),
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
      let memberFind = this.allEmployee.find(x => x.value === Number(this.selectedMemberCard.id)
        && x.type === this.selectedMemberCard.userType);
      if (!memberFind) {
        memberFind = this.allMember.find(x => x.id === Number(this.selectedMemberCard.id)
          && x.userType === this.selectedMemberCard.userType);
      }

      let content = '';
      if (this.selectedMemberCard.id === this.authService.getCurrentLoggedInId()) {
        content = 'left this card';
      } else {
        content = 'removed <b>' + this.selectedMemberCard.name + '</b> from this card';
      }
      this.allComment.push({
        type: CommonCode.DEL_MEMBER_TASK, // add member
        typeName: 'removed',
        createdAt: new Date().getTime(),
        createdByUsr: this.authService.getCurrentLoggedInName(),
        memberId: Number(this.selectedMemberCard.name),
        memberName: memberFind.name,
        content,
        userType: this.selectedMemberCard.userType,
        userInfo: this.allEmployee.find(x => x.value === this.authService.getCurrentLoggedInId()) || {}
      });
      this.allComment = this.allComment.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
      this.sendTaskUpdateNotification(this.selectedCard.id, 'update');
    });
    const idx = this.selectedCard.assignedUsers.findIndex(x => x.id === this.selectedMemberCard.id);
    this.selectedCard.assignedUsers.splice(idx, 1);
    this.panelUnassign.hide();
  }
  copyLink(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.copyBtnContent = 'Copied!';
    setTimeout(() => {
      this.inviteLink = '';
      this.copyBtnContent = 'Copy';
    }, 1000);
  }

  unassignChecklist() {
    if (this.selectedCheckList) {
      this.selectedCheckList.assignedUser = null;
      this.selectedCheckList.assignedUserId = null;
      this.taskManagementService.saveChecklist(this.selectedCheckList).subscribe(() => this.sendTaskUpdateNotification(this.selectedCard.id));
    }

  }

  createLabel() {
    if (!this.selectedLabel.name || this.selectedLabel.name === '') {
      this.isCheckLabelName = true;
    } else {
      // check exist name
      const find = this.allLabel.find(i => { return i.name.toLowerCase() === this.selectedLabel.name.trim().toLowerCase() });
      if (find) {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Name already exist' });
        return;
      }
      this.isCheckLabelName = false;
      this.loadingLabel = true;
      this.taskManagementService.createLabel(this.selectedLabel.name, this.selectedLabel.color, Number(this.projectId)).subscribe((res: any) => {
        this.allLabel.push(res.data);
        this.loadingLabel = false;
        this.showLabelForm = false;
        this.monitoringDetailsService.monitorAction(
          'Added New Label',
          this.timeSpent,
          {
            added_label_by: this.authService.getCurrentLoggedInName(),
            label_name: this.selectedLabel.name,
            label_color: this.selectedLabel.color,
            type: CommonCode.ADD_LABEL.toString()
          },
          'complete',
          'Task management',
          Number(this.projectId)
        );
      }, err => {
        this.loadingLabel = false;
      });
    }
  }
  updateLabel() {
    if (!this.selectedLabel.name || this.selectedLabel.name === '') {
      this.isCheckLabelName = true;
    } else {
      // check exist name
      const find = this.allLabel.find(i => { return i.name.toLowerCase() === this.selectedLabel.name.trim().toLowerCase() });
      if (find) {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Name already exist' });
        return;
      }

      this.isCheckLabelName = false;
      this.loadingLabel = true;
      this.taskManagementService.updateLabel(this.selectedLabel.id,
        this.selectedLabel.name,
        this.selectedLabel.color,
        Number(this.projectId)).subscribe((res: any) => {
          this.loadingLabel = false;
          this.showLabelForm = false;
          const idx = this.allLabel.findIndex(x => x.id === this.selectedLabel.id);
          this.allLabel[idx] = res.data;
          this.board.forEach(bucket => {
            bucket.tasks.forEach(task => {
              const indexOfLabel = task.labels.findIndex(x => x.id === this.selectedLabel.id);
              task.labels[indexOfLabel] = res.data;
            });
          });
          this.monitoringDetailsService.monitorAction(
            'Updated Label',
            this.timeSpent,
            {
              updated_label_by: this.authService.getCurrentLoggedInName(),
              label_name: this.selectedLabel.name,
              label_color: this.selectedLabel.color,
              type: CommonCode.UPDATE_LABEL.toString()
            },
            'complete',
            'Task management',
            Number(this.projectId)
          );
        }, err => {
          this.loadingLabel = false;
          this.showLabelForm = false;
        });
    }
  }
  deleteLabel() {
    this.timeSpent = new Date();
    const idx = this.allLabel.findIndex(x => x.id === this.selectedLabel.id);
    this.loadingLabel = true;
    this.taskManagementService.deleteLabel(this.selectedLabel.id).subscribe(res => {
      this.loadingLabel = false;
      this.showLabelForm = false;
      this.allLabel.splice(idx, 1);
      this.board.forEach(bucket => {
        bucket.tasks.forEach(task => {
          const indexOfLabel = task.labels.findIndex(x => x.id === this.selectedLabel.id);
          task.labels.splice(indexOfLabel, 1);
        });
      });
      this.monitoringDetailsService.monitorAction(
        'Label deleted',
        this.timeSpent,
        {
          deleted_label_by: this.authService.getCurrentLoggedInName(),
          label_name: this.selectedLabel.name,
          label_color: this.selectedLabel.color,
          type: CommonCode.DELETE_LABEL.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    }, err => {
      this.loadingLabel = false;
    });
  }

  toogleAssignLabel(label) {
    if (label.color !== '#387964') {
      if (this.searchLabelName && this.filteredLabel.length > 0) {
        const idxFiltered = this.filteredLabel.findIndex(x => x.id === label.id);
        if (label.checked) {
          this.unassignLabel(label);
          this.filteredLabel[idxFiltered].checked = false;
          this.filteredLabel[idxFiltered].labelChecked = false;
        } else {
          this.assignLabel(label);
          this.filteredLabel[idxFiltered].checked = true;
          this.filteredLabel[idxFiltered].labelChecked = true;
        }
      } else {
        const idx = this.allLabel.findIndex(x => x.id === label.id);
        if (label.checked) {
          this.unassignLabel(label);
          this.allLabel[idx].checked = false;
          this.allLabel[idx].labelChecked = false;
        } else {
          this.assignLabel(label);
          this.allLabel[idx].checked = true;
          this.allLabel[idx].labelChecked = true;
        }
      }
    }

  }

  assignLabel(label) {
    if (this.selectedCard.labels) {
      this.selectedCard.labels.push(label);
    } else {
      this.selectedCard.labels = [label];
    }
    this.taskManagementService.assignLabelToTask(label.id, this.selectedCard.id).subscribe(res => {
      this.updateCard(true)
    });
  }

  unassignLabel(label) {
    const idx = this.selectedCard.labels.findIndex(x => x.id === label.id);
    this.selectedCard.labels.splice(idx, 1);
    this.taskManagementService.unassignLabel(label.id, this.selectedCard.id).subscribe(res => {
      this.updateCard(true)
    });
  }

  openAddNewLabel() {
    this.selectedLabel = { color: this.listLabelColor[1] };
    this.showLabelForm = true;
    this.timeSpent = new Date();
  }
  openEditLabel(label) {
    this.selectedLabel = { ...label };
    this.showLabelForm = true;
  }
  onSelectFile(event) {
    if (event.target.files.length === 0) {
      return;
    }
    const files = event.target.files;
    console.log(files);

    files.forEach(file => {
      this.fileType = null;
      const reader = new FileReader();
      this.fileType = file;
      reader.readAsDataURL(file);
      if (this.mimeType(file)) {
        if (file.size > 10 * 1024 * 1024) {
          return this.messageService.add({
            severity: 'info', summary: 'Information',
            detail: `File is too large to upload`
          });
        }
      }
      if (this.mimeType(this.fileType)) {
        this.thumbnail = null;
        this.uploadFileToComment(file);
      } else {
        reader.onload = () => {
          this.documentsService.uploadAnResize(reader.result).then((res) => {
            this.thumbnail = this.dataURIToBlob(res);
            this.uploadFileToComment(file);
          });
        };
      }
    });
  }

  async uploadFileToComment(file, type?: string) {
    const timeSpent = new Date();
    let fileName: any;
    if (this.mimeType(this.fileType)) {
      fileName = file.name;
    } else {
      // fileName = type ? file.name : file.name.replace(/ /g, '').replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_')
      fileName = type ? file.name : file.name.replace(/ /g, '_');
    }
    this.loadingUpload = true;
    const resObj = await this.documentsService.uploadFileWithThumbnail(file, this.thumbnail, 'task-management', this.projectId, 'task-management', fileName).toPromise();
    if (resObj.status === 'SUCCESS' && resObj.data.fileUrl) {
      await this.uploadFileToServer(resObj.data, timeSpent, type, resObj.data.id);
      this.loadingUpload = false;
    }
    // else {
    //   await this.documentsService.uploadFileWithFileName(file, 'task-management', this.projectId, 'task-management', fileName).subscribe((res: any) => {
    //     if (res.status === 'SUCCESS') {
    //       this.uploadFileToServer(res.data, timeSpent, type)
    //     }
    //   }, err => {
    //     this.loadingUpload = false;
    //   });
    // }
  }

  async uploadFileToServer(data, timeSpent, type, Id?) {
    const url = data.fileThumbnailUrl ? data.fileThumbnailUrl : data.fileUrl;
    if (this.mimeType(this.fileType)) {
      await this.addAttachmentComment(data.fileUrl, 'DOCUMENT', Id);
    } else {
      await this.addAttachmentComment(url, 'IMAGE_URL', Id);
      this.makeCover({ content: url }, 'type');
    }
    this.monitoringDetailsService.monitorAction(
      'File uploaded',
      timeSpent,
      {
        uploaded_file_by: this.authService.getCurrentLoggedInName(),
        file_name: data.id,
        file_url: data.fileUrl,
        type: CommonCode.ADD_IMAGE_CARD.toString()
      },
      'complete',
      'Task management',
      Number(this.projectId)
    );
    this.updateCard(true, type);
    this.loadingUpload = false;
  }

  async addAttachmentComment(content, type, documentId?) {
    const timeSpent = new Date();
    const action = (type === 'IMAGE_URL' || type === 'DOCUMENT') ? 'File uploaded' : 'New Comment added';
    type = this.commentType ? this.commentType : type;
    if (type === 'TEXT') {
      content = this.urlify(content);
    }
    if (type === 'COMMENT_ON_IMAGE') {
      content = this.urlify(content, 'rply');
    }
    const request = {
      content,
      type,
      createdByUsr: this.authService.getCurrentLoggedInName(),
      userId: this.authService.getCurrentLoggedInId(),
      createdAt: new Date(),
      projectId: this.currenBoard.id,
      ticketLink: window.location.toString(),
      documentId: documentId ? documentId : null
    };

    this.loadingUpload = true;
    const res: any = await this.taskManagementService.addComment(request, this.selectedCard.id).toPromise();
    this.commentType = '';
    if (this.valueAdded) {
      this.newComment = this.valueAdded;
      setTimeout(() => {
        this.commentEditorQuill.setSelection(this.newComment.length);
      }, 100);
    }
    this.loadingUpload = false;
    this.allComment.unshift(res.data);
    this.allComment.forEach(e => {
      const userInfo = this.allMember.find(x => x.id === e.userId || x.name === e.createdByUsr) || {};
      e.userInfo = userInfo;
    });
    this.board.forEach(column => {
      if (column.id === this.selectedCard.bucketId) {
        const idx = column.tasks.findIndex(x => x.id === this.selectedCard.id);
        column.tasks[idx].commentsCount = Number(column.tasks[idx].commentsCount) + 1;
      }
    });
    this.allCommentFile = this.allComment.filter(x => (x.type === 'IMAGE_URL' || x.type === 'DOCUMENT'));
    this.monitoringDetailsService.monitorAction(
      action,
      timeSpent,
      {
        added_comment_by: this.authService.getCurrentLoggedInName(),
        type: (type === 'IMAGE_URL' || type === 'DOCUMENT') ? CommonCode.ADD_IMAGE_CARD.toString() : CommonCode.ADD_COMMENT_CARD.toString()
      },
      'complete',
      'Task management',
      Number(this.projectId)
    );
    this.count = 0;
    this.dataUrls = [];
    this.commentClicked = false;
  }

  addComment(content, type, documentId?) {
    if (this.commentClicked) return;
    this.commentClicked = true;
    const timeSpent = new Date();
    const action = (type === 'IMAGE_URL' || type === 'DOCUMENT') ? 'File uploaded' : 'New Comment added';
    type = this.commentType ? this.commentType : type;
    if (type === 'TEXT') {
      content = this.urlify(content);
    }
    if (type === 'COMMENT_ON_IMAGE') {
      content = this.urlify(content, 'rply');
    }
    const request = {
      content,
      type,
      createdByUsr: this.authService.getCurrentLoggedInName(),
      userId: this.authService.getCurrentLoggedInId(),
      createdAt: new Date(),
      projectId: this.currenBoard.id,
      ticketLink: window.location.toString(),
      documentId: documentId ? documentId : null
    };

    this.loadingUpload = true;
    this.taskManagementService.addComment(request, this.selectedCard.id).subscribe((res: any) => {
      this.sendTaskUpdateNotification(this.selectedCard.id, 'update');
      this.notifyMentionEmployee(this.newComment);
      this.commentType = '';
      this.newComment = '';
      this.loadingUpload = false;
      this.allComment.unshift(res.data);
      this.allComment.forEach(e => {
        const userInfo = this.allMember.find(x => x.id === e.userId || x.name === e.createdByUsr) || {};
        e.userInfo = userInfo;
      });
      this.board.forEach(column => {
        if (column.id === this.selectedCard.bucketId) {
          const idx = column.tasks.findIndex(x => x.id === this.selectedCard.id);
          column.tasks[idx].commentsCount = Number(column.tasks[idx].commentsCount) + 1;
        }
      });
      this.allCommentFile = this.allComment.filter(x => (x.type === 'IMAGE_URL' || x.type === 'DOCUMENT'));
      this.monitoringDetailsService.monitorAction(
        action,
        timeSpent,
        {
          added_comment_by: this.authService.getCurrentLoggedInName(),
          type: (type === 'IMAGE_URL' || type === 'DOCUMENT') ? CommonCode.ADD_IMAGE_CARD.toString() : CommonCode.ADD_COMMENT_CARD.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
      this.count = 0;
      this.dataUrls = [];
      this.commentClicked = false;
      setTimeout(() => {
        this.commentEditorQuill.setSelection(this.newComment.length);
      }, 100);
    }, err => { this.commentClicked = false });
    if (type === 'TEXT') { this.valueAdded = null; }
    // this.updateCard(true);
  }

  updateComment(comment, type?) {
    if (!type) {
      if (comment.type || comment.type === 'TEXT') {
        comment.content = this.urlify(comment.content);
      }
      const idx = this.allComment.findIndex(x => x.id === comment.id);
      this.allComment[idx] = comment;
    }
    this.taskManagementService.updateComment(comment, this.selectedCard.id).subscribe((res: any) => {
      this.sendTaskUpdateNotification(this.selectedCard.id, 'update');
      this.monitoringDetailsService.monitorAction(
        'Comment updated',
        this.timeSpent,
        {
          updated_comment_by: this.authService.getCurrentLoggedInName(),
          type: CommonCode.UPDATE_COMMENT_CARD.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    });
  }

  deleteComment(comment) {
    const timeSpent = new Date();
    const action = comment.type === 'IMAGE_URL' ? 'File deleted' : 'Comment deleted';
    const idx = this.allComment.findIndex(x => x.id === comment.id);
    this.allComment.splice(idx, 1);
    let fileName = '';
    if (comment.type === 'IMAGE_URL') {
      fileName = comment.content.split('/').pop();
    }
    this.taskManagementService.deleteComment(comment.id, this.selectedCard.id, fileName, comment.type).subscribe((res: any) => {
      this.sendTaskUpdateNotification(this.selectedCard.id, 'update');
      this.board.forEach(column => {
        if (column.id === this.selectedCard.bucketId) {
          const idx = column.tasks.findIndex(x => x.id === this.selectedCard.id);
          column.tasks[idx].commentsCount = Number(column.tasks[idx].commentsCount) - 1;
        }
      });
      this.monitoringDetailsService.monitorAction(
        action,
        timeSpent,
        {
          deleted_comment_by: this.authService.getCurrentLoggedInName(),
          type: comment.type === 'IMAGE_URL' ? CommonCode.DELETE_IMAGE_CARD.toString() : CommonCode.DELETE_COMMENT_CARD.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    });
    this.allCommentFile = this.allComment.filter(x => (x.type === 'IMAGE_URL' || x.type === 'DOCUMENT'));
  }
  replyComment(comment, type?: string) {
    this.timeSpent = new Date();
    this.commentType = '';
    if (type) {
      if (this.count === 0 && this.newComment) {
        this.dataUrls.push(this.newComment);
      }
      this.count = this.count + 1;
      this.dataUrls = [];
      this.dataUrls.push(`<span>${comment.content}</span><br>`);
      if (this.newComment) {
          this.newComment += `${this.dataUrls.join('').toString()}`;
      } else {
          this.newComment = `${this.dataUrls.join('').toString()}`;
      }
      this.commentType = 'COMMENT_ON_IMAGE';
    } else {
      if (comment.type === 'IMAGE_URL') {
        this.newComment = `${comment.content}`;
        this.commentType = 'COMMENT_ON_IMAGE';
      } else {
        const tag = `<strong>@${comment.createdByUsr.replace(/ /g, '')}</strong>`;
        if (!this.newComment.includes(tag)) {
          this.newComment += tag;
          this.newComment += '&nbsp;';
        }
      }
    }
  }
  filesDropped(event) {
    event.forEach(file => {
      this.fileType = null;
      const reader = new FileReader();
      this.fileType = file.file;
      reader.readAsDataURL(file.file);
      if (file.file.name.includes('.zip')) {
        if (file.file.size > 10 * 1024 * 1024) {
          return this.messageService.add({
            severity: 'info', summary: 'Information',
            detail: `File is too large to upload`
          });
        }
      }
      if (!this.fileSizeCheck(file.file)) {
        return;
      }
      if (this.mimeType(this.fileType)) {
        this.thumbnail = null;
        this.uploadFileToComment(file.file);
      } else {
        reader.onload = () => {
          this.documentsService.uploadAnResize(reader.result).then((res) => {
            this.thumbnail = this.dataURIToBlob(res);
            this.uploadFileToComment(file.file);
          });
        };
      }
    });
  }
  toogleTaskDetail() {
    this.showTaskDetail = !this.showTaskDetail;
  }
  onEditorInit(event) {
    event.editor.root.focus();
    this.editor = event.editor;
  }

  moveTask(data, panel, selectedBucket, isSuggested?) {
    let moveTicketToOther: boolean = false;
    if (isSuggested) {
      data.targetBucket = data.suggested;
    }

    const targetBucketIdx = this.moveCardInfo.targetBoard.buckets.findIndex(x => x.id === data.targetBucket.id);
    // if (this.moveCardInfo.targetBoard.id == this.currenBoard.id) {
    //   this.selectedCard.bucketId = data.targetBucket.id;
    // }
    const sourceBucketIdx = this.board.findIndex(x => x.id === this.selectedCard.bucketId);
    const indexOfTaskOldBucket = this.moveCardInfo.targetBoard.buckets[targetBucketIdx].tasks.findIndex(x => x.id === this.selectedCard.id);

    this.board[sourceBucketIdx].tasks.splice(indexOfTaskOldBucket, 1);
    this.reloadTaskView(this.board[sourceBucketIdx]);
    this.selectedCard.bucketId = data.targetBucket.id;
    this.moveCardInfo.targetBoard.buckets[targetBucketIdx].tasks.splice(data.targetPosition - 1, 0, this.selectedCard);
    this.reloadTaskView(this.moveCardInfo.targetBoard.buckets[targetBucketIdx]);
    // update to api
    if (this.moveCardInfo.targetBoard.id != this.currenBoard.id) {
      moveTicketToOther = true;
    }
    const movedIds = this.moveCardInfo.targetBoard.buckets[targetBucketIdx].tasks.map(x => x.id)
    this.sendBucketTaskOrderChageNotification(this.selectedCard.bucketId, movedIds);
    if (data.targetBucket.projectId !== this.projectId) {
      this.sendBucketUpdateNotification(data.targetBucket.id, data.targetBucket.projectId);
    }
    
    this.taskManagementService.updateTaskOrder(
      data.targetBucket.id,
      {
        ids: movedIds,
        updatedBy: this.authService.getCurrentLoggedInName(),
        taskId: this.selectedCard.id,
        moveTicketToOther: moveTicketToOther,
        newBucketId: data.targetBucket.id,
        newBoardId: this.moveCardInfo.targetBoard.id
      }).subscribe((res: any) => {
        this.messageService.add({ severity: 'success', summary: 'Moved', detail: `This task moved to ${data.targetBucket.name}` });
        this.monitoringDetailsService.monitorAction(
          'Moved Card',
          new Date(),
          {
            moved_card_by: this.authService.getCurrentLoggedInName(),
            bucket_prev: data.targetBucket.name,
            bucket_next: selectedBucket,
            task_id: data.id,
            task_name: data.name,
            type: CommonCode.MOVE_CARD.toString()
          },
          'complete',
          'Task management',
          Number(this.projectId)
        );

        if (this.moveCardInfo.targetBoard.id == this.currenBoard.id) {
          this.selectedBucket = this.board.find(x => x.id === data.targetBucket.id)?.name;
          this.toggleTaskUrl(res.data)
        } else {
          if (res.data) {
            this.closeModalTask();
            const pos = res.data;
            const name = pos.name;
            let slugName = name.replace(/ /g, '-');
            slugName = slugName.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
            let slug = `${pos.internalId}-${slugName}`;
            slug = slug.replace(/--+/g, '-');
            const enc = this.taskManagementService.encodeId(data.targetBucket.projectId).replace(/\=/gi, '');
            const url = `${window.location.origin}/#/app/tm/${enc}?t=${slug}`
            window.open(url)
            this.router.navigateByUrl(url);
          }
        }
      });

    // hide modal
    // if (data.targetBoard.id !== this.projectId) {
    //   this.closeModalTask();
    // } else {
    //   this.selectedBucket = this.board.find(x => x.id === data.targetBucket.id)?.name;
    // }

    panel.hide();
  }

  changeTargetBucket(bucket) {
    const positionList = [];
    for (let i = 0; i < bucket.tasks.length; i++) {
      positionList.push({ value: i + 1, label: i + 1 });
    }
    positionList.push(
      {
        value: bucket.tasks.length + 1,
        label: bucket.tasks.length + 1
      }
    );
    this.moveCardInfo.positionList = positionList;
    this.moveCardInfo.targetPosition = positionList[positionList.length - 1].value;

    return positionList;
  }
  onPickBackgroundImage(event) {
    this.background = event.background;
    this.taskManagementService.updateProjectBackground({
      background: event.background,
      backgroundType: 'IMAGE',
      name: this.boardName
    }, this.projectId).subscribe(res => {
      this.sendProjectBackgroundNotification(this.projectId, this.background);
    });
  }
  toggleTaskUrl(task?) {
    let slug = '';
    if (task) {
      const name = task.name ? task.name : task.taskName;
      let slugName = name.replace(/ /g, '-');
      slugName = slugName.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
      slug = `${task.internalId}-${slugName}`;
      slug = slug.replace(/--+/g, '-');
    }
    if (slug) {
      const queryParams = { t: slug };
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge',
        });
    } else {
      // this.router.navigate(
      //   [],
      //   {
      //     relativeTo: this.activatedRoute,
      //     queryParams: {}
      //   });
    }
  }
  closeModalTask() {
    if (this.draffContent[this.selectedCard.id] !== this.selectedCard.content) {
      this.updateCardAuto();
    }

    if (this.newComment) {
      this.addComment(this.newComment, 'TEXT');
    }

    // this.cardIsOpened = false;
    this.toggleTaskUrl();
    this.showDialogUpdateCard = false;
    this.monitoringDetailsService.monitorAction(
      'View Card',
      this.timeSpent,
      {
        viewed_card_by: this.authService.getCurrentLoggedInName(),
        task_id: this.selectedCard.id,
        task_name: this.selectedCard.name,
        bucket_name: 'selectedCard',
        type: CommonCode.VIEW_CARD.toString()
      },
      'complete',
      'Task management',
      Number(this.projectId)
    );
    this.reset();
  }
  openSlugLink() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.viewBoardError = false;
      this.cardDeletedHistory = null;
      const taskUrl = params.t;
      if (taskUrl) {
        const taskUrlOnlyLetter = taskUrl.slice(taskUrl.indexOf('-') + 1).toLowerCase().replace(/[^a-zA-Z0-9]+/g, '');
        const internalId = taskUrl.split('-')[0];
        let task;
        for (let bucket of this.board) {
          task = bucket.tasks.find(x => x.internalId.toString() === internalId.toString() && (x.name && taskUrlOnlyLetter === x.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '')));
          if (task) {
            break;
          }
        }
        if (task) {
          this.openEditCardDialog(task);
        } else {
          this.taskManagementService.searchTaskHistory({
            taskAction: 'MOVED',
            boardId: this.projectId,
            internalId: internalId,
            checkCurrentName: true,
            sortField: 'createdAt',
            sortOrder: 'DESC'
          }).subscribe((res: any) => {
            let exist = false;
            if (res.status === 'SUCCESS') {
              const content: any[] = res.data.content;
              const histories = content.filter(c => c.internalId === Number(internalId));
              if (histories && histories.length > 0) {
                let prev = histories.find(x => taskUrlOnlyLetter === x.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, ''));
                if (prev && (prev.newCurrentName || prev.name)) {
                  const name = prev.newCurrentName ? prev.newCurrentName : prev.name;
                  let slugName = name.replace(/ /g, '-');
                  slugName = slugName.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
                  let slug = `${prev.newInternalId}-${slugName}`;
                  slug = slug.replace(/--+/g, '-');
                  exist = true;
                  const newUrl = '/app/tm/' + this.taskManagementService.encodeId(prev.newBoardId).replace(/\=/gi, '') + '?t=' + slug;
                  console.log(newUrl);
                  this.router.navigateByUrl(newUrl);
                }

              }
            }

            if (!exist) {
              this.monitoringDetailsService.getPageWithFilter({
                companyId: this.authService.getCurrentCompanyId(),
                projectId: this.projectId,
                action: 'Card Name Updated'
              }).subscribe((res: any) => {
                console.log(res);
                if (res.status === 'SUCCESS') {
                  const content: any[] = res.data.content;
                  const histories = content.filter(c => c.arguments && c.arguments.old_task_name_only_letter === taskUrlOnlyLetter);
                  if (histories && histories.length > 0) {
                    const name = histories[0].arguments.task_name;
                    let slugName = name.replace(/ /g, '-');
                    slugName = slugName.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
                    let slug = `${internalId}-${slugName}`;
                    slug = slug.replace(/--+/g, '-');
                    const queryParams = { t: slug };
                    this.router.navigate(
                      [],
                      {
                        relativeTo: this.activatedRoute,
                        queryParams,
                        queryParamsHandling: 'merge',
                      });
                    exist = true;
                  }
                }
                if (!exist) {
                  this.taskManagementService.searchTaskHistory({
                    taskAction: 'DELETED',
                    boardId: this.projectId,
                    internalId: internalId
                  }).subscribe((res: any) => {
                    if (res.status === 'SUCCESS' && res.data.content.length > 0) {
                      const task = res.data.content[0];
                      if (task.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '') === taskUrlOnlyLetter) {
                        this.cardDeletedHistory = task;
                        this.viewBoardError = true;
                      }
                    }
                  })
                }
              })
            }
          });
        }
      }
    });
  }
  makeCover(comment, type?: string) {
    const url = comment.content;
    this.selectedCard.background = url;
  }

  removeCover() {
    this.selectedCard.background = '';
    this.updateCard(true);
  }

  loadingProject = false;
  getAllProject() {
    this.loading = true;
    this.loadingProject = true;
    this.taskManagementService.getAllProject().subscribe((res: any) => {
      this.loading = false;
      this.loadingProject = false;
      this.allProject = res.data.content;
      console.log(this.allProject);
      setTimeout(() => {
        this.taskManagementTopBarComponent.onSerchTask();
      }, 100);
      this.cdref.detectChanges();
    }, err => {
      this.loading = false;
      this.loadingProject = false;
    });
    // this.taskManagementService.getAllProjectByGuest().subscribe((res: any) => {
    //   this.loading = false;
    //   this.allProject.push(...res.data.content);
    // }, err => this.loading = false);

  }
  checkRemoveCard($event, member, type?: string) {
    console.log(member);
    const loggedInUserId = this.authService.getCurrentLoggedInId();
    const isAdminRole = this.authService.adminRole();
    if (loggedInUserId === member.id) {
      this.opLeaveBoard.toggle($event);
    }
    if (this.isBoardAdmin() || this.isSuperAdmin || this.isCompanyAdmin) {
      if (member.id === loggedInUserId && member.role === 'ADMIN' && member.userType === this.taskManagementService.getUserType() && !this.isSuperAdmin) {
        return;
      }
      this.adminAccess.toggle($event);
    }

    if (member && type) {
      this.selectedMemberCard = member;
    }

  }
  removeFromBoard() {
    const allMember = this.allMember.filter(x => x.projectUserAssignmentId !== this.selectedMemberCard.projectUserAssignmentId);
    const admin = allMember.find(u => u.role === 'ADMIN');
    if (this.selectedMemberCard.role === 'ADMIN' && !admin) {
      this.messageService.add({
        severity: 'info', summary: 'Information',
        detail: `Please choose other member as an Admin before removing this user`
      });
      return;
    }
    this.adminAccess.hide();
    this.opRemoveCard.hide();
    this.taskManagementService.removeBoardMember(this.selectedMemberCard.projectUserAssignmentId).subscribe(res => {
      this.taskManagementService.saveFirebaseNotification(`/task-management/${this.projectId}/assigned-memeber-update`, {
        projectId: this.projectId,
        updatedByUser: this.authService.getUserInfo().username,
        lastUpdateTime: new Date().getTime(),
        trackingSession: this.trackingSession
      }).subscribe();
      this.allMember = this.allMember.filter(x => x.projectUserAssignmentId !== this.selectedMemberCard.projectUserAssignmentId);
      this.invitedUsers = this.invitedUsers.filter(x => x.projectUserAssignmentId !== this.selectedMemberCard.projectUserAssignmentId);
      const loggedInUserId = this.authService.getCurrentLoggedInId();
      if (this.selectedMemberCard.id === loggedInUserId && this.selectedMemberCard.userName === this.authService.getCurrentUsername()) {
        this.router.navigate(['app/tm']);
      }
    });
  }
  handleVisibleMode() {
    const currentUserId = this.authService.getCurrentLoggedInId();
    const isAdminRole = this.authService.adminRole();
    this.board.forEach(board => {
      board.tasks.forEach(task => {

        if (isAdminRole) {
          task.hidden = false;
        } else {
          switch (task.viewMode) {
            case VISIBILITY_MODE.EVERYONE:
              task.hidden = false;
              break;

            case VISIBILITY_MODE.ASSIGN_ONLY:
              const isAssign = task.accessibleUsers && task.assignedUsers.find(x => x.id === currentUserId);
              if (isAssign) {
                task.hidden = false;
              } else {
                task.hidden = true;
              }
              break;
            case VISIBILITY_MODE.PERSONALIZE:
              const isAdded = task.accessibleUsers && task.accessibleUsers.find(x => x.id === currentUserId);
              if (isAdded) {
                task.hidden = false;
              } else {
                task.hidden = true;
              }
              break;
            default:
              task.hidden = false;
              break;
          }
        }
      });
    });
  }
  changeTargetBoard(board) {
    // handle data move panel

    const idxBucket = this.board.findIndex(x => x.id === this.selectedCard.bucketId);
    let nextBucket = 0;
    if (idxBucket === this.board.length - 1) {
      nextBucket = 0;
    } else {
      nextBucket = idxBucket + 1;
    }

    if (this.board.length > 1) {
      const listBucket = board.buckets.filter(x => x.id !== this.selectedCard.bucketId) || [];
      if (listBucket.length > 0) {
        const positionList = this.changeTargetBucket(listBucket[0]);
        const includeAttachment = this.moveCardInfo.includeAttachment;
        this.moveCardInfo = {
          suggested: this.board[nextBucket],
          listBucket,
          positionList,
          targetBoard: board,
          targetBucket: listBucket[0],
          targetPosition: positionList[positionList.length - 1].value,
          includeAttachment: includeAttachment
        };
      }
    }
  }
  checkOnClickImage(event) {

    if (event.target.localName === 'img') {
      this.previewImage = event.target.currentSrc;

    }
  }
  monitorAddTaskDescription(selectedCard) {
    if (!this.selectedCard.content || this.selectedCard.content === '') {
      this.monitoringDetailsService.monitorAction(
        'Description Added in Card',
        this.timeSpent,
        {
          desciption_added_by: this.authService.getCurrentLoggedInName(),
          task_id: selectedCard.id,
          task_name: selectedCard.name,
          bucket_name: 'selectedCard',
          type: CommonCode.ADD_DESCRIPTION_CARD.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    } else {
      this.monitoringDetailsService.monitorAction(
        'Description Updated in Card',
        this.timeSpent,
        {
          desciption_added_by: this.authService.getCurrentLoggedInName(),
          task_id: selectedCard.id,
          task_name: selectedCard.name,
          bucket_name: 'selectedCard',
          type: CommonCode.ADD_DESCRIPTION_CARD.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
    }
    this.notifyMentionEmployee(this.selectedCard.content);
  }

  getUserType(type) {
    switch (type) {
      case 'ADMIN': return 0;
      case 'EMPLOYEE': return 1;
      case 'FREELANCER': return 2;
      case 'GUEST': return 3;
    }
  }

  getAllUsersList(projectId) {
    this.taskManagementService.fetchAllUsers({ projectId: projectId }).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.suggestionsForBaseEmailFullList = res.data.map(x => ({
          email: x.email,
          fullName: x.fullName, key: x.email, id: x.id, firstName: x.firstName, lastName: x.lastName
        }));
        this.allEmployee = res.data.map(x => ({
          name: x.fullName + ` (${x.userName})`,
          type: x.userType,
          value: x.id,
          fullName: x.fullName,
          userName: x.userName
        }));
        this.showAddMemberTask();
      }
    });
  }

  openInviteModal() {
    const access = 'invite members for this board';
    if (this.isBoardAdmin() || this.isBoardSubAdmin() || this.isSuperAdmin || this.isCompanyAdmin) {
      this.inviteLink = '';
      this.selectUserToInvite = [];
      this.showInvitePopup = true;
    } else {
      if (this.countErrorDontPermission === 0) {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: `You don't have permission to invite members for this board`
        });
        this.countErrorDontPermission = this.countErrorDontPermission + 1;
        this.countDownTime = 60;
        this.countDownTimeErrorDontPermission();
        return;
      }
    }
  }

  countDownTimeErrorDontPermission() {
    const inerval = setInterval(() => {
      this.countDownTime--;
      if (this.countDownTime === 0) {
        this.countErrorDontPermission = 0;
        // console.log('countDownTimeErrorDontPermission countErrorDontPermission: ', this.countErrorDontPermission)
        clearInterval(inerval);
      }
      // console.log('countDownTimeErrorDontPermission countDownTime: ', this.countDownTime)
    }, 50);
  }

  openAddColumnModal() {
    const access = 'add a new Bucket for this board';
    // if (this.isBoardAdmin(access)) {
    this.showDialogAddColumn = true;
    // }
    this.timeSpent = new Date();
  }

  isBoardAdminTakeBack() {
    if (this.isAdminAccess() || this.isBoardSubAdmin()) {
      return true;
    } else {
      return false;
    }
  }

  isBoardAdmin(type?: any) {
    if (this.isAdminAccess() || this.isBoardSubAdmin()) {
      return true;
    } else {
      if (type) {
        return this.messageService.add({ severity: 'error', summary: 'Error', detail: `You don't have permission to ${type}` });
      }
      return false;
    }

    // if ((this.currenBoard && this.currenBoard.userId === this.authService.getCurrentLoggedInId()
    //   && this.currenBoard.createdByUsr == this.authService.getCurrentUsername())
    //   || this.isBoardSubAdmin()) {
    //   return true;
    // } else {
    //   if (type) {
    //     return this.messageService.add({ severity: 'error', summary: 'Error', detail: `You don't have permission to ${type}` });
    //   }
    //   return;
    // }
  }

  isAdminAccess() {
    if (this.authService.isEmployeeRole()) {
      const isAdmin = this.allEmployeeUser.find(user => user.id === this.authService.getCurrentLoggedInId());
      if (isAdmin && isAdmin.role === 'ADMIN') {
        return true;
      } else { return false; }
    }
    const isBoardAdmin = this.allMember.find(user => (user.id === this.authService.getCurrentLoggedInId()
      && user.userName.toLowerCase() === this.authService.getCurrentUsername().toLowerCase() && user.userType === this.authService.getUserType()));
    if (isBoardAdmin && isBoardAdmin.role === 'ADMIN') {
      return true;
    } else { return false; }
  }

  isBoardSubAdmin() {
    const isBoardSubAdmin = this.allMember.find(user =>
    (user.id === this.authService.getCurrentLoggedInId()
      && user.userName.toLowerCase() === this.authService.getCurrentUsername().toLowerCase() && user.userType === this.taskManagementService.getUserType()));
    if (isBoardSubAdmin && isBoardSubAdmin.role === 'SUB_ADMIN') {
      return true;
    } else { return false; }
  }

  changeMemberRole(role: number, type) {
    const payLoad = {
      projectUserAssignmentId: this.selectedMemberCard.projectUserAssignmentId,
      role: role
    };
    this.taskManagementService.changeUserRole(payLoad).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Permission changed for the member`
        });
        this.allMember.forEach(u => {
          if (u.projectUserAssignmentId === res.data.id) {
            u.role = type;
          }
        });
        this.adminAccess.hide();
      }
    });
  }
  duplicateCard(data, panel) {
    this.loading = true;
    //this.closeModalTask();
    this.taskManagementService.duplicateTask(this.selectedCard.id, {
      name: this.moveCardInfotitle,
      bucketId: data.targetBucket.id,
      keepComments: data.includeAttachment,
      userId: this.authService.getUserInfo().adminId,
      userType: this.taskManagementService.getUserType()
    }).subscribe((res: any) => {
      if (res.status !== 'SUCCESS') {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: res.message });
        return;
      }
      const resObj: any = res;
      this.loading = false;
      this.monitoringDetailsService.monitorAction(
        'Duplicated Card',
        new Date(),
        {
          card_duplicated_by: this.authService.getCurrentLoggedInName(),
          task_id: resObj.data.id,
          task_name: resObj.data.name,
          bucket_name: data.targetBucket.name,
          type: CommonCode.ADD_CARD.toString()
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
      setTimeout(() => {
        this.openDuplicate(data.targetBoard.id, resObj.data.internalId, resObj.data.name);
      }, 500);
      this.messageService.add({ severity: 'success', summary: 'Duplicated', detail: `This task duplicated to ${data.targetBucket.name}` });

      // this.moveCardInfo.targetBoard.buckets[targetBucketIdx].tasks.splice(data.targetPosition - 1, 0, this.selectedCard);
      let taskIds = [];
      if (data.targetBoard.id === this.projectId) {
        const columnIdx = this.board.findIndex(column => column.id === data.targetBucket.id);
        this.board[columnIdx].tasks.splice(data.targetPosition - 1, 0, { ...res.data, assignedUsers: [] });
        this.reloadTaskView(this.board[columnIdx]);
        taskIds = this.board[columnIdx].tasks.map(x => x.id);
      } else {
        taskIds = data.targetBucket.tasks.map(x => x.id);
        taskIds.splice(data.targetPosition - 1, 0, res.data.id);
      }
      this.sendBucketTaskOrderChageNotification(data.targetBucket.id, taskIds);
      this.taskManagementService.updateTaskOrder(
        data.targetBucket.id,
        {
          ids: taskIds,
          updatedBy: this.authService.getCurrentLoggedInName()
        }).subscribe(res => {
          this.getProjectDetail(this.projectId);
        });
    }, error => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: error.error.status, detail: error.error.message });
    });
    panel.hide();
  }
  onPaste(event, type) {
    console.log(event);
    const text = event.clipboardData.getData('text/plain');
    if (text) {
      event.preventDefault();
      const editor = type === 'description' ? this.descriptionEditor.getQuill() : this.commentEditor.getQuill();
      const range = editor.getSelection(true);
      const delta = new Delta()
        .retain(range.index)
        .delete(range.length)
        .insert(text);
      const index = text.length + range.index;
      const length = 0;
      this.newComment = text;
      editor.updateContents(delta, 'silent');
      editor.setSelection(index, length, 'silent');
    }
  }
  onTextChange(event, type?) {
    if (event.delta.ops[0].delete === 1 && event.htmlValue === null) {
      this.showFocuOnComment = true;
    }
    if (type) {
      if (event.textValue && event.htmlValue.includes('<img src=')) {
        this.descriptionAdded = event.htmlValue.replace(/<img[^>]*>/g, '');
      }
    }
    if (!type) {
      if (event.textValue && event.htmlValue.includes('<img src=')) {
        this.valueAdded = event.htmlValue.replace(/<img[^>]*>/g, '');
      }
    }
    event.delta.map((item) => {
      if (item?.insert?.image) {
        if (item.insert.image.includes('https://livestore')) {
          this.addComment(item.insert.image, 'IMAGE_URL');
          // this.updateCard(true, 'type');
          return;
        }
        const file = this.dataURIToBlob(item.insert.image);
        if (file && this.fileSizeCheck(file)) {
          this.documentsService.uploadAnResize(item.insert.image).then((res) => {
            this.thumbnail = this.dataURIToBlob(res);
            this.uploadFileToComment(file, 'paste');
          });
        }
      }
    });

    // if (this.pasteEvent) {
    //   setTimeout(() => {
    //     if (type) {
    //       if (event.delta.ops.length > 1) {
    //         const retain = event.delta.ops[0].retain;
    //         const insert = event.delta.ops[1].insert;

    //         if (retain < event.htmlValue.length) {
    //           this.descriptionEditor.getQuill().setSelection(retain + (insert?.length || 0));
    //         } else {
    //           this.descriptionEditor.getQuill().setSelection(retain);
    //         }
    //       } else if (event.delta.ops[0].insert) {
    //         const insert = event.delta.ops[0].insert;
    //         this.descriptionEditor.getQuill().setSelection((insert?.length || 0));
    //       }
    //     } else {
    //       if (event.delta.ops.length > 1) {
    //         const retain = event.delta.ops[0].retain;
    //         const insert = event.delta.ops[1].insert;

    //         if (retain < event.htmlValue.length) {
    //           this.commentEditor.getQuill().setSelection(retain + (insert?.length || 0));
    //         } else {
    //           this.commentEditor.getQuill().setSelection(retain);
    //         }
    //       } else if (event.delta.ops[0].insert) {
    //         const insert = event.delta.ops[0].insert;
    //         this.commentEditor.getQuill().setSelection((insert?.length || 0));
    //       }
    //     }
    //   }, 100);
    // }
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const file: any = new Blob([ia], { type: mimeString });
    file.lastModifiedDate = new Date();
    file.name = 'image_' + new Date().getTime() + '.jpg';
    return file;

  }

  fileSizeCheck = (file): boolean => {
    if (file.size > 15758640) { // 15 mb = 15758640 bytes
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'File should not be greater than 15MB',
      });
      return false;
    }
    return true;
  }

  onCloseEstTask(event) {
    this.showEstTaskDialog = false;
    if (event) {
      this.selectedCard.deadline = event;
      this.updateCard(true, 'type');
    }
  }

  boldMentionDescription() {
    this.isEditDescriptionMention = true;
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      const regexTag3 = /(#)([#.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag4 = /(#)([#.a-zA-Z0-9_-]*)( +)/g;
      let content = this.draffContent[this.selectedCard.id] ? this.draffContent[this.selectedCard.id] : this.selectedCard.content;
      content = content.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      content = content.replace(regexTag2, '<strong>$1$2</strong>$3');
      this.descriptionEditor.writeValue(this.selectedCard.content.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3'));
      this.descriptionEditor.writeValue(this.selectedCard.content.replace(regexTag2, '<strong>$1$2</strong>$3'));

      const url = `${location.origin}/#/app/tm/${this.rawProjectId}`;
      content = content.replace(regexTag3, `<a href="${url}?t=$2"><strong>$1$2</strong>&nbsp;$3</a>`);
      content = content.replace(regexTag4, `<a href="${url}?t=$2"><strong>$1$2</strong>$3</a>`);

      this.descriptionEditor.writeValue(this.selectedCard.content.replace(regexTag3, `<a href="${url}?t=$2"><strong>$1$2</strong>&nbsp;$3</a>`));
      this.descriptionEditor.writeValue(this.selectedCard.content.replace(regexTag4, `<a href="${url}?t=$2"><strong>$1$2</strong>$3</a>`));

      this.draffContent[this.selectedCard.id] = content;
      setTimeout(() => {
        this.descriptionEditor.getQuill().setSelection(this.descriptionEditor.value.length);
      }, 100);
    }, 500);
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;

      const regexTag3 = /(#)([#.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag4 = /(#)([#.a-zA-Z0-9_-]*)( +)/g;

      this.newComment = this.newComment.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.newComment = this.newComment.replace(regexTag2, '<strong>$1$2</strong>$3');
      this.commentEditor.writeValue(this.newComment.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3'));
      this.commentEditor.writeValue(this.newComment.replace(regexTag2, '<strong>$1$2</strong>$3'));

      const url = `${location.origin}/#/app/tm/${this.rawProjectId}`;
      this.newComment = this.newComment.replace(regexTag3, `<a href="${url}?t=$2"><strong>$1$2</strong>&nbsp;$3</a>`);
      this.newComment = this.newComment.replace(regexTag4, `<a href="${url}?t=$2"><strong>$1$2</strong>$3</a>`);
      this.commentEditor.writeValue(this.newComment.replace(regexTag3, `<a href="${url}?t=$2"><strong>$1$2</strong>&nbsp;$3</a>`));
      this.commentEditor.writeValue(this.newComment.replace(regexTag4, `<a href="${url}?t=$2"><strong>$1$2</strong>$3</a>`));
      setTimeout(() => {
        this.commentEditor.getQuill().setSelection(this.commentEditor.value.length);
      }, 100);

    }, 500);
  }

  async notifyMentionEmployee(details: string) {
    const employeeList = details.match(/(@)([@.a-zA-Z0-9_-]*)/g);
    if (employeeList) {
      for (const employee of employeeList) {
        const key = this.mentionEmployees.find(e => '@' + e.value === employee)?.key;
        if (key != null) {
          const notification: BrowserNotification = new BrowserNotification();
          notification.entity = 'employee';
          const regex = new RegExp(employee + '(.{0,30})');
          notification.content = this.authService.getCurrentUsername() + ' mentioned you: ' + this.stripHtml(details).match(regex)[0] + '...';
          notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
          notification.url = document.location.origin + '/#' + this.router.url;
          notification.status = 'New';
          notification.entityId = key;
          await this.browserNotificationService.save(notification).toPromise();
        }
      }
    }
  }

  stripHtml(html) {
    const tmp = document.createElement('tmp');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  onBoardSnapshotChange() {
    this.initedSnapshotBoard = true;
  }
  onBucketSnapshotChange(boardSnapshot) {
    if (!this.initedSnapshotBoard) { return; }
    const bucketsSnapshot = boardSnapshot.buckets;
    const newBucketOrder = boardSnapshot.buckets.map(x => x.id);
    // case: add new bucket
    const newBuckets = bucketsSnapshot.filter(x => !this.board.find(b => b.id === x.id));
    this.board = this.board.concat(newBuckets);
    this.board = this.board.filter(x => bucketsSnapshot.find(snapshot => snapshot.id === x.id));
    // case: move task to other bucket
    this.board.forEach(bucket => {
      const snapshot = bucketsSnapshot.find(b => b.id === bucket.id);
      const newBucketOrder = snapshot.tasks;
      const newTaskIds = newBucketOrder.filter(id => !bucket.tasks.find(t => t.id === id));
      if (newTaskIds.length > 0) {
        const newTasks = [];
        this.board.map(bucket => {
          const tasks = bucket.tasks || [];
          const newTask = tasks.find(t => newTaskIds.includes(t.id));
          if (newTask) {
            newTasks.push(newTask);
          }
        });
        bucket.tasks = bucket.tasks.concat(newTasks);
      }
    });

    this.board.forEach(bucket => {
      const snapshot = bucketsSnapshot.find(b => b.id === bucket.id);
      const newTasksOrder = snapshot.tasks;

      bucket.tasks = bucket.tasks.filter(task => newTasksOrder.includes(task.id));
      // case: reorder tasks inside bucket
      bucket.tasks.sort((a, b) => newTasksOrder.findIndex(o => a.id === o) - newTasksOrder.findIndex(o => b.id === o));
    });
    // case: reorder bucket
    this.board.sort((a, b) => newBucketOrder.findIndex(o => a.id === o) - newBucketOrder.findIndex(o => b.id === o));
  }
  onTaskSnapshotChange(data) {
    if (!this.initedSnapshotBoard) { return; }
    const newBucketId = data.bucketId;
    const newBucket = this.board.find(b => b.id === newBucketId);

    if (!newBucket.tasks.find(t => t.id === data.id)) {
      newBucket.tasks.push(data);
    }
    this.board.forEach(column => {
      if (column.id === data.bucketId) {
        const task = column.tasks.find(x => x.id === data.id);
        task ? task.content = data.content : null;
        if (this.selectedCard.id === data.id) {
          this.selectedCard.content = data.content;
        }
      }
    });
  }

  setWatchCard() {
    this.isSubscribe = false;
    this.loggedInMember = this.currenBoard.acceptedUsers.find(x => x.id === this.authService.getCurrentLoggedInId()
      && x.userType === this.taskManagementService.getUserType());
    if (!this.loggedInMember) { return; }
    const subscribe = this.selectedCard.watchCard.find(x => x.projectUserAssignmentId === this.loggedInMember.projectUserAssignmentId);
    if (subscribe) { this.isSubscribe = true; }
  }

  subscribe() {
    if (!this.isSubscribe) {
      this.isSubscribe = true;
      const payLoad = {
        bucketId: this.selectedCard.bucketId,
        projectId: this.currenBoard.id,
        projectUserAssignmentId: this.loggedInMember.projectUserAssignmentId,
        taskId: this.selectedCard.id,
        userId: this.loggedInMember.id,
        userType: this.loggedInMember.userType
      };
      this.taskManagementService.setWatchCard(payLoad).subscribe((res: any) => {
        this.selectedCard.watchCard.push(res.data);
        // this.getProjectDetail(this.pId, 'watch');
      });
    } else {
      this.isSubscribe = false;
      const subscribe = this.selectedCard.watchCard.find(x => x.projectUserAssignmentId === this.loggedInMember.projectUserAssignmentId);
      this.selectedCard.watchCard.splice(subscribe, 1);
      this.taskManagementService.unWatchCard(subscribe.id).subscribe((res: any) => {
        // this.getProjectDetail(this.pId, 'watch');
      });
    }
  }
  onSearchTaskFromSidebar(searchTerm: ITaskSearchTerm, ignoreReloadView?) {
    this.searchTerm = searchTerm;
    this.board.forEach(bucket => {
      bucket.newArr = [];
      bucket.tasks.forEach(task => {
        let hideByLabel = false;
        let hideByName = false;
        let hideByMember = false;
        let hideByDate = false;
        let hideByTicketType = false;
        // search by lable
        if (searchTerm.labels.length > 0) {
          const labels = task.labels;
          const label = labels.find(l => searchTerm.labels.includes(l.id));
          if (!label) {
            hideByLabel = true;
          }
        }

        if (searchTerm.labels.findIndex(l => l === 0) > -1) {
          hideByLabel = task.labels.length > 0 ? true : false;
        }
        // search by member
        if (searchTerm.members.length > 0) {
          const members = task.assignedUsers;
          const member = members.find(m => searchTerm.members.includes(m.id));
          if (!member) {
            hideByMember = true;
          }
        }

        if (searchTerm.members.findIndex(m => m === 0) > -1) {
          hideByMember = task.assignedUsers.length > 0 ? true : false;
        }

        // search by name
        if (!task.name.toLowerCase().includes(searchTerm.name.toLowerCase())) {
          hideByName = true;
        }

        // search by date

        if (searchTerm.date) {
          const currentDate = new Date().getTime();
          const currentMonth = new Date().getMonth();
          const currentWeek = _moment().isoWeek();

          const deadline = task.deadline ? new Date(task.deadline).getTime() : 0;
          const deadlineMonth = task.deadline ? new Date(task.deadline).getMonth() : 0;
          const dealineWeek = task.deadline ? _moment(task.deadline).isoWeek() : 0;
          switch (searchTerm.date) {
            case SEARCH_TASK_DATE_TYPE.NEXT_DAY:
              const diffDay = task.deadline ? _moment(new Date(task.deadline)).diff(_moment().startOf('day'), 'days') : 0;
              hideByDate = !task.deadline || diffDay !== 1;
              break;
            case SEARCH_TASK_DATE_TYPE.NEXT_WEEK:
              hideByDate = !task.deadline || (dealineWeek - currentWeek) !== 1;
              break;
            case SEARCH_TASK_DATE_TYPE.NEXT_MONTH:
              hideByDate = !task.deadline || (deadlineMonth - currentMonth) !== 1;
              break;
            case SEARCH_TASK_DATE_TYPE.OVERDUE:
              hideByDate = !task.deadline || (currentDate - deadline) < 0;
              break;
            case SEARCH_TASK_DATE_TYPE.NO_DUE:
              hideByDate = !!task.deadline;
              break;
          }
        } else {
          hideByDate = false;
        }

        // search by ticket type
        if (searchTerm.ticketTypes.length > 0) {
          const type = searchTerm.ticketTypes.includes(task.ticketType);
          if (!type) {
            hideByTicketType = true;
          }
        }

        // apply condition
        task.hideFromSearch = hideByLabel || hideByName || hideByMember || hideByDate || hideByTicketType;
        bucket.totalTicket = true;
        if (!task.hideFromSearch) bucket.newArr.push(task);
        if (!ignoreReloadView) {
          this.reloadTaskView(bucket, true);
        }

      });
    });
  }
  getAllTasks() {
    this.board.forEach(bucket => {
      bucket.tasks.forEach(task => {
        this.allTasks.push({
          id: task.id,
          name: `${task.internalId}-${this.createSlugName(task)}`
        });
      });
    });
  }
  createSlugName(task) {
    let slugName = task.name.replace(/ /g, '-');
    slugName = slugName.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
    return slugName;
  }
  initMention() {
    this.mentionConfig = {
      mentions: [
        {
          items: this.allMember,
          triggerChar: '@',
          maxItems: 10,
          labelKey: 'userName'
        }, {
          items: this.allTasks,
          triggerChar: '#',
          maxItems: 10,
          labelKey: 'name'
        }
      ]
    };
  }

  onExistedEstTask(event) {
    this.isDisableConfirm = !event;
  }

  onApproved() {
    if (this.statusAfter === 'Approved' || Object.keys(this.confirmSpinner).length > 0) {
      return;
    }
    if (this.isFreelancer || !this.isBoardAdmin()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Sorry, you do not have permission to approve`
      });
    } else {
      this.showApproveTicketDialog = true;
    }
  }

  onConfirm() {
    if (Object.keys(this.confirmSpinner).length > 0) {
      return;
    }

    if (this.isFreelancer || !this.isBoardAdmin()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Sorry, you do not have permission to confirm`
      });
      return;
    }
    if (this.statusAfter === 'Confirm' || this.statusAfter === 'Confirmed' || this.statusAfter === 'Approved') {
      this.showRevertConfirmedTicketDialog = true;
    } else {
      this.showConfirmTicketDialog = true;
    }

  }

  onTakeBack() {
    if (Object.keys(this.confirmSpinner).length > 0) {
      return;
    }
    if (!this.isBoardAdmin()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `You don't have permission to delete card created by other member`
      });
    } else {
      this.showTakebackTicketDialog = true;
    }
  }

  onDeleteEstTask(freelancer) {
    this.statusAfter = '';
    this.changeStatus = '';
    this.taskManagementService.unassign({
      userId: freelancer.id,
      projectId: Number(this.projectId),
      companyId: this.authService.getCurrentCompanyId(),
      taskId: this.selectedCard.id,
      userType: 3
    }).subscribe(res => {
      this.confirmSpinner = {};
      this.monitoringDetailsService.monitorAction(
        'Removed Member from Card',
        new Date(),
        {
          removed_member_from_card_by: this.authService.getCurrentLoggedInName(),
          task_id: this.selectedCard.id,
          task_name: this.selectedCard.name,
          members: freelancer.id,
          memberName: freelancer.email
            + ' ( ' + freelancer.firstName + ' ' + freelancer.lastName + ' )',
          userType: 'FREELANCER',
          type: CommonCode.DELETE_MEMBER.toString(),
        },
        'complete',
        'Task management',
        Number(this.projectId)
      );
      const memberFind = this.allEmployee.find(x => x.value === Number(freelancer.id)
        && x.type === 'FREELANCER');
      let content = '';
      if (this.selectedMemberCard.id === this.authService.getCurrentLoggedInId()) {
        content = 'left this card';
      } else {
        content = 'removed <b>' + memberFind.name + '</b> from this card';
      }
      this.allComment.push({
        type: CommonCode.DEL_MEMBER_TASK, // add member
        typeName: 'removed',
        createdAt: new Date().getTime(),
        createdByUsr: this.authService.getCurrentLoggedInName(),
        memberId: Number(this.selectedMemberCard.name),
        memberName: memberFind.name,
        content,
        userType: 'FREELANCER',
        userInfo: this.allEmployee.find(x => x.value === this.authService.getCurrentLoggedInId()) || {}
      });
      this.allComment = this.allComment.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
    }, () => this.confirmSpinner = {});
    const idx = this.selectedCard.assignedUsers.findIndex(x => x.id === this.selectedMemberCard.id);
    this.selectedCard.assignedUsers.splice(idx, 1);
    this.panelUnassign.hide();
  }

  onConfirmTask(status) {
    this.confirmSpinner = {};
    this.statusAfter = status;
    this.changeStatus = '';
    this.messageService.add({ severity: 'info', summary: 'Updated', detail: 'Status changed successfully!' });
  }

  onCurrentStatus(event) {
    this.statusAfter = event;
  }

  confirmTicket(option) {
    if (option === 1) {
      this.confirmSpinner['confirm'] = true;
      this.changeStatus = 'Confirm';
    } else {
      this.confirmSpinner = {};
    }
    
    this.showConfirmTicketDialog = false;
  }

  approveTicket(option) {
    if (option === 1) {
      this.confirmSpinner['approved'] = true;
      this.changeStatus = 'Approved';
    } else {
      this.confirmSpinner = {};
    }
    this.showApproveTicketDialog = false;
  }

  takebackTicket(option) {
    if (option === 1) {
      this.confirmSpinner['tackback'] = true;
      this.changeStatus = 'delete';
    } else {
      this.confirmSpinner = {};
    }
    this.showTakebackTicketDialog = false;
  }

  mimeType(file) {
    if (!file) { return false; }
    if (file.type === 'application/pdf' || file.type === 'text/plain' || file.type === 'text/html' 
      || file.name.includes('.csv') || file.name.includes('.xlsx') || file.name.includes('.xlsm') || file.name.includes('.xltx') || file.name.includes('.xltm') || file.name.includes('.xlsb') || file.name.includes('.xlam') 
      || file.name.includes('.docx') || file.name.includes('.docm') || file.name.includes('.dotx') || file.name.includes('.dotm')
      || file.name.includes('.pptx') || file.name.includes('.pptm') || file.name.includes('.potx') || file.name.includes('.potm') || file.name.includes('.ppam') || file.name.includes('.ppsx') || file.name.includes('.ppsm') || file.name.includes('.sldx') || file.name.includes('.sldm') || file.name.includes('.thmx') 
      || file.name.includes('.zip')) {
      return true;
    }
  }

  onCompleteTicket() {
    if (Object.keys(this.confirmSpinner).length > 0) {
      return;
    }
    if (this.isFreelancer || !this.isBoardAdmin()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Sorry, you do not have permission to complete`
      });
      return;
    }
    if (this.statusAfter === 'Close') {
      this.showRevertCompletedTicketDialog = true;
    } else {
      this.showCompletedTicketDialog = true;
    }

  }

  completeTicket(option) {
    if (option === 1) {
      this.confirmSpinner['complete'] = true;
      this.changeStatus = 'Close';
    } else {
      this.confirmSpinner = {};
    }
    this.showCompletedTicketDialog = false;
  }

  revertCompletedTicket(option) {
    if (option === 1) {
      this.confirmSpinner['complete'] = true;
      this.changeStatus = 'Reviewing';
    } else {
      this.confirmSpinner = {};
    }
    this.showRevertCompletedTicketDialog = false;
  }

  revertConfirmedTicket(option) {
    if (option === 1) {
      this.confirmSpinner['confirm'] = true;
      this.changeStatus = 'Reviewing';
    } else {
      this.confirmSpinner = {};
    }
    this.showRevertConfirmedTicketDialog = false;
  }

  focusOnEditor(event) {
    this.showFocusOnDescription = true;
    this.showFocuOnComment = false;
    if (this.newComment !== null) this.newComment = '' + this.newComment;
    else this.newComment = '';
  }

  focusOnComment(event) {
    this.showFocusOnDescription = false;
    this.showFocuOnComment = true;
    $("mention-list").show();
  }

  sortCards(type, ind?) {
    this.sortType = type;
    switch (type) {
      case 'dateAsc': {
        let tasks = this.selectedColumnToSort.tasks.sort((a, b) => b.createdAt - a.createdAt);
        this.selectedColumnToSort.tasks = [...tasks]
        this.reloadTaskView(this.selectedColumnToSort);
        return tasks;
      }
      case 'dateDesc': {
        let tasks = this.selectedColumnToSort.tasks.sort((a, b) => a.createdAt - b.createdAt);
        this.selectedColumnToSort.tasks = [...tasks]
        this.reloadTaskView(this.selectedColumnToSort);
        return tasks;
      }
      case 'AtoZ': {
        let tasks = this.selectedColumnToSort.tasks.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedColumnToSort.tasks = [...tasks]
        this.reloadTaskView(this.selectedColumnToSort);
        return tasks;
      }
      case 'ZtoA': {
        let tasks = this.selectedColumnToSort.tasks.sort((a, b) => b.name.localeCompare(a.name));
        this.selectedColumnToSort.tasks = [...tasks]
        this.reloadTaskView(this.selectedColumnToSort);
        return tasks;
      }
      case 'HideList': {
        this.onHideBucket(this.selectedColumnToSort);
        return this.selectedColumnToSort.tasks;
      }
      case 'SelectCards': {
        if (ind) this.selectedColumnIndex = ind;
        this.selectedColumnToSort.multiSelect = !this.selectedColumnToSort.multiSelect;
        this.selectedColumnToSort.tasks.forEach(t => t.selected = false);
        if (this.selectedColumnToSort.tasksView) {
          this.selectedColumnToSort.tasksView.forEach(t => t.selected = false);
        }
        this.board.forEach(bucket => {
          if (this.selectedColumnToSort.id !== bucket.id) {
            bucket.tasks.forEach(t => t.selected = false);
            if (bucket.tasksView) {
              bucket.tasksView.forEach(t => t.selected = false);
            }
            bucket.multiSelect = false;
          }
        })
        return this.selectedColumnToSort.tasks;
      }
    }
  }

  setFocus(id) {
    this.showFocusOnDescription = false;
    this.showFocuOnComment = false;
    document.getElementById(id)?.focus();

  }

  getCheckList() {
    this.taskManagementService.getCheckListByTask({ taskId: this.selectedCard.id }).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.checkListData = res.data;
        this.assignMemberChecklist();
        this.checkListData.forEach(ele => {
          this.calPercentage(ele);
        });
      }
    });
  }

  showInput(index) {
    console.log(index);
    document.getElementById('showItemInput_' + index).style.display = 'block';
    document.getElementById('item2_' + index).style.display = 'block';
    document.getElementById('item_' + index).style.display = 'none';
  }

  hideInput(index) {
    console.log(index);
    document.getElementById('showItemInput_' + index).style.display = 'none';
    document.getElementById('item2_' + index).style.display = 'none';
    document.getElementById('item_' + index).style.display = '';
  }

  saveCheckList() {
    this.checkList.name = this.checkListName;
    this.checkList.taskId = this.selectedCard.id;
    this.checkList.projectId = Number(this.projectId);
    this.taskManagementService.saveChecklist(this.checkList).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sendTaskUpdateNotification(this.selectedCard.id);
        this.getCheckList();
        this.checkListName = null;
      }
    });
  }

  addChecklistItem(item) {
    const data = {
      name: this.listItemName,
      selectItem: false,
      taskCheckListId: item.id,
    };
    this.taskManagementService.saveChecklistItem(data).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sendTaskUpdateNotification(this.selectedCard.id);
        this.getCheckList();
        this.listItemName = null;
      }
    });
  }

  selectChecbox(event, item, checks) {
    item.selectItem = event.checked;
    let count = 0;
    checks.taskCheckListItems.forEach(ele => {
      if (ele.selectItem) {
        count++
      }
    });
    let checkedCache = checks.checked;
    if (count === 0) {
      checks.checked = false;
      this.updateCheckListItem(checks);
    } else if (count === checks.taskCheckListItems.length) {
      checks.checked = true;
      this.updateCheckListItem(checks);
    } else {
      checks.checked = false;
      if (count < checks.taskCheckListItems.length && checkedCache !== checks.checked) {
        this.taskManagementService.saveChecklist(checks).subscribe((res: any) => {
          if (res.status === 'SUCCESS') {
            this.sendTaskUpdateNotification(this.selectedCard.id);
            this.getCheckList();
          }
        });
      } else {
        this.taskManagementService.saveChecklistItem(item).subscribe((res: any) => {
          if (res.status === 'SUCCESS') {
            this.sendTaskUpdateNotification(this.selectedCard.id);
            this.getCheckList();
          }
        });
      }
    }
  }

  updateCheckListItem(data) {
    if (data.taskCheckListItems) {
      data.taskCheckListItems.forEach(ele => {
        if (data.checked) {
          ele.selectItem = true;
        } else {
          ele.selectItem = false;
        }
      });
    }

    this.taskManagementService.saveChecklist(data).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sendTaskUpdateNotification(this.selectedCard.id);
        this.getCheckList();
      }
    });
  }

  calPercentage(checks) {
    checks.percentage = 0;
    if (checks.taskCheckListItems && checks.taskCheckListItems.length === 0) {
      if (checks.checked) {
        checks.percentage = 100;
      }
      return;
    }
    const length = checks.taskCheckListItems.length;
    const a = 100 / length;
    let count = 0;
    checks.taskCheckListItems.forEach(ele => {
      if (checks.checked) {
        ele.selectItem === true;
      }
      if (ele.selectItem === true) {
        count++;
        ele.percentage = a;
        checks.percentage = checks.percentage + ele.percentage;
      }
    });
    if (checks.taskCheckListItems && count === checks.taskCheckListItems.length) {
      checks.checked = true;
    }
  }

  deleteItem(index, checklist) {
    checklist.taskCheckListItems.splice(index, 1);
    this.taskManagementService.saveChecklist(checklist).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sendTaskUpdateNotification(checklist.taskId);
        this.getCheckList();
      }
    });
  }

  delChecklist() {
    this.taskManagementService.deleteCheckList(this.selectedCheckList.id).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sendTaskUpdateNotification(this.selectedCheckList.taskId);
        this.getCheckList();
      }
    });
  }

  hideSelectedItem(value, type: boolean) {
    value.selectedHide = type;
    this.taskManagementService.saveChecklist(value).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sendTaskUpdateNotification(this.selectedCard.id);
        this.getCheckList();
      }
    });
  }

  monitorCardUpdated() {
    let args = {};
    let action = ''
    if (!this.nameChanged) {
      action = 'Updated Card'
      args = {
        updated_card_by: this.authService.getCurrentLoggedInName(),
        task_id: this.selectedCard.id,
        task_name: this.selectedCard.name.trim(),
        type: CommonCode.UPDATE_CARD.toString(),
        bucket_name: this.selectedColumn.name
      }
    } else {
      action = 'Card Name Updated'
      args = {
        updated_card_name_by: this.authService.getCurrentLoggedInName(),
        task_id: this.selectedCard.id,
        task_name: this.selectedCard.name.trim(),
        old_task_name: this.selectedCard.oldCardName,
        old_task_name_only_letter: this.selectedCard.oldCardName.toLowerCase().replace(/[^a-zA-Z0-9]+/g, ''),
        type: CommonCode.UPDATE_CARD.toString(),
        bucket_name: this.selectedColumn.name
      }
    }

    this.monitoringDetailsService.monitorAction(
      action,
      this.timeSpent,
      args,
      'complete',
      'Task management',
      Number(this.projectId)
    );
    this.nameChanged = false;
  }

  showInputItem(index, index1, item,) {
    this.listItemNameUp = item.name
    document.getElementById('showItemInputItem_' + index + index1).style.display = 'block';
    document.getElementById('temInputItem_' + index + index1).style.display = 'none';
  }

  hideInputItem(index, index1, item) {
    this.listItemNameUp = item.name
    document.getElementById('showItemInputItem_' + index + index1).style.display = 'none';
    document.getElementById('temInputItem_' + index + index1).style.display = 'block';
  }

  checkListNameShow(index, item) {
    this.updateCheckListName = item.name
    document.getElementById('ItemListItem_' + index).style.display = 'block';
    document.getElementById('showItemListItem_' + index).style.display = 'none';
    document.getElementById('showItemListItem1_' + index).style.display = 'none';
  }

  hideInputList(index, item) {
    this.updateCheckListName = item.name
    document.getElementById('ItemListItem_' + index).style.display = 'none';
    document.getElementById('showItemListItem_' + index).style.display = 'block';
    document.getElementById('showItemListItem1_' + index).style.display = 'block';
  }

  saveItem(i, j, data, type?: string) {
    if (type) {
      data.name = this.updateCheckListName
      this.taskManagementService.updateChecklist(data).subscribe((res: any) => {
        this.sendTaskUpdateNotification(this.selectedCard.id);
        this.hideInputList(i, data)
      });
    } else {
      data.name = this.listItemNameUp
      this.taskManagementService.saveChecklistItem(data).subscribe((res: any) => {
        this.sendTaskUpdateNotification(this.selectedCard.id);
        this.hideInputItem(i, j, data)
      });
    }

  }

  // As default modal closed without saving desc & comment
  updateCardAuto() {
    const content = this.draffContent[this.selectedCard.id]
      ? this.draffContent[this.selectedCard.id] : this.draffContent[this.selectedCard.id] === null ? null
        : this.selectedCard.content;

    const payload = {
      name: this.selectedCard.name.trim(),
      content: content ? this.urlify(content.replace(/<img[^>]*>/g, '')) : ' ',
      status: '',
      bucketId: Number(this.selectedCard.bucketId),
      background: this.selectedCard.background,
      assignedUsers: this.selectedCard.assignedUsers ? this.selectedCard.assignedUsers : [],
      accessibleUsers: this.selectedCard.accessibleUsers,
      position: this.selectedCard.position,
      projectId: this.currenBoard.id,
      lastModifiedBy: this.authService.getCurrentLoggedInName(),
    }
    this.taskManagementService.updateCard(this.selectedCard.id, payload).subscribe((res: any) => {
      this.selectedCard.content = content;
    });
  }

  checkContract() {
    let freelancer;
    let dateArray = [];
    const date = Date.parse(new Date().toISOString());
    this.contentService.filter({ id: this.authService.getCurrentLoggedInId() }).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        freelancer = resObj.data.content[0];
        if (!freelancer.contractItems || freelancer.contractItems.length === 0) {
          this.notContracted = true;
        } else {
          freelancer.contractItems.forEach(c => {
            if (Date.parse(new Date(c.endTime).toISOString()) > date || Date.parse(new Date(c.endTime).toISOString()) === date) {
              dateArray.push(c.endTime)
            }
          });
          console.log(dateArray);
          if (dateArray.length === 0) this.notContracted = true;
        }
      }
    });
  }

  getViewportHeight(cdkVirtualScrollViewport: CdkVirtualScrollViewport, columnId) {
    let selector = document.querySelector('[data-id="' + columnId + '"]');
    let boardSection = document.getElementById("boardSection");
    if (selector && boardSection) {
      const estHeight = selector.clientHeight + cdkVirtualScrollViewport.getDataLength();
      let actualHeight = estHeight;
      if (estHeight > boardSection.offsetHeight) {
        actualHeight = boardSection.offsetHeight;
      }
      const actualHeightInPx = actualHeight + 'px';
      // if (actualHeightInPx !== cdkVirtualScrollViewport._totalContentHeight) {
      //   cdkVirtualScrollViewport.checkViewportSize();
      // }
      cdkVirtualScrollViewport._totalContentHeight = actualHeightInPx;
      cdkVirtualScrollViewport.checkViewportSize();
      return actualHeightInPx;
    }
    return '300px';
  }

  //NEW Search Process

  searchTerm: ITaskSearchTerm = {
    name: '',
    labels: [],
    members: [],
    date: 0,
    ticketTypes: []
  };

  selectLabel(labelId) {
    const idx = this.searchTerm.labels.findIndex(x => x === labelId);
    if (idx > -1) this.searchTerm.labels.splice(idx, 1);
    else this.searchTerm.labels.push(labelId);
    this.onSearchTaskFromSidebar(this.searchTerm);
  }

  selectMember(memberId) {
    const idx = this.searchTerm.members.findIndex(x => x === memberId);
    if (idx > -1) this.searchTerm.members.splice(idx, 1);
    else this.searchTerm.members.push(memberId);

    this.onSearchTaskFromSidebar(this.searchTerm);
  }

  selectTicketType(type) {
    const idx = this.searchTerm.ticketTypes.findIndex(x => x === type);
    if (idx > -1) this.searchTerm.ticketTypes.splice(idx, 1);
    else this.searchTerm.ticketTypes.push(type);
    this.onSearchTaskFromSidebar(this.searchTerm);
  }


  async selectBucket(task) {
    const data: any = await this.taskManagementService.gettaskDetails(task.id).toPromise();
    this.selectedBucketId = this.board.filter(x => x.id === data.data.bucketId)[0];
    this.selectedCard.id = task.id;
  }

  checkAssignedUser() {
    this.selectedBucketId.tasks.forEach(tsk => {
      if (tsk.id === this.selectedCard.id) tsk.assignedUsers = this.selectedCard.assignedUsers
    })
  }

  onHideBucket(column) {
    const date = new Date();
    const payload = {
      ids: [column.id],
      status: true
    };
    this.taskManagementService.updateHideBucket(payload).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        column.hide = true;
        this.monitoringDetailsService.monitorAction(
          'Bucket Name Hided',
          date,
          {
            bucket_name: this.selectedColumnToSort.name,
            bucket_hided_by: this.authService.getCurrentLoggedInName(),
            type: CommonCode.HIDE_BUCKET.toString(),
          },
          'complete',
          'Task management',
          Number(this.projectId)
        );
      }
    });
    column.hide = true;
  }

  onUnhideBuckets() {
    const date = new Date();
    this.board.filter(b => b.hide).map(b => b.id);
    const payload = {
      ids: [...this.board.filter(b => b.hide).map(b => b.id)],
      status: false
    };
    const bucketName = [...this.board.filter(b => b.hide).map(b => b.name)]
    this.taskManagementService.updateHideBucket(payload).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.board.forEach(bucket => bucket.hide = false);
        if (bucketName.length > 0) {
          this.monitoringDetailsService.monitorAction(
            'Bucket Name Unhided',
            date,
            {
              bucket_unhided_by: this.authService.getCurrentLoggedInName(),
              bucket_names: bucketName.toString() + ',',
              type: CommonCode.UNHIDE_BUCKET.toString(),
            },
            'complete',
            'Task management',
            Number(this.projectId)
          );
        }
      }
    });
  }

  onScrollDown(columnId) {
    const bucket = this.board[columnId];
    if (!bucket.tasksView) {
      bucket.tasksView = [];
    }
    const tasks = bucket.tasks.filter(t => !t.hideFromSearch);
    const tasksView = bucket.tasksView.filter(t => !t.hideFromSearch);
    const start = tasksView.length;
    const end = tasksView.length + this.tasksViewSize;
    for (let i = start; i < end; i++) {
      if (tasks && tasks.length > i) {
        bucket.tasksView.push(tasks[i]);
      }
    }
  }

  moveElement(input, from, to) {
    let numberOfDeletedElm = 1;
    const elm = input.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;
    input.splice(to, numberOfDeletedElm, elm);
  }

  private hasClass(el: any, name: string): any {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  private removeClass(el: any, name: string): void {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), ' ');
    }
  }

  checkTicketCount() {
    if (!this.searchTerm.labels || !this.searchTerm.members || !this.searchTerm.ticketTypes) {
      this.board.forEach(bucket => bucket.totalTicket = false)
    } else {
      this.onSearchTaskFromSidebar(this.searchTerm);
    }
  }


  reloadTaskView(bucket, ignoreSearch?) {
    bucket.tasksView = [];
    if (!ignoreSearch && this.searchTerm) {
      this.onSearchTaskFromSidebar(this.searchTerm, true);
    }
    const tasks = bucket.tasks.filter(t => !t.hideFromSearch);
    for (let i = 0; i < this.tasksViewSize; i++) {
      if (tasks && tasks.length > i) {
        bucket.tasksView.push(tasks[i]);
      }
    }
  }

  initFirebaseEvent(projectId) {
    if (this.projectFirebaseSubscriptions) {
      this.projectFirebaseSubscriptions.unsubscribe();
    }
    this.projectFirebaseSubscriptions = this.db.list(`/task-management/${projectId}/project`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
      if (event) {
        event.forEach(data => {
          if (this.allowNotification(data)) {
            const eventType = data['eventType'];
            if (eventType === 'CHANGE_BACKGROUND') {
              this.background = data['background'];
            }
          }
        });
      }
    });

    if (this.assignedMemberUpdateFirebaseSubscriptions) {
      this.assignedMemberUpdateFirebaseSubscriptions.unsubscribe();
    }
    this.assignedMemberUpdateFirebaseSubscriptions = this.db.list(`/task-management/${projectId}/assigned-memeber-update`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
      this.taskManagementService.getProjectById(projectId).subscribe((res: any) => {
        this.allMember = res.data.acceptedUsers;
      });
    });

    if (this.bucketProjectOrderFirebaseSubscriptions) {
      this.bucketProjectOrderFirebaseSubscriptions.unsubscribe();
    }

    this.bucketProjectOrderFirebaseSubscriptions = this.db.object(`/task-management/${projectId}/bucket-order`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any) => {
      if (event) {
        if (this.allowNotification(event)) {
          const bucketOrder: any[] = event['bucketOrder'];
          if (bucketOrder && bucketOrder.length > 0) {
            const newBoard = [];
            bucketOrder.forEach(b => {
              let exist = this.board.find(bucket => b.bucketId === bucket.id);
              if (exist) {
                console.log(exist);

                newBoard.push(exist);
              } else {
                newBoard.push({
                  id: b.bucketId,
                  name: b.name,
                  tasks: []
                });
              }
            });
            this.board = newBoard;
            console.log(this.board);

            this.board.forEach(b => this.reloadTaskView(b))
          } else {
            this.board = [];
          }

        }
      }
    });

    if (this.bucketTaskOrderFirebaseSubscriptions) {
      this.bucketTaskOrderFirebaseSubscriptions.unsubscribe();
    }
    this.bucketTaskOrderFirebaseSubscriptions = this.db.list(`/task-management/${projectId}/bucket/task-order`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any[]) => {
      if (event) {
        event.forEach(data => {
          if (this.allowNotification(data)) {
            const bucketId = data['bucketId'];
            const ids = data['ids'];
            console.log('Update bucket tasks ordering, bucketId=' + bucketId + ", ids=" + ids);

            let bucket = this.board.find(bucket => bucket.id === bucketId);
            if (!bucket || !ids || ids.length <= 0) {
              return;
            }
            const movedBucketTasks = [];
            const movedTasks = [];
            this.board.forEach(bucket => {
              if (ids && ids.length > 0) {
                bucket.tasks.forEach(t => {
                  if (ids.find(id => id === t.id)) {
                    let b = movedBucketTasks.find(t => t.bucketId === bucket.id);
                    if (!b) {
                      b = { bucketId: bucket.id, tasks: [] }
                      movedBucketTasks.push(b);
                    }
                    b.tasks.push(t);
                    movedTasks.push(t);
                  }
                });
              }
            });
            if (movedBucketTasks.find(m => m.bucketId !== bucketId)) {
              // Remove tasks from source bucket if task is transfered between two buckets
              this.board.filter(b => b.id !== bucketId).forEach(bucket => {
                let movedBucket = movedBucketTasks.find(m => m.bucketId === bucket.id);
                if (movedBucket) {
                  bucket.tasks = bucket.tasks.filter(t => !movedBucket.tasks.find(task => task.id === t.id));
                  this.reloadTaskView(bucket);
                }
              });
            }

            const reOderTasks = [];
            if (movedTasks.length > 0) {
              ids.forEach(id => {
                const t = movedTasks.find(t => t.id === id);
                if (t) {
                  if (t.bucketId !== bucketId) {
                    t.bucketId = bucketId;
                  }
                  reOderTasks.push(t);
                }
              });
              bucket.tasks = reOderTasks;
              this.reloadTaskView(bucket);
            }
          }
        });
      }
    });

    if (this.bucketUpdateFirebaseSubscriptions) {
      this.bucketUpdateFirebaseSubscriptions.unsubscribe();
    }

    this.bucketTaskOrderFirebaseSubscriptions = this.db.list(`/task-management/${projectId}/bucket/update`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any[]) => {
      if (event) {
        event.forEach(data => {
          if (this.allowNotification(data)) {
            const bucketId = data['bucketId'];
            this.taskManagementService.getBucket(bucketId).subscribe((res: any) => {
              if (res.status === 'SUCCESS') {
                let currentBucket = this.board.find(bucket => bucket.id === bucketId);
                if (currentBucket) {
                  currentBucket.tasks = res.data.tasks;
                  this.reloadTaskView(currentBucket);
                }
              }
            });
          }
        });
      }
    });

    if (this.taskFirebaseSubscriptions) {
      this.taskFirebaseSubscriptions.unsubscribe();
    }
    this.taskFirebaseSubscriptions = this.db.list(`/task-management/${projectId}/task`).valueChanges().pipe(debounceTime(1000)).subscribe((event) => {
      if (event) {
        
        console.log('taskFirebaseSubscriptions event: ', event)
        console.log('taskFirebaseSubscriptions this.selectedCard.id: ', this.selectedCard.id)
        event.forEach(data => {
          if (this.allowNotification(data)) {
            const taskId = data['taskId'];
            console.log('Update task information, taskId=' + taskId);
            this.taskManagementService.gettaskDetails(taskId).subscribe((res: any) => {
              if (res.status === 'SUCCESS') {
                const task = res.data;
                if (task.deadline) {
                  const getTD = new Date(task.deadline);
                  const statusDeadline = getTD.valueOf() -
                    this.currentTime.valueOf() <= 86400000 && getTD.valueOf() -
                    this.currentTime.valueOf() >= 0 ? 'upcoming' : getTD.valueOf() - this.currentTime.valueOf() < 0 ?
                    'expired' : 'normal';
                  task['statusDeadline'] = statusDeadline;
                }
                if (this.selectedCard && taskId === this.selectedCard.id) {
                  this.selectedCard = task;
                  this.taskManagementService.getListComment(this.selectedCard.id).subscribe((res: any) => {
                    let allComment = res.data || [];
                    this.allCommentFile = allComment.filter(x => (x.type === 'IMAGE_URL' || x.type === 'DOCUMENT'));
                    allComment.forEach(e => {
                      const userInfo = allComment.find(x => x.id === e.userId) || {};
                      e.userInfo = userInfo;
                    });
                    this.showAddMemberTask(allComment);
                  });
                  this.getCheckList();
                }
                this.board.forEach(bucket => {
                  if (bucket.id === task.bucketId) {
                    const taskIdx = bucket.tasks.findIndex(t => t.id === task.id);
                    if (taskIdx >= 0) {
                      bucket.tasks.splice(taskIdx, 1, task);
                    } else {
                      bucket.tasks.push(task);
                    }
                    const taskViewIdx = bucket.tasksView.findIndex(t => t.id === task.id);
                    if (taskViewIdx >= 0) {
                      bucket.tasksView.splice(taskViewIdx, 1, task);
                    } else {
                      bucket.tasksView.push(task);
                    }
                  }
                })
              }
            })
          }

          if (this.refreshWhenUpdateData(data)) {
            const taskId = data['taskId'];
            if (this.selectedCard && taskId === this.selectedCard.id) {
              this.showButtonRefresh = true;
            }
          }
        });
      }
    });
  }

  allowNotification(data) {
    const currentTimestamp = new Date().getTime();
    const lastUpdateTime = data['lastUpdateTime'];
    const trackingSession = data['trackingSession'];
    return lastUpdateTime > currentTimestamp - 15000 && this.trackingSession !== trackingSession
  }

  refreshWhenUpdateData(data) {
    const updatedByUser = data['updatedByUser'];
    const actionUpdate = data['actionUpdate'];
    if (actionUpdate === 'update'
      && updatedByUser !== this.authService.getUserInfo().username
    ) {
      return true;
    }
    return false;
  }
  sendTaskUpdateNotification(taskId, update?) {
    console.log('sendTaskUpdateNotification taskId: ', taskId)
    this.taskManagementService.saveFirebaseNotification(`/task-management/${this.projectId}/task/${taskId}`, {
      taskId: taskId,
      updatedByUser: this.authService.getUserInfo().username,
      lastUpdateTime: new Date().getTime(),
      trackingSession: this.trackingSession,
      actionUpdate: update
    }).subscribe();
  }

  sendBucketProjectOrderChageNotification(projectId, bucketOrder: any[]) {
    this.taskManagementService.saveFirebaseNotification(`/task-management/${this.projectId}/bucket-order`, {
      projectId: projectId,
      updatedByUser: this.authService.getUserInfo().username,
      bucketOrder: bucketOrder,
      lastUpdateTime: new Date().getTime(),
      trackingSession: this.trackingSession
    }).subscribe();
  }

  sendBucketTaskOrderChageNotification(bucketId, taskIds: any[], projectId?) {
    this.taskManagementService.saveFirebaseNotification(`/task-management/${projectId ? projectId : this.projectId}/bucket/task-order/${bucketId}`, {
      bucketId: bucketId,
      updatedByUser: this.authService.getUserInfo().username,
      ids: taskIds,
      lastUpdateTime: new Date().getTime(),
      trackingSession: this.trackingSession
    }).subscribe();
  }

  sendBucketUpdateNotification(bucketId, projectId?) {
    this.taskManagementService.saveFirebaseNotification(`/task-management/${projectId ? projectId : this.projectId}/bucket/update/${bucketId}`, {
      bucketId: bucketId,
      updatedByUser: this.authService.getUserInfo().username,
      lastUpdateTime: new Date().getTime(),
      trackingSession: this.trackingSession
    }).subscribe();
  }

  sendProjectBackgroundNotification(projectId, background) {
    this.taskManagementService.saveFirebaseNotification(`/task-management/${projectId}/project/background`, {
      eventType: 'CHANGE_BACKGROUND',
      background: background,
      updatedByUser: this.authService.getUserInfo().username,
      lastUpdateTime: new Date().getTime(),
      trackingSession: this.trackingSession
    }).subscribe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.shiftKey) {
      this.shiftPressed = true;
      this.sortType === 'SelectCards';
    } else if (event.keyCode == 27 && this.selectedColumnToSort.multiSelect) {
      this.sortCards('SelectCards');
      this.shiftPressed = false;
      this.sortType === '';
    }  else {
      this.shiftPressed = false;
    }
  }

  reset() {
    this.keyCount = 0;
    this.shiftPressed = false;
    // this.selectedColumnToSort = null;
    // this.selectedColumnIndex = null;
  }

  onCommentEditorInit(event) {
    event.editor.root.focus();
    this.commentEditorQuill = event.editor;
  }

  findLabels(filter) {
    this.filteredLabel = this.allLabel.filter(item => {
      if (item.name.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    }
    );
  }

  hidePanelMember() {
    this.searchMemberName = "";
    this.searchMemberName2 = "";
  }

  hidePanelLabel() {
    this.searchLabelName = "";
  }

  getAllComments() {
    return this.allComment.filter(c => c.type === 'TEXT' || c.type === 'COMMENT_ON_IMAGE' || c.type === 'HISTORY'
        || c.type === 'DEL_MEMBER_TASK' || c.type === 'ADD_MEMBER_TASK');
  }

  refreshProjectDetail() {
    this.ngOnInit();
  }

  scrollToTop(boardIndex) {
    const bucket = this.board[boardIndex];
    if (bucket) {
      this.reloadTaskView(bucket);
      const interval = setInterval(() => {
        this.autoscroll.get(boardIndex).nativeElement.scrollTop = 0;

        if (interval)
          clearInterval(interval);
      }, 500);
    }
  }

  scrollToBottom(boardIndex) {
    const bucket = this.board[boardIndex];
    if (bucket) {
      bucket.tasksView = bucket.tasks.filter(t => !t.hideFromSearch);
      const interval = setInterval(() => {
        const scrollHeight = this.autoscroll.first.nativeElement.scrollHeight;
        this.autoscroll.get(boardIndex).nativeElement.scrollTop = scrollHeight > 10000 ? scrollHeight : 10000;

        if (interval)
          clearInterval(interval);
      }, 500);
    }
  }

  typingText() {
    this.pasteEvent = false;
  }

  openDuplicate(projectId, cardId, cardName) {
    let slugName = cardName.replace(/ /g, '-');
    slugName = slugName.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
    let slug = `${cardId}-${slugName}`;
    slug = slug.replace(/--+/g, '-');
    const enc = this.taskManagementService.encodeId(projectId).replace(/\=/gi, '');
    const url = `${window.location.origin}/#/app/tm/${enc}?t=${slug}`
    window.open(url, '_blank')
    this.router.navigateByUrl(url);
  }
}

