import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem, Message, MessageService} from 'primeng/api';
import {InternalRequest} from '../../../shared/model/internal-request';
import {CommService} from '../../../employee/performance/service/comm.service';
import {NavigationEnd, Router} from '@angular/router';
import {BreadcrumbService} from '../../../layout/service/breadcrumb.service';
import {takeUntil} from 'rxjs/operators';
import {Subscription, Subject, interval} from 'rxjs';

@Component({
  selector: 'app-transaction-type-layout',
  templateUrl: './ar-report-layout.component.html',
  styleUrls: ['./ar-report-layout.component.css']
})
export class ArReportLayoutComponent implements OnInit, OnDestroy {
  loading = false;
  arReportItems: MenuItem[] = [];
  subscription: Subscription;
  basicMenuItems = [];
  activeItem: MenuItem;
  defaultBreadCrumbs: MenuItem[] = [];
  private destroyed$ = new Subject<void>();
  messages: Message[] = [];

  initBreadCrumbs() {
    this.defaultBreadCrumbs = [];
    this.defaultBreadCrumbs.push({label: 'Accounting'});
    this.defaultBreadCrumbs.push({label: 'Accounts Receivable'});
    this.basicMenuItems.push(
    {label: 'Revenue Payments', id: 'revenue_payment', routerLink: ['/', 'app', 'revenue-payment', 'list']},
    {label: 'Service Revenue', id: 'service_revenue', routerLink: ['/', 'app', 'service-revenue', 'list']},
    {label: 'Gross Income', id: 'gross-income', routerLink: ['/', 'app', 'gross-income', 'list']},
    {label: 'Aging Summary', id: 'aging-summary', routerLink: ['/', 'app', 'aging-summary', 'list']},
    {label: 'Aging Details', id: 'aging-details', routerLink: ['/', 'app', 'receivable-aging-details', 'list']});
  }

  constructor(private commService: CommService, private router: Router,
              private breadcrumbService: BreadcrumbService) {
    this.activeItem = this.arReportItems[0];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('/app/service-revenue/list') >= 0) {
          this.initBreadCrumbs();
          this.arReportItems = [];
          this.arReportItems.push(...this.basicMenuItems);
          this.defaultBreadCrumbs.push({label: 'Service Revenue'});
        } else if (event.url.indexOf('/app/revenue-payment/list') >= 0) {
          this.initBreadCrumbs();
          this.arReportItems = [];
          this.arReportItems.push(...this.basicMenuItems);
          this.defaultBreadCrumbs.push({label: 'Revenue Payments'});
        } else if (event.url.indexOf('/app/gross-income/list') >= 0) {
          this.initBreadCrumbs();
          this.arReportItems = [];
          this.arReportItems.push(...this.basicMenuItems);
          this.defaultBreadCrumbs.push({label: 'Gross Income'});
        } else if (event.url.indexOf('/app/aging-summary/list') >= 0) {
          this.initBreadCrumbs();
          this.arReportItems = [];
          this.arReportItems.push(...this.basicMenuItems);
          this.defaultBreadCrumbs.push({label: 'Aging Summary'});
        } else if (event.url.indexOf('/app/receivable-aging-details/list') >= 0) {
            this.initBreadCrumbs();
            this.arReportItems = [];
            this.arReportItems.push(...this.basicMenuItems);
            this.defaultBreadCrumbs.push({label: 'Aging Details'});
        } else {
          this.initBreadCrumbs();
          this.arReportItems = [];
          this.arReportItems.push(...this.basicMenuItems);
        }
        this.breadcrumbService.setItems(this.defaultBreadCrumbs);
      }
    });
  }

  ngOnInit() {
    this.subscribeMessage();
    this.subscription = this.commService.announced$.subscribe(
      (data: InternalRequest) => {
        if (data.cmd === 'show_spin') {
          setTimeout(() => {
            this.loading = true;
          }, 0);
        } else if (data.cmd === 'hide_spin') {
          setTimeout(() => {
            this.loading = false;
          }, 0);
        }
      }
    );
  }

  private subscribeMessage() {
    this.commService.message$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((message: Message) => {
        this.messages.push(message);
      });

    interval(5000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.messages.length) {
          this.messages.shift();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
