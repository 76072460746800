import { DocumentData } from 'app/shared/model/document-data.model';


export class CompanySettingV2 {
    reminderEmployeeVacation ?: boolean;
    reminderEmployeeBirthday ?: boolean;
    reminderEmployeeWorkingOvertime ?: boolean;
    reminderMethod ?: string;
    reminderTimePeriod ?: string;
    reminderRepeatEndOn ?: Date;
    reminderNeedRepeat ?: boolean;
    reminderRepeatEvery ?: string;
    userNameOption: string;
    reminderRepeatOn ?: string[];
    id ?: number;
    upperLeftLabel ?: string;
    is2ndVerificationAdminEnable  ? = false;
    employeeMenuList ?: string[];
    employeeDepartmentList?: string[];
    crmMenuList ?: string[];
    crmDepartmentList?: string[];
    accountMenuList ?: string[];
    accountDepartmentList?: string[];
    billingMenuList ?: string[];
    billingDepartmentList?: string[];
    otherMenuList ?: string[];
    otherDepartmentList?: string[];
    birthdaySongEnable  ? = false;
    // birthdaySong ?: DocumentData;
    birthdaySong ?: number;
    logo ?: DocumentData;
    logoId ?: number;
    is2ndVerificationEmployeeEnable  ? = false;
    requiredHoursStatus  ? = false;
    pinVerificationLimit ?: number;
    requiredHours ?: Date;
    supportResponseTime ?: Date;
    supportResponseTimeStr = '';
    calendarNotification ? = false;
    calendarNotificationMethod ?: string[];
    isEmployeeMissingPunchNotificationEnable ? = false;
    employeeMissingPunchNotificationMethod ?: string[];
    employeeMissingPunchNotificationEmail ?: string;
    maxLoginAttemptCountAdmin ?: number;
    maxLoginAttemptCountEmployee ?: number;
    isSeniorPunchInOutNotificationToEmergencyContactEnable ? = false;
    seniorPunchInOutNotificationToEmergencyContactMethod ?: string[];
    mobileVisitVerification ? = false;
    overtimeValidation ? = false;
    promptPasswordAt ?: number;
    overtimeAlert ? = false;
    toleranceRange ?:  number;
    otAlertHour ?: number;
    otAlertMinute ?: number;
    otAlertType: string;
    otAlertEmail ?: string[];
    otAlertSMS ?: string[];
    companyId: any;
    // badge ?: DocumentData;
    badge ?: number;
    sendTo: string[];
    sendAt: Date;
    sendAtStr = '';
    updateTrackingEvery: Date;
    updateTrackingEveryStr = '';
    sendAlert: Date;
    sendAlertStr = '';
    getNotifyFor: any;
    supportEmail: string;
    financeEmail: string;
    smsNumber: string;
    sendAlertWhenPunchInOut: number;
    sendActive: any;
    emailSendTo: string[];
    dayOfWeek: any;
    atHours: Date;
    atHoursStr = '';
    planAssignmentAlertTime?:Date;
    planAssignmentAlertTimeStr = '';
    displayRemainingDays?:boolean = false;
    lastModifiedBy?:string;
    clientPaymentGracePeriod?: boolean = false;
    clockInOutCondition?: string;
    clientInvoiceNotification?: string;
    jobReportNotifySms?: boolean = false;
    jobReportNotifyEmail?: boolean = false;
    jrNeedToNotifyOtherPeople?: string[]; 
    jrNeedToNotifyOtherPeopleArr?: any[];
    jrDayToSendNotify?: string[]; 
    jrSendNotifyEvery?: string;
    jrAllowExpand?: boolean = false;
    employeeEndTimeRemider?:Date;
    employeeEndTimeRemiderStr = '';
    smsQuota?: number;
    smsCount?: number;
    smsQuotaFrequency?:  string;
    smsPrice : any = null;
    smsUsageCost : any = null;
    smsAfterQuota?: string;
    smsAfterQuotaInUse?: number;
    smsAvailableAfterQuota?: number;
    mmsQuota?: number;
    mmsCount?: number;
    mmsQuotaFrequency ?:  string;
    mmsPrice: any = null;
    mmsUsageCost: any = null;
    mmsAfterQuota?: string;
    mmsAfterQuotaInUse?: number;
    mmsAvailableAfterQuota?: number;
    otAlertFormatHour: string = '24';
    timeCorrectionQuota: number;
    timeCorrectionPer?: string;

    credits?: string;
    autoCallMinutes?: string;
    assistanceHours?: string;

    followMeList ?: any[];

    creditDollarValue?: number;
    creditValue?: number;
    creditSmsValue?: number;
    creditMmsValue?: number;
    smsCreditsUsed?: number;
    mmsCreditsUsed?: number;

    bulkMessageLimit?: string;
    alreadyPaid?: string;
    printerDevice?: string;
    residentQrCodeTemplate?: number;
    replySms?: string;
    unitPrice?: any;
    pinFacialRecognition?: string;
    punchValidation?: string;
}
