import { AbstractService } from '../../shared/service/abstract.service';
import { BillingInvoice } from '../model/billinginvoice.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PunchBillingService extends AbstractService<any>{

    public static readonly PUNCH_CLOCK_BILLING = `${environment.v2_server_ip}/${Constants.ROUTES.PUNCH_CLOCK_BILLING}`;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.PUNCH_BILLING, '');
    }

    searchPunchClock(payload: any): Observable<Page<BillingInvoice>> {
        // return this._http.post<Page<BillingInvoice>>('http://localhost:8005/api/v2/clocking/punch-billing/search', payload).pipe(map(resp => resp));
        return this._http.post<Page<BillingInvoice>>(PunchBillingService.PUNCH_CLOCK_BILLING + '/search', payload).pipe(map(resp => resp));
    }

    generate(payload: any, createdByUsr: string): Observable<Page<BillingInvoice>> {
        return this._http.post<Page<BillingInvoice>>(`${this.baseUrlV2}?createdByUsr=${createdByUsr}`, payload).pipe(map(resp => resp));
    }

    exportPdfDetails(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdfDetails/${id}`).pipe(map(res => res));
    }

    exportPdfDetailsByInvoiceId(invoiceId: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdfDetailsByInvoiceId/${invoiceId}`).pipe(map(res => res));
    }

    exportPdf(options: any) {
        return this._http.post<any>(`${this.baseUrlV2}exportPdf`, options).pipe(map(res => res));
    }
}

