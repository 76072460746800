import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import * as moment from 'moment';
import { PaymentInvoice } from '../model/payment-invoice.model';
import { Package } from '../../phonesystem/model/package.model';
import { Message } from 'primeng/api';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { map } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class PaymentInvoiceService extends AbstractService<PaymentInvoice> {
    private messageSubject = new Subject<Message>();
    message$ = this.messageSubject.asObservable();
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.OPERATOR + '/billing/invoice', '');

    }
    notifyMessage(message: Message) {
        this.messageSubject.next(message);
    }
    getAllList(options?: any): Observable<Package[]> {
        const params = this.createParams(options);
        return this._http.get<Package[]>(this.baseUrlV2 + '/all', { params: params }).pipe(map(res => res));
    }
    getListByPage(params: any): Observable<Page<Package>> {
        return this._http.get<Page<Package>>(this.baseUrlV2, { params: params }).pipe(map(res => res));
    }

    getTotalPayment(options: any): any {
        return this._http.post<any>(this.baseUrlV2 + 'invoicePayment', options).pipe(map(res => res));
        // return this._http.post<any>('http://localhost:8007/api/v2/operation/billing/invoice/invoicePayment', options).pipe(map(res => res));
    }

    invoiceOutStandingBalance(options: any): any {
        return this._http.post<any>(this.baseUrlV2 + 'invoiceOutStandingBalance', options).pipe(map(res => res));
    }

    checkGenerate(options: any): Observable<Page<Package>> {
        if (options.toDate) {
            options.toDate = moment(options.toDate).toISOString(true);
        }

        if (options.fromDate) {
            options.fromDate = moment(options.fromDate).toISOString(true);
        }

        const reqUrl = `${this.baseUrlV2}${Constants.URI.PAYMENT_INVOICE.CHECKGENERATE}`;
        return this._http.post<Page<Package>>(reqUrl, options).pipe(map(res => res));
    }
    generateGenerate(options: any): Observable<Page<Package>> {
        options.toDate = moment(options.toDate).toISOString(true);
        options.fromDate = moment(options.fromDate).toISOString(true);
        const reqUrl = `${this.baseUrlV2}${Constants.URI.BILLING_INVOICE.GENERATE}`;
        return this._http.post<Page<Package>>(reqUrl, options).pipe(map(res => res));
    }

    filter(options: any): Observable<Page<Package>> {
        const option = Object.assign({}, options);
        const reqUrl = `${this.baseUrlV2}${Constants.URI.BILLING_INVOICE.FILTER}`;
        // const reqUrl = `http://localhost:8007/api/v2/operation/billing/invoice/search`;
        return this._http.post<Page<Package>>(reqUrl, option).pipe(map(res => res));
    }

    filterPublic(options: any): Observable<Page<Package>> {
        const envUrl = `${environment.v2_server_ip}/` + 'operation-service/api/v2/operation/billing/invoice/';
        const option = Object.assign({}, options);
        const reqUrl = `${envUrl}${Constants.URI.BILLING_INVOICE.PUBLIC_INVOICE_SEARCH}`;
        // const reqUrl = `http://localhost:8007/api/v2/operation/billing/invoice/search`;
        return this._http.post<Page<Package>>(reqUrl, option).pipe(map(res => res));
    }

    exportPdf(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdf/${id}`)
            .pipe(map(res => res));
    }

    exportExcel(options: any): Observable<any> {
        const option = Object.assign({}, options);
        const reqUrl = `${this.baseUrlV2}export-excel`;
        // const reqUrl = `http://localhost:8007/api/v2/operation/billing/invoice/export-excel`;
        return this._http.post<Page<Package>>(reqUrl, option).pipe(map(res => res));
    }

    exportPdfByInvoiceNumber(invoiceNumber: string): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdf-invoice-number/${invoiceNumber}`)
            .pipe(map(res => res));
    }
    

    exportReportPdf(options: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}exportPdf`, options)
            .pipe(map(res => res));
    }

    exportTablePdf(data): Observable<any> {
        //return this._http.post(`http://localhost:8007/api/v2/operation/billing/invoice/exportTablePdf`, data).pipe(map(resp => resp));
        return this._http.post(`${this.baseUrlV2}exportTablePdf`, data).pipe(map(resp => resp));
    }

    updateInactiveStatus(id: number): Observable<any> {
        return this._http.get(`${this.baseUrlV2}${id}/in-active`).pipe(map(resp => resp));
    }

    inactiveInvoice(payload): Observable<any> {
        return this._http.post(`${this.baseUrlV2}in-active`, payload).pipe(map(resp => resp));
    }

    sendEmailReminder(data, notificationType): Observable<any> {
        return this._http.post(`${this.baseUrlV2}send-billing-reminder?notificationType=${notificationType}`, data).pipe(map(resp => resp));
    }

    exportPdfWithName(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdf-file-name/${id}`)
            .pipe(map(res => res));
    }


    exportPdfWithNameByInvoiceNumber(invoiceNumber: string): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdf-file-name-invoice-number/${invoiceNumber}`)
            .pipe(map(res => res));
    }

    generatePdfLink(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdf-link/${id}`)
            .pipe(map(res => res));
    }

    getAllBillingInvoiceDropdownList(options: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}dropdown`, options)
        .pipe(map(res => res));
    }

    updateDateApplyCredit(options: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}apply-credit`, options)
        .pipe(map(res => res));
    }

    searchByPost(options: any): Observable<Page<Package>> {
        return this._http.post<any>(`${this.baseUrlV2 + Constants.URI.BILLING_INVOICE.FILTER}`, options)
        .pipe(map(res => res));
    }

    searchCreditAmount(options: any): Observable<Page<Package>> {
        return this._http.post<any>(`${this.baseUrlV2 + 'credit-amount/' + Constants.URI.BILLING_INVOICE.FILTER}`, options)
        .pipe(map(res => res));
    }

    exportCreditPdf(options: any): Observable<Page<Package>> {
        return this._http.post<any>(`${this.baseUrlV2 + 'credit-amount/exportCreditPdf'}`, options)
            .pipe(map(res => res));
    }

    updateApplyTransaction(id: number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}apply-transaction/${id}`)
        .pipe(map(res => res));
    }

    getAllBillingInvoice(options: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}invoice-dropdown`, options)
        .pipe(map(res => res));
    }

    exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {
    
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const wscols = [ {wch:12},{wch:12}, {wch:12},{wch:12},{wch:12},{wch:12}
            ,{wch:15},{wch:16},{wch:15},{wch:14}];
      
        worksheet['!cols'] = wscols;
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
        if (fileExtension) {
          this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
        } else {
          this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
        }
    }

    saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
        let downloadFileExtension: string = EXCEL_EXTENSION;
        if (fileExtension) {
            downloadFileExtension = fileExtension;
        }
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
    }

    getBillingInvoiceById(id){
        return this._http.get<any>(`${this.baseUrlV2}${id}`)
    }

    autoPayInvoices(payload) {
        return this._http.post<any>(this.baseUrlV2 + 'auto-pay-invoices', payload).pipe(map(res => res));
    }

    outstandingBalancePay(payload) {
        return this._http.post<any>(this.baseUrlV2 + 'outstanding-balance-pay', payload).pipe(map(res => res));
    }

    getPendingTransaction(id) {
        return this._http.get<any>(`${this.baseUrlV2}pending-transaction/${id}`).pipe(map(res => res));
    }

    confirmPendingTransaction(payload) {
        return this._http.post<any>(this.baseUrlV2 + 'confirm-pending-transaction', payload).pipe(map(res => res));
    }

    searchClientSelfPayment(payload) {
        // return this._http.post<any>('http://localhost:8007/api/v2/operation/billing/invoice/client-self-payment/search', payload).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2 + 'client-self-payment/search', payload).pipe(map(res => res));
    }

    updateDiscount(id: number, discountType: string, discountValue: number): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}update-discount/${id}?discountType=${discountType}&discountValue=${discountValue}`, {})
        .pipe(map(res => res));
    }

    waiveLateFee(id: number): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}waive-late-fee/${id}`, {}).pipe(map(res => res));
    }

    exportPdfClientSelfPayment(payload): Observable<any> {
        // return this._http.post<any>('http://localhost:8007/api/v2/operation/billing/invoice/client-self-payment/export-pdf', payload).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2 + 'client-self-payment/export-pdf', payload).pipe(map(res => res));
    }

    updateDiscountV1(id: number, body: any): Observable<any> {
        // return this._http.put<any>(`http://localhost:8007/api/v2/operation/billing/invoice/v1/update-discount/${id}`, body).pipe(map(res => res));
        return this._http.put<any>(`${this.baseUrlV2}v1/update-discount/${id}`, body).pipe(map(res => res));
    }

    waiveLateFeeV1(id: number, body: any): Observable<any> {
        // return this._http.put<any>(`http://localhost:8007/api/v2/operation/billing/invoice/v1/waive-late-fee/${id}`, body).pipe(map(res => res));
        return this._http.put<any>(`${this.baseUrlV2}v1/waive-late-fee/${id}`, body).pipe(map(res => res));
    }

    waiveFee(id: number, body: any): Observable<any> {
        // return this._http.put<any>(`http://localhost:8007/api/v2/operation/billing/invoice/v1/waive-late-fee/${id}`, body).pipe(map(res => res));
        return this._http.put<any>(`${this.baseUrlV2}/waive-fee/${id}`, body).pipe(map(res => res));
    }

    searchDiscountAndWaiveFee(options: any): Observable<Page<Package>> {
        // return this._http.post<any>(`http://localhost:8007/api/v2/operation/billing/invoice/discount-search`, options).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}discount-search`, options).pipe(map(res => res));
    }

    exportPdfDiscountAndWaiveFee(payload): Observable<any> {
        // return this._http.post<any>('http://localhost:8007/api/v2/operation/billing/invoice/discount-waive-fee/export-pdf', payload).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2 + 'discount-waive-fee/export-pdf', payload).pipe(map(res => res));
    }

    sendEmailStatement(option): Observable<any> {
        // return this._http.post<any>('http://localhost:8007/api/v2/operation/billing/invoice/statement-balance/send-email-statement', option).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2 + 'statement-balance/send-email-statement', option).pipe(map(res => res));
    }

    createInvoice(entity: any): Observable<any> {
        //return this._http.post<any>('http://localhost:8007/api/v2/operation/billing/invoice', entity).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
    }

    getClientInvoiceStatus(options: any): Observable<Page<Package>> {
        const option = Object.assign({}, options);
        const reqUrl = `${this.baseUrlV2}client-invoice-status`;
        return this._http.post<Page<Package>>(reqUrl, option).pipe(map(res => res));
    }

    invoiceSummarySearch(options: any): Observable<Page<Package>> {
        const option = Object.assign({}, options);
        const reqUrl = `${this.baseUrlV2}${Constants.URI.BILLING_INVOICE.INVOICE_SUMMARY_SEARCH}`;
        // const reqUrl = `http://localhost:8007/api/v2/operation/billing/invoice/invoice-summary/search`;
        return this._http.post<Page<Package>>(reqUrl, option).pipe(map(res => res));
    }

    exportInvoices(options: any): Observable<Page<Package>> {
        const option = Object.assign({}, options);
        const reqUrl = `${this.baseUrlV2}${Constants.URI.BILLING_INVOICE.EXPORT_INVOICES}`;
        // const reqUrl = `http://localhost:8007/api/v2/operation/billing/invoice/export-invoices`;
        return this._http.post<Page<Package>>(reqUrl, option).pipe(map(res => res));
    }

    getAllInvoicesDropdown(options: any): Observable<Page<Package>> {
        const option = Object.assign({}, options);
        const reqUrl = `${this.baseUrlV2}${Constants.URI.BILLING_INVOICE.GET_INVOICE_DROPDOWN}`;
        // const reqUrl = `http://localhost:8007/api/v2/operation/billing/invoice/get-all-invoice`;
        return this._http.post<Page<Package>>(reqUrl, option).pipe(map(res => res));
    }
}
