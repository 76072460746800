<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="spinner-overlay" *ngIf="exportLoading">
	<p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<h1 class="m-0 ml-2 my-4">{{'Transactions' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Transactions' | translate}}</span>
	</p-header>
    <div class="ui-g">
        <div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'companyLabel' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="allCompanyList" optionLabel="value" dataKey="key" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
        	</div>
		</div>
        <div class="ui-g-6 ui-sm-12">
			<label>{{'TransactionType' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-filter-circle-dollar"></i>
					</button>
				</div>
				<p-dropdown [options]="allTransactionTypes" placeholder="{{'pleaseSelect' | translate}}" [showClear]="true" [(ngModel)]="selectedTransactionType" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
        <div class="ui-g-6 ui-sm-12">
			<label>{{'dateRange' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
        <div class="ui-g-6 ui-sm-12">
			<label>{{'Project' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-diagram-project"></i>
					</button>
				</div>
				<p-dropdown [options]="allProjects" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedProject" dataKey="value" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
        <div class="ui-g-6 ui-sm-12">
			<label>{{'user' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [options]="allAdminNameList" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCreatedBy" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
        <div class="ui-g-6 ui-sm-12">
			<label>{{'Client' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<p-dropdown [options]="clientList" [filter]="true" optionLabel="value" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedClient" emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
    	<div class="ui-g-6 ui-sm-12">
            <label>{{'Contractor' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hard-hat"></i>
					</button>
				</div>
				<p-dropdown [options]="allCreatedByUser" [(ngModel)]="selectedByCreatedByUser" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" placeholder="{{'pleaseSelect' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Account #' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [options]="billingAccounts" [(ngModel)]="selectedBillingAccount" [filter]="true" placeholder="{{'pleaseSelect' | translate}}" emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Verify' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-check-double"></i>
					</button>
				</div>
				<p-multiSelect [options]="verifyOptions" [(ngModel)]="selectedVerify" [filter]="true" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Attachment' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-paperclip"></i>
					</button>
				</div>
				<p-multiSelect [options]="attachmentOptions" [(ngModel)]="selectedAttachments" [filter]="true" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'PaymentMethod' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-wallet"></i>
					</button>
				</div>
				<p-multiSelect [options]="paymentMethods" defaultLabel="{{'Select Items' | translate}}"  [(ngModel)]="selectedPaymentMethods" [filter]="false">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Invoice' | translate}} #:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-file-invoice-dollar"></i>
					</button>
				</div>
				<p-dropdown [options]="allInvoicesDropdownData" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}"
							[(ngModel)]="invoiceId" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}" [autoDisplayFirst]="false">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Invoice Type' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-tree"></i>
					</button>
				</div>
				<p-dropdown [options]="invoiceTypes" [(ngModel)]="invoiceType" placeholder="{{'pleaseSelect' | translate}}" [autoDisplayFirst]="false">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'Reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1"  (click)="searchList();"><i class="fa-solid fa-check mr-2"></i>{{'Search' | translate}}</button>
		</div>
    </div>
</p-panel>
<div class="ui-g" style="margin: 10px -7px;">

		<p-panel [toggleable]="true"  class="ui-g-4 ui-sm-12">
			<p-header class="flex-grow-1 text-truncate">
				<span><i class="fa-solid fa-circle-check text-primary mr-2"></i> {{'Available' | translate}}</span>
			</p-header>
			<div class="ui-g">
				<div class="ui-g-12 text-center">
					<h2 class="m-0 ml-2" pTooltip="{{availableBalance | currency : 'USD':'symbol':'1.2-2'}}">{{availableBalance | currency : 'USD':'symbol':'1.2-2'}}</h2>
				</div>
			</div>
		</p-panel>

		<p-panel [toggleable]="true"  class="ui-g-4 ui-sm-12">
			<p-header class="flex-grow-1 text-truncate">
				<span><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i> {{'Yesterday' | translate}}</span>
			</p-header>
			<div class="ui-g">
				<div class="ui-g-12 text-center">
					<h2 class="m-0 ml-2" pTooltip="{{previousDayBalance | currency : 'USD':'symbol':'1.2-2'}}">{{previousDayBalance | currency : 'USD':'symbol':'1.2-2'}}</h2>
				</div>
			</div>
		</p-panel>

		<p-panel [toggleable]="true"  class="ui-g-4 ui-sm-12">
			<p-header class="flex-grow-1 text-truncate">
				<span><i class="fa-solid fa-calculator text-primary mr-2"></i> {{'Difference' | translate}}</span>
			</p-header>
			<div class="ui-g">
				<div class="ui-g-12 text-center">
					<h2 class="m-0 ml-2 " pTooltip="{{difference | currency : 'USD':'symbol':'1.2-2'}}">{{difference | currency : 'USD':'symbol':'1.2-2'}}</h2>
				</div>
			</div>
		</p-panel>
</div>
<div class="d-flex flex-wrap align-items-center justify-content-between pb-4">
	<h1 class="m-0 ml-2">{{'Browse Transactions' | translate}}</h1>
	<button class="btn btn-primary" (click)="addTransaction()" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Transactions' | translate}} ({{totalRecords || 0 | number}})</span>
	</p-header>
    <p-table id="main-table" [value]="transactions" dataKey="id" selectionMode="single" [lazy]="true" (onLazyLoad)="loadLazy(1, $event)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [columns]="cols" [responsive]="true" (sortFunction)="customSort($event)" [customSort]="true">
		<ng-template pTemplate="caption">
			<div class="d-flex flex-wrap align-items-center justify-content-between" style="row-gap: 10px">
				<div class="d-flex align-items-center flex-grow-1">
					<button type="button" class="btn btn-primary mx-1" (click)="op5.toggle($event)" >
						<span class="text-nowrap"><i class="fa-solid fa-file-export mr-2"></i>{{'Export' | translate}}</span>
					</button>
					<button type="button" class="btn btn-purple mx-1" (click)="openViewGraphDialog()">
						<span class="text-nowrap"><i class="fa-solid fa-bar-chart mr-2"></i>{{'Graph' | translate}}</span>
					</button>
					<div class="input-group w-100 mx-1" style="max-width: 250px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" type="button" style="cursor:default !important">
								<i class="fa-solid fa-table"></i>
							</button>
						</div>
<!--						<p-dropdown [options]="listFilterOptions" [(ngModel)]="selectedShowOptions" (onChange)="getClientBase()" placeholder="{{'pleaseSelect' | translate}}">-->
<!--							<ng-template pTemplate="item" let-item>-->
<!--								<span class="text-truncate">{{item.label | translate}}</span>-->
<!--							</ng-template>-->
<!--							<ng-template let-item pTemplate="selectedItem">-->
<!--								<span class="one-liner">{{item.label | translate}}</span>-->
<!--							</ng-template>-->
<!--						</p-dropdown>-->
						<p-multiSelect [options]="listFilterOptions" defaultLabel="{{'pleaseSelect' | translate}}"  [(ngModel)]="selectedShowOptions"
									   [ngModelOptions]="{standalone: true}" [filter]="false" class="w-100" (onChange)="onSelectColumn($event)">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate" style="width: 93% !important">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItemsLabel">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-multiSelect>
					</div>
				</div>
				<div class="input-group w-100 mr-2" style="max-width: 400px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input [(ngModel)]="textSearchStr" (keyup)="loadLazy(2, $event)" placeholder="{{'Filter' | translate}}" pInputText type="text">
				</div>
			</div>
		</ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [hidden]="col.field==='actions' && !(isEditable || isDeleteAble)">
                    <ng-container [ngSwitch]="col.field" [style.width]="col?.width">
                        <span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="!col.sort">
									<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order" [pSortableColumn]="col.field"></p-sortIcon>
								</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
                        </span>
						<span *ngSwitchCase="'date'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
                        </span>
						<span *ngSwitchCase="'document'" class="text-center justify-content-center">
							<i class="fa-solid fa-paperclip text-muted m-auto" pTooltip="{{'Attachements' | translate}}" tooltipPosition="left"></i>
						</span>
						<span *ngSwitchCase="'actions'">
							<span class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
                    </ng-container>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
			<tr *ngIf="loading">
                <td [attr.colspan]="columns?.length" class="text-center py-5">
					<span class="text-muted">{{'Loading...' | translate}}</span>
                </td>
            </tr>
            <tr *ngIf="!loading" [pSelectableRow]="rowData">
                <td [style.width.%]="12">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created Date' | translate}}: </span>
						<span *ngIf="rowData.date" pTooltip="{{rowData.date | date : 'M/d/yyyy h:mm:ss a'}}">{{rowData.date | date : 'M/d/yyyy h:mm:ss a'}}</span>
						<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Method' | translate}}: </span>
						<span *ngIf="rowData.transactionTypeName" pTooltip="{{rowData?.transactionTypeName}}">{{rowData?.transactionTypeName | translate}}</span>
						<span *ngIf="!rowData.transactionTypeName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cash In' | translate}}: </span>
						<span *ngIf="rowData.cashIn" pTooltip="{{rowData.cashIn | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.cashIn | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.cashIn" class="text-muted">$0</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cash Kept' | translate}}: </span>
						<span *ngIf="rowData.cashKept" pTooltip="{{rowData.cashKept | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.cashKept | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.cashKept" class="text-muted">$0</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cash Out' | translate}}: </span>
						<span *ngIf="rowData.cashOut" pTooltip="{{rowData.cashOut | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.cashOut | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.cashOut" class="text-muted">$0</span>
						
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Net' | translate}}: </span>
						<span *ngIf="rowData.netCashInOut" pTooltip="{{rowData.netCashInOut | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.netCashInOut | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.netCashInOut" class="text-muted">$0</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span>
						<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
						<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td *ngIf="selectedShowOptions && selectedShowOptions.length > 0 && selectedShowOptions?.includes('invoice')">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber"  (click)="clickInvoiceRef($event,rowData);" class="text-primary c-pointer" pTooltip="{{rowData.invoiceNumber}}">{{rowData.invoiceNumber}}</span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td *ngIf="selectedShowOptions && selectedShowOptions.length > 0 && selectedShowOptions?.includes('client')">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>
						<span *ngIf="rowData.nameClient" pTooltip="{{rowData.nameClient}}">{{rowData.nameClient}}</span>
						<span *ngIf="!rowData.nameClient" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td [style.width.%]="12">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Transaction Date' | translate}}: </span>
						<span *ngIf="rowData?.transaction?.transactionDate" pTooltip="{{rowData?.transaction?.transactionDate | date : 'MM/dd/yyyy'}}">{{rowData?.transaction?.transactionDate | date : 'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData?.transaction?.transactionDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td id="attachment">
					<span class="d-flex" style="max-height: unset">
						<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
						<p-dropdown *ngIf="!rowData.fileUrl" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment" [options]="this.noFileOptions" id="attach"
						[style]="{'background': 'transparent', 'border' : '0px', 'width' : '20px', 'min-width' : 'unset', 'height' : '20px'}" (onChange)="onChangeUploadOption($event, rowData)" optionLabel="label" [autoWidth]="false" appendTo="body" [editable]="true" styleClass="custom-input"  pTooltip="{{'Attach File' | translate}}">
						</p-dropdown>
						<p-dropdown *ngIf="rowData.fileUrl" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment1" [options]="this.hasFileOptions" id="attach" 
							[style]="{'background': 'transparent', 'border' : '0px', 'width' : '20px', 'min-width' : 'unset', 'height' : '20px'}" optionLabel="label" placeholder="" (onChange)="onChangeUploadOption($event, rowData)" 
							[autoWidth]="false" appendTo="body" [editable]="true"  styleClass="custom-input green-icon" class="text-success" pTooltip="{{'Edit Attached' | translate}}">
						</p-dropdown>
						<input type="file" id="fileUrl" accept=".jpg, .jpeg, .png, .pdf, .doc, .xls, .xlsx, .webp" style="display: none;" (change)="onSelectDocument($event)">
					</span>
                </td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'1st Verification' | translate}}: </span>
						<span *ngIf="rowData.verification1st" pTooltip="{{getAdminName(rowData.verification1st)}}">{{rowData.verification1st}}</span>
						<span *ngIf="!rowData.verification1st" class="c-pointer text-primary" (click)="openConfirmationPopup('1', rowData.id)"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Confirm' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'2nd Verification' | translate}}: </span>
						<span *ngIf="rowData.verification2st" pTooltip="{{getAdminName(rowData.verification2st)}}">{{rowData.verification2st}}</span>
						<span *ngIf="!rowData.verification2st" class="c-pointer text-primary" (click)="openConfirmationPopup('2', rowData.id)"><i class="fa-solid fa-check-double text-primary mr-2"></i>{{'Confirm' | translate}}</span>
					</span>
				</td>
                <td *ngIf="isEditable && !isDeleteAble" style="width: 45px !important">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
						<i *ngIf="isEditable && rowData?.type != 'group'" class="fa-solid fa-pen-to-square text-primary mx-1 curson-pointer" (click)="viewTransaction(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
						<i *ngIf="isEditable && rowData?.type == 'group'" class="fa-solid fa-pen-to-square text-primary mx-1 curson-pointer" (click)="viewTransactionGroup(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
					</span>
                </td>
				<td *ngIf="!isEditable && isDeleteAble" style="width: 45px !important">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
						<i *ngIf="isDeleteAble && !rowData.showHideActions" class="fa-solid fa-xmark text-danger mx-1 cursor-pointer" (click)="deleteTransaction(rowData)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
					</span>
                </td>
				<td *ngIf="isEditable && isDeleteAble" style="width: 65px !important">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
						<i *ngIf="isEditable && rowData?.type != 'group'" class="fa-solid fa-pen-to-square text-primary mx-1 curson-pointer" (click)="viewTransaction(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
						<i *ngIf="isEditable && rowData?.type == 'group'" class="fa-solid fa-pen-to-square text-primary mx-1 curson-pointer" (click)="viewTransactionGroup(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
						<i *ngIf="isDeleteAble && !rowData.showHideActions" class="fa-solid fa-xmark text-danger mx-1 cursor-pointer" (click)="deleteTransaction(rowData)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
					</span>
                </td>
            </tr>
        </ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading && transactionTypes.length === 0">
				<td [attr.colspan]="columns?.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="columns?.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
        <ng-template pTemplate="footer" let-columns>       
            <tr *ngIf="!loading && transactions.length > 0">
                <td></td>
                <td class="text-right" style="font-weight: 500;">{{'Totals' | translate}} :</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cash In' | translate}}: </span>
						<span *ngIf="totalSum.cashInSum" pTooltip="{{totalSum?.cashInSum | currency : 'USD':'symbol':'1.2-2'}}">{{totalSum?.cashInSum | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!totalSum.cashInSum" class="text-muted">$0</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cash Kept' | translate}}: </span>
						<span *ngIf="totalSum.cashKeptSum" pTooltip="{{totalSum?.cashKeptSum | currency : 'USD':'symbol':'1.2-2'}}">{{totalSum?.cashKeptSum | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!totalSum.cashKeptSum" class="text-muted">$0</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cash Out' | translate}}: </span>
						<span *ngIf="totalSum.cashOutSum" pTooltip="{{totalSum?.cashOutSum | currency : 'USD':'symbol':'1.2-2'}}">{{totalSum?.cashOutSum | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!totalSum.cashOutSum" class="text-muted">$0</span>
					</span>
				</td>
                <td colspan="2">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Net Amount' | translate}}: </span>
						<span *ngIf="totalSum.netAmtSum" pTooltip="{{totalSum?.netAmtSum | currency : 'USD':'symbol':'1.2-2'}}">{{totalSum?.netAmtSum | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!totalSum.netAmtSum" class="text-muted">$0</span>
					</span>
				</td>
            </tr>
        </ng-template>
	</p-table>
</p-panel>

<p-dialog [(visible)]="showConfirmDialog" class="modal_in" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Item' | translate}}</span>
	</p-header>
    <div class="ui-g">
        <div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to delete this item?' | translate}}</span>
		</div>
    </div>
    <p-footer class="d-flex align-items-center justify-content-between">
		<button type="button" (click)="reject()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="accept()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showViewFileDialog" [responsive]="true" [style]="{'width': '90%', 'max-width':'600px', 'max-height':'85vh'}" [modal]="true" id="viewAttachedModal">
	<p-header>
		<span><i class="fa-solid fa-image mr-2"></i>{{'View Attached' | translate}}</span>
	</p-header>
    <img [src]="fileUrl" *ngIf="fileUrl" class="imageShown" style="width: 100%"/>
    <p-footer class="d-flex justify-content-end">
		<button type="button" (click)="closeViewFileDialog()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [showHeader]="true" *ngIf="showHistoryUploadDialog" [(visible)]="showHistoryUploadDialog" [modal]="true" [dismissableMask]="true" class="deparment-history" id="attachmentHistoryModal" [style]="{'width':'90%', 'max-width':'900px', 'max-height':'85vh'}">
    <p-header>
        <span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Attachment History' | translate}} <span *ngIf="(dataSource$|async)?.content">({{(dataSource$|async)?.content.length}})</span></span>
    </p-header>
	<p-table #table [value]="(dataSource$|async)?.content" [rows]="(dataSource$|async)?.size" [paginator]="false" [totalRecords]="(dataSource$|async)?.content.length" (onLazyLoad)="loadDataSource($event)" [lazy]="true" [responsive]="true">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayColumns" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<span class="one-liner" pTooltip="{{ col?.label}}" tooltipPosition="left">{{ col?.label | translate}}</span>
					<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
			<tr [pSelectableRow]="rowData">
				<td *ngFor="let col of displayColumns">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'updatedDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate  | date : 'M/d/yyyy h:mm:ss a'}}">{{rowData.updatedDate | date : 'M/d/yyyy h:mm:ss a'}}</span>
								<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'userName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'User' | translate}}: </span>
								<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
								<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'oldValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Before' | translate}}: </span>
								<a *ngIf="rowData.oldValue && rowData.fieldName === 'fileUrl'"  class="text-primary" href="javascript:void(0)" (click)="viewFullFile(rowData.oldValue)">{{'Open' | translate}}<i class="ml-2 fa-solid fa-up-right-from-square text-primary c-pointer"></i></a>
								<span *ngIf="!rowData.oldValue || !rowData.fieldName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'newValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'After' | translate}}: </span>
								<a *ngIf="rowData.fieldName === 'fileUrl'" class="text-primary" href="javascript:void(0)" (click)="viewFullFile(rowData.newValue)">{{'Open' | translate}}<i class="ml-2 fa-solid fa-up-right-from-square text-primary c-pointer"></i></a>
								<span *ngIf="!rowData.newValue || !rowData.fieldName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'action'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Action' | translate}}: </span>
								<span *ngIf="rowData.action" pTooltip="{{rowData.action}}">{{rowData.action}}</span>
								<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showHistoryUploadDialog = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="showViewGraphDialog"  [style]="{ 'width': '90%', 'max-width': '900px' }" [closable]="true">
	<p-header>
		<span><i class="fa-solid fa-hand-holding-dollar mr-2"></i>{{'Daily Payments' | translate}}</span>
	</p-header>
	<div *ngIf="viewGraphLoading" style="height: 25vw;" class="d-flex justify-content-center">
		<p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
	</div>
	<p-chart *ngIf="!viewGraphLoading" #viewGraphChart type="bar" [data]="viewGraphData" [options]="viewGraphOptions"></p-chart>
    <br>
    <p-footer class="d-flex align-items-center justify-content-between">
		<button type="button" class="btn btn-primary" (click)="previousWeekDays()"> 
			<span class="text-nowrap"><i class="fa-solid fa-chevron-left mr-2"></i>{{'Back' | translate}}</span>
		</button>
		<button type="button" class="btn btn-primary" (click)="getWeekDays()">
			<span class="text-nowrap">{{'Forward' | translate}}<i class="fa-solid fa-chevron-right mr-2"></i></span>
		</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showConfirmationPopup" class="modal_in" [modal]='true' [style]="{'width':'90%','max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Verification' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you verifying this transaction was received?' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" (click)="openConfirmationPopup()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="confirmTransaction()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>


<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClickNew(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClickNew(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClickNew(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-overlayPanel *ngIf="showpanel" [appendTo]="'body'" #op5>
    <p-listbox [options]="downloadOptions" [(ngModel)]="selectedDownload" (onChange)="clickDownladFile($event,op5);">
        <ng-template let-col pTemplate="item">
            <div>
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>

<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
		<ng-template let-col pTemplate="item">
			<div>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<!-- charts for pdf file -->
<div id="piechart" style="display: none;"></div>
<div id="barChar"></div>
