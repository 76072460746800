import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {BreadcrumbService} from '../../../layout/service/breadcrumb.service';

@Component({
  selector: 'app-task-layout',
  templateUrl: './task-layout.component.html',
  styleUrls: ['./task-layout.component.scss']
})
export class TaskLayoutComponent implements OnInit {

  routerEventsSubscribe;
  constructor(  private router: Router,
                private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
              console.log("event.url", event.url);
              // this.breadcrumbService.setItems([
              //     { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' }
              // ]);
              
              // if (event.url.includes('/app/tm/') && !currentUrl.includes('/app/tm/')) {
              //   const projectDetail = JSON.parse(localStorage.getItem("projectDetail"));
              //   console.log(projectDetail);
              //     this.breadcrumbService.setItems([
              //         { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' }, projectDetail
              //     ]);
              // }
          }
      });
  }
  ngOnDestroy() {
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }

}
