<p-toast position="bottom-right">{{msgs}}</p-toast>
<form [formGroup]="addDocumentForm" (ngSubmit)="saveDocument();" #updatePw="ngForm">
    <div *ngIf="documentSettingsId" style="text-align: right">
        <span *ngIf="documentSettingsId" (click)="historyShow = true" style="cursor: pointer !important;"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}} </span>
    </div>
	<div class="ui-g">
        <div class="ui-g-12">
            <label>{{'Operational Policy' | translate}}<span class="required-indicator">*</span>
                <i style="cursor: pointer;" class="ml-2 fa-solid fa-info-circle" (click)="operationalPolicyPanel.show($event)"></i>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-info"></i>
                    </button>
                </div>
                <p-multiSelect class="flex-grow-1" [options]="operationalPolicies" formControlName="operationalPolicy" name="operationalPolicy" [(ngModel)]="documentSetting.operationalPolicy">
                    <ng-template pTemplate="item" let-item>
                        <span class="text-truncate">{{item.label | translate}}</span>
                    </ng-template>
                    <ng-template let-item pTemplate="selectedItem">
                        <span class="one-liner">{{item.label | translate}}</span>
                    </ng-template>
                </p-multiSelect>
            </div>
        </div>
		<div class="ui-g-12">
			<label>{{'Role' | translate}} <span style="color: red">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-multiSelect [options]="documentRoles" [(ngModel)]="documentSetting.selectedRoleEdit" formControlName="role" optionLabel="label" [panelStyle]="{width:'250px'}" appendTo="body">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-12">
			<label>{{'documentName' | translate}} <span style="color: red">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</div>
				<input type="text" pInputText class="w-90" formControlName="documentName" [(ngModel)]="documentSetting.documentNameEdit" [maxLength]="100" (ngModelChange)="onchangeText()"/>
			</div>
			<span *ngIf="charactersLength < 100"  class="text-muted pull-right"  style="font-size: 12px;">{{charactersLength}}/100</span>
			<span *ngIf="charactersLength >= 100"  class="text-danger pull-right"  style="font-size: 12px;">{{charactersLength}}/100</span>
		</div>
        <div class="ui-g-12">
            <label>{{'Information' | translate}}</label>
            <div class="input-group w-100">
<!--                <textarea rows="3" style="resize: none; overflow-y: auto" formControlName="information" class="w-100" [(ngModel)]="documentSetting.information" maxlength="65000"></textarea>-->
              <p-editor formControlName="information" #detailsEditor class="w-100"
                        [(ngModel)]="documentSetting.information" [style]="{'height':'200px', 'overflow-y':'auto'}">
                <p-header>
                  <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline" aria-label="Underline"></button>
                  </span>
                </p-header>
              </p-editor>
            </div>
        </div>
    <div class="ui-g-12 ui-sm-12">
      <div class="input-group w-100">
        <p-checkbox formControlName="confirmSetting" [(ngModel)]="documentSetting.confirmSetting" [binary]="true" inputId="confirmSetting" label="{{'Receipt confirmation required' | translate}}"></p-checkbox>
      </div>
    </div>
		<div class="ui-g-12 ui-sm-12">
			<div class="input-group w-100">
                <p-checkbox formControlName="addExpDate" [(ngModel)]="documentSetting.addExpDate" [binary]="true" inputId="addExpDate" label="{{'Add Expiration Date' | translate}}"></p-checkbox>
			</div>
		</div>
		<div class="ui-g-12">
			<label>{{'Required?' | translate}} </label>
			<p-checkbox formControlName="required" [(ngModel)]="documentSetting.isRequiredEdit" [binary]="true" inputId="required" label="{{'Make Required' | translate}}"></p-checkbox>
		</div>
<!--        <div class="ui-g-12">-->
<!--            <label>{{'Upload' | translate}}</label>-->
<!--            <div class="input-group w-100">-->
<!--                <div class="input-group-prepend p-0">-->
<!--                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
<!--                        <i class="fa-solid" class="fa fa-paperclip"></i>-->
<!--                    </button>-->
<!--                </div>-->
<!--                <div class="form-control d-flex justify-content-between align-items-center" style="height: 40px;line-height: 2;overflow: hidden;">-->
<!--                    <div *ngIf="documentSetting?.material && fileName"  class="d-flex align-items-center justify-content-between flex-wrap w-100">-->
<!--                        <span class="one-liner" style="max-height: 2rem;line-height: 2;">{{fileName}}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <input type="file" hidden (change)="uploadFile($event)" accept=".docx,.pdf,.doc,.xlsx,.xls,.txt,image/*" #upload>-->
<!--                &lt;!&ndash;<input *ngIf="todo" type="text" pInputText [(ngModel)]="todo" accept=".docx,.pdf,.doc,.xlsx,.xls,.txt,image/*" disabled>&ndash;&gt;-->
<!--                &lt;!&ndash;<input *ngIf="!todo" type="text" pInputText [(ngModel)]="todo" accept=".docx,.pdf,.doc,.xlsx,.xls,.txt,image/*" placeholder="{{'Upload File' | translate}}" disabled>&ndash;&gt;-->
<!--                <div class="input-group-append">-->
<!--                    <button *ngIf="documentSetting?.material" class="btn btn-white" id="button-addon2" type="button" (click)="uploadFilePanel.toggle($event)" style="cursor:default !important">-->
<!--                        <i class="fa-solid fa-upload text-primary"></i>-->
<!--                    </button>-->
<!--                    <button *ngIf="!documentSetting?.material" class="btn btn-white" id="button-addon2" type="button" (click)="onUploadDocument(uploadFilePanel)"  pTooltip="{{'Upload' | translate}}" tooltipPosition="left" style="cursor:default !important">-->
<!--                        <i class="fa-solid fa-upload text-primary"></i>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--            <p-overlayPanel #uploadFilePanel appendTo="body">-->
<!--                <ng-template pTemplate>-->
<!--                    <ul class="m-0 px-1" style="list-style: none; min-width: 150px;">-->
<!--                        <li style="cursor: pointer;" class="px-2" *ngIf="!documentSetting?.material">-->
<!--                            <span (click)="onUploadDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-upload text-primary mr-2"></i>{{'upload' | translate}}</span>-->
<!--                        </li>-->
<!--                        <li style="cursor: pointer;" class="px-2" *ngIf=" documentSetting?.material">-->
<!--                            <span (click)="onViewDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{'view' | translate}}</span>-->
<!--                        </li>-->
<!--                        <li style="cursor: pointer;" class="px-2" *ngIf="documentSetting?.material">-->
<!--                            <span (click)="onChangeDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-recycle text-primary mr-2"></i>{{'change' | translate}}</span>-->
<!--                        </li>-->
<!--                        <li style="cursor: pointer;" class="px-2" *ngIf="documentSetting?.material">-->
<!--                            <span (click)="onRemoveDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-xmark text-danger mr-2"></i>{{'remove' | translate}}</span>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </ng-template>-->
<!--            </p-overlayPanel>-->
<!--        </div>-->
    <div class="ui-g-12">
      <label>{{'Attachment' | translate}}</label>
      <div class="input-group w-100">
        <div class="input-group-prepend p-0 c-pointer">
          <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="onUploadDocument(fileUpload)">
            <i class="fa-solid fa-paperclip"></i>
          </button>
        </div>
        <div (click)="onUploadDocument(fileUpload)" class="form-control d-flex justify-content-between align-items-start c-pointer" style="height: 40px;line-height: 2;overflow: hidden;">
          <div *ngIf="documentSetting && !documentSetting.documentIds" class="d-flex align-items-center flex-wrap flex-grow-1">
							<span class="one-liner" style="max-height: 2rem;line-height: 2;">
								{{'Click or Drag & Drop' | translate}}
								<div class="upload-process" *ngIf="processing">
									<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
										<style>
											.line {
												fill: none;
												stroke: #aaa;
												stroke-width: 8;
												stroke-dasharray: 40
											}

											.animated {
												animation: draw .8s ease-in-out;
												animation-iteration-count: infinite;
												stroke-dashoffset: 0
											}

											.line2 {
												animation-delay: .08s
											}

											.line3 {
												animation-delay: .16s
											}

											@keyframes draw {
												0% {
													stroke-dashoffset: 0
												}

												50% {
													stroke-dashoffset: 30
												}

												100% {
													stroke-dashoffset: 0
												}
											}
										</style>
										<path class="line animated" d="M14 10V50z" />
										<path class="line animated line2" d="M30 10V50z" />
										<path class="line animated line3" d="M46 10V50z" />
									</svg>
								</div>
							</span>
          </div>
        </div>
        <input type="file" hidden (change)="uploadFile($event)" accept=".docx,.pdf,.doc,.xlsx,.xls,.txt,image/*" #upload multiple>
        <div class="input-group-append">
          <button class="btn btn-white c-pointer" id="button-addon2" type="button" style="cursor:default !important" (click)="onUploadDocument(fileUpload)">
            <i class="fa-solid fa-upload text-primary" [ngClass]="{'text-success': documentSetting && documentSetting?.documentIds && documentSetting?.documentIds.length >= 1}"></i>
          </button>
        </div>
      </div>
      <div *ngIf="documentSetting && documentSetting.documentIds" class="d-flex align-items-center flex-wrap flex-grow-1">
        <div *ngFor="let file of fileUploaded; let i = index" class="w-100">
          <div class="d-flex align-items-center justify-content-between w-100">
            <span class="one-liner" style="max-height: 2rem;line-height: 2;" data-bs-toggle="collapse" data-bs-target="#uploadCollapse" aria-expanded="false" aria-controls="uploadCollapse">{{ i + 1 }}&#41; {{getFileName(file.fileName)}}</span>
            <span class="d-flex">
										<i class="fa-solid fa-arrow-up-right-from-square text-muted text-primary mx-1" (click)="onViewDocument(file); $event.stopPropagation()" pTooltip="Open" tooltipPosition="left"></i>
										<i class="fa-solid fa-xmark text-danger mx-1" (click)="removeFile(file.id); $event.stopPropagation()" pTooltip="Delete" tooltipPosition="left"></i>
									</span>
          </div>
        </div>
      </div>
      <p-overlayPanel [baseZIndex]="99999" #fileUpload appendTo="body">
        <ng-template pTemplate>
          <ul class="m-0 px-1" style="list-style: none; min-width: 150px; line-height: 2">
            <li (click)="onUploadDocument(fileUpload)" style="cursor: pointer;">
              <span class="text-nowrap"><i class="fa-solid fa-file-upload text-primary mr-2"></i> {{'Upload' | translate}}</span>
            </li>
          </ul>
        </ng-template>
      </p-overlayPanel>
    </div>
	</div>
	<hr>
	<div class="ui-g">
		<div class="ui-g-12 d-flex justify-content-between">
			<button type="button" class="btn btn-danger" (click)="closeAddDocumentPopup()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button type="submit" class="btn btn-primary" [disabled]="addDocumentForm.invalid"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</div>
	</div>
</form>

<p-overlayPanel #operationalPolicyPanel appendTo="body">
    <div style=" max-width: 500px;">
        <div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
            <i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
            Information
        </div>
        <div class="px-2 py-3" style="white-space: pre-line;">
            Other Businesses that share common operational elements such as customer service, reservations or bookings, cleanliness,
            and logistical management, making their policies and procedures similar to those of restaurants:
        </div>
        <div class="ui-g">
            <div class="ui-g-6 p-0">
                <ul>
                    <li>Brewpubs</li>
                    <li>Gastropubs</li>
                    <li>Nightclubs</li>
                    <li>Cafés with Alcohol</li>
                    <li>Lounges</li>
                    <li>Wine Bars</li>
                    <li>Tapas Bars</li>
                    <li>Bistro</li>
                    <li>Beer Gardens</li>
                    <li>Speakeasies</li>
                    <li>Cider Houses</li>
                    <li>Food Trucks with Alcohol</li>
                </ul>
            </div>
            <div class="ui-g-6 p-0">
                <ul>
                    <li>Hotels</li>
                    <li>Parking Garages</li>
                    <li>Event Venues</li>
                    <li>Cinemas</li>
                    <li>Retail Stores</li>
                    <li>Gyms and Fitness Centers</li>
                    <li>Spas and Wellness Centers</li>
                    <li>Theaters and Performance Venues</li>
                    <li>Theme Parks and Amusement Parks</li>
                    <li>Casinos</li>
                    <li>Conference Centers</li>
                </ul>
            </div>
        </div>
    </div>
</p-overlayPanel>

<p-dialog *ngIf="historyShow" [(visible)]="historyShow" [modal]="true" [style]="{ 'width':'90%', 'max-width':'900px', 'max-height':'85vh'}" appendTo="body">
    <p-header>
        <span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Document Setting History' | translate}}</span>
    </p-header>
    <app-document-setting-history (onHideHistory)="hideHistory()" [settingId]="documentSettingsId"></app-document-setting-history>
    <p-footer class="d-flex justify-content-end">
        <button class="btn btn-primary" (click)="historyShow = false">
            <span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
        </button>
    </p-footer>
</p-dialog>

<p-dialog header="{{'View Document' | translate}}" [(visible)]="showViewFileDialog" [style]="{width: '50vw'}">
    <img class="w-100" [src]="fileUrl" alt="">
</p-dialog>