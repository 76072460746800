import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/model/user/user.model';
import '../../rxjs.operators';
import { Constants, RESPONSE_STATUS } from '../../shared/model/constants';
import * as Crypto from 'crypto-js';
import {AllMenusService} from '../../layout/service/all-menus/all-menus.service';
import { Sip } from '../../shared/model/user/sip.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as timezones from 'countries-and-timezones';

@Injectable({ providedIn: 'root' })
export class LoginService {

    public token: string;

    private apiUrl = Constants;
    private user: User = new User();
    constructor(private http: HttpClient, private cookieService: CookieService, private allMenus: AllMenusService) {
        const activeUser = JSON.parse(localStorage.getItem('globals'));
        this.token = activeUser && activeUser.token;
    }

    login(username: string, password: string, address:any, fromDispatch: boolean): Observable<string>{
        let reqUrl: any;
        const failedMessage = 'Authentication Failed';
        const successMessage = '';
        // if (userType === 1) {
        //     // reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.LOGIN}`;
        //     reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.LOGIN}`;
        // }
        // if (userType === 2) {
        //     reqUrl = `${environment.v2_server_ip}/${this.apiUrl.ROUTES.EMPLOYEE}/${this.apiUrl.URI.EMPLOYEE.LOGIN}`;
        // }
        // if (userType === 3) {
        //     reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.FREELANCER}/${this.apiUrl.URI.FREELANCER.LOGIN}`;
        // }
        // if (userType === 4) {
        //     reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.CRM.CLIENT}/${this.apiUrl.URI.CLIENT.LOGIN}`;
        // }

        // if (userType === 6) {
        //     reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.CRM.CLIENT}/${this.apiUrl.URI.CHILD_CLIENT.LOGIN}`;
        // }

        // if (userType === 5) {
        //     reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.GUESS}/${this.apiUrl.URI.CLIENT.LOGIN}`;
        // }
        reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.USER_LOGIN}`;
        const countryName = timezones.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone).name;
        const reqData = {
            username: username,
            password: fromDispatch ? password : Crypto.MD5(password).toString(),
            applicationType: fromDispatch ? 'OperrDispatch' : 'Computer',
            locationAddress: address,
            countryName: countryName
        };

        const config = {
        cache: false,
        //   params:reqData
        };
        if (reqUrl) {
            return this.http.post(reqUrl, reqData).pipe(map((res: any) => {
                console.log('login res: ', res)
                const resObj = res;
                if (!resObj || !resObj.data || !resObj.data.info) {
                    if (resObj.status === 'INVALID_PASSWORD'){
                        const data = {
                            count: 5 - resObj.data.length,
                            status: resObj.status
                        };
                       return data;
                    }
                    if (resObj.status === 'BLOCK')  {
                        return resObj;
                    }
                    if (resObj.status === 'TERMINATED')  {
                        return resObj;
                    }

                    if(resObj.status === 'ERROR')  {
                        return failedMessage;
                    }

                    if (resObj.status === 'USER_NOT_FOUND') {
                        return 'There was no account registered with the following credentials please make sure you have entered the correct information.';
                    }
                    return resObj.message;
                }
                const userType = username.includes('punchinout_') ? 1 : resObj.data.userType;
                this.cookieService.set('_config', JSON.stringify(resObj.data.info), new Date(resObj.expiration));
                localStorage.setItem('_config', JSON.stringify(resObj.data.info));
                if (userType === 1) {
                    localStorage.setItem('_user', 'admin');
                }
                if (userType === 2) {
                    localStorage.setItem('_user', 'employee');
                }
                if (userType === 3) {
                    localStorage.setItem('_user', 'freelancer');
                    if (JSON.stringify(resObj.data.info.firstLoggedIn) === '1') {
                        if (JSON.stringify(resObj.data.info.freelancerWorkHours) === 'null') {
                            localStorage.setItem('_disableAll', '1');
                        } else {
                            localStorage.setItem('_disableAll', '0');
                        }
                    }
                }
                if (userType === 4) {
                    localStorage.setItem('_user', 'client');
                }
                if (userType === 6) {
                    localStorage.setItem('_user', 'child_client');
                }

                if (userType === 5) {
                    localStorage.setItem('_user', 'guess');
                }

                if (res.status === 'SUCCESS') {
                    const token = res && res.data && res.data.token;
                    if (!token) {
                        return failedMessage;
                    }

                    this.token = token;
                    const tmpCookieObj = this.user;
                    tmpCookieObj.email = resObj.data.info.email;
                    tmpCookieObj.token = resObj.data.token;
                    tmpCookieObj.adminId = resObj.data.info.id;
                    tmpCookieObj.phone = resObj.data.info.phone;
                    if (resObj.data.info.phone) {
                        localStorage.setItem('phoneNumber', resObj.data.info.phone);
                    } else {
                        localStorage.removeItem('phoneNumber');
                    }
                    localStorage.setItem('_userId', resObj.data.info.id);
                    tmpCookieObj.permission = resObj.data.info.role.levelName;
                    tmpCookieObj.permissionId = resObj.data.info.role.id;
                    tmpCookieObj.apiKey = resObj.data.apiKey;
                    tmpCookieObj.projectId = resObj.data.projectId;
                    tmpCookieObj.username = reqData.username;
                    tmpCookieObj.type = userType === 1 ? 'Admin' :  userType === 2 ? 'Employee' : 'Client';
                    tmpCookieObj.messagingSenderId = resObj.data.senderId;
                    tmpCookieObj.vapidKey = resObj.data.vapidKey;
                    tmpCookieObj.menuList = resObj.data.info.menuList;
                    tmpCookieObj.companyId = resObj.data.info.companyId;
                    tmpCookieObj.groupId = resObj.data.info.groupId;
                    tmpCookieObj.agencyId = resObj.data.info.agencyId;
                    tmpCookieObj.did = resObj.data.info.did;
                    tmpCookieObj.extension = resObj.data.info.extension;
                    tmpCookieObj.crmClientId = resObj.data.info.crmClientId;
                    if (resObj.data.info.sipServerInfo) {
                        const sipServerInfo = resObj.data.info.sipServerInfo; 
                        const sip = new Sip();
                        sip.api = sipServerInfo.api;
                        sip.dndApi = sipServerInfo.dndApi;
                        sip.callForwardApi = sipServerInfo.callForwardApi;
                        sip.phoneSettingsApi = sipServerInfo.phoneSettingsApi;
                        sip.callLog = sipServerInfo.callLog;
                        sip.listAvailableDids = sipServerInfo.listAvailableDids;
                        sip.server = sipServerInfo.server;
                        sip.websocketProxyUrl = sipServerInfo.websocketProxyUrl;
                        sip.iceServer = sipServerInfo.iceServer;
                        tmpCookieObj.sip = sip;
                    }
                    tmpCookieObj.actionList = resObj.data.info.actionList;
                    tmpCookieObj.firstName = resObj.data.info.firstName;
                    tmpCookieObj.lastName = resObj.data.info.lastName;
                    tmpCookieObj.pin = resObj.data.info.pin;
                    tmpCookieObj.level = resObj.data.info.role.level;
                    tmpCookieObj.role = resObj.data.info.role;
                    tmpCookieObj.company = resObj.data.info.company;
                    tmpCookieObj.stageState = resObj.data.info.stageState;
                    tmpCookieObj.editList = resObj.data.info.editList;
                    tmpCookieObj.deleteList = resObj.data.info.deleteList;
                    if (resObj.data.info.profilePhoto) {
                        tmpCookieObj.profilePhoto = resObj.data.info.profilePhoto.fileUrl;
                    } else {
                        tmpCookieObj.profilePhoto = 'assets/layout/images/avatar1.png';
                    }

                    if (resObj.data.info.profilePhoto) {
                        tmpCookieObj.profileId = resObj.data.info.profilePhoto;
                    }

                    if (resObj.data.info.menuExtraList) {
                        tmpCookieObj.menuList.concat(resObj.data.info.menuExtraList)
                    }

                    if (tmpCookieObj.level && tmpCookieObj.level === 8) {
                        tmpCookieObj.contactName = resObj.data.info.contactName;
                        tmpCookieObj.companyName = resObj.data.info.companyName;
                        tmpCookieObj.isBillingCustomer = resObj.data.info.isBillingCustomer;
                    }

                    if (tmpCookieObj.level && tmpCookieObj.level !== 8) {
                        tmpCookieObj.isEnableBillingDepartment = resObj.data.isEnableBillingDepartment;
                        tmpCookieObj.isEnableMarketingDepartment = resObj.data.isEnableMarketingDepartment;
                    }
                    if (resObj.data.info.profileImageId) {
                        tmpCookieObj.profileId = resObj.data.info.profileImageId;
                    }
                    tmpCookieObj.loginTime = new Date().getTime();
                    if (resObj.data.info.name) {
                        tmpCookieObj.name = resObj.data.info.name;
                    }
                    if (resObj.data.info.group) {
                        tmpCookieObj.group = resObj.data.info.group;
                        if (tmpCookieObj.level === 7) {
                            tmpCookieObj.menuList = tmpCookieObj.group.menuList;
                            tmpCookieObj.actionList = tmpCookieObj.group.actionList;
                            if (tmpCookieObj.editList) {
                                tmpCookieObj.editList.concat(tmpCookieObj.group.editList);
                            } else {
                                tmpCookieObj.editList = tmpCookieObj.group.editList;
                            }
                            if (tmpCookieObj.deleteList) {
                                tmpCookieObj.deleteList.concat(tmpCookieObj.group.deleteList);
                            } else {
                                tmpCookieObj.deleteList = tmpCookieObj.group.deleteList;
                            }
                            

                            if (resObj.data.info.menuExtraList) {
                                tmpCookieObj.menuList.concat(resObj.data.info.menuExtraList)
                            }
                        }
                    }
                    tmpCookieObj.accountType = resObj.data.info.accountType;
                    tmpCookieObj.defaultDashboard = resObj.data.info.defaultDashboard;
                    this.cookieService.set('_globals', JSON.stringify(tmpCookieObj));
                    localStorage.setItem('_globals', JSON.stringify(tmpCookieObj));
                    localStorage.setItem('serve_notification', "true");
                    this.allMenus.getThemeConfig();
                    // this.loginOnDev3();
                    return successMessage;
                }
                return failedMessage;
            }));
        }
    }

    
    loginOnDev3() {
        const payload = {
          passwordHash: "e10adc3949ba59abbe56e057f20f883e",
          username: "operr1"
        }
        const url = `https://dev3-api.operr.com/base/api/v3/employee/login`
        this.http.post(url, payload).subscribe((res:any) => {
            localStorage.setItem('dev3Auth', res.auth_token)
        })
    }

    getAddress(latitude: number, longitude: number): Observable<any> {
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        return this.http.get(url);
    }
}
