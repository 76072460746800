import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {AuthService} from '../../../shared/service/auth/auth.service';

@Component({
    selector: 'app-payroll-layout',
    templateUrl: './payroll-layout.component.html',
    styleUrls: ['./payroll-layout.component.css']
})
export class PayrollLayoutComponent implements OnInit {

    basicMenutItems: MenuItem[] = [

    ];
    payrollTabMenuItems: MenuItem[] = [];

    activeItem: MenuItem;
    constructor(private router: Router,
                private authService: AuthService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                console.log(event.url);
                if (event.url.indexOf('/app/agency/payroll') >= 0) {
                    this.payrollTabMenuItems.push({label: 'Payroll Setting', routerLink: ['payroll'],
                        visible: this.authService.isEmployeeRole() ? true :
                          this.authService.getLoggedInUserMenuList().includes('42')
                    });
                } else {
                    this.payrollTabMenuItems = [];
                    this.payrollTabMenuItems.push(...this.basicMenutItems);
                }
                console.log(this.payrollTabMenuItems);
            }
        });
    }

    ngOnInit() {
        this.activeItem = this.payrollTabMenuItems[0];
    }

}
