import { DocumentData } from '../../shared/model/document-data.model';
import {PayrollHoliday} from './payroll-holiday.model';
import {Position} from '../../position/model/position.model';
export class PayrollSetting {
    id: number;
    payReference: any;
    useOvertime: number;
    minimumHourDaily: string;
    displayEmployeeNameOnSub: number;
    payrollWeekEnding: any;
    overtimeAfter: string = "40 00";
    overtimeMultipler: number = 1.5;
    vacationTimeCalculationHour: number = 1.3;
    vacationTimeCalculationForEvery: string = "40 00";
    sickTimeCalculationHour: number = 1.3;
    sickTimeCalculationForEvery: string = "40 00";
    agencyId: number;
    mileagePriceFormat: string;
    mileage: Mileage;
    roundOn: string;
    mins: number;
    payrollHolidays: any[] = [];

    sendAlertWhenPunchInOut: string;
    employeeLateHours: Date;
    employeeLateAfterHours: any;
    sendAlertTo: number[];
    allowEmployeePTO: any;
    alertEmployeeEndTime: any;
    redeemHours: number;
    onTimeRate: any;
    frequency: string;
    planAssignmentOption: string;
    frequencyForOtRate: string;
    lunchTimeDuration: any;
    disableOvertime: boolean;
    showTakeOvertime: boolean;
    vacationMethod:string;
    maxSickLeave:string;
    maxVacation:string;
    customizedHour: any;
    customizedMinute: any;
    sendTime: any;
    intervalTime: any;
    holidayPay: any;
    holidayPayType: any[];
    companyId: number;
    takeHourBack:string;
    isTakeHourBack:boolean;
    hoursTakeBackWeekly: string;
    fromWeekEndDate: Date;
    hoursTakeDaily: string;
    isOneTime: boolean;
    transferFeeCashOut: number;
    company: any;
    transferFeeFixedAmount: number;
    lateFee: number;
    employeeLatenessDecision: number;
    jobReportVsEndWork: string;
    createdByUsr: string;
    lastModifiedBy: string;
    delayOver: number;
    clockOnDayOff: number;
    checkLunchTimeLimit: number;
    lunchTimeLimit: string;
    punchDailyRate: number;
    punchTrackingRate: number;
    employeeLateLimit: number;
    jobReportMissingLimit: number;
    beyondLimitationDecision: number;
    employeeLateSuspend: number;
    employeeLateHoursDays: number;
    employeeEarlySuspend: number;
    employeeEarlyHoursDays: number;
    employeeEarlyDecision: number;
    employeeEarlyRules: string;
    employeeEarlyAfterHours: any;
    employeeLateTimes: number;
    employeeLatePer: number;
    employeeEarlyPer: number;
    employeeLateRules: string;
    employeeEarlyTimes: number;
    readerId: string;
    testMode: boolean;
    testCardNumber: string;
    donationLimit: any;
    fundraisersFee: any;
    fundraisingMaxLimitation: any;
    allowMonitorComeLateOrLeaveEarly: boolean;
}

export class Mileage {
    mileagePrice: any;
    perMile: any;
}
