import { ContactInfoMapping } from "../../employee/model/contact-info-mapping";
import { PhonePackage } from "../../phonesystem/model/phone-package.model";
import { Agency } from "../../payroll-setting/model/agency.model";
import { CompanySetting } from "./company-setting.model";

export class Company {
    id: number;
    licenseNumber: string;
    federalTax: string;
    federalTaxStart: Date;
    federalTaxExpire: Date;
    federalTaxStatus: number;
    stateTax: string;
    stateTaxStart: Date;
    stateTaxExpire: Date;
    stateTaxStatus: number;
    name: string;
    phone: any;
    email: string;
    fax: any;
    addressOne: string;
    addressTwo: string;
    worktimeStart: Date;
    worktimeEnd: Date;
    city: string;
    state: string;
    zipcode: string;
    status: number;
    branchAgency: number;
    type: string;
    daysWork: string[] | string;
    timezone: string;
    contactInfoMapping: ContactInfoMapping;
    agencies: Agency[];
    companySetting: CompanySetting;
    serviceTypeIds: number[];
    key?: number
    value?: string;
    createdAt?:any;
    operrTel?:any;
    planId: string;
    planName: string;
}

