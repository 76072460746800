import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { MenuItem } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {

    private itemsSource = new Subject<MenuItem[]>();
    private isDeactive = new Subject<boolean>();



    itemsHandler = this.itemsSource.asObservable();
    deactiveHandler = this.isDeactive.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }

    setDeactive(bl: boolean) {
        this.isDeactive.next(bl);
    }
}