import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { CompanyServicev2 } from '../../service/company.v2.service';
import { CompanyV2, ServiceTypes } from '../../model/v2/company.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { Constants } from 'app/shared/model/constants';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CompanyAdminService } from 'app/admin/company-admin/service/company-admin.service';
import { AgencyAdmin } from 'app/admin/agency-admin/model/agency-admin.model';
import * as Crypto from 'crypto-js';
import {LangChangeEvent, TranslatePipe, TranslateService} from '@ngx-translate/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { OverlayPanel } from 'primeng';
import { PaymentProfileService } from 'app/payment/service/payment-profile.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { PaymentCardDefaultService } from 'app/payment/service/payment-card-default.service';
import { catchError, map } from 'rxjs/operators';
import {OperrtelPoolService} from '../../../operrtel-pool/service/operrtel-pool.service';
import { UtilsService } from 'app/shared/service/utils.service';

declare var google: any;
@Component({
    selector: 'app-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.css'],
    providers: [CompanyServicev2, FormValidatorService, AuthService, CompanyAdminService, OperrtelPoolService,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
})
export class CompanyFormComponent implements OnInit {
    @Input() mode: String;
    readOnly: Boolean = false;
    @Input() companyId: number;
    @ViewChild('fdt') table: Table;
    timezones = [];
    timezoneValueList = [];
    company: CompanyV2 = new CompanyV2();
    companyForm: UntypedFormGroup;
    updatePasswordForm: UntypedFormGroup;
    msgs = [];
    isEdit = false;
    hideCurrentPassword: Boolean = true;
    hideNewPassword: Boolean = true;
    hideConfirmPassword: Boolean = true;
    isCorrectPassword: Boolean = true;
    isUpdatePassword = false;
    allowPasswordReset = false;
    punchInOutAdminUserName: string;
    punchInOutAdminId: any;
    loading = false;
    newPassword = '';
    confirmPassword = '';
    oldPassword = '';
    invalidEdit: boolean;
    punchInOutAdmin: AgencyAdmin = new AgencyAdmin();
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = null;
    historyType = '';
    rangeDates: Date[];
    selectedField: string;
    fieldsName = [];
    disableInput: Boolean = false;
    enableSubmitButton = false;
    serviceTypes: ServiceTypes;
    adminData: any = {};
    trackingModeOptions = [
        { label: 'Mode 1', value: 1 },
        { label: 'Mode 2', value: 2 }
    ];
    mealOptions = [
        { label: 'Breakfast', value: 'breakfast' },
        { label: 'Lunch', value: 'lunch' },
        { label: 'Dinner', value: 'dinner' },
    ];
    statusTypes = [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 }
    ];
    operationDays = [
        { label: 'Monday', value: 'Mon' },
        { label: 'Tuesday', value: 'Tue' },
        { label: 'Wednesday', value: 'Wed' },
        { label: 'Thursday', value: 'Thu' },
        { label: 'Friday', value: 'Fri' },
        { label: 'Saturday', value: 'Sat' },
        { label: 'Sunday', value: 'Sun' }
    ];
    companyType = [
        { label: 'companyType.soleProprietorship', value: '1' },
        { label: 'companyType.partnership', value: '2' },
        { label: 'companyType.corporation', value: '3' },
        { label: 'companyType.LLC', value: '4' },
        { label: 'companyType.ministries', value: '5' },
        { label: 'companyType.lawFirm', value: '6' },
    ];
    displayColumns: any[] = [
        { field: 'updatedDate', label: 'Updated', sortable: false },
        { field: 'user', label: 'User', sortable: false },
        { field: 'oldValue', label: 'Old Value', sortable: false },
        { field: 'newValue', label: 'New Value', sortable: false },
        { field: 'action', label: 'Action', sortable: false }
    ];
    operationalPolicies = [
        { label: 'Office', value: 'Office' },
        { label: 'Restaurant/Bar', value: 'Restaurant/Bar' },
    ];
    totalEmployees = [
        { label: '100 or more employees', value: '100 or more employees' },
        { label: '5 to 99 employees', value: '5 to 99 employees' },
        { label: '4 or fewer employees', value: '4 or fewer employees' },
    ];
    disableAfterSave = false;
    historyShow = false;
    isPlatformAdmin = false;
    isCompanyAdmin = false;
    enteredAddressOne = '';
    enteredAddressTwo = '';
    isDisable = false;
    title: string;
    planId: number;
    plans: any;
    percentage = 0;
    interval: NodeJS.Timeout;
    processing: Boolean = false;
    isHovering: Boolean;
    files: File[] = [];
    showFileUpload = false;
    fileUploaded: any = [];
    @ViewChildren('upload') uploadFileRef: QueryList<ElementRef>;
    documents: any;
    useFaceDetection;
    useFaceDetectionUpdating = false;
    displayDialog: Boolean = false;
    fileUrl: SafeResourceUrl;
    isSwitchedOn = false;
    isPinSwitchedOn = false;
    modePunch: string; 

    phoneKeySetupDialog;
    phoneKeySetupForm: UntypedFormGroup;
    hideApiSecret: Boolean = true;
    isDefaultPhoneKey;
    selectedPhoneKeySetup: any = {};
    operrTelProcessMethodOptions = [
        { label: 'One Default Number', value: 'One default number' },
        { label: 'Split Between Available', value: 'Split between the available' }
    ];
    selectedRemoveOperrTelKey: any;
    showRemoveOperrTelConfirmDialog = false;
    removeOperrTelDefault = false;
    listOperrTelPhone = [];
    listOperrTel = [];
    listOperrTelUpdate = [];

    trackByIdentify = (index: number, item: any): number => item.idx;

    subscriptions: Subscription = new Subscription();

    constructor(
        private companyService: CompanyServicev2,
        private route: ActivatedRoute,
        private router: Router,
        private formValidator: FormValidatorService,
        private companyAdminService: CompanyAdminService,
        private authService: AuthService,
        private messageService: MessageService,
        private translate: TranslateService,
        private translatePipe: TranslatePipe,
        private sanitizer: DomSanitizer,
        private paymentProfileService: PaymentProfileService,
        private paymentCardDefaultService: PaymentCardDefaultService,
        private operrtelPoolService: OperrtelPoolService,
        private documentServiceV2: DocumentsServicev2,
        private utilsService: UtilsService,
    ) {
        this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.setDefaultLang(event.lang);
            this.translate.use(event.lang);
        });

    }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.isCompanyAdmin = this.authService.isCompanyAdminRole();
        this.modePunch = 'viewPunch';
        if (this.mode === 'view') {
            this.readOnly = true;
            this.isDisable = true;
        }
        this.statusTypes = [
            { label: 'active', value: 1 },
            { label: 'inactive', value: 0 }
        ];
        this.isEdit = this.authService.getLoggedInUserEditList().includes('5');
        this.company.documentIds = [];
        this.formInit();
        this.loadTimeZones();
        this.getCompany();
        this.getPunchInOutAdmin();
        this.getPaymentCardDefault();
        this.getCardDetails();
        this.loadAllOperrTelPool();
        console.log("this.isSwitchedOn");
        console.log(this.isSwitchedOn);
        this.punchInOutAdminUserName = this.generatePunchInOutAdminUserName();
    }

    ngAfterViewInit() {
        const addressOneBox = new google.maps.places.SearchBox(document.getElementById('addressOne'));
        addressOneBox.addListener('places_changed', () => {
            const places = addressOneBox.getPlaces();
            if (places.length === 0) {
              return;
            }
            this.enteredAddressOne = places[0].formatted_address;
        });
        const addressTwoBox = new google.maps.places.SearchBox(document.getElementById('addressTwo'));
        addressTwoBox.addListener('places_changed', () => {
            const places = addressTwoBox.getPlaces();
            if (places.length === 0) {
              return;
            }
            this.enteredAddressTwo = places[0].formatted_address;
        });
    }

    formInit() {
        this.companyForm = new UntypedFormGroup({
            companyName: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator]),
            // licenseNumber: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator]),
            contactPerson: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            addressOne: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator]),
            addressTwo: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            city: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator]),
            state: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator]),
            zipcode: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator, Validators.pattern('^[0-9]*\\.?[0-9]+$')]),
            phone: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator, this.noLetterValidation]),
            fax: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            email: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, Validators.email]),
            timezone: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            type: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
            status: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            operationalPolicy: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            totalEmployee: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            serviceTypeIds: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            // left
            capacityNum: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            minimumWage: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            cctvUrl: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            agreementSignature: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            meals: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            mainWhiteLabel: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            upperLeftLabel: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            is2ndVerificationEnable: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            offDaysMultiple: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            trackingMode: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            federalTax: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            federalTaxStatus: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            federalTaxStart: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            federalTaxExpire: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            stateTax: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            stateTaxStatus: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            stateTaxStart: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            stateTaxExpire: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            operrTel: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            operrTelProcessMethod: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            autoPay: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            punchInOutAdminUserName: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            specialty: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            website: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            description: new UntypedFormControl({ value: '', disabled: this.readOnly }),
        });

        this.updatePasswordForm = new UntypedFormGroup({
            currentPassword: new UntypedFormControl('', [Validators.required]),
            newPassword: new UntypedFormControl(''),
            confirmNewPassword: new UntypedFormControl('')
        });

        this.phoneKeySetupForm = new UntypedFormGroup({
            apiKey: new UntypedFormControl('', [Validators.required]),
            apiSecret: new UntypedFormControl('', [Validators.required]),
            startDate: new UntypedFormControl('', [Validators.required]),
            endDate: new UntypedFormControl(''),
        });
    }

    addPlanId(planId: any) {
        this.company.planId = planId;
    }

    getCompany() {
        this.companyService.get(this.companyId).subscribe(res => {
            // console.log(res)
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.company = resObj.data;
                // if(resObj.data.type !== '1' && resObj.data.type !== '2' && resObj.data.type !== '3' && resObj.data.type !== '4' && resObj.data.type !== '5') {
                //     // this.invalidEdit = true;
                //     this.companyForm.controls['type'].setValue(null);
                // }
                if (resObj.data.zipcode && resObj.data.zipcode.length > 5) {
                    const zipCode = resObj.data.zipcode.substring(0, 5);
                    this.companyForm.controls['zipcode'].setValue(zipCode);
                } else if (resObj.data.zipcode && resObj.data.zipcode.length < 5) {
                    this.onZipCodeBlur();
                }
                if (this.company.daysWork) {
                    const array = (this.company.daysWork as string).split(' ');
                    this.company.daysWork = array;
                }
                if (this.company.federalTaxExpire) {
                    this.company.federalTaxExpire = new Date(this.company.federalTaxExpire)
                }
                if (this.company.federalTaxStart) {
                    this.company.federalTaxStart = new Date(this.company.federalTaxStart)
                }
                if (this.company.stateTaxExpire) {
                    this.company.stateTaxExpire = new Date(this.company.stateTaxExpire)
                }
                if (this.company.stateTaxStart) {
                    this.company.stateTaxStart = new Date(this.company.stateTaxStart)
                }

                if (this.company.documentIds === null || !this.company.documentIds) {
                    this.company.documentIds = [];
                  } else {
                    const options = {
                        ids: this.company.documentIds.map(Number)
                    };
                    this.getAllFiles(options);
                    this.company.documentIds = [];
                }
                this.listOperrTelPhone.push({label : this.company.operrTel, value: this.company.operrTelId});
                if (this.company.extraOperrTelKeys && this.company.extraOperrTelKeys.length > 0) {
                    for (let i = 0; i < this.company.extraOperrTelKeys.length; i++) {
                        this.company.extraOperrTelKeys[i].idx = this.company.extraOperrTelKeys[i].id;
                        this.companyForm.addControl(`operrTel-${this.company.extraOperrTelKeys[i].id}`, new UntypedFormControl(this.company.extraOperrTelKeys[i], [Validators.required]));
                        this.listOperrTelPhone.push({label : this.company.extraOperrTelKeys[i].phone, value: this.company.extraOperrTelKeys[i].operrtelId});
                    }
                }
                console.log('this.listOperrTelPhone', this.listOperrTelPhone);

                if (this.company.autoPay || this.company.autoPay === 1) {
                    this.companyAutoPay = true;
                } else {
                    this.companyAutoPay = false;
                }
            }

            // console.log(this.company.type);
        });
    }

    getPunchInOutAdmin() {
        this.companyAdminService.getPunchInOutAdmin(this.companyId).subscribe(res => {
            const resObj: any = res;
            console.log('getPunchInOutAdmin', resObj);
            if (resObj.status === 'SUCCESS' && resObj.data) {
                this.punchInOutAdminUserName = resObj.data.username;
                this.punchInOutAdminId = resObj.data.id;
                this.allowPasswordReset = resObj.data.isResetPassword;
                this.useFaceDetection = resObj.data.useFaceDetection;
                this.isSwitchedOn = this.useFaceDetection;
                this.isPinSwitchedOn = resObj.data.pinMethod;
                this.adminData.id = this.punchInOutAdminId;
                this.adminData.useFaceDetection = this.isSwitchedOn;
                this.adminData.pinMethod = this.isPinSwitchedOn;
            }
        });
    }

    updateFaceDetection(value) {
        if (this.useFaceDetectionUpdating) {
            return;
        }
        console.log("this.punchInOutAdminId");
        console.log(this.punchInOutAdminId);
        this.useFaceDetectionUpdating = true;
        this.companyAdminService.changeFaceDetectionSetting(this.punchInOutAdminId, value).subscribe((res: any) => {
            this.useFaceDetection = !this.useFaceDetection;
            this.useFaceDetectionUpdating = false;
        }, () => this.useFaceDetectionUpdating = false);
    }

    loadTimeZones() {
        const tzones = moment.tz.names();
        this.timezones = [];
        tzones.forEach((e) => {
            const offset = ' UTC ' + moment.tz(e).format('Z');
            this.timezones.push({value: moment.tz.zone(e).abbr(360) + ' ' + e + offset, label: moment.tz.zone(e).abbr(360) + ' ' + e + offset});
            this.timezoneValueList.push(moment.tz.zone(e).abbr(360) + ' ' + e + offset);
        });
    }

    noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    noLetterValidation(control: AbstractControl) {
        const value = control.value;
        if (value && !!value.replace(/\s/g, '')) {
            const isValid = value.match(/(^[^a-zA-Z0-9() ]{1,}| [^a-zA-Z0-9() ]{2,})/g);
            if (!!isValid) {
                return {
                    'custom': true
                }
            }
        }
        return null;
    }

    changePassword(updatePw) {
        this.msgs = [];
        this.punchInOutAdmin.id = this.punchInOutAdminId;
        this.punchInOutAdmin.password = this.newPassword;
        this.companyAdminService.changePassword({ id: this.punchInOutAdminId, password: Crypto.MD5(this.newPassword).toString() }).subscribe(res => {
            if (res.status === 'SUCCESS') {
                this.isUpdatePassword = false;
                this.messageService.add({ severity: 'success', summary: 'Updated', detail: 'PunchInOut Account password updated successfully!' });
            } else {
                this.isUpdatePassword = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: `Couldn't update the password` });
            }
            updatePw.resetForm();
        }, () => {
            this.isUpdatePassword = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: `Couldn't update the password` });
            updatePw.resetForm();
        });
    }

    onCancelSavePassword(updatePw) {
        this.isUpdatePassword = false;
        updatePw.resetForm();
    }

    showPassword(type: string) {
        if (type === 'current') {
            this.hideCurrentPassword = false;
        } else if (type === 'new') {
            this.hideNewPassword = false;
        } else if (type === 'confirm') {
            this.hideConfirmPassword = false;
        } else if (type === 'apiSecret') {
            this.hideApiSecret = false;
        }
    }

    hidePassword(type: string) {
        if (type === 'current') {
            this.hideCurrentPassword = true;
        } else if (type === 'new') {
            this.hideNewPassword = true;
        } else if (type === 'confirm') {
            this.hideConfirmPassword = true;
        } else if (type === 'apiSecret') {
            this.hideApiSecret = true;
        }
    }

    onSavePassword(updatePasswordForm, updatePw) {
        this.changePassword(updatePw);
    }

    loadDataSource(event?: any) {

    }

    onCancel() {
        if (this.mode === Constants.FORM_MODE.UPDATE) {
            this.viewCompany();
            // localStorage.removeItem('_agencyData');
        } else {
            localStorage.removeItem('_companyDetail');
            this.router.navigate(['app/company']);
        }
    }

    onUpdatePassword() {
        this.isUpdatePassword = true;
    }


    completeMethodDescription(event) {
        this.loadTimeZones();
        const tzone = this.timezones;
        this.timezones = [];
        (tzone).forEach((e) => {
            if (e.toLowerCase().indexOf(event.query.toLowerCase()) >= 0) {
                this.timezones.push(e);
            }
        });
    }

    editCompany() {
        this.msgs = [];
        if (this.isEdit) {
            this.readOnly = false;
            this.mode = 'edit';
            this.companyForm.enable();
            this.isDisable = false;
        } else {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission')});
        }
    }

    editPunchClock() {
        this.msgs = [];
        if (this.isCompanyAdmin) {
            this.modePunch = 'editPunch';
            this.companyForm.controls['punchInOutAdminUserName'].enable();
        } else {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission')});
        }
    }

    viewPunchClock() {
        this.msgs = [];
        if (this.isCompanyAdmin) {
            this.modePunch = 'viewPunch';
            this.companyForm.controls['punchInOutAdminUserName'].disable();
        } else {
            this.messageService.add({ 
                severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission')
            });
        }
    }

    updatePunchClock() {
        if (this.punchInOutAdminId) {
            this.adminData.username = this.punchInOutAdminUserName;
            this.adminData.useFaceDetection = this.isSwitchedOn;
            this.adminData.pinMethod = this.isPinSwitchedOn;
            this.adminData.id = this.punchInOutAdminId;
            console.log('this.adminData', this.adminData);
            this.companyAdminService.updateAdminPunch(this.adminData).subscribe(res => {
                this.loading = false;
                this.modePunch = 'viewPunch';
                if (res.status === 'SUCCESS') {
                    this.messageService.add({ severity: 'info', summary: 'updated', detail: 'Company setting update successfully' });
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 3000);
                }
            }, () => {
                this.loading = false;
            });
        } else {
            this.messageService.add({ severity: 'error', summary: 'updated', detail: 'Company needs to create a punch in out admin' });
        }
    }

    viewCompany() {
        this.msgs = [];
        if (this.isEdit) {
            this.readOnly = true;
            this.mode = 'view';
            this.companyForm.disable();
            this.isDisable = true;
        } else {
            this.messageService.add({ 
                severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission')
            });
        }
    }

    updateCompany(dataForm) {
        this.company.createdByUsr = this.authService.getCurrentUsername();
        // const index = this.timezones.indexOf(this.company.timezone);
        const index = this.timezoneValueList.indexOf(this.company.timezone);
        if(index < 0) {
            this.company.timezone = null;
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please select time zone value from the list'});
            this.formValidator.validateForm(this.companyForm, dataForm);
            return;
        }

        if (this.punchInOutAdminUserName) {
            if (!this.punchInOutAdminUserName.startsWith('punchinout_')) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: "Please Must enter the correct format 'punchinout_################' for the Punch Clock Username field"});
                return;
            } 
        } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Punch Clock Username is required'});
            return;
        }
        
        this.fileUploaded.forEach(file => {
            this.company.documentIds.push(file.id);
        });
        if (this.formValidator.validateForm(this.companyForm, dataForm)) {
            this.disableAfterSave = true;
            this.company.lastModifiedBy = this.company.createdByUsr;
            const companyData: any = this.company;
            let array;
            if (this.company.daysWork) {
                array = (this.company.daysWork).join(' ');
            }
            companyData.daysWork = array;
            if(this.enteredAddressOne && this.enteredAddressOne.length > 0) {
                companyData.addressOne = this.enteredAddressOne;
            }
            if(this.enteredAddressTwo && this.enteredAddressTwo.length > 0) {
                companyData.addressTwo = this.enteredAddressTwo;
            }
            companyData.extraOperrTelKeys.forEach(item => {
                item.startDate = item.startDate ? item.startDate : new Date();
            });
            companyData.punchInOutAdminUserName = this.punchInOutAdminUserName;
            companyData.useFaceDetection = this.isSwitchedOn;
            companyData.pinMethod = this.isPinSwitchedOn;
            companyData.punchInOutAdminId = this.punchInOutAdminId;
            localStorage.setItem('_companyDetail', JSON.stringify(companyData));
            this.companyService.updateCompany(companyData, this.company.id).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    const  listOperrTelUpdate = [];
                    listOperrTelUpdate.push(companyData.operrTelId);
                    companyData.extraOperrTelKeys.forEach(item => {
                        listOperrTelUpdate.push(item.operrtelId);
                    });
                    this.updateOperrTelPhone('Customer Pool', companyData.name, listOperrTelUpdate);
                    this.messageService.add({ severity: 'info',
                        summary: this.translatePipe.transform('success'),
                        detail: this.translatePipe.transform('message.updated',
                            {value: this.translatePipe.transform('companyLabel')})
                    });
                    setTimeout(() => this.router.navigate(['app/company']), 1000);
                } else {
                    this.disableAfterSave = false;
                    this.messageService.add({ severity: 'error',
                    summary: 'Error',
                    detail: resObj.message})
                }
            }, (err) => {
                console.log('updateCompany error: ', err);
                this.disableAfterSave = false;
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Update Company Fail!'});
            });
        }
    }

    addCompany(dataForm) {
        // const index = this.timezones.indexOf(this.company.timezone);
        const index = this.timezoneValueList.indexOf(this.company.timezone);
        if(index < 0) {
            this.company.timezone = null;
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please select time zone value from the list'});
            this.formValidator.validateForm(this.companyForm, dataForm);
            return;
        }

        if (this.punchInOutAdminUserName) {
            if (!this.punchInOutAdminUserName.startsWith('punchinout_')) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: "Please Must enter the correct format 'punchinout_################' for the Punch Clock Username field"});
                return;
            }
        } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Punch Clock Username is required'});
            return;
        }
        
        this.fileUploaded.forEach(file => {
            this.company.documentIds.push(file.id);
        });
        if (this.formValidator.validateForm(this.companyForm, dataForm)) {
            this.disableAfterSave = true;
            const companyData: any = JSON.parse(JSON.stringify(this.company));
            if (this.company.daysWork != null) {
                const array = (this.company.daysWork as string[]).join(' ');
                companyData.daysWork = array;
            }
            if(this.enteredAddressOne && this.enteredAddressOne.length > 0) {
                companyData.addressOne = this.enteredAddressOne;
            }
            if(this.enteredAddressTwo && this.enteredAddressTwo.length > 0) {
                companyData.addressTwo = this.enteredAddressTwo;
            }
            companyData.createdByUsr = this.authService.getCurrentUsername();
            companyData.punchInOutAdminUserName = this.punchInOutAdminUserName;
            this.companyService.createCompany(companyData).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    const  listOperrTelUpdate = [];
                    listOperrTelUpdate.push(companyData.operrTelId);

                    if (companyData.extraOperrTelKeys) {
                        companyData.extraOperrTelKeys.forEach(item => {
                            listOperrTelUpdate.push(item.operrtelId);
                        });
                    }
                    this.updateOperrTelPhone('Customer Pool', companyData.name, listOperrTelUpdate);
                    this.messageService.add({ severity: 'success',
                        summary: this.translatePipe.transform('success'),
                        detail: this.translatePipe.transform('message.created',
                            {value:this.translatePipe.transform('companyLabel')})
                    });
                    setTimeout(() => this.router.navigate(['app/company']), 2000);
                } else {
                    this.disableAfterSave = false;
                    this.messageService.add({ severity: 'error',
                        summary: 'Error',
                        detail: resObj.message})
                }
            }, (err) => {
                console.log('createCompany error: ', err);
                this.disableAfterSave = false;
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Create Company Fail!'});
            });

        }
    }

    enableForm() {
        this.enableSubmitButton = true;
        this.disableInput = false;
    }


    onZipCodeBlur() {
        if (this.company.zipcode !== null && this.company.zipcode !== undefined) {
            const zipCode = String(this.company.zipcode);
            const missingLeading = 5 - zipCode.length;
            if (missingLeading > 0) {
                let leading = '';
                for (let i = 0; i < missingLeading; i++) {
                    leading = leading + '0';
                }
                this.company.zipcode = leading + this.company.zipcode;
            }
        }
    }

    trackChangesCompany(type?:string) {
        if (type === 'plan') {
            this.title = 'Edit History: Plan';
            this.planId =  this.company.planId;
        } else if (type === 'companyDetails') {
            this.title = 'Edit History: Company Details';
            this.historyType = 'companyDetails';
            this.planId = null;
        } else if (type === 'taxDetail') {
            this.title = 'Edit History: Tax Details';
            this.historyType = 'taxDetail';
            this.planId = null;
        } else if (type === 'phoneConfiguration') {
            this.title = 'Edit History: Phone configuration';
            this.historyType = 'phoneConfiguration';
            this.planId = null;
        } else {
            this.title = 'View Company History';
            this.planId = null;
        }
        this.historyShow = true;
    }

    groups(data){
        this.plans = data;
    }

    onUploadDocument(uploadFilePanel: OverlayPanel) {
        this.uploadFileRef.toArray()[0].nativeElement.click();
        uploadFilePanel.hide();
    }

    getAllFiles(options: any) {
        this.documentServiceV2.searchFile(options)
          .subscribe(res => {
              const resObj: any = res;
              this.documents = resObj.data.content;
              this.showFileUpload = this.documents.length > 0;
              for (let i = 0; i < this.documents.length; i++) {
                  this.fileUploaded.push(this.documents[i]);
              }
          });
    }

    uploadFile(event) {
        this.percentage = 0;
        let filesToUpload = [];
        if (event.target.files.length === 0) {
            return;
        }

        filesToUpload = event.target.files;
        for (let i = 0; i < filesToUpload.length; i++) {
            this.files.push(filesToUpload[i]);
            const reader = new FileReader();
            reader.readAsDataURL(filesToUpload[i]);
            this.uploadMultipleFiles(filesToUpload[i]);
        }
        this.showFileUpload = true;
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    onDrop(files: FileList) {
        if (files) {
            this.percentage = 0;
        }
        for (let i = 0; i < files.length; i++) {
            this.uploadMultipleFiles(files[i]);
        }
        this.showFileUpload = true;
    }

    uploadMultipleFiles(file) {
        this.processing = true;
        this.documentServiceV2.uploadFile(file, 'company_file', 1, 'Company')
            .subscribe(res => {
                const resObj: any = res;
                this.processing = false;
                if (resObj.status === 'SUCCESS') {
                    const options = {
                        ids: [resObj.data.id]
                    };
                    this.getAllFiles(options);
                }
            });
        this.interval = setInterval(() => {
            this.percentage = this.percentage + Math.floor(Math.random() * 10) + 1;
            if (this.percentage >= 100) {
                this.percentage = 100;
                clearInterval(this.interval);
                setTimeout(() => this.processing = false, 1500);
            }
        }, 1000);

    }

    removeFile(id) {
        this.fileUploaded = this.fileUploaded.filter(file => file.id !== id);
        this.showFileUpload = this.fileUploaded.length > 0;
    }

    viewDocument(file) {
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(file.fileUrl);
        this.displayDialog = true;
    }

    handleSwitchChange(event) {
        console.log("this.isSwitchedOn");
        console.log(event);
        console.log(this.isSwitchedOn);
        if (this.isSwitchedOn) {
          this.updateFaceDetection(1);
        } else {
          this.updateFaceDetection(0);
        }
    }

    openPhoneSetupDialog(object, isDefault) {
        if (isDefault) {
            this.selectedPhoneKeySetup.phone = object.operrTel;
            this.selectedPhoneKeySetup.apiKey = object.operrTelApiKey;
            this.selectedPhoneKeySetup.apiSecret = object.operrTelApiSecret;
            this.selectedPhoneKeySetup.endDate = object.operrtelEnd;
            this.selectedPhoneKeySetup.startDate = object.operrtelStart ? object.operrtelStart : new Date();
        } else {
            this.selectedPhoneKeySetup = object;
            this.selectedPhoneKeySetup.startDate = object.startDate ? object.startDate : new Date();
        }

        this.isDefaultPhoneKey = isDefault;
        this.phoneKeySetupDialog = true;
    }

    closePhoneSetupDialog() {
        this.phoneKeySetupDialog = false;
    }

    changePhoneKeySetup() {
        if (this.companyId) {
            if (!this.isDefaultPhoneKey && !this.selectedPhoneKeySetup.id) {
                this.phoneKeySetupDialog = false;
                return;
            }
            const options: any = {
                companyId: this.companyId,
                phone: this.selectedPhoneKeySetup.phone,
                apiKey: this.selectedPhoneKeySetup.apiKey,
                apiSecret: this.selectedPhoneKeySetup.apiSecret,
                startDate: this.selectedPhoneKeySetup.startDate,
                endDate: this.selectedPhoneKeySetup.endDate,
                default: this.isDefaultPhoneKey
            }
            if (this.isDefaultPhoneKey) {
                options.id = this.selectedPhoneKeySetup.id;
            }

            this.companyService.updateOperrTelKeySetup(this.companyId, options).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                    if (this.isDefaultPhoneKey) {
                        this.company.operrTelApiKey = this.selectedPhoneKeySetup.apiKey;
                        this.company.operrTelApiSecret = this.selectedPhoneKeySetup.apiSecret;
                        this.company.operrtelStart = this.selectedPhoneKeySetup.startDate;
                        this.company.operrtelEnd = this.selectedPhoneKeySetup.endDate;
                    }
                    this.messageService.add({ severity: 'success', summary: 'Updated', detail: 'Phone Setup Key updated successfully!' });
                    this.phoneKeySetupDialog = false;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: `Couldn't update the Phone Setup Key` });
                }
            });
        } else {
            if (this.isDefaultPhoneKey) {
                this.company.operrTelApiKey = this.selectedPhoneKeySetup.apiKey;
                this.company.operrTelApiSecret = this.selectedPhoneKeySetup.apiSecret;
                this.company.operrtelStart = this.selectedPhoneKeySetup.startDate;
                this.company.operrtelEnd = this.selectedPhoneKeySetup.endDate;
            }

        }
    }

    addOperTelKey() {
        if (!this.company.extraOperrTelKeys) {
            this.company.extraOperrTelKeys = [];
        }
        const idx = new Date().getTime();
        this.companyForm.addControl(`operrTel-${idx}`, new UntypedFormControl('', [Validators.required]));
        this.company.extraOperrTelKeys.push({
            idx: idx,
            companyId: this.companyId,
            phone: null,
            apiKey: null,
            apiSecret: null,
            operrtelId: null,
            startDate: null,
            endDate: null
        });
    }

    removeOperrtelKey(key: any, isDefault) {
        this.selectedRemoveOperrTelKey = {};
        if (isDefault) {
            this.removeOperrTelDefault = true;
            this.selectedRemoveOperrTelKey.phone = key.operrTel;
            this.selectedRemoveOperrTelKey.apiKey = key.operrTelApiKey;
            this.selectedRemoveOperrTelKey.apiSecret = key.operrTelApiSecret;
            this.selectedRemoveOperrTelKey.endDate = key.operrtelEnd;
            this.selectedRemoveOperrTelKey.startDate = key.operrtelStart ? key.operrtelStart : new Date();
            this.selectedRemoveOperrTelKey.operrTelId = key.operrTelId;
        } else {
            this.selectedRemoveOperrTelKey = key;
        }


        this.showRemoveOperrTelConfirmDialog = true;
    }

    confirmRemoveOperrtelKey() {
        if (this.removeOperrTelDefault && this.selectedRemoveOperrTelKey.operrTelId) {
            if (this.selectedRemoveOperrTelKey.operrTelId)  {
                const options: any = {
                    id: this.selectedRemoveOperrTelKey.operrTelId
                };
                this.companyService.deleteOperrTelKeySetupByPhone(options).subscribe(() => {
                    this.updateOperrTelPhone('Free Pool', '', [options.id]);
                    this.listOperrTelPhone.push({label : this.selectedRemoveOperrTelKey.phone, value: this.selectedRemoveOperrTelKey.operrtelId});
                    this.company.operrTel = null;
                    this.company.operrTelId = null;
                    this.company.operrTelApiKey = null;
                    this.company.operrTelApiSecret = null;
                    this.company.operrtelEnd = null;
                    this.company.operrtelStart = null;
                    this.showRemoveOperrTelConfirmDialog = false;
                    this.companyForm.controls['operrTel'].setValue('');
                    this.messageService.add({ severity: 'success', summary: 'Removed', detail: 'Phone Setup Key removed successfully!' });
                }, () => this.messageService.add({ severity: 'error', summary: 'Error', detail: `Couldn't remove the Phone Setup Key` }));
            } else {
                this.companyForm.removeControl(`operrTel-${this.selectedRemoveOperrTelKey.idx}`);
                this.company.extraOperrTelKeys = [...this.company.extraOperrTelKeys.filter(k => k.idx !== this.selectedRemoveOperrTelKey.idx)];
                this.listOperrTelPhone.push({label : this.selectedRemoveOperrTelKey.phone, value: this.selectedRemoveOperrTelKey.operrtelId});
                this.selectedRemoveOperrTelKey = null;
                this.showRemoveOperrTelConfirmDialog = false;
            }
        } else {
            if (this.selectedRemoveOperrTelKey.id)  {
                const options: any = {
                    companyId: this.companyId,
                    phone: this.selectedRemoveOperrTelKey.phone,
                    apiKey: this.selectedRemoveOperrTelKey.apiKey,
                    apiSecret: this.selectedRemoveOperrTelKey.apiSecret,
                    startDate: this.selectedRemoveOperrTelKey.startDate,
                    endDate: this.selectedRemoveOperrTelKey.endDate,
                    default: this.isDefaultPhoneKey,
                    createdByUsr: this.authService.getCurrentUsername()
                };
                this.company.extraOperrTelKeys.forEach((k, index) => {
                    if (k.idx === this.selectedRemoveOperrTelKey.idx) {
                        options.operrTelIndex = index;
                        return;
                    }
                });
                this.companyService.deleteOperrTelKeySetup(this.selectedRemoveOperrTelKey.id, options).subscribe(() => {
                    this.companyForm.removeControl(`operrTel-${this.selectedRemoveOperrTelKey.idx}`);
                    this.company.extraOperrTelKeys = [...this.company.extraOperrTelKeys.filter(k => k.idx !== this.selectedRemoveOperrTelKey.idx)];
                    this.listOperrTelPhone.push({label : this.selectedRemoveOperrTelKey.phone, value: this.selectedRemoveOperrTelKey.operrtelId});

                    this.updateOperrTelPhone('Free Pool', '', [this.selectedRemoveOperrTelKey.operrtelId]);
                    this.selectedRemoveOperrTelKey = null;
                    this.showRemoveOperrTelConfirmDialog = false;
                    this.messageService.add({ severity: 'success', summary: 'Removed', detail: 'Phone Setup Key removed successfully!' });
                }, () => this.messageService.add({ severity: 'error', summary: 'Error', detail: `Couldn't remove the Phone Setup Key` }));
            } else {
                this.companyForm.removeControl(`operrTel-${this.selectedRemoveOperrTelKey.idx}`);
                this.company.extraOperrTelKeys = [...this.company.extraOperrTelKeys.filter(k => k.idx !== this.selectedRemoveOperrTelKey.idx)];
                this.selectedRemoveOperrTelKey = null;
                this.showRemoveOperrTelConfirmDialog = false;
                this.listOperrTelPhone.push({label : this.selectedRemoveOperrTelKey.phone, value: this.selectedRemoveOperrTelKey.operrtelId});
            }
        }
    }

    userCardList: any[];
    selectedCard: any = {id: null};
    paymentCardDefault;
    loadedCards = false;
    isSelectedCard = false;
    payEnable = false;
    lastFourNumbers;
    showConfirmCardDialog = false;
    autoPay: any;
    showConditionDialog = false;

    companyAutoPay: boolean;

    getCardDetails() {
        const userId = this.authService.getUserInfo().adminId;
        const userType = this.authService.getPaymentUserType();
        this.paymentProfileService.getUserCardList(userId, userType).subscribe((response: any) => {
            this.loadedCards = true;
            if (response.status === 200) {
                this.userCardList = response.data;
                if (this.paymentCardDefault && this.userCardList.find(c => c.id === this.paymentCardDefault.cardId)) {
                    this.selectedCard.id = this.paymentCardDefault.cardId;
                    this.isSelectedCard = true;
                    this.payEnable = true;
                }
            }
        }, err => {
            this.loadedCards = true;
            console.log(err);
        });
    }

    addCard(type?){
        localStorage.setItem('paymentMethod', type)
        this.router.navigate(['app/payments/method'])
    }

    confirmRemoveCard(card: any) {
        this.selectedCard = card;
        this.lastFourNumbers = this.selectedCard.lastFourNumbers;
        this.showConfirmCardDialog = true;
    }

    rejectCard() {
        this.showConfirmCardDialog = false;
    }

    acceptCard() {
        this.paymentProfileService.deletePaymentCard(this.selectedCard.id, this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()).subscribe(() => {
            this.isSelectedCard = false;
            this.showConfirmCardDialog = false;
            this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Card has been deleted')});
        }, err => {
            this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: err.responeObj.errors.error[0].errorText});
        });
    }

    selectCard(event, data?) {

    }

    openDialogCondition() {
        this.showConditionDialog = true;
    }
    closeDialogCondition() {
        this.showConditionDialog = false;
    }

    getPaymentCardDefault() {
        const paymentTransaction = <any>{
            roleId: this.authService.getRoleLevel(),
            userId: this.authService.getCurrentLoggedInId(),
            companyId: this.authService.getCurrentCompanyId()
        };
        this.paymentCardDefaultService.getDefaultCard(paymentTransaction).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.paymentCardDefault = resObj.data;
                if (resObj.data && (resObj.data.cardId || resObj.data.bankId)) {
                    this.isSelectedCard = true;
                    this.autoPay = resObj.data.autoPay;
                    this.payEnable = true;
                    if (this.userCardList && this.userCardList.find(c => c.id === resObj.data.cardId)) {
                        this.selectedCard.id = resObj.data.cardId;
                    } else {
                        this.isSelectedCard = false;
                        this.payEnable = false;
                    }
                }
                console.log('getDefaultCard  autoPay: ', this.autoPay)
            }
        });
    }

    changeAutoPay(event: any) {
        console.log(event)
        if (event.checked) {
            this.company.autoPay = 1;
        } else {
            this.company.autoPay = 0;
        }
    }

    validateUserNameNotTaken(control: AbstractControl): Observable<ValidationErrors | null> {
        if (this.companyId || this.punchInOutAdminId) {
            return of(null);
        }
        if (_.size(control.value) < 8) {
          return of({ minlength: true });
        }
        return this.companyAdminService.isAccountNameAvail(control.value).pipe(
          map(isTaken => {
            return (isTaken ? { userTaken: true } : null);
          }),
          catchError(() => null)
        );
      }

    loadAllOperrTelPool() {
        const options: any = {page: 0, size: 99999, status: 'Free Pool'};
        this.operrtelPoolService.getListPage(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.listOperrTel = resObj.data.content;
                this.listOperrTelPhone.push(...resObj.data.content.map((phone: any) => this.mapToDropdown(phone.provider, phone.id)));
                if (this.mode === 'create' || (this.mode === 'view' && !this.company.operrTelId && !this.company.operrTel)) {
                    this.company.status = 1;
                    this.listOperrTelPhone.push({label : '9293960005', value: 0});
                    this.company.operrTelApiKey = '0a54fe39';
                    this.company.operrTelApiSecret = 'f9c30b639ed749ac95bcf523450a8730';
                    this.company.operrTelId = 0;
                    this.company.operrTel = '9293960005';
                }
            }
        });
    }
    mapToDropdown(label, val) {
        return {
            label: label,
            value: val
        };
    }

    onChangeOperrTelPhone(index, value) {
        const data = this.listOperrTel.filter(item => item.id === value);
        this.company.extraOperrTelKeys[index].phone = data[0].provider;
        this.company.extraOperrTelKeys[index].apiKey = data[0].apiKey;
        this.company.extraOperrTelKeys[index].apiSecret = data[0].apiSecret;
        this.listOperrTelPhone.splice(this.listOperrTelPhone.findIndex(v => v.value === value), 1);
    }

    onChangeOperrTel(value) {
        const data = this.listOperrTel.filter(item => item.id === value);
        this.company.operrTel = data[0].provider;
        this.company.operrTelApiKey = data[0].apiKey;
        this.company.operrTelApiSecret = data[0].apiSecret;
        this.listOperrTelPhone.splice(this.listOperrTelPhone.findIndex(v => v.value === value), 1);
    }

    updateOperrTelPhone(status, useBy, ids) {
        console.log('this.ids', ids);
        if (ids && ids.length > 0) {
            const  data = {
                status: status,
                useBy: useBy,
                ids: ids
            };
            this.operrtelPoolService.updateByIds(data).subscribe(res => {
                const resObj: any = res;
            });
        }
    }

    generatePunchInOutAdminUserName() {
        let dataGenerate = 'punchinout_' + moment.utc(new Date().getTime()).format('DDMMYYHHmmss') + this.utilsService.generateByNumber(3);
        return dataGenerate;
    }
}
