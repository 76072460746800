import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from '../../../shared/service/auth/auth.service';

@Component({
  selector: 'app-email-layout',
  templateUrl: './email-layout.component.html',
  styleUrls: ['./email-layout.component.css']
})
export class EmailLayoutComponent implements OnInit {

  basicMenutItems = [
    { label: 'Email List', id: 'list_email', routerLink: ['/', 'app', 'email', 'list'],
      visible: this.authService.isEmployeeRole() ? true :
        this.authService.getLoggedInUserMenuList().includes('999')
    },
    { label: 'Compose', id: 'compose', routerLink: ['/','app','email'],
      visible: this.authService.isEmployeeRole() ? true :
        this.authService.getLoggedInUserMenuList().includes('999')}
  ];

  // departmentTabMenuItems:MenuItem[];
  defaultBreadCrumbs: MenuItem[] = []
  mailTabMenuItems: MenuItem[] = [];

  activeItem: MenuItem;
  // defaultBreadCrumbs:MenuItem[] = []

  initTab() {
    this.mailTabMenuItems = [];
    this.mailTabMenuItems.push(...this.basicMenutItems);
  }

  initBreadCrumbs() {
    this.defaultBreadCrumbs = [];
  }
  routerEventsSubscribe;

  constructor(private router: Router,
              private breadcrumbService: BreadcrumbService,
              private translate: TranslateService,
              private authService: AuthService,
              private cookieService: CookieService) {
    this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.cookieService.set('_currentLang', event.lang);
      this.translate.setDefaultLang(event.lang);
      this.translate.use(event.lang);
    });
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(event.url.indexOf('/app/email/list') >=0 ) {
          this.initTab();
          this.defaultBreadCrumbs = [];
          this.defaultBreadCrumbs.push({ label: 'Email List' });
        } else if (event.url.indexOf('/app/email') >= 0) {
          this.initTab();
          this.defaultBreadCrumbs = [];
          this.defaultBreadCrumbs.push({ label: 'Compose' });
        } 
        else {
          this.initTab();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Email List', id: 'list_email', routerLink: '/app/email/list' });
        }
        this.breadcrumbService.setItems(this.defaultBreadCrumbs);
      }
    })
  }

  ngOnInit() {
    // this.departmentTabMenuItems = [
    //   {label:'Show All Departments', routerLink:['list']},
    //   {label:'Create a Department', routerLink:['add']}
    // ];
    // this.activeItem = this.letterMailTabMenuItems[0];
  }
  ngOnDestroy() {
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }

}
