import { environment } from "./../../../../environments/environment";
import {
  Component,
  HostListener,
  Optional,
  Inject,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { AuthService } from "../../../shared/service/auth/auth.service";
import {
  AbstractControl,
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validator,
  Validators,
  FormControl,
  NgForm
} from "@angular/forms";
import * as _ from 'lodash';
import {
  CallLogService
} from "../../service/calllog/call-log.service";
import {Message, LazyLoadEvent, MessageService} from 'primeng/api';
import {
  ContactService,
} from "../../service/calllog/contact.service";

import {
  PhoneSettingsService
} from "../../service/calllog/phone-settings.service";
import { CallLogModel } from "../../model/call-log/call-log";
import * as moment from "moment";
import { ContactModel } from "../../model/call-log/contact";
import { ContactResponseDTO } from "../../model/call-log/contactResponseDTO";
import { CallLogResponseDTO } from "../../model/call-log/callLogResponseDTO";
import { PhoneSettingsModel } from "../../model/call-log/phone-settings";
import {AppHotkey} from "../../../shared/model/hotkey";
import { ClientService } from "../../../crm/service/client.service";
import { Router } from "@angular/router";
import { NoteHistory } from "../../../crm/model/note-history";
import { PositionService } from "../../../position/service/position.service";
import { CrmClient } from "../../../crm/model/base";
import { EmployeeDepartmentService } from "../../../department/service/employee-department.service";
import { NoteHistoryService } from "../../../crm/service/note-history.service";
import { Constants } from "../../../shared/model/constants";
import { UtilsService } from "../../../shared/service/utils.service";
import { DocumentsService } from "../../../shared/service/documents-upload/documents.service";
import { Paginator } from "primeng/paginator";

// Import Jssip
declare var JsSIP: any;


@Component({
  selector: "opr-call-handler",
  templateUrl: "./opr-call-handler.component.html",
  styleUrls: ["./opr-call-handler.component.scss"],
  providers: [PositionService, EmployeeDepartmentService]
})
export class CallHandler {
  isShowingCallModel = false;
  isMaximized = true;
  isSipmlSet = false;
  sipmlOptions: any = {};
  user: any = {};
  newContact: any = {};
  newSms: any = {};
  formGroup: UntypedFormGroup;
  formGroupNewContact: UntypedFormGroup;
  formGroupNewSms: UntypedFormGroup;
  openLeft = false;
  openRight = false;
  openSettingLeft = false;
  showDialPad = false;
  scale = 1;
  otherEndName = "";
  otherEndDid = "";
  search = "";
  selectedIndex = 0;
  isNewContactClicked = false;
  isNewSmsClicked = false;
  isDisplayDetailedThread = false;
  phoneSettings: any = {};
  noteHistory: NoteHistory = <any>{};
  dropdownEmail = false;
  selectedUser: any;
  positionsList = [];
  isValidEmail = false;
  isCallNoteModal = false;
  isValidForm = false;
  saveButtonDisabled = false;
  msgs: Message[] = [];
  selectedPositionsEmployeeEmailList = [];
  notificationData: any = {};
  dateForNoteDialog: any;
  size: number;
  tableData = <any>{};
  @ViewChild(Paginator) pgn: Paginator;
  noteHistoryTableData = <any>[];
  page: number;
  secondInterval = null;
  searchText = '';
  noteTypeList = [
    {label: 'Client Busy', value: 'clientBusy'},
    {label: 'Follow Up', value: 'followUp'},
    {label: 'Schedule Visit', value: 'scheduleVisit'},
    {label: 'No Answer', value: 'noAnswer'},
    {label: 'On Vacation', value: 'onVacation'},
    {label: 'Meeting, Other', value: 'meetingOther'},
];
  formGroupPhoneSettings: UntypedFormGroup;

  public static readonly DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';
  public static $inject = [];
  private sipSessionCallMute = false;
  private audioElement = new Audio();
  private ringingTone = new Audio();
  private ringingBackTone = new Audio();
  incomingCallAudio = new Audio();
  private dtmfTone = new Audio();
  callStatus = "";
  mute: any = false;
  oSipStack: {};
  @ViewChildren("contactNumberInput") contactNumberInp;
  contactNumber = "";
  keyDownCN = "";
  hold: any = false;
  destinationNumber: any;
  inTransfer: any = false;
  remoteFriendlyName: any;
  callStartTime: number;
  callEndTime: number;
  currentCallDuration: any;
  callType: string;
  callLogs = [];
  contacts = [];
  contactsMasterList = [];
  smsLogs = [];
  smsLogsByOtherEndDid: any[];
  skipLog: boolean;
  currentThreadOtherEndDid: any;
  client:CrmClient = <any>{};
  msgText: string;
  disable: boolean;
  unReadCount: number;
  customerNoShowList = [];
  noShowCount: any;
  private socket: any;
  configuration: any;
  private dialer: any;
  private options: any;
  private session: any;
  outgoingSession: any = null;
  showCallButton = true;
  connectionStatus = null;
  showIncomingConsole = false;
  showConferenceConsole = false;
  mergeCallOption = false;
  isConnected = false;
  incomming = false;
  ringing = false;
  isConsoleVisible = false;
  numberBeforeTransfering = "";
  userInfo: any;
  voiceMailNumber: any;
  isVideo = false;

  constructor(
    private authService: AuthService,
    private callLogService: CallLogService,
    private contactService: ContactService,
    private fb: UntypedFormBuilder,
    private positionService: PositionService,
    private router: Router,
    private clientService: ClientService,
    private noteHistoryService: NoteHistoryService,
    private utilsService: UtilsService,
    private employeeDepartmentService: EmployeeDepartmentService,
    private documentsService: DocumentsService,
    private messageService: MessageService,

    private phoneSettingsService: PhoneSettingsService,
  ) {
    this.selectedPositionsEmployeeEmailList = ['rthernozier@operr.com']
    this.userInfo = authService.getUserInfo();
    this.socket = new JsSIP.WebSocketInterface(
      // this.userInfo.sip ?
      // this.userInfo.sip.websocketProxyUrl :
      environment.sip.websocketProxyUrl
    );

    this.configuration = {
      sockets: [this.socket]
    };
    this.options = {
      mediaConstraints: { audio: true, video: this.isVideo },
      pcConfig: {
        iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }]
      }
    };
    this.clientService.subject.subscribe(value => {
      if (value) {
        if (value === this.contactNumber) {
          this.isShowingCallModel = false;
        } else {
          this.isShowingCallModel = true;
          this.contactNumber = value;
        }
      }
    });
    this.buildForm();
    this.buildFormNewContact({});
    this.buildFormNewSms({});
    this.buildFormPhoneSettings({});
    this.selectedUser = this.userInfo;

    if (this.userInfo.extension) {
      this.user = {username: this.userInfo.extension,  password: `${this.userInfo.firstName}${this.userInfo.extension}`}
    }

    if (this.userInfo) {
      this.loginToSipml();
    }

    this.audioElement.autoplay = true;

    this.incomingCallAudio.loop = true;
    this.incomingCallAudio.src = "ringingtone.wav";

    this.ringingTone.loop = true;
    this.ringingTone.src = "ringingbacktone.wav";

    this.ringingBackTone.loop = true;
    this.ringingBackTone.src = "ringingbacktone.wav";

    this.dtmfTone.src = "dtmf.wav";
    // this.authService.receiveLogOutAlert().subscribe(x => this.endCall());
  }

  editContact(cont) {
    this.buildFormNewContact(cont);
    this.isNewContactClicked = true;
  }

  sendSms({ }) {
    this.buildFormNewSms({});
    this.isNewSmsClicked = true;
    this.isDisplayDetailedThread = false;
  }

  sendSmsFromThead() {
    this.buildFormNewSms({ otherEndDid: this.currentThreadOtherEndDid });
    this.isNewSmsClicked = true;
    this.isDisplayDetailedThread = false;
  }

  emailDropdown() {
    this.dropdownEmail = this.dropdownEmail === false;
    if (!this.dropdownEmail) {
        this.noteHistory.positions = <any>[];
        console.log(this.noteHistory.positions);
    }
}

selectFile() {
  document.getElementById('documentPath').click();
}

async saveNoteHistoryDate() {
  this.noteHistory.clientId = this.client.id;

  // check validation
  this.isValidForm = !(this.noteHistory.note && this.noteHistory.noteType);
  if (this.isValidForm) return;
  this.isValidEmail = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.noteHistory.email));
  if (this.isValidEmail) return;
  if (this.noteHistory.note){
      if (this.noteHistory.note.length < 25) {
          this.msgs.push({severity: 'info', summary: 'Note', detail: 'Please enter minimum 25 characters in "Note"!'});
          document.getElementById('id_note_text').focus();
          return;
      }
  }

  // file upload code
  const fileUpload: any = document.getElementById('documentPath');
  let file: File;
  if (fileUpload && fileUpload.files && fileUpload.files.length > 0) {
      file = fileUpload.files[0];
      // console.log(file);
  }
  if (file && file.size >= 1000000) {
      this.msgs.push({severity: 'warn', summary: 'Note', detail: 'File size must be 1000 KB or smaller!'});
      return;
  }
  this.saveButtonDisabled = true;
  if (file) this.noteHistory.documentPath = await this.uploadFileToServer(file);

  // positions
  if (this.noteHistory.positions) {
      await this.getSelectedPositionsEmployeeEmail();
      this.noteHistory.positions = this.noteHistory.positions.toString();
  }

  this.noteHistoryService.saveNote(this.noteHistory).subscribe((e: any) => {
      if (e.status === 'ERROR') {
          this.msgs.push({severity: 'error', summary: 'Note', detail: 'Error saving note'});
          this.saveButtonDisabled = false;
          return;
      }
      this.messageService.add({severity: 'SUCCESS', detail: 'Note details saved!'});
      this.sendEmail(e.data.id);
      // for Notification
      this.notificationData.createdBy = e.data.user;
      this.notificationData.uniqueKey = e.data.id;
      this.notificationSave();
      this.noteHistory = <any>{};
      this.isCallNoteModal = false;
      this.client = new CrmClient();
  });
}

sendEmail(id) {
  if (this.selectedPositionsEmployeeEmailList && this.selectedPositionsEmployeeEmailList.length > 0) {
    this.noteHistoryService.sendEmailNoteHistory(id, {emails: (this.selectedPositionsEmployeeEmailList).toString()}).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.msgs.push({severity: 'info', summary: 'Email Sent', detail: 'Data was sent successfully!'});
      }
    });
  }
}

getCallDuration() {
  this.secondInterval = setInterval(() => {
    if (this.callStartTime) {
      this.currentCallDuration = this.calculateDuration(this.callStartTime, performance.now());
    }
  }, 1000);
}

showMMDDYYYYFormat(date) {
  // return moment(date).format('MM/DD/YYYY');
  return moment(date).format('MM/DD/YYYY, hh:mm:ss a');
}

onFilterChange() {
  this.loadDataSourceGroup();
}

loadDataSourceGroup(event?: LazyLoadEvent) {
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 5);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    if (this.client.id) {
        this.noteHistoryService.getAllNoteByClientId(this.client.id, {
            size: this.size,
            page: this.page,
            search: this.searchText
        }).subscribe((item: any) => {
          this.tableData.size = item.data.size;
          this.tableData.totalPages = item.data.totalPages;
          this.tableData.totalElements = item.data.totalElements;
            let pgnCount = 0;
            if (item.status === 'SUCCESS' && item.data) {
                this.noteHistoryTableData = [];
                (item.data.content).forEach((e) => {
                    if ((e.status).toString() === '1'){
                        this.noteHistoryTableData.push(e);
                        pgnCount += 1;
                    }
                });
                if (this.pgn) {
                    // this.pgn.totalRecords = item.totalElements;
                    this.pgn.totalRecords = pgnCount;
                }
            }
        });
    }
}

paginate(event) {
    this.loadDataSourceGroup(event);
}

notificationSave() {
  this.notificationData.tableName = Constants.NOTE_DATA.SAVE_HISTORY; // this is static because from here only save the note notification
  this.notificationData.type = Constants.NOTE_DATA.SAVE_NOTE; // this is static because from here only save the note notification
  this.noteHistoryService.saveNotification(this.notificationData).subscribe((e) => {
      console.log(e);
      // this.showNotificationOnTop = e.data;
      this.utilsService.showNotificationOnTop(e.data);
      // this.showNotificationOnTop = new Observable(observe => {
      //     observe.next(e);
      // });

  });
}

getSelectedPositionsEmployeeEmail() {
  const tempArr = <any>this.noteHistory.positions;
  return new Promise((resolve) => {
      this.employeeDepartmentService.getEmployeeEmailsByPosition(tempArr.toString()).subscribe((e) => {
          this.selectedPositionsEmployeeEmailList = e.data;
          resolve(this.selectedPositionsEmployeeEmailList);
      });
  });

}

uploadFileToServer(file: File): Promise<any> {
  return new Promise((resolve) => {
      this.documentsService.uploadFile(file, this.noteHistory.user, this.noteHistory.clientId, 'CompanySetting').subscribe((response) => {
          console.log(response);
          resolve(response.data.fileUrl);
      });
  });
}

  nowDate() {
    this.noteHistory.date = null;
    this.noteHistory.date = <any>moment().toDate();
    return moment().format('MM/DD/YYYY, hh:mm:ss a');
    // return moment().format('MM/DD/YYYY');
}

// getUserName() {
//   this.selectedUser = (this.authService.getLoginsToday().toPromise()).data[0];
// }

  onChange($event) {
    this.selectedIndex = $event.index;
    this.buildFormNewContact({});
    this.buildFormNewSms({});
    this.isNewContactClicked = false;
    this.isNewSmsClicked = false;
    this.isDisplayDetailedThread = false;
  }

  keyDownCNMethod(s: string) {
    this.keyDownCN = s;
  }

  zoomIn() {
    this.scale = this.scale + 0.25;
  }

  zoomOut() {
    this.scale = this.scale - 0.25;
  }

  keyUpCNMethod(s: string) {
    if (this.keyDownCN < s) {
      this.sipSendDTMF(s);
    }
  }

  private loginToSipml = () => {
    localStorage.removeItem('sipUsername');
    if (this.userInfo.extension) {
      localStorage.setItem('sipUsername', this.userInfo.extension);
      this.configuration = {
        ...this.configuration,
        uri: `sip:${this.userInfo.extension}@${
          // this.userInfo.sip ?
          // this.userInfo.sip.server :
          environment.sip.server
        }`,
        password: this.userInfo.firstName + this.userInfo.extension,
        // password: this.user ? this.user.password : '',
        authorization_user: this.userInfo.extension
      };
    }
    else if (this.user && this.user.username && this.user.password) {
      localStorage.setItem('sipUsername', this.user.username);
      this.configuration = {
        ...this.configuration,
        uri: `sip:${this.user ? this.user.username : ''}@${
          // this.userInfo.sip ?
          // this.userInfo.sip.server :
          environment.sip.server}`,
        password: this.user ? this.user.password : '',
      };
    }
    if(this.configuration.uri && this.configuration.password) {
      this.dialer = new JsSIP.UA(this.configuration);
      this.setDialerEvents();
      this.dialer.start();
      this.dialer.register();
    }
  };

  disconnect = () => {
    this.isConnected = false;
    this.contactNumber = "";
    this.setRingtonePlayback(false);
    this.dialer.stop();
    this.user.username = null;
    this.user.password = null;
    this.authService.setSipmlUser(this.user);
    this.buildForm();
    this.dialer.unregister({ all: true });
  };

  initTransferCall = () => {
    this.setRingtonePlayback(false);
    this.inTransfer = true;
    this.numberBeforeTransfering = this.contactNumber;
    this.contactNumber = "";
    this.isConsoleVisible = false;
    this.showCallButton = false;
  };

  cancelTranferCall = () => {
    this.contactNumber = this.numberBeforeTransfering;
    this.inTransfer = false;
    this.isConsoleVisible = true;
  };

  onHoldCall = () => {
    if (this.session.isOnHold().local) {
      this.hold = false;
      this.connectionStatus = "resume"
      this.session.unhold()
    }
    else {
      this.hold = true;
      this.connectionStatus = "on-hold"
      this.session.hold();
    }
    // this.holdCall(this.hold);
  };

  connect = () => {
    this.user = this.formGroup.value.user;
    // this.user = this.formGroup.getRawValue().user;
    this.loginToSipml();
  };

  getClientByPhone() {
    if (this.session) {
      if(this.otherEndDid) {
        if(this.otherEndDid.length > 10 && this.otherEndDid.charAt(0) === '1') {
          this.otherEndDid = this.otherEndDid.substring(1)
        }
        this.clientService.getByPhone(this.otherEndDid).subscribe(
          (data: any) => {
            if(data.data && data.data.id) {
              this.client = data.data;
              this.otherEndName = data.data.name;
              this.resolveCustomerName();
              this.dateForNoteDialog = this.nowDate();
              this.noteHistory.user = this.selectedUser.username;
              // this.router.navigate(['app/crm/client', 'edit', data.data.id]);
            }
          }
        );
      }
    }
  }

  saveNewContact = () => {
    const newCont = this.formGroupNewContact.getRawValue().newContact;
    if (
      this.contactsMasterList.find(
        c =>
          newCont.mobile && c.mobile === newCont.mobile && newCont.id !== c.id
      ) ||
      this.contactsMasterList.find(
        c =>
          newCont.landLine &&
          c.landLine === newCont.landLine &&
          newCont.id !== c.id
      )
    ) {
    } else {
      this.contactService.create(newCont).subscribe((rs: ContactModel) => {
        this.isNewContactClicked = false;
        this.buildFormNewContact({});
        this.getContacts();
      });
    }
  };

  deleteContact = () => {
    this.contactService
      .delete(this.formGroupNewContact.getRawValue().newContact.id)
      .subscribe((rs: ContactModel) => {
        this.buildFormNewContact({});
        this.getContacts();
        this.onChange({ index: 1 });
      });
  };

  onSearch() {
    console.log(this.search);
    this.contacts = this.contactsMasterList.filter(o =>
      Object.keys(o).some(
        k =>
          o[k] &&
          o[k]
            .toString()
            .toLowerCase()
            .includes(this.search.toLowerCase())
      )
    );
  }
  buildForm = () => {
    this.formGroup = this.fb.group({
      user: this.fb.group({
        username: [null, [Validators.maxLength(150)]],
        password: [null, [Validators.maxLength(150)]],
        serverIP: [null, [Validators.maxLength(150)]],
        serverPORT: [null, [Validators.maxLength(150)]]
      })
    });
  };

  buildFormNewContact = existingValue => {
    this.formGroupNewContact = this.fb.group({
      newContact: this.fb.group({
        firstName: [
          existingValue.firstName,
          [Validators.maxLength(150)]
        ],
        lastName: [existingValue.lastName, [Validators.maxLength(150)]],
        mobile: [existingValue.mobile, [Validators.maxLength(150)]],
        landLine: [existingValue.landLine, [Validators.maxLength(150)]],
        id: [existingValue.id, [Validators.maxLength(150)]]
      })
    });
  };

  buildFormNewSms = existingValue => {
    this.formGroupNewSms = this.fb.group({
      newSms: this.fb.group({
        otherEndDid: [
          existingValue.otherEndDid,
          [Validators.maxLength(150)]
        ],
        content: [existingValue.content, [Validators.maxLength(150)]]
      })
    });
  };

  buildFormPhoneSettings = existingValue => {
    this.formGroupPhoneSettings = this.fb.group({
      phoneSettings: this.fb.group({
        id: [existingValue.id, [Validators.maxLength(150)]],
        did: [existingValue.did,[Validators.maxLength(150)]],
        dnd: [existingValue.dnd],
        cfwd: [existingValue.cfwd],
        cfwdTo: [existingValue.cfwdTo, [Validators.maxLength(150)]]
      })
    });
  };

  public savePhoneSettings() {
    const settings = this.formGroupPhoneSettings.getRawValue().phoneSettings;
    const phoneSettings: PhoneSettingsModel = {
      id: settings.id,
      did: this.user.username,
      dnd: settings.dnd,
      cfwd: settings.cfwd,
      cfwdTo: settings.cfwdTo,
      createdAt: new Date(),
      updatedAt: new Date()
    };
    this.phoneSettingsService.create(phoneSettings).subscribe((rs: PhoneSettingsModel) => {
      this.phoneSettings = rs
    });
    let dndPhoneData = {
      extension: phoneSettings.did,
      type: "disable"
    };
    let cfwdPhoneData: any = {
      extension: phoneSettings.did,
      type: "disable"
    };
    if (phoneSettings.dnd) {
      dndPhoneData = {
        ...dndPhoneData,
        type: 'enable',
      }
    }
    if (phoneSettings.cfwd) {
      if (phoneSettings.cfwdTo) {
        cfwdPhoneData = {
          ...cfwdPhoneData,
          type: 'enable',
          cfextension: phoneSettings.cfwdTo
        }
      }
      else { alert('Please provide call forward number') }
    }

    this.phoneSettingsService.onSavePhoneSettings(dndPhoneData, cfwdPhoneData).subscribe(x => {
      console.log('action completed', x);
    },
      y => {
        console.log('error', y);
      });
  }

  getPhoneSettings() {
    this.phoneSettingsService
      .getPhoneSettings(this.user.username)
      .subscribe((phoneSetting: any) => {
        this.buildFormPhoneSettings(phoneSetting);
      }, err => {
        console.log('error in fetching phone setting', err);
      });
  }

  // phoneSettingsUpdated(setting) {
  //   const phoneSettings = this.formGroupPhoneSettings.getRawValue()
  //     .phoneSettings;
  //   if (setting === "dnd" && phoneSettings.dnd) {
  //     phoneSettings.cfwd = false;
  //   } else if (setting === "cfwd" && phoneSettings.dnd) {
  //     phoneSettings.dnd = false;
  //   }
  //   this.buildFormPhoneSettings(phoneSettings);
  // }

  ngOnInit = () => { };

  toggleCallModel = () => {
    this.isShowingCallModel = !this.isShowingCallModel;
    if (this.isShowingCallModel) {
      setTimeout(() => {
        if (
          this.contactNumberInp &&
          this.contactNumberInp.first &&
          this.contactNumberInp.first.nativeElement
        ) {
          this.contactNumberInp.first.nativeElement.focus();
        }
      }, 100);
    }
  };

  toggleSize = () => {
    this.isMaximized = !this.isMaximized;
  };

  updateContactNumber = input => {
    if (!isNaN(input) || ["*", "#"].indexOf(input) !== -1) {
      this.contactNumber = this.contactNumber + input;
      this.sipSendDTMF(input);
    } else if (input === "DELETE") {
      this.contactNumber = this.contactNumber.slice(0, -1);
    }
  };
  isModelOpen = () => {
    if (["init_i_new_call"].indexOf(this.callStatus) !== -1) {
      this.isShowingCallModel = true;
    }

    return this.isShowingCallModel;
  };

  toggleRightTab() {
    this.openRight = !this.openRight;
  }

  private setRingtonePlayback = play => {
    play ? this.ringingTone.play() : this.ringingTone.pause();
  };
  private setInComingCallAudio = (play: any) => {
    play ? this.incomingCallAudio.play() : this.incomingCallAudio.pause();
  };

  private setRingBacktonePlayback = play => {
    play ? this.ringingBackTone.play() : this.ringingBackTone.pause();
  };

  private calculateDuration(callStartTime: number, callEndTime: number) {
    let durationString = "00:00:00 ";
    const callDuration = `${callEndTime - callStartTime}`;
    const duration = this.convertMS(callDuration);
    durationString = duration.h > 0 ? this.getTimeInTwoDigits(duration.h) + ":" : "00:";
    durationString =
      durationString + (duration.m > 0 ? this.getTimeInTwoDigits(duration.m) + ":" : "00:");
    durationString =
      durationString + (duration.s > 0 ? this.getTimeInTwoDigits(duration.s) : "00");
    return durationString;
  }

  getTimeInTwoDigits(time) {
    return ("0" + time).slice(-2)
  }

  getCallLog() {
    this.callLogService
      .getAll(this.user.username)
      .subscribe((callLogs: any) => {
        // let unReadCount = 0;

        callLogs.forEach(callLog => {
          callLog.displayTime = moment(moment.utc(callLog.calldate).local()).format(
            CallHandler.DATE_TIME_FORMAT
          );
          // if (!r.isRead) {
          //   unReadCount++;
          // }
        });
        // this.unReadCount = unReadCount;
        this.callLogs = callLogs;
        this.getCallRecords();
      }, err => {
        console.log(err);
      });
  }

  getContacts() {
    this.search = "";
    this.contactService.getAll().subscribe((rs: ContactResponseDTO) => {
      this.contacts = rs.content;
      this.contactsMasterList = this.contacts;
      this.getCallLog();
    });
  }

  getUserAvtar(url) {
    return url ? url : "/assets/images/user.jpg";
  }

  callFromLog(otherEndDid) {
    this.contactNumber = otherEndDid;
    this.call(false);
  }

  getCallLogs(callType) {
    let callLogsProccessed = [];
    switch (callType) {
      case "DIALED":
        callLogsProccessed = this.callLogs
          ? this.callLogs.filter(c => c.callType === "DIALED")
          : [];
        break;
      case "INCOMING":
        callLogsProccessed = this.callLogs
          ? this.callLogs.filter(c => c.callType === "INCOMING")
          : [];
        break;
      case "MISSED":
        callLogsProccessed = this.callLogs
          ? this.callLogs.filter(c => c.callType === "MISSED")
          : [];
        break;
      case "ALL":
        callLogsProccessed = this.callLogs;
        break;
      default:
        break;
    }
    return callLogsProccessed;
  }

  getIcon(callType) {
    let icon = "";
    switch (callType) {
      case "DIALED":
        icon = "call_made";
        break;
      case "INCOMING":
        icon = "call_received";
        break;
      case "MISSED":
        icon = "call_missed";
        break;
      default:
        break;
    }
    return icon;
  }

  getCallRecords() {
    this.callLogs.forEach(callLog => {
      let callType;

      switch (callLog.callType) {
        case "DIALED":
          callType = "out";
          callLog.otherEndDid = callLog.dst;
          break;
        case "INCOMING":
          callType = "in";
          callLog.otherEndDid = callLog.src;
          break;
        default:
          callType = "missed";
          callLog.otherEndDid = callLog.src;
      }

      let user = callLog.clid.replace(/[^\w\s]/gi, '').split(' ');

      let dateTimeSplitArray = callLog.createdAt.split(" ");
      let dateValue = dateTimeSplitArray[0].split("-").join("");
      let folderPath = dateTimeSplitArray[0].split("-").join("/");
      let unixStamp = Math.floor(new Date(callLog.createdAt).getTime() / 1000);
      let timeValue = dateTimeSplitArray[1]
        .split(".")[0]
        .split(":")
        .join("");
      let durationValue = callLog.duration;
      callLog.otherEndName = user[0];
      callLog.otherEndDid = user[1];
      // let link = `${environment.api.callLogServerURL}${folderPath}/${callType}-${callLog.dst}-${callLog.did}-${dateValue}-${timeValue}-${unixStamp}.${durationValue}.wav`;
      let audioObject = {
        audioRef: new Audio(),
        stage: "stopped",
        validLink: true
      };
      // audioObject.audioRef.src = link;
      audioObject.audioRef.load();
      audioObject.audioRef.onerror = event => {
        audioObject.validLink = false;
      };
      callLog.audioObject = audioObject;
    });
  }

  play(item) {
    item.audioRef.play();
    item.stage = "playing";
    item.audioRef.onended = function () {
      item.stage = "stopped";
    };
  }
  pause(item) {
    item.audioRef.pause();
    item.stage = "paused";
  }
  stop(item) {
    item.audioRef.load();
    item.stage = "stopped";
  }

  public answer = (video) => {
    if (this.session && this.session.direction === "incoming") {
      this.session.answer({...this.options, mediaConstraints: {...this.options.mediaConstraints, video}});
    }
  };

  public endCall = () => {
    if (this.session) {
      this.session.terminate();
    }
    this.showCallButton = true;
    this.mergeCallOption = false;
    // this.callType = null;
    this.isConsoleVisible = false;
    this.contactNumber = "";
    this.numberBeforeTransfering = "";
    this.connectionStatus = "ended";
    this.showIncomingConsole = false;
    this.showConferenceConsole = false;
    this.setRingtonePlayback(false);
    this.setInComingCallAudio(false);
  };

  public addNewCallLog() {
    if (this.callType) {
      let durationString = "0s ";
      if (this.callStartTime && this.callEndTime) {
        const callDuration = `${this.callEndTime - this.callStartTime}`;

        const duration = this.convertMS(callDuration);
        durationString = duration.d > 0 ? duration.d + "d " : "";
        durationString =
          durationString + (duration.h > 0 ? duration.h + "h " : "");
        durationString =
          durationString + (duration.m > 0 ? duration.m + "m " : "");
        durationString =
          durationString + (duration.s > 0 ? duration.s + "s " : "");
      }
      const callLog: CallLogModel = {
        id: null,
        did: this.user.username,
        dateTime: new Date(),
        callType: this.callType,
        duration: durationString,
        otherEndName: this.otherEndName,
        otherEndDid: this.otherEndDid,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      this.callLogService.create(callLog).subscribe((rs: CallLogModel) => {
        this.getCallLog();
      });
      this.callStartTime = null;
      this.callEndTime = null;
      this.otherEndDid = null;
      this.otherEndName = null;
      this.callType = null;
    }
  }

  private getCallerNumber() {
    if (this.session) {
      this.remoteFriendlyName = this.session._remote_identity._uri._user;
    } else {
      this.remoteFriendlyName = null;
    }
    this.noShowCount = null;
    this.customerNoShowList = [];
    this.otherEndDid = this.remoteFriendlyName;
  }

  // Don't remove below method. Need to implement using jssip session

  public sipSendDTMF(c) {
    if (this.session && c) {
      this.session.sendDTMF(c);
      this.dtmfTone.play()
    }
  }

  setDialerEvents() {
    this.dialer.on(
      "registered",
      function (x) {
        this.authService.setSipmlUser(this.user);
        console.log("registered");
        this.getContacts();
        this.getPhoneSettings();
        this.showCallButton = true;
        this.callStatus = "registered";
        this.connectionStatus = "ended";
        this.isConnected = true;
      }.bind(this)
    );
    this.dialer.on('sipEvent', function (event: any) {
      if (event && event.request && event.request.data && typeof (event.request.data) === 'string') {
        let splitMesage = event.request.data.split('\n');
        let tempArray = splitMesage.filter(x => x.includes('Voice-Message'));
        if (tempArray.length) {
          let stageOne = tempArray[0].split(': ');
          if (stageOne.length > 0) {
            let stageTwo = stageOne[1].split('/');
            this.voiceMailNumber = stageTwo[0];
          }
        }
      }
    }.bind(this));

    this.dialer.on(
      "registrationFailed",
      function (e: any) {
        console.log("registrationFailed");
        this.connectionStatus = "ended";
        this.isConnected = false;
      }.bind(this)
    );

    this.dialer.on(
      "newRTCSession",
      ((data: any) => {
        this.session = data.session;

        this.session.on(
          "disconnected",
          function (e: any) {
            console.log('"disconnected to SIP server Successfully"');
            this.isConnected = false;
            this.showCallButton = true;
            this.showIncomingConsole = false;
            this.showConferenceConsole = false;
            this.connectionStatus = "disconnected";
          }.bind(this)
        );

        if (this.session.direction === "incoming") {
          this.setInComingCallAudio(true);
          this.connectionStatus = "incoming";
          this.showIncomingConsole = true;
          this.isConsoleVisible = false;
          this.showCallButton = false;
          this.resolveCustomerName();
          this.getClientByPhone();
          this.callType = "MISSED";
          // incoming call here
          this.session.on(
            "peerconnection",
            ((e: any) => {
              this.session.connection.addEventListener(
                "addstream",
                ((e: any) => {
                  this.addMediaStream(e);
                }).bind(this)
              );
            }).bind(this)
          );

          this.session.on(
            "connecting",
            ((e: any) => {
              console.log("connecting Incomming call");
              this.connectionStatus = "connecting";
              this.resolveCustomerName();
              this.getClientByPhone();
              this.callType = "MISSED";
            }).bind(this)
          );

          this.session.on(
            "accepted",
            ((e: any) => {
              console.log("accepted incoming call");
              this.connectionStatus = "accepted";
              this.showIncomingConsole = false;
              this.isConsoleVisible = true;
              this.setInComingCallAudio(false);
              this.callStartTime = performance.now();
              this.displayNoteModel();
              this.getCallDuration();
              // this.isShowingCallModel = false;
              this.showDialPad = true;
              this.callType = "INCOMING";
            }).bind(this)
          );

          this.session.on(
            "connected",
            ((e: any) => {
              this.setInComingCallAudio(false);
              console.log("connected Incomming call");
              this.connectionStatus = "connected";
              this.showIncomingConsole = false;
              this.callStartTime = performance.now();
            }).bind(this)
          );

          this.session.on(
            "ended",
            (() => {
              console.log("ended");
              this.cleanUpOnCallEnd();
            }).bind(this)
          );
          this.session.on(
            "failed",
            (() => {
              console.log("failed");
              this.addLog();
              this.cleanUpOnCallEnd();
            }).bind(this)
          );
        } else {
          this.showCallButton = false;
          this.isConsoleVisible = true;
          this.session.on(
            "connecting",
            ((e: any) => {
              console.log("connecting out going call");
              this.callType = "DIALED";
              this.connectionStatus = "connecting";
              this.showIncomingConsole = false;
              this.setRingtonePlayback(true);
              this.resolveCustomerName();
              this.getClientByPhone();
            }).bind(this)
          );

          this.session.on(
            "accepted",
            ((e: any) => {
              console.log("accepted");
              this.connectionStatus = "accepted";
              this.showIncomingConsole = false;
              this.setRingtonePlayback(false);
              this.callStartTime = performance.now();
              this.displayNoteModel();
              this.getCallDuration();
              // this.isShowingCallModel = false;
              this.showDialPad = true;
            }).bind(this)
          );

          this.session.on(
            "failed",
            (() => {
              console.log("failed outgoing call");
              this.cleanUpOnCallEnd();
            }).bind(this)
          );

          this.session.on(
            "connected",
            ((e: any) => {
              console.log("connected out going call");
              this.connectionStatus = "connected";
              this.showIncomingConsole = false;
              this.callStartTime = performance.now();
            }).bind(this)
          );
          this.session.on(
            "ended",
            (() => {
              console.log("ended");
              this.cleanUpOnCallEnd();
            }).bind(this)
          );
        }
      }).bind(this)
    );
    this.resolveCustomerName();
    this.getClientByPhone();
  }
  public call = (video) => {
    if (this.contactNumber && this.contactNumber !== this.user.username) {
      this.outgoingSession = this.dialer.call(
        `sip:${this.contactNumber}@${
          // this.userInfo.sip ?
          // this.userInfo.sip.server :
          environment.sip.server}`,
          {...this.options, mediaConstraints: {...this.options.mediaConstraints, video}}
      );
      this.outgoingSession.connection.addEventListener(
        "addstream",
        ((e: any) => {
          this.addMediaStream(e);
        }).bind(this)
      );

      this.showCallButton = false;
      this.callType = "DIALED";
    } else if (this.contactNumber === this.user.username) {
      alert("Cannot call to your own number");
    }
  };

  private displayNoteModel() {
    if (this.client && this.client.id) {
      this.isCallNoteModal = true;
    }
  }
  private addMediaStream(e: any) {

    const localStream = this.session.connection.getLocalStreams()[0];
    
    if(localStream && localStream.getVideoTracks()[0] && e.stream) {
      let selfView = <HTMLVideoElement>document.getElementById('selfView');
      let remoteView = <HTMLVideoElement>document.getElementById('remoteView');
      remoteView.srcObject = (e.stream);
      selfView.muted = true;
      selfView.srcObject = localStream.clone();
    } else {
      let remoteAudio1 = new Audio();
      remoteAudio1.srcObject = e.stream;
      remoteAudio1.play();
    }
  }

  onConferenceCall() {
    if (this.contactNumber) {
      this.mergeCallOption = true;
      this.showConferenceConsole = false;
      this.session.sendDTMF('*2' + this.contactNumber + '#');
    }
  }
  addConference() {
    this.showConferenceConsole = true;
    this.isConsoleVisible = false;
    this.contactNumber = '';
    this.connectionStatus = 'connected';
  }

  onMergeCall() {
    this.session.sendDTMF('*3#');
    this.mergeCallOption = false;
    this.isConsoleVisible = true;
    this.connectionStatus = 'conference'
  }

  CancelConferenceOption() {
    this.showConferenceConsole = false;
    this.isConsoleVisible = true;
  }

  // need to implement Voice mail using jssip
  public callVoicemail = () => {
    if (this.dialer) {
        this.dialer.call(`sip:${"*97"}@${
          // this.userInfo.sip ?
          // this.userInfo.sip.server :
          environment.sip.server}`,
        this.options
      );
      this.session.connection.addEventListener(
        "addstream",
        ((e: any) => {
          let remoteAudio1 = new Audio();
          remoteAudio1.srcObject = e.stream;
          remoteAudio1.play();
        }).bind(this)
      );

      this.showCallButton = false;
      this.callType = "DIALED";
    }

    else {
      alert('Voice mail not sent');
    }
  };

  // need to implement using jssip
  public decline = () => {
    // this.sipSessionCall.hangup();
  };

  private cleanUpOnCallEnd = () => {
    this.callEndTime = performance.now();
    this.addNewCallLog();
    this.getCallLog();
    console.log("ended");
    this.showCallButton = true;
    this.callType = "";
    this.isConsoleVisible = false;
    this.showConferenceConsole = false;
    this.showIncomingConsole = false;
    this.connectionStatus = "ended";
    this.numberBeforeTransfering = "";
    this.setInComingCallAudio(false);
    this.onSipSessionCallTerminated();
    this.setRingtonePlayback(false);
    this.setRingBacktonePlayback(false);
    this.mute = false;
    this.hold = false;
    this.contactNumber = "";
    this.callStartTime = null;
    this.callEndTime = null;
    clearInterval(this.secondInterval);
  };

  public onSipSessionCallDeclined = () => {
    this.remoteFriendlyName = null;
    this.noShowCount = null;
    this.customerNoShowList = [];
  };

  public onSipSessionCallIncoming = () => {
    this.callType = "MISSED";
  };

  public onSipSessionCallRinging = () => { };

  public onSipSessionCallTerminated = () => {
    this.remoteFriendlyName = null;
    this.noShowCount = null;
    this.customerNoShowList = [];
  };

  // public holdCall = hold => {
  //   if (this.sipSessionCall) {
  //     hold ? this.sipSessionCall.hold() : this.sipSessionCall.resume();
  //   }
  // };

  muteCall() {
    this.mute = !this.mute;
    if (this.session.isMuted().audio) {
      this.session.unmute({ audio: true });
    } else {
      this.session.mute({ audio: true });
    }
  }

  // transfers the call
  public transferCall = () => {
    if (this.contactNumber && this.session) {
      this.setRingtonePlayback(false);
      this.inTransfer = false;
      this.session.refer(this.contactNumber);
      this.endCall();
    } else {
      alert("Please provide transfer number");
    }
  };

  private addLog() {
    if (!this.skipLog) this.addNewCallLog();
    else this.skipLog = false;
  }

  private resolveCustomerName() {
    if (this.session != null) {
      this.getCallerNumber();
      const contactMatch = this.contactsMasterList.find(
        c =>
          this.otherEndDid &&
          (c.mobile === this.otherEndDid ||
            c.landLine === this.otherEndDid)
      );
      if (contactMatch) {
        this.otherEndName = `${contactMatch.firstName} ${
          contactMatch.lastName
        }`;
        this.setFriendlyName();
      } else if (this.otherEndName) {
        this.setFriendlyName();
      }
    }
  }

  private setFriendlyName() {
    this.remoteFriendlyName = `${this.otherEndName} (${this.session._remote_identity._uri._user})`;
  }

  @HostListener("window:keydown", ["$event"])
  onKeydown(event: KeyboardEvent) {
    if (event && event.ctrlKey) {
      if (
        [
          "call_connecting",
          "call_connected",
          "call_m_local_hold_ok",
          "call_m_local_resume_ok"
        ].indexOf(this.callStatus) !== -1
      ) {
        if (77 === event.keyCode) {
          // mute ctrl + m
          this.muteCall();
        } else if (79 === event.keyCode) {
          // hold ctrl + o 79
          this.onHoldCall();
        } else if (84 === event.keyCode) {
          // Transfer ctrl + t
          this.initTransferCall();
        } else if (72 === event.keyCode) {
          // Hang up Ctrl + h or Esc
          this.endCall();
        }
      }
    } else if (
      ["incoming"].indexOf(this.connectionStatus) !== -1 &&
      13 === event.keyCode
    ) {
      // answer Ctrl + a
      this.answer(false);
    } else if (
      27 === event.keyCode &&
      [
        "accepted",
        "connecting",
        "incoming"
      ].indexOf(this.connectionStatus) !== -1
    ) {
      this.endCall();
    } else if (13 === event.keyCode &&
      [
        "ended"
      ].indexOf(this.connectionStatus) !== -1) {
      this.call(false);
    } else if (event.which === AppHotkey.P_KEY && event.altKey) {
      this.toggleCallModel();
    }
  }

  convertMS(ms) {
    let d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    return { d, h, m, s };
  }

    noteHistoryToDropDownChange(event) {
        if (event.value.length === this.positionsList.length) {
            let x = document.getElementById('id_noteHistory_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            let x = document.getElementById('id_noteHistory_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }
}
