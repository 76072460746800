import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import {AllMenusService} from '../../service/all-menus/all-menus.service';
import { UtilsService } from '../../../shared/service/utils.service';
import { EmployeeClockingServiceV2 } from 'app/employee-clocking/service/v2/employee.clocking.v2.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import { EmployeeService } from 'app/employee/service/employee.service';
import { FreelancerService } from 'app/crm/service/freelancer.service';
import { ClientService } from 'app/crm/service/client.service';
import { MembershipService } from 'app/membership/service/membership.service';
import { PlatformAdminService } from 'app/admin/platform-admin/service/platform-admin.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app-breadcrumb.component.html',
    providers: [EmployeeClockingServiceV2, FreelancerService, ClientService, PlatformAdminService, MembershipService ]
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    items: MenuItem[];
    isPunchInOut: Boolean = false;
    disable: any;
    isShowSixHoursMessage: Boolean = false;
    isEmployeeUser = false;
    constructor(public breadcrumbService: BreadcrumbService,
                private router: Router,
        private authService: AuthService,
        private cookieService: CookieService,
        private employeeClockingServiceV2: EmployeeClockingServiceV2,
        public allMenus: AllMenusService,
        private employeeService: EmployeeService,
        private freelancerService: FreelancerService,
        private clientService: ClientService,
        private membershipService: MembershipService,
        private platformAdminService: PlatformAdminService,
        private utilsService: UtilsService,
        private translate: TranslateService) {
        this.isEmployeeUser = this.authService.isEmployeeRole();
        this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.cookieService.set('_currentLang', event.lang);
            this.translate.setDefaultLang(event.lang);
            this.translate.use(event.lang);
        });
        const tempData = JSON.parse(localStorage.getItem('_globals'));
        this.disable = JSON.parse(localStorage.getItem('_disableAll'));
        if (tempData && tempData.level === 8) {
            this.isPunchInOut = true;
        }
    }

    ngOnInit() {
        this.subscription = this.breadcrumbService.itemsHandler.subscribe(response => {
            setTimeout(() => {
                this.items = response;
            }, 0);
        });
        this.getOTIncreaseTime();
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    triggerLogOut() {
        this.onClickLogout();
        // if (this.utilsService.visitedPageData.pageUrl) {
        //     this.utilsService.isLogoutTriggerCompleted = true;
        //     this.utilsService.isLogoutTriggered.next(true);
        // } else {
        //     this.utilsService.isLogoutTriggerCompleted = false;
        //     this.onClickLogout();
        // }
    }

    onClickLogout() {
        this.authService.logUserActivity();
        if (localStorage.getItem('_user') === 'employee') {
            this.employeeService.updateEmployeeOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user')  === 'freelancer') {
            this.freelancerService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }
        if (localStorage.getItem('_user')  === 'admin') {
            this.platformAdminService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user')  === 'client') {
            this.clientService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user')  === 'guess') {
            this.membershipService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        localStorage.clear();
        this.authService.authenticateUser();
        this.authService.setAuthObservable();
        this.router.navigate(['/login']);
        // this.authService.logout().subscribe((res: any) => {
        //     // let resObj = res.json();

        //     // this.cookieService.set("_globals",null);
        //     if (res.status === 200 ) {
        //         //   this.cookieService.set("_globals",null);
        //         this.authService.logUserActivity();
        //         // this.cookieService.deleteAll();
        //         localStorage.clear();
        //         this.authService.authenticateUser();
        //         this.authService.setAuthObservable();
        //         this.router.navigate(['/login']);
        //     }
        //     //this.allMenus.changeTheme("bluegrey");
        //     //this.allMenus.changeLayout("moody");
        //   },error => {
        //     // this.cookieService.deleteAll();
        //     localStorage.clear();
        //     this.authService.authenticateUser();
        //     this.authService.setAuthObservable();
        //     this.router.navigate(['/login']);
        //   });
        // window.location.assign('/');
    }

    // TODO: NEED TO ENABLE AFTER REFACTOR BACKEND
    getOTIncreaseTime() {
        // this.employeeClockingServiceV2.getOverTimeIncrease(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
        //     if (res.status === 'SUCCESS') {
        //         this.isShowSixHoursMessage = res.data.isSixHours;
        //     }
        // });

    }

    onHomeClick() {
        if (this.authService.isEmployeeRole()) {
            this.router.navigate(['/app/employee-dashboard']);
        } else if (this.authService.isClientRole()) {
            this.router.navigate(['/app/payments/invoice']);
        } else {
            this.router.navigate(['/']);
        }
    }
}
