import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {CommService} from '../../../employee/performance/service/comm.service';
import {NavigationEnd, Router} from '@angular/router';
import {CrmCommService} from '../../../crm/service/crm-comm.service';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';
import {AuthService} from '../../../shared/service/auth/auth.service';

@Component({
  selector: 'app-payment-layout',
  templateUrl: './payment-layout.component.html',
  styleUrls: ['./payment-layout.component.scss']
})
export class PaymentLayoutComponent implements OnInit {
    paymentItems: MenuItem[] = [];
    basicMenuItems = [
        {label: 'Process Payments', id: 'payment_process', routerLink: ['/', 'app', 'payments', 'detail'],
            visible: this.authService.isEmployeeRole() ? true :
              this.authService.getLoggedInUserMenuList().includes('59'),
              routerLinkActiveOptions: { exact: true }
        },
        {label: 'Settings', id: 'payment_method', routerLink: ['/', 'app', 'payments', 'method'],
            visible: this.authService.isEmployeeRole() ? true :
              this.authService.getLoggedInUserMenuList().includes('59')
        },
        { label: 'Payment Availability', id: 'payment_availability', routerLink: ['/', 'app', 'payments', 'payment-availability'],
            visible: this.isDisplay()
        },
        {label: 'Payments', id: 'payment_list', routerLink: ['/', 'app', 'payments', 'detail', 'list'],
            visible: this.authService.isEmployeeRole() ? true :
                this.authService.getLoggedInUserMenuList().includes('59'),
                routerLinkActiveOptions: { exact: true }
        },
        {label: 'Payment Methods', id: 'view_payment_method', routerLink: ['/', 'app', 'payments', 'view-payment-method', 'list'],
            visible: this.authService.isCompanyAdminRole() ? true :
              this.authService.getLoggedInUserMenuList().includes('1120')
        },
        {label: 'Invoices', id: 'invoice_search', routerLink: ['/', 'app', 'payments', 'invoice'],
            visible: this.authService.isEmployeeRole() ? true :
              this.authService.getLoggedInUserMenuList().includes('65') && !this.authService.isContractorRole()
        },
        {label: 'Old Invoices', id: 'invoice_search', routerLink: ['/', 'app', 'payments', 'old-invoice'],
            visible: this.authService.isEmployeeRole() ? true :
              this.authService.getLoggedInUserMenuList().includes('1013')
        },
        {label: 'Follow-Ups', id: 'invoice_search', routerLink: ['/', 'app', 'payments', 'follow-up-note'],
        visible: this.authService.isEmployeeRole() ? true :
          this.authService.getLoggedInUserMenuList().includes('1014')
        },
        {label: 'Credits', id: 'invoice_search', routerLink: ['/', 'app', 'payments', 'available-credit'],
        visible: this.authService.isEmployeeRole() ? true :
          this.authService.getLoggedInUserMenuList().includes('1016')
        },
        { label: 'Wallet', id: 'wallet', routerLink: ['/', 'app', 'payments', 'wallet'],
        visible: this.authService.getLoggedInUserMenuList().includes('1666') || this.authService.isSuper() || this.authService.isSubSuper()
        },
        { label: 'Wallet Transactions', id: 'wallet_transaction', routerLink: ['/', 'app', 'payments', 'wallet-transaction'],
            visible: this.authService.getLoggedInUserMenuList().includes('1667') || this.authService.isSuper() || this.authService.isSubSuper()
        },
        { label: 'Client Self-Pay', id: 'client_self_payment', routerLink: ['/', 'app', 'payments', 'client-self-payment'],
        visible: this.authService.getLoggedInUserMenuList().includes('1668') || this.authService.isClientRole()
        },
        {
            label: 'Invoice Verification', id: 'invoice_verification', routerLink: ['/', 'app', 'payments', 'invoice-verification'],
            visible: this.authService.getLoggedInUserMenuList().includes('1678') || this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isSubCompanyAd(),
        },
        {
            label: 'Invoice Summary', id: 'invoice_summary', routerLink: ['/', 'app', 'payments', 'invoice-summary'],
            visible: this.authService.getLoggedInUserMenuList().includes('1679') || this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isSubCompanyAd()
        }
    ];

    activeItem: MenuItem;
    defaultBreadCrumbs: MenuItem[] = [];
    initBreadCrumbs() {
      this.defaultBreadCrumbs = [];
      this.defaultBreadCrumbs.push({ label: 'Payments' });
    }
    routerEventsSubscribe;

    constructor(private commService: CommService ,
                private crmCommService: CrmCommService,
                private router: Router,
                private authService: AuthService,
                private breadcrumbService: BreadcrumbService) {
        this.routerEventsSubscribe = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.url.indexOf('/app/payments/detail') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Process Payments', routerLink: ['/', 'app', 'payments', 'detail']});
                } else if (event.url.indexOf('/app/payments/invoice') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Invoice Search', routerLink: ['/', 'app', 'payments', 'invoice']});
                } else if (event.url.indexOf('/app/payments/old-invoice') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Old Invoices', routerLink: ['/', 'app', 'payments', 'old-invoice']});
                } else if (event.url.indexOf('/app/payments/follow-up-note') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Follow-Ups', routerLink: ['/', 'app', 'payments', 'follow-up-note']});
                } else if (event.url.indexOf('/app/payments/available-credit') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Client Credits', routerLink: ['/', 'app', 'payments', 'available-credit']});
                } else if (event.url.indexOf('/app/payments/payment-availability') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Payment Availability', routerLink: ['/', 'app', 'payments', 'payment-availability']});
                } else if (event.url.indexOf('/app/payments/client-self-payment') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Process Payments'});
                    this.defaultBreadCrumbs.push({label: 'Client Self-Pay', routerLink: ['/', 'app', 'payments', 'client-self-payment']});
                } else if (event.url.indexOf('/app/payments/wallet-transaction') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Wallet Transactions', routerLink: ['/', 'app', 'payments', 'wallet-transaction']});
                } else if (event.url.indexOf('/app/payments/invoice-verification') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Invoice Verification', routerLink: ['/', 'app', 'payments', 'invoice-verification']});
                } else if (event.url.indexOf('/app/payments/view-payment-method/list') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Payment Methods', routerLink: ['/', 'app', 'payments', 'invoice-verification']});
                } else if (event.url.indexOf('/app/payments/invoice-summary') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Invoice Summary', routerLink: ['/', 'app', 'payments', 'invoice-summary']});
                }
                else {
                    this.initBreadCrumbs();
                }
                if (!this.activeItem) {
                    if (event.url.indexOf('/app/payments/invoice') >= 0) {
                        this.activeItem = this.paymentItems[2];
                    } else if (event.url.indexOf('/app/payments/detail') >= 0 && event.url.indexOf('/app/payments/detail/list') < 0) {
                        this.activeItem = this.paymentItems[1];
                    } else {
                        this.activeItem = this.paymentItems[0];
                    }
                }
                this.breadcrumbService.setItems(this.defaultBreadCrumbs);
            }
        });
    }

    ngOnInit() {
        this.paymentItems = this.basicMenuItems;
    }

    ngOnDestroy() {
        if (this.routerEventsSubscribe) {
          this.routerEventsSubscribe.unsubscribe();
        }
    }

    isDisplay() {
        if (this.authService.isContractorRole() || this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAd()
            || this.authService.isSubCompanyAd()) {
            return true;
        }
        return false;
    }

}
