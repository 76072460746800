<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 *ngIf="isPlatformAdmin" class="m-0 ml-2 my-4">{{'Companies' | translate}}</h1>
<p-panel *ngIf="isPlatformAdmin" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'Find Companies' | translate}}</span>
	</p-header>
	<div class="ui-g platform-list-panel">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'dateRange' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100" style="width: 50% !important; flex-grow:1">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput style="padding-left: 10px;" [(ngModel)]="filter.fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="filter.toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
				<!--<input id="calendar" [locale]="{customRangeLabel:'customeRange' | translate }" pInputText type="text" ngxDaterangepickerMd [(ngModel)]="filter.date" [showCustomRangeLabel]="true"  [alwaysShowCalendars]="true" [ranges]="translateRange()" [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" (ngModelChange)="searchBySelectedDate($event)" />-->
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Creator' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [options]="allCreatedByList" placeholder="{{'placeholder.pleaseSelect' | translate}}" [filter]="true" [(ngModel)]="filter.createdBy">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'form.status' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<p-dropdown [options]="statusList" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="filter.status">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label.toLowerCase()  | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label.toLowerCase()  | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 pb-4 pt-3 text-center">
			<button type="button" class="btn btn-danger mx-1" (click)="resetEmployeeSearchForm()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="filterByCompanyAndAgency()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex justify-content-between align-items-center flex-wrap py-4">
	<h1 *ngIf="isPlatformAdmin" cclass="m-0 ml-2">{{'Browse Companies' | translate}}</h1>
	<h1 *ngIf="!isPlatformAdmin" class="m-0 ml-2">{{'Companies' | translate}}</h1>
	<span><button *ngIf="isSuperAdmin()" class="btn btn-primary c-pointer" [routerLink]="['/app/company/add']" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button></span>
</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Companies' | translate}} ({{totalRecords}})</span>
		</p-header>
		<p-table #dt [columns]="cols" (onFilter)="onFilter($event, dt)" [value]="companies" dataKey="id" [rows]="10" [paginator]="true" [resizableColumns]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [(contextMenuSelection)]="selectedCompany" [responsive]="true" [lazy]="false"
				 [globalFilterFields]="['id', 'name', 'email', 'phone', 'addressOne', 'type']">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center justify-content-between w-100">
				<button type="button" class="btn btn-primary mr-2" (click)="exportPdf()" [disabled]="isDisableExport">
					<span class="text-nowrap">
						<i *ngIf="!exportingPDF" class="fa-solid fa-print mr-2"></i>
						<i *ngIf="exportingPDF" class="fa-solid fa-spinner mr-2 loadingHourglass"></i>
						{{'Export PDF'| translate}}</span>
				</button>
				<div class="input-group w-100" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input pInputText type="text" [ngModel]="textSearch" (input)="dt.filterGlobal($event?.target?.value, 'contains')" placeholder="{{'Filter' | translate}}">
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th class="adjust-padding" pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': col.display}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<span class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="!col.sort">
									<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order" [pSortableColumn]="col.field"></p-sortIcon>
								</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'status'">
							<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
						</span>
						<span *ngSwitchCase="'actions'">
							<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
    <ng-template pTemplate="body" let-rowData [pContextMenuRow]="rowData">
      <tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
        <td>
			<span class="one-liner">
				<span class="mobile-table-label">{{'ID' | translate}}: </span>
				<span *ngIf="rowData.id" pTooltip="{{rowData.id}}">{{rowData.id}}</span>
				<span *ngIf="!rowData.id" class="text-muted">{{'no data' | translate}}</span>
			</span>
		</td>
        <td>
			<span class="one-liner">
				<span class="mobile-table-label">{{'form.name' | translate}}: </span>
				<span *ngIf="rowData.name" pTooltip="{{rowData.name}}">{{rowData.name}}</span>
				<span *ngIf="!rowData.name" class="text-muted">{{'no data' | translate}}</span>
			</span>
		</td>
        <td>
			<span class="one-liner">
				<span class="mobile-table-label">{{'form.email' | translate}}: </span>
				<span *ngIf="rowData.email" pTooltip="{{rowData.email}}">{{rowData.email}}</span>
				<span *ngIf="!rowData.email" class="text-muted">{{'no data' | translate}}</span>
			</span>
		</td>
        <td>
			<span class="one-liner">
				<span class="mobile-table-label">{{'form.phone' | translate}}: </span>
				<span *ngIf="rowData.phone" pTooltip="{{rowData?.phone}}">{{rowData?.phone}}</span>
				<span *ngIf="!rowData.phone" class="text-muted">{{'no data' | translate}}</span>
			</span>
		</td>
		  <td>
			<span class="one-liner">
				<span class="mobile-table-label">{{'OperrTel #' | translate}}: </span>
				<span *ngIf="rowData.operrTel" pTooltip="{{rowData?.operrTel}}">{{rowData?.operrTel}}</span>
				<span *ngIf="!rowData.operrTel" class="text-muted">{{'no data' | translate}}</span>
			</span>
		  </td>
        <td>
			<span class="one-liner">
				<span class="mobile-table-label">{{'Address' | translate}}: </span>
				<span *ngIf="rowData.addressOne" pTooltip="{{rowData?.addressOne}}">{{rowData?.addressOne}}</span>
				<span *ngIf="!rowData.addressOne" class="text-muted">{{'no data' | translate}}</span>
			</span>
		</td>
        <td [ngSwitch]="rowData.type">
			<span class="one-liner">
				<span class="mobile-table-label">{{'Type' | translate}}: </span>
				<span *ngIf="rowData.type && rowData.type != ''" pTooltip="{{rowData?.type}}">{{rowData?.type}}</span>
				<span *ngIf="!rowData.type || rowData.type == ''" class="text-muted">{{'no data' | translate}}</span>
			</span>
        </td>

		  <td [ngSwitch]="rowData.planName">
			<span class="one-liner">
				<span class="mobile-table-label">{{'Active Plan' | translate}}: </span>
				<span *ngIf="rowData.planName && rowData.planName != ''" pTooltip="{{rowData?.planName}}">{{rowData?.planName}}</span>
				<span *ngIf="!rowData.planName || rowData.planName == ''" class="text-muted">{{'no data' | translate}}</span>
			</span>
		  </td>
		<td>
			<span class="one-liner">
				<span class="mobile-table-label">{{'Added Date' | translate}}: </span>
				<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}">{{rowData.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
				<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
			</span>
		</td>
        <td translate>
			<span class="one-liner">
				<span class="mobile-table-label">{{'Status' | translate}}:</span>
				<i *ngIf="rowData.status==='ACTIVE' || rowData.status===1" class="fa-solid fa-circle-check text-success m-auto" pTooltip="{{'Active' | translate}}"></i>
				<i *ngIf="rowData.status==='INACTIVE' || rowData.status===0" class="fa-solid fa-circle-xmark text-danger m-auto" pTooltip="{{'Inactive' | translate}}"></i>
			</span>
		</td>
        <td>
			<span class="d-flex align-items-center">
				<span class="mobile-table-label">{{'Actions' | translate}}:</span>
				<i class="fa-solid fa-pen-to-square mx-1 text-primary c-pointer" pTooltip="{{'Edit/View' | translate}}" (click)="viewCompany(rowData)" tooltipPosition="left"></i>
				<i class="fa-solid fa-gear text-purple mx-1 c-pointer " *ngIf="ableToAccessSettings()" pTooltip="{{'settings' | translate}}" label="{{'settings' | translate}}" (click)="companySetting(rowData)" tooltipPosition="left"></i>
				<!--<i class="fa-solid fa-gear text-muted mx-1 c-pointer" *ngIf="!isSettingActive && ableToAccessSettings()" pTooltip="{{'settings' | translate}}" label="{{'settings' | translate}}"  (click)="showAlert()" tooltipPosition="left"></i>-->
				<i class="fa-solid fa-calculator mx-1 text-bright c-pointer" *ngIf="ableToAccessPayrollSettings()" pTooltip="{{'payrollSetting' | translate}}" label="{{'payrollSetting' | translate}}" (click)="viewPayrollSetting(rowData)" tooltipPosition="left"></i>
				<!--<i class="fa-solid fa-calculator mx-1 text-muted c-pointer" *ngIf="!isSettingActive && ableToAccessPayrollSettings()" pTooltip="{{'payrollSetting' | translate}}" label="{{'payrollSetting' | translate}}" (click)="showAlert()" tooltipPosition="left"></i>-->
				<i class="fa-solid fa-file-invoice mx-1 text-muted c-pointer" *ngIf="!isPlatformAdmin" pTooltip="{{'Bill & Payments' | translate}}" label="{{'Bill & Payments' | translate}}" (click)="gotoBill(rowData)" tooltipPosition="left"></i>
				<i class="fa-solid fa-file-pen mx-1 text-info c-pointer" pTooltip="{{'contractLabel' | translate}}" label="{{'setting' | translate}}" (click)="companyContract(rowData)" tooltipPosition="left"></i>
				<!--<i class="fa-solid fa-file-pen mx-1 text-muted c-pointer" *ngIf="!isContractActive" pTooltip="{{'contractLabel' | translate}}" label="{{'setting' | translate}}" (click)="showAlert()" tooltipPosition="left"></i>-->
				<i class="fa-solid fa-square-arrow-up-right mx-1 text-orange c-pointer" *ngIf="ableToAccessOtherSettings()" pTooltip="{{'Other Settings' | translate}}" (click)="viewOtherSetting(rowData)" tooltipPosition="left"></i>
			</span>
        </td>
      </tr>
    </ng-template>
	<ng-template pTemplate="emptymessage" let-columns>
		<tr *ngIf="!loading">
			<td [attr.colspan]="columns?.length" class="text-center py-5">
				<div class="text-muted my-5">{{'no data' | translate}}</div>
			</td>
		</tr>
		<tr *ngIf="loading">
			<td [attr.colspan]="columns?.length" class="text-center py-5">
				<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
			</td>
		</tr>
	</ng-template>
  </p-table>
  </p-panel>
  

  <p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
