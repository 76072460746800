import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { SharedModule } from '../shared/shared.module';
import { CompanyLayoutComponent } from './component/company-layout/company-layout.component';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyListComponent } from './component/company-list/company-list.component';
import { CompanySettingComponent } from './component/company-setting/company-setting.component';
import { CompanySettingNewComponent } from './component/company-setting-new/company-setting-new.component';
import { CompanyAddComponent } from './component/company-add/company-add.component';
import { CompanyFormComponent } from './component/company-form/company-form.component';
import { CompanyViewComponent } from './component/company-view/company-view.component';
import { CompanyUpdateComponent } from './component/company-update/company-update.component';
import { CompanyService } from './service/company.service';
import { CompanyServicev2 } from './service/company.v2.service';
import { CompanyContractComponent } from './component/company-contract/company-contract.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CompanySettingViewComponent } from './component/company-setting-view/company-setting-view.component';
import { CompanyOtherViewComponent } from './component/company-other-view/company-other-view.component';
import { CompanySettingEditComponent } from './component/company-setting-edit/company-setting-edit.component';
import { CompanyHolidayManageComponent } from './component/company-holiday-manage/company-holiday-manage.component';
import { CompanyServiceTypeComponent } from './component/company-service-type/company-service-type.component';
import { CompanyPaymentDetailsComponent } from './component/comany-payment-details/company-payment-details.component';
import { CompanyPaymentMethodComponent } from './component/company-payment-method/company-payment-method.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CompanyHolidayService } from './service/company-holiday.service';
import { CompanyHistoryComponent } from './component/company-history/company-history.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CompanySettingHistoryComponent } from './component/company-setting-history/company-setting-history.component';
import { HolidayHistoryComponent } from './component/holiday-history/holiday-history.component';
import { CompanyPlanComponent } from './component/company-plan/company-plan.component';
import { DragDropDirective } from './drag-drop.directives';
import { CompanyOtherSettingHistoryComponent } from './component/company-other-setting-history/company-other-setting-history.component';
import { CompanyOtherSettingHistoryService } from './service/company-other-setting-history.service';
import { DocumentSettingsComponent } from './component/document-settings/document-settings.component';
import { DocumentSettingsService } from './service/document-settings.service';
import { DocumentSettingsFormComponent } from './component/document-settings-form/document-settings-form.component';
import { PaymentModule } from 'app/payment/payment.module';
import { PayrollHolidayFormComponent } from 'app/payroll-setting/component/payroll-holiday-form/payroll-holiday-form.component';
import { PayrollHolidayAddComponent } from 'app/payroll-setting/component/payroll-holiday-add/payroll-holiday-add.component';
import { PayrollHolidayEditComponent } from 'app/payroll-setting/component/payroll-holiday-edit/payroll-holiday-edit.component';
import { PayrollHolidayFormNewComponent } from 'app/payroll-setting/component/payroll-holiday-form-new/payroll-holiday-form-new.component';
import { ConfirmationService } from 'primeng';
import {DocumentSettingHistoryComponent} from "./component/document-setting-history/document-setting-history.component";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimeNgModule,
    SharedModule,
    CompanyRoutingModule,
    ProgressSpinnerModule,
    SignaturePadModule,
    PaymentModule,
    NgxDaterangepickerMd.forRoot()
  ],
  declarations: [
    CompanyLayoutComponent,
    CompanyListComponent,
    CompanySettingComponent,
    CompanySettingNewComponent,
    CompanyFormComponent,
    CompanyAddComponent,
    CompanyViewComponent,
    CompanyUpdateComponent,
    CompanyContractComponent,
    CompanySettingViewComponent,
    CompanySettingEditComponent,
    CompanyServiceTypeComponent,
    CompanyOtherViewComponent,
    CompanyHolidayManageComponent,
    HolidayHistoryComponent,
    CompanyHistoryComponent,
    CompanySettingHistoryComponent,
    CompanyPlanComponent,
    DragDropDirective,
    CompanyOtherSettingHistoryComponent,
    DocumentSettingsComponent,
    DocumentSettingsFormComponent,
    CompanyPaymentDetailsComponent,
    CompanyPaymentMethodComponent,
    PayrollHolidayFormComponent,
    PayrollHolidayAddComponent,
    PayrollHolidayFormNewComponent,
    PayrollHolidayEditComponent,
    DocumentSettingHistoryComponent
  ],
  providers: [
    CompanyService,
    CompanyServicev2,
    CompanyHolidayService,
    CompanyOtherSettingHistoryService,
    DocumentSettingsService,
    ConfirmationService
  ],
  exports: [
    CompanyServiceTypeComponent
  ],
})
export class CompanyModule {
}
