import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';

import * as _ from 'lodash';
import {DatePipe} from "@angular/common";
import {PayrollSettingHistoryService} from "../../service/payroll-setting-history.service";
import {PayrollSettingHistory} from "../../model/payroll-setting-history.model";


@Component({
    selector: 'app-payroll-setting-history',
    templateUrl: './payroll-setting-history.component.html',
    styleUrls: ['./payroll-setting-history.component.scss'],
    providers: [PayrollSettingHistoryService]
})
export class PayrollSettingHistoryComponent implements OnInit {
    @Input() payrollSettingId: number;
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    size: number;
    page: number;
    payrollSettingHistory: PayrollSettingHistory[];
    cols: any[] = [
        { field: 'updatedDate', label: 'history.updatedDate' },
        { field: 'userName', label: 'history.userName' },
        { field: 'oldValue', label: 'history.oldValue' },
        { field: 'newValue', label: 'history.newValue' },
        { field: 'action', label: 'history.action' }
    ];
    first = 0;
    rangeDates;
    maxDate: any;
    fieldNames: any[];
    fromDate: any;
    toDate: any;
    selectedField = 'Field';
    loading = false;
    @Output() onHistoryHide: EventEmitter<any> = new EventEmitter();
    constructor(
        private datePipe: DatePipe,
        private breadcrumbService: BreadcrumbService,
        private historyService: PayrollSettingHistoryService
    ) {
        this.breadcrumbService.setItems([
            { label: 'CRM', routerLink: ['/app/crm'] },
            { label: 'History', routerLink: ['/app/crm/history'] }
        ]);
        this.maxDate = new Date();
    }

    ngOnInit() {
        this.fieldNames = [
            { label: 'Punch In/Out Daily Rate', value: 'punchDailyRate' },
            { label: 'Tracking Daily Rate', value: 'punchTrackingRate' },
            { label: 'Payroll Frequency', value: 'payReference' },
            { label: 'Mileage Charge', value: 'mileage' },
            { label: 'Mile', value: 'mile' },
            { label: 'Amount Rounding', value: 'roundOn' },
            { label: 'Mins', value: 'mins' },
            { label: 'Payroll Week Ending', value: 'payrollWeekEnding' },
            { label: 'Minimum Hours For Daily', value: 'minimumHourDaily' },
            { label: 'Allow Overtime', value: 'useOvertime' },
            { label: 'Display Employee Name on Stub', value: 'displayEmployeeNameOnSub' },
            { label: 'Overtime After', value: 'overtimeAfter' },
            { label: 'Overtime Rate (Multiplier)', value: 'overtimeMultipler' },
            { label: 'PTO Calculation', value: 'sickTimeCalculationHour' },
            { label: 'Sick time Caculation Every', value: 'sickTimeCalculationForEvery' },
            { label: 'Vacation Caculation Hours', value: 'vacationTimeCalculationHour' },
            { label: 'Vacation time Caculation Every', value: 'vacationTimeCalculationForEvery' },
            { label: 'Vacation time Caculation Every', value: 'vacationTimeCalculationForEvery' },
            { label: 'Sick Day/Vacation Calculation Method', value: 'vacationMethod' },
            { label: 'Max Paid Sick Leave per Year', value: 'maxSickLeave' },
            { label: 'Max Paid Vacation per Year', value: 'maxVacation' }
        ];

        this.fieldNames.sort((first, second):number=> {
            if (first.label < second.label) return -1;
            if (first.label > second.label) return 1;
            return 0;
        });
    }
    public hideHistory() {
        this.onHistoryHide.emit();
      }
    loadDataSource(event?: any) {
        this.dataSourceSubject.next([]);
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    
        const options: any = {
          size: 9999,//this.size,
          page: this.page,
          fieldName: this.selectedField
        };
    
        if (this.selectedField) {
            options.fieldName = this.selectedField;
        }
        if (this.fromDate) {
            options.fromDate = this.fromDate;
        }
        if (this.toDate) {
            options.toDate = this.toDate;
        }
        console.log('options', options);
    
        if (!this.selectedField) {
          this.dataSourceSubject.next([]);
          return;
        }
        this.historyService.getPayrollSettingListHistory(options).subscribe(
            (data: any) => {
                this.payrollSettingHistory = data.data.content;
                this.payrollSettingHistory.forEach(history => {
                    if (history.fieldName === 'date') {
                        history.newValue = this.datePipe.transform(history.newValue, 'M/d/y h:m:s a');
                        history.oldValue = this.datePipe.transform(history.oldValue, 'M/d/y h:m:s a');
                    }
                });
                this.dataSourceSubject.next(data.data);
            }
        );
    }

    filterClientHistory() {
        const fromDate = this.rangeDates ? new Date(this.rangeDates[0]).getTime() : null;
        const toDate = this.rangeDates ? new Date(this.rangeDates[1]).getTime() : null;
        const options: any = {};
        if (this.payrollSettingId) {
            options.payrollSettingId = this.payrollSettingId;
        }
        if (this.selectedField) {
            options.fieldName = this.selectedField;
        }
        if (fromDate) {
            options.fromDate = fromDate;
        }
        if (toDate) {
            options.toDate = toDate;
        }
        options.size = 9999;
        options.page = 0;
        this.loading = true;
        this.historyService.getPayrollSettingListHistory(options).subscribe(
            (data: any) => {
                this.payrollSettingHistory = data.data.content;
                this.payrollSettingHistory.forEach(history => {
                    if (history.fieldName === 'date') {
                        history.newValue = this.datePipe.transform(history.newValue, 'M/d/y h:m:s a');
                        history.oldValue = this.datePipe.transform(history.oldValue, 'M/d/y h:m:s a');
                    }
                });
                this.loading = false;
                this.dataSourceSubject.next(data.data);
            },
            error => {
                console.error('Error fetching data', error);
                this.loading = false;
            }
        );

    }


    reset() {
        this.rangeDates = [];
        this.selectedField = null;
        this.loadDataSource();
    }
}
