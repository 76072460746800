import { IdleTimerService } from './../../service/idle-timer.service';
import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as MOMENT from 'moment';
import * as moment from 'moment';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {EmployeeClockingService} from '../../service/employee-clocking.service';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {UtilsService} from '../../../shared/service/utils.service';
import {PunchHistory} from '../../../employee/model/punch-history';
import {Employee} from '../../../employee/model/employee.model';
import {EmployeeService} from '../../../employee/service/employee.service';
import {Constants} from '../../../shared/model/constants';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {AesUtilService} from '../../../shared/service/aes-util.service';
import {PayrollSettingService} from '../../../payroll-setting/service/payroll-setting.service';
import axios from 'axios';
import {IpAddressSetting} from '../../../company/model/v2/ip-address.model';
import {IpAddressSettingServiceV2} from '../../../company/service/ip-address-setting.v2.service';
import {PunchType, PunchDirection} from '../../model/v2/punch.enum';
import {LazyLoadEvent} from 'primeng';
import * as _ from 'lodash';
import {MessageService} from 'primeng/api';

// V2 dependencies
import {EmployeeSearch} from '../../../shared/model/search/employee.search';
import {EmployeeServiceV2} from '../../../employee/service/v2/employee.v2.service';
import {forkJoin, from, Subscription} from 'rxjs';
import {PaginatedResponse} from 'app/shared/model/PageResponse/paginated.response';
import {ResponseStatus, ClockLocation, HttpStatus, ClockLocationName} from '../../../shared/model/enums';
import {EmployeePunchRequest, EmployeePunchResponse} from '../../model/v2/employee.clocking';
import {EmployeeClockingServiceV2} from '../../service/v2/employee.clocking.v2.service';
import {BaseResponse} from 'app/shared/model/PageResponse/base.response';
import {CompanySettingServiceV2} from 'app/company/service/company-setting.v2.service';
import {DocumentsService} from 'app/shared/service/documents-upload/documents.service';

import {PayrollSetting} from 'app/payroll-setting/model/payroll-setting.model';
import {PlatformAdminService} from 'app/admin/platform-admin/service/platform-admin.service';
import {AgencyAdminService} from 'app/admin/agency-admin/service/agency-admin.service';
import {FreelancerService} from 'app/crm/service/freelancer.service';
import {MembershipService} from 'app/membership/service/membership.service';
import {ClientService} from 'app/crm/service/client.service';
import {MonitoringDetailsService} from 'app/admin/process-monitor/service/monitoring-details.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {FirebaseNotificationService} from 'app/shared/service/firebase-notification.service';
import {debounceTime} from 'rxjs/operators';
import {FaceRecognitionModel} from 'app/face-recognition/model/face-recognition.model';
import {EmployeeClockingTrackingServiceV2} from '../../service/v2/employee.clocking.tracking.v2.service';
import { FaceRecognitionService } from 'app/face-recognition/service/face-recognition.service';
import { CompanyService } from 'app/company/service/company.service';
import { CompanyAdminService } from 'app/admin/company-admin/service/company-admin.service';
import { ClockingAutorizationCodeService } from 'app/employee-clocking/service/clocking-authorization-code.service';
import { NotificationService } from 'app/notification/service/notification.service';
import { FaceRecognitionComponent } from 'app/face-recognition/face-recognition/face-recognition.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-punch-in-out',
    templateUrl: './punch-in-out.component.html',
    styleUrls: ['./punch-in-out.component.scss'],
    providers: [EmployeeClockingService, FaceRecognitionService, EmployeeClockingServiceV2,
        FreelancerService, ClientService, PlatformAdminService, MembershipService, NotificationService,
        EmployeeServiceV2, MessageService, IpAddressSettingServiceV2, IdleTimerService]
})
export class PunchInOutComponent implements OnInit, AfterViewInit, OnDestroy {
    size: number;
    page: number;
    photoURL: String;
    employeePunch: EmployeePunchRequest = new EmployeePunchRequest();
    punchInOutData: EmployeePunchResponse = new EmployeePunchResponse();
    punchHeader: string;
    signaturePadConfig: Object = {
        'minWidth': 5,
        'minHeight': 200,
        'canvasWidth': 400,
        'canvasHeight': 200,
        'canvasBorder': '1px solid #e4e4e4',
        'penColor': '#007bff'
    };
    @ViewChild('signaturePad') signaturePad: SignaturePad;
    @ViewChild('signaturePadSection') signaturePadSection: ElementRef;
    pinInputType: string;
    togglePinType: boolean;
    private employeePunchSign: any;
    msgs = [];
    isInvalidPin: boolean;
    isInvalidSign: boolean;
    isInvalidPinMsg: string;
    payrollSetting: PayrollSetting;
    employeeData: any = {};
    showPIOD = false;
    role = 0;
    showFRDialog = false;
    showWPDialog = false;
    multipleWebcamsAvailable;
    listIpAddress: IpAddressSetting[] = [];
    camErrors: Array<any>;
    triggerSnapShot: Subject<void> = new Subject<void>();
    triggerWPSnapShot: Subject<void> = new Subject<void>();
    webcamImage: any;
    detectFace: any;
    readOnly: boolean;
    pinRegExp: RegExp = /^[0-9]{1,4}$/;
    selectedPunchHistory;
    totalHoursToday: any = 0;
    totalHoursWeek: any = 0;
    employee: Employee = new Employee();
    punchHistoryList: Array<any>;
    punchHistoryListUpdated: Array<PunchHistory> = [];
    types: Array<any> = [];
    punchType: number;
    isPunchInOut = false;
    dateTimeNow;
    disabledPinButton = false;
    payrollDate: Date;
    disabledEnterButton = false;
    disabledStarkWork = false;
    disabledLunchOutTime = false;
    currentLoggedInUserId: number;
    disabledLunchInTime = false;
    disabledEndWork = false;
    refreshTokenInterval;
    companyId: any;
    punchInOutOption = '';
    punchInOutNote = '';
    lunchTimeLeft;
    audio = new Audio();
    pinOffLines: any;
    private _jsonIpURL = 'assets/ipAddress.json';
    ipAddress = '';

    @Output() userSignOut = new EventEmitter();
    localIpAddress: Array<any> = [];
    showLunchDurationDialog = false;
    showAddNoteDialog = false;
    showWorkHours = true;
    showPlanAssignmentDialog = false;
    isSkipCheckPlanAssignment = false;
    showPopupSelect = false;

    currentPunchType: PunchType;
    currentPunchDirection: PunchDirection;
    welcomeText = '';
    currentDate: any;
    currentHour: any;
    currentEmp: any;
    timeSpent = new Date();
    private _routerSub = Subscription.EMPTY;
    payload: any;

    subscriptions: Subscription = new Subscription();
    trackingSession = new Date().getTime();

    faceRecognitionMode = false;
    faceModel: FaceRecognitionModel;
    faceMode = 'detection';
    faceRecognitionChecking = false;
    retryFaceRecognitionPopup = false;
    disabledRetryFaceRecognition = false;
    clockingTracking: any = {};
    faceRecognized = false;
    errorPopup = false;
    errorMessage = null;
    errorTitle = null;
    pinMethod;
    faceRecognitionLoaded = false;

    companyLogo;
    companyName;

    successMessage;
    useFaceDetection;
    allEmployeeList = [];
    personName = '';
    @ViewChild('faceRecognition') faceRecognition: FaceRecognitionComponent;

    checkPinFail = false;

    authorizationCode;
    authorizationCodePopup = false;
    nextStepAfterAuthorization: any = {};
    requestCodeCountdown = 0;
    requestCodeCountdownStr;
    requestCodeInterval;
    showLunchDurationConfirmDialog = false;
    lunchDurationDate;
    lunchDurationNote;
    punchHistoryListTable: any[] = [
        { field: 'day', label: 'Day' },
        { field: 'type', label: 'Punch' },
        { field: 'date', label: 'Date' }
    ];
    loading = false;
    loadingButtons = false;
    limitLateness: number;
    latenessCount = 0;
    employeeLatenessDecision: number;
    photoBase64String;

    pauseDetectFace;
    detectionCount = 0;

    companySetting;
    isFaceDetection = false;
    isManualInput = false;
    faceRecognitionBase64;
    isToastShowing;

    disabledTakePhoto;
    languages = [
        { label: '日本語', value: 'jp', checked: false },
        { label: '한국어', value: 'ko', checked: false },
        { label: '汉语（简体）', value: 'zh', checked: false },
        { label: '漢語 (繁體）', value: 'zh2', checked: false },
        { label: 'Español', value: 'es', checked: false },
        { label: 'French', value: 'fr', checked: false },
        { label: 'Pу́сский', value: 'ru', checked: false },
        { label: 'Hindi', value: 'hi12', checked: false },
        { label: 'العربية', value: 'ar', checked: false },
        { label: 'Tiếng Việt', value: 'vi', checked: false }
      ];

      selectedLanguages: string[] = [];

      selectedCount = 0;
      showJA = false;
      showKO = false;
      showZH = false;
      showZH2 = false;
      showES = false;
      showFR = false;
      showRU = false;
      showHI = false;
      showAR = false;
      showVI = false;
      selectedColors: string[] = [];

      selectedColor: String = 'blue';
      colors = [
        { label: 'Red', value: 'red' },
        { label: 'Orange', value: 'orange' },
        { label: 'Yellow', value: 'yellow' },
        { label: 'Green', value: 'green' },
        { label: 'Teal', value: 'teal' },
        { label: 'Blue', value: 'blue' },
        { label: 'Purple', value: 'purple' },
        { label: 'Dark', value: 'dark' },
      ];
      red = false;
      orange = false;
      yellow = false;
      green = false;
      purple = false;
      dark = false;
    constructor(private employeeService: EmployeeService,
                private clockingService: EmployeeClockingService,
                private http: HttpClient,
                public datePipe: DatePipe,
                public ipAddressService: IpAddressSettingServiceV2,
                private aesUtilService: AesUtilService,
                private payrollSettingService: PayrollSettingService,
                private authService: AuthService,
                private platformAdminService: PlatformAdminService,
                private messageService: MessageService,
                private _employeeService: EmployeeServiceV2,
                private agencyAdminService: AgencyAdminService,
                private _employeeClockingService: EmployeeClockingServiceV2,
                private companySettingServiceV2: CompanySettingServiceV2,
                private utilsService: UtilsService,
                private documentService: DocumentsService,
                private freelancerService: FreelancerService,
                private clientService: ClientService,
                private membershipService: MembershipService,
                private router: Router,
                private monitoringDetailsService: MonitoringDetailsService,
                private companyServiceoute: CompanyService,
                private ngZone: NgZone,
                private db: AngularFireDatabase,
                private firebaseNotificationService: FirebaseNotificationService,
                private _employeeClockingTrackingService: EmployeeClockingTrackingServiceV2,
                private faceRecognitionService: FaceRecognitionService,
                private companyAdminService: CompanyAdminService,
                private clockingAutorizationCodeService: ClockingAutorizationCodeService,
                private idleTimerService: IdleTimerService,
                private notificationService: NotificationService,
                private translateService: TranslateService,
                private translatePipe: TranslatePipe
        ) {
        this.currentLoggedInUserId = this.authService.getCurrentLoggedInId();
        this.role = this.authService.getRoleLevel();
        this.changeImageProfile();
        this._routerSub = router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url !== '/app/employee-clocking/punch') {
                    this.monitoringDetailsService.monitorAction(
                        `Navigated to Punch In/Out page `,
                        this.timeSpent,
                        {
                            navigated_to_punch_in_out_page_by: this.authService.getCurrentLoggedInName()
                        },
                        'complete',
                        `Navigated to Punch In/Out page `,
                        0
                    );
                }
            }
        });
    }

    // public getJSON(): Observable<any> {
    //     return this.http.get(this._jsonURL);
    // }

    public getIpJSON(): Observable<any> {
        return this.http.get(this._jsonIpURL);
    }

    ngOnInit() {
        setInterval(() => {
            this.handleDatePunchInOut();
        }, 500);
        this.idleTimerService.onUserInactive().subscribe(() => {
            this.clearPinNumber();
        });
        const tempData = JSON.parse(localStorage.getItem('_globals'));

        if (tempData && tempData.level === 8) {
            this.isPunchInOut = true;
            
            this.employeeService.filterByCompany({
                'companyId': this.authService.getCurrentCompanyId()
            }).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                    this.allEmployeeList = res.data.content;
                }
            });
            // refresh token
            this.refreshTokenInterval = setInterval(() => {
                this.authService.refreshToken();
            }, Constants.REFRESH_TOKEN_TIMER);
        } else if (!this.authService.isEmployeeRole()) {
            this.employeeService.filterByCompany({
                'companyId': this.authService.getCurrentCompanyId()
            }).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                    this.allEmployeeList = res.data.content;
                }
            });
        }
        this.companyAdminService.getPunchInOutAdmin(this.authService.getCurrentCompanyId()).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data) {
                this.faceRecognized = resObj.data.useFaceDetection;
                this.pinMethod = resObj.data.pinMethod;
                // this.faceRecognized = true;
                if (this.isPunchInOut && this.faceRecognized) {
                    this.faceRecognitionModeChange()
                }
            }
        });
        this.dateTimeNow = moment().format('dddd Do, YYYY hh:mm');
        this.liveStartTime();
        this.pinInputType = 'password';
        this.togglePinType = false;
        this.isInvalidPin = false;
        this.isInvalidSign = false;
        this.showPIOD = false;
        this.showFRDialog = false;
        this.showWPDialog = false;
        this.camErrors = [];

        this.employee = new Employee();

        this.employee.firstName = this.authService.getUserInfo().firstName;
        this.employee.lastName = this.authService.getUserInfo().lastName;
        this.employee.companyId = this.authService.getUserInfo().companyId;

        // this.getJSON().subscribe(response => {
        //     let data = response.data;
        //     let temp = localStorage.getItem('_pinInfo');
        //     if (temp) {
        //         this.pinOffLines = this.aesUtilService.decrypt(temp);
        //     } else {
        //         localStorage.setItem('_pinInfo', data);
        //         this.pinOffLines = this.aesUtilService.decrypt(data);
        //     }
        // });

        this.getIpJSON().subscribe(response => {
            this.localIpAddress = response.companyIPs;
        });

        this.punchHistoryList = [];
        this.types = [
            {label: 'Part Time', value: 'part_time'},
            {label: 'Full Time', value: 'full_time'},
            {label: 'Consultant', value: 'consultant'},
            {label: 'Volunteer', value: 'volunteer'}];
        // get local ip address
        this.getLocalIpAddress();

        // handle ip location

        // this.ipAddressService.findAllByCompanyId(agency.id).subscribe(res => {
        //     const resObj: any = res;
        //     this.listIpAddress = resObj.data;
        // });
        this._employeeService.get(this.authService.getCurrentLoggedInId()).subscribe((res: any) => {
            this.currentEmp = res.data;
            this.latenessCount = (this.currentEmp||{}).punchInLateCount;
            
            if (this.authService.isEmployeeRole()) {
                this.faceRecognitionService.listFace({
                    human_id: this.currentEmp.id + '_' + this.currentEmp.firstName + '_' + this.currentEmp.lastName
                }).subscribe((faces: any) => {
                    if (faces && faces.length > 0) {
                        this.faceRecognized = true;
                    }
                });
            }
        });

        if (!this.isPunchInOut) {
            setInterval(() => {
                this.totalHours();
            }, 60 * 1000);
        }

        this.subscriptions.add(this.db.object(`/punch-in-out/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().level}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any) => {
            if (event) {
                const currentTimestamp = new Date().getTime();
                if (event.updateTime > currentTimestamp - 15000 && this.trackingSession !== event.trackingSession) {
                    if (this.employeePunch.pin && (!this.disabledStarkWork || !this.disabledLunchOutTime || !this.disabledLunchInTime || !this.disabledEndWork)) {
                        const user = this.authService.getUserInfo();
                        const options = {
                            companyId: user.companyId,
                            roleId: user.level,
                            id: user.adminId,
                        };
                        this._employeeClockingService.verifyNextStep(this.employeePunch.employeeId, options).subscribe((resObj: any) => {
                            if (resObj.status === ResponseStatus.SUCCESS && resObj.data) {
                                if (this.employeePunch.clockAuthorization === 'Request authorization code') {
                                    this.nextStepAfterAuthorization = {
                                        disabledStarkWork: resObj.data.startWork,
                                        disabledLunchOutTime: resObj.data.lunchOutTime,
                                        disabledLunchInTime: resObj.data.lunchInTime,
                                        disabledEndWork: resObj.data.lunchInTime,
                                    }
                                    this.requestCodeCountdownStr = null;
                                    this.translateService.use('en');
                                    this.authorizationCodePopup = true;
                                } else {
                                    this.disabledStarkWork = resObj.data.startWork;
                                    this.disabledLunchOutTime = resObj.data.lunchOutTime;
                                    this.disabledLunchInTime = resObj.data.lunchInTime;
                                    this.disabledEndWork = resObj.data.endWork;
                                }
                            }
                        });
                    }
                }
            }
        }));
        this.payrollSettingService.getbyId(this.employee.companyId).subscribe(res1 => {
            const res1Obj: any = res1;
            this.payrollSetting = res1Obj.data;
            this.limitLateness = this.payrollSetting?.employeeLateLimit;
            this.employeeLatenessDecision = this.payrollSetting?.employeeLatenessDecision;
        });

        this.loadPunchHistoryLazy();
    }

    triggerLogOut() {
        if (localStorage.getItem('_user') === 'employee') {
            this.employeeService.updateEmployeeOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user') === 'freelancer') {
            this.freelancerService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }
        if (localStorage.getItem('_user') === 'admin') {
            this.platformAdminService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user') === 'client') {
            this.clientService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        if (localStorage.getItem('_user') === 'guess') {
            this.membershipService.updateOnlineStatus(this.authService.getCurrentLoggedInId(), 0).subscribe((res: any) => {
            });
        }

        localStorage.clear();
        this.authService.authenticateUser();
        this.authService.setAuthObservable();
        this.router.navigate(['/login']);
    }

    handleDatePunchInOut() {
        const today = new Date();
        const curHr = today.getHours();
        if (curHr < 12) {
            this.welcomeText = 'Good Morning';
        } else if (curHr < 18) {
            this.welcomeText = 'Good Afternoon';
        } else {
            this.welcomeText = 'Good Evening';
        }
        this.currentDate = moment().format('dddd, MMMM D YYYY');
        this.currentHour = moment().format('h:mm a');
    }

    ngAfterViewInit() {
        // this.signaturePad is now available
        if (this.signaturePad) {
            this.signaturePad.set('minWidth', 5);

        }
        setTimeout(() => {
            this.initSignalPad();
        }, 100);

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        clearInterval(this.refreshTokenInterval);
        setTimeout(() => {
            this._routerSub.unsubscribe();
        }, 1000);
        if (this.requestCodeInterval) {
            clearInterval(this.requestCodeInterval);
        }
        if (this.faceRecognition) {
            this.faceRecognition.stopVideo();
        }
    }

    liveStartTime() {
        const today = moment().toDate();
        let h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        m = this.liveCheckTime(m);
        s = this.liveCheckTime(s);
        let tag = 'AM';
        if (h > 12) {
            h -= 12;
            tag = 'PM';
        }
        const showOnlyDayAndDate = moment().format('dddd Do, YYYY');
        this.dateTimeNow = showOnlyDayAndDate + ' ' + h + ':' + m + ':' + s + ' ' + tag;
        setTimeout(this.liveStartTime.bind(this), 500);
    }

    liveCheckTime(i) {
        if (i < 10) {
            i = '0' + i;
        }  // add zero in front of numbers < 10
        return i;
    }

    async validateAdminPin() {
        const searchCriteria: EmployeeSearch = {};
        searchCriteria.pin = this.employeePunch.pin;
        searchCriteria.companyId = this.authService.getCurrentCompanyId();
        const employeeResponse: PaginatedResponse<Employee> = await this._employeeService.searchByGet(searchCriteria).toPromise();
        if (employeeResponse.status === ResponseStatus.SUCCESS && employeeResponse.data && employeeResponse.data.content.length > 0) {
            const employeeStatus = employeeResponse.data.content[0].status;
            if (employeeStatus !== 1) {
                if (!this.isPunchInOut) {
                    this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('Account Deactivated') + '. ' + this.translatePipe.transform('Please Contact HR or System Admin'));
                    // this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Account Deactivated'), detail: this.translatePipe.transform('Please Contact HR or System Admin')});
                }
                this.clearPinNumber();
                return Promise.resolve(false);
            }

            this.latenessCount = employeeResponse.data.content[0].punchInLateCount;
            if (this.limitLateness && this.employeeLatenessDecision !== 3 && this.latenessCount >= this.limitLateness) {
                this.latenessCount = this.latenessCount ? this.latenessCount : 0;
                this.buildErrorMessage('', "Login Failed, You've exceed " + this.latenessCount + " times of the lateness, Please Contact your Manager/HR");
                setTimeout(() => {
                    this.clearPinNumber();
                }, 2500);
                return Promise.resolve(false);
            } else {
                this.currentEmp = employeeResponse.data.content[0];
                this.employeePunch.employeeId = employeeResponse.data.content[0].id;
                this.employeePunch.fullName = employeeResponse.data.content[0].fullName;
                this.employeePunch.clockAuthorization = employeeResponse.data.content[0].clockAuthorization;
                this.companyId = employeeResponse.data.content[0].companyId;
                this.isInvalidPin = false;
                this.disabledEnterButton = true;
                return Promise.resolve(true);
            }
        }
        this.isInvalidPin = true;
        this.clockingTracking.employeeId = this.employeePunch.employeeId;
        this.clockingTracking.date = new Date();
        this.clockingTracking.pin = this.employeePunch.pin;
        this.clockingTracking.clockAction = 'WRONG_PIN';
        this.clockingTracking.status = 'Error';
        this.clockingTracking.locationName = this.employeePunch.locationName;
        this.clockingTracking.accountUsed = this.authService.getCurrentUsername();
        this.clearPinNumber();
        if (!this.isPunchInOut) {
            this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('Invalid PIN. Please try again.'));
            // this.messageService.add({severity: 'info', summary: this.translatePipe.transform('Invalid Pin'), detail: this.translatePipe.transform('Please enter correct pin')});
        }
        this.isInvalidPinMsg = 'This PIN is invalid. Please enter a valid PIN';
        this.handlingInvalidPin();
        this.punchTracking();
        return Promise.resolve(false);
    }

    async validateEmployeePin() {
        if (this.currentEmp.status !== 1) {
            if (!this.isPunchInOut) {
                this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('Account Deactivated') + '. ' + this.translatePipe.transform('Please Contact HR or System Admin'));
                // this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Account Deactivated'), detail: this.translatePipe.transform('Please Contact HR or System Admin')});
            }
            this.clearPinNumber();
            return Promise.resolve(false);
        }
        if (this.limitLateness && this.employeeLatenessDecision !== 3 && this.latenessCount >= this.limitLateness) {
            this.latenessCount = this.latenessCount ? this.latenessCount : 0;
            this.buildErrorMessage('', "Login Failed, You've exceed " + this.latenessCount + " times of the lateness, Please Contact your Manager/HR");
            setTimeout(() => {
                this.clearPinNumber();
            }, 2500);
            return Promise.resolve(false);
        }
        this.employeePunch.fullName = this.currentEmp.fullName;
        this.employeePunch.employeeId = this.currentEmp.id;
        this.employeePunch.clockAuthorization = this.currentEmp.clockAuthorization;
        this.companyId = this.currentEmp.companyId;
        this.isInvalidPin = false;
        this.disabledEnterButton = true;

        const pinValidation: any = await this._employeeService.validatePin(this.employeePunch.employeeId, this.employeePunch.pin).toPromise();
        if (pinValidation.data.locked || pinValidation.data.wrongPinTimes > 0) {
            this.isInvalidPin = true;
            this.clearPinNumber();
            if (pinValidation.data.locked) {
                this.isInvalidPinMsg = 'PIN was blocked';
                if (!this.isPunchInOut) {
                    this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('your_attempts_are_exceeded_the_limitation'));
                    // this.messageService.add({
                    //     severity: 'info',
                    //     summary: this.translatePipe.transform('PIN was blocked'),
                    //     detail: this.translatePipe.transform('your_attempts_are_exceeded_the_limitation')});
                }
            } else {
                this.clockingTracking.employeeId = this.employeePunch.employeeId;
                this.clockingTracking.date = new Date();
                this.clockingTracking.pin = this.employeePunch.pin;
                this.clockingTracking.clockAction = 'WRONG_PIN';
                this.clockingTracking.status = 'Error';
                this.clockingTracking.createdByUsr = this.authService.getCurrentUsername();
                this.clockingTracking.locationName = this.employeePunch.locationName;
                this.clockingTracking.accountUsed = this.authService.getCurrentUsername();
                this.punchTracking();
                this.isInvalidPinMsg = 'This Pin is invalid. Please enter a valid Pin';
                if (!this.isPunchInOut) {
                    this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('Invalid PIN! Please enter correct PIN.'));
                    // this.messageService.add({severity: 'info', summary: this.translatePipe.transform('Invalid Pin'), detail: this.translatePipe.transform('Please enter correct pin')});
                }
            }
            this.handlingInvalidPin();
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }


    async validatePin(manualInput = false, faceRecognition = false) {
        this.isFaceDetection = faceRecognition;
        this.isManualInput = manualInput;
        this.loadingButtons = true
        this.errorMessage = null;
        this.successMessage = null;
        if (!this.employeePunch.pin) {
            this.isInvalidPin = true;
            this.isInvalidPinMsg = 'Please enter a pin';
            return Promise.resolve(false);
        }

        try {
            let validateResult: boolean;
            if (this.authService.isEmployeeRole()) {
                validateResult = await this.validateEmployeePin();
            } else if (this.authService.isContractorRole()) {
                validateResult = await this.validateContractorPin();
            } else {
                validateResult = await this.validateAdminPin();
            }
            // const validateResult = this.authService.isEmployeeRole() ? await this.validateEmployeePin() : await this.validateAdminPin();
            if (!validateResult) {
                this.checkPinFail = true;
                if (this.isPunchInOut) {
                    if (this.errorMessage == null) {
                        this.buildErrorMessage(PunchType.CLOCK_IN);
                    }
                }
                return Promise.resolve(false);
            } else {
                this.checkPinFail = false;
            }
    
            await this.ipAddressService.findAllByCompanyId(this.companyId).toPromise().then(resIpAddressObj => {
                if (resIpAddressObj.status === ResponseStatus.SUCCESS) {
                    this.listIpAddress = resIpAddressObj.data;
                }
            });
            const user = this.authService.getUserInfo();
            const options = {
                companyId: user.companyId,
                roleId: user.level,
                id: user.adminId,
            };
            await this.ipAddressService.findAllByCompanyId(this.companyId).toPromise().then(resIpAddressObj => {
                if (resIpAddressObj.status === ResponseStatus.SUCCESS) {
                    this.listIpAddress = resIpAddressObj.data;
                }
            });
            let resObj: any =await this._employeeClockingService.verifyNextStep(this.employeePunch.employeeId, options).toPromise();
            if (resObj.status === ResponseStatus.SUCCESS) {
                this.loadingButtons = false;
                if (resObj.data) {
                    let checkRemote = 'REMOTELY';
                    let tempRemote = [];
                    tempRemote = this.listIpAddress.filter(item => this.checkIsSubnet(item.locationIp));
                    if (tempRemote.length > 0) {
                        checkRemote = 'IN_OFFICE';
                    } else {
                        checkRemote = 'REMOTELY';
                    }
                    if (this.employeePunch.clockAuthorization === 'Request authorization code' && checkRemote === 'REMOTELY') {
                        this.nextStepAfterAuthorization = {
                            disabledStarkWork: resObj.data.startWork,
                            disabledLunchOutTime: resObj.data.lunchOutTime,
                            disabledLunchInTime: resObj.data.lunchInTime,
                            disabledEndWork: resObj.data.lunchInTime,
                        }
                        this.requestCodeCountdownStr = null;
                        this.translateService.use('en');
                        this.authorizationCodePopup = true;
                    } else {
                        this.nextStepAfterAuthorization = {};
                        this.disabledStarkWork = resObj.data.startWork;
                        this.disabledLunchOutTime = resObj.data.lunchOutTime;
                        this.disabledLunchInTime = resObj.data.lunchInTime;
                        this.disabledEndWork = resObj.data.endWork;
                        if (!faceRecognition) {
                            this.idleTimerService.startTimer(15);
                        }
                    }
                    this.isInvalidPinMsg = '';
                    return Promise.resolve(true);
                }
            }
            if (resObj.status === 202) {
                if (resObj.message === 'Not clock on day off') {
                    this.buildErrorMessage('', 'You are not scheduled to work today. Contact supervisor.');
                    this.messageService.clear();
                    this.messageService.add({severity: 'error', summary: 'Error!', detail: 'Sorry you cannot clock on your Day Off, please contact HR/Admin'});
                    setTimeout(() => {
                        this.clearPinNumber();
                      }, 2500);
                } else {
                    this.msgs = [];
                    this.buildErrorMessage(PunchType.CLOCK_IN, resObj.message);
                }
            }
            return Promise.resolve(false);
        } catch (error) {
            if (!navigator.onLine) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: 'Error!', detail: 'Connectivity issue. Please check your internet connection.'});
            }
        }
        return Promise.resolve(false);
    }

    handleBirthDayEmp() {
        const employeeBirthDay = new Date(this.currentEmp.dateOfBirth);
        employeeBirthDay.setMinutes(employeeBirthDay.getMinutes() + employeeBirthDay.getTimezoneOffset());
        const employeeDob = moment(employeeBirthDay).local();
        const today = moment(new Date());
        if (today.date() === employeeDob.date() && today.month() === employeeDob.month()) {
            this.companySettingServiceV2.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId()).subscribe((response: any) => {
                if (response.status === 'SUCCESS' && response.data) {
                    if (response.data.birthdaySongEnable && response.data.birthdaySong) {
                        this.getBirthdaySong(response.data.birthdaySong);
                    }
                }
            });
        }
    }

    getBirthdaySong(id) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.audio.src = resObj.data.fileUrl;
                this.audio.load();
                const promise = this.audio.play();
                if (promise !== undefined) {
                    promise.then(() => {
                    }).catch(error => {
                        this.audio.muted = true;
                        this.audio.play();
                    });
                }
                const timerId = setInterval(() => {
                    this.running();
                }, 1000);
                // clear the interval after 15s
                setTimeout(() => {
                    clearInterval(timerId);
                }, 20000);
            }
        }, err => {
            console.log(err);
        });
    }

    handlingInvalidPin() {
        // capture image & upload to server
        this.employeePunch.pin = '';
        this.showWPDialog = true;
        if (!this.camErrors || this.camErrors.length === 0) {
            this.triggerWPSnapShot.next();
        } else {
            // this.handleWrongPin();
        }
    }


    async submitEmployeePunch(punchStatus: string) {
        await this.validatePin();
        if (!this.isInvalidPin) {
            this.userSignOut.emit('User signout');
            this.employeePunchSign = this.dataURItoBlob(this.signaturePad.toDataURL());
            this.employeePunchSign.lastModifiedDate = new Date();
            const signatureFile = new File([this.employeePunchSign], 'signature.png');
            if (!this.signaturePad.isEmpty()) {
                this.isInvalidSign = false;
                this.clockingService.uploadSignature(signatureFile, this.authService.getCurrentLoggedInId())
                    .subscribe(res => {
                        const resObj: any = res;
                        if (resObj.status === 'SUCCESS') {
                            // this.employeePunch.signature = resObj.data;
                            if (punchStatus === 'IN') {
                                this.employeePunch.isPunchIn = true;
                                this.punchHeader = 'ClockIn';
                            }
                            if (punchStatus === 'OUT') {
                                this.employeePunch.isPunchIn = false;
                                this.punchHeader = 'ClockOut';
                            }
                            this._employeeClockingService.doPunch(this.employeePunch).subscribe(resp => {
                                const resObj2: BaseResponse<EmployeePunchResponse> = resp;
                                if (resObj2.status === ResponseStatus.SUCCESS) {
                                    this.employeePunch = new EmployeePunchRequest();
                                    this.punchInOutData = resObj2.data;
                                    this.employeeData.isMissedPunchIn = this.punchInOutData.isMissedPunchIn;
                                    this.punchInOutData.isMissedPunchIn = this.employeeData.isMissedPunchIn;
                                    this.translateService.use('en');
                                    this.showPIOD = true;
                                    if (resObj2.data.weeklyWorkedHours) {
                                        const weeklyHOurs = resObj2.data.weeklyWorkedHours.replace('m', '').split('h');
                                        const workedHours = parseInt(weeklyHOurs[0], 10);
                                        const workedMinutes = parseInt(weeklyHOurs[1], 10);

                                        this.employeeData.totalHours = this.getFullTime(workedHours) + ':' + this.getFullTime(workedMinutes);
                                        this.punchInOutData.totalHours = this.getFullTime(workedHours) + ':' + this.getFullTime(workedMinutes);
                                        this.totalHoursWeek = (workedHours * 60) + workedMinutes;
                                        this.calculateRemainingHours(workedHours, workedMinutes, this.employeeData.weeklyHours);

                                    } else {
                                        this.employeeData.leftWorkingHours = '00:00';
                                        this.employeeData.totalHours = '00:00';
                                        this.punchInOutData.leftWorkingHours = '00:00';
                                        this.punchInOutData.totalHours = '00:00';
                                    }
                                }
                                setTimeout(() => {
                                    this.showPIOD = false;
                                }, 6000);
                            });
                        }
                    });
            } else {
                this.translateService.use('en');
                this.isInvalidSign = true;
            }
        } else {

            if (this.signaturePad && this.signaturePad.isEmpty()) {
                this.translateService.use('en');
                this.isInvalidSign = true;
            }
        }
    }

    submitEmployeePunchForAccount(punchType: PunchType, punchDirection: PunchDirection) {
        try {
            this.submitEmployeePunchForAccountNew(punchType, punchDirection);
        } catch {
            setTimeout(() => {
                if (this.faceRecognition && this.pauseDetectFace) {
                    this.pauseDetectFace = false;
                    this.faceRecognition.resumeVideo();
                }
            }, 2000);
            if (!navigator.onLine) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail:  this.translatePipe.transform('There is a problem communicating with back-end services, please try again later. If this error persist, please contact support.')});
            }
        }
    }

    submitEmployeePunchForAccountNew(punchType: PunchType, punchDirection: PunchDirection) {
        this.errorMessage = null;
        this.errorTitle = null;
        this.successMessage = null;
        // pause this video first when have another clocking
        if (this.audio != null) {
            this.audio.pause();
        }
        if (this.ipAddress == null || this.ipAddress === undefined) {
            this.getLocalIpAddress();
        }
        if (!this.isInvalidPin) {
            this.clockingTracking.employeeId = this.employeePunch.employeeId;
            this.clockingTracking.date = new Date();
            this.clockingTracking.pin = this.employeePunch.pin;
            this.clockingTracking.clockAction = punchType;
            this.employeePunch.isPunchIn = (punchDirection === PunchDirection.IN);
            this.employeePunch.punchType = punchType;
            this.employeePunch.ipAddress = this.ipAddress;
            let tempRemote = [];
            this.employeePunch.location = ClockLocation.REMOTELY; // set default value
            this.employeePunch.locationName = ClockLocationName.NA;
            tempRemote = this.listIpAddress.filter(item => this.checkIsSubnet(item.locationIp));
            if (this.listIpAddress.length > 0) {
                this.employeePunch.location = ClockLocation.REMOTELY;
                this.employeePunch.locationName = ClockLocationName.REMOTELY;
            }
            if (tempRemote.length > 0) {
                this.employeePunch.location = ClockLocation.IN_OFFICE;
                this.employeePunch.locationName = tempRemote[0].locationName !== '' ? tempRemote[0].locationName : ClockLocationName.IN_OFFICE;
            }

            this.employeePunch.clockInPhoto = null;
            this.employeePunch.lunchInPhoto = null;
            this.employeePunch.lunchOutPhoto = null;
            this.employeePunch.lunchOutPhoto = null;
            if (punchType === PunchType.CLOCK_IN) {
                if (this.punchInOutOption !== '' && this.punchInOutOption !== null) {
                    this.employeePunch.clockInReason = this.punchInOutOption + ' - ' + this.punchInOutNote;
                }
                this.employeePunch.clockInPhoto = this.photoBase64String;
            }

            if (punchType === PunchType.LUNCH_IN) {
                if (this.punchInOutOption !== '' && this.punchInOutOption !== null) {
                    this.employeePunch.lunchInReason = this.punchInOutOption + ' - ' + this.punchInOutNote;
                }
                this.employeePunch.lunchInPhoto = this.photoBase64String;
            }

            if (punchType === PunchType.LUNCH_OUT) {
                if (this.punchInOutOption !== '' && this.punchInOutOption !== null) {
                    this.employeePunch.lunchOutReason = this.punchInOutOption + ' - ' + this.punchInOutNote;
                }
                this.employeePunch.lunchOutPhoto = this.photoBase64String;
            }

            if (punchType === PunchType.CLOCK_OUT) {
                if (this.punchInOutOption !== '' && this.punchInOutOption !== null) {
                    this.employeePunch.clockOutReason = this.punchInOutOption + ' - ' + this.punchInOutNote;
                }
                this.employeePunch.clockOutPhoto = this.photoBase64String;
            }
            this.clockingTracking.locationName = this.employeePunch.locationName;
            this.clockingTracking.accountUsed = this.authService.getCurrentUsername();
            this.clockingTracking.createdByUsr = this.authService.getCurrentUsername();
            this.clockingTracking.lastModifiedBy = this.authService.getCurrentUsername();
            this.employeePunch.device = 'WEB_APP';
            this.employeePunch.isSkipCheckPlanAssignment = this.isSkipCheckPlanAssignment;
            this.employeePunch.isContractor = this.authService.isContractorRole();
            this.employeePunch.faceDetection = this.isFaceDetection;
            this._employeeClockingService.doPunch(this.employeePunch).subscribe(resp => {
                const resObj2: BaseResponse<EmployeePunchResponse> = resp;
                this.isSkipCheckPlanAssignment = false;
                if (resObj2.status === 202) {
                    this.msgs = [];
                    if (resObj2.message === 'NEED_PLAN_ASSIGNMENT') {
                        this.clockingTracking.status = 'Error';
                        this.translateService.use('en');
                        this.showPlanAssignmentDialog = true;
                    } else {
                        this.clockingTracking.status = 'Error';
                        this.punchTracking();
                        
                        if (resObj2.message && resObj2.message.includes('have a Missed Clock On') 
                            || resObj2.message.includes('Suspended for recurrent lateness. See supervisor.') 
                            || resObj2.message.includes('Clock will be available in one hour') || resObj2.message.includes('please add a job report as none was found for you')) {
                            if (resObj2.message.includes('have a Missed Clock On')) {
                                this.errorTitle = 'Missed Clock';
                            } else if (resObj2.message.includes('please add a job report as none was found for you')) {
                                this.errorTitle = 'Missed Job Report';
                            } else {
                                this.errorTitle = 'Lateness Decision';
                            }
                            this.translateService.use('en');
                            this.errorMessage = resObj2.message;
                            if (this.isPunchInOut) {
                                this.buildErrorMessage(punchType, this.errorMessage);
                            } else {
                                this.errorPopup = true;
                            }
                        } else {
                            // if (this.isPunchInOut) {
                            //     this.buildErrorMessage(punchType, resObj2.message);
                            // } else {
                            //     this.messageService.add({severity: 'error', summary: 'Error!', detail: resObj2.message});
                            // }
                            this.buildErrorMessage(punchType, resObj2.message);
                        }
                    }
                    setTimeout(() => {
                        if (this.faceRecognition && this.pauseDetectFace) {
                            this.pauseDetectFace = false;
                            this.faceRecognition.resumeVideo();
                        }
                    }, 2000);
                } else {
                    this.clockingTracking.status = 'Success';
                    if (this.employeePunch === undefined) {
                        this.employeePunch = new EmployeePunchRequest();
                    }
                    this.employeeData.lastPunchDate = resObj2.data.lastPunchDate;
                    this.punchInOutData = resObj2.data;
                    this.punchHeader = resObj2.data.isClockIn ? this.translatePipe.transform('Punched-In') + ' ' + `
                    ${moment().format('h:mm a')}` : this.translatePipe.transform('Punched Out') + `${moment().format('h:mm a')}`;
                    // only display message when clicking start work
                    if (punchType === PunchType.CLOCK_IN && punchDirection === PunchDirection.IN) {
                        this.handleBirthDayEmp();
                        if (resObj2.data.isMissedPreviousDayPunchOut === true) {
                            this.employeeData.isMissedPunchIn = false;
                            this.punchInOutData.isMissedPunchIn = false;
                            //  this.msgs.push({severity: 'warn', summary: 'You Missed a Clock', detail: 'Last Clock ' +
                            //  moment(resObj2.data.lastPunchDate).format('hh:mm a')});
                        }
                        this.employeeData.isMissedPunchIn = this.punchInOutData.isMissedPunchIn;
                    }

                    this.employeeData.leftWorkingHours = resObj2.data.remainWorkingHours ? resObj2.data.remainWorkingHours : '0:00';
                    this.punchInOutData.leftWorkingHours = resObj2.data.remainWorkingHours ? resObj2.data.remainWorkingHours : '0:00';
                    if (resObj2.data.weeklyWorkedHours) {
                        const weeklyHOurs = resObj2.data.weeklyWorkedHours.replace('m', '').split('h');
                        const workedHours = parseInt(weeklyHOurs[0], 10);
                        const workedMinutes = parseInt(weeklyHOurs[1], 10);
                        this.employeeData.totalHours = this.getFullTime(workedHours) + ':' + this.getFullTime(workedMinutes);
                        this.punchInOutData.totalHours = this.getFullTime(workedHours) + ':' + this.getFullTime(workedMinutes);
                        this.totalHoursWeek = (workedHours * 60) + workedMinutes;
                    } else {
                        this.employeeData.totalHours = '0:00';
                        this.punchInOutData.totalHours = '0:00';
                    }
                    this.punchTracking(this.punchInOutData.id, this.punchInOutData.lunchHistoryId);
                    if (this.isPunchInOut) {
                        this.buildSuccessMessage(punchType);
                        this.disabledPinButton = false;
                        this.disabledEnterButton = false;
                        this.clearPinNumber(5000);
                        // if (this.employeePunch && this.employeePunch.pin && this.employeePunch.pin.toString().length === 4) {
                        //     this.disabledPinButton = true;
                        //     this.validatePin();
                        // }
                        if (this.isManualInput && this.companySetting.pinFacialRecognition == 'AUTO_TAKE_PICTURE') {
                            this.disabledTakePhoto = false;
                            this.faceRecognition.onTakePhoto();
                        }
                        setTimeout(() => {
                            this.disabledTakePhoto = this.companySetting.pinFacialRecognition === 'AUTO_TAKE_PICTURE';
                            this.successMessage = null;
                            this.faceRecognition.resumeVideo();
                        }, 5000);
                    } else {
                        if (this.isManualInput && this.companySetting.pinFacialRecognition == 'AUTO_TAKE_PICTURE') {
                            this.disabledTakePhoto = false;
                            this.faceRecognition.onTakePhoto();
                        }
                        this.translateService.use('en');
                        this.showPIOD = true;
                        setTimeout(() => {
                            if (this.showPIOD) {
                                this.showPIOD = false;
                                this.disabledPinButton = false;
                                this.disabledEnterButton = false;
                                this.clearPinNumber(5000);
                                // if (this.employeePunch && this.employeePunch.pin && this.employeePunch.pin.toString().length === 4) {
                                //     this.disabledPinButton = true;
                                //     this.validatePin();
                                // }
                            } else {
                                if (this.faceRecognition && this.faceRecognitionMode) {
                                    setTimeout(() => {
                                        this.disabledTakePhoto = this.companySetting.pinFacialRecognition === 'AUTO_TAKE_PICTURE';
                                        this.personName = '';
                                        this.pauseDetectFace = false;
                                        this.faceRecognition.resumeVideo();
                                    }, 5000);
                                }
                            }
    
                        }, 5000);
                    }

                }
                this.punchActions(punchType);
            }, () => {
                if (this.isPunchInOut) {
                    this.buildErrorMessage(punchType);
                } else {
                    this.buildErrorMessage(punchType, this.translatePipe.transform('Failed to Punch In'));
                    // this.messageService.add({severity: 'error', summary: 'Failed', detail: this.translatePipe.transform('Failed to Punch In')});
                }
            });
        } else {
            // if (this.isPunchInOut) {
            //     this.buildErrorMessage(punchType, this.translatePipe.transform('Please enter correct pin'));
            // } else {
            //     this.messageService.add({severity: 'info', summary: 'Invalid Pin', detail: this.translatePipe.transform('Please enter correct pin')});
            // }
            this.buildErrorMessage(punchType, this.translatePipe.transform('Please enter correct pin'));
            setTimeout(() => {
                if (this.faceRecognition && this.pauseDetectFace) {
                    this.pauseDetectFace = false;
                    this.faceRecognition.resumeVideo();
                }
            }, 2000);
        }
    }

    buildErrorMessage(punchType, errorMessage?, isFace?) {
        const typeError = isFace ? 'Face Recognization Failed' : 'PIN Failed';

        if (punchType === PunchType.CLOCK_IN || punchType === PunchType.LUNCH_IN || punchType === PunchType.LUNCH_OUT || punchType === PunchType.CLOCK_OUT) {
            this.errorMessage = typeError;
        }
        // if (punchType === PunchType.LUNCH_IN) {
        //     this.errorMessage = 'Back from Lunch with ' + typeError;
        // }
        //
        // if (punchType === PunchType.LUNCH_OUT) {
        //     this.errorMessage = 'Break Out with ' + typeError;
        // }
        //
        // if (punchType === PunchType.CLOCK_OUT) {
        //     this.errorMessage = 'Ended Work with ' + typeError;
        // }
        if (errorMessage) {
            this.errorMessage = errorMessage;
        }
        setTimeout(() => {
            this.errorMessage = null;
        }, 5000);
    }

    buildSuccessMessage(punchType) {
        if (punchType === PunchType.CLOCK_IN) {
            this.successMessage = 'Work started successfully';
        }

        if (punchType === PunchType.LUNCH_IN) {
            this.successMessage = 'Your break has been ended';
        }

        if (punchType === PunchType.LUNCH_OUT) {
            this.successMessage = 'You are now on break';
        }

        if (punchType === PunchType.CLOCK_OUT) {
            this.successMessage = 'You ended your work day. Bye!';
        }
    }

    punchTracking(clockingId?, lunchHistoryId?) {
        if (clockingId) {
            this.clockingTracking.clockingId = clockingId;
            if (this.isFaceDetection) {
                this.clockingTracking.faceDetection = this.isFaceDetection;
                this.clockingTracking.faceDetectionImage = this.faceRecognitionBase64;
            } else {
                if (this.photoBase64String) {
                    this.clockingTracking.faceImage = this.photoBase64String;
                    if (this.companySetting.punchValidation === 'NO_VERIFICATION') {
                        this.clockingTracking.faceImageStatus = 'Accepted';
                    } else {
                        this.clockingTracking.faceImageStatus = 'Pending';
                    }
                    this.clockingTracking.faceImage = this.photoBase64String;
                }
            }
        } else {
            this.clockingTracking.clockingId = null;
            this.clockingTracking.faceDetection = null;
            this.clockingTracking.faceDetectionImage = null;
            this.clockingTracking.faceImageStatus = null;
            this.clockingTracking.faceImage = null;
        }
        const payload = Object.assign({}, this.clockingTracking);
        payload.lunchHistoryId = lunchHistoryId;
        this._employeeClockingTrackingService.createClockingTracking(payload).subscribe(resp => {
            
        }, err => {
            if (!this.isPunchInOut) {
                this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('Failed to Punch In'));
                // this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Failed'), detail:  this.translatePipe.transform('Failed to Punch In')});
            }
        });
    }

    changeImageProfile() {
        if (this.authService.getCurrentCompanyId()) {
            this.companySettingServiceV2.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId())
            .subscribe((response: any) => {
                this.companySetting = response.data;
                this.disabledTakePhoto = this.companySetting.pinFacialRecognition === 'AUTO_TAKE_PICTURE';
                this.documentService.getUploadedFile(response.data.logoId).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.companyLogo = resObj.data.fileUrl ? resObj.data.fileUrl : 'assets/punch-images/operr.png';
                    } else {
                        this.companyLogo = 'assets/punch-images/operr.png';
                    }
                }, () => this.companyLogo = 'assets/punch-images/operr.png');
            }, () => this.companyLogo = 'assets/punch-images/operr.png');
            this.companyServiceoute.getCompanyByCompanyId(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
                this.companyName = res.data.name;
            });
        }
        switch (this.role) {
            case 1:
                this.getPlatformAdmin();
                break;
            case 3:
            case 5:
                this.getAgencyAdmin();
                break;
            case 4:
            // case 6:
            //     this.router.navigate(['app/admin/company-admin/view/' + userId]);
            //     break;
            case 7:
                this.getEmployee();
                break;
            default:
                break;
        }
    }

    getPlatformAdmin() {
        this.platformAdminService.get(this.currentLoggedInUserId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.profilePhoto) {
                this.getProfileImage(resObj.data.profilePhoto);
            }
        });
    }

    getAgencyAdmin() {
        this.agencyAdminService.getDetail(this.currentLoggedInUserId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.profilePhoto) {
                this.getProfileImage(resObj.data.profilePhoto);
            }
        });
    }

    getEmployee() {
        // this.employeeService.get(this.employeeId).subscribe(res => {
        this.employeeService.getEmployeeByIdCustom(this.currentLoggedInUserId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data.profileImageId) {
                this.getProfileImage(resObj.data.profileImageId);
            }
        });
    }

    getProfileImage(id) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data) {
                this.photoURL = resObj.data.fileUrl;
            }
        }, error => {
        });
    }

    updateUrl(event) {
        this.photoURL = 'assets/images/logo.png';
    }

    calculateRemainingHours(workedHours, workedMinutes, weeklyHours) {
        if (weeklyHours > 0 && weeklyHours != null) {
            const WeeklyWorkingTimeLimit = weeklyHours.toString();
            let weeklyWorkingTimeLimitHours;
            let weeklyWorkingTimeLimitMinutes;
            if (WeeklyWorkingTimeLimit.length === 4) {
                weeklyWorkingTimeLimitHours = parseInt(WeeklyWorkingTimeLimit.substr(0, 2), 10);
                weeklyWorkingTimeLimitMinutes = parseInt(WeeklyWorkingTimeLimit.substr(WeeklyWorkingTimeLimit.length - 2), 10);
            } else if (WeeklyWorkingTimeLimit.length === 3) {
                weeklyWorkingTimeLimitHours = parseInt(WeeklyWorkingTimeLimit.substr(0, 1), 10);
                weeklyWorkingTimeLimitMinutes = parseInt(WeeklyWorkingTimeLimit.substr(WeeklyWorkingTimeLimit.length - 2), 10);
            } else if (WeeklyWorkingTimeLimit.length === 2) {
                weeklyWorkingTimeLimitHours = 0;
                weeklyWorkingTimeLimitMinutes = parseInt(WeeklyWorkingTimeLimit.substr(WeeklyWorkingTimeLimit.length - 2), 10);
            } else if (WeeklyWorkingTimeLimit.length === 1) {
                weeklyWorkingTimeLimitHours = 0;
                weeklyWorkingTimeLimitMinutes = parseInt(WeeklyWorkingTimeLimit.substr(WeeklyWorkingTimeLimit.length - 1), 10);
            }
            const workedHoursConvertInMinutes = workedHours * 60;
            const weeklyWorkingTimeLimitHoursConvertInMinutes = weeklyWorkingTimeLimitHours * 60;
            const totalWorkingLimitInMinute = weeklyWorkingTimeLimitHoursConvertInMinutes + weeklyWorkingTimeLimitMinutes;
            const totalWorkInMinute = workedHoursConvertInMinutes + workedMinutes;
            if (totalWorkingLimitInMinute >= totalWorkInMinute) {
                const totalDiffInMinutes = totalWorkingLimitInMinute - totalWorkInMinute;
                if (totalDiffInMinutes > 0) {
                    const minutesToShow: any = totalDiffInMinutes % 60;
                    const hoursToShow: any = Math.floor(totalDiffInMinutes / 60);
                    let hoursToShowFinal;
                    let minutesToShowFinal;
                    if (hoursToShow.toString().length < 2) {
                        hoursToShowFinal = '0' + hoursToShow.toString();
                    } else {
                        hoursToShowFinal = hoursToShow;
                    }
                    if (minutesToShow.toString().length < 2) {
                        minutesToShowFinal = '0' + minutesToShow.toString();
                    } else {
                        minutesToShowFinal = minutesToShow;
                    }
                    this.employeeData.leftWorkingHours = hoursToShowFinal + ':' + minutesToShowFinal;
                    this.punchInOutData.leftWorkingHours = hoursToShowFinal + ':' + minutesToShowFinal;
                } else {
                    this.employeeData.leftWorkingHours = '00:00';
                    this.punchInOutData.leftWorkingHours = '00:00';
                }

            } else {
                this.employeeData.leftWorkingHours = '00:00';
                this.punchInOutData.leftWorkingHours = '00:00';
            }
        } else {
            this.employeeData.leftWorkingHours = '00:00';
            this.punchInOutData.leftWorkingHours = '00:00';
        }
    }


    drawStart() {
    }

    drawComplete() {
    }

    togglePin() {
        if (this.togglePinType) {
            this.pinInputType = 'password';
            this.togglePinType = false;
        } else {
            this.pinInputType = 'text';
            this.togglePinType = true;
        }
    }

    private dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
            type: mimeString
        });
    }


    get triggerWPSnapShotObservable(): Observable<void> {
        return this.triggerWPSnapShot.asObservable();
    }

    get triggerSnapShotObservable(): Observable<void> {
        return this.triggerSnapShot.asObservable();
    }


    validateEmployeeFace() {
        this.showFRDialog = true;
        const pinc = this;
    }

    private takeSnapShot() {
        this.triggerSnapShot.next();
    }

    cancelFaceDetection() {
        this.showFRDialog = false;
    }

    // ---
    loadPunchHistoryLazy(event?: LazyLoadEvent) {
        const page = (event.first) / event.rows;
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        // this.historyFilter.page = page;
        // this.historyFilter.size = event.rows;
        if (!this.authService.isEmployeeRole()) {
            this.employeeService.getByUsername({usernames: this.authService.getCurrentUsername()}).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    if (resObj.data) {
                        this.employee.id = resObj.data.id;
                        this.loadPage({page: this.page, size: this.size});
                    } else {
                        this.showWorkHours = false;
                    }
                }
            });
        } else {
            this.loadPage({page: this.page, size: this.size});
        }
    }

    loadPage(options) {
        this.loading = true;
        options.employeeIds = [];
        options.employeeIds.push(this.authService.isEmployeeRole() ? this.authService.getCurrentLoggedInId() : this.employee.id);

        options.page = 0;
        options.size = 100;
        options.fromDate = MOMENT().startOf('week');
        options.requestFromPunchInOutPage = true;
        if (this.authService.getUserInfo().agency) {
            options.agencyId = this.authService.getUserInfo().agency.id;
        }
        this.payrollSettingService.getbyId(this.employee.companyId).subscribe(res1 => {
            const res1Obj: any = res1;
            this.payrollSetting = res1Obj.data;
            this.limitLateness = this.payrollSetting?.employeeLateLimit;
            this.employeeLatenessDecision = this.payrollSetting?.employeeLatenessDecision;
            this.calculatePayrollDate();
            options.fromDate = this.payrollDate;
            this.payload = options;
            this.clockingService.punchHistoryFilter(options).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.punchHistoryList = resObj.data.content;
                    if (this.punchHistoryList) {
                        for (const obj of this.punchHistoryList) {
                            console.log('punchHistoryFilter obj: ', obj);
                            const inElement: PunchHistory = new PunchHistory();
                            const outElement: PunchHistory = new PunchHistory();
                            inElement.type = 'In';
                            outElement.type = 'Out';
                            if (this.isExceptionPunchInOut(obj.locationName)) {
                                inElement.type = obj.locationName;
                                inElement.day = MOMENT(obj.clockInTime).format('dddd');
                                this.punchHistoryListUpdated.push(inElement);
                            }
                            if (obj.clockInTime && !this.isExceptionPunchInOut(obj.locationName)) {
                                inElement.date = MOMENT(obj.clockInTime).format('hh:mma');
                                inElement.fullDate = obj.punchDateStr + ' ' + MOMENT(obj.clockInTime).format('hh:mm a');
                                inElement.day = MOMENT(obj.clockInTime).format('dddd');
                                this.punchHistoryListUpdated.push(inElement);
                            }

                            let totalLunchTime = 0;
                            if (obj.employeeClockingLunchHourHistory && obj.employeeClockingLunchHourHistory.length > 0 && !this.isExceptionPunchInOut(obj.locationName)) {
                                for (const lunchInOut of obj.employeeClockingLunchHourHistory) {
                                    const lunchOutElement: PunchHistory = new PunchHistory();
                                    const lunchInElement: PunchHistory = new PunchHistory();
                                    lunchOutElement.type = 'Break Out';
                                    lunchInElement.type = 'Break Back';

                                    if (lunchInOut.lunchOutTime) {
                                        lunchOutElement.date = MOMENT(lunchInOut.lunchOutTime).format('hh:mma');
                                        lunchOutElement.fullDate = obj.punchDateStr + ' ' + MOMENT(lunchInOut.lunchOutTime).format('hh:mm a');
                                        lunchOutElement.day = MOMENT(lunchInOut.lunchOutTime).format('dddd');
                                        this.punchHistoryListUpdated.push(lunchOutElement);
                                    }
                                    if (lunchInOut.lunchInTime) {
                                        lunchInElement.date = MOMENT(lunchInOut.lunchInTime).format('hh:mma');
                                        lunchInElement.fullDate = obj.punchDateStr + ' ' + MOMENT(lunchInOut.lunchInTime).format('hh:mm a');
                                        lunchInElement.day = MOMENT(lunchInOut.lunchInTime).format('dddd');
                                        lunchInElement.labelType = this.convertDifference(lunchInOut.lunchInTime - lunchInOut.lunchOutTime);
                                        totalLunchTime = totalLunchTime + (lunchInOut.lunchInTime - lunchInOut.lunchOutTime);
                                        this.punchHistoryListUpdated.push(lunchInElement);
                                    }
                                }
                            }

                            if (obj.clockOutTime && !this.isExceptionPunchInOut(obj.locationName)) {
                                outElement.date = MOMENT(obj.clockOutTime).format('hh:mma');
                                outElement.fullDate = obj.punchDateStr + ' ' + MOMENT(obj.clockOutTime).format('hh:mm a');
                                outElement.labelType = outElement.type + ' (' + this.convertDifference(obj.clockOutTime - obj.clockInTime - totalLunchTime) + ')';
                                outElement.day = MOMENT(obj.clockOutTime).format('dddd');
                                this.punchHistoryListUpdated.push(outElement);
                            }
                            this.totalHours();
                        }

                        console.log('punchHistoryListUpdated: ', this.punchHistoryListUpdated)
                    }
                    this.loading = false;
                }
            });
        });
    }

    convertDifference(difference) {
        const milliseconds = difference;
        let hours = Math.floor(milliseconds / 3600000);
        const remainingMilliseconds = milliseconds % 3600000;
        let minutes = Math.floor(remainingMilliseconds / 60000);
        let hourStr = hours < 10 ? '0' + hours : '' + hours;
        let minutesStr = minutes < 10 ? '0' + minutes : '' + minutes;
        if (hours > 0) {
            return hourStr + 'h ' + minutesStr + 'm' ;
        } else {
            return minutesStr + ' mins';
        }
    }


    isExceptionPunchInOut(location: any) {
        return location === 'Holiday' || location === 'OFF' || location === 'VACATION' || location === 'SICK' || location === 'OTHER';
    }

    calculatePayrollDate() {
        // calculate payroll date
        const today = new Date();
        const dayOfWeek = today.getDay() === 0 ? 7 : today.getDay();
        const dayending = this.utilsService.convertWeekDaysToNumber(this.payrollSetting.payrollWeekEnding);
        const last = today.getDate() - dayOfWeek + dayending + 1;
        const temp = new Date(today.setDate(last));
        temp.setHours(0, 0, 0, 0);
        const tempToday = new Date();
        tempToday.setHours(0, 0, 0, 0);
        if (temp > tempToday) {
            this.payrollDate = new Date(today.setDate(last - 7));
        } else {
            this.payrollDate = temp;
        }
    }

    updatePinNumber(number: number) {
        this.onUserInteraction();
        if (this.employeePunch.pin) {
            this.employeePunch.pin = this.employeePunch.pin.concat(number.toString());
        } else {
            this.employeePunch.pin = number.toString();
        }
        if (this.employeePunch.pin.toString().length === 4) {
            this.disabledPinButton = true;
            this.validatePin(true);
        }
    }

    reDoLastNumber() {
        if (this.employeePunch.pin && this.employeePunch.pin.length > 0) {
            this.employeePunch.pin = this.employeePunch.pin.slice(0, -1);
        }
        if (this.employeePunch.pin.toString().length < 4) {
            // this.disabledSignOutButton = false;
            // this.disabledSignInButton = false;
            this.disabledPinButton = false;
            this.disabledEnterButton = false;
            this.disabledPunchIn();
            this.isInvalidPin = false;
        }
    }

    checkForButtonDisable() {
        if (this.employeePunch.pin.toString().length === 4) {
            this.validatePin();
            this.disabledPinButton = true;
        } else if (this.employeePunch.pin.toString().length < 4) {
            this.disabledPinButton = false;
            // this.disabledSignOutButton = false;
            // this.disabledSignInButton = false;
            this.disabledEnterButton = false;
            this.disabledPunchIn();
            this.isInvalidPin = false;
        }
    }

    getFullTime(unit) {
        return unit > 9 ? '' + unit : '0' + unit;
    }

    closedDialog() {
        this.showPIOD = false;
        this.disabledPinButton = false;
        // this.disabledSignOutButton = false;
        // this.disabledSignInButton = false;
        this.disabledEnterButton = false;
        this.disabledPunchIn();
        this.clearPinNumber();
    }

    clearPinNumber(delayFaceRecognition?: number) {
        this.photoBase64String = null;
        this.employeePunch.pin = '';
        this.loadingButtons = false;
        this.disabledPinButton = false;
        // this.disabledSignOutButton = false;
        // this.disabledSignInButton = false;
        this.disabledEnterButton = false;
        this.disabledPunchIn();
        this.isInvalidPin = false;
        if (delayFaceRecognition >= 0) {
            setTimeout(() => {
                this.disabledTakePhoto = this.companySetting.pinFacialRecognition === 'AUTO_TAKE_PICTURE';
                this.personName = '';
                this.pauseDetectFace = false;
                this.faceRecognition.resumeVideo();
            }, delayFaceRecognition);
        } else {
            this.disabledTakePhoto = this.companySetting.pinFacialRecognition === 'AUTO_TAKE_PICTURE';
        }
    }


    closedLunchTimeDialog() {
        this.showLunchDurationDialog = false;
        this.disabledEnterButton = false;
        this.disabledPunchIn();
        this.clearPinNumber();
    }

    doPunchInOut(type: number) {

    }

    checkStartWork() {
        let workDay = this.currentEmp.workHours.filter(i => i.dayOfWeek == new Date().getDay());
        if (workDay?.[0]?.startTime) {
            let startTime = new Date(workDay?.[0]?.startTime);
            let currentTime = new Date();
            let timeDiff = (startTime.getHours() * 60 + startTime.getMinutes()) - (currentTime.getHours() * 60 + currentTime.getMinutes());
            if (timeDiff > 10) {
                this.punchType = 1;

                if (this.payrollSetting.allowMonitorComeLateOrLeaveEarly) {
                    this.translateService.use('en');
                    this.showPopupSelect = true;
                } else {
                    this.startWork();
                }
                
            } else {
                this.startWork();
            }
        } else {
            this.startWork();
        }
    }

    checkEndWork() {
        let workDay = this.currentEmp.workHours.filter(i => i.dayOfWeek == new Date().getDay());
        if (workDay?.[0]?.endTime) {
            let endTime = new Date(workDay?.[0]?.endTime);
            let currentTime = new Date();
            let timeDiff = (currentTime.getHours() * 60 + currentTime.getMinutes()) - (endTime.getHours() * 60 + endTime.getMinutes());
            if (timeDiff > 10) {
                this.punchType = 4;
                if (this.payrollSetting.allowMonitorComeLateOrLeaveEarly) {
                    this.translateService.use('en');
                    this.showPopupSelect = true;
                } else {
                    this.endWork();
                }
            } else {
                this.endWork();
            }
        } else {
            this.endWork();
        }
    }

    async startWork() {
        this.onUserInteraction();
        this.disabledPunchIn();
        try {
            await this.loadTimeOffRequestDataSource();
        } catch (e) {
            if (!navigator.onLine) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Trouble connecting to severs. Please check your internet connection and try again. If this error persists, contact support.')});
            }
        }
        if (this.absenceList && this.absenceList.length) {
            let vacation = this.absenceList.filter(item => item.type == 'vacation' && item.employeeId == this.employeePunch.employeeId && item.timeStart <= new Date().getTime() &&  item.timeEnd >= new Date().getTime());
            if (vacation && vacation.length > 0) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Employee is on vacation')});
                return;
            }
        }
        this.punchType = 1;
        const tempData = JSON.parse(localStorage.getItem('_globals'));
        if (tempData && tempData.level !== 8 && localStorage.getItem('_user') === 'admin') {
            this.translateService.use('en');
            this.showAddNoteDialog = true;
        } else {
            this.handleSubmitEmployeePunchForAccount(PunchType.CLOCK_IN, PunchDirection.IN);
        }
        this.clearSignature();
        this.loadPunchHistoryLazy();
    }

    async outToLunch() {
        this.onUserInteraction();
        this.disabledPunchIn();
        try {
            await this.loadTimeOffRequestDataSource();
        } catch (e) {
            if (!navigator.onLine) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Trouble connecting to severs. Please check your internet connection and try again. If this error persists, contact support.')});
            }
        }
        if (this.absenceList && this.absenceList.length) {
            let vacation = this.absenceList.filter(item => item.type == 'vacation' && item.employeeId == this.employeePunch.employeeId && item.timeStart <= new Date().getTime() &&  item.timeEnd >= new Date().getTime());
            if (vacation && vacation.length > 0) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Employee is on vacation')});
                return;
            }
        }
        this.punchType = 2;
        const tempData = JSON.parse(localStorage.getItem('_globals'));
        if (tempData && tempData.level !== 8 && localStorage.getItem('_user') === 'admin') {
            this.translateService.use('en');
            this.showAddNoteDialog = true;
        } else {
            this.handleSubmitEmployeePunchForAccount(PunchType.LUNCH_OUT, PunchDirection.OUT);
        }
        this.clearSignature();
        this.loadPunchHistoryLazy();
    }

    submitLunchTimeDuration() {
        this.showLunchDurationDialog = false;
        this.punchType = 3;
        const tempData = JSON.parse(localStorage.getItem('_globals'));
        if (tempData && tempData.level !== 8 && localStorage.getItem('_user') === 'admin') {
            this.translateService.use('en');
            this.showAddNoteDialog = true;
        } else {
            this.handleSubmitEmployeePunchForAccount(PunchType.LUNCH_IN, PunchDirection.IN);
        }
    }

    async backFromLunch() {
        this.onUserInteraction();
        this.disabledPunchIn();
        try {
            await this.loadTimeOffRequestDataSource();
        } catch (e) {
            if (!navigator.onLine) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Trouble connecting to severs. Please check your internet connection and try again. If this error persists, contact support.')});
            }
        }
        if (this.absenceList && this.absenceList.length) {
            let vacation = this.absenceList.filter(item => item.type == 'vacation' && item.employeeId == this.employeePunch.employeeId && item.timeStart <= new Date().getTime() &&  item.timeEnd >= new Date().getTime());
            if (vacation && vacation.length > 0) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error!'), detail: this.translatePipe.transform('Employee is in vacation')});
                return;
            }
        }
        this._employeeClockingService.getLunchTime(this.employeePunch.employeeId).subscribe(resObj => {
            if (resObj.status === 'SUCCESS') {
                this.translateService.use('en');
                this.showLunchDurationDialog = !resObj.data.isOverLunchTimeDuration;
                this.lunchTimeLeft = this.hhmm(resObj.data.leftTime);
                if (!this.showLunchDurationDialog) {
                    this.submitLunchTimeDuration();
                }
                this.monitoringDetailsService.monitorAction(
                    `Back From Lunch`,
                    this.timeSpent,
                    {
                        back_from_lunch_by: this.authService.getCurrentLoggedInName()
                    },
                    'complete',
                    `Back From Lunch`,
                    0
                );
            } else {
                this.messageService.clear();
                this.messageService.add({
                    severity: 'error',
                    summary: this.translatePipe.transform('error'),
                    detail: this.translatePipe.transform('CannotGetLunchTime')
                });
            }
        });
        this.clearSignature();
        this.loadPunchHistoryLazy();
    }

    hhmm(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        return _.padStart(hours, 1, '0') + 'h ' + _.padStart(minute, 2, '0') + 'm';
    }

    async endWork() {
        this.onUserInteraction();
        this.disabledPunchIn();
        try {
            await this.loadTimeOffRequestDataSource();
        } catch (e) {
            if (!navigator.onLine) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Trouble connecting to severs. Please check your internet connection and try again. If this error persists, contact support.')});
            }
        }
        if (this.absenceList && this.absenceList.length) {
            let vacation = this.absenceList.filter(item => item.type == 'vacation' && item.employeeId == this.employeePunch.employeeId && item.timeStart <= new Date().getTime() &&  item.timeEnd >= new Date().getTime());
            if (vacation && vacation.length > 0) {
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error!'), detail: this.translatePipe.transform('Employee is in vacation')});
                return;
            }
        }
        this.punchType = 4;
        const tempData = JSON.parse(localStorage.getItem('_globals'));
        if (tempData && tempData.level !== 8 && localStorage.getItem('_user') === 'admin') {
            this.translateService.use('en');
            this.showAddNoteDialog = true;
        } else {
            this.handleSubmitEmployeePunchForAccount(PunchType.CLOCK_OUT, PunchDirection.OUT);
        }
        this.clearSignature();
        this.loadPunchHistoryLazy();
    }
    onMessageClose(event: any) {
        this.msgs = [];
    }

    running() {
        // pause the song after 15s
        if (this.audio.currentTime >= 15) {
            this.audio.pause();
        }
    }

    /**
     * Validate Pin with 2 mode: online + offline
     */
    handleValidatePin() {
        this.validatePin();
 
    }

    /**
     * Handle Submit EmployeePunch For Account : online + offline
     * @param punchType
     * @param punchDirection
     */
    handleSubmitEmployeePunchForAccount(punchType: PunchType, punchDirection: PunchDirection) {
        this.showAddNoteDialog = false;
        this.punchInOutOption = '';
        this.punchInOutNote = '';
        this.submitEmployeePunchForAccount(punchType, punchDirection);
   
    }

    /**
     * Handle Submit EmployeePunch For Account offline
     * @param punchType
     * @param punchDirection
     */
   

    /**
     * check the backend server is down or up
     */
    onlineCheck() {
        const xhr = new XMLHttpRequest();
        return new Promise((resolve, reject) => {
            xhr.onload = () => {
                resolve(true);
            };
            xhr.onerror = () => {
                reject(false);
            };
            xhr.open('GET', environment.server_backend, true);
            xhr.send();
        });
    }

    /**
     * Auto generate the string 8 characters
     */
    generateSubString() {
        // tslint:disable-next-line:prefer-const
        let length = 8,
            charset = '0123456789',
            retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    checkIsSubnet(ip) {
        return this.inSubNet(this.ipAddress, ip);
    }

    /**
     *
     */
    async getLocalIpAddress() {
        try {
            const response = await axios.get(environment.ip_checking_site);
            this.ipAddress = response.data.ip;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Convert ip to long
     * @param ip
     */
    ip2long(ip) {
        let components;
        if (components = ip.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/)) {
            let iplong = 0;
            let power = 1;
            for (let i = 4; i >= 1; i -= 1) {
                iplong += power * parseInt(components[i]);
                power *= 256;
            }
            return iplong;
        } else {
            return -1;
        }
    }

    /**
     * Check the ip is in sub net company
     * @param ip
     * @param subnet
     */
    inSubNet(ip, subnet) {
        if (ip == null || subnet == null) {
            return false;
        }
        let mask, base_ip;
        const long_ip = this.ip2long(ip);
        if ((mask = subnet.match(/^(.*?)\/(\d{1,2})$/)) && ((base_ip = this.ip2long(mask[1])) >= 0)) {
            const freedom = Math.pow(2, 32 - parseInt(mask[2], 10));
            return (long_ip > base_ip) && (long_ip < base_ip + freedom - 1);
        } else {
            return false;
        }
    }

    disabledPunchIn() {
        this.disabledStarkWork = false;
        this.disabledLunchOutTime = false;
        this.disabledLunchInTime = false;
        this.disabledEndWork = false;
    }

    clearSignature() {
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    closePlanAssignmentDialog() {
        this.showPlanAssignmentDialog = false;

        this.clearPinNumber();
    }

    clockOutAnyWay() {
        this.isSkipCheckPlanAssignment = true;
        this.showPlanAssignmentDialog = false;
        this.endWork();
    }

    underDevelopment() {
        this.messageService.clear();
        this.messageService.add({
            severity: 'info', summary: 'Information',
            detail: this.translatePipe.transform('Under Development')
        });
    }

    punchActions(punchType) {
        this.firebaseNotificationService.saveFirebaseNotification(`/punch-in-out/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().level}/${this.authService.getUserInfo().adminId}`, {
            punchType: punchType,
            trackingSession: this.trackingSession,
            updateTime: new Date().getTime()
        }).subscribe();
        let args: any = {};
        let actions: string;
        if (punchType === PunchType.CLOCK_IN) {
            actions = 'Checked In';
            args = {
                checked_in_by: this.authService.getCurrentLoggedInName()
            };
        }
        if (punchType === PunchType.LUNCH_OUT) {
            actions = 'Break Out';
            args = {
                out_to_break_by: this.authService.getCurrentLoggedInName()
            };
        }
        if (punchType === PunchType.LUNCH_IN) {
            actions = 'Break Back';
            args = {
                back_from_break_by: this.authService.getCurrentLoggedInName()
            };
        }

        if (punchType === PunchType.CLOCK_OUT) {
            actions = 'Ended Work';
            args = {
                out_to_break_by: this.authService.getCurrentLoggedInName()
            };
        }
        this.monitoringDetailsService.monitorAction(
            `${actions}`,
            this.timeSpent,
            args,
            'complete',
            `${actions}`,
            0
        );
    }

    @HostListener('window:resize', ['$event.target'])
    onResize() {
        this.ngZone.runOutsideAngular(() => {
            this.initSignalPad();
            // this.ngOnInit();
        });
    }

    initSignalPad() {
        if (this.signaturePadSection && this.signaturePadSection.nativeElement && this.signaturePadConfig) {
            if (this.signaturePad.queryPad() && this.signaturePad.queryPad()._canvas) {
                this.signaturePad.queryPad()._canvas.width = this.signaturePadSection.nativeElement.offsetWidth;
                this.signaturePad.queryPad()._canvas.height = 200;//this.signaturePadSection.nativeElement.offsetHeight / 2;
            }
            this.signaturePadConfig = {
                'minWidth': 5,
                'canvasWidth': this.signaturePadSection.nativeElement.offsetWidth,
                'canvasHeight': 200,//this.signaturePadSection.nativeElement.offsetHeight / 2,
                'canvasBorder': 'none',
                'penColor': '#5c93ce'
            };
        }

    }

    totalHours() {
        this.clockingService.punchHistoryFilter(this.payload).subscribe(res => {
            this.totalHoursToday = 0;
            this.totalHoursWeek = 0;
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const punchHistoryList = resObj.data.content;
                if (punchHistoryList) {
                    for (const obj of punchHistoryList) {
                        this.totalHoursWeek += (obj.totalWorkTime / 60000);
                        if (obj.clockInTime && !obj.clockOutTime && MOMENT(obj.clockInTime).isSame(MOMENT(), 'day')) {
                            this.totalHoursToday += Math.floor(MOMENT.duration(MOMENT().diff(MOMENT(obj.clockInTime))).asMinutes());
                        }
                    }
                }
                if (this.totalHoursWeek < 0) {
                    this.totalHoursWeek = 0;
                }
            }
        });
    }

    isHided() {
        this.disabledPunchIn();
        this.clearPinNumber();
    }

    clickFaceRecognitionMode(value) {
        if (value) {
            this.faceModel = new FaceRecognitionModel();
            this.faceModel.adminId = this.currentEmp.id;
            this.faceRecognitionMode = true;
            this.messageService.clear();
            this.messageService.add({severity: 'info', summary: this.translatePipe.transform('Loading...'), detail: this.translatePipe.transform('Loading recognition module')});
        } else {
            this.faceRecognitionMode = false;
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('This is not an employee account')});
        }

    }

    faceRecognitionModeChange() {
        this.faceRecognitionMode = !this.faceRecognitionMode;
        if (!this.faceModel) {
            this.faceModel = new FaceRecognitionModel();
        }
        if (!this.isPunchInOut && this.authService.isEmployeeRole()) {
            this.faceModel.adminId = this.currentEmp?.id;
        }
        if (!this.faceRecognitionMode) {
            this.faceRecognition.pauseVideo();
        }
    }

    async handleDetectionEvent(event) {
        if (this.authService.isEmployeeRole() && this.currentEmp?.id) {
            if (this.pauseDetectFace) {
                return;
            }
            if (this.faceRecognitionChecking) {
                return;
            }
            if (this.employeePunch.pin && (!this.disabledStarkWork || !this.disabledLunchOutTime || !this.disabledLunchInTime || !this.disabledEndWork)) {
                return;
            }
            if (this.disabledRetryFaceRecognition) {
                return;
            }
            this.detectionCount++;
            if (this.detectionCount === 1) {
                setTimeout(() => {
                    this.detectionCount = 0;
                }, 10000);
            }
            if (this.detectionCount >= 3) {
                this.detectionCount = 0;
                if (!this.isToastShowing) {
                    this.messageService.clear();
                    this.messageService.add({ severity: 'info', summary: this.translatePipe.transform(''), detail: 'Please remain still so the camera can recognize your face.' });
                    this.isToastShowing = true;
                    setTimeout(() => {
                        this.isToastShowing = false;
                    }, 5000);
                }
            }
            if (!event) {
                if (!this.pauseDetectFace) {
                    this.personName = 'Unrecognized';
                }
                this.faceRecognition.requestAnimationFrame();
                return;
            }
            if (event.fakeFace) {
                if (!this.isToastShowing) {
                    this.messageService.clear();
                    this.messageService.add({ severity: 'error', summary: this.translatePipe.transform(''), detail: 'Please don\'t use the photo but show your face to the camera' });
                    this.isToastShowing = true;
                    setTimeout(() => {
                        this.isToastShowing = false;
                    }, 5000);
                }
                this.faceRecognition.requestAnimationFrame();
                return;
            }
            const face = event.face;
            if (event.canvas) {
                event.canvas.toBlob(async (uint8Array) => this.faceRecognitionBase64 = await this.blobToBase64(uint8Array), 'image/jpeg');
            }
            if (face && face.human_id) {
                this.faceRecognitionChecking = true;
                const str = face.human_id.split('_');
                if (Number(str[0]) === this.authService.getUserInfo().adminId && face.human_name.includes(str[1])) {
                    this.employeePunch.pin = this.authService.getUserInfo().pin;
                    this.pauseDetectFace = true;
                    if (await this.validatePin(false, true)) {
                        if (this.isPunchInOut) {
                            // TODO
                        } else {
                            this.messageService.clear();
                            this.messageService.add({severity: 'info', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Face recognition Successful!')});
                        }
                        this.faceRecognitionChecking = false;
                    } else {
                        this.translateService.use('en');
                        this.retryFaceRecognitionPopup = true;
                    }
                }
            } else {
                this.faceRecognition.requestAnimationFrame();
            }
        } else {
            this.handleMutilDetectionEvent(event); 
        }
    }

    async handleMutilDetectionEvent(event) {
        console.log('handleMutilDetectionEvent', event);
        if (this.pauseDetectFace) {
            return;
        }
        if (this.faceRecognitionChecking) {
            return;
        }
        if (this.disabledRetryFaceRecognition) {
            return;
        }
        this.detectionCount++;
        if (this.detectionCount === 1) {
            setTimeout(() => {
                this.detectionCount = 0;
            }, 10000);
        }
        if (this.detectionCount >= 3) {
            this.detectionCount = 0;
            if (!this.isToastShowing) {
                this.messageService.clear();

                const englishMessage = this.getTranslation('Please remain still so the camera can recognize your face.', 'en');
                const englishSummary = this.getTranslation('Warning', 'en');
                console.log('English Alert:', englishSummary, englishMessage); 
                this.messageService.add({
                    severity: 'warn',
                    summary: englishSummary,
                    detail: englishMessage,
                    life: 5000 
                });

                this.selectedLanguages.forEach((lang) => {
                    const message = this.getTranslation('Please remain still so the camera can recognize your face.', lang);
                    const summary = this.getTranslation('Warning', lang);
                    console.log('Alert in', lang, ':', summary, message);
                    this.messageService.add({
                        severity: 'warn',
                        summary: summary,
                        detail: message,
                        life: 5000
                    });
                });

                this.isToastShowing = true;
                setTimeout(() => {
                    this.isToastShowing = false;
                }, 5000);
            }
        }

        if (!event) {
            if (!this.pauseDetectFace) {
                this.personName = 'Unrecognized';
            }
            this.faceRecognition.requestAnimationFrame();
            return;
        }
        if (event.fakeFace) {
            if (!this.isToastShowing) {
                this.messageService.clear();
                const englishError = this.getTranslation('Malicious login acivity detected! Use your real face.', 'en');
                const englishErrorSummary = this.getTranslation('Warning', 'en');
                this.messageService.add({ severity: 'error', summary: englishErrorSummary, detail: englishError });

                this.selectedLanguages.forEach((lang) => {
                    const message2 = this.getTranslation('Malicious login acivity detected! Use your real face.', lang);
                    const summary2 = this.getTranslation('Warning', lang);
                    console.log('Alert in', lang, ':', summary2, message2);
                    this.messageService.add({
                        severity: 'warn',
                        summary: summary2,
                        detail: message2,
                        life: 5000
                    });
                });


                this.isToastShowing = true;
                setTimeout(() => {
                    this.isToastShowing = false;
                }, 5000);
            }
            this.faceRecognition.requestAnimationFrame();
            return;
        }
        this.faceRecognitionChecking = true;
        let maxPercentMatch = 0;
        let employee;
        let personName;
        let employeeNotBelongToCompany = '';
        let employeeNotBelongMaxPercentMatch = 0;
        const faces = event.faces;
        if (event.canvas) {
            event.canvas.toBlob(async (uint8Array) => this.faceRecognitionBase64 = await this.blobToBase64(uint8Array), 'image/jpeg');
        }
        faces.forEach(face => {
            const str = face.human_id.split('_');
            const employeeId = Number(str[0]);
            const employeeMatched = this.allEmployeeList.find(e => e.id === employeeId);
            if (face.human_name.includes(str[1]) && employeeMatched) {
                if (maxPercentMatch < face.percent_match) {
                    employee = employeeMatched;
                    personName = face.human_name + ', ' + face.percent_match.toFixed(2) + '%';
                    maxPercentMatch = face.percent_match;
                }
            } else {
                if (employeeNotBelongMaxPercentMatch < face.percent_match) {
                    // employeeNotBelongToCompany = event.human_name + ', ' + event.percent_match.toFixed(2) + '% (Wrong company)';
                    employeeNotBelongToCompany = 'Unrecognized';
                    employeeNotBelongMaxPercentMatch = face.percent_match;
                }
            }
        });
        if (employee) {
            this.personName = personName;
            this.employeePunch.pin = employee.pin;
            this.pauseDetectFace = true;
            await this.validatePin(false, true);
        } else {
            this.personName = employeeNotBelongToCompany;
            this.pauseDetectFace = false;
            this.faceRecognition.requestAnimationFrame();
        }
        this.faceRecognitionChecking = false;
    }

    retryFaceRecognitionClose() {
        this.faceRecognitionChecking = false;
        this.retryFaceRecognitionPopup = false;
        if (this.faceRecognition && this.pauseDetectFace) {
            this.pauseDetectFace = false;
            this.faceRecognition.resumeVideo();
        }
    }

    getSuffixTimes(times: number) {
        if (times == 1) {
            return 'st';
        }
        if (times == 2) {
            return 'nd';
        }
        if (times == 3) {
            return 'rd';
        }
        return 'th';
    }

    absenceList = [];
    async loadTimeOffRequestDataSource() {
        let absenceFilter = {
            page: 0,
            size: 9999,
            companyId: this.authService.getCurrentCompanyId(),
            employeeId: this.employeePunch.employeeId
            //currentDate: new Date().getTime()
        };
        let resp = <any>await this.employeeService.getAllAbsence(absenceFilter).toPromise();
        this.absenceList = resp.data.content;
    }

    requestAuthorizationCode() {
        this.requestCodeCountdown = 30000;
        this.clockingAutorizationCodeService
            .generateEmployeeAuthorizationCode(this.authService.getCurrentCompanyId(), this.employeePunch.employeeId)
            .subscribe(() => {
                this.messageService.clear();
                this.messageService.add({ severity: 'info', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Authorization code has been sent')});
            }, (error) => {
                this.messageService.clear();
                this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Authorization Code'), detail: error.message })
            });
        
        this.requestCodeCountdownStr = this.calulateCountdown(this.requestCodeCountdown);
        if (this.requestCodeInterval) {
            clearInterval(this.requestCodeInterval);
        }
        this.requestCodeInterval = setInterval(() => {
            this.requestCodeCountdown = this.requestCodeCountdown - 1000;
            if (this.requestCodeCountdown <= 0) {
                this.requestCodeCountdownStr = null;
                clearInterval(this.requestCodeInterval);
                return;
            }
            this.requestCodeCountdownStr = this.calulateCountdown(this.requestCodeCountdown);
        }, 1000);

    }

    submitAuthorizationCode() {
        this.clockingAutorizationCodeService.validateAuthorizationCode(this.authService.getCurrentCompanyId(), this.employeePunch.employeeId, this.authorizationCode).subscribe((res: any) => {
            if (res.status === 'SUCCESS' && res.data) {
                this.disabledStarkWork = this.nextStepAfterAuthorization.disabledStarkWork;
                this.disabledLunchOutTime = this.nextStepAfterAuthorization.disabledLunchOutTime;
                this.disabledLunchInTime = this.nextStepAfterAuthorization.disabledLunchInTime;
                this.disabledEndWork = this.nextStepAfterAuthorization.disabledEndWork;
                this.closeAuthorizationCodePopup();
            } else {
                this.messageService.clear();
                this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Invalid Code'), detail: this.translatePipe.transform('Authorization Code you entered is invalid.')});
            }
        }, () => {
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Invalid Code'), detail: this.translatePipe.transform('Authorization Code you entered is invalid.')})
        });
    }

    closeAuthorizationCodePopup() {
        this.authorizationCode = null;
        this.authorizationCodePopup = false;
    }

    calulateCountdown(estimateTime) {
        if (estimateTime <= 0) {
            return '00';
        }
        const date = new Date(estimateTime);
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
        return `${seconds}`;
    }
    closePopupSelect() {
        this.showPopupSelect = false;
    }

    gotoTimeCorrect() {
        this.router.navigate(['app/employee/protest-hour']);
    }

    showPopupReason() {
        this.showAddNoteDialog = true;
        this.translateService.use('en');
        this.showPopupSelect = false;
    }

    hiddenPopupReason() {
        this.showAddNoteDialog = false;
        this.punchInOutOption = '';
        this.punchInOutNote = '';
    }

    onUserInteraction() {
        this.idleTimerService.stopTimer();
    }

    openLunchDurationConfirmDialog() {
        if (this.authService.isEmployeeRole()) {
            this.lunchDurationDate = new Date();
            this.lunchDurationNote = null;
            this.showLunchDurationDialog = false;
            this.translateService.use('en');
            this.showLunchDurationConfirmDialog = true;
        } else {
            this.submitLunchTimeDuration();
        }
    }

    submitLunchDuration() {
        this.punchInOutOption = '5';
        this.punchInOutNote = this.lunchDurationNote;
        const note = this.lunchDurationNote;
        this.submitEmployeePunchForAccount(PunchType.LUNCH_IN, PunchDirection.IN);
        forkJoin([
            this.companySettingServiceV2.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId()),
            this.employeeService.searchEmployee({
              companyId: this.authService.getCurrentCompanyId(),
              departmentNameSearch: ['Accountant', 'HR'],
              status: 1
            })
        ]).subscribe((details: any[]) => {
            const emails = [];
            if (details[0] && details[0].status === 'SUCCESS' && details[0].data) {
              if (details[0].data.employeeMissingPunchNotificationEmail) {
                emails.push(details[0].data.employeeMissingPunchNotificationEmail);
              }
            }
            if (details[1] && details[1].status === 'SUCCESS' && details[1].data && details[1].data.content && details[1].data.content.length > 0) {
              details[1].data.content.forEach(e => {
                if (!emails.includes(e.email)) {
                  emails.push(e.email);
                }
              });
            }
            if (emails.length > 0) {
                const emailPayload = {
                    toEmails: emails,
                    subject: 'Short Break Notification',
                    body: `Attention,<br><br>
                        ${note}<br><br>
                        Best regards`,
                    emailCompanyId: this.authService.getUserInfo().companyId,
                }
                this.notificationService.sendTextEmail(emailPayload).subscribe();
            }
        });
        this.closeLunchDurationConfirmDialog();
        this.punchInOutOption = null;
        this.punchInOutNote = null;
    }

    closeLunchDurationConfirmDialog() {
        this.lunchDurationDate = null;
        this.lunchDurationNote = null;
        this.showLunchDurationConfirmDialog = false;
    }

    async validateContractorPin() {
        if (this.currentEmp.status !== 1) {
            if (!this.isPunchInOut) {
                this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('Account Deactivated') + '. ' + this.translatePipe.transform('Please Contact HR or System Admin'));
                // this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Account Deactivated'), detail: this.translatePipe.transform('Please Contact HR or System Admin')});
            }
            this.idleTimerService.startTimer(15);
            return Promise.resolve(false);
        }
        
        if (this.limitLateness && this.employeeLatenessDecision !== 3 && this.latenessCount >= this.limitLateness) {
            this.latenessCount = this.latenessCount ? this.latenessCount : 0;
            this.buildErrorMessage('', "Login Failed, You've exceed " + this.latenessCount + " times of the lateness, Please Contact your Manager/HR");
            setTimeout(() => {
                this.clearPinNumber();
            }, 2500);
            return Promise.resolve(false);
        }

        this.employeePunch.fullName = this.currentEmp.fullName;
        this.employeePunch.employeeId = this.currentEmp.id;
        this.employeePunch.clockAuthorization = this.currentEmp.clockAuthorization;
        this.companyId = this.currentEmp.companyId;
        this.isInvalidPin = false;
        this.disabledEnterButton = true;

        const pinValidation: any = await this.freelancerService.validatePin(this.employeePunch.employeeId, this.employeePunch.pin).toPromise();
        if (pinValidation.data.locked || pinValidation.data.wrongPinTimes > 0) {
            this.isInvalidPin = true;
            if (pinValidation.data.locked) {
                this.isInvalidPinMsg = 'PIN was blocked';
                if (!this.isPunchInOut) {
                    this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('your_attempts_are_exceeded_the_limitation'));
                    // this.messageService.add({
                    //     severity: 'info',
                    //     summary: this.translatePipe.transform('PIN was blocked'),
                    //     detail: this.translatePipe.transform('your_attempts_are_exceeded_the_limitation')});
                }
            } else {
                this.clockingTracking.employeeId = this.employeePunch.employeeId;
                this.clockingTracking.date = new Date();
                this.clockingTracking.pin = this.employeePunch.pin;
                this.clockingTracking.clockAction = 'WRONG_PIN';
                this.clockingTracking.status = 'Error';
                this.clockingTracking.createdByUsr = this.authService.getCurrentUsername();
                this.clockingTracking.locationName = this.employeePunch.locationName;
                this.clockingTracking.accountUsed = this.authService.getCurrentUsername();
                this.clockingTracking.isContractor = true;
                this.punchTracking();
                this.isInvalidPinMsg = 'This Pin is invalid. Please enter a valid Pin';
                if (!this.isPunchInOut) {
                    this.buildErrorMessage(PunchType.CLOCK_IN, this.translatePipe.transform('PIN not found, please enter a valid PIN.'));
                    // this.messageService.add({severity: 'info', summary: this.translatePipe.transform('Invalid Pin'), detail: this.translatePipe.transform('Please enter correct pin')});
                }
            }
            this.handlingInvalidPin();
            this.clearPinNumber();
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    async onTakePhoto(event) {
        this.messageService.clear();

        const englishError2 = this.getTranslation('Image taken.', 'en');
        const englishErrorSummary2 = this.getTranslation('Facial Recognition', 'en');
        this.messageService.add({ severity: 'error', summary: englishErrorSummary2, detail: englishError2 });

        this.selectedLanguages.forEach((lang) => {
            const message3 = this.getTranslation('Image taken.', lang);
            const summary3 = this.getTranslation('Facial Recognition', lang);
            console.log('Alert in', lang, ':', summary3, message3);
            this.messageService.add({
                severity: 'warn',
                summary: summary3,
                detail: message3,
                life: 5000
            });
        });


        const blob: any = await this.compressImage(event);
        this.photoBase64String = await this.blobToBase64(blob);
        if (this.punchInOutData.id && this.isManualInput && this.companySetting.pinFacialRecognition === 'AUTO_TAKE_PICTURE' || this.companySetting.pinFacialRecognition === 'MANUAL_TAKE_PICTURE') {
            const payload: any = {};
            if (this.punchInOutData.clockType === PunchType.CLOCK_IN) {
                payload.clockInPhoto = this.photoBase64String;
            } else if (this.punchInOutData.clockType === PunchType.CLOCK_OUT) {
                payload.clockOutPhoto = this.photoBase64String;
            } else if (this.punchInOutData.clockType === PunchType.LUNCH_OUT) {
                payload.lunchOutPhoto = this.photoBase64String;
            } else if (this.punchInOutData.clockType === PunchType.LUNCH_IN) {
                payload.lunchInPhoto = this.photoBase64String;
            }
            this._employeeClockingService.updateFaceImg(this.punchInOutData.id, payload).subscribe(res => console.log(res));
            this.isManualInput = false;
        }
        console.log('onTakePhoto', blob);
    }

    blobToBase64(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
    }

    compressImage(blob, quality = 1) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);
                canvas.toBlob((compressedBlob) => {
                    resolve(compressedBlob);
                }, 'image/jpeg', quality);
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(blob);
        });
    }


    onLanguageChange(languageValue: string, event: any) {
        const isChecked = event.target.checked;

        if (isChecked) {
          if (this.selectedLanguages.length < 2) {
            this.selectedLanguages.push(languageValue);
          }
        } else {
          this.selectedLanguages = this.selectedLanguages.filter(lang => lang !== languageValue);
        }
      }

      getTranslation(key: string, lang: string): string {
        const currentLang = this.translateService.currentLang;
        this.translateService.use(lang); // Temporarily switch to the desired language
        const translatedValue = this.translatePipe.transform(key);
        this.translateService.use(currentLang); // Switch back to the original language
        return translatedValue;
      }

      resetFlags() {
        this.showJA = false;
        this.showKO = false;
        this.showZH = false;
        this.showZH2 = false;
        this.showES = false;
        this.showFR = false;
        this.showRU = false;
        this.showHI = false;
        this.showAR = false;
        this.showVI = false;
      }

      resetSelection() {
        this.selectedLanguages = [];
        this.languages.forEach(language => language.checked = false);
        this.translateService.use('en');
      }

      onColorChange(color: string) {
        this.selectedColor = color;
      }

      resetBG() {
        this.selectedColors.forEach(color => this[color] = false);
        this.selectedColors = [];
        this.selectedColor = 'blue';
      }
}
