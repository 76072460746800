<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
    <h1 class="m-0 ml-2 my-4">{{'Payments' | translate}}</h1>
    <p-panel [toggleable]="true">
        <p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Payments' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Date Range' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{'Start date' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
							<input matEndDate matInput placeholder="{{'End date' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown class="w-100" [options]="allClientList" optionLabel="label" [filter]="true" filterMatchMode="startsWith" [(ngModel)]="selectedClient" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}" [disabled]="isClientRole" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'PaymentMethod' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-wallet"></i>
						</button>
					</div>
					<p-dropdown class="w-100" [options]="paymentMethods" [showClear]="true" [(ngModel)]="paymentMethodSelected" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Status' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-multiSelect defaultLabel="{{'pleaseSelect' | translate}}" name="statusSearch" [options]="statusTypes" [(ngModel)]="selectedStatus" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="loadContentsLazy()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex align-items-center flex-wrap justify-content-between my-4">   
		<h1 class="m-0 ml-2 mr-5">{{'Browse Payments' | translate}}</h1>
		<div class="m-0 text-secondary" id="total-payments" style="font-size: 16px">{{'Total Paid' | translate}}: {{ totalAmountPaid | currency : 'USD':'symbol':'1.2-2'}}</div>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Transactions' | translate}} ({{totalRecords || 0 | number}})</span>
		</p-header>
		<p-table #dt [columns]="cols" [value]="transactionList" dataKey="id" [lazy]="true" (onLazyLoad)="loadContentsLazy($event)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [resizableColumns]="true">

			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between" style="row-gap: 10px">
			
					<button type="button" (click)="exportPdf()" class="btn btn-primary mx-1">
						<span class="text-nowrap"><i *ngIf="!exportingPDF" class="fa-solid fa-print mr-2"></i><i *ngIf="exportingPDF" class="fa-solid fa-spinner mr-2 loadingHourglass"></i> {{'Export PDF' | translate}}</span>
					</button>
				
					<div class="input-group w-100 px-1" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" [(ngModel)]="searchText" #searchKeyInput (keyup)="searchEvent($event)" placeholder="{{'Filter' | translate}}" pInputText>
					</div>
				</div>
			</ng-template>

			<ng-template pTemplate="header" let-columns>
				<tr>
					<th class="adjust-padding" pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': col.display, 'width': col.width}">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
							<span *ngSwitchCase="'date'">
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op4.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
							<span *ngSwitchCase="'status'">
								<span class="d-flex justify-content-center w-100">
									<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
								</span>
            				</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowData let-i="rowIndex">
				<tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
							<span *ngIf="rowData.invoiceNumber && rowData.description !== 'Donation'" class="text-primary c-pointer"
								  (click)="clickInvoiceRef($event,rowData);" pTooltip="{{rowData.invoiceNumber}}">{{rowData.invoiceNumber}}</span>
							<span *ngIf="rowData.invoiceNumber && rowData.description === 'Donation'"
								  pTooltip="{{rowData.invoiceNumber}}">{{rowData.invoiceNumber}}</span>
							<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Date' | translate}}: </span>
							<span *ngIf="rowData.date" pTooltip="{{ rowData.date | date: 'MM/dd/yyyy hh:mm:ss a' }}">{{ rowData.date | date: 'MM/dd/yyyy hh:mm:ss a' }}</span>
							<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Transaction ID' | translate}}: </span>
							<a href="javascript:void(0);" *ngIf="rowData.transactionId" pTooltip="{{ rowData.transactionId }}" (click)="openPaymentDetailPopup(rowData)">{{ rowData.transactionId }}</a>
							<span *ngIf="!rowData.transactionId" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Description' | translate}}: </span>
							<span *ngIf="rowData.description" pTooltip="{{ rowData.description }}">{{ rowData.description }}</span>
							<span *ngIf="!rowData.description">{{'Payment' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Amount' | translate}}: </span>
							<span *ngIf="rowData.amount"  pTooltip="{{rowData.amount | currency : 'USD' : true : '1.2-2'}}">{{rowData.amount | currency : 'USD' : true : '1.2-2'}}</span>
							<span *ngIf="!rowData.amount" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Method' | translate}}: </span>
							<span *ngIf="rowData.paymentMethod" pTooltip="{{ rowData.paymentMethod }}">{{ rowData.paymentMethod }}</span>
							<span *ngIf="!rowData.paymentMethod" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Trans. Type' | translate}}: </span>
							{{'Auth & Capture' | translate}}
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Proccessed by' | translate}}: </span>
							<span *ngIf="rowData.processedBy || rowData.autoPay" pTooltip="{{rowData.autoPay ? 'Auto Pay' : (rowData.processedBy ? rowData.processedBy : 'Client')}}">{{rowData.autoPay ? 'Auto Pay' : (rowData.processedBy ? rowData.processedBy : 'Client')}}</span>
							<span *ngIf="!rowData.processedBy && !rowData.autoPay" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Status' | translate}}: </span>
							<i *ngIf="rowData.status === 'Succeeded'" class="fa-solid fa-circle-check text-success" [pTooltip]="rowData.status" tooltipPosition="left"></i>
							<i *ngIf="rowData.status === 'Failed' && rowData.description" class="fa-solid fa-circle-xmark text-danger" [pTooltip]="rowData.description" tooltipPosition="left"></i>
							<i *ngIf="rowData.status === 'Refunded'" class="fa-solid fa-circle-minus text-orange" [pTooltip]="rowData.status" tooltipPosition="left"></i>
							<i *ngIf="rowData.status === 'Pending'" class="fa-solid fa-circle-info text-primary" [pTooltip]="rowData.status" tooltipPosition="left"></i>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'by' | translate}}: </span>
							<span *ngIf="rowData.createdByUsr" pTooltip="{{ rowData.createdByUsr }}" tooltipPosition="left">{{ rowData.createdByUsr }}</span>
							<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="footer">
				<tr *ngIf="totalAmountPaid > 0">
					<td colspan="4" class="text-right text-sm-left">
						<span>{{'Total Paid (All pages)' | translate}}</span>
					</td>
					<td [attr.colspan]="1">
						<span class="one-liner" pTooltip="{{ totalAmountPaid | currency : 'USD':'symbol':'1.2-2'}}">{{ totalAmountPaid | currency : 'USD':'symbol':'1.2-2'}}</span>
					</td>
					<td colspan="5"></td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="cols?.length" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="cols?.length" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
		<div style="position: absolute; top: -10000px; left: -10000px; z-index: -999;" id="pdfContent"></div>
	</p-panel>
</div>

<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption"  (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-confirmDialog header="Confirmation" icon="fa-solid fa-circle-question" width="525"></p-confirmDialog>

<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs"
		(click)="invoiceRefAction(exportAs)">
		<ng-template let-col pTemplate="item">
			<div>
				<span>{{col.name}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<app-payment-confirmation-dialog *ngIf="showPaymentDetail" [display]="showPaymentDetail" [data]="detailData" (sendEmailChange)="openSendEmailPopup($event)"
								 [functionImport]="functionImport" (displayChange)="closePaymentDetailPopup($event)">
</app-payment-confirmation-dialog>
<p-dialog header="{{'Email Receipt' | translate}}" [(visible)]="showSendEmailPopup" [modal]="true" [responsive]="true" [style]="{width: '40vw' }" (onHide)="showSendEmailPopup = false">
	<div class="ui-g">
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Email address' | translate}}<span class="text-danger">*</span>:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<input pInputText class="w-100 flex-auto" style="width: 100%;" placeholder="{{'Email address' | translate}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="emailAddress" />
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Message' | translate}}<span class="text-danger">*</span>:</label>
			<div class="input-group w-100">
				<textarea pInputTextarea style="width:100%" [rows]="4" class="acceptance-inputs" autoResize="true" [ngModelOptions]="{standalone: true}"
						  maxlength="500" placeholder="{{'Maximum length of notes is 500' | translate}}" [(ngModel)]="emailMessage">
				</textarea>
			</div>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button type="button" pButton (click)="sendEmail()" class="ui-button-info" label="{{'Send' | translate}}"></button>
			<button type="button" pButton (click)="showSendEmailPopup = false" class="ui-button-info" label="{{'Cancel' | translate}}"></button>
		</div>
	</div>
</p-dialog>
