import {EmployeeWorkHour} from './employee-work-hour.model';
import {Agency} from '../../agency/model';
import {Company} from '../../company/model/company.model';
import {EmployeeDepartment} from '../../department/model/employee-department.model';
import {EmployeeDepartmentPosition} from './employee-department-position.model';
import {EmployeeClocking} from '../../employee-clocking/model/employee-clocking.model';
import {PayrollSetting} from '../../payroll-setting/model/payroll-setting.model';

export class Employee {
    employeeId: number;
    id: number;
    ident: number;
    employeeIdent: number;
    name: string;
    firstName: string;
    lastName: string;
    middleName: string;
    username: string;
    password: string;
    email: string;
    alternativeEmail: string;
    phone: string;
    gender: string;
    department: string;
    companyName: string;
    pin: string;
    dateOfBirth: any;
    socialSecurityNumber: string;
    employeeStartDate: any;
    profileImageId:number;
    status: number;
    type: string;
    facePersonId: String;
    profile: any;
    role: any;
    classification: string;
    departmentOther?: string;
    weeklyWorkingTimeLimitation: number;
    matarialStatus: string;
    dependent: string;
    position: string;
    salaryType: string;
    weeklyHours: number;
    rate: any = null;
    rate1: any = null;
    isChangeRate = false;
    workHours: EmployeeWorkHour[];
    agency: Agency;
    company: Company;
    fullName: string;
    rateFromDate: any;
    rateToDate: any;
    employeeDepartments: EmployeeDepartment[];
    employeeDepartmentPositions: EmployeeDepartmentPosition[];
    employeeClockings: EmployeeClocking[];
    employeeAdjustmentBonus: any;
    did: string;
    extension: number;
    trackEmployeeMobile: boolean;
    agreement: boolean;
    isAgreement: boolean;
    updated = 0;
    punchInOutNotification: boolean;
    totalHours: any;
    payrollSetting: PayrollSetting;
    agencyId: number;
    companyId: number;
    deskStatus: string;
    clockDevice: string[];
    contractName: string;
    value: string;
    key: number;
    state: string;
    wrongPinTimes: number;
    isPinBlocked: boolean;
    selected: boolean;
    invitationStatus?: number;
    plate?: string;
    createdByUsr?: string;
    employeeGroupId: number;
    acceptSmsNotifyJobReport: boolean;
    timeType: string;
    completedDocument?: number;
    totalDocument?: number;
    jobDescription?: string;
    totalLateTime: any;
    balanceHour: any;
    balanceOTStr: any;
    totalWorkedTime: any;
    overtimeHours: any;
    clockAuthorization?: string;
    reminder?: boolean;
    defaultReminder?: boolean;
    reminderViaEmail?: boolean;
    reminderViaSms?: boolean;
    customizedReminder?: string;
    departmentNames?: any;
    preferredZipcode?: string[];
    preferredZone?: number[];
    preferredZones?: any;
    preferredZoneType?: string;
    punchInLateCount?: number;

    approvedTracking?: boolean;
    phoneFlag?: string;

    complianceStatus?: string;
    documentNames?: any;
    complianceDocuments?: any;
    salaryHourRate?: number;
}
