import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import { Expense } from '../model/expense.model';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { parseMarker } from '@fullcalendar/common';
import * as _moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ExpenseService extends AbstractService<Expense> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CRM.EXPENSE, '');
  }
  filter(options: any): Observable<Page<Expense>> {
    // options.toDate = moment(options.toDate).toISOString(true);
    // options.fromDate = moment(options.fromDate).toISOString(true);
    const reqUrl = `${this.baseUrl}${Constants.URI.EXPENSE.ALL}`;
    return this._http.post<Page<Expense>>(this.baseUrl, options, { params: { page: options.page, size: options.size, sort: options.sort } }).pipe(map(res => res));
  }

  loadExpenses(options: any) {
    const params: any = { page: options.page, size: options.size };
    if (options.sort) {
      params.sort = options.sort;
    }
    // return this._http.post<Page<Expense>>(`http://localhost:8010/api/v2/crm/${Constants.ROUTES.EXPENSE}/search`, options, { params: params }).pipe(map(res => res));
    return this._http.post<Page<Expense>>(`${environment.v2_server_backend}/crm-service/api/v2/crm/${Constants.ROUTES.EXPENSE}/search`, options).pipe(map(res => res));
  }

  createExpense(entity) {
    const params: any = {
      id: entity.id,
      companyId: entity.companyId,
      agencyId: entity.agencyId,
      startMileage: entity.startMileage,
      endMileage: entity.endMileage,
      total: entity.total,
      amount: entity.amount,
      tollFee: entity.tollFee,
      date: entity.date,
      comment: entity.comment,
      plateNumber: entity.plateNumber,
      username: entity.username,
      payrollSetting: entity.payrollSetting,
      createdByUsr: entity.createdByUsr,
      lastModifiedBy: entity.lastModifiedBy,
      easyPass: entity.easyPass,
      signature: entity.signature,
      tripDescription: entity.tripDescription,
      meals: entity.meals,
      parking: entity.parking,
      mealsDocumentId: entity.mealsDocumentId,
      parkingDocumentId: entity.parkingDocumentId,
      startMileageDocumentId: entity.startMileageDocumentId,
      endMileageDocumentId: entity.endMileageDocumentId,
      status: 'Pending',
      otherExpenses: entity.otherExpenses,
      cashAdvance: entity.cashAdvance,
    };
    const d = new Date(params.date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    params.date = d;

    // return this._http.post(`http://localhost:8010/api/v2/crm/${Constants.ROUTES.EXPENSE}`, params).pipe(map(res => res));
    return this._http.post(`${environment.v2_server_backend}/crm-service/api/v2/crm/${Constants.ROUTES.EXPENSE}`, params).pipe(map(res => res));
  }

  updateExpense(entity, id) {
    const params: any = {
      id: entity.id,
      companyId: entity.companyId,
      agencyId: entity.agencyId,
      startMileage: entity.startMileage,
      endMileage: entity.endMileage,
      total: entity.total,
      amount: entity.amount,
      tollFee: entity.tollFee,
      date: entity.date,
      comment: entity.comment,
      plateNumber: entity.plateNumber,
      username: entity.username,
      payrollSetting: entity.payrollSetting,
      createdByUsr: entity.createdByUsr,
      lastModifiedBy: entity.lastModifiedBy,
      easyPass: entity.easyPass,
      signature: entity.signature,
      tripDescription: entity.tripDescription,
      meals: entity.meals,
      parking: entity.parking,
      mealsDocumentId: entity.mealsDocumentId,
      parkingDocumentId: entity.parkingDocumentId,
      startMileageDocumentId: entity.startMileageDocumentId,
      endMileageDocumentId: entity.endMileageDocumentId,
      status: entity.status,
      approvedBy: entity.approvedBy,
      approvedAt: entity.approvedAt,
      otherExpenses: entity.otherExpenses,
      otherExpenseDeleteIds: entity.otherExpenseDeleteIds,
      amountPaid: entity.amountPaid,
      transactionId: entity.transactionId,
      cashAdvance: entity.cashAdvance
    };

    if (_moment.isMoment(entity.date)) {
      const d = new Date(params.date);
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      params.date = d;

    }

    // return this._http.put(`http://localhost:8010/api/v2/crm/${Constants.ROUTES.EXPENSE}/${id}`, params).pipe(map(res => res));
    return this._http.put(`${environment.v2_server_backend}/crm-service/api/v2/crm/${Constants.ROUTES.EXPENSE}/${id}`, params).pipe(map(res => res));
  }

  deleteExpense(id) {
    return this._http.delete(`${environment.v2_server_backend}/crm-service/api/v2/crm/${Constants.ROUTES.EXPENSE}/${id}`).pipe(map(res => res));
  }

  exportPdf(data?: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8010/api/v2/crm/${Constants.ROUTES.EXPENSE}/exportPdf`, data).pipe(map(resp => resp));
    return this._http.post<any>(`${environment.v2_server_backend}/crm-service/api/v2/crm/${Constants.ROUTES.EXPENSE}/exportPdf`, data).pipe(map(resp => resp));
  }

  exportPdfV2(ids: any) {
    return this._http.get<any>(`${this.baseUrlV2}pdf-list/` + ids);
  }

  exportPdfDetail(id: any) {
    return this._http.get<any>(`${this.baseUrlV2}pdf/` + id);
  }

  saveHistoryData(data) {
    return this._http.post(`${environment.v2_server_backend}/crm-service/api/v2/crm/${Constants.ROUTES.EXPENSE}/history`, data).pipe(map(res => res));
  }

  getClientHistory(options?: any): Observable<Page<Expense>> {
    // return this._http.post<Page<Expense>>(`http://localhost:8010/api/v2/crm/${Constants.ROUTES.EXPENSE}/history/search`, options).pipe(map(res => res));
    return this._http.post<Page<Expense>>(`${this.baseUrlV2}history/search`, options).pipe(map(res => res));
  }

  getUserList(): Observable<any> {
    return this._http.get<any>(this.baseUrlV2 + Constants.URI.EXPENSE.GETUSER, {}).pipe(map(res => res));
  }

  getAllAddedByCompany(options?: any): Observable<any> {
    const params = this.createParams(options);
    return this._http.get(this.baseUrlV2 + 'getAllAddedByCompany', { params: params }).pipe(map(res => res));
  }

  getSummary(params: any) {
      // return this._http.post<any>(`http://localhost:8010/api/v2/crm/expense/summary`, params);
      return this._http.post<any>(`${this.baseUrlV2}summary`, params);
  }

  exportPdfDetailWithDate(id: any, printOn: string) {
    // return this._http.get<any>(`http://localhost:8010/api/v2/crm/expense/pdf/` + id + '/' + printOn);
    return this._http.get<any>(`${this.baseUrlV2}pdf/` + id + '/' + printOn);
  }
}
