import { Role } from "./role.model";
import { Sip } from "./sip.model";

export class User {
  email: string;
  token: string;
  adminId: number;
  permission: string;
  permissionId: number;
  apiKey: string;
  projectId: string;
  messagingSenderId: string;
  vapidKey: string;
  menuList: Array<any>;
  actionList: Array<any>;
  companyId: number;
  agencyId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  level: number;
  pin: string;
  agency: any;
  company: any;
  profilePhoto: string;
  profileId: string;
  contactName: string;
  companyName: string;
  isBillingCustomer: any;
  isEnableMarketingDepartment: any;
  isEnableBillingDepartment: any;
  role: Role;
  sip: Sip;
  username: string;
  type: string;
  did: string;
  extension: string;
  loginTime?: any;
  stageState?: any;
  editList: Array<any>;
  deleteList: Array<any>;
  name?: string;
  phone?: string;
  crmClientId?: string;
  group?: any;
  groupId: number;
  accountType: string;
  defaultDashboard?: string;
}
