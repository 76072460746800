import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormControl, ValidationErrors, Validators } from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { of } from 'rxjs/internal/observable/of';
import { EmployeeService } from 'app/employee/service/employee.service';
import { catchError, map } from 'rxjs/operators';
import { Guess } from '../model/guess.model';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { FormValidatorService } from 'app/shared/service/form-validator/form-validator.service';
import { MembershipService } from 'app/membership/service/membership.service';
import { NotificationService, NotificationType } from 'app/crm/service/notification.service';
import { Message } from 'primeng/api';
import { AgencyAdminService } from 'app/admin/agency-admin/service/agency-admin.service';
import { UtilsService } from 'app/shared/service/utils.service';
import * as Crypto from 'crypto-js';
import { ClientService } from 'app/crm/service/client.service';
import { FreelancerService } from 'app/crm/service/freelancer.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { MessageService } from 'primeng';
import { TranslatePipe } from '@ngx-translate/core';
import {MonitoringDetailsService} from '../../admin/process-monitor/service/monitoring-details.service';

@Component({
    selector: 'app-component',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
    providers: [FormValidatorService, MembershipService, EmployeeService, FreelancerService, ClientService, AgencyAdminService,
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }]
})
export class SignUpComponent implements OnInit {


    guessForm: UntypedFormGroup;
    showPwd = false;
    readOnly: Boolean = false;
    listOfPhoneCode: any = [];
    guess: Guess = new Guess();
    msgs: Message[] = [];
    private _jsonPhoneCodeURL = 'assets/country-dial-codes.json';
    dateOfBirth: any;
    maxDate = new Date();
    showPolicy = false;
    genders: Array<any> = [];
    accountTypeList: Array<any> = [];
    serviceTypeList: Array<any> = [];
    servicePlanList: Array<any> = [];
    paymentCard = null;
    paymentMethodData: any;
    generatedKey: string;
    zipCode = new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{1,5}$/) // Allows only up to 5 digits
      ]);
    isLoading = false;
    constructor(
        private http: HttpClient,
        private formValidator: FormValidatorService,
        private membershipService: MembershipService,
        private notificationService: NotificationService,
        private agencyAdminService: AgencyAdminService,
        private employeeService: EmployeeService,
        private clientService: ClientService,
        private freelancerService: FreelancerService,
        private utilsService: UtilsService,
        private router: Router,
        private messageService: MessageService,
        private translatePipe: TranslatePipe,
        private monitoringDetailsService: MonitoringDetailsService,
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes('/sign-up')) {
                    this.monitoringDetailsService.monitorAction(
                        `Navigated to Sign-up`,
                        new Date(),
                        {
                            navigated_to_sign_up_by: 'Guest',
                        },
                        'complete',
                        `Navigated to Sign-up`,
                        0
                    );
                }
            }
        });
    }

    ngOnInit() {
        this.genders = [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }];
        this.accountTypeList = [{ label: 'Guest', value: 'Guest' }, { label: 'Merchant', value: 'Merchant' }];
        this.serviceTypeList = [{ label: 'Guest', value: 'Guest' }, { label: 'Merchant', value: 'Merchant' }];
        this.servicePlanList = [
            { label: '$188/month - up to 5,000 times send', value: '$188/month - up to 5,000 times send' }, 
            { label: '$348/month - up to 10,000 times send', value: '$348/month - up to 10,000 times send' }, 
            { label: '$748/month - up to 25,000  times send', value: '$748/month - up to 25,000  times send' }, 
            { label: '$1248/month - up to 50,000 times send', value: '$1248/month - up to 50,000 times send' }
        ];
        this.getPhoneCode().subscribe(response => {
            this.listOfPhoneCode = [];
            const phoneCodes = response.countries;
            phoneCodes.forEach(item => {
                const data = { label: item.name + ' (' + item.code + ')', value: item.code };
                this.listOfPhoneCode.push(data);
            });
        });
        // init form group
        this.guessForm = new UntypedFormGroup({
            first_name: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator]),
            last_name: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator]),
            email: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')], [this.validateEmailNotTaken.bind(this),
                this.validateEmailNotTakenClient.bind(this),
                this.validateEmailAdmin.bind(this), this.validateEmailEmployee.bind(this), this.validateEmailNotTakenFreelancer.bind(this)]),
            username: new UntypedFormControl({ value: '', disabled: this.readOnly },
                [Validators.required], [this.validateUserNameNotTaken.bind(this),
                    this.validateUserNameNotTakenAdmin.bind(this), this.validateUserNameNotTakenEmployee.bind(this)]),
            password: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/)]),
            phone_code: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
            phone: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, this.noWhitespaceValidator, this.customPhoneValidate]),
            date_of_birth: new UntypedFormControl({ value: new Date(), disabled: this.readOnly }),
            gender: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            referenceBy: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            accountType: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            serviceTypes: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            servicePlan: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            addressOne: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            city: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            state: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            zipcode: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            paymentGroup: new UntypedFormControl({ value: '', disabled: this.readOnly }),
        });
        this.guessForm.get('gender').setValue(null);
        this.guessForm.get('accountType').setValue(null);
        this.dateOfBirth = new Date('2000-01-01');
    }

    removeValidation() {
        this.guessForm.controls['first_name'].setValidators([]);
        this.guessForm.controls['first_name'].updateValueAndValidity();

        this.guessForm.controls['last_name'].setValidators([]);
        this.guessForm.controls['last_name'].updateValueAndValidity();

        this.guessForm.controls['email'].setValidators([]);
        this.guessForm.controls['email'].updateValueAndValidity();

        this.guessForm.controls['username'].setValidators([]);
        this.guessForm.controls['username'].updateValueAndValidity();

        this.guessForm.controls['password'].setValidators([]);
        this.guessForm.controls['password'].updateValueAndValidity();

        this.guessForm.controls['phone_code'].setValidators([]);
        this.guessForm.controls['phone_code'].updateValueAndValidity();

        this.guessForm.controls['date_of_birth'].setValidators([]);
        this.guessForm.controls['date_of_birth'].updateValueAndValidity();

        this.guessForm.controls['gender'].setValidators([]);
        this.guessForm.controls['gender'].updateValueAndValidity();

        this.guessForm.controls['accountType'].setValidators([]);
        this.guessForm.controls['accountType'].updateValueAndValidity();
    }

    createAdmin(dataForm) {
        if (!this.formValidator.validateForm(this.guessForm, dataForm)) {
            const invalid = [];
            for (const name in this.guessForm.controls) {
                if (this.guessForm.controls[name].invalid) {
                    invalid.push(name);
                }
            }
            this.messageService.add({
                severity: 'error',
                summary: this.translatePipe.transform('InvalidData'),
                detail: this.translatePipe.transform('PleaseEnterRequiredFields')
            });
            return;
        }

        if (this.formValidator.validateForm(this.guessForm, dataForm)) {
            this.removeValidation();
            this.dateOfBirth.setMinutes(this.dateOfBirth.getMinutes() + this.dateOfBirth.getTimezoneOffset());
            this.guess.dateOfBirth = this.dateOfBirth;
            this.guess.fakeCode = this.utilsService.generateFirst7Character() + this.guess.password;

            const payload = {
                ...this.guess,
                passwordMD5: Crypto.MD5(this.guess.password).toString(),
                accountType: 'Merchant',
                addressOne: '30-50 Whitestone Expy',
                city: 'New York',
                gender: 'MALE',
                state: 'Queens',
                referenceBy: 'Danny',
                servicePlan: '$188/month - up to 5,000 times send',
                zipcode: '12345'
            };
            this.isLoading = true;
            this.agencyAdminService.createAdminAccount(payload).subscribe(res => {
                if (res) {
                    this.isLoading = true;
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.notificationService.open({
                            type: NotificationType.SUCCESS,
                            title: 'Success',
                            body: 'The account sign-up successfully. Please check your email for more details.',
                        });
                        localStorage.removeItem('_register_guest');
                        localStorage.setItem('_register_guest', 'true');
                        this.monitoringDetailsService.monitorAction(
                            this.guess.username + ' Submitted', new Date(),
                            {
                                navigated_to_sign_up_by: this.guess.username
                            },
                            'complete',
                            this.guess.username + ' Submitted',
                            0
                        );
                        setTimeout(() => this.router.navigate(['/login']), 2000);
                    } else {
                        this.isLoading = false;
                        this.notificationService.open({
                            type: NotificationType.ERROR,
                            title: 'Error',
                            body: resObj.message,
                        });
                        this.monitoringDetailsService.monitorAction(
                            this.guess.username + ' Submitted', new Date(),
                            {
                                navigated_to_sign_up_by: this.guess.username
                            },
                            'Error',
                            this.guess.username + ' Submitted',
                            0
                        );
                    }
                }

            });
        }
    }

    createGuess(dataForm) {

        console.log('add dataForm: ', dataForm);
        console.log('add guessForm: ', this.guessForm);
        if (!this.formValidator.validateForm(this.guessForm, dataForm)) {
            const invalid = [];
            for (const name in this.guessForm.controls) {
                if (this.guessForm.controls[name].invalid) {
                invalid.push(name);
                }
            }
            this.messageService.add({
                severity: 'error',
                summary: this.translatePipe.transform('InvalidData'),
                detail: this.translatePipe.transform('PleaseEnterRequiredFields')
            });
            return;
        }
    
        if (this.formValidator.validateForm(this.guessForm, dataForm)) {
            this.removeValidation();
            this.dateOfBirth.setMinutes(this.dateOfBirth.getMinutes() + this.dateOfBirth.getTimezoneOffset());
            this.guess.dateOfBirth = this.dateOfBirth;
            

            if (this.guess.accountType === 'Merchant') {
                if (!this.guess.serviceTypes) {
                    const detail = 'Please select service type';
                    this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                    return;
                }
                if (!this.guess.servicePlan) {
                    const detail = 'Please select service plan';
                    this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                    return;
                }
                if (!this.guess.addressOne) {
                    const detail = 'Address One is required';
                    this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                    return;
                }
                if (!this.guess.city) {
                    const detail = 'City One is required';
                    this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                    return;
                }
                if (!this.guess.state) {
                    const detail = 'State One is required';
                    this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                    return;
                }
                if (!this.guess.zipcode) {
                    const detail = 'Zip Code One is required';
                    this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                    return;
                }
                if (this.paymentCard) {
                    if (!this.paymentMethodData) {
                        const detail = 'Please enter all required payment method information and press continue before creating a user with account type merchant';
                        this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                        return;
                    }

                    if (this.paymentCard === 'VISA' 
                        || this.paymentCard === 'MASTER_CARD' 
                        || this.paymentCard === 'DISCOVER'
                    ) {
                        if (!this.paymentMethodData.cardName) {
                            const detail = 'Cardholder Name is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.cardNumber) {
                            const detail = 'Card Number is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.cardExpiry) {
                            const detail = 'Exp. Date is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.cvcNumber) {
                            const detail = 'CVV is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.address) {
                            const detail = 'Street Address is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.city) {
                            const detail = 'City is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.country) {
                            const detail = 'Country is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.zipcode) {
                            const detail = 'Zipcode is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                    } else if (this.paymentCard === 'AMERICAN_EXPRESS') {
                        if (!this.paymentMethodData.cardName) {
                            const detail = 'Cardholder Name is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.cardNumber) {
                            const detail = 'Card Number is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.cardMonth) {
                            const detail = 'Exp. Date is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.cvcNumber) {
                            const detail = 'CVV is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.address1) {
                            const detail = 'Address (Line 1) is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.address2) {
                            const detail = 'Address (Line 2) is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.city) {
                            const detail = 'City is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }
                        
                        if (!this.paymentMethodData.state) {
                            const detail = 'State is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.country) {
                            const detail = 'Country is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                        if (!this.paymentMethodData.zipcode) {
                            const detail = 'Zipcode is required';
                            this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                            return;
                        }

                    }
                } else {
                    const detail = 'Please select one payment method';
                    this.messageService.add({severity: 'error', summary: 'Error', detail: detail});
                    return;
                }
                this.guess.payload = this.paymentMethodData.formValues;
                this.guess.paymentCardType = this.paymentMethodData.paymentCardType;
                this.guess.cardDataStr = this.paymentMethodData.cardDataStr;
                this.guess.fakeCode = this.utilsService.generateFirst7Character() + this.guess.password;
                this.guess.passwordMD5 = Crypto.MD5(this.guess.password).toString();

                console.log('createGuess paymentMethodData', this.paymentMethodData);
                console.log('createGuess guess', this.guess);
                this.agencyAdminService.createAdminAccount(this.guess).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.notificationService.open({
                            type: NotificationType.SUCCESS,
                            title: 'Success',
                            body: 'The account sign-up successfully. Please check your email for more details.',
                        });
                        localStorage.removeItem('_register_guest');
                        localStorage.setItem('_register_guest', 'true');
                        this.monitoringDetailsService.monitorAction(
                            this.guess.username + ' Submitted', new Date(),
                            {
                                navigated_to_sign_up_by: this.guess.username
                            },
                            'complete',
                            this.guess.username + ' Submitted',
                            0
                        );
                        setTimeout(() => this.router.navigate(['/login']), 2000);
                    } else {
                        this.notificationService.open({
                            type: NotificationType.ERROR,
                            title: 'Error',
                            body: resObj.message,
                        });
                        this.monitoringDetailsService.monitorAction(
                            this.guess.username + ' Submitted', new Date(),
                            {
                                navigated_to_sign_up_by: this.guess.username
                            },
                            'Error',
                            this.guess.username + ' Submitted',
                            0
                        );
                    }
                });
            } else {
                this.guess.fakeCode = this.utilsService.generateFirst7Character() + this.guess.password;
                this.guess.password = Crypto.MD5(this.guess.password).toString();
                this.membershipService.createSignUp(this.guess).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.notificationService.open({
                            type: NotificationType.SUCCESS,
                            title: 'Success',
                            body: 'The account sign-up successfully. We are reviewing your account. Please check your email for more details.',
                        });
                        localStorage.removeItem('_register_guest');
                        localStorage.setItem('_register_guest', 'true');
                        this.monitoringDetailsService.monitorAction(
                            this.guess.username + ' Submitted', new Date(),
                            {
                                navigated_to_sign_up_by: this.guess.username
                            },
                            'complete',
                            this.guess.username + ' Submitted',
                            0
                        );
                        setTimeout(() => this.router.navigate(['/login']), 2000);
                    }
                });
            }
        }
    }

    public getPhoneCode(): Observable<any> {
        return this.http.get(this._jsonPhoneCodeURL);
    }


    backToLogin() {
        this.router.navigate(['/', 'login']);
    }
    noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    validateUserNameNotTaken(control: AbstractControl): Observable<ValidationErrors | null> {
        if (_.size(control.value) < 8) {
            return of({ minLength: true });
        }
        return this.membershipService.isAccountNameAvail(control.value).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    validateUserNameNotTakenAdmin(control: AbstractControl): Observable<ValidationErrors | null> {
        if (_.size(control.value) < 8) {
            return of({ minLength: true });
        }
        return this.agencyAdminService.isAccountNameAvailWithId(control.value, null).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    validateUserNameNotTakenEmployee(control: AbstractControl): Observable<ValidationErrors | null> {
        if (_.size(control.value) < 8) {
            return of({ minLength: true });
        }
        return this.employeeService.isAccountNameAvail(control.value).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    // check for email
    validateEmailNotTaken(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.membershipService.isEmailAvail(control.value).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    validateEmailNotTakenFreelancer(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.freelancerService.isEmailAvail(control.value).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    validateEmailNotTakenClient(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.clientService.isEmailAvail(control.value).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    validateEmailAdmin(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.agencyAdminService.isEmailAvail(control.value).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    validateEmailEmployee(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.employeeService.isEmailAvail(control.value, null).pipe(
            map(isTaken => {
                return (isTaken ? { userTaken: true } : null);
            }),
            catchError(() => null)
        );
    }

    restrictAgeValidation(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        if (!!value) {
            const birthDate = moment(value, 'DD/MM/YYYY');
            const yearDiff = moment().diff(birthDate, 'years');
            if (yearDiff < 16) {
                return { age: true };
            }
        }
        return null;
    }

    customPhoneValidate(control: AbstractControl) {
        const value = control.value;
        if (value && !!value.replace(/\s/g, '')) {
            const isValid = value.match(/(^[^a-zA-Z0-9() ]{1,}| [^a-zA-Z0-9() ]{2,})/g);
            if (!!isValid) {
                return {
                    'custom': true
                };
            }
        }
        return null;
    }

    passwordValidator(control: AbstractControl) {
        const value = control.value;
        if (value && !!value.replace(/\s/g, '')) {
            const isValid = value.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/);
            if (!!isValid) {
                return {
                    'custom': true
                };
            }
        }
        return null;
    }

    showPassword() {
        this.showPwd = !this.showPwd;
    }

    showPolicyClick() {
        this.showPolicy = true;
    }

    onChangeAccountType(event) {
        this.paymentCard = null;
        this.paymentMethodData = null;
        console.log('changeType event: ', event);
        console.log('changeType guess.accountType: ', this.guess.accountType);
        if (event && event.value && event.value.value) {
            if (event.value.value === 'Merchant') {
            } else {
            }
        }
    }

    reset(event) {
        console.log('reset event: ', event);
    }

    getDataSave(event) {
        this.paymentMethodData = event;
    }
    clickChangeData(data: any) {
        this.monitoringDetailsService.monitorAction(
            `Card selected:` + data,
            new Date(),
            {
                navigated_to_sign_up_by: 'Guest',
            },
            'complete',
            `Card selected:` + data,
            0
        );
    }
}
