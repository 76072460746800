<div class="ui-g" *ngIf="fundRaiserWarningMessage">
    <div class="ui-g-12 p-0" style="margin-top: 20px">
        <div class="alert alert-danger alert-dismissible" role="alert">
            <p>{{fundRaiserWarningMessage}}</p>
        </div>
    </div>
</div>

<div class="card add-shadow p-4" *ngIf="!isCheckout">
    <div>
        <h1 class="m-0 my-3"><b>{{candidateName}}</b></h1>
        <p>{{fundraisingTitle}}</p>
    </div>
    <div>
        <img *ngIf="selectData.profilePhotoUrl" style="cursor: pointer"
             width="225" height="150" src="{{selectData.profilePhotoUrl}}" (click)="openProfilePhoto()"/>
    </div>
    <br>
    <div *ngIf="isShowDetailEmployee">
        <h4 class="m-0 my-3"><b>{{'Please enter the following details: ' | translate}}</b></h4>
        <i>{{'All field are required unless otherwise noted. ' | translate}}</i>
    </div>
    <div *ngIf="!isShowDetailEmployee">
        <h4 class="m-0 my-3"><b>{{'How much would you like to contribute ?' | translate}}</b></h4>
    </div>
    <div class="card card-w-title p-0 border-0 m-0">
        <form [formGroup]="contributeForm">
            <div *ngIf="!isShowDetailEmployee">
                <div class="ui-g-12 ui-md-12 pb-3" >
                    <div class="ui-g-4 ui-md-4 pb-3" *ngFor="let item of listRaisedAmount">
                        <button *ngIf="item.lable !== 'Other'" (click)="changeRaisedAmount(item)" type="button" class="btn btn-light" style="width: 100%" ><span style="color: tomato"><b>${{item.lable}}</b></span></button>
                        <button *ngIf="item.lable === 'Other'" (click) = "showDialog()" type="button" class="btn btn-light" style="width: 100%" ><span style="color: tomato"><b>{{item.lable}}</b></span></button>
                    </div>
                </div>

                <div class="ui-g-12 ui-md-12 pb-3" style="background-color: #CCFFFF" >
                    <span class="ui-g-12 ui-md-12">NYC resident? With matching funds, your <ins>{{selectRaisedAmount}}</ins></span>
                    <span class="ui-g-12 ui-md-12">contribution can be worth <ins>{{selectRaisedAmount}}</ins>.</span>
                </div>
            </div>
            <div class="ui-g-12 ui-md-12 pb-3" *ngIf="isShowDetailEmployee" >
                <div class="ui-g-12">
                    <label class="font-weight-bold" *ngIf="authService.getUserInfo()">{{content.employer}}</label>
                    <ng-container *ngIf="!authService.getUserInfo()">
                        <label class="font-weight-bold">Employer<span class="required-indicator">*</span>:</label>
                        <input class="w-100" type="text" pInputText formControlName="employer" [(ngModel)]="content.employer"
                               placeholder="{{'Employer' | translate}}">
                    </ng-container>
                </div>

                <div class="ui-g-12">
                    <label>{{'Occupation' | translate}}<span class="required-indicator">*</span>:</label>
                    <input class="w-100" type="text" pInputText formControlName="occupation" [(ngModel)]="content.occupation"
                           placeholder="{{'Occupation' | translate}}">
                </div>

                <div class="ui-g-12">
                    <label>{{'Business Street Address' | translate}}<span class="required-indicator">*</span>:</label>
                    <input class="w-100" type="text" pInputText formControlName="address" [(ngModel)]="content.address"
                           placeholder="{{'Business Street Address' | translate}}">
                </div>

                <div class="ui-g-12">
                    <label>{{'Apt/Suite/Floor(optional)' | translate}}:</label>
                    <input class="w-100" type="text" pInputText formControlName="aptSuiteFloor" [(ngModel)]="content.aptSuiteFloor"
                           placeholder="{{'Apt/Suite/Floor' | translate}}">
                </div>

                <div class="ui-g-12">
                    <label>{{'City' | translate}}<span class="required-indicator">*</span>:</label>
                    <input class="w-100" type="text" pInputText formControlName="city" [(ngModel)]="content.city"
                           placeholder="{{'City' | translate}}">
                </div>

                <div class="ui-g-12">
                    <label>{{'State' | translate}}<span class="required-indicator">*</span>:</label>
                    <input class="w-100" type="text" pInputText formControlName="state" [(ngModel)]="content.state"
                           placeholder="{{'State' | translate}}">
                </div>

                <div class="ui-g-12">
                    <label>{{'ZIP Code' | translate}}<span class="required-indicator">*</span>:</label>
                    <input class="w-100" type="text" pInputText formControlName="zipCode" [(ngModel)]="content.zipCode" (keypress)="isNumber($event)" maxlength="5"
                           placeholder="{{'ZIP' | translate}}">
                </div>
            </div>
        </form>
        <div class="ui-g-12 ui-md-12 pb-3" >
            <div class="ui-g-6 ui-md-6 pb-3" style="text-align: left">
                <button type="button" class="btn btn-orange" *ngIf="isShowDetailEmployee" (click)="isShowDetailEmployee = false" >{{'Back' | translate}}</button>
            </div>
            <div class="ui-g-6 ui-md-6 pb-3" style="text-align: right" >
                <!--			<button pButton class="w-auto btn-warning"  label="{{'Next' | translate}}" style="margin-right: 4px;"></button>-->
                <button  type="button" class="btn btn-orange" [disabled]="selectRaisedAmount === '$0.00' || !selectRaisedAmount || fundRaiserWarningMessage" *ngIf="!isShowDetailEmployee" (click)="showDetail()">{{'Next' | translate}}</button>
                <button  type="button" class="btn btn-orange" *ngIf="isShowDetailEmployee" [disabled]="sumbitClicked"   (click)="saveFundraisersDetail(contributeForm)" >{{'Next' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="isShowOther" modal="modal" showEffect="fade" width="600" [closable]="false">
    <div class="ui-g pr-5 mt-3">
        <div class="ui-g-12 p-0">
            <div class="ui-g">
                <div class="ui-g-4 py-1 text-right">
                    <label>{{'Amount' | translate}}:</label>
                </div>
                <div class="ui-g-8 px-0 py-1">
                    <input pInputText class="w-90" [(ngModel)]="selectRaisedAmount" name="selectRaisedAmount" twoDigitDecimaNumber (keypress)="isNumber($event)" (blur)="formatData()"/>
                </div>
            </div>
        </div>
    </div>
    <div class="ui-g mt-3">
        <div class="ui-g-12 text-center">
            <button pButton type="submit" label="{{'Ok' | translate}}"
                    (click)="changeOtherPrice()"
                    [disabled]="selectRaisedAmount === '$0.00' || !selectRaisedAmount"></button>
        </div>
    </div>
</p-dialog>

<app-fundraisers-checkout *ngIf="isCheckout"
                          [content]="content"
                          [selectData]="selectData"
                          (back)="back($event)"
                          (addFundraisersDetail)="addFundraisersDetail()"
></app-fundraisers-checkout>


