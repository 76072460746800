import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MenuItem, Message, MessageService } from 'primeng/api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { DepartmentService } from '../../../department/service/department.service';
import { Employee } from '../../../employee/model/employee.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { EmployeeClockingService } from '../../service/employee-clocking.service';
import { EmployeeClocking } from '../../model/employee-clocking.model';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import * as MOMENT from 'moment';
import * as moment from 'moment';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { CompanyService } from '../../../company/service/company.service';
import { AgencyService } from '../../../agency/service';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../agency/model';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import { NoteHistoryService } from '../../../crm/service/note-history.service';
import { Constants } from '../../../shared/model/constants';
import { EditedClockingBeyondSettings } from '../../model/edited.clocking-beyond-settings.model';
import { EmployeeService } from "../../../employee/service/employee.service";
import { PayrollSetting } from '../../../payroll-setting/model/payroll-setting.model';
import { bool } from 'aws-sdk/clients/signer';
import {DropDownsService} from "../../../shared/service/drop-downs/drop-downs.service";
import {TranslatePipe} from '@ngx-translate/core';
import {EmployeeClockingServiceV2} from '../../service/v2/employee.clocking.v2.service';

declare let $: any;

@Component({
    selector: 'app-employee-punch-confirmation',
    templateUrl: './employee-punch-confirmation.component.html',
    styleUrls: ['./employee-punch-confirmation.component.scss'],
    providers: [EmployeeClockingService, WindowRefService, PayrollSettingService, MessageService]
})
export class EmployeePunchConfirmationComponent implements OnInit {
    totalRecords: Number = 0;
    msgs: Message[] = [];
    loading = false;
    employeeList: Array<Employee> = [];
    filteredEmployeeList: Array<Employee> = [];
    @ViewChild('dt', {static: true}) table: Table;
    companies = [];

    selectedCompany: any = 0;
    selectApplication: any;
    selectEmployee: any;
    showViewFileDialog = false;
    fileUrl: any;
    optionsApplication = [
        { label: 'Web', value: 'Web' },
        { label: 'IOS Mobile', value: 'IOS Mobile'},
        { label: 'Android Mobile', value: 'Android Mobile'},
    ];

    textSortOptionsClient = [
        { name: 'Sort A To Z', value: 'ASC', img: 'a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'z-a' }
    ];
    numberSortOptionsClient = [
        { name: 'Sort 0 To9', value: 'ASC', img: 'a-z' },
        { name: 'Sort 9 To 0', value: 'DESC', img: 'z-a' }
    ];

    dateSortOptions = [
        { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    selectedSortOption = '';

    employeeConfirmation: any = [];
    invalidDates: MOMENT.Moment[] = [MOMENT().add(2, 'days'), MOMENT().add(3, 'days'), MOMENT().add(5, 'days')];
    isInvalidDate = (m: MOMENT.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }
    isPlatformAdmin: boolean = false;
    adminData;
    selectedField;
    fromDate;
    toDate;
    employeeIds = [];
    constructor(private router: Router, private route: ActivatedRoute,
        private employeeService: EmployeeService,
        private employeeClockingServiceV2: EmployeeClockingServiceV2,
        private employeeClockingService: EmployeeClockingService,
        private payrollSettingService: PayrollSettingService,
        private noteHistoryService: NoteHistoryService,
        private windowRef: WindowRefService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private agencyService: AgencyService,
        private datePipe: DatePipe,
        private dropdownService: DropDownsService,
        private departmentService: DepartmentService,
        private authService: AuthService,
                private translatePipe: TranslatePipe) { }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.adminData = JSON.parse(localStorage.getItem('_globals'));

        this.selectedCompany = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany().id : null;

        // this.getAllEmployees();
        this.dropdownService.getAllCompanyList().subscribe(
        res => {
            const responseObject = res;
            const tempCompanyList = [];
            if (responseObject.status === 'SUCCESS') {
                responseObject.data.forEach(element => {
                    tempCompanyList.push({ label: element.value, value: element.key });
                });
                this.companies = tempCompanyList;
            }
        });
        this.loadPage();
        this.loadEmployeeList(null);
    }
    onSearch() {
        this.msgs = [];
        this.loadPage();
    }

    onReset() {
        this.selectedCompany = null;
    }

    async loadPage() {
        this.loading = true;
        const  options = {
            companyId: null,
            employeeId: null,
            fromDate: null,
            toDate: null,
            application: null,
            size : 9999,
            page : 0
        };
        if (this.fromDate && this.toDate) {
            options.fromDate = this.fromDate;
            options.toDate = this.toDate;
        }
        if (this.selectedCompany) {
            options.companyId = this.selectedCompany;
        }
        if (this.selectApplication) {
            options.application = this.selectApplication;
        }
        if (this.selectEmployee) {
            options.employeeId = this.selectEmployee;
        }
        const resObj: any = await this.employeeClockingServiceV2.searchPunchConfirmation(options).toPromise();
        if (resObj.status === 'SUCCESS') {
            this.loading = false;
            this.employeeConfirmation = resObj.data.content;
            this.totalRecords = resObj.data.content.length;
        }
    }

    search(event) {
        this.loading = true;
        this.table.first = 0;
        this.loadPage();
    }
    getAllEmployees() {
        this.employeeClockingService.getEmployeeList().subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employeeList = resObj.data;
            }
        });
    }

    mapToDropdown(label, val) {
        return {
            label: label,
            value: val
        };
    }

    getAllEmployeesByCompany() {
        this.loadEmployeeList(this.selectedCompany);
    }

    sortingClick(selectedSortOption) {
        this.employeeConfirmation.sort(this.compare(this.selectedField.split('.').reverse()[0], selectedSortOption));
    }
    compare(selected, sortBy) {
        return function (a, b) {
            if (selected === 'completeHours' && sortBy === 'ASC') {
                return (parseInt(a[selected])) - (parseInt(b[selected]));
            }
            if (selected === 'completeHours' && sortBy === 'DESC') {
                return (parseInt(b[selected])) - (parseInt(a[selected]));
            }
            if (selected === 'completeHoursInWeak' && sortBy === 'ASC') {
                return (parseInt(a[selected])) - (parseInt(b[selected]));
            }
            if (selected === 'completeHoursInWeak' && sortBy === 'DESC') {
                return (parseInt(b[selected])) - (parseInt(a[selected]));
            }
            if (selected === 'weeklyHours' && sortBy === 'ASC') {
                return (parseInt(a.emp.employee[selected])) - (parseInt(b.emp.employee[selected]));
            }
            if (selected === 'weeklyHours' && sortBy === 'DESC') {
                return (parseInt(b.emp.employee[selected])) - (parseInt(a.emp.employee[selected]));
            }
            if (sortBy === 'ASC') {
                return (a.emp.employee[selected]).toLowerCase() > (b.emp.employee[selected]).toLowerCase() ? 1 : -1;
            }
            if (sortBy === 'DESC') {
                return (a.emp.employee[selected]).toLowerCase() > (b.emp.employee[selected]).toLowerCase() ? -1 : 1;
            }
        }
    }

    loadEmployeeList(company) {
        this.employeeList = [];
        const searchOption = <any>{};
        if (company != null) {
            searchOption.companyId = company;
        } else {
            searchOption.companyId = this.authService.getCurrentCompanyId();
        }
        searchOption.status = 1;
        this.employeeService.getAllsDropdown(searchOption).subscribe(resEmp => {
            const empObj: any = resEmp;
            const tempList: any[] = [];
            if (empObj.status === 'SUCCESS') {
                empObj.data.forEach(element => {
                    tempList.push({ label: element.fullName, value: element.key });
                });
                tempList.sort((a, b) => a.label.localeCompare(b.label));
            }
            this.employeeList = tempList;
        });
    }
    viewSignatureDialog(rowData) {
        this.showViewFileDialog = true;
        this.fileUrl = rowData.signatureUrl;
    }

}
