import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Message, MessageService} from 'primeng/api';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {FormValidatorService} from '../../../shared/service/form-validator/form-validator.service';
import {PayrollSettingService} from '../../service/payroll-setting.service';
import {Mileage, PayrollSetting} from '../../model/payroll-setting.model';
import {PayrollHolidayService} from '../../service/payroll-holiday.service';
import {DepartmentService} from '../../../department/service/department.service';
import {AgencyHolidayService, AgencyService} from '../../../agency/service';
import {AgencyHoliday} from '../../../agency/model';
import {NGDropdownModel, PayrollModelUtility} from '../../utility/model.utility';
import {PositionService} from '../../../position/service/position.service';
import {Position} from '../../../position/model/position.model';
import {Constants} from '../../../shared/model/constants';
import {CurrencyPipe} from '@angular/common';
import {Department} from '../../../department/model/department.model';
import {PayrollHolidayV2} from '../../model/payroll-holiday-v2.model';
import {Observable} from 'rxjs';
import {SpreadHourSetting} from '../../../company/model/v2/spread-hour.model';
import {HttpClient} from '@angular/common/http';
import {SpreadHourSettingServiceV2} from '../../../company/service/spread-hour-setting.v2.service';
import {TranslatePipe} from '@ngx-translate/core';
import {PayrollSettingOtherHistoryComponent} from '../payroll-setting-other-history/payroll-setting-other-history.component';
import {AutoGenerateInvoiceSettingsService} from '../../service/auto-generate-invoice-settings.service';
import {AutoGenerateInvoiceSettings} from '../../model/auto-generate-invoice-settings.model';
import {ProductService} from '../../../crm/service/product.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';

@Component({
    selector: 'app-payroll-setting-form-new',
    templateUrl: './payroll-setting-form-new.component.html',
    styleUrls: ['./payroll-setting-form-new.component.scss'],
    providers: [AuthService, FormValidatorService, PayrollSettingService, PayrollHolidayService,
        DepartmentService, PositionService, CurrencyPipe, SpreadHourSettingServiceV2]
})
export class PayrollSettingFormNewComponent implements OnInit, AfterViewInit {
    payrollSettingId: number;
    payrollSettingFormData: PayrollSetting;
    payrollSetting: PayrollSetting;
    payrollSettingsForm: UntypedFormGroup;
    displayEmployeeNameOnSub: boolean;
    clockOnDayOff: boolean;
    checkLunchTimeLimit: boolean;
    payrollHolidays: PayrollHolidayV2[] = [];
    holidayTypes: any[] = [];
    @Input() mode: string;
    @Input()
    readOnly = false;
    @Input() settingsForm: ElementRef;
    @Input()
    companyId: number;
    days: Array<any> = [{ label: 'Sun', value: 'SUNDAY' }, { label: 'Mon', value: 'MONDAY' }, { label: 'Tue', value: 'TUESDAY' }, { label: 'Wed', value: 'WEDNESDAY' }, { label: 'Thur', value: 'THURSDAY' }, {
        label: 'Fri',
        value: 'FRIDAY'
    }, { label: 'Sat', value: 'SATURDAY' }];
    msgs: Message[];
    totalRecords: Number = 0;
    displayHoliday = false;
    holiday: AgencyHoliday;
    holidays: AgencyHoliday[] = [];
    dropDownPositions: NGDropdownModel[] = [];
    listSpreadHour: SpreadHourSetting[];
    spreadHour: SpreadHourSetting = new SpreadHourSetting(null, null, null);
    minimumWage: any;
    listOfStateValue: [];
    calMethodValue: string;
    listOfExistingState: any[] = [];
    listOfStateTemp: any[] = [];
    length: any;
    allowEmployeePTOs: any[] = [];
    selectedSentToAlerts: any[] = [];
    roundOn: any = [];
    listOfState: any = [];
    calculateMethod: any = [];
    private _jsonIpURL = 'assets/us-states.json';
    mins: any = [];
    size: number;
    planAssignmentListOption: any[];
    page: number;
    disableOvertime = true;
    frequencyDropdown: any[];
    historyShow = false;
    historyOtherShow = false;
    historyType = '';
    allowEmployeePTOStr: String;
    positions: Position[];
    departments: Department[] = [];
    vacationMethodOptions: any[];
    isOtherOpen = false;
    isHolidayOpen = false;
    holidayPayType: any[];
    holidayPay: any;
    payReferences: any;
    showTakeOvertime: any;
    isOvertimeOpen = false;
    mileage: Mileage;
    listOfUsedStates: any[] = [];
    takeHourBackOption: any[] = [];
    isEditAble: Boolean = false;
    employeeLatenessDecisionOption = [
        { label: 'Clock Will be available in one hour', value: 1 },
        { label: 'After surpass the limitation # of lateness then display message "Please Contact your Manager/HR"', value: 2 },
        { label: 'No Restriction', value: 3 },
        { label: 'Remind manager/CEO on Dashboard for reaching limitation', value: 4},
        { label: 'Other', value: 5}
    ];
    hoursDaysOption = [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4},
    ];
    perWeekMonthYearOption = [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4},
    ];
    jobReportVsEndWorkDropdown = [
        { label: 'Can End Work with No Job Report', value: 'CAN_END_WORK_WITH_NO_JOB_REPORT' },
        { label: 'User Must add job Report', value: 'USER_MUST_ADD_JOB_REPORT' },
    ];
    useOvertimeOptions: any;
    @ViewChild(PayrollSettingOtherHistoryComponent)
    private historyOtherCompo: PayrollSettingOtherHistoryComponent;

    punchSettingDialog = false;
    punchDailyRate: number;
    punchTrackingRate: number;

    limitationDecisionLst = [];
    showLateRules = false;
    showEarlyRules = false;
    showLate = false;
    showEarly = false;
    autoGenerateInvoice: any = '0';
    autoGenerateInvoiceSettings: any = [];
    autoGenerateInvoiceOptions = [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
    ];
    frequencyOptions = [
        { label: 'Weekly', value: '1' },
        { label: 'Bi-weekly', value: '2' },
        { label: 'Monthly', value: '3' },
        { label: 'Yearly', value: '4' },
    ];
    productOptions: any = [];

    dayOfWeekOptions = [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' }
    ];

    employeeTimeConfirmationOptions = [
        { label: 'Confirm Weekly clock in/out time', value: '1' },
        { label: 'Not Required', value: '0' }
    ];

    dayOfMonthOptions
    
    invoiceTypeOptions: any[] = [
        { label: 'Billing', value: 'Billing' },
        { label: 'Dispatch', value: 'Dispatch' },
        { label: 'Operrwork SaaS', value: 'Operrwork SaaS' }
    ];
    constructor(
        private currencyPipe: CurrencyPipe,
        private authService: AuthService,
        private agencyService: AgencyService,
        private positionService: PositionService,
        private router: Router,
        private route: ActivatedRoute,
        private formValidator: FormValidatorService,
        private departmentService: DepartmentService,
        private agencyHolidayService: AgencyHolidayService,
        private payrollSettingService: PayrollSettingService,
        private payrollHolidayService: PayrollHolidayService,
        private http: HttpClient,
        private spreadHourSettingServiceV2: SpreadHourSettingServiceV2,
        private messageService: MessageService,
        private translatePipe: TranslatePipe,
        private autoGenerateInvoiceSettingsService: AutoGenerateInvoiceSettingsService,
        private productService: ProductService,
        private monitoringDetailsService: MonitoringDetailsService,
    ) {
        const dayOfMonthOptions = [];
        const getOrdinalSuffix = day => {
            if (day >= 11 && day <= 13) {
                return 'th';
            }
            const lastDigit = day % 10;
            switch (lastDigit) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        };
        for(let i = 1; i <= 30; i++) {
            dayOfMonthOptions.push({ label: i + getOrdinalSuffix(i), value: i});
        }
        this.dayOfMonthOptions = dayOfMonthOptions;
    }

    collapsedList = [];
    ngOnInit() {
        this.collapsedList = localStorage.getItem('collapsedList') ? localStorage.getItem('collapsedList').split(',') : [];
        if (!(this.collapsedList || []).length) {
            this.collapsedList = [false, true, true, true, true, true, true, true, true, true, false];
        } else {
            this.collapsedList = this.collapsedList.map(item => item === 'true');
        }

        this.limitationDecisionLst = [
            { label: 'After surpass the limitation # of Missing Job report then display message "Please Contact your Manager/HR"', value: 1},
            { label: 'Remind manager/CEO on Dashboard for reaching limitation', value: 2}
        ]
        this.useOvertimeOptions = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' }
          ];

        this.payReferences = [
            {label: 'Weekly', value: 'Weekly'},
            {label: 'Bi-Weekly', value: 'BIWEEKLY'}
          ];
        this.planAssignmentListOption = [
            { value: 'OPTIONAL', label: 'Can End Work with or no Plan/Assignment' },
            { value: 'FORCE', label: 'Must add Plan/Assignment to be able to End Work' },
        ];

        this.holidayPayType = [{ value: 'HOURLY', label: 'Hourly' },
        { value: 'SALARY', label: 'Salary' }];
        this.frequencyDropdown = [
            { value: 'Daily', label: 'Daily' },
            { value: 'Weekly', label: 'Weekly' },
            { value: 'Monthly', label: 'Monthly' }
        ];

        this.vacationMethodOptions = [
            { label: 'January1stToDecember31st', value: 'YEARLY' },
            { label: 'FromEmployeeStartDate', value: 'EMP_START_DATE' },

        ];

        this.calculateMethod = [
            { value: 'INF_OR_EQU', label: 'InferiorOrEqual' },
            { value: 'EQU_ONL', label: 'EqualOnly' },
            { value: 'CUSTOMIZE', label: 'Customize' }
        ];

        this.roundOn = [
            { value: 'CLOSEST', label: 'Closest' },
            { value: 'UP', label: 'Up' },
            { value: 'DOWN', label: 'Down' }
        ];

        this.mins = [
            { value: 15, label: '15' },
            { value: 30, label: '30' },
            { value: 45, label: '45' },
            { value: 60, label: '60' }
        ];

        this.takeHourBackOption = [{ label: 'Last Week', value: 'LAST_WEEK' },
        { label: 'Last Two Weeks', value: 'LAST_TWO_WEEKS' },
        { label: 'Last Three Weeks', value: 'LAST_THREE_WEEKS' },
        { label: 'Last Four Weeks', value: 'LAST_FOUR_WEEKS' },
        { label: 'Continuously', value: 'CONTINUOUSLY' }];
        this.mileage = new Mileage();
        this.positionService.findAllForSelection({}).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.positions = resObj.data;
            }
        });

        this.departmentService.getAllByAgency(this.companyId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.departments = resObj.data;
            }
        });
        this.getAllSpreadHourById();
        this.allowEmployeePTOs = PayrollModelUtility.getConditionalData();
        this.payrollHolidays = [];
        this.payrollSettingFormData = new PayrollSetting();
        this.setFormGroup();
        this.getPositions();
        this.getSettings(this.companyId);
        this.isEditAble = this.authService.isEmployeeRole() ? true :
        this.isSuperAdmin() || this.isCompanyAdmin();
        this.msgs = [];
        this.getProducts();
        this.getAllAutoGenerateInvoiceSettings();
    }
    onOvertimeChange(event) {
        if(event.value === '1') {
          this.setDisable(true);
        } else {
          this.setDisable(false);
        }
      }
    ngAfterViewInit() {
    }

    formatMoney(value) {
        const temp = `${value}`.replace(/\,/g, '');
        return this.currencyPipe.transform(temp).replace('$', '');
    }
    setDisable(event: boolean) {
        this.disableOvertime = event;
    }
    loadPayrollHolidayLazy(event?: any) {
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        this.loadPage({ page: this.page, size: this.size });
        // if (this.mode === 'update') {
        //
        //     this.payrollHolidays = JSON.parse(localStorage.getItem('_payrollHolidays'));
        //     this.payrollSettingFormData.payrollHolidays = this.payrollHolidays;
        //     this.totalRecords = JSON.parse(localStorage.getItem('_totalRecords'));
        // }
        // if (this.mode === 'view') {
        //     this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        //     this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        //     this.loadPage({ page: this.page, size: this.size });
        // }

    }
    viewPayroll() {
        this.router.navigate(['app/company/payroll/view', this.companyId]);
    }

    settingPayroll() {
        if (!this.isEditAble) {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('message.permission') });
            return;
        }
        this.msgs = [];
        localStorage.setItem('_payrollHolidays', JSON.stringify(this.payrollSettingFormData.payrollHolidays));
        localStorage.setItem('_totalRecords', JSON.stringify(this.totalRecords));
        this.router.navigate(['app/company', 'payroll', this.companyId]);
    }
    onCancel() {
        const payrollHolidaysString =  localStorage.getItem('_payrollHolidaysCache');
        if (payrollHolidaysString) {
            const payrollHoliday = JSON.parse(payrollHolidaysString);
            const ids = [];
            payrollHoliday.forEach(item => {
                ids.push(item.id);
            });
            this.payrollHolidayService.deleteAll(ids).subscribe((_response) => {
            }, (error) => {console.log(error); });
            localStorage.removeItem('_payrollHolidaysCache');
            if (this.mode === Constants.FORM_MODE.UPDATE) {
                setTimeout(() => this.viewPayroll(), 2000);
            } else {
                const hasBranch = this.authService.isCurrentCompanyHasBranch();
                if (hasBranch) {
                    localStorage.removeItem('_payrollSetting');
                    localStorage.removeItem('_payrollHolidays');
                    localStorage.removeItem('_totalRecords');
                    this.router.navigate(['app/company']);
                }
            }
        }
    }
    loadPage(options) {
        this.payrollSettingService.getbyId(this.companyId).subscribe((res: any) => {
            const data = (res.data) ? res.data : new PayrollSetting();
            this.payrollSettingFormData = data;
            // if (this.payrollSettingFormData.mileage == null) {
            //     this.payrollSettingFormData.mileage = new Mileage();
            // }
            this.setData();

            this.displayEmployeeNameOnSub = this.payrollSettingFormData.displayEmployeeNameOnSub === 1 ? true : false;
            this.displayHoliday = true;
            if (this.payrollSettingFormData.useOvertime) {
                this.disableOvertime = true;
            } else {
                this.disableOvertime = false;
            }

            this.agencyHolidayService.readAllForDropDownList({ companyId: this.companyId }).subscribe(resHoliday => {
                const resObjHoliday: any = resHoliday;
                if (resObjHoliday.status === 'SUCCESS') {
                    this.holidays = resObjHoliday.data;

                }
            });
            this.payrollSettingFormData.payrollHolidays.forEach(item => {
                item.holidayName = item.holiday.holidayName;
                item.holidayDate = item.holiday.holidayDate;
            });
            // const resObj: any = res;
            // console.log('this.payrollSettingFormData', this.payrollSettingFormData.payrollHolidays);
            // const payrollHolidaysString =  localStorage.getItem('payrollHolidays');
            // if (payrollHolidaysString) {
            //     const payrollHoliday = JSON.parse(payrollHolidaysString);
            //     console.log('payrollHoliday localStorage', payrollHoliday);
            //     this.payrollSettingFormData.payrollHolidays.push(...payrollHoliday);
            //     console.log('this.payrollSettingFormData.payrollHolidays', this.payrollSettingFormData.payrollHolidays);
            // }
            // if (resObj.status === 'SUCCESS') {
            //     if (resObj.data) {
            //         this.payrollSetting = resObj.data;
            //         this.payrollHolidayService.getByPayrollSetting(this.payrollSetting.id, options).subscribe(res1 => {
            //             const res1Obj: any = res1;
            //             if (res1Obj.status === 'SUCCESS') {
            //                 this.payrollHolidays = res1Obj.data.content;
            //
            //                 // this.payrollHolidays.forEach(payrollHoliday => {
            //
            //                     // payrollHoliday.holiday = this.holidays.find(holiday => payrollHoliday.holidayId === holiday.id);
            //                     // if (payrollHoliday.positions.length === this.positions.length) {
            //                     //     payrollHoliday.positionNames = 'All';
            //                     // } else {
            //                     //     const positionNames = [];
            //                     //     payrollHoliday.positions.forEach(pos => {
            //                     //         positionNames.push(pos.positionName);
            //                     //     });
            //                     //     payrollHoliday.positionNames = positionNames.join(',');
            //                     // }
            //                     //
            //                     // if (payrollHoliday.departments.length === this.departments.length) {
            //                     //     payrollHoliday.departmentNames = 'All';
            //                     // } else {
            //                     //     const departmentNames = [];
            //                     //     payrollHoliday.departments.forEach(dep => {
            //                     //         departmentNames.push(dep.name);
            //                     //     });
            //                     //     payrollHoliday.departmentNames = departmentNames.join(',');
            //                     // }
            //                 // });
            //                 this.totalRecords = res1Obj.data.totalElements;
            //             }
            //         });
            //     }
            // }
        });

    }

    deletePayrollHistory(rowData: PayrollHolidayV2) {
        this.payrollHolidayService.delete(rowData.id).subscribe((_response) => {
            this.messageService.add({ severity: 'info', summary: 'Deleted', detail: 'Payroll Holiday Deleted successfully!' });
            this.loadPayrollHolidayLazy();
        }, (error) => {
            console.log(error);
        });
    }

    editPayrollHistory(rowData: PayrollHolidayV2) {
        this.router.navigate(['app/company/payroll', this.companyId, 'holiday', 'edit', rowData.id],
            { queryParams: { returnUrl: this.router.url } });
    }

    addPayrollHoliday() {
        this.router.navigate(['app/company/payroll', this.companyId, 'holiday', 'add'],
            { queryParams: { returnUrl: this.router.url } });
    }

    changeHistoryHandler(payrollSettingId, mergedObject) {
        const admin = this.authService.getUserInfo();
        console.log(mergedObject);
        const historyData = [];
        const fieldsName = ['payReference', 'mileagePrice', 'mile',
            'roundOn', 'mins', 'payrollWeekEnding', 'minimumHourDaily', 'useOvertime',
            'displayEmployeeNameOnSub', 'overtimeAfter', 'overtimeMultipler',
            'sickTimeCalculationHour', 'sickTimeCalculationForEvery',
            'vacationTimeCalculationHour', 'vacationTimeCalculationForEvery',
            'vacationMethod', 'maxSickLeave', 'maxVacation', 'delayOver', 'clockOnDayOff', 'checkLunchTimeLimit', 'lunchTimeLimit', 'invoiceTax', 'invoiceType'];
        fieldsName.forEach(element => {
            if (mergedObject[element] !== null && mergedObject[element] !== '') {
                const dataObj: any = {};
                dataObj.fieldName = element;
                dataObj.oldValue = '';
                if (element === 'vacationMethod') {
                    console.log(this.vacationMethodOptions);
                    const vacationMethod = this.vacationMethodOptions.find(method => method.value === mergedObject[element]);
                    if (vacationMethod) {
                        dataObj.newValue = vacationMethod.label;
                    }
                } else {
                    dataObj.newValue = mergedObject[element];
                }

                dataObj.action = 'Created';
                dataObj.userId = payrollSettingId;
                dataObj.payrollSettingId = payrollSettingId;
                dataObj.userName = admin.username;
                historyData.push(dataObj);
            }
        });
        console.log(historyData);
        const newData = { 'historyArrays': historyData };
        this.payrollSettingService.saveHistoryData(newData).subscribe((item: any) => {
            console.log(item);
        });
    }

    changeHistoryHandlerEdit(newClientData) {
        const admin = this.authService.getUserInfo();
        console.log(newClientData);
        this.payrollSettingService.getPayRollSettingbyId(this.companyId, newClientData.id).subscribe((item: any) => {
            const oldData = item.data;
            console.log(oldData);
            const historyData = [];
            const fieldsName = ['payReference', 'mileagePrice', 'mile',
                'roundOn', 'mins', 'payrollWeekEnding', 'minimumHourDaily', 'useOvertime',
                'displayEmployeeNameOnSub', 'overtimeAfter', 'overtimeMultipler',
                'sickTimeCalculationHour', 'sickTimeCalculationForEvery',
                'vacationTimeCalculationHour', 'vacationTimeCalculationForEvery',
                'vacationMethod', 'maxSickLeave', 'maxVacation', 'delayOver', 'clockOnDayOff', 'checkLunchTimeLimit', 'lunchTimeLimit', 'invoiceTax', 'invoiceType'];
            fieldsName.forEach(element => {
                if (newClientData[element] !== oldData[element]) {
                    const dataObj: any = {};
                    dataObj.fieldName = element;
                    if (element === 'date') {
                        dataObj.oldValue = oldData[element];
                        dataObj.newValue = new Date(newClientData[element]).getTime();
                    } else if (element === 'vacationMethod') {
                        dataObj.oldValue = this.vacationMethodOptions.find(method => method.value === oldData[element]).label;
                        dataObj.newValue = this.vacationMethodOptions.find(method => method.value === newClientData[element]).label;
                    } else {
                        dataObj.oldValue = oldData[element];
                        dataObj.newValue = newClientData[element];
                    }

                    if (dataObj.newValue.length === 0) {
                        dataObj.action = 'Deleted';
                    } else {
                        dataObj.action = 'Updated';
                    }

                    dataObj.payrollSettingId = newClientData.id;
                    dataObj.userName = admin.username;
                    historyData.push(dataObj);
                }
            });
            console.log(historyData);
            const newData = { 'historyArrays': historyData };
            this.payrollSettingService.saveHistoryData(newData).subscribe((item: any) => {
                console.log(item);
            });
        });
    }

    getHistory() {

        this.historyShow = true;
    }

    showFundraiserHistory() {
      this.historyShow = true;
      this.historyType = 'fundraiser';
    }

    convertTime(data: any) {

        if (this.allowEmployeePTOStr === 'yes') {
            data.allowEmployeePTO = true;
        } else {
            data.allowEmployeePTO = false;
        }

        if (this.showTakeOvertime === '1') {
            data.showTakeOvertime = true;
        } else {
            data.showTakeOvertime = false;
        }

        if (data.overtimeAfter) {
            const time = data.overtimeAfter.split(':');
            data.overtimeAfter = (parseInt(time[0], 10) * 60 + parseInt(time[1], 10)).toString();
        }

        if (data.lunchTimeDuration) {
            const time = data.lunchTimeDuration.split(':');
            data.lunchTimeDuration = (parseInt(time[0], 10) * 60 + parseInt(time[1], 10)).toString();
        }

        if (data.onTimeRate) {
            const time = data.onTimeRate.split(':');
            data.onTimeRate = (parseInt(time[0], 10) * 60 + parseInt(time[1], 10)).toString();
        }

        if (data.alertEmployeeEndTime) {
            const time = data.alertEmployeeEndTime.split(':');
            data.alertEmployeeEndTime = (parseInt(time[0], 10) * 60 + parseInt(time[1], 10)).toString();
        }

        if (data.employeeLateAfterHours) {
            const time = data.employeeLateAfterHours.split(':');
            data.employeeLateAfterHours = (parseInt(time[0], 10) * 60 + parseInt(time[1], 10)).toString();
        }

        if (data.employeeEarlyAfterHours) {
            const time = data.employeeEarlyAfterHours.split(':');
            data.employeeEarlyAfterHours = (parseInt(time[0], 10) * 60 + parseInt(time[1], 10)).toString();
        }


        if (data.minimumHourDaily) {
            const time = data.minimumHourDaily.split(':');
            data.minimumHourDaily = (parseInt(time[0], 10) * 60 + parseInt(time[1], 10)).toString();
        }
        if (data.maxSickLeave) {
            const time = data.maxSickLeave.split(':');
            data.maxSickLeave = (parseInt(time[0]) * 60 + parseInt(time[1])).toString();
        }
        if (data.hoursTakeBackWeekly) {
            const time = data.hoursTakeBackWeekly.split(':');
            data.hoursTakeBackWeekly = (parseInt(time[0]) * 60 + parseInt(time[1])).toString();
        }
        if (data.hoursTakeDaily) {
            const time = data.hoursTakeDaily.split(':');
            data.hoursTakeDaily = (parseInt(time[0]) * 60 + parseInt(time[1])).toString();
        }
        if (data.maxVacation) {
            const time = data.maxVacation.split(':');
            data.maxVacation = (parseInt(time[0]) * 60 + parseInt(time[1])).toString();
        }
        if (data.sickTimeCalculationForEvery) {
            const time = data.sickTimeCalculationForEvery.split(':');
            data.sickTimeCalculationForEvery = (parseInt(time[0]) * 60 + parseInt(time[1])).toString();
        }
        if (data.vacationTimeCalculationForEvery) {
            const time = data.vacationTimeCalculationForEvery.split(':');
            data.vacationTimeCalculationForEvery = (parseInt(time[0]) * 60 + parseInt(time[1])).toString();
        }
        if (data.lunchTimeLimit) {
            const time = data.lunchTimeLimit.split(':');
            data.lunchTimeLimit = (parseInt(time[0]) * 60 + parseInt(time[1])).toString();
        }
        
        if (data.useOvertime === '1') {
            data.useOvertime = true;
        } else {
            data.useOvertime = false;
        }

        data.holidayPay = {};
        // Build the holidayPay object per user selects
        if (this.holidayPay) {
            data.holidayPay.holidayPay = this.holidayPay;
        }
        if (this.holidayTypes) {
            data.holidayPay.holidayPayType = this.holidayTypes;
        }
    }

    modifyPayrollSetting(form) {
        if (this.maxFundraisersFee()) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Maximum Fundraisers Fee is 100',
          });
          return;
        }
        if (this.formValidator.validateForm(this.payrollSettingsForm, form)) {
            if (this.mileage) {
                this.payrollSettingFormData.mileage = this.mileage;
            }

            this.payrollSettingFormData.companyId = this.companyId;
            if (this.payrollSettingFormData.lateFee) {
                this.payrollSettingFormData.lateFee = Number(this.payrollSettingFormData.lateFee);
            }
            if (this.payrollSettingFormData.displayOnList && this.payrollSettingFormData.displayOnList.length > 0) {
                let dataDisplayOn = '';
                this.payrollSettingFormData.displayOnList.forEach((item , index) => {
                    dataDisplayOn += index > 0 ? ',' + item : item;
                });
                this.payrollSettingFormData.displayOn = dataDisplayOn;
            };

            const data = { ...this.payrollSettingFormData };

            if (!data.isTakeHourBack) {
                data.takeHourBack = null;
            } else {
                data.takeHourBack = data.takeHourBack ?? this.takeHourBackOption[0].value;
            }
            if ( data.lunchTimeLimit) {
              const time = data.lunchTimeLimit.split(':');
              const lunchTimeLimit = (parseInt(time[0]) * 60 + parseInt(time[1]));
              if (lunchTimeLimit < 30) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Lunch Time Limit must be configured to be greater than or equal to 30 Minutes',
                });
                return;
              }
            }
            
            this.convertTime(data);
            data.donationLimit = data.donationLimit ? parseFloat(data.donationLimit) : null;
            data.fundraisersFee = data.fundraisersFee ? parseFloat(data.fundraisersFee) : null;
            data.fundraisingMaxLimitation = data.fundraisingMaxLimitation ? parseFloat(data.fundraisingMaxLimitation) : null;
            if (this.autoGenerateInvoice === '0') {
                this.autoGenerateInvoiceSettings.forEach(i => {
                    this.deleteAutoGenerateInvoiceSettings(i.companyId, i.id, true);
                });
            } else {
                this.autoGenerateInvoiceSettingsService.saveAll(this.authService.getCurrentCompanyId(), this.autoGenerateInvoiceSettings).subscribe(res => {
                    // console.log(res);
                });
            }
            
            localStorage.setItem('_payrollSetting', JSON.stringify(data));
            if (data.id) {
                data.lastModifiedBy = this.authService.getUserInfo().username;
                // this.changeHistoryHandlerEdit(data);
                this.payrollSettingService.updatePayrollSetting(this.companyId, data, data.id).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        localStorage.removeItem('_payrollHolidaysCache');
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('PayrollSettingsHaveBeenUpdated')
                        });
                        setTimeout(() => this.viewPayroll(), 2000);
                    }
                });
            } else {
                data.createdByUsr = this.authService.getUserInfo().username;
                this.payrollSettingService.createPayrollSetting(this.companyId, data).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        localStorage.removeItem('_payrollHolidaysCache');
                        this.changeHistoryHandler(resObj.data.id, data);
                        this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('PayrollSettingsHaveBeenUpdated') });
                        setTimeout(() => this.viewPayroll(), 2000);
                    }
                });
            }
        }
    }

    EmployeeNameOnSub(checked: boolean) {
        this.payrollSettingFormData.displayEmployeeNameOnSub = checked === true ? 1 : 0;
    }

    changeClockOnDayOff(event: any) {
        console.log(event)
        if (event.checked) {
            this.payrollSettingFormData.clockOnDayOff = 1;
        } else {
            this.payrollSettingFormData.clockOnDayOff = 0;
        }
        
    }

    changeCheckLunchTimeLimit(event: any) {
        console.log(event)
        if (event.checked) {
            this.payrollSettingFormData.checkLunchTimeLimit = 1;
        } else {
            this.payrollSettingFormData.checkLunchTimeLimit = 0;
        }
        
    }

    getSettings(companyId: number) {
        const data = JSON.parse(localStorage.getItem('_payrollSetting'));
        if (data) {
            this.payrollSettingFormData = data;
        }
        this.allowEmployeePTOStr = 'no';
        if (data.allowEmployeePTO) {
            this.allowEmployeePTOStr = 'yes';
        }
        this.setData();
    }

    setData() {
        console.log('setData payrollSettingFormData: ', this.payrollSettingFormData)
        if (this.payrollSettingFormData.company.totalEmployee == '4 or fewer employees') {
            this.payrollSettingFormData.sickTimeCalculationForEvery = '2400';
        }
        if (this.payrollSettingFormData.company.totalEmployee == '5 to 99 employees') {
            this.payrollSettingFormData.sickTimeCalculationForEvery = '2400';
        }
        if (this.payrollSettingFormData.company.totalEmployee == '100 or more employees') {
            this.payrollSettingFormData.sickTimeCalculationForEvery = '3360';
        }
        if (this.payrollSettingFormData.mileage === null) {
            this.payrollSettingFormData.mileage = new Mileage();
        }
        this.mileage = this.payrollSettingFormData.mileage;
        if (this.payrollSettingFormData.alertEmployeeEndTime) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.alertEmployeeEndTime, 10) / 60);
            const min = parseInt(this.payrollSettingFormData.alertEmployeeEndTime, 10) - hour * 60;
            this.payrollSettingFormData.alertEmployeeEndTime = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }

        if (this.payrollSettingFormData.employeeLateAfterHours) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.employeeLateAfterHours, 10) / 60);
            const min = parseInt(this.payrollSettingFormData.employeeLateAfterHours, 10) - hour * 60;
            this.payrollSettingFormData.employeeLateAfterHours = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }

        if (this.payrollSettingFormData.employeeEarlyAfterHours) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.employeeEarlyAfterHours, 10) / 60);
            const min = parseInt(this.payrollSettingFormData.employeeEarlyAfterHours, 10) - hour * 60;
            this.payrollSettingFormData.employeeEarlyAfterHours = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }

        if (this.payrollSettingFormData.onTimeRate) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.onTimeRate, 10) / 60);
            const min = parseInt(this.payrollSettingFormData.onTimeRate, 10) - hour * 60;
            this.payrollSettingFormData.onTimeRate = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }


        if (this.payrollSettingFormData.lunchTimeDuration) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.lunchTimeDuration, 10) / 60);
            const min = parseInt(this.payrollSettingFormData.lunchTimeDuration, 10) - hour * 60;
            this.payrollSettingFormData.lunchTimeDuration = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }

        if (this.payrollSettingFormData.overtimeAfter) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.overtimeAfter) / 60);
            const min = parseInt(this.payrollSettingFormData.overtimeAfter) - hour * 60;
            this.payrollSettingFormData.overtimeAfter = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }
        if (this.payrollSettingFormData.minimumHourDaily) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.minimumHourDaily) / 60);
            const min = parseInt(this.payrollSettingFormData.minimumHourDaily) - hour * 60;
            this.payrollSettingFormData.minimumHourDaily = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }
        if (this.payrollSettingFormData.maxSickLeave) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.maxSickLeave) / 60);
            const min = parseInt(this.payrollSettingFormData.maxSickLeave) - hour * 60;
            this.payrollSettingFormData.maxSickLeave = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }
        if (this.payrollSettingFormData.maxVacation) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.maxVacation) / 60);
            const min = parseInt(this.payrollSettingFormData.maxVacation) - hour * 60;
            this.payrollSettingFormData.maxVacation = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }
        if (this.payrollSettingFormData.sickTimeCalculationForEvery) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.sickTimeCalculationForEvery) / 60);
            const min = parseInt(this.payrollSettingFormData.sickTimeCalculationForEvery) - hour * 60;
            this.payrollSettingFormData.sickTimeCalculationForEvery = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }
        if (this.payrollSettingFormData.vacationTimeCalculationForEvery) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.vacationTimeCalculationForEvery) / 60);
            const min = parseInt(this.payrollSettingFormData.vacationTimeCalculationForEvery) - hour * 60;
            this.payrollSettingFormData.vacationTimeCalculationForEvery = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }
        if (this.payrollSettingFormData.lunchTimeLimit) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.lunchTimeLimit) / 60);
            const min = parseInt(this.payrollSettingFormData.lunchTimeLimit) - hour * 60;
            this.payrollSettingFormData.lunchTimeLimit = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }

        if (this.payrollSettingFormData.hoursTakeBackWeekly) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.hoursTakeBackWeekly) / 60);
            const min = parseInt(this.payrollSettingFormData.hoursTakeBackWeekly) - hour * 60;
            this.payrollSettingFormData.hoursTakeBackWeekly = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }

        if (this.payrollSettingFormData.hoursTakeDaily) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.hoursTakeDaily) / 60);
            const min = parseInt(this.payrollSettingFormData.hoursTakeDaily) - hour * 60;
            this.payrollSettingFormData.hoursTakeDaily = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }

        if (this.payrollSettingFormData.fromWeekEndDate) {
            this.payrollSettingFormData.fromWeekEndDate = new Date(this.payrollSettingFormData.fromWeekEndDate);
        }

        this.payrollSettingFormData.isOneTime = this.payrollSettingFormData.isOneTime ?? false;

        this.payrollSettingFormData.isTakeHourBack = this.payrollSettingFormData.takeHourBack !== undefined && this.payrollSettingFormData.takeHourBack !== null;
        this.showTakeOvertime = this.payrollSettingFormData.showTakeOvertime;

        this.payrollSettingId = this.payrollSettingFormData.id;
        this.displayEmployeeNameOnSub = this.payrollSettingFormData.displayEmployeeNameOnSub === 1 ? true : false;
        

        if (this.payrollSettingFormData.clockOnDayOff || this.payrollSettingFormData.clockOnDayOff === 1) {
            this.clockOnDayOff = true;
        } else {
            this.clockOnDayOff = false;
        }

        if (this.payrollSettingFormData.checkLunchTimeLimit || this.payrollSettingFormData.checkLunchTimeLimit === 1) {
            this.checkLunchTimeLimit = true;
        } else {
            this.checkLunchTimeLimit = false;
        }

        this.displayHoliday = true;

        if (this.payrollSettingFormData.holidayPay) {
            this.holidayPay = undefined;
            // Convert from boolean to string for radio button value
            if (this.payrollSettingFormData.holidayPay.holidayPay !== undefined) {
                if (typeof this.payrollSettingFormData.holidayPay.holidayPay === 'boolean') {
                    this.holidayPay = this.payrollSettingFormData.holidayPay.holidayPay === true ? 'true' : 'false';
                } else {
                    // This is string value
                    this.holidayPay = this.payrollSettingFormData.holidayPay.holidayPay;
                }
            }
            this.holidayTypes = this.payrollSettingFormData.holidayPay.holidayPayType;
        }
        if (this.payrollSettingFormData.useOvertime) {
            this.payrollSettingFormData.useOvertime = 1;
        } else {
            this.payrollSettingFormData.useOvertime = 0;
        }

        if (this.payrollSettingFormData.showTakeOvertime) {
            this.showTakeOvertime = '1';
        } else {
            this.showTakeOvertime = '0';
        }

        if (this.payrollSettingFormData.employeeLatenessDecision && this.payrollSettingFormData.employeeLatenessDecision === 5) {
            this.showLate = true;
        }

        if (this.payrollSettingFormData.employeeEarlyDecision && this.payrollSettingFormData.employeeEarlyDecision === 5) {
            this.showEarly = true;
        }
        if (this.payrollSettingFormData.employeeLateRules) {
            this.showLateRules = true;
        }

        if (this.payrollSettingFormData.employeeEarlyRules) {
            this.showEarlyRules = true;
        }

        if (!this.payrollSettingFormData.employeeTimeConfirmation) {
            this.payrollSettingFormData.employeeTimeConfirmation = '0';
        }

        if (this.payrollSettingFormData.displayOn) {
            this.payrollSettingFormData.displayOnList = this.payrollSettingFormData.displayOn.split(',');
        }
    }

    setAndFormatPrice(mileagePrice) {

    }
    private getPositions() {
        this.positionService.readAllForDropDownList({})
            .subscribe(res => {
                console.log(res);
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.dropDownPositions = resObj.data.map((position) => ({
                        label: position.value,
                        value: position.key.toString()
                    }));
                }
            });
    }

    private setFormGroup() {
        this.payrollSettingsForm = new UntypedFormGroup({
            payReference: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            useOvertime: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            showTakeOvertime: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            minimumHourDaily: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            displayEmployeeNameOnSub: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            payrollWeekEnding: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            overtimeAfter: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            overtimeMultipler: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            vacationTimeCalculationHour: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.pattern('^[0-9]*\\.?[0-9]+$')),
            vacationTimeCalculationForEvery: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            sickTimeCalculationHour: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.pattern('^[0-9]*\\.?[0-9]+$')),
            sickTimeCalculationForEvery: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            mileagePrice: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            mile: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            roundOn: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            mins: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            // Other group
            allowEmployeePTO: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            sendAlertTo: new UntypedFormControl({ value: [], disabled: this.readOnly }),
            employeeLate: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            alertEmployeeEndTime: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            frequency: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            frequencyForOtRate: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            lunchTimeDuration: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            redeemHours: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            onTimeRate: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            vacationMethod: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            maxSickLeave: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            maxVacation: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            planAssignmentOption: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            transferFeeCashOut: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            transferFeeFixedAmount: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLateLimit: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            // avertime alert
            customizedHour: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            customizedMinute: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            sendTime: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            intervalTime: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            holidayPay: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            holidayPayType: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            // Balance OT
            isTakeHourBack: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            takeHourBack: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            fromWeekEndDate: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            isOneTime: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            hoursTakeDaily: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            hoursTakeBackWeekly: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            lateFee: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLatenessDecision: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            jobReportVsEndWork: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            delayOver: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            clockOnDayOff: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            checkLunchTimeLimit: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            lunchTimeLimit: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            // Job Report
            jobReportMissingLimit: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            beyondLimitationDecision: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeEarlyHoursDays: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeEarlyDecision: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeEarlySuspend: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLatenessRules: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeEarlyRules: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeEarlyTimes: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeEarlyAfterHours: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLateSuspend: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLateHoursDays: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLateTimes: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLatePer: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeEarlyPer: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLateRules: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            readerId: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            testMode: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            testCardNumber: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            donationLimit: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            fundraisersFee: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            fundraisingMaxLimitation: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            allowMonitorComeLateOrLeaveEarly: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeTimeConfirmation: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            displayOnList: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            invoiceTax: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            invoiceType: new UntypedFormControl({ value: '', disabled: this.readOnly })
        });

        this.setInputFilter(document.getElementById("transferFeeCashOut"), function(value) {
            return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });

        this.setInputFilter(document.getElementById("transferFeeFixedAmount"), function(value) {
            return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
    }
    setInputFilter(textbox, inputFilter) {
        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
            textbox.addEventListener(event, function() {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;


                } else {
                    this.value = "";
                }
            });
        });
    }
    noDecimalValidator(control: UntypedFormControl) {
        const isCheckDecimal = control.value && control.value === '0.00';
        console.log(isCheckDecimal);
        const rgx = /^[0-9]*\.?[0-9]*$/;
        const isValid = !isCheckDecimal && control.value.match(rgx);
        return isValid ? null : {'ng-invalid': true};
    }
    payrollSettingToDropDownChange(event) {
        if (event.value.length === this.dropDownPositions.length) {
            const x = document.getElementById('id_payrollSetting_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            const x = document.getElementById('id_payrollSetting_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }
    public showHistory() {
        this.historyType = 'mainSetting';
        this.historyShow = true;
    }

    public hideHistory() {
        this.historyShow = false;
    }

    toggleOtherOpen() {
        this.isOtherOpen = !this.isOtherOpen;
    }

    toggleHolidayOpen() {
        this.isHolidayOpen = !this.isHolidayOpen;
    }

    toggleOvertimeOpen() {
        this.isOvertimeOpen = !this.isOvertimeOpen;
    }

    public getStates(): Observable<any> {
        return this.http.get(this._jsonIpURL);
    }

    deleteSpreadHour(companyId: any, id: any): void {
        this.spreadHourSettingServiceV2.deleteSpreadHour(companyId, id).subscribe(res => {
            if (res.status === 'SUCCESS') {
                this.spreadHour = new SpreadHourSetting(null, null, null);
                this.listSpreadHour = res.data;
                this.listOfExistingState = [];
                this.length = this.listSpreadHour.length;
                if (this.length > 0) {
                    this.listSpreadHour.forEach(item => {
                        item.minimumWage = item.minimumWage.toFixed(2);
                        item.statesObj = [];
                        this.listOfExistingState.push.apply(this.listOfExistingState, item.states);
                    });
                }
                this.hidenStateIfUsed();
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Delete  Spread Hour',
                });
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Cannot Delete  Spread Hour',
                });
            }
        }, error => {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Cannot Delete  Spread Hour',
            });
        });
    }

    updateSpreadHour(companyId: any, id: any, entity: any): void {
        if (entity.minimumWage === null || entity.states === undefined ||
            entity.states.length === 0 || entity.calculateMethod === undefined) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'The Spread Hour cannot be empty',
            });
        } else {
            this.spreadHourSettingServiceV2.updateSpreadHour(companyId, id, entity).subscribe(res => {
                if (res.status === 'SUCCESS') {
                    this.spreadHour = new SpreadHourSetting(null, null, null);
                    this.listSpreadHour = res.data;
                    this.listOfExistingState = [];
                    this.length = this.listSpreadHour.length;
                    if (this.length > 0) {
                        this.listSpreadHour.forEach(item => {
                            item.statesObj = [];
                            item.minimumWage = item.minimumWage.toFixed(2);
                            this.listOfExistingState.push.apply(this.listOfExistingState, item.states);
                        });
                    }
                    this.hidenStateIfUsed();
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Update Spread Hour successfully',
                    });
                } else if (res.status === 202) {
                    this.msgs = [];
                    this.messageService.add({ severity: 'error', summary: 'Error!', detail: res.message });
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Cannot Update Spread Hour',
                    });
                }
            }, error => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Cannot Update Spread Hour',
                });
            });
        }
    }

    createSpreadHour(): void {
        if (this.minimumWage === null || this.listOfStateValue === undefined || this.listOfStateValue.length === 0 || this.calMethodValue === undefined) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Spread Hour cannot be empty',
            });
        } else {
            this.spreadHour = new SpreadHourSetting(this.minimumWage, this.listOfStateValue, this.calMethodValue);
            this.spreadHour.companyId = this.companyId;
            this.spreadHourSettingServiceV2.saveSpreadHour(this.companyId, this.spreadHour).subscribe(res => {
                if (res.status === 'SUCCESS') {
                    this.minimumWage = null;
                    this.listOfStateValue = null;
                    this.calMethodValue = null;
                    this.listSpreadHour = res.data;
                    this.listOfExistingState = [];
                    this.length = this.listSpreadHour.length;
                    if (this.length > 0) {
                        this.listSpreadHour.forEach(item => {
                            item.statesObj = [];
                            item.minimumWage = item.minimumWage.toFixed(2);
                            this.listOfExistingState.push.apply(this.listOfExistingState, item.states);
                        });
                    }
                    this.hidenStateIfUsed();
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Save  Spread Hour',
                    });
                } else if (res.status === 202) {
                    this.msgs = [];
                    this.messageService.add({ severity: 'error', summary: 'Error!', detail: res.message });
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Cannot Save Spread Hour',
                    });
                }
            }, error => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Cannot Save  Spread Hour',
                });
            });
        }
    }

    changeStates(event) {
        const x = document.getElementById('id_filteredEmployeeList_to_selected_label0').getElementsByClassName('ui-multiselect-label').item(0);
        x.textContent = `${event.value.length} items selected`;
    }

    getAllSpreadHourById() {
        // load list all states
        this.listOfExistingState = [];
        this.spreadHourSettingServiceV2.findAllByCompanyId(this.companyId).subscribe(res => {
            const resObj: any = res;
            this.listSpreadHour = resObj.data;
            this.length = this.listSpreadHour.length;
            if (this.length > 0) {
                this.listSpreadHour.forEach(item => {
                    item.statesObj = [];
                    item.minimumWage = item.minimumWage.toFixed(2);
                    this.listOfExistingState.push.apply(this.listOfExistingState, item.states);
                });
            }
            this.hidenStateIfUsed();
        });
    }

    hidenStateIfUsed() {
        let states = [];
        this.listOfStateTemp = [];
        this.listOfState = [];
        this.getStates().subscribe(response => {
            states = response.states;
            states.forEach(item => {
                const data = { value: item.abbreviation, label: item.name + ' (' + item.abbreviation + ')' };
                if (this.listOfExistingState.length === 0) {
                    this.listOfState.push(data);
                } else {
                    if (!this.listOfExistingState.includes(item.abbreviation)) {
                        this.listOfState.push(data);
                    } else {
                        this.listOfStateTemp.push(data);
                    }
                }

            });

            this.listSpreadHour.forEach((item, index) => {
                item.statesObj = [];
                item.states.forEach(s => {
                    this.listOfStateTemp.forEach(ls => {
                        if (ls.value === s) {
                            item.statesObj.push(ls);
                        }
                    });
                });
                item.statesObj.push.apply(item.statesObj, this.listOfState);
                item.statesObj.sort();
            });
        });
    }

    minimumWageOnchange(event) {
        if (event) {
            event.minimumWage = event.minimumWage.toFixed(2);
        } else {
            this.minimumWage = this.minimumWage.toFixed(2);
        }
    }

    isSuperAdmin() {
        const role = this.authService.getRoleLevel();
        return role === 1 || role === 2;
    }

    isCompanyAdmin() {
        const role = this.authService.getRoleLevel();
        return role === 4 || role === 3;
    }

    formatCurrency(data, type) {
        this.payrollSettingFormData[type] = Number(data).toFixed(2);
    }

    formatLateFee() {
        console.log(this.payrollSettingFormData.lateFee);
        if (this.payrollSettingFormData.lateFee && Number(this.payrollSettingFormData.lateFee) > 100) {
            this.payrollSettingFormData.lateFee = 100;
        }
    }

    openOtherHistory(type?: any) {
        // this.historyType = type;
        this.historyOtherShow = true;
    }

    public hideOtherHistory() {
        this.historyOtherCompo.dataOtherSourceSubject.next([]);
        this.historyOtherCompo.selectedOtherField = '';
        this.historyOtherCompo.rangeOtherDates = [];
        this.historyOtherCompo.historyOtherFor = {};
        this.historyOtherShow = false;
    }


    openPunchSetting() {
        if (this.isSuperAdmin()) {
            this.punchDailyRate = this.payrollSettingFormData.punchDailyRate;
            this.punchTrackingRate = this.payrollSettingFormData.punchTrackingRate;
            this.punchSettingDialog = true;
        }
    }

    viewHistoryPunchSetting() {
        this.historyType = 'rateSetting';
        this.historyShow = true;
    }
    
    submitPunchSetting() {
        if (this.punchDailyRate >= 0 && this.punchTrackingRate >= 0) {
            if (!this.payrollSettingFormData.id) {
                this.payrollSettingFormData.punchDailyRate = this.punchDailyRate;
                this.payrollSettingFormData.punchTrackingRate = this.punchTrackingRate;
                this.punchSettingDialog = false;
                return;
            }
            this.payrollSettingService.updatePunchSetting(this.payrollSettingFormData.id, {
                punchDailyRate: this.punchDailyRate,
                punchTrackingRate: this.punchTrackingRate
            }).subscribe(() => {
                this.payrollSettingFormData.punchDailyRate = this.punchDailyRate;
                this.payrollSettingFormData.punchTrackingRate = this.punchTrackingRate;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Update Punch in/out Rate successfully',
                });
                this.punchSettingDialog = false;

                this.monitoringDetailsService.monitorAction(
                    `Punch in/out Rate Updated`,
                    new Date(),
                    {
                        update_punch_in_out_rate: this.authService.getCurrentLoggedInName()
                    },
                    'Punch in/out Rate Updated',
                    `Punch in/out Rate Updated`,
                    0
                );
            });
        } else {
            return this.messageService.add({
                severity: 'error', summary: 'Invalid',
                detail: 'Please enter all required fields!'
            });
        }
    }
    changeLatenessDecision (event) {
        if (event && event.value === 5) {
            this.showLate = true;
        } else {
            this.showLate = false;
        }
    }

    changeEarlyDecision (event) {
        if (event && event.value === 5) {
            this.showEarly = true;
        } else {
            this.showEarly = false;
        }
    }

    getProducts() {
        this.productOptions = [];
        const options: any = {
            companyId: this.authService.getCurrentCompanyId(),
            agencyId: 0,
            status: 1,
        };

        this.productService.getPage(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.productOptions = resObj.data.content.map(i => {
                    return {
                        label: i.productName,
                        value: i.id
                    }
                });
            }
        });
    }

    getAllAutoGenerateInvoiceSettings() {
        this.autoGenerateInvoiceSettingsService.findByCompanyIdAndPayrollSettingId(this.authService.getCurrentCompanyId(), this.payrollSettingId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.autoGenerateInvoiceSettings = resObj.data;
                this.autoGenerateInvoice = this.autoGenerateInvoiceSettings && this.autoGenerateInvoiceSettings.length > 0 ? '1' : '0';
            }
        });
    }

    deleteAutoGenerateInvoiceSettings(companyId, id, displayNone?) {
        this.autoGenerateInvoiceSettingsService.deleteByIdAndCompanyId(companyId, id).subscribe(res => {
            if (res.status === 'SUCCESS') {
                const findIndex = this.autoGenerateInvoiceSettings.findIndex(i => i.id === id);
                if (findIndex > -1)
                    this.autoGenerateInvoiceSettings.splice(findIndex, 1);

                if (!displayNone)
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Delete Successfully' });
            } else {
                if (!displayNone)
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Delete Failure' });
            }
        }, error => {
            if (!displayNone)
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Delete Failure ' + error.message });
        });
    }

    createAutoGenerateInvoice(setting: AutoGenerateInvoiceSettings) {
        if (this.autoGenerateInvoiceSettings.length >= 4) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Only allow to create maximum 3 records' });
            return;
        }

        this.autoGenerateInvoiceSettingsService.create(setting).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (!this.autoGenerateInvoiceSettings) {
                    this.autoGenerateInvoiceSettings = [];
                }
                this.autoGenerateInvoiceSettings.push(resObj.data);

                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Create Auto Generate Invoice Settings Successfully' });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Create Failure' });
            }
        }, error => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Create Failure ' + error.message });
        });
    }

    generateInvoiceChange(v) {
        if (v === '1') {
            if (!this.autoGenerateInvoiceSettings || !this.autoGenerateInvoiceSettings.length) {
                this.autoGenerateInvoiceSettings = [];
                this.autoGenerateInvoiceSettings.push({
                    companyId: this.companyId,
                    payrollSettingsId: this.payrollSettingFormData.id,
                    frequency: this.frequencyOptions[0].value,
                    productId: this.productOptions[0].value,
                    weekDay: this.dayOfWeekOptions[0].value
                });
            }
        } else {
            this.autoGenerateInvoiceSettings = [];
        }
    }

    onlyNumber(event) {
        const initialValue = event.target.value;
        const regex = /[^0-9.]+/;
        const newValue = initialValue.replace(regex, '');
        event.target.value = newValue;
    }

     onlyDecimal(evt): boolean {
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 &&
            (charCode != 46 || evt.target.value.indexOf('.') != -1) &&
            (charCode < 48 || charCode > 57))
            return false;
        if ((evt.target.value.indexOf('.') != -1) && (evt.target.value.substring(evt.target.value.indexOf('.'), evt.target.value.indexOf('.').length).length > 2)) {
            if (evt.keyCode !== 8 && evt.keyCode !== 46) { //exception
                return false;
            }
        } else {
            if (!evt.target.value && evt.key == '.') {
                return false;
            }
        }
        return true;
    }

    maxFundraisersFee() {
      return this.payrollSettingFormData.fundraisersFee && parseFloat(this.payrollSettingFormData.fundraisersFee) > 100
    }

    collapsedChange(index) {
        this.collapsedList[index] = !this.collapsedList[index];
        localStorage.setItem('collapsedList', this.collapsedList.toString());
    }

    changeDisplayOn(event) {
        if (event.value.length === this.dayOfWeekOptions.length) {
            const x = document.getElementById('id_displayOn').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            const x = document.getElementById('id_displayOn').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }
}
