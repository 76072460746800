<div class="card add-shadow p-4" *ngIf="!isCheckout">
    <h1 class="m-0 ml-2 my-4">{{'Make Donation' | translate}}</h1>

	<div class="ui-g" *ngIf="fundRaiserWarningMessage">
		<div class="ui-g-12">
			<div class="alert alert-warning alert-dismissible" role="alert">
				<i class="fa-solid fa-circle-exclamation mr-2"></i><span>{{fundRaiserWarningMessage}}</span>
			</div>
		</div>
	</div>

	<div class="ui-g">
		<div class="ui-g-8 ui-sm-12">
			<p-panel [toggleable]="true">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Donate' | translate}}</span>
				</p-header>
				<div class="ui-g">
					<div class="ui-g-12 pb-4">
						<h3 class="m-0 mt-3 mb-2">{{'How much would you like to contribute?' | translate}}</h3>
						<div class="text-secondary" style="font-size: 16px">NYC resident? With matching funds, your <b>{{selectRaisedAmount}}</b></div>
						<div class="text-secondary" style="font-size: 16px">contribution can be worth <b>${{ (selectRaisedAmount.replace('$', '')) * 2 }}.00</b></div>
					</div>
					<div class="ui-g-4 ui-sm-6" *ngFor="let item of listRaisedAmount;let i = index">
                        <button *ngIf="item.lable !== 'Other'" (click)="changeRaisedAmount(item);showDetail()" type="button"  class="btn btn-primary btn-lg btn-block" style="height: 75px;font-size:30px"><span><b>${{item.lable}}</b></span></button>
                        <button *ngIf="item.lable === 'Other'" (click)="showDialog()" type="button" class="btn btn-primary btn-block btn-lg" style="height: 75px;font-size:30px"><span><b>{{item.lable}}</b></span></button>
                    </div>
				</div>
				<div *ngIf="isShowDetailEmployee" class="ui-g mt-4">
					<div class="ui-g-12">
						<h3 class="m-0" *ngIf="authService.getUserInfo()"><b>{{content.employer}}</b></h3>
						<div style="font-size: 16px">{{'Please enter the following details: ' | translate}}</div>
						<div class="text-muted">{{'All field are required unless otherwise noted. ' | translate}}</div>
					</div>
				</div>
				<form *ngIf="isShowDetailEmployee" [formGroup]="contributeForm">
					<div class="ui-g pb-3">
						<div class="ui-g-6 ui-sm-12">
							<ng-container *ngIf="!authService.getUserInfo()">
								<label class="font-weight-bold">Employer<span class="required-indicator">*</span></label>
								<div class="input-group w-100">
									<div class="input-group-prepend p-0">
										<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
											<i class="fa-solid fa-folder-tree"></i>
										</button>
									</div>
									<input class="w-100" type="text" pInputText formControlName="employer" [(ngModel)]="content.employer" placeholder="{{'Employer' | translate}}">
								</div>
							</ng-container>
						</div>

						<div class="ui-g-12 ui-sm-12" [ngClass]="authService.getUserInfo() ? 'ui-g-6' : 'ui-g-12'">
							<label>{{'Business Street Address' | translate}}<span class="required-indicator">*</span></label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signs-post"></i>
									</button>
								</div>
								<input class="w-100" type="text" pInputText formControlName="address" [(ngModel)]="content.address" placeholder="123 Example Street">
							</div>
						</div>
						
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Occupation' | translate}}<span class="required-indicator">*</span></label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-quote-left"></i>
									</button>
								</div>
								<input class="w-100" type="text" pInputText formControlName="occupation" [(ngModel)]="content.occupation" placeholder="{{'Occupation' | translate}}">
							</div>
						</div>
		
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Address (Line 2)' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-door-open"></i>
									</button>
								</div>
								<input class="w-100" type="text" pInputText formControlName="aptSuiteFloor" [(ngModel)]="content.aptSuiteFloor" placeholder="Suite 1">
							</div>
						</div>
		
						<div class="ui-g-6 ui-sm-12">
							<label>{{'City' | translate}}<span class="required-indicator">*</span>:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-city"></i>
									</button>
								</div>
								<input class="w-100" type="text" pInputText formControlName="city" [(ngModel)]="content.city" placeholder="e.g. New York City">
							</div>
						</div>
		
						<div class="ui-g-3 ui-sm-6">
							<label>{{'State' | translate}}<span class="required-indicator">*</span></label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-map"></i>
									</button>
								</div>
								<p-dropdown class="w-100" formControlName="state" [(ngModel)]="content.state" [options]="states" optionLabel="label" placeholder="{{ 'Select' | translate }}">
									<ng-template pTemplate="item" let-item>
										<span class="text-truncate">{{item.label | translate}}</span>
									</ng-template>
									<ng-template let-item pTemplate="selectedItem">
										<span class="text-truncate">{{item.label | translate}}</span>
									</ng-template>
								</p-dropdown>
							</div>
						</div>

						<div class="ui-g-3 ui-sm-6">
							<label>{{'ZIP Code' | translate}}<span class="required-indicator">*</span>:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-location-dot"></i>
									</button>
								</div>
								<input class="w-100" type="text" pInputText formControlName="zipCode" [(ngModel)]="content.zipCode" (keypress)="isNumber($event)" maxlength="5" placeholder="{{'ZIP' | translate}}">
							</div>
						</div>
					</div>
				</form>
				<p-footer class="d-flex justify-content-between">
					<button *ngIf="!isShowDetailEmployee"  type="button" class="btn btn-warning text-light" [disabled]="selectRaisedAmount === '$0.00' || !selectRaisedAmount || fundRaiserWarningMessage" (click)="showDetail()"><i class="fa-solid fa-check mr-2"></i>{{'Next' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
					<button type="button" class="btn btn-danger" *ngIf="isShowDetailEmployee" (click)="isShowDetailEmployee = false" ><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
					<button *ngIf="isShowDetailEmployee"  type="button" class="btn btn-primary" [disabled]="sumbitClicked" (click)="saveFundraisersDetail(contributeForm)" ><i class="fa-solid fa-cehck mr-2"></i>{{'Submit' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
				</p-footer>
			</p-panel>
		</div>
		<div class="ui-g-4 ui-sm-12">
			<p-panel [toggleable]="true">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Candidate' | translate}}</span>
				</p-header>
				<div class="ui-g">
					<div class="ui-g-12 text-center">
						<h3 class="m-0">{{candidateName}}</h3>
						<div class="text-secondary">{{fundraisingTitle}}</div>
					</div>
					<div class="ui-g-12 text-center">
						<img *ngIf="selectData.profilePhotoUrl" class="m-auto" src="{{selectData.profilePhotoUrl}}" (click)="openProfilePhoto()" style="width:fit-content;max-height:250px;aspect-ratio: 1"/>
					</div>
				</div>
			</p-panel>
		</div>
	</div>
</div>

<p-dialog [(visible)]="isShowOther" [modal]="true" [style]="{'width':'90%', 'max-width':'400px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i> {{'Custom Amount' | translate}}</span>
	</p-header>
    <div class="ui-g pb-3">
        <div class="ui-g-12">
			<label>{{'Amount' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-dollar-sign"></i>
					</button>
				</div>
				<input pInputText class="w-90" [(ngModel)]="selectRaisedAmount" placeholder="0.00" name="selectRaisedAmount" twoDigitDecimaNumber (keypress)="isNumber($event)"/>
			</div>
        </div>
    </div>
	<p-footer class="d-flex justify-content-between">
		<button type="submit" class="btn btn-danger" (click)="isShowOther = false;"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="submit" class="btn btn-primary" (click)="changeOtherPrice()" [disabled]="selectRaisedAmount === '$0.00' || !selectRaisedAmount"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
	</p-footer>
</p-dialog>

<app-fundraisers-checkout *ngIf="isCheckout"
                          [content]="content"
                          [selectData]="selectData"
                          (back)="back($event)"
                          (addFundraisersDetail)="addFundraisersDetail()"
></app-fundraisers-checkout>


