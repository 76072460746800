import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../model/constants';
import {DocumentData} from '../../model/document-data.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentSearch } from "../../../shared/model/search/document.search";
import { AbstractServiceV2 } from '../abstract.v2.service'
import { ListResponse } from 'app/shared/model/list.response';
import { Email } from '../../../layout/model/email';

@Injectable({ providedIn: 'root' })
export class DocumentsServicev2 extends AbstractServiceV2<DocumentData,DocumentSearch> {
    constructor(protected _http: HttpClient) {
        super(_http, Constants.ROUTES.NOTIFICATION, '');
    }

    save(document: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}/document/save`, document).pipe(map(res => res));
    }

    uploadFile(file: File, entityName: string, entityId: any, fileclass: string, userUpload?): Observable<any> {
        let reqParams = {entity: entityName, entityId: entityId, fileClass: fileclass, userUpload};
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        payload.append('file', file);
        // return this._http.post<any>(`http://localhost:8009/api/v2/notification/document/`, payload, {params: reqParams}).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}/document/`, payload, {params: reqParams}).pipe(map(res => res));
    }

    searchFile(options: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}/document/search`, options).pipe(map(res => res));
    }

    sendEmailFromOperr(mail: Email): Observable<any> {
        // return this._http.post(`http://localhost:8009/api/v2/notification/email`, mail).pipe(map(res => res));
        return this._http.post(`${this.baseUrlV2}/email`, mail).pipe(map(resp => resp));
    }

    sendEmailSendGrid(mail: Email): Observable<any> {
        // return this._http.post(`http://localhost:8009/api/v2/notification/email/send-grid`, mail).pipe(map(res => res));
        return this._http.post(`${this.baseUrlV2}/email/send-grid`, mail).pipe(map(resp => resp));
    }

    getUploadedFile (id): Observable<any>{
        return this._http.get<any>(`${this.baseUrlV2}/document/${id}`).pipe(map(res => res));
    }

    sendContractorActivationEmail(payload): Observable<any> {
        return this._http.post(`${this.baseUrlV2}/email/contractor-activation-email`, payload).pipe(map(resp => resp));
    }

    getLogoCompany(logoId: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/document/${logoId}`).pipe(map(resp => resp));
    }

    uploadMultiFile(files: File[], entityName: string, entityId: any, fileclass: string, userUpload?): Observable<any> {
        let reqParams = {entity: entityName, entityId: entityId, fileClass: fileclass, userUpload};
        if (!reqParams.entityId) {
            reqParams.entityId = 0;
        }
        let payload = new FormData();
        Array.from(files).forEach((file) =>
            payload.append('file', file)
        );
        // return this._http.post<any>(`http://localhost:8009/api/v2/notification/document/multi-files`, payload, {params: reqParams}).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}/document/multi-files`, payload, {params: reqParams}).pipe(map(res => res));
    }

    getFileByEnitity(entity: string, entityId: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/document/${entityId}/get-by-entity/${entity}`).pipe(map(res => res));
    }

    deleteDocument(id: any): Observable<any> {
        return this._http.delete<any>(`${this.baseUrlV2}/document/${id}`).pipe(map(res => res));
    }

    searchEmail(req: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8009/api/v2/notification/email-logs/search-by-username`, req).pipe(map(res => res));
        return this._http.post(`${this.baseUrlV2}/email-logs/search-by-username`, req).pipe(map(resp => resp));
    }

    downloadFile(id: number) {
        return this._http.get(`${this.baseUrlV2}/document/download/${id}`, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }
}
