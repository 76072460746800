import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService } from 'app/layout/service/breadcrumb.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { MenuItem } from 'primeng';

@Component({
  selector: 'app-job-follow-up-layout',
  templateUrl: './job-follow-up-layout.component.html',
  styleUrls: ['./job-follow-up-layout.component.scss']
})
export class JobFollowUpLayoutComponent implements OnInit {

  public viewJobFollowUp = { label: 'Log Details', routerLink: ['view'] };
  public editJobFollowUp = { label: 'Edit Log', routerLink: ['edit'] };

  defaultBreadCrumbs: MenuItem[] = []
  activeItem: MenuItem;
  JobFollowUpItems = [];

  initBreadCrumbs() {
    this.defaultBreadCrumbs = [{ label: 'CRM' }];
  }
  routerEventsSubscribe;

  constructor(private router: Router, private authService: AuthService, private breadcrumbService: BreadcrumbService,) {
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('/app/job-follow-up/list') >= 0) {
          this.jobFollowUpItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Billing Clients Log' });
        } else if (event.url.indexOf('/app/job-follow-up/view') >= 0) {
          this.jobFollowUpItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Log Details' });
          this.JobFollowUpItems.push(this.viewJobFollowUp);
        } else if (event.url.indexOf('/app/job-follow-up/edit') >= 0) {
          this.jobFollowUpItems();
          this.initBreadCrumbs();
		  this.defaultBreadCrumbs = [{ label: 'Billing Clients' }];
          this.defaultBreadCrumbs.push({ label: 'Edit Log' });
          this.JobFollowUpItems.push(this.editJobFollowUp);
        } else if (event.url.indexOf('/app/job-follow-up/add') >= 0) {
          this.jobFollowUpItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Add Client Log' });
        } else if (event.url.indexOf('/app/job-follow-up/billing-report') >= 0) {
          this.billingReportsItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Billing Report' });
        } else if (event.url.indexOf('/app/job-follow-up/billing-total-job') >= 0) {
          if (!this.authService.isChildClientRole()) {
            this.billingReportsItems();
            this.initBreadCrumbs();
          }
          this.defaultBreadCrumbs.push({ label: 'Total Jobs' });
        } else if (event.url.indexOf('/app/job-follow-up/dispatch-total-job') >= 0) {
            if (!this.authService.isChildClientRole()) {
                this.billingReportsItems();
                this.initBreadCrumbs();
            }
            this.defaultBreadCrumbs.push({ label: 'Dispatch Jobs' });
        } else if (event.url.indexOf('/app/job-follow-up/billing-log-total-job') >= 0) {
          if (!this.authService.isChildClientRole()) {
            this.billingReportsItems();
            this.initBreadCrumbs();
          }
          this.defaultBreadCrumbs.push({ label: 'LOG Total Jobs' });
        }
        else if (event.url.indexOf('/app/job-follow-up/billing-details-report') >= 0) {
          this.billingReportsItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Details Report' });
        } else if (event.url.indexOf('/app/job-follow-up/billing-correction-report') >= 0) {
          this.billingReportsItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Correction Report' });
        } else if (event.url.indexOf('/app/job-follow-up/billing-upload-history') >= 0) {
          this.billingReportsItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Upload History' });
        } else if (event.url.indexOf('/app/job-follow-up/billing-client-status') >= 0) {
          this.billingReportsItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'Billing Client Status' });
        } else if (event.url.indexOf('/app/job-follow-up/billing-log-details-report') >= 0) {
          this.billingReportsItems();
          this.initBreadCrumbs();
          this.defaultBreadCrumbs.push({ label: 'LOG Details Report' });
        }
        this.breadcrumbService.setItems(this.defaultBreadCrumbs);
      }
    });
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }

  jobFollowUpItems() {
    this.JobFollowUpItems = [
      { label: 'Billing Clients Log', routerLink: ['list'], visible: this.authService.isClientRole() ? false : true },
      {
        label: 'Add Log', routerLink: ['add'], visible: this.authService.isEmployeeRole() ? true :
          this.authService.getLoggedInUserEditList().includes('1012')
      },
    ];
  }

  billingReportsItems() {
    this.JobFollowUpItems = [
      { label: 'Billing Report', routerLink: ['billing-report'] },
      { label: 'Total Job', routerLink: ['billing-total-job'] },
      { label: 'Log Total Job', routerLink: ['billing-log-total-job'] },
      { label: 'Details Report', routerLink: ['billing-details-report'] },
      { label: 'Correction Report', routerLink: ['billing-correction-report'] },
      { label: 'Upload History', routerLink: ['billing-upload-history'] },
      { label: 'Billing Client Status', routerLink: ['billing-client-status'] },
      { label: 'Log Details Report', routerLink: ['billing-log-details-report'] },
      { label: 'Dispatch Job', routerLink: ['dispatch-total-job'] },
    ]
  }
}
