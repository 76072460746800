export class DocumentSettings {
    id?: number;
    companyId?: number;
    role?: string;//employee, inter_paid, inter_not_paid, volunteer, contractor
    documentName?: string;
    createdAt?: any;
    createdBy?: string;
    position?: any;
    isDefault?: boolean;
    isRequired?: boolean;
    isVisible?: boolean;
    parentId?: number;
    selectedRole?: any;
    selectedRoleEdit?: any;
    documentNameEdit?: string;
    isRequiredEdit?: boolean;
    roleEdit?: string;
    addExpDate?: boolean;
    confirmSetting?: boolean;
    operationalPolicy?: any;
    information?: string;
    material?: any;
    roleText?: any;
    documentIds?: any;
    lastModifiedBy?: any;
}
