import { Injectable } from '@angular/core';
import {of} from 'rxjs/observable/of';
import { AbstractService } from '../../shared/service/abstract.service';
import { Employee } from '../model/employee.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs/Observable';
import { Page } from '../../shared/model/page.model';
import { environment } from '../../../environments/environment';
import { EmployeeNotifcationSetting } from '../model/employee-setting/employee-notifcation-setting.model';
import { AuthService } from '../../shared/service/auth/auth.service';
import { WeekDateRange } from '../../shared/model/custom-ui.model';
import { SingleResponse } from '../../shared/model/response';
import { TimeSheetRespone } from '../model/timesheet.model';
import { map } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class OvertimeConfirmationService {
  private apiURl = `${environment.v2_server_ip}/clocking/api/v2/clocking`;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  save(body: any, employeeId: any): Observable<any> {
    return this.http.post(`${this.apiURl}/${employeeId}/overtime`, body);
  }

  getOvertimeConfirmationToday(id): Observable<any> {
    return this.http.get(`${this.apiURl}/${id}`);
  }

  displayOvertimeAlert(id): Observable<any> {
    return this.http.get(`${this.apiURl}/${id}/display-alert`);
  }

  displayClockingReminder(id): Observable<any> {
    return this.http.get(`${this.apiURl}/reminder/${id}`);
  }

  getOvertimeControlNumber(number): Observable<any> {
    return this.http.get(`${this.apiURl}/control-number/${number}`);
  }

  list(companyId, startDate, endDate, employeeId, authorizedNumber): Observable<any> {
    const params = {};
    if (companyId) {
      params['companyId'] = companyId;
    }
    if (startDate) {
      params['startDate'] = startDate;
    }
    if (endDate) {
      params['endDate'] = endDate;
    }
    if (employeeId) {
      params['employeeId'] = employeeId;
    }
    if (authorizedNumber) {
      params['authorizedNumber'] = authorizedNumber;
    }
    return this.http.get(`${this.apiURl}/overtime`, {params: params}).pipe(map(resp => resp));;
  }
}
