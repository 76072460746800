
<p-dialog header="Payment Confirmation"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [style]="{
            width: '700px',
            minWidth: '400px'
          }"
          (onHide)="onClickClose()"
          [minY]="70"
          [dismissableMask]="true"
          id="paymentConfirmationDialog"
          >
    <p-card id="payment-print" #paymentPrint>
        <div class="ui-g">
            <div class="ui-g-2">
            </div>
            <div class="ui-g-10">
                =============RECEIPT CONFIRMATION=============
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport !== 'TOP_UP' && functionImport !== 'PLAN'">
            <div class="ui-g-3">
                Invoice:
            </div>
            <div class="ui-g-9">
                {{data?.invoice}}
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport === 'TOP_UP' || functionImport === 'PLAN'">
            <div class="ui-g-3">
                Plan:
            </div>
            <div class="ui-g-9">
                {{data?.planTypeName}}
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport === 'TOP_UP'">
            <div class="ui-g-3">
                Total SMS available:
            </div>
            <div class="ui-g-9">
                {{data?.totalSmsAvailable}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Description:
            </div>
            <div class="ui-g-9" *ngIf="functionImport !== 'TOP_UP' && functionImport !== 'PLAN'">
                OPERRWORK Invoices Payment
            </div>
            <div class="ui-g-9" *ngIf="functionImport === 'TOP_UP' || functionImport === 'PLAN'">
                {{data?.description}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Amount:
            </div>
            <div class="ui-g-9">
                ${{data?.amount | number:'1.2-2'}} USD
            </div>
        </div>

        <div class="ui-g">
            <div class="ui-g-3">
                Fee:
            </div>
            <div class="ui-g-9">
                ${{data?.fee | number:'1.2-2'}} USD
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Payment Method:
            </div>
            <div class="ui-g-9">
                {{data?.paymentCardType}}
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport !== 'TOP_UP' && functionImport !== 'PLAN'">
            <div class="ui-g-3">
                Transaction Type:
            </div>
            <div class="ui-g-9">
                Authorization and Capture
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Transaction ID:
            </div>
            <div class="ui-g-9">
                {{data?.transactionPaymentId}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-2">
            </div>
            <div class="ui-g-10">
                ===============LINE ITEMS===============
            </div>
        </div>
    </p-card>
    <p-footer *ngIf="functionImport !== 'PRINT'">
        <button pButton label="Ok" (click)="onSubmit()"></button>
    </p-footer>
    <p-footer *ngIf="functionImport === 'PRINT'">
        <div class="ui-g">
            <div class="ui-g-12 text-center" align="center">
                <button pButton type="button" class="btn btn-danger" label="{{'Cancel' | translate}}" (click)="onSubmit()"></button>
                <button pButton type="button" class="btn btn-primary" label="{{'print' | translate}}" [useExistingCss]="true" printSectionId="payment-print" ngxPrint></button>
                <button pButton type="button" class="btn btn-success" label="{{'Email' | translate}}" (click)="sendEmail(paymentPrint)"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>
