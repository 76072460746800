import { Component, Input, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { AuthService } from '../../../shared/service/auth/auth.service';
// import {AllMenus} from './all-menus';
import * as _ from 'lodash';
import { AllMenusService } from '../../service/all-menus/all-menus.service';
import { CompanyService } from '../../../company/service/company.service';
import { ServiceType } from './service-type';
import { ScrollPanel } from 'primeng/scrollpanel';
import { MenuItem } from 'primeng/api';
import { LocationManagementService } from 'app/location-management/service/location-management-plan.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    providers: [ LocationManagementService ]
})
export class AppMenuComponent implements OnInit, AfterViewInit {

    @Input() reset: boolean;

    model: any[];
    loggedInUserMenus = [];

    @ViewChild('scrollPanel', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;

    constructor(
        public app: AppComponent,
        private authService: AuthService,
        public allMenus: AllMenusService,
        private companyService: CompanyService,
        private locationManagementService: LocationManagementService,
    ) {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.layoutMenuScrollerViewChild.moveBar();
        }, 100);
    }

    ngOnInit() {
        this.model = this.getMenuByRole();
        if (!this.authService.isClientRole()) {
            this.model = this.model.filter(i => i.id !== 'client_dasboard');
        }
        if (this.authService.isEmployeeRole()) {
            this.model.forEach((mItem: any) => {
                if (mItem.id === 'menu_payment') {
                    // mItem.items = mItem.items.filter(i => { return i.id != 'fees_invoice_template' });
                    let check = false;
                    mItem.items.forEach(e => {
                        if (e.id === 'menu_billing') { check = true; }
                    });
                    if (!check) {
                        mItem.items = mItem.items.filter(i => i.id !== 'menu_invoice_template');
                    }
                }
                if (mItem.id === 'menu_crm') {
                    let check = false;
                    mItem.items.forEach(e => {
                        if (e.id !== 'menu_crm_list') {
                            check = true;
                        }
                    });
                    if (check) {
                        mItem.items = mItem.items.filter(i => i.id !== 'menu_client_account');
                    }
                }
            });

            this.model = this.model.filter(i => i.id !== 'client_dasboard');
        }

        if (this.authService.isClientRole()) {
            this.model = this.model.filter(i => i.id !== 'menu_operrtel');
            // const foundMenu = this.model.find(item => item.id === 'menu_crm');
            // if (!foundMenu) {
            //     const crmMenu = {
            //         id: 'menu_crm', label: 'CRM', icon: 'group',
            //     };
            //     this.model.push(crmMenu);
            // }
            // this.model.forEach((mItem: any) => {
            //     this.model = this.model.filter(item => item.id !== 'menu_accounting');
            //     if (mItem.id === 'menu_crm') {
            //         const crmMenu = [
            //             { label: 'Billing Report', routerLink: ['/app/job-follow-up/billing-report'] },
            //             { label: 'Total Job', routerLink: ['/app/job-follow-up/billing-total-job'] },
            //             { label: 'Details Report', routerLink: ['/app/job-follow-up/billing-details-report'] },
            //             { label: 'Correction Report', routerLink: ['/app/job-follow-up/billing-correction-report'] }
            //         ];
            //         mItem.items = crmMenu;
            //     }
            // });
            console.log(this.model);
            const foundMenu = this.model.find(item => item.id === 'billing_report');

            if (!foundMenu) {
                const crmMenu = {
                    label: 'Billing Report', icon: 'assessment', id: 'billing_report',
                };
                this.model.push(crmMenu);
            }

            // const foundMenu1 = this.model.find(item => item.id === 'menu_survey_land');
            // if (!foundMenu1) {
            //     const crmMenu1 = {
            //         label: 'Survey Land', icon: 'assessment', id: 'menu_survey_land',
            //     };
            //     this.model.push(crmMenu1);
            // }

            this.model.forEach((mItem: any) => {
                this.model = this.model.filter(item => item.id !== 'menu_accounting');
                if (mItem.id === 'billing_report') {
                    const crmMenu = [
                        {
                            label: 'MAS', id: 'mas_report',
                            items: [
                                { label: 'Total Job', routerLink: ['/app/job-follow-up/billing-total-job'] },
                                { label: 'General Report', routerLink: ['/app/job-follow-up/billing-report'] },
                                { label: 'Details Report', routerLink: ['/app/job-follow-up/billing-details-report'] },
                                { label: 'Correction', routerLink: ['/app/job-follow-up/billing-correction-report'] },
                                { label: 'Upload History', routerLink: ['/app/job-follow-up/billing-upload-history'] },
                                { label: 'Billing Client Status', routerLink: ['/app/job-follow-up/billing-client-status'] }
                            ]
                        }, {
                            label: 'LOG', id: 'log_report',
                            items: [
                                { label: 'Log Total Job', routerLink: ['/app/job-follow-up/billing-log-total-job'] },
                                { label: 'Details Report', routerLink: ['/app/job-follow-up/billing-log-details-report'] }
                            ]
                        }
                    ];
                    mItem.items = crmMenu;
                }

                if (mItem.id === 'menu_survey_land') {
                    mItem.items = mItem.items.filter(i => i.id !== 'menu_service_rate');
                }

            });
            this.model.push({ label: 'Child Account', id: 'client_child_account', icon: 'persion' });
            // this.model.sort((a, b) => a.label.localeCompare(b.label));
        }

        if (this.authService.isChildClientRole()) {
            const foundMenu = this.model.find(item => item.id === 'billing_report');
            if (!foundMenu) {
                const crmMenu = {
                    label: 'Billing Report', icon: 'assessment', id: 'billing_report',
                };
                this.model.push(crmMenu);
            }

            this.model.forEach((mItem: any) => {
                this.model = this.model.filter(item => item.id !== 'menu_accounting');
                if (mItem.id === 'billing_report') {
                    const crmMenu = [
                        {
                            label: 'MAS', id: 'mas_report',
                            items: [
                                { label: 'Total Job', routerLink: ['/app/job-follow-up/billing-total-job'] },
                            ]
                        }
                    ];
                    mItem.items = crmMenu;
                }
            });
            // this.model.sort((a, b) => a.label.localeCompare(b.label));
        }

        this.locationManagementService.getLocationMenu({companyId: this.authService.getCurrentCompanyId()}).subscribe(resLm => {
            // this.model.push(resLm.data);
            if (resLm && resLm.data) {
                const getCookieJSON = localStorage.getItem('_globals');
                let getCookieObj: any;
                if (getCookieJSON) {
                    getCookieObj = JSON.parse(getCookieJSON);
                    let menuList: any[] = getCookieObj.menuList;

                    if (getCookieObj && getCookieObj.menuList && getCookieObj.menuList.length > 0) {
                        let locationMenu: any;
                        let locationMenuItems: any[] = resLm.data.items.filter((itemLm: any) => (menuList.includes(itemLm.menuId.toString())));

                        if (locationMenuItems && locationMenuItems.length > 0) {
                            locationMenu = resLm.data;
                            for (let menu of locationMenuItems) {
                                if (menu.items && menu.items.length) {
                                    menu.items = menu.items.filter(item => item.label == 'Asset Management');
                                    for (let submenu of menu.items) {
                                        if (submenu.routerLink && submenu.routerLink.length) {
                                            submenu.routerLink[0] = submenu.routerLink[0] + '/' + (menu.menuId%9999);
                                        }
                                    }
                                }
                            }
                            locationMenu.items = locationMenuItems;
                            this.model.push(locationMenu);
                        }
                    }
                }
                
            }
        });
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }

    changeLayout(theme) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }

    getMenuByRole(): MenuItem[] {
        let menuList = this.allMenus.getAllMenus().filter(m => !m.hideOnMenuBar);
        if (this.authService.isEmployeeRole()) {
            menuList = menuList.map(menu => {
                if (menu.id === 'menu_employee_list') {
                    let findEmployeeProfile = {};
                    let employeeDocument = {};
                    const menuExisted = menu.items.filter(menuChild => menuChild.id !== 'menu_employee');
                    const menuEmployee = menu.items.find(menuChild => menuChild.id === 'menu_employee');
                    if (menuEmployee) {
                        findEmployeeProfile = menuEmployee.items.find(menuChild2 =>
                            menuChild2.id === 'menu_employee_profile');
                        employeeDocument = menuEmployee.items.find(menuChild2 =>
                            menuChild2.id == 'menu_employee_document');
                    }
                    return { ...menu, items: [{ ...findEmployeeProfile }, {... employeeDocument}, ...menuExisted] };
                }
                return menu;
            });
        }
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
            if (getCookieObj.company && getCookieObj.company.serviceTypes && getCookieObj.company.serviceTypes.length) {
                let menuIds = [];
                if (!this.authService.isEmployeeRole()) {
                    getCookieObj.company.serviceTypes.forEach(type => {
                        menuIds = menuIds.concat(ServiceType[type]);
                    });
                } else {
                    menuIds = menuIds.concat(ServiceType['Employee']);
                }

                menuIds = menuIds.concat(getCookieObj.menuList);
                menuIds = menuIds.map(Number);
                menuList.forEach(e => {
                    if (e['items']) {
                        e['items'] = e['items'].filter(item => menuIds.indexOf(item.menuId) > -1 || (item.uid && menuIds.indexOf(item.uid) > -1));
                        e['items'].forEach(sub => {
                            if (sub['items']) {
                                sub['items'] = sub['items'].filter(item => menuIds.indexOf(item.menuId) > -1 || (item.uid && menuIds.indexOf(item.uid) > -1));
                            }
                        });
                    }
                });
                menuList = menuList.filter(item => (item.uid && menuIds.indexOf(item.uid) > -1));
                const rtn = [];
                for (let i = 0; i < menuList.length; i++) {
                    const menuGranted = this.isMenuAccessGranted(menuList[i]);
                    if (menuGranted) {
                        rtn.push(menuGranted);
                    }
                }
                return _.compact(rtn);
            } else {
                const rtn = [];
                for (let i = 0; i < menuList.length; i++) {
                    const menuGranted = this.isMenuAccessGranted(menuList[i]);
                    if (menuGranted) {
                        rtn.push(menuGranted);
                    }
                }
                return _.compact(rtn);
            }
        } else {
            const rtn = [];
            for (let i = 0; i < menuList.length; i++) {
                const menuGranted = this.isMenuAccessGranted(menuList[i]);
                if (menuGranted) {
                    rtn.push(menuGranted);
                }
            }
            return _.compact(rtn);
        }
    }

    get1MenuByRole(menuItem: any, level: number): MenuItem {
        let mni: any;
        if (_.includes(menuItem.show, level)) {
            mni = {};
            mni.label = menuItem.label;
            mni.routerLink = menuItem.routerLink;
            mni.icon = menuItem.icon;
            mni.id = menuItem.id;
            if (menuItem.items && menuItem.items.length > 0) {
                mni.items = [];
                for (let i = 0; i < menuItem.items.length; i++) {

                    mni.items.push(this.get1MenuByRole(menuItem.items[i], level));
                }
            }
            mni.items = _.compact(mni.items);
        }
        return mni;
    }

    private isMenuAccessGranted(menuItem: any): MenuItem {
        this.loggedInUserMenus = this.authService.getLoggedInUserMenuList();
        // this.loggedInUserMenus.push('1056');
        // this.loggedInUserMenus.push('1027');
        // if (this.authService.isContractorRole()) {
        //     this.loggedInUserMenus.push('710');
        //     this.loggedInUserMenus.push('711');
        //     this.loggedInUserMenus.push('704');
        //     this.loggedInUserMenus.push('701');
        //     this.loggedInUserMenus.push('70');
        //     this.loggedInUserMenus.push('1666');
        //     this.loggedInUserMenus.push('1667');
        // }
        // if (this.authService.isEmployeeRole()) {
        //     this.loggedInUserMenus.push('677');
        //     // this.loggedInUserMenus.push('1101');
        //     if (this.authService.getEmployeeType() === 'contractor') {
        //         this.loggedInUserMenus.push('56');
        //         this.loggedInUserMenus.push('703');
        //     }
        //     this.loggedInUserMenus.push('701');
        //     this.loggedInUserMenus.push('70');
        //     this.loggedInUserMenus.push('500');
        //     // this.loggedInUserMenus.push('1153');
        //     // this.loggedInUserMenus.push('1154');
        //     // this.loggedInUserMenus.push('1155');

        //     if (this.loggedInUserMenus.includes('9020')) {
        //         const arr = ['1019', '1020', '1021', '1022', '1024', '1034'];
        //         this.loggedInUserMenus.push(...arr);
        //     }
        // }
        // if (this.authService.isEmployeeRole() || this.authService.isSuper()) {
        //     this.loggedInUserMenus.push('78');
        //     this.loggedInUserMenus.push('1055');
        // }

        // if (!this.authService.isEmployeeRole() && !this.authService.isContractorRole()) {
        //     this.loggedInUserMenus.push('1012');
        //     // this.loggedInUserMenus.push('1000');
        // }

        // if (this.authService.isClientRole()) {
        //     this.loggedInUserMenus.push('70');
        //     this.loggedInUserMenus.push('701');
        //     this.loggedInUserMenus.push('57');
        //     this.loggedInUserMenus.push('1670');
        //     this.loggedInUserMenus.push('1671');
        // }
        // if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAd()
        //     || this.authService.isSubCompanyAd()) {
        //     // this.loggedInUserMenus.push('1666');
        //     // this.loggedInUserMenus.push('1667');
        //     // this.loggedInUserMenus.push('86');
        //     if (this.authService.isSuper() || this.authService.isSubSuper()) {
        //         this.loggedInUserMenus.push('85');
        //     }
        // }
        

        // https://login.operrwork.com/#/app/tm/OA%3D%3D?t=1047-Employee-Group-new-Logic
        // if (this.authService.isEmployeeRole()) {
        //     this.loggedInUserMenus = [];
        //     const group = this.authService.getLoggedInUserGroup();
        //     if (group && group.menuList && group.menuList.length > 0) {
        //         group.menuList.forEach(item => {
        //             if (!isNaN(item) && item !== 'null') {
        //                 this.loggedInUserMenus.push(item);
        //             }
        //         });
        //     } else {
        //         return null;
        //     }
        //     if (this.loggedInUserMenus.indexOf('45') > -1) {
        //         this.loggedInUserMenus.push('500');
        //     }
        // }
        let mni: any;
        if (menuItem.menuId) {
            if (this.loggedInUserMenus.indexOf(menuItem.menuId.toString()) > -1) {
                mni = {};
                mni.label = menuItem.label;
                mni.routerLink = menuItem.routerLink;
                mni.icon = menuItem.icon;
                mni.command = menuItem.command;
                mni.id = menuItem.id;

                if (menuItem.items && menuItem.items.length > 0) {
                    mni.items = [];
                    menuItem.items.forEach((it) => {
                        const mnii = this.isMenuAccessGranted(it);
                        if (mnii) {
                            mni.items.push(mnii);
                        }

                    });
                }

                return mni;
            }
        } else {
            mni = {};
            mni.label = menuItem.label;
            mni.id = menuItem.id;
            if (menuItem.command) {
                mni.command = menuItem.command;
            }
            if (menuItem.routerLink) {
                mni.routerLink = menuItem.routerLink;
            }

            mni.icon = menuItem.icon;
            if (menuItem.items && menuItem.items.length > 0) {
                mni.items = [];

                menuItem.items.forEach((it) => {
                    const mnii = this.isMenuAccessGranted(it);
                    if (mnii) {
                        mni.items.push(mnii);
                    }

                });
            }
            mni.items = _.compact(mni.items);
            if ((mni.items && mni.items.length > 0) || (mni.routerLink && (!menuItem.items || menuItem.items.length === 0))) {
                return mni;
            }
        }
        // return mni;
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
                <a [href]="child.url||'#'" [id]="child.id" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text" style="word-break: normal !important">{{child.label | translate}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items && child.items.length > 0">keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>

                <a [style]="child.icon === '-1' ? {display : 'none'} : ''" (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                   [routerLink]="child.routerLink" [id]="child.id" routerLinkActive="active-menuitem-routerlink"
                       [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null"
                   [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text" style="word-break: normal !important">{{child.label | translate}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items && child.items.length > 0">keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
                    [@children]="(app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    activeIndex: number;

    routerEventsSubscribe;

    constructor(public app: AppComponent, public router: Router, public location: Location, public appMenu: AppMenuComponent) {
        this.routerEventsSubscribe = this.router.events.subscribe((e: RouterEvent) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                if (e.url.indexOf('platform-admin') !== -1) {
                    // set platform admin active
                    // console.log('set platform admin active');
                    //    if (this.root) {
                    //        this.activeIndex = 3;
                    //    } else {
                    //        this.activeIndex = 0;
                    //    }
                }
                if (e.url.indexOf('agency-admin') !== -1) {
                    // set agency admin active
                }
                if (e.url.indexOf('company-admin') !== -1) {
                    // set company admin active
                }
            }
        });
    }

    itemClick(event: Event, item: MenuItem, index: number) {
        if (item.id === 'client_child_account') {
            if (localStorage.getItem('_pastDueInvoice')) {
                return true;
            } else {
                this.app.getChildClient();
                return true;
            }
        }
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
            event.preventDefault();
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex as number === index) ? -1 : index;
        }

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.appMenu.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.mobileMenuActive = false;
            }

            if (this.app.isHorizontal()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
            this.activeIndex = null;
        }
    }

    ngOnDestroy() {
        if (this.routerEventsSubscribe) {
          this.routerEventsSubscribe.unsubscribe();
        }
    }
}
