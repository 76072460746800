import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslatePipe} from '@ngx-translate/core';
import {AuthService} from 'app/shared/service/auth/auth.service';
import {MessageService} from 'primeng';
import {FormValidatorService} from '../../../../shared/service/form-validator/form-validator.service';
import {FundraisersService} from '../../service/fundraisers.service';
import {CandidateService} from '../../../candidate/service/candidate.service';
import {FundraisersDetailModel} from '../../model/fundraisers_detail.model';
import {ActivatedRoute, Router} from '@angular/router';
@Component({
    selector: 'app-contribute-form',
    templateUrl: './contribute-form.component.html',
    styleUrls: ['./contribute-form.component.scss']
})
export class ContributeFormComponent implements OnInit {
    constructor(
        private fundraisersService: FundraisersService,
        private candidateService: CandidateService,
        private messageService: MessageService,
        public formValidator: FormValidatorService,
        private translatePipe: TranslatePipe,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.route.params.subscribe(params => this.fundraisersId = +params.id);
    }

    mode: string;
    fundraisersId: number = null;
    @Input() readOnly: Boolean = false;

    disableInput;
    @Input() contentDataForm: ElementRef;
    content = new FundraisersDetailModel();

    isPlatformAdmin = false;
    isClientRole = false;
    openDialog = false;
    isShowDetailEmployee = false;
    isShowOther = false;
    isEditAble = false;
    candidateId: any;

    contributeForm: UntypedFormGroup;
    selectData: any;
    candidateName: any;
    fundraisingTitle: any;

    listRaisedAmount = [
        {value: '25', lable: '25'},
        {value: '50', lable: '50'},
        {value: '75', lable: '75'},
        {value: '100', lable: '100'},
        {value: '175', lable: '175'},
        {value: 'Other', lable: 'Other'},
    ];
    selectRaisedAmount = '$0.00';
    cols: any[] = [
        {field: 'type', label: 'Type', sort: 'number'},
        {field: 'startDate', label: 'Start Date', sort: 'text'},
        {field: 'endDate', label: 'End Date', sort: 'text'},
        {field: 'status', label: 'Status', sort: 'text'},
    ];
    sumbitClicked = false;

    remainCharacters = 250;
    checkLengthNote = true;

    fromDate;
    toDate;
    isCheckout;
    capLimitIndividual;
    fundRaiserWarningMessage;
    fundraisersDetails = [];
    ngOnInit(): void {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.disableInput = this.mode === 'view' ? true : false;
        // init form group
        this.contributeForm = new UntypedFormGroup({
            employer: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
            occupation: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
            address: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
            aptSuiteFloor: new UntypedFormControl({value: '', disabled: false}),
            city: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
            state: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
            zipCode: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
        });

        if (this.fundraisersId) {
            this.fundraisersService.findFundraisersById(this.fundraisersId).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.content = new FundraisersDetailModel();
                    this.selectData = resObj.data;
                    this.openDialog = true;
                    this.isShowDetailEmployee = false;
                    this.candidateName = this.selectData.candidateName;
                    this.fundraisingTitle = this.selectData.fundraisingTitle;
                    this.candidateId = resObj.data.candidateId;
                    this.capLimitIndividual = resObj.data.capLimitIndividual;
                    this.fundraisersService.findFundraisersDetail(this.fundraisersId).subscribe(res1 => {
                        const resObj1: any = res1;
                        if (resObj1.status === 'SUCCESS' && resObj1.data) {
                            this.fundraisersDetails = resObj1.data;
                        }
                    });
                    const currentDate = new Date();
                    const fFromDate = new Date(resObj.data.fromDate);
                    const fToDate = new Date(resObj.data.toDate);
                    if (currentDate < fFromDate || currentDate > fToDate) {
                        this.fundRaiserWarningMessage = 'Please note that the fundraising campaign has not yet started or has already ended at this time. ' +
                            'We appreciate your interest and encourage you to stay tuned for future opportunities to support our cause. Thank you for your understanding.';
                    } else {
                        this.fundRaiserWarningMessage = undefined;
                    }
                }
            });
        }
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
            return false;
        }
        return true;
    }

    saveFundraisersDetail(fundraisersDetailForm) {
        if (!this.formValidator.validateForm(this.contributeForm, fundraisersDetailForm)) {
            this.messageService.add({
                severity: 'error',
                summary: this.translatePipe.transform('InvalidData'),
                detail: this.translatePipe.transform('PleaseEnterRequiredFields')
            });
            this.isCheckout = false;
            return;
        }
        const employer = this.content.employer;
        const address = this.content.address;
        const totalRaisedAmount = this.fundraisersDetails.filter(detail => detail.employer === employer && detail.address === address)
            .map(detail => detail.raisedAmount).reduce((a, b) => a + b, 0);

        if (this.content.raisedAmount + totalRaisedAmount > this.capLimitIndividual) {
            this.fundRaiserWarningMessage = 'Thank you for your generosity! However, the amount you\'ve donated exceeds the maximum limit per user. ' +
                'The maximum limit you can contribute this time is $' + this.capLimitIndividual;
            this.isCheckout = false;
            return;
        } else {
            this.fundRaiserWarningMessage = undefined;
            this.isCheckout = true;
        }
    }

    addFundraisersDetail() {
      if (!this.isPlatformAdmin) {
        this.content.companyId = this.authService.getCurrentCompanyId();
      }
      this.content.candidateId = this.candidateId;
      this.content.fundraisersId = this.fundraisersId;
      this.content.createdByUsr = (this.authService.getUserInfo() && this.authService.getUserInfo().username) ? this.authService.getUserInfo().username : this.content.employer;
      this.content.raisedAmount = Number(this.selectRaisedAmount.replace('$', ''));
      const data = { ...this.content };
      this.fundraisersService.addFundraisersDetail(data).subscribe(() => {
        this.messageService.add({
          severity: 'info',
          summary: this.translatePipe.transform('Created'),
          detail: this.translatePipe.transform('Fundraisers Detail created successfully')
        });
        /*this.openDialog = false;
        this.isShowDetailEmployee = false;*/
        // setTimeout(() => this.router.navigate(['/app/payments/method']), 1000);
      }, error1 => {
      });
    }

    changeRaisedAmount(data) {
        this.selectRaisedAmount = data.value;
        let temp = '';
        temp = this.selectRaisedAmount.toString().replace('$', '');
        if ((Number(temp))) {
            this.selectRaisedAmount = '$' + Number(temp).toFixed(2);
        } else {
            this.selectRaisedAmount = '$0.00';
        }
        this.content.raisedAmount = Number(this.selectRaisedAmount.replace('$', ''));
    }

    showDialog() {
        this.isShowOther = true;
        if (this.selectRaisedAmount !== '$0.00') {
            this.isEditAble = true;
        }
    }

    formatData() {
        let temp = '';
        temp = this.selectRaisedAmount.toString().replace('$', '');
        if ((Number(temp))) {
            this.selectRaisedAmount = '$' + temp;
            this.isEditAble = true;
        } else {
            this.selectRaisedAmount = '$0.00';
            this.isEditAble = false;
        }
    }

    back(event?) {
      this.isCheckout = false;
      if (event) {
        this.isShowDetailEmployee = false;
      }
        this.router.navigate(['app/vrm/contribute-thankyou']);
    }

    showDetail() {
        if (this.authService.getUserInfo()) {
            const {firstName, lastName, username} = this.authService.getUserInfo();
            this.content.employer = firstName ? `${firstName} ${lastName}` : username;
            this.contributeForm.get('employer').setValue(this.content.employer);
        }
        if (this.content.raisedAmount > this.capLimitIndividual) {
            this.fundRaiserWarningMessage = 'Thank you for your generosity! However, the amount you\'ve donated exceeds the maximum limit per user. Our maximum limit per user is $'
                + this.capLimitIndividual + '. We sincerely appreciate your support and encourage you to consider contributing within the specified limit. Thank you for understanding.';
            this.isShowDetailEmployee = false;
        } else {
            this.fundRaiserWarningMessage = undefined;
            this.isShowDetailEmployee = true;
        }
    }

    changeOtherPrice() {
        this.isShowOther = false;
        this.content.raisedAmount = Number(this.selectRaisedAmount.replace('$', ''));
    }

    openProfilePhoto() {
        window.open(this.selectData.profilePhotoUrl, '_blank');
    }
}
