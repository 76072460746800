<div class="card" >


  <p-panel id="table-ppanel">
    <p-header>
      <span>{{'Client Contract List' | translate}} ({{totalRecords}})</span>
    </p-header>

    <p-table #dt [columns]="cols" [value]="transactionList" dataKey="id" [lazy]="true"
             (onLazyLoad)="loadContentsLazy($event)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,20]"
             [totalRecords]="totalRecords" [resizableColumns]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="adjust-padding" pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': col.display}" [hidden]="col.field==='actions' && !(isEditAllowed || isDeleteAble)">
            <ng-container [ngSwitch]="col.field">
<!--            <span *ngSwitchDefault>-->
<!--              {{col.label}}-->

<!--              <span-->
<!--                      (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; selectedSortOption = col.sortOptions">-->
<!--                <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>-->
<!--              </span>-->

<!--            </span>-->
<!--              <span *ngSwitchCase="'actions'">-->
<!--              {{col.label}}-->
<!--            </span>-->
              <ng-container [ngSwitch]="col.field">
                <span *ngSwitchDefault>
                    {{col.label}}
                  <span *ngIf="col.sort">
                        <span
                            (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;selectedSortOption = col.sortOptions">
                            <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                        </span>
                    </span>
                </span>
              </ng-container>
            </ng-container>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-i="rowIndex">

        <tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
          <td>{{i+1}}</td>
          <td>{{rowData.clientName}}</td>
          <td>{{rowData.productName}}</td>
          <td>{{ rowData.date | date: 'MM/dd/yyyy h:mm:ss' }}</td>
          <td>{{rowData.client.assignedTo}}</td>


          <td>{{rowData.user}}</td>
        </tr>


      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>

        <tr *ngIf="!loading" class="text-center">
          <td [attr.colspan]="cols.length">
            No Data
          </td>
        </tr>

      </ng-template>
      <ng-template pTemplate="footer" let-columns>
        <tr *ngIf="loading" class="text-center">
          <td [attr.colspan]="cols.length">
            Loading...
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
</div>

<p-dialog header="Contract Details" [baseZIndex]="99999999" *ngIf="displayContractDetail" showEffect="fade" styleClass="w-50"
          [autoZIndex]="true" [(visible)]="displayContractDetail" [contentStyle]="{'max-height':'400px'}">
  <div class="ui-g invoice-details">

    <div >
      <div class="ui-g">
        <div class="ui-g-6">
          Client Name: {{contractSelected.clientName}}
        </div>
        <div class="ui-g-6">
          Total Contract: {{contractList.length}}
        </div>
      </div>
      <p-table [value]="contractList">

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th width="10%">
              #
            </th>
            <th>
              Contract Signed
            </th>
            <th width="15%">
              Rate
            </th>
            <th width="15%">
              % Rate
            </th>
            <th width="20%">
              Monthly Rate
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
          <tr>
            <td>{{index +1}}</td>
            <td>{{rowData?.productName}}</td>
            <td>
              {{rowData.tripRate}}
            </td>

            <td>
              {{rowData.rate}}
            </td>
            <td>
              {{rowData.monthlyRate}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="ui-g-12" style="text-align: center;">
    <button pButton type="button" label="Cancel" (click)="cancelDialog()"></button>
  </div>
</p-dialog>


<p-overlayPanel [appendTo]="'body'" #op1>

  <p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
    <ng-template let-col pTemplate="item">
      <div>
        <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
        <span>{{col.name}}</span>
      </div>
    </ng-template>
  </p-listbox>

</p-overlayPanel>
<p-overlayPanel [appendTo]="'body'" #op2>

  <p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption"
    (onChange)="sortingClick(selectedSortOption)">
    <ng-template let-col pTemplate="item">
      <div>
        <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
        <span>{{col.name}}</span>
      </div>
    </ng-template>
  </p-listbox>

</p-overlayPanel>


<p-confirmDialog header="Confirmation" icon="fa-solid fa-circle-question" width="525"></p-confirmDialog>
