<form [formGroup]="formGroup">
  <div class="ui-g form-group">
    <ng-container *ngFor="let field of documentTemplate?.fields; let i=index">
      <div class="ui-g-12" [ngSwitch]="field?.dataType">
        <div class="py-2" *ngSwitchCase="DocumentDataType[DocumentDataType.TEXT]">
          <label translate>{{field?.attribute?.placeHolder | translate}}
            <span class="text-red" *ngIf="field && field.required">*</span>
          </label>
          <input *ngIf="field?.attribute?.placeHolder != 'Note'" pInputText type="text" formControlName="{{field.id}}" [required]="field?.required" maxlength="{{field?.maxLength && field?.maxLength != -1 ? field?.maxLength : 500}}">
          <textarea (paste)="numberPasteMaxWordsLengthOnly($event, 300)" (keypress)="countRemainChars($event)" *ngIf="field?.attribute?.placeHolder == 'Note'" pInputTextarea rows="7" maxlength="500" style="resize: none;font-size: 16px !important" formControlName="{{field.id}}" [required]="field?.required" placeholder="{{'Enter note or description' | translate}}"></textarea>
          <span class="pull-right" style="font-size: 12px">{{this.characterCount || 0}}/500</span>
        </div>

        <div class="py-2"
          *ngSwitchCase="DocumentDataType[DocumentDataType.DATE] || DocumentDataType[DocumentDataType.DATETIME]">
          <label>{{field?.attribute?.placeHolder | translate}}
            <span class="text-red" *ngIf="field && field.required">*</span>
          </label>
          <p-calendar styleClass="w-100" inputStyleClass="w-100" formControlName="{{field.id}}"
            (onSelect)="validateDateTime(field.id, $event)" [required]="field?.required"></p-calendar>

          <span class="md-inputfield ng-star-inserted" *ngIf="showMessage">
            <div class="ui-message ui-messages-error ui-corner-all" translate>
              Please select the date after the current date.
            </div>
          </span>
        </div>

        <div class="py-2"
          *ngSwitchCase="DocumentDataType[DocumentDataType.DECIMAL] || DocumentDataType[DocumentDataType.NUMBER]">
          <label translate>{{field?.attribute?.placeHolder | translate}}
            <span class="text-red" *ngIf="field && field.required">*</span>
          </label>
          <input class="w-100" pInputText type="number" formControlName="{{field.id}}" [required]="field?.required">
        </div>


        <div *ngSwitchCase="DocumentDataType[DocumentDataType.BOOLEAN]" style="line-height: 55px">
          <label>
            {{field?.attribute?.placeHolder | translate}}
            <span class="text-red" *ngIf="field && field.required">*</span>
          </label>
          <p-radioButton name="group{{field.id}}" label="Yes" value="true"
            formControlName="{{field.id}}"></p-radioButton>
          <p-radioButton name="group{{field.id}}" label="No" value="false"
            formControlName="{{field.id}}"></p-radioButton>
        </div>
      </div>
    </ng-container>
  </div>

</form>