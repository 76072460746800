import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';

@Component({
  selector: 'app-crm-dashboard-layout',
  templateUrl: './crm-dashboard-layout.component.html',
  styleUrls: ['./crm-dashboard-layout.component.scss']
})
export class CrmDashboardLayoutComponent implements OnInit, OnDestroy {

  activeItem: MenuItem;
  subscription: Subscription;
  defaultBreadCrumbs: MenuItem[] = [{ label: 'Dashboard', routerLink: ['dashboard'] }];

  initBreadCrumbs() {
    this.defaultBreadCrumbs = [{ label: 'Dashboard' }];
  }
  routerEventsSubscribe;

  constructor(private router: Router,
    private breadcrumbService: BreadcrumbService) {
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('/app/crm-dashboard') >= 0) {
          this.defaultBreadCrumbs = [{ label: 'Dashboard', routerLink: ['/app/dashboard'] }];
          this.defaultBreadCrumbs.push({ label: 'CEO Dashboard'});
        }
        this.breadcrumbService.setItems(this.defaultBreadCrumbs);
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }
}

