import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {Table} from 'primeng/table';
import {MenuItem, Message, MessageService, SelectItem} from 'primeng/api';
import {Company} from '../../../company/model/company.model';
import {Agency} from '../../../payroll-setting/model/agency.model';
import {RoleLevel} from '../../../layout/component/app-menu/role-level';
import {User} from '../../../shared/model/user/user.model';
import {PaymentInvoiceService} from '../../service/payment-invoice.service';
import {PaymentInvoice} from '../../model/payment-invoice.model';
import {PhonePackageService} from '../../../phonesystem/service/phone-package.service';
import {PhonePackage} from '../../../phonesystem/model/phone-package.model';
import {Subject} from 'rxjs/Subject';
import {FileUtility} from '../../../shared/utility/file.utility';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {DATE_FORMATS} from 'app/shared/data/config-common';
import {ClientService} from '../../../crm/service/client.service';
import {ProductService} from '../../../crm/service/product.service';
import {DatePipe} from '@angular/common';
import {TransactionDetail} from 'app/transaction-type/model/transaction-detail.model';
import {ContactService} from 'app/crm/service/contact.service';
import {FollowUpBillingInvoice} from '../../model/follow-up-billing-invoice.model';
import {FollowUpBillingInvoiceService} from '../../service/follow-up-billing.service';
import {BrowserNotification} from 'app/shared/model/browser-notification.model';
import {BrowserNotificationService} from 'app/shared/service/browser-notification/browser-notification.service';
import {OverlayPanel} from 'primeng';
import {OperatorService} from 'app/employee/service/v2/operator.v2.service';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import {MonitoringDetailsService} from 'app/admin/process-monitor/service/monitoring-details.service';
import {PaymentNotificationService} from 'app/payment/service/payment-notification.service';
import {ProjectService} from 'app/project/service/project.service';
import {TransactionTypeService} from 'app/transaction-type/service/transaction-type.service';
import {TransactionService} from 'app/transaction-type/service/transaction.service';
import {FirebaseNotificationService} from 'app/shared/service/firebase-notification.service';
import {NotificationService} from 'app/notification/service/notification.service';
import {QuoteService} from 'app/quote/service/quote.service';
import {forkJoin} from 'rxjs-compat/observable/forkJoin';
import {CompanyService} from 'app/company/service/company.service';
import {buildSurveyLandPdfFile} from 'app/quote/model/quote.model';
import {DepartmentService} from 'app/department/service/department.service';
import {RESPONSE_STATUS} from 'app/shared/model/constants';
import {EmployeeDepartmentService} from 'app/department/service/employee-department.service';
import {EmployeeService} from 'app/employee/service/employee.service';
import {UtilsService} from 'app/shared/service/utils.service';
import {TranslatePipe} from '@ngx-translate/core';
import {GeneralInvoiceService} from '../../../general-invoice/service/general-invoice.service';
import {PunchBillingService} from 'app/billing/service/punch-billing.service';
import {Chart} from 'chart.js';
import {PayrollSettingService} from 'app/payroll-setting/service/payroll-setting.service';
import {PaymentTransactionService} from '../../service/payment-transaction.service';
import {ExternalPaymentService} from 'app/payment/service/external-payment.service';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {TransactionDetailsHistoryService} from '../../../transaction-type/service/transaction-details-history.service';
import {DocumentsService} from '../../../shared/service/documents-upload/documents.service';
import {TransactionDetailsService} from '../../../transaction-type/service/transaction-details.service';
import {CompanyAdminService} from '../../../admin/company-admin/service/company-admin.service';

declare let $: any;

@Component({
  selector: 'app-payment-invoice-list',
  templateUrl: './payment-invoice-list.component.html',
  styleUrls: ['./payment-invoice-list.component.scss'],
  providers: [PaymentInvoiceService, DropDownsService, OperatorService,
    PhonePackageService, FollowUpBillingInvoiceService, BrowserNotificationService, TransactionTypeService, TransactionService,
    QuoteService, CompanyService, DepartmentService, EmployeeDepartmentService, PunchBillingService, PayrollSettingService,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }]
})
export class PaymentInvoiceListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('op3', { static: true }) op3: OverlayPanel;
  @ViewChild('op1', { static: true }) op1: OverlayPanel;
  @ViewChild('op2', { static: true }) op2: OverlayPanel;
  detailEnable = false;
  contentList: PaymentInvoice[] = [];
  contentTransactionList: TransactionDetail[] = [];
  selectedContent: PaymentInvoice = new PaymentInvoice();
  followUpBillingInvoice: FollowUpBillingInvoice;
  invoiceDetail: PaymentInvoice = new PaymentInvoice();
  details = [];
  totalRecords: number = 0;
  items: MenuItem[];
  msgs: Message[] = [];
  private destroyed$ = new Subject<void>();
  loading = false;
  totalServiceFee = 0;
  totalServiceFeePaid = 0;
  totalOutstandingBalance = 0;
  tableRows = 10;
  sumFee = 0;
  balance = 0;
  balanceTxt = '';
  sumTotalAmount = 0;
  sumFeeTxt: string;
  sumTotalAmountTxt: string;
  sumOutStandingBalanceTxt: string;
  sumOutStandingBalance = 0;
  sumPaidFee = 0;
  sumPaidFeeTxt: string;
  @ViewChild('dt', { static: true }) table: Table;
  userPermission: Boolean;
  rowData: any;
  allCompanyList: any = [];
  allAgencyList: any = [];
  phonePackages: PhonePackage[];
  invoiceNumber: string;
  searchText: string;
  selectedCompany: Company;
  selectedCompany1: Company;
  selectedMethodContact: any;
  selectedAgency: Agency;
  selectedPhonePackage: PhonePackage;
  selectedStatus: string[];
  user: User;
  checkFileSize = false;
  selectedFiles: File;
  loggedInRole: number;
  disableCompany = true;
  disableAgency = false;
  paymentInvoiceData: any = {};
  isNew = false;
  now = new Date();
  fromDate: Date;
  toDate: Date;
  rangeDates: any;
  statusTypes: any[] = [
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Partially Paid', value: 'Partial Paid' },
    { label: 'Paid', value: 'Paid' },
    { label: 'Pending', value: 'Pending' }
  ];

  enabledGenerate = false;
  rowsPerPageOptions: any[];
  rowsPerPageItems: SelectItem[];
  loadingSpinner = false;
  size: number;
  page: number;
  rows = 10;
  sortF: any = '';
  @ViewChild('notesEditor', { static: true })
  followUpNoteEditor: any;
  paymentMethod = null;
  selectedPaymentMethods = [];
  followUpDate: any;
  showConfirmDialog = false;
  message = '';
  @ViewChild('notesEditor', { static: true })
  concernEditor: any;
  charactersLength = 0;
  exportOptions = [
    { name: 'View Detail', value: 'view' },
    { name: 'Download invoice', value: 'download' },
  ];
  invoiceRef: any = {};
  exportAs: any;
  MAX_LENGTH = 500;
  cols: any[] = [
    { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text', show: true },
    { field: 'invoiceType', label: 'Type', sortOptions: '', sort: 'text', show: true },
    { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number', show: true },
    { field: 'clientId', label: 'Client', sortOptions: '', sort: 'text', show: true },
    { field: 'dueDate', label: 'Due', sortOptions: '', sort: 'number', show: true },
    { field: 'totalAmount', label: 'Amount', sortOptions: '', sort: 'number', show: true },
    { field: 'fee', label: 'Fee', sortOptions: '', sort: 'number', show: true },
    { field: 'paidFee', label: 'Paid', sortOptions: '', sort: 'number', show: true },
    { field: 'dueIn', label: 'Due In', show: true },
    { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number', show: true },
    { field: 'quickPay', label: 'Quick Pay', show: true },
    { field: 'paymentMethod', label: 'Method', sortOptions: '', sort: 'text', show: true },
    { field: 'Email Reminder', label: 'Reminder', sortOptions: '', show: true },
    { field: 'reminderCount', label: 'Sent', sortOptions: '', sort: 'number', show: true },
    { field: 'notes', label: 'FollowUps', sortOptions: '', show: true }
  ];
  sortField: string;
  sortOrder: number = 0;
  textSortOptionsClient = [
    { name: 'Sort A to Z', value: 'ASC', img: 'a-z' },
    { name: 'Sort Z to A', value: 'DESC', img: 'z-a' }
  ];
  numberSortOptionsClient = [
    { name: 'Sort 0 to 9', value: 'ASC', img: 'a-z' },
    { name: 'Sort 9 to 0', value: 'DESC', img: 'z-a' }
  ];
  paymentMethods = [
    { label: 'Cash', value: 'cash' },
    { label: 'Credit/Debit', value: 'credit/Debit' },
    { label: 'Zelle', value: 'zelle' },
    { label: 'Check', value: 'check' },
    { label: 'Bank Transfer', value: 'BANK_TRANSFER' },
    { label: 'Other', value: 'other' }
  ];
  contacts = [
    { label: 'Phone', value: 'Phone Call' },
    { label: 'SMS', value: 'Text Message' },
    { label: 'Email', value: 'Email' },
    { label: 'In-Person', value: 'In Person Contact' }
  ];
  selectedContact = '';
  assignedToEmployeeList: any = [];
  assignedToEmployeeMentionList: any = [];
  employeeList: any = [];
  selectedCollectionPresentative: any;
  headerNote = 'Collection Note';
  followUpNote: any;
  selectedSortOption = '';
  selectedField = '';
  allClientList: any = [];
  clientList: any[] = [];
  selectedClient;
  productList = [];
  isPlatformAdmin = false;
  isClientRole = false;
  isDisplayCompany = false;
  disableFields = false;
  reminderModal = false;
  selectedInvoice: any;
  selectedEmail: any = [];
  selectedPhone: any = [];
  reminderSelected;
  disableButton = false;
  outStandingBalance = false;
  isOpenCollectionNote = false;
  deleteFollowUpId: any;
  charactersNumber = 0;
  mentionConfig: any = {};
  source: any;
  @ViewChild('searchKeyInput') searchKeyInput: ElementRef;
  timespent = new Date();
  downloadOptions = [
    { name: 'Export PDF', value: 'PDF' },
    { name: 'Export XLS', value: 'EXCEL' }
  ];
  selectedDownload = '';
  invoiceTypes = [
    { label: 'Billing', value: 'Billing' },
    { label: 'Dispatch', value: 'Dispatch' },
    { label: 'Advertisement', value: 'Advertisement' },
    { label: 'Dispatching', value: 'Dispatching' },
    { label: 'OperrTel', value: 'OperrTel' },
  ];
  invoiceType: any;

  isCompanyAdminRole = false;

  showPendingTransactionConfirmDialog = false
  pendingTransactionConfirm;
  showPendingTransactionConfirmQuestionDialog = false;
  pendingInvoiceConfirmSelected;
  selectedProject: number;
  txnType: any;

  visibleImage;
  previewImage;
  subscriptions: Subscription = new Subscription();
  selectedInvoiceNumers;

  customizeColumns = [
    { label: 'Invoice Type', value: 'invoiceType' },
    { label: 'Total Amount', value: 'totalAmount' },
    { label: 'Email Reminder', value: 'Email Reminder' },
    { label: 'Alerts', value: 'reminderCount' }
  ];
  dataCol = {
    invoiceType: true,
    totalAmount: true,
    emailReminder: true,
    reminderCount: true
  };
  customCol: any = {};
  customColumnName: any = ['invoiceType', 'totalAmount', 'Email Reminder', 'reminderCount'];

  assigneeSelected;
  assigneeRoleEmploSelected;
  assigneeList = [];
  customToolbarOptions: any[] = [
    ['bold', 'italic', 'underline'],
    ['link']
  ];
  collectionTypeOptions = [
    { label: 'Follow-up', value: 'Follow-up' },
    { label: 'Payment Refusal', value: 'Payment Refusal' },
    { label: 'Delay Payment Requested', value: 'Delay Payment Requested' }
  ];
  collectionTypeSelected;
  followUpInvoiceDueDate;
  followUpCount;
  disabledExtendDueDate;

  clientIds: any = [];
  isOverTime: boolean;
  isOwnerNote: boolean;

  showPendingTransactionRejectConfirmDialog = false;
  isSaveCollectionNote: boolean = false;
  progressDownloadAll: any = 0;
  estDownloadAll: any = 0;
  durDownloadAllText: any = '';
  exporting: boolean = false;
  exportSubscribe: Subscription;
  downloadALlInterval: any;
  durDownloadAll: number = 0;

  xOptions: any = [
    {
      value: '1',
      label: 'Invoice Date'
    },
    {
      value: '2',
      label: 'By Week'
    },
    {
      value: '3',
      label: 'By Month'
    },
    {
      value: '4',
      label: 'By Quarter'
    },
    {
      value: '5',
      label: 'By Year'
    }
  ];

  yOptions: any = [
    {
      value: '1',
      label: 'Amount'
    },
    {
      value: '2',
      label: 'Total'
    },
    {
      value: '3',
      label: 'Open Balance'
    }
  ];

  timeOptions: any = [];
  tableView: boolean = true;
  xViewSelected: any = '1';
  yViewSelected: any = '1';
  timeOptionSelected: any = '1';
  chartData: any;
  chartOptions: any;
  paymentInvoiceBarChart: any;
  paymentInvoiceLineChart: any;
  defaultMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  defaultQuarter = ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4'];
  chartDataTmp: any = [];
  allChartData: any;
  yOptionsLabel: any;
  xChartData: any;
  yChartData: any;
  xDataSelected: any;
  chartType: any = '1';
  loadingDataChart: boolean = false;
  isLoading = false;

  transferFeeCashOut = 0;
  transferFeeFixedAmount = 0;
  lateFee = 0;
  invoices = [];
  invoiceOptions = [];
  invoicePayPOS: any = {};
  invoicePayPOSDialog = false;
  showDialogInfoFee = false;

  cardReaderPaymentDialog = false;
  timerPaymentStatus;
  cardPaymentStatus;
  posFailedMessage;
  terminalId;
  testMode;
  testCardNumber;
  showTransactionDetails: boolean = false;
  showViewFileDialog: boolean = false;
  showHistoryUploadDialog: boolean = false;
  transactionLoading: boolean = false;
  showConfirmationPopup: boolean = false;
  transactions: any = [];
  totalTransactions = 0;
  adminList: any = [];
  confirmationId: any;
  confirmationType: any;
  selectedTransactionDetail: any;
  historySelectedField: any;
  fileUrl: any;
  historyFor: any;
  fieldsName: any;
  historySize: any;
  historyPage: any;
  historyFromDate: any;
  historyToDate: any;
  invoiceIdTmp: any;
  dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  actionHistoryFile = 'Updated';
  attachment = 'fa-solid fa-paperclip text-muted';
  attachment1 = 'fa-solid fa-paperclip text-success';
  transactionCols = [
    { field: 'date', label: 'date', sortOptions: '', sort: 'number' },
    { field: 'transactionTypeName', label: 'Method', sortOptions: '', sort: 'text', width: '13%' },
    { field: 'cashIn', label: 'In', sortOptions: '', sort: 'number', width: '12%' },
    { field: 'netCashInOut', label: 'Net', sortOptions: '', sort: 'number', width: '15%' },
    { field: 'createdByUsr', label: 'Creator', sortOptions: '', sort: 'text', width: '12%' },
    { field: 'nameClient', label: 'Client', sortOptions: '', sort: 'text', width: '12%' },
    { field: 'document', label: 'Upload' },
    { field: 'verification1st', label: 'Verify 1', sortOptions: '', sort: 'text', width: '12%' },
    { field: 'verification2st', label: 'Verify 2', sortOptions: '', sort: 'text', width: '12%' }
  ];
  noFileOptions = [
    { label: 'Upload', value: 'Upload' },
    { label: 'History', value: 'History' }
  ];
  hasFileOptions = [
    { label: 'View', value: 'View' },
    { label: 'Change', value: 'Change' },
    { label: 'Remove', value: 'Remove' },
    { label: 'History', value: 'History' }
  ];
  displayColumns: any[] = [
    { field: 'updatedDate', label: 'Updated', sortable: false },
    { field: 'userName', label: 'User', sortable: false },
    { field: 'oldValue', label: 'Before', sortable: false },
    { field: 'newValue', label: 'After', sortable: false },
    { field: 'action', label: 'Action', sortable: false }
  ];

  allInvoicesDropdownData: any = []

  constructor(
    private phonePackageService: PhonePackageService,
    private contentService: PaymentInvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dropDownsService: DropDownsService,
    private browserNotificationService: BrowserNotificationService,
    private messageService: MessageService,
    private clientService: ClientService,
    private datePipe: DatePipe,
    private operatorService: OperatorService,
    private followUpBillingInvoiceService: FollowUpBillingInvoiceService,
    private productService: ProductService,
    private contactService: ContactService,
    private monitoringDetailsService: MonitoringDetailsService,
    private paymentNotificationService: PaymentNotificationService,
    private projectService: ProjectService,
    private transactionTypeService: TransactionTypeService,
    private transactionService: TransactionService,
    private firebaseNotificationService: FirebaseNotificationService,
    private notificationService: NotificationService,
    private quoteService: QuoteService,
    private companyService: CompanyService,
    private departmentService: DepartmentService,
    private employeeDepartmentService: EmployeeDepartmentService,
    private employeeService: EmployeeService,
    private translatePipe: TranslatePipe,
    private utilsService: UtilsService,
    private generalInvoiceService: GeneralInvoiceService,
    private punchBillingService: PunchBillingService,
    private payrollSettingService: PayrollSettingService,
    private externalPaymentService: ExternalPaymentService,
    private paymentTransactionService: PaymentTransactionService,
    private transactionDetailsHistoryService: TransactionDetailsHistoryService,
    private documentsService: DocumentsService,
    private transactionDetailsService: TransactionDetailsService,
    private companyAdminService: CompanyAdminService,
  ) {
    this.user = this.authService.getUserInfo();
    this.subscriptions.add(this.route.queryParams.subscribe(params => {
      const outStandingBalance = params['outStandingBalance'];
      if (outStandingBalance) {
        this.outStandingBalance = outStandingBalance;
      }
      const status = params['status'];
      if (status) {
        this.selectedStatus = this.statusTypes.filter(s => s.value === status).map(s => s.value);
      }
      const invoices: string = params['invoices'];
      if (invoices && invoices !== '') {
        this.selectedInvoiceNumers = invoices.split(',');
      }
    }));
  }

  ngOnInit() {
    this.isClientRole = this.authService.isClientRole();
    this.isCompanyAdminRole = this.authService.isCompanyAdminRole();
    this.loggedInRole = this.authService.getRoleLevel();
    this.selectedCompany = this.loggedInRole === RoleLevel.ROLE_ADMIN ? null : this.authService.getCurrentCompanyId();
    if (this.loggedInRole === RoleLevel.ROLE_CLIENT) { this.disableFields = true; }
    this.rowsPerPageOptions = [5, 10, 20];
    this.rowsPerPageItems = [];
    // this.getAssigneeList();
    this.getAssigneeList2();
    this.loadEmployeeDropDown();
    this.rowsPerPageOptions.forEach(rowPerPage => {
      this.rowsPerPageItems.push({ label: String(rowPerPage), value: rowPerPage });
    });
    this.items = [
      { label: 'View', icon: 'fa-solid fa-magnifying-glass text-primary', command: (event) => this.viewContent(this.selectedContent) },
      { label: 'Delete', icon: 'fa-solid fa-xmark text-danger', command: (event) => this.deleteContent(this.selectedContent) }
    ];
    const operrDispatchInvoiceNumber = localStorage.getItem('_invoiceNumber');
    if (operrDispatchInvoiceNumber && operrDispatchInvoiceNumber !== 'undefined') {
      this.invoiceNumber = operrDispatchInvoiceNumber;
      this.paymentInvoiceData.invoiceNumber = operrDispatchInvoiceNumber;
    }
    this.contentList = [];
    this.userPermission = this.authService.adminRole();
    this.searchText = '';
    this.loadAllCompanyList();
    this.loadAllClient();
    this.timeOptions = this.getDefaultYears();
    this.loadChartData();
    this.enabledGenerate = false;
    this.disableAgency = true;
    this.disableCompany = true;

    if (this.loggedInRole === RoleLevel.ROLE_COMPANY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_COMPANY_ADMIN || this.loggedInRole === RoleLevel.ROLE_EMPLOYEE) {
      this.disableCompany = true;
      this.disableAgency = false;
    }

    if (this.loggedInRole === RoleLevel.ROLE_AGENCY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_AGENCY_ADMIN) {
      this.disableAgency = true;
      this.disableCompany = true;
    }
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.isPlatformAdmin) {
      this.cols = [
        { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text', show: true },
        { field: 'invoiceType', label: 'Type', sortOptions: '', sort: 'text', show: true },
        { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number', show: true },
        { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text', isDisable: this.authService.isClientRole(), show: true },
        { field: 'dueDate', label: 'Due', sortOptions: '', sort: 'text', show: true },
        { field: 'companyName', label: 'companyLabel', sortOptions: '', sort: 'text', isDisable: this.authService.isClientRole(), show: true },
        { field: 'totalAmount', label: 'Amount', sortOptions: '', sort: 'number', show: true },
        { field: 'fee', label: 'Fee', sortOptions: '', sort: 'number', show: true },
        { field: 'paidFee', label: 'Paid', sortOptions: '', sort: 'number', show: true },
        { field: 'dueIn', label: 'Due In', show: true },
        { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number', show: true },
        { field: 'quickPay', label: 'Quick Pay', show: true },
        { field: 'paymentMethod', label: 'Method', sortOptions: '', sort: 'text', show: true },
        { field: 'Email Reminder', label: 'Reminder', sortOptions: '', show: true },
        { field: 'reminderCount', label: 'Sent', sortOptions: '', sort: 'number', show: true }
      ];
      this.isDisplayCompany = true;
    }

    if (this.authService.isClientRole()) {
      this.cols = [
        { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text', show: true },
        { field: 'invoiceType', label: 'Type', sortOptions: '', sort: 'text', show: true },
        { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number', show: true },
        { field: 'dueDate', label: 'Due', sortOptions: '', sort: 'text', show: true },
        { field: 'totalAmount', label: 'Amount', sortOptions: '', sort: 'number', show: true },
        { field: 'fee', label: 'Fee', sortOptions: '', sort: 'number', show: true },
        { field: 'paidFee', label: 'Paid', sortOptions: '', sort: 'number', show: true },
        { field: 'dueIn', label: 'Due In', show: true },
        { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number', show: true },
        { field: 'quickPay', label: 'Quick Pay', show: true },
        { field: 'paymentMethod', label: 'Method', sortOptions: '', sort: 'text', show: true },
        // { field: 'Email Reminder', label: 'Email Reminder', sortOptions: '' },
        { field: 'reminderCount', label: 'Sent', sortOptions: '', sort: 'number', show: true }
      ];
    }
    if (this.loggedInRole === RoleLevel.ROLE_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_ADMIN) {
      this.disableAgency = false;
      this.disableCompany = false;
    }
    this.detailEnable = false;
    // this.subscribeMessage();
    this.search();

    this.subscriptions.add(this.notificationService.invoiceListChange$.subscribe((message: any) => {
      this.selectedInvoiceNumers = null;
      this.selectedStatus = null;
      if (message) {
        if (message.type === 'STATUS') {
          this.selectedStatus = this.statusTypes.filter(s => s.value === message.status).map(s => s.value);
          this.search();
        } else if (message.type === 'INVOICE_NUMBER') {
          this.selectedInvoiceNumers = message.invoices.split(',');
          this.search();
        }
      }
    }));

    let savedColumns = localStorage.getItem('_invoiceCustomizeColumn');
    if (savedColumns) {
      this.customColumnName = JSON.parse(savedColumns);
      this.onSelectColumn();
    }

    if (this.authService.isEmployeeRole()) {
      this.collectionTypeOptions = [
        { label: 'Follow-up', value: 'Follow-up' },
        { label: 'Payment Refusal', value: 'Payment Refusal' }
      ];
    }

    const companyId: number = this.authService.getCurrentCompanyId();
    this.payrollSettingService.getPayrollSettings(companyId, { companyId: companyId }).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        if (res.data.transferFeeCashOut) {
          this.transferFeeCashOut = Number(res.data.transferFeeCashOut);
        }
        if (res.data.transferFeeFixedAmount) {
          this.transferFeeFixedAmount = Number(res.data.transferFeeFixedAmount);
        }
        if (res.data.lateFee) {
          this.lateFee = Number(res.data.lateFee);
        }
        this.testMode = res.data.testMode;
        this.terminalId = res.data.readerId;
        this.testCardNumber = res.data.testCardNumber;
      }
      this.searchInvoices();
    }, () => this.searchInvoices());
    this.loadAllAdminNameList();
  }

  ngAfterViewInit(): void {
    this.source = fromEvent(this.searchKeyInput.nativeElement, 'keyup');
    this.source.pipe(debounceTime(1200)).subscribe(c => {
      this.search();
    });
  }

  getAllAddedProductsByClientId(clientId: any) {
    const options: any = {
      page: 0, size: 99999, sort: 'id,desc',
    };
    options.clientId = this.selectedClient;
    options.crmClientIds = [this.selectedClient];
    this.productService.getAllProductsByClient(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.productList = resObj.data.content;
      }
    });
  }
  setEditor(event: any) {
    event.editor.root.focus();
    this.concernEditor = event.editor;
  }

  customerPopup(event: any) {
  }

  textChanged1(event) {
    this.msgs = [];
    if (this.concernEditor.getLength() < 25) {
      this.charactersLength = this.concernEditor.getLength() - 1;
      return;
    } else {
      this.charactersLength = this.concernEditor.getLength() - 1;
    }
    if (this.concernEditor.getLength() > this.MAX_LENGTH) {
      this.followUpNote.deleteText(this.MAX_LENGTH - 1, this.concernEditor.getLength());
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit') });
    }
    this.charactersLength = this.concernEditor.getLength() - 1;
  }
  loadAllClient() {
    const options: any = { size: 99999, page: 0, moduleName: 'billing' };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    }
    if (this.disableFields) {
      options.userId = this.authService.getCurrentLoggedInId();
      this.selectedClient = options.userId;
    }
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    // options.status = 1;

    if (this.assigneeSelected) {
      const assigneeTo = this.assigneeList.find(a => a.value === this.assigneeSelected);
      if (assigneeTo && assigneeTo.label) {
        options.assignedTo = assigneeTo.label;
      }
    }

    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      this.clientList = [];
      this.clientIds = [];
      if (resObj.status === 'SUCCESS') {
        let data = resObj.data.sort((a, b) => a.value.localeCompare(b.value));
        this.allClientList = data.map(c => ({ value: c.key, label: c.value, email: c.email }));
        this.clientList = data;
        console.log('getAllClientList: ', this.allClientList);
        // this.allClientList.forEach(item => {
        //   this.clientIds.push(item.value);
        // });

      }
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.subscriptions.unsubscribe();
  }

  loadPackageBasedOnCompanyId() {
    this.phonePackageService.getAllList({ companyId: this.selectedCompany }).subscribe((res) => {
      const resObj: any = res;
      console.log(resObj);
      if (resObj.status === 'SUCCESS') {
        this.phonePackages = resObj.data;
      }
    });
    this.detailEnable = false;
  }

  viewContent(c: PaymentInvoice) {
    this.router.navigate(['app/phonesystem', 'view', c.id]);
  }

  deleteContent(c: PaymentInvoice) {
    this.contentService.delete(c.id).subscribe((_response) => {
      this.search();
    }, (error) => {
      console.log(error);
    });
  }

  public openPaynow(rowData: any) {
    const queryParams: NavigationExtras = {
      queryParams: { 'invoiceNumber': rowData.invoiceNumber }
    };
    this.router.navigate(['app/payments/detail'], queryParams);
  }

  cancelDialog() {
    this.detailEnable = false;
  }

  resetFilters() {
    this.selectedInvoiceNumers = null;
    this.invoiceNumber = null;
    this.selectedStatus = null;
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.fromDate = null;
    this.toDate = null;
    this.paymentMethod = null;
    this.selectedPaymentMethods = [];
    this.invoiceType = null;
    this.sortField = null;
    this.sortOrder = null;
    this.page = 0;
    this.size = 10;
    this.searchText = null;
    if (!this.authService.isEmployeeRole()) {
      this.assigneeSelected = null;
      this.assigneeRoleEmploSelected = null;
    }
    if (!this.disableFields) {
      this.selectedClient = null;
    }
    this.search();
  }

  search(event?: any) {
    const options = this.buildOptions(event);
    this.loadPage(options);
  }

  buildOptions(event?: any, page?, size?) {
    this.size = event ? event.rows : this.size ? this.size : 10;
    this.page = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;
    const options = <any>{
      page: page ? page : this.page,
      size: size ? size : this.size
    };
    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'id';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;
    if (this.sortField === 'id') {
      this.sortOrder = 0;
    }

    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';

    // if (this.paymentMethod) {
    //   options.paymentMethod = this.paymentMethod;
    // }
    // if (this.selectedPaymentMethods) {
    //   options.paymentMethods = this.selectedPaymentMethods;
    // }

    if (this.invoiceNumber) {
      options.invoiceNumberTxt = this.invoiceNumber;
    }
    if (this.selectedInvoiceNumers) {
      options.invoiceNumbers = this.selectedInvoiceNumers;
    }
    if (this.selectedStatus && this.selectedStatus.length > 0 && this.selectedStatus.length !== this.statusTypes.length) {
      options.listStatus = this.selectedStatus;
    } else {
      options.listStatus = null;
    }

    if (this.user.companyId && !this.isPlatformAdmin) {
      options.companyId = this.user.companyId;
    }
    if (this.selectedCompany != null) {
      options.companyId = this.selectedCompany;
    }

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient;
    }

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = options.fromDate;
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = options.toDate;
    }

    this.detailEnable = false;
    if (this.invoiceType) {
      options.invoiceType = this.invoiceType;
    }
    if (this.searchText && this.searchText !== '') {
      const clientIds = this.allClientList.filter(c => c.label && c.label.toLowerCase().includes(this.searchText.toLowerCase())).map(c => c.value);
      options.includedClientIds = clientIds;
      options.searchText = this.searchText;
    }

    options.oldInvoiceOnly = false;
    if (this.paymentMethod) {
      options.paymentMethod = this.paymentMethod;
    }

    if (this.selectedPaymentMethods) {
      options.paymentMethods = this.selectedPaymentMethods;
    }

    const invoiceTopBarInfoString = localStorage.getItem('top-bar-invoice-number');
    if (invoiceTopBarInfoString) {
      const invoiceTopBarInfo = JSON.parse(invoiceTopBarInfoString);
      options.clientId = invoiceTopBarInfo.clientId;
      this.searchText = invoiceTopBarInfo.invoiceNumber;
      this.selectedClient = invoiceTopBarInfo.clientId;
      localStorage.removeItem('top-bar-invoice-number');
    }

    if (this.assigneeSelected) {
      const isEmployee = this.authService.isEmployeeRole();
      if (isEmployee) {
        options.biller = this.assigneeRoleEmploSelected;
      } else {
        const assigneeTo = this.assigneeList.find(a => a.value === this.assigneeSelected);
        if (assigneeTo && assigneeTo.label) {
          options.biller = assigneeTo.label;
        }
      }
    }
    options.ignoreInvoiceDetails = true;
    return options;
  }

  generateInvoice() {
    this.loadingSpinner = true;
    const startDate = new Date(this.fromDate);
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date(this.toDate);
    endDate.setDate(endDate.getDate() - 30);
    this.contentService.generateGenerate({ fromDate: startDate, toDate: endDate }).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        setTimeout(() => {
          this.loadingSpinner = false;
        }, 1000);
        this.contentService.notifyMessage(
          { severity: 'info', summary: this.translatePipe.transform('Generate'), detail: this.translatePipe.transform('Generate Invoice successfully') });
        this.search();
      }
    });
  }

  redirectToFollowUptUrl(rowData: any) {
    if (!this.isEnableFollowUps()) {
      return;
    }
    localStorage.removeItem('follow-up-note-number');
    localStorage.setItem('follow-up-note-number', rowData.invoiceNumber);
    this.router.navigate(['/app/payments/follow-up-note']);
  }

  searchBySelectedDate(date) {
    if (date.startDate) {
      this.fromDate = date.startDate;
    }
    if (date.endDate) {
      this.toDate = date.endDate;
    }
  }

  loadPage(options) {
    this.loading = true;
    this.loadStatistics(options);

    this.contentService.filter(options).subscribe(res => {
      this.loading = false;
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.contentList = resObj.data.content;
        this.totalRecords = resObj.data.totalElements;
        for (const invoice of this.contentList) {
          const company = this.allCompanyList.find(com => com.value === invoice.companyId);
          const client = this.allClientList.find(com => com.value === invoice.clientId);
          if (company) {
            invoice.companyName = company.label;
          }
          if (client) {
            invoice.clientName = client.label;
          }
          const invoiceDate = new Date(invoice.invoiceDate);
          invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());
          invoice.invoiceDate = invoiceDate;
          if (invoice.paymentMethod === 'cash') {
            invoice.paymentMethod = 'Cash';
          }

          if (invoice.paymentMethod === 'credit/Debit') {
            invoice.paymentMethod = 'Credit/Debit';
          }

          if (invoice.paymentMethod === 'zelle') {
            invoice.paymentMethod = 'Zelle';
          }

          if (invoice.paymentMethod === 'check') {
            invoice.paymentMethod = 'Check';
          }

          if (invoice.paymentMethod === 'BANK_TRANSFER') {
            invoice.paymentMethod = 'Bank Transfer';
          }

          if (invoice.paymentMethod === 'other') {
            invoice.paymentMethod = 'Other';
          }
          if (invoice.totalAmount) {
            invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
          } else {
            invoice.totalAmountTxt = '0.00';
          }

          if (invoice.fee) {
            invoice.feeTxt = invoice.fee.toFixed(2);
          } else {
            invoice.feeTxt = '0.00';
          }

          if (invoice.paidFee) {
            invoice.paidFeeTxt = invoice.paidFee.toFixed(2);
          } else {
            invoice.paidFeeTxt = '0.00';
          }

          if (invoice.balance) {
            invoice.balanceTxt = invoice.balance.toFixed(2);
          } else {
            invoice.balanceTxt = '0.00';
          }

          if (invoice.dueDate) {
            invoice.dueIn = Math.floor((new Date(invoice.dueDate).getTime() - new Date().getTime()) / 86400000);//one day = 86400000
          } else {
            invoice.dueIn = 0
          }

          const length = invoice.followUpBillingInvoices ? invoice.followUpBillingInvoices.length : 0;
          if (invoice.followUpBillingInvoices && length > 0) {
            invoice.followUpNoteDate = invoice.followUpBillingInvoices[length - 1].createdAt;
            invoice.numOfFollowUpNote = length;
          }

          if (invoice.invoiceType === 'Punch') {
            invoice.invoiceType = 'Operrwork SaaS';
          }
        }
      } else {
        this.contentList = [];
        this.totalRecords = 0;
        this.resetStatistics();
      }
    }, () => {
      this.loading = false;
      this.contentList = [];
      this.totalRecords = 0;
      this.resetStatistics();
    });
  }

  loadStatistics(options) {
    this.contentService.getTotalPayment(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.sumFee = res.data.serviceFee;
        if (this.sumFee) {
          this.sumFeeTxt = this.sumFee.toFixed(2);
        } else {
          this.sumFeeTxt = '00.00';
        }

        this.sumTotalAmount = res.data.totalAmount;
        if (this.sumTotalAmount) {
          this.sumTotalAmountTxt = this.sumTotalAmount.toFixed(2);
        } else {
          this.sumTotalAmountTxt = '00.00';
        }

        this.sumOutStandingBalance = res.data.outStandingBalance;
        if (this.sumOutStandingBalance) {
          this.sumOutStandingBalanceTxt = this.sumOutStandingBalance.toFixed(2);
        } else {
          this.sumOutStandingBalanceTxt = '00.00';
        }

        this.sumPaidFee = res.data.serviceFeePaid;
        if (this.sumPaidFee) {
          this.sumPaidFeeTxt = this.sumPaidFee.toFixed(2);
        } else {
          this.sumPaidFeeTxt = '00.00';
        }

        this.totalServiceFee = this.sumFee ? this.sumFee : 0;
        this.totalOutstandingBalance = this.sumOutStandingBalance ? this.sumOutStandingBalance : 0;
        this.totalServiceFeePaid = this.sumPaidFee ? this.sumPaidFee : 0;
      } else {
        this.resetStatistics();
      }
    }, () => this.resetStatistics());
  }

  exportReportPdf() {
    const data: any = {
      productPrices: this.productList,
      clientId: this.selectedClient
    };
    if (this.selectedCompany != null) {
      data.companyId = this.selectedCompany;
    }
    if (!(this.authService.isSuper() || this.authService.isSubSuper())) {
      data.companyId = this.authService.getCurrentCompanyId();
    }
    this.contentService.exportReportPdf(data).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    });

  }

  exportPdf(invoice) {
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else if (invoice?.lastModifiedBy && invoice.lastModifiedBy.startsWith('GENERAL-INVOICE')) {
      this.viewGeneralInvoiceRefPdf(invoice.lastModifiedBy.replace('GENERAL-INVOICE-', ''));
    } else if (invoice?.invoiceType === 'Punch') {
      this.viewPunchInvoiceRefPdf(invoice.id);
    } else {
      if (invoice.invoiceType === 'Survey Land') {
        this.quoteService.findByInvoiceNumber(invoice.invoiceNumber).subscribe(res => {
          const resObj: any = res;
          forkJoin([
            this.clientService.getClientById(resObj.data.clientId),
            this.companyService.getCompanyByCompanyId(resObj.data.companyId)
          ]).subscribe((response: any) => {
            const doc = buildSurveyLandPdfFile(resObj.data, response[1].data.name, response[0].data.email, response[0].data.phone, true);
            const blobUrl = URL.createObjectURL(doc.output("blob"));
            window.open(blobUrl, '_blank');
          });
        });
      } else {
        this.contentService.exportPdf(invoice.id).subscribe(res => {
          if (res.data) {
            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          }
        });
      }
    }
  }

  loadAllCompanyList() {
    const pafc = this;
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        // this.allCompanyList = resObj.data;
        resObj.data.forEach(rs => {
          this.allCompanyList.push({ value: rs.key, label: rs.value });
        });
        if (this.user.companyId && this.loggedInRole !== RoleLevel.ROLE_ADMIN) {
          const company = this.allCompanyList.find(c => c.value === this.user.companyId);
          this.selectedCompany = company.value;
        }

        this.getAllInvoices()
      }
    });
  }

  sortDataSelected(selectedSortOption) {
    this.sortField = this.selectedField;
    this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
    this.search();
  }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
            result = -1;
          else if (value1 != null && value2 == null)
            result = 1;
          else if (value1 == null && value2 == null)
            result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
          else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          return (event.order * result);
        });
      }
      this.sortField = ""
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  async exportTablePdf() {
    this.estimateExportTime();
    const options = this.buildOptions(null, 0, 99999);
    // const res: any = await this.contentService.filter(options).toPromise();
    const res: any = await this.contentService.exportInvoices(options).toPromise();
    if (res.status === 'SUCCESS') {
      const contentList = res.data.content;
      const data: any = {
        contentList: contentList.map(value => {

          const company = this.allCompanyList.find(com => com.value === value.companyId);
          const client = this.allClientList.find(com => com.value === value.clientId);
          if (company) {
            value.companyName = company.label;
          }
          if (client) {
            value.clientName = client.label;
          }
          if (value.totalAmount) {
            value.totalAmountTxt = value.totalAmount.toFixed(2);
          } else {
            value.totalAmountTxt = '0.00';
          }
          if (value.fee) {
            value.feeTxt = value.fee.toFixed(2);
          } else {
            value.feeTxt = '0.00';
          }
          if (value.paidFee) {
            value.paidFeeTxt = value.paidFee.toFixed(2);
          } else {
            value.paidFeeTxt = '0.00';
          }
          if (value.paymentMethod === 'cash') {
            value.paymentMethod = 'Cash';
          } else if (value.paymentMethod === 'credit/Debit') {
            value.paymentMethod = 'Credit/Debit';
          } else if (value.paymentMethod === 'zelle') {
            value.paymentMethod = 'Zelle';
          } else if (value.paymentMethod === 'check') {
            value.paymentMethod = 'Check';
          } else if (value.paymentMethod === 'other') {
            value.paymentMethod = 'Other';
          }
          if (value.balance) {
            value.balanceTxt = '$' + value.balance.toFixed(2);
          } else {
            value.balanceTxt = 'Paid';
          }
          if (value.dueDate && value.status !== 'Paid') {
            value.dueIn = Math.floor((new Date(value.dueDate).getTime() - new Date().getTime()) / 86400000);//one day = 86400000
          } else {
            value.dueIn = 0
          }

          return {
            invoiceNumber: value.invoiceNumber,
            invoiceDate: this.datePipe.transform(value.invoiceDate, 'MM/dd/yyyy'),
            dueDate: this.datePipe.transform(value.dueDate, 'MM/dd/yyyy'),
            clientName: value.clientName,
            companyName: value.companyName,
            totalAmount: value.totalAmountTxt,
            feeTxt: value.feeTxt,
            paidFeeTxt: value.paidFeeTxt,
            balance: value.balanceTxt,
            reminderCount: value.reminderCount,
            followUpBillingInvoices: value.followUpBillingInvoices,
            paymentMethod: value.paymentMethod,
            dueIn: value.dueIn,
            status: value.status
          };
        })
      };
      if (!this.isPlatformAdmin) {
        data.companyId = this.authService.getCurrentCompanyId();
        data.includeCompany = false;
      } else {
        data.companyId = this.selectedCompany;
        data.includeCompany = true;
      }
      data.username = this.authService.getCurrentUsername();
      data.totalServiceFee = this.totalServiceFee.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      data.totalServiceFeePaid = this.totalServiceFeePaid.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      data.totalOutstandingBalance = this.totalOutstandingBalance.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
      if (this.authService.isClientRole()) {
        data.userType = 'CLIENT';
        data.fullName = this.authService.getUserInfo().name;
      }
      this.exportSubscribe = this.contentService.exportTablePdf(data).subscribe(res => {
        if (res.data) {
          this.progressDownloadAll = 100;
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Problem exporting'), detail: this.translatePipe.transform('Problem exporting client pdf list') });
        }
        this.cancelExporting();
      }, () => {
        this.cancelExporting();
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Problem exporting'), detail: this.translatePipe.transform('Problem exporting client pdf list') });
      });
    }
  }

  async openReminderPopup(data) {
    this.selectedInvoice = data;
    const email = new Set<string>();
    const phones = new Set<string>();
    const client: any = await this.clientService.getClientById(this.selectedInvoice.clientId).toPromise();
    if (client && client.status === 'SUCCESS') {
      if (client.data.email) {
        email.add(client.data.email);
      }
      if (client.data.phone) {
        phones.add(client.data.phone);
      }
    }

    const contactInfo: any = await this.contactService.getPage({ clientId: client.data.id }).toPromise();
    if (contactInfo && contactInfo.data.content.length) {
      contactInfo.data.content.forEach(ele => {
        if (ele.defaultEmail1) {
          email.add(ele.email);
        }
        if (ele.defaultEmail2) {
          email.add(ele.email2);
        }
      });
    }

    this.selectedEmail = [...email];
    this.selectedPhone = [...phones];
    if (!this.selectedEmail.length && !this.selectedPhone.length) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Did not find any email and phone from this client') });
      return;
    }
    this.reminderSelected = 'email';
    this.reminderModal = true;
  }

  accept() {
    this.disableButton = true;
    const oneTimePaymentLink = environment.fe_url + '/one-time-payment/' + this.selectedInvoice.invoiceNumber;
    const data = {
      ...this.selectedInvoice,
      oneTimePaymentLink
    };
    this.contentService.sendEmailReminder(data, this.reminderSelected).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({
          severity: 'info', summary: this.translatePipe.transform('Email Reminder'),
          detail: this.translatePipe.transform('Reminder is sent successfully')
        });
        // this.search();
        this.selectedInvoice.reminderCount = res.data.reminderCount
        this.reminderModal = false;
        setTimeout(() => {
          this.disableButton = false;
        }, 1000);
      }
    }, err => {
      this.reminderModal = false;
      this.messageService.add({
        severity: 'error', summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Email Reminder Failed')
      });
      this.disableButton = false;
    });
  }

  reject() {
    this.reminderModal = false;
    this.disableButton = false;
  }

  openDialog(data?: any, event?: any, isNew?: any) {
    if (!this.isEnableFollowUps()) {
      return;
    }
    this.isOverTime = false;
    this.isOwnerNote = true;
    this.now = new Date();
    this.paymentInvoiceData = data;
    this.isNew = isNew;
    this.followUpDate = this.now;
    this.headerNote = 'Collection Note';
    if (!isNew) {
      this.headerNote = 'Update Notes';
    }
    if (data.followUpBillingInvoices && data.followUpBillingInvoices.length > 0 && !isNew) {
      const length = data.followUpBillingInvoices.length;
      this.selectedCollectionPresentative = data.followUpBillingInvoices[length - 1].collectionRepresentative;
      this.selectedContact = data.followUpBillingInvoices[length - 1].methodContact;
      this.followUpNote = data.followUpBillingInvoices[length - 1].note;
      this.followUpDate = data.followUpBillingInvoices[length - 1].createdAt;
      this.collectionTypeSelected = data.followUpBillingInvoices[length - 1].collectionType;

      this.isOverTime = this.isOver24hrs(data.followUpBillingInvoices[length - 1].createdAt);
      this.isOwnerNote = this.authService.getCurrentLoggedInName() === data.followUpBillingInvoices[length - 1].createdByUsr;
    } else {
      if (this.authService.isEmployeeRole()) {
        this.selectedCollectionPresentative = this.authService.getCurrentUsername();
      } else {
        this.selectedCollectionPresentative = null;
      }
      this.selectedContact = '';
      this.followUpNote = '';
    }
    this.followUpCount = data.extendDueDateCount ? data.extendDueDateCount : 0;
    this.followUpInvoiceDueDate = new Date(data.dueDate);
    this.disabledExtendDueDate = data.disabledExtendDueDate;

    this.charactersLength = this.followUpNote ? this.followUpNote.replace(/<\/?[^>]+(>|$)/g, '').length : 0;
    this.isOpenCollectionNote = true;
    this.timespent = new Date();

  }

  closeDialog() {
    this.isOpenCollectionNote = false;
    this.paymentInvoiceData = {};
    if (this.headerNote && this.headerNote === 'Update Notes') {
      this.monitoringDetailsService.monitorAction(
        'Follow Up Note Viewed',
        this.timespent,
        {
          follow_up_note_viewed_by: this.authService.getCurrentLoggedInName()
        },
        'complete',
        'Follow Up Note Viewed',
        0
      );
    }
  }

  saveCollectionNote(data?: any, event?: any) {
    this.isSaveCollectionNote = true;
    const followUpBilling = new FollowUpBillingInvoice();
    followUpBilling.collectionRepresentative = this.selectedCollectionPresentative;
    followUpBilling.methodContact = this.selectedContact;
    followUpBilling.note = this.followUpNote;
    followUpBilling.invoiceId = this.paymentInvoiceData.id;
    followUpBilling.invoiceNumber = this.paymentInvoiceData.invoiceNumber;
    followUpBilling.clientName = this.paymentInvoiceData.clientName;
    followUpBilling.companyId = this.paymentInvoiceData.companyId;
    followUpBilling.collectionType = this.collectionTypeSelected;
    followUpBilling.invoiceDueDate = this.followUpInvoiceDueDate;
    this.msgs = [];
    if (this.concernEditor.getLength() < 25) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Please add at least 25 characters') });
      this.isSaveCollectionNote = false;
      return;
    }
    if (this.concernEditor.getLength() > this.MAX_LENGTH) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit') });
      return;
    }
    if (this.paymentInvoiceData.followUpBillingInvoices && this.paymentInvoiceData.followUpBillingInvoices.length > 0 && !this.isNew) {
      const length = this.paymentInvoiceData.followUpBillingInvoices.length;
      followUpBilling.id = this.paymentInvoiceData.followUpBillingInvoices[length - 1].id;
      followUpBilling.createdAt = this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdAt;
      followUpBilling.createdByUsr = this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdByUsr;
      followUpBilling.lastModifiedBy = this.authService.getCurrentLoggedInName();
      this.followUpBillingInvoiceService.update(followUpBilling, this.paymentInvoiceData.followUpBillingInvoices[length - 1].id).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        this.isSaveCollectionNote = false;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Update Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been updated successfully!')
          });

          this.contentList.forEach(element => {
            if (element.id === this.paymentInvoiceData.id) {
              element.followUpBillingInvoices[length - 1] = resObj.data;
              if (this.collectionTypeSelected === 'Delay Payment Requested' && moment(this.followUpInvoiceDueDate).diff(moment(element.dueDate), 'days') !== 0) {
                element.extendDueDateCount = this.followUpCount + 1;
                element.dueDate = moment(this.followUpInvoiceDueDate).toDate();
                if (element.extendDueDateCount > 2) {
                  element.disabledExtendDueDate = true;
                }
              }
            }
          });
          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: resObj.message });
        }
        this.monitoringDetailsService.monitorAction(
          'Follow Up Note Updated',
          this.timespent,
          {
            follow_up_note_updated_by: this.authService.getCurrentLoggedInName(),
            follow_up_note_id: resObj.data.id
          },
          'complete',
          'Follow Up Note Updated',
          0
        );

      }, (error) => {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: error.message });
        this.isSaveCollectionNote = false;
      });
    } else {
      followUpBilling.createdByUsr = this.authService.getCurrentLoggedInName();
      this.followUpBillingInvoiceService.create(followUpBilling).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        this.isSaveCollectionNote = false;
        if (resObj.status === 'SUCCESS') {
          this.contentList.forEach(element => {
            if (element.id === this.paymentInvoiceData.id) {
              element.followUpBillingInvoices.push(resObj.data);
              const length = element.followUpBillingInvoices ? element.followUpBillingInvoices.length : 0;
              element.followUpNoteDate = element.followUpBillingInvoices[length - 1].createdAt,
                // element.numOfFollowUpNote = '(' + length + ')';
                element.numOfFollowUpNote = length;
              if (this.collectionTypeSelected === 'Delay Payment Requested' && moment(this.followUpInvoiceDueDate).diff(moment(element.dueDate), 'days') !== 0) {
                element.extendDueDateCount = this.followUpCount + 1;
                element.dueDate = moment(this.followUpInvoiceDueDate).toDate();
                if (element.extendDueDateCount > 2) {
                  element.disabledExtendDueDate = true;
                }
              }


            }
          });

          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Add Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been created successfully!')
          });
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: resObj.message });
        }
        this.monitoringDetailsService.monitorAction(
          'Follow Up Note Added',
          this.timespent,
          {
            follow_up_note_added_by: this.authService.getCurrentLoggedInName(),
            follow_up_note_id: resObj.data.id
          },
          'complete',
          'Follow Up Note Added',
          0
        );
        // this.search();
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: error.message });
        this.isSaveCollectionNote = false;
      });
    }
    // this.search();
  }

  onSelectDocument(event) {
    if (event.target.files[0].size > 1048576) {
      this.checkFileSize = true;
      event.target.value = '';
    } else {
      this.checkFileSize = false;
      this.selectedFiles = event.target.files[0];
    }
  }

  async loadEmployeeDropDown() {
    const options: any = {};
    options.companyId = this.authService.getCurrentCompanyId();
    options.status = 1;
    this.employeeList = [];
    const employee = await this.dropDownsService.getAllEmployeeList(options).toPromise();
    this.assignedToEmployeeList = [];
    this.assignedToEmployeeMentionList = [];
    if (employee.data.length > 0) {
      this.employeeList = employee.data;
      employee.data.forEach((item) => {
        const nm = item.value;
        this.assignedToEmployeeList.push({
          label: nm,
          value: nm
        });
      });

      this.assignedToEmployeeMentionList = Object.assign({}, this.assignedToEmployeeList);
    }
  }

  openConfirmDeleteDialog(rowData: any) {
    this.showConfirmDialog = true;
    this.deleteFollowUpId = rowData.followUpBillingInvoices.id;
    this.message = 'Are you sure you want to Delete this record?';
  }

  closeConfirmDeleteDialog() {
    this.showConfirmDialog = false;
  }

  deleteFollowUpNote() {
    this.showConfirmDialog = false;
    this.followUpBillingInvoiceService.delete(this.deleteFollowUpId).subscribe((data: any) => {
      const resObj = data;
      if (resObj.status === 'SUCCESS') {
        this.messageService.add({
          severity: 'info', summary: this.translatePipe.transform('Delete Follow Up Note'),
          detail: this.translatePipe.transform('Follow Up Note has been deleted successfully')
        });
        this.search();
      }
    });
  }

  async notifyMentionEmployee(details: string, followNoteId: number, followUpBilling: any) {
    const employeeList = details.match(/(@)([@.a-zA-Z0-9_-]*)/g);
    if (!employeeList || employeeList.length < 1) return
    for (const employee of employeeList) {
      const key = this.employeeList.find(e => '@' + e.value === employee)?.key;
      if (key != null) {
        const notification: BrowserNotification = new BrowserNotification();
        notification.entity = 'employee';
        const regex = new RegExp(employee + '(.{0,30})');
        notification.content = this.authService.getCurrentUsername() + ' mentioned you: ' + this.stripHtml(details).match(regex)[0] + '...';
        notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
        notification.url = document.location.origin + '/#/app/payments/follow-up-note/' + followNoteId;
        notification.status = 'New';
        notification.entityId = key;
        await this.browserNotificationService.save(notification).toPromise();
        // add the bell notification
        const bellNotification: any = {};
        bellNotification.sourceId = followNoteId;
        bellNotification.targetTableName = 'employee';
        bellNotification.targetRoleId = 7;
        bellNotification.targetId = key;
        bellNotification.message = details;
        bellNotification.type = 'follow_up_note';
        bellNotification.tableName = 'follow_up_billing';
        bellNotification.projectId = this.authService.getCurrentCompanyId();
        bellNotification.createdByUsr = this.authService.getCurrentLoggedInName();
        bellNotification.urlLink = '/app/payments/follow-up-note/' + followNoteId;
        await this.operatorService.addBellNotification(bellNotification).toPromise();
        // send email
        const emailData: any = {};
        emailData.employeeId = key;
        emailData.methodContact = followUpBilling.methodContact;
        emailData.collectionRepresentation = followUpBilling.collectionRepresentative;
        emailData.clientName = followUpBilling.clientName;
        emailData.invoiceNumber = followUpBilling.invoiceNumber;
        emailData.note = details.replace(/<[^>]*>/g, '');
        await this.followUpBillingInvoiceService.sendFollowUpTagEmail(emailData).toPromise();
      }
    }
  }

  stripHtml(html) {
    const tmp = document.createElement('tmp');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.followUpNote = this.followUpNote.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.followUpNote = this.followUpNote.replace(regexTag2, '<strong>$1$2</strong>$3');
      setTimeout(() => {
        this.followUpNoteEditor.getQuill().setSelection(this.followUpNoteEditor.value.length);
      }, 100);

    }, 500);
  }

  clickInvoiceRef(event: any, rowData) {
    this.op3.toggle(event);
    this.invoiceRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportPdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
    }
  }

  downloadInvoiceRefPdf() {
    this.op3.hide();
    if (this.invoiceRef.invoiceType === 'Survey Land') {
      this.quoteService.findByInvoiceNumber(this.invoiceRef.invoiceNumber).subscribe(res => {
        const resObj: any = res;
        forkJoin([
          this.clientService.getClientById(resObj.data.clientId),
          this.companyService.getCompanyByCompanyId(resObj.data.companyId)
        ]).subscribe((response: any) => {
          const doc = buildSurveyLandPdfFile(resObj.data, response[1].data.name, response[0].data.email, response[0].data.phone, true);
          const blobUrl = URL.createObjectURL(doc.output("blob"));
          const a = document.createElement('a');
          a.href = blobUrl;
          a.target = '_blank';
          let fileName = 'Dummy.pdf';
          if (res.data.fileName && res.data.fileName.length > 0) {
            fileName = res.data.fileName;
          }
          a.download = fileName;
          a.click();
        });
      });
    } else if (this.invoiceRef?.lastModifiedBy && this.invoiceRef.lastModifiedBy.startsWith('GENERAL-INVOICE')) {
      this.downloadGeneralInvoiceRefPdf(this.invoiceRef.lastModifiedBy.replace('GENERAL-INVOICE-', ''));
    } else if (this.invoiceRef?.invoiceType === 'Punch') {
      this.downloadPunchInvoiceRefPdf(this.invoiceRef.id);
    } else {
      this.contentService.exportPdfWithName(this.invoiceRef.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.target = '_blank';
          let fileName = 'Dummy.pdf';
          if (res.data.fileName && res.data.fileName.length > 0) {
            fileName = res.data.fileName;
          }
          a.download = fileName;
          a.click();
          const args = {
            ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
            Ticket_id: this.invoiceRef.invoiceId,
          };
        }
      });
    }
  }

  formatDate(date: any) {
    if (date) {
      return moment.utc(moment(date).format('MM/DD/YYYY hh:mm a')).local().format('MM/DD/YYYY hh:mm a');
    }
    return null;
  }

  async clickDownladFile() {
    if (this.selectedDownload === 'PDF') {
      await this.exportTablePdf();
    } else if (this.selectedDownload === 'EXCEL') {
      await this.converDataToExcelFormat();
    }

  }

  async converDataToExcelFormat() {
    this.estimateExportTime();
    const options = this.buildOptions(null, 0, 99999);

    this.exportSubscribe = this.contentService.exportExcel(options).subscribe(res => {
      if (res.status !== 'SUCCESS') {
        this.cancelExporting();
        return;
      }
      if (res.data) {
        this.progressDownloadAll = 100;
        this.cancelExporting();
        const blob = FileUtility.b64toBlob(res.data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        let fileName = 'Invoice_export_' + new Date().getTime() + '.xlsx'
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
      }
    }, (error) => {
      this.cancelExporting();
      return;
    });
    // const res: any = await this.contentService.exportExcel(options).toPromise();
    // if (res.status !== 'SUCCESS') {
    //   this.cancelExporting();
    //   return;
    // }
    // if (res.data) {
    //   this.progressDownloadAll = 100;
    //   this.cancelExporting();
    //   const blob = FileUtility.b64toBlob(res.data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8');
    //   const blobUrl = URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = blobUrl;
    //   a.target = '_blank';
    //   let fileName = 'Invoice_export_' + new Date().getTime() + '.xlsx'
    //   if (res.data.fileName && res.data.fileName.length > 0) {
    //     fileName = res.data.fileName;
    //   }
    //   a.download = fileName;
    //   a.click();
    // }
  }

  openConfirnPendingTransaction(invoice) {
    this.contentService.getPendingTransaction(invoice.pendingTransactionId).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.pendingTransactionConfirm = res.data;
        this.showPendingTransactionConfirmDialog = true;
      } else {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.message });
      }
    }, () => this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Could not found transaction') }));
  }

  openConfirmationQuestion(status: 'Approved' | 'Rejected') {
    this.pendingTransactionConfirm.status = status;
    this.pendingTransactionConfirm.reason = null;
    this.showPendingTransactionConfirmDialog = false;
    if (status === 'Approved') {
      this.showPendingTransactionConfirmQuestionDialog = true;
    } else {
      this.showPendingTransactionRejectConfirmDialog = true;
    }
    this.isLoading = false;

  }

  async acceptConfirmation() {
    this.isLoading = true;
    this.pendingTransactionConfirm.confirmedByAdminId = this.authService.getUserInfo().adminId;
    await this.confirmPendingTransaction();
    this.isLoading = false;
  }

  confirmPendingTransaction() {
    return new Promise((resolve, reject) => {
      this.contentService.confirmPendingTransaction(this.pendingTransactionConfirm).subscribe(async (res: any) => {
        if (res.status === 'SUCCESS') {
          $('#refresh_noti').trigger('click');
          await this.firebaseNotificationService.saveFirebaseNotification(`/invoice-payment/${this.authService.getCurrentCompanyId()}/${res.data.userId}`, {
            updateTime: new Date().getTime()
          }).subscribe();
          if (res.data.status === 'Approved') {
            await this.saveTransaction(res.data.invoiceId, res.data.userId, res.data.amount, 1, res.data.paymentMethod, res.data.note, res.data.documentUrl);
          } else {
            this.pendingTransactionConfirm = null;
            this.showPendingTransactionConfirmDialog = false;
            this.showPendingTransactionConfirmQuestionDialog = false;
            this.showPendingTransactionRejectConfirmDialog = false;
            await this.search();
          }
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Confirmation successfully') });
          await this.monitoringDetailsService.monitorAction(
            'Pending Transaction ' + res.data.status,
            new Date(),
            {
              invoice_number: res.data.invoiceNumber,
              amount: res.data.amount,
              created_by_usr: res.data.createdByUsr,
              confirmed_by_admin_id: this.authService.getUserInfo().adminId
            },
            'complete',
            'Pending Transaction',
            0
          );
          const client = this.allClientList.find(c => c.value === res.data.userId);
          const emailPayload = res.data;
          emailPayload.confirmedBy = this.authService.getUserInfo().firstName;
          emailPayload.paymentDate = moment(emailPayload.paymentDate).format('MM/DD/YYYY');
          emailPayload.clientName = client ? client.label : '';
          emailPayload.amount = '$' + emailPayload.amount.toFixed(2);
          await this.paymentNotificationService.sendPendingTransactionEmail(emailPayload, client ? client.email : null);
          await this.sendPendingTransactionNotification(res.data);
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.message });
        }

      }, () => {
        this.showPendingTransactionConfirmDialog = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Confirmation Failed') });
      });
    });
  }

  saveTransaction(invoiceId, clientId, amount, paymentStatus, paymentMethod, note, fileUrl) {
    const details: any = [];
    const payload: any = {
      companyId: this.authService.getCurrentCompanyId(),
      createdByUsr: this.authService.getCurrentUsername(),
      crmClientId: clientId,
      projectId: this.selectedProject,
      expenseCurrency: 'USD',
      invoiceId: invoiceId,
      status: paymentStatus,
      appliedTransaction: true,
      note: note
    };

    const transactionDetail: any = {};
    transactionDetail.invoiceId = invoiceId,
      transactionDetail.status = paymentStatus;
    transactionDetail.date = new Date();
    transactionDetail.cashIn = amount;
    transactionDetail.cashKept = 0;
    transactionDetail.cashOut = 0;
    transactionDetail.paymentMethod = paymentMethod;
    transactionDetail.transactionTypeId = this.txnType && this.txnType.length > 0 ? this.txnType[0].id : null;
    transactionDetail.createdByUsr = this.authService.getCurrentUsername(),
      // tslint:disable-next-line:max-line-length
      transactionDetail.netCashInOut = (parseFloat(transactionDetail.cashIn) + parseFloat(transactionDetail.cashKept)) - parseFloat(transactionDetail.cashOut);
    transactionDetail.netCashInOut = transactionDetail.netCashInOut.toFixed(2);
    transactionDetail.fileUrl = fileUrl;
    details.push(transactionDetail);
    payload.transactionDetails = details;
    this.transactionService.createTransaction(payload).subscribe(() => {
      this.pendingTransactionConfirm = null;
      this.showPendingTransactionConfirmDialog = false;
      this.showPendingTransactionConfirmQuestionDialog = false;
      this.showPendingTransactionRejectConfirmDialog = false;
      this.search();
      if (invoiceId) {
        this.contentService.updateApplyTransaction(invoiceId).toPromise();
      }
    }, () => this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Confirmation Failed') }));
  }

  loadProjectList() {
    const options: any = {
      status: 1
    };
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    this.projectService.getProjectDropdown(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach(ele => {
          if (ele.projectName === 'Operr Service Bureau' || ele.projectName === 'Operr Services Bureau' || ele.projectName === 'Income') {
            this.selectedProject = ele.id;
          }
        });
      }
    });
  }

  transactionType() {
    const options: any = {
      companyId: this.authService.getCurrentCompanyId(),
      status: 'Active'
    };
    this.transactionTypeService.findAllTransactionType(options).subscribe(res => {
      const resObj: any = res;
      this.txnType = resObj.data.content.filter(item => item.transactionType.toLowerCase() === 'payment');
    });
  }

  sendPendingTransactionNotification(transaction) {
    this.firebaseNotificationService.saveFirebaseNotification(`/latest-pending-transaction/${this.authService.getCurrentCompanyId()}`, {
      ...transaction,
      updateTime: new Date().getTime()
    }).subscribe();
  }

  resetStatistics() {
    this.totalServiceFee = 0;
    this.totalOutstandingBalance = 0;
    this.totalServiceFeePaid = 0;
    this.sumFee = 0;
    this.sumFeeTxt = '00.00';
    this.sumTotalAmount = 0;
    this.sumTotalAmountTxt = '00.00';
    this.sumOutStandingBalance = 0;
    this.sumOutStandingBalanceTxt = '00.00';
    this.sumPaidFee = 0;
    this.sumPaidFeeTxt = '00.00';
  }

  onSelectColumn(event?: any) {
    if (event) {
      this.checkColumns(event.value);
    } else {
      const column = this.customColumnName;
      if (column && column.length) { this.checkColumns(column); }
    }
    let totalWidth = 0;
    localStorage.setItem('_invoiceCustomizeColumn', JSON.stringify(this.customColumnName));
  }

  checkColumns(column) {
    column.forEach(ele => {
      this.cols.forEach(col => {
        if (ele === col.field) { col.show = true; }
      });
    });
    this.columnsToShow(column);
    this.dataCol.invoiceType = column.includes('invoiceType') ? true : false;
    this.dataCol.totalAmount = column.includes('totalAmount') ? true : false;
    this.dataCol.emailReminder = column.includes('Email Reminder') ? true : false;
    this.dataCol.reminderCount = column.includes('reminderCount') ? true : false;
  }

  columnsToShow(column) {
    this.cols.forEach(col => {
      if (!column.includes('invoiceType') && col.field === 'invoiceType') { col.show = false; }
      if (!column.includes('totalAmount') && col.field === 'totalAmount') { col.show = false; }
      if (!column.includes('Email Reminder') && col.field === 'Email Reminder') { col.show = false; }
      if (!column.includes('reminderCount') && col.field === 'reminderCount') { col.show = false; }
    });
  }
  getShowHeader() {
    return this.cols.filter(item => item.show);
  }

  openClientProfile(rowData) {
    if (rowData && rowData.clientId) {
      this.router.navigate(['/app/crm/client/edit/', rowData.clientId]);
    }
  }

  getAssigneeList() {
    if (this.authService.isCompanyAdminRole()) {
      const options: any = {
        name: 'Billing',
        companyId: this.authService.getCurrentCompanyId()
      };
      this.departmentService.getDepartmentsDropdown(options).subscribe(departmentsRes => {
        if (departmentsRes.status === RESPONSE_STATUS.SUCCESS) {
          if (departmentsRes.data) {
            const department = departmentsRes.data[0];
            if (department) {
              this.employeeDepartmentService.getActiveByDepartmentId(department.key).subscribe(employeeBillersRes => {
                if (employeeBillersRes.status === RESPONSE_STATUS.SUCCESS) {
                  this.assigneeList = employeeBillersRes.data.map(d => {
                    const fullName = `${d.firstName} ${d.lastName}`;
                    return { value: fullName, label: fullName };
                  });
                }
              }, error1 => console.log(error1));
            }
          }
        }
      });
    } else if (this.authService.isEmployeeRole()) {
      this.assigneeList = [];
      const id = this.authService.getUserInfo().adminId;
      const userName = this.authService.getUserInfo().username;
      this.assigneeList.push({ value: id, label: userName });
      this.assigneeSelected = id;
      this.assigneeRoleEmploSelected = userName;
    }
  }

  getAssigneeList2() {
    if (this.authService.isCompanyAdminRole()) {
      const options: any = {};
      if (this.selectedCompany) {
        options.companyId = this.selectedCompany;
      } else {
        options.companyId = this.authService.getCurrentCompanyId();
      }
      options.status = 1;
      options.departmentNameSearch = 'Marketing';
      this.employeeService.getAllsDropdown(options).subscribe(res => {
        const resObj: any = res;
        this.assigneeList = [];
        if (resObj && resObj.data && resObj.data.length > 0) {
          resObj.data.sort(this.utilsService.GetSortOrder('value'));
          resObj.data.forEach((item) => {
            this.assigneeList.push({
              label: item.value,
              value: item.key,
              fullName: item.fullName
            });
          });
          console.log('this.assigneeList: ', this.assigneeList);
          console.log('this.assigneeList resObj.data: ', resObj.data);
        }
      });
    } else if (this.authService.isEmployeeRole()) {
      this.assigneeList = [];
      const id = this.authService.getUserInfo().adminId;
      const userName = this.authService.getUserInfo().username;
      this.assigneeList.push({ value: id, label: userName });
      this.assigneeSelected = id;
      this.assigneeRoleEmploSelected = userName;
    }
  }

  changeAssignedTo(event) {
    this.loadAllClient();
  }

  isEnableFollowUps() {
    if (this.authService.isEmployeeRole()) {
      return this.authService.getLoggedInUserEditListRoleEmployee().includes('1014');
    } else {
      return this.authService.getLoggedInUserEditList().includes('1014');
    }
  }

  viewGeneralInvoiceRefPdf(id) {
    this.generalInvoiceService.exportPdfWithName(id).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    }, () => { });
  }

  downloadGeneralInvoiceRefPdf(id) {
    this.generalInvoiceService.exportPdfWithName(id).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
      }
    }, () => { });
  }

  viewPunchInvoiceRefPdf(invoiceId) {
    this.punchBillingService.exportPdfDetailsByInvoiceId(invoiceId).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    }, () => { });
  }

  downloadPunchInvoiceRefPdf(invoiceId) {
    this.punchBillingService.exportPdfDetailsByInvoiceId(invoiceId).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
      }
    }, () => { });
  }

  isOver24hrs(createdDate) {
    const currentTime = moment().tz('America/New_York');
    return currentTime.diff(createdDate, 'days') > 0;
  }

  tableFilter(e) {
    const interval = setInterval(() => {

      if (!this.loading && this.searchText === e.target.value) {
        this.search();
      }

      if (interval)
        clearInterval(interval);
    }, 1000);
  }

  totalEstimate: any = 0;
  estimateExportTime() {
    this.cancelExporting();
    this.totalEstimate = Math.floor(this.totalRecords * 0.15);
    const hoursNum = Math.floor(this.totalEstimate / 3600);
    const minutesNum = Math.floor((this.totalEstimate - (hoursNum * 3600)) / 60);
    const secondsNum = Math.floor(this.totalEstimate - (hoursNum * 3600) - (minutesNum * 60));
    const hours = hoursNum >= 10 ? hoursNum : ('0' + hoursNum);
    const minutes = minutesNum >= 10 ? minutesNum : ('0' + minutesNum);
    const seconds = secondsNum >= 10 ? secondsNum : ('0' + secondsNum);
    this.estDownloadAll = hours + ':' + minutes + ':' + seconds;
    this.exporting = true;

    this.downloadALlInterval = setInterval(() => {
      this.durDownloadAll = (this.durDownloadAll || 0) + 1;

      const hoursNum = Math.floor(this.durDownloadAll / 3600);
      const minutesNum = Math.floor((this.durDownloadAll - (hoursNum * 3600)) / 60);
      const secondsNum = Math.floor(this.durDownloadAll - (hoursNum * 3600) - (minutesNum * 60));
      const hours = hoursNum >= 10 ? hoursNum : ('0' + hoursNum);
      const minutes = minutesNum >= 10 ? minutesNum : ('0' + minutesNum);
      const seconds = secondsNum >= 10 ? secondsNum : ('0' + secondsNum);
      this.durDownloadAllText = hours + ':' + minutes + ':' + seconds;

      const progressBar = (this.durDownloadAll / this.totalEstimate) * 100;
      this.progressDownloadAll = (progressBar < 100 ? progressBar : 99.99).toFixed(2);
    }, 1000);
  }

  cancelExporting() {
    this.exporting = false;
    this.totalEstimate = 0;
    this.progressDownloadAll = 0;
    this.durDownloadAll = 0
    this.estDownloadAll = '00:00:00';
    this.durDownloadAllText = '00:00:00';
    if (this.exportSubscribe) {
      this.exportSubscribe.unsubscribe();
    }
    if (this.downloadALlInterval) {
      clearInterval(this.downloadALlInterval);
    }
  }

  switchView(type) {
    this.tableView = '1' === type;

    if (!this.tableView && this.allChartData) {
      this.yOptionsLabel = this.yOptions[0].label;
      this.xChartData = this.allChartData.byInvoiceDate.xData;
      this.yChartData = this.allChartData.byInvoiceDate.amounts;
      this._initBarChartsOptions(this.yOptionsLabel, this.xChartData, this.yChartData);
    }
  }

  _initBarChartsOptions(dataSetLabel, xLabels, yValues) {
    const ts = this;
    this.chartOptions = {
      tooltips: {
        callbacks: {
          label: function (t, data) {
            const dataset: any = data.datasets[t.datasetIndex];
            const currentValue = dataset.data[t.index];
            return ts.yOptionsLabel + ': ' + (currentValue && currentValue > 0 ? '$' + (currentValue).toFixed(2) : '0');
          }
        }
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            callback: (label, index, labels) => {
              return (label && label > 0 ? '$' + (label).toFixed(2) : '0');
            },
          },
        }],
      },
    };

    this.chartData = {
      data: {
        labels: xLabels,
        datasets: [
          {
            label: dataSetLabel,
            data: yValues,
            backgroundColor: '#03A9F4',
            hoverBackgroundColor: '#006b9a'
          }]
      },
      type: 'bar',
      options: this.chartOptions
    };
    this.paymentInvoiceBarChart = new Chart('paymentInvoiceBarChart', this.chartData);
  }

  _initLineChartsOptions(dataSetLabel, xLabels, yValues) {
    const ts = this;
    this.chartOptions = {
      tooltips: {
        callbacks: {
          label: function (t, data) {
            const dataset: any = data.datasets[t.datasetIndex];
            const currentValue = dataset.data[t.index];
            return ts.yOptionsLabel + ': ' + (currentValue && currentValue > 0 ? '$' + (currentValue).toFixed(2) : '0');
          }
        }
      },
      responsive: true,
      scales: {
        xAxes: [{
        }],
        yAxes: [{
          ticks: {
            callback: (label, index, labels) => {
              return (label && label > 0 ? '$' + (label).toFixed(2) : '0');
            },
          },
        }],
      },
    };

    this.chartData = {
      data: {
        labels: xLabels,
        datasets: [
          {
            label: dataSetLabel,
            data: yValues,
            fill: false,
            borderColor: '#03A9F4'
          }]
      },
      type: 'line',
      options: this.chartOptions
    };
    this.paymentInvoiceLineChart = new Chart('paymentInvoiceLineChart', this.chartData);
  }

  switchChartType(type) {
    this.chartType = type;
    this.chartDataChange();
  }

  getDefaultWeeks() {
    const listWeek = [];
    for (let i = 1; i <= 52; i++) {
      listWeek.push(i);
    }
    return listWeek;
  }

  getDefaultYears() {
    const years = [];
    const currentYear = moment().year();
    for (let i = currentYear; i >= 2021; i--) {
      const o = {
        label: i === currentYear ? 'Current Year' : (i === currentYear - 1 ? 'Last Year' : i.toString()),
        value: i === currentYear ? '1' : (i === currentYear - 1 ? '2' : i.toString()),
      }
      years.push(o);
    }
    return years;
  }

  chartSummary(invoiceDate, invoice, unit?) {
    let unitDate = unit !== 'invoiceDate' ? moment(invoiceDate).utc().get(unit) : moment(invoiceDate).format('MM/DD/YYYY');
    if (unit === 'invoiceDate') {
      unitDate = moment(invoiceDate).format('MM/DD/YYYY');
    } else if (unit === 'month') {
      unitDate = this.defaultMonths[moment(invoiceDate).utc().get(unit)];
    } else if (unit === 'quarter') {
      unitDate = this.defaultQuarter[moment(invoiceDate).utc().get(unit) - 1];
    } else {
      unitDate = moment(invoiceDate).utc().get(unit);
    }

    const chartData = this.chartDataTmp.find(i => i.unitDate === unitDate && i.unit === unit && i.year === moment(invoiceDate).utc().year());

    if (chartData) {
      chartData.amount = (chartData.amount || 0) + invoice.totalAmount;
      chartData.total = (chartData.total || 0) + invoice.totalFee;
      chartData.openBalance = (chartData.openBalance || 0) + invoice.balance;
    } else {
      const data = {
        unit: unit,
        year: moment(invoiceDate).utc().year(),
        unitDate: unitDate,
        amount: invoice.totalAmount || 0,
        total: invoice.totalFee || 0,
        openBalance: invoice.balance || 0
      }

      this.chartDataTmp.push(data);
    }
  }

  buildChartConfig() {
    let allYears = this.chartDataTmp.filter(i => i.unit === 'year');
    let allQuarters = this.chartDataTmp.filter(i => i.unit === 'quarter');
    let allMonths = this.chartDataTmp.filter(i => i.unit === 'month');
    let allWeeks = this.chartDataTmp.filter(i => i.unit === 'week');
    let allInvoiceDates = this.chartDataTmp.filter(i => i.unit === 'invoiceDate');

    allYears = _.orderBy(allYears, ['unitDate'], ['asc']);
    allQuarters = _.orderBy(allQuarters, ['unitDate'], ['asc']);
    allMonths = _.orderBy(allMonths, ['unitDate'], ['asc']);
    allWeeks = _.orderBy(allWeeks, ['unitDate'], ['asc']);
    allInvoiceDates = _.orderBy(allInvoiceDates, ['unitDate'], ['asc']);

    this.allChartData = {
      byWeek: this.getYData(this.getDefaultWeeks(), allWeeks),
      byMonth: this.getYData(this.defaultMonths, allMonths),
      byQuarter: this.getYData(this.defaultQuarter, allQuarters),
      byYear: {
        xData: allYears.map(i => i.unitDate),
        amounts: allYears.map(i => i.amount),
        total: allYears.map(i => i.total),
        openBalance: allYears.map(i => i.openBalance),
      },
      byInvoiceDate: {
        xData: allInvoiceDates.map(i => i.unitDate),
        amounts: allInvoiceDates.map(i => i.amount),
        total: allInvoiceDates.map(i => i.total),
        openBalance: allInvoiceDates.map(i => i.openBalance),
      }
    };
  }

  async loadChartData() {
    this.chartDataTmp = [];
    this.loadingDataChart = true;
    const options = this.buildChartOptions(0, 99999);
    const res: any = await this.contentService.filter(options).toPromise();
    if (res.status === 'SUCCESS') {
      const contentList = res.data.content;
      for (const invoice of contentList) {
        const invoiceDate = new Date(invoice.invoiceDate);
        invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());

        this.chartSummary(invoiceDate, invoice, 'year');
        this.chartSummary(invoiceDate, invoice, 'quarter');
        this.chartSummary(invoiceDate, invoice, 'month');
        this.chartSummary(invoiceDate, invoice, 'week');
        this.chartSummary(invoiceDate, invoice, 'invoiceDate');
      }

      this.buildChartConfig();
    }
    this.loadingDataChart = false;
  }

  buildChartOptions(first?, rows?) {
    let fromDate, toDate;
    if (this.timeOptionSelected === '1') {
      fromDate = moment().set('date', 1).set('month', 0).utc(true).startOf('day').toDate();
      toDate = moment().utc(true).endOf('day').toDate();
    } else if (this.timeOptionSelected === '2') {
      fromDate = moment().set('date', 1).set('month', 0).set('year', moment().year() - 1).utc(true).startOf('day').toDate();
      toDate = moment().set('date', 31).set('month', 11).set('year', moment().year() - 1).utc(true).endOf('day').toDate();
    } else {
      fromDate = moment().set('date', 1).set('month', 0).set('year', this.timeOptionSelected).utc(true).startOf('day').toDate();
      toDate = moment().set('date', 31).set('month', 11).set('year', this.timeOptionSelected).utc(true).endOf('day').toDate();
    }

    if (this.xViewSelected === '5') {
      fromDate = null;
      toDate = null;
    }

    return {
      page: first ? first : 0,
      size: rows ? rows : 20,
      sortField: 'id',
      sortOrder: 'DESC',
      companyId: this.isPlatformAdmin ? this.selectedCompany : this.authService.getCurrentCompany(),
      fromDate: fromDate,
      toDate: toDate,
      oldInvoiceOnly: false,
      ignoreInvoiceDetails: true,
    };
  }

  getYData(units, lstData) {
    const amounts = [];
    const total = [];
    const openBalance = [];
    for (const u of units) {
      const item = lstData.find(i => i.unitDate === u);
      amounts.push(item ? item.amount : 0);
      total.push(item ? item.total : 0);
      openBalance.push(item ? item.openBalance : 0);
    }

    return {
      xData: units,
      amounts: amounts,
      total: total,
      openBalance: openBalance,
    }
  }

  timeOptionChange() {
    this.loadChartData().then(() => {
      this.chartDataChange();
    });
  }

  chartDataChange() {
    if (this.xViewSelected === '1') {
      this.xChartData = this.allChartData.byInvoiceDate.xData;
      this.xDataSelected = this.allChartData.byInvoiceDate;
      this.updateChartConfig();
    } else if (this.xViewSelected === '2') {
      this.xChartData = this.allChartData.byWeek.xData;
      this.xDataSelected = this.allChartData.byWeek;
      this.updateChartConfig();
    } else if (this.xViewSelected === '3') {
      this.xChartData = this.allChartData.byMonth.xData;
      this.xDataSelected = this.allChartData.byMonth;
      this.updateChartConfig();
    } else if (this.xViewSelected === '4') {
      this.xChartData = this.allChartData.byQuarter.xData;
      this.xDataSelected = this.allChartData.byQuarter;
      this.updateChartConfig();
    } else {
      if (this.allChartData.byYear && this.allChartData.byYear.xData?.length > 1) {
        this.updateChartConfig();
      } else {
        this.loadChartData().then(() => {
          this.xChartData = this.allChartData.byYear.xData;
          this.xDataSelected = this.allChartData.byYear;
          this.updateChartConfig();
        });
      }
    }
  }

  updateChartConfig() {
    if (this.yViewSelected === '1') {
      this.yChartData = this.xDataSelected.amounts;
    } else if (this.yViewSelected === '2') {
      this.yChartData = this.xDataSelected.total;
    } else {
      this.yChartData = this.xDataSelected.openBalance;
    }
    this.yOptionsLabel = this.yOptions.find(i => i.value === this.yViewSelected)?.label;

    if (this.chartType === '1') {
      this.paymentInvoiceBarChart.config.data.labels = this.xChartData;
      this.paymentInvoiceBarChart.config.data.datasets[0].label = this.yOptionsLabel;
      this.paymentInvoiceBarChart.config.data.datasets[0].data = this.yChartData;
      this.paymentInvoiceBarChart.update();
    } else {
      if (!this.paymentInvoiceLineChart) {
        this._initLineChartsOptions(this.yOptionsLabel, this.xChartData, this.yChartData);
      } else {
        this.paymentInvoiceLineChart.config.data.labels = this.xChartData;
        this.paymentInvoiceLineChart.config.data.datasets[0].label = this.yOptionsLabel;
        this.paymentInvoiceLineChart.config.data.datasets[0].data = this.yChartData;
        this.paymentInvoiceLineChart.update();
      }
    }
  }

  searchInvoices() {
    if (this.authService.isContractorRole()) {
      return;
    }
    const options = <any>{
      page: 0,
      size: 9999
    };

    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }

    if (this.authService.isClientRole()) {
      options.clientId = this.authService.getCurrentLoggedInId();
    }
    options.oldInvoiceOnly = false;
    options.sortField = "id";
    options.sortOrder = "DESC";
    options.ignoreInvoiceDetails = true;
    this.contentService.filter(options).subscribe((res: any) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const list = resObj.data.content;
        this.invoices = [];
        const now = moment().startOf('d');
        for (const invoice of list) {
          if (invoice.status === 'Pending') {
            continue;
          }
          const dueDate = moment(invoice.dueDate).startOf('d');
          const diff = dueDate.diff(now, 'd', true);
          let lateFee = 0;
          if (invoice.totalLateFee && !invoice.waiveLateFee) {
            lateFee = invoice.totalLateFee;
          }
          const balance = invoice.balance - lateFee;
          if (balance > 0 && balance.toFixed(2) !== '0.00') {
            this.invoices.push({
              label: invoice.invoiceNumber, value: balance, id: invoice.id, clientId: invoice.clientId, invoiceType: invoice.invoiceType,
              status: invoice.status, lateInvoice: diff < 0, waiveLateFee: invoice.waiveLateFee, apiUsageCost: invoice.apiUsageCost, lateFee: lateFee
            });
          }
        }
      }
    }, err => {
      console.log(err);
    });
  }

  onPayPosClientChange() {
    this.invoiceOptions = this.invoices.filter(i => i.clientId === this.invoicePayPOS.clientId);
    this.resetInvoicePayPOS();
  }

  totalInvoice() {
    this.invoicePayPOS.totalAmount = 0;
    this.invoicePayPOS.totalLateFee = 0;
    this.invoicePayPOS.totalFee = 0;
    this.invoicePayPOS.totalChargeAmount = 0;
    if (this.invoicePayPOS.invoices && this.invoicePayPOS.invoices.length > 0) {
      this.invoicePayPOS.description = 'Pay Via POS for invoices: ' + this.invoicePayPOS.invoices.map(i => i.label).join(', ');
      this.invoicePayPOS.invoices.forEach((invoice) => {
        if (invoice.value > 0) {
          this.invoicePayPOS.totalAmount += invoice.value;
          this.invoicePayPOS.totalLateFee += invoice.lateFee;
          invoice.totalFee = ((invoice.value + invoice.lateFee) * this.transferFeeCashOut / 100) + this.transferFeeFixedAmount;
          invoice.totalChargeAmount = invoice.totalFee + invoice.value + invoice.lateFee;
          this.invoicePayPOS.totalFee += invoice.totalFee;
          this.invoicePayPOS.totalChargeAmount += invoice.totalChargeAmount;
        }
      });
    }
  }

  acceptInvoicePayPOS() {
    this.invoicePayPOS.submitting = true;
    this.cardReaderPaymentDialog = true;
    this.chargeAmountWithExternalPayment(this.invoicePayPOS.totalChargeAmount, this.invoicePayPOS.description);
  }

  async chargeAmountWithExternalPayment(totalAmount: number, description: string) {
    if (this.timerPaymentStatus) {
      clearInterval(this.timerPaymentStatus);
    }
    this.cardPaymentStatus = null;
    this.posFailedMessage = null;
    if (!this.terminalId) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: 'No available readers.' });
      this.cardPaymentStatus = 'no_available_readers';
      return;
    }
    if (totalAmount <= 0) {
      this.cardPaymentStatus = 'invalid_amount';
      return;
    }
    try {
      if (this.cardPaymentStatus === 'cancel') {
        return;
      }

      const readerRes: any = await this.externalPaymentService.retrieveReader(this.terminalId, this.testMode).toPromise();
      if (!readerRes || !readerRes.data) {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: 'No available readers.' });
        this.cardPaymentStatus = 'no_available_readers';
        return;
      }
      const reader = JSON.parse(readerRes.data);
      if (reader.status !== 'online') {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: 'No available readers.' });
        this.cardPaymentStatus = 'no_available_readers';
        return;
      }
      if (this.cardPaymentStatus === 'cancel') {
        return;
      }
      const paymentIntentRes: any = await this.externalPaymentService.createPaymentIntent(totalAmount, this.testMode, description).toPromise();
      console.log("PaymentIntent: ", paymentIntentRes);
      if (paymentIntentRes.status !== 200) {
        this.cardPaymentStatus = 'failed';
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: paymentIntentRes.message });
        return;
      }
      this.invoicePayPOS.paymentIntentId = paymentIntentRes.data.id;
      if (this.cardPaymentStatus === 'cancelling' || this.cardPaymentStatus === 'cancelled') {
        return;
      }
      const processPaymentIntentRes: any = await this.externalPaymentService.processPaymentIntent(this.terminalId, this.invoicePayPOS.paymentIntentId, this.testMode).toPromise();
      if (processPaymentIntentRes.status === 200) {
        this.cardPaymentStatus = 'ready';
        let lastChargeError;
        this.timerPaymentStatus = setInterval(() => {
          this.externalPaymentService.retrievePaymentIntent(this.invoicePayPOS.paymentIntentId, this.testMode).subscribe(async (res: any) => {
            if (res && res.status === 200) {
              console.log(res.data);
              if (res.data.status === 'succeeded') {
                this.invoices = this.invoices.filter(i => !this.invoicePayPOS.invoices.find(invoice => invoice.id === i.id));
                if (this.timerPaymentStatus) {
                  clearInterval(this.timerPaymentStatus);
                }
                let chargeId;
                let paymentMethod = 'POS';
                if (res.data.charges && res.data.charges.data && res.data.charges.data.length > 0) {
                  const succeeded = res.data.charges.data.filter(c => c.status === 'succeeded');
                  if (succeeded && succeeded.length > 0) {
                    chargeId = succeeded[succeeded.length - 1].id;
                    if (res.data.payment_method_types && res.data.payment_method_types.length) {
                      const methodType = res.data.payment_method_types[0];
                      const details = succeeded[succeeded.length - 1]['payment_method_details'][methodType];
                      if (details) {
                        if (methodType === 'card_present' || methodType === 'card') {
                          paymentMethod = details.brand.toUpperCase();
                        } else {
                          paymentMethod = methodType.toUpperCase();
                        }
                      }
                    }
                  }
                }
                if (chargeId) {
                  this.invoicePayPOS.chargeId = chargeId;
                  const paymentTransaction = <any>{
                    date: new Date(),
                    invoiceNumber: this.invoicePayPOS.invoices.map(i => i.label),
                    transactionId: chargeId,
                    paymentMethod: paymentMethod,
                    amount: totalAmount,
                    companyId: this.authService.getCurrentCompanyId(),
                    createdByUsr: this.authService.getCurrentUsername(),
                    autoPay: false,
                    transferFee: this.invoicePayPOS.totalFee,
                    lateFee: this.invoicePayPOS.totalLateFee
                  }
                  const time = new Date();
                  this.paymentTransactionService.create(paymentTransaction).subscribe(() => {
                    this.cardPaymentStatus = 'success';
                    this.invoicePayPOS.invoiceLabel = paymentTransaction.invoiceNumber;
                    this.invoicePayPOS.paymentMethod = paymentMethod;
                    this.invoicePayPOS.chargeId = chargeId;
                    this.invoicePayPOS.invoices.forEach((invoice) => {
                      if (invoice.totalChargeAmount > 0) {
                        const time = new Date();
                        this.savePOSTransaction(invoice, paymentTransaction, time);
                      }
                    });
                  }, err => {
                    const args = {
                      payment_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                    }
                    this.saveMonitorDetails(args, `Paid & Checkout Failed`, time, (r) => {
                      this.paymentNotificationService.sendPaymentFailedEmail(r, err.error.message);
                    });
                  });
                }
              } else if (res.data.charges && res.data.charges.data && res.data.charges.data.length > 0) {
                const lastPaymentError = res.data.last_payment_error;
                if (lastPaymentError && lastChargeError !== lastPaymentError.charge) {
                  lastChargeError = lastPaymentError.charge;
                  this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: lastPaymentError.message });
                  if (this.timerPaymentStatus) {
                    clearInterval(this.timerPaymentStatus);
                  }
                  this.posFailedMessage = lastPaymentError.message;
                  this.cardPaymentStatus = 'failed';
                }
              } else {
                const readerRes: any = await this.externalPaymentService.retrieveReader(this.terminalId, this.testMode).toPromise();
                if (!readerRes || !readerRes.data) {
                  this.posFailedMessage = 'No available readers.';
                  this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: 'No available readers.' });
                  this.cardPaymentStatus = 'no_available_readers';
                  if (this.timerPaymentStatus) {
                    clearInterval(this.timerPaymentStatus);
                  }
                  this.cardPaymentStatus = 'failed';
                  return;
                }
                if (readerRes.data.action) {
                  const cardStatus = readerRes.data.action.status;
                  if (cardStatus === 'failed') {
                    if (this.timerPaymentStatus) {
                      clearInterval(this.timerPaymentStatus);
                    }
                    this.posFailedMessage = readerRes.data.action.failure_message;
                    this.cardPaymentStatus = 'failed';
                  }
                }
              }
            }
          });
          if (this.testMode) {
            setTimeout(() => {
              if (this.cardPaymentStatus === 'cancelling' || this.cardPaymentStatus === 'cancelled') {
                return;
              }
              this.externalPaymentService.testHelpersPaymentIntent(this.terminalId, this.testCardNumber ? this.testCardNumber : '42424242424242', this.testMode).subscribe();
            }, 2000);
          }
        }, 3000);
      } else {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: processPaymentIntentRes.message });
        this.cardPaymentStatus = 'failed';
      }
    } catch (error) {
      this.cardPaymentStatus = 'failed';
    }
  }

  cancelCardReaderPayment() {
    this.cardPaymentStatus = 'cancelling';
    if (!this.invoicePayPOS.paymentIntentId) {
      this.cardPaymentStatus = 'cancelled';
      this.invoicePayPOS.submitting = false;
      return;
    }
    this.externalPaymentService.cancelReaderAction(this.terminalId, this.testMode).subscribe(() => {
      this.externalPaymentService.cancelPaymentIntent(this.invoicePayPOS.paymentIntentId, this.testMode).subscribe(() => {
        this.cardPaymentStatus = 'cancelled';
        this.invoicePayPOS.submitting = false;
      });
    });
  }

  retryCardReaderPayment() {
    this.acceptInvoicePayPOS();
  }

  closeCardReaderPaymentDialog() {
    if (this.cardPaymentStatus === 'success') {
      this.search();
    }
    this.cardPaymentStatus = null;
    this.cardReaderPaymentDialog = false;
    this.resetInvoicePayPOS();
    this.invoicePayPOSDialog = false;
  }

  resetInvoicePayPOS() {
    this.invoicePayPOS = {
      paymentIntentId: null,
      clientId: null,
      invoices: [],
      totalFee: 0,
      totalAmount: 0,
      totalLateFee: 0,
      totalChargeAmount: 0,
      description: null,
      submitting: false
    }
    this.invoicePayPOS.invoices = [];
  }

  savePOSTransaction(invoice, paymentTransaction, time) {
    const details: any = [];
    const payload: any = {
      companyId: this.authService.getCurrentCompanyId(),
      createdByUsr: this.authService.getCurrentUsername(),
      crmClientId: invoice.clientId,
      projectId: this.selectedProject,
      expenseCurrency: 'USD',
      invoiceId: invoice.id,
      status: 1,
      appliedTransaction: true,
      paymentTransactionId: paymentTransaction.transactionId
    };

    const transactionDetail: any = {};
    transactionDetail.invoiceId = invoice.id,
      transactionDetail.status = 1;
    transactionDetail.date = new Date();
    transactionDetail.cashIn = invoice.totalChargeAmount;
    transactionDetail.cashKept = 0;
    transactionDetail.cashOut = 0;
    transactionDetail.paymentMethod = paymentTransaction.paymentMethod;
    transactionDetail.createdByUsr = this.authService.getCurrentUsername(),
      // tslint:disable-next-line:max-line-length
    transactionDetail.netCashInOut = (parseFloat(transactionDetail.cashIn) + parseFloat(transactionDetail.cashKept)) - parseFloat(transactionDetail.cashOut);
    transactionDetail.netCashInOut = transactionDetail.netCashInOut.toFixed(2);
    transactionDetail.paymentTransactionId = paymentTransaction.transactionId;
    transactionDetail.clientInvoiceFee = Number(invoice.totalFee);
    transactionDetail.lateFee = Number(invoice.lateFee);
    details.push(transactionDetail);
    payload.transactionDetails = details;
    this.transactionService.createTransaction(payload).subscribe((res: any) => {
      if (invoice.id) {
        this.contentService.updateApplyTransaction(invoice.id).toPromise();
        const args = {
          paid_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
        }
        this.saveMonitorDetails(args, `Paid & Checkout with Success`, time)
      }
    });
  }

  saveMonitorDetails(args, type, time, callback?: Function) {
    this.monitoringDetailsService.monitorAction(
      `${type}`,
      time,
      args,
      'complete',
      `${type}`,
      0,
      callback
    );
  }

  async openTransactionDetails(rowData) {
    this.invoiceIdTmp = rowData?.id;
    await this.loadLazyTransaction(this.invoiceIdTmp).then(() => {
      this.showTransactionDetails = true;
    });
  }

  onChangeUploadOption(event, data) {
    this.selectedTransactionDetail = data;
    if (event.value.label === 'View') {
      this.onViewDocument();
    }
    if (event.value.label === 'Upload' || event.value.label === 'Change') {
      if (event.value.label === 'Change') {
        this.actionHistoryFile = 'Changed';
      } else {
        this.actionHistoryFile = 'Updated';
      }
      this.selectFile();
    }
    if (event.value.label === 'Remove') {
      this.actionHistoryFile = 'Removed';
      this.onRemoveDocument();
    }
    if (event.value.label === 'History') {
      this.openViewHistory();
    }
    data.uploadOption = null;
    this.selectedTransactionDetail.uploadOption = {};
  }

  openConfirmationPopup(type?, id?) {
    this.confirmationId = id;
    this.confirmationType = type;
    this.showConfirmationPopup = (type === '1' || type === '2');
  }

  onViewDocument() {
    if (this.selectedTransactionDetail.fileUrl.includes('.pdf')) {
      window.open(this.selectedTransactionDetail.fileUrl);
    } else {
      if (this.selectedTransactionDetail.fileUrl.includes('http')) {
        this.fileUrl = this.selectedTransactionDetail.fileUrl;
        this.showViewFileDialog = true;
      } else {
        this.documentsService.getUploadedFile(this.selectedTransactionDetail.fileUrl).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            if (resObj.data.fileType === 'pdf') {
              window.open(resObj.data.fileUrl);
            } else {
              this.fileUrl = resObj.data.fileUrl;
              this.showViewFileDialog = true;
            }
          }
        }, () => {});
      }
    }
  }

  selectFile() {
    document.getElementById('fileUrl').click();
  }

  onRemoveDocument() {
    if (this.selectedTransactionDetail.fileUrl) {
      this.selectedTransactionDetail.fileUrl = null;
      this.selectedTransactionDetail.action = 'Removed';
      this.transactionDetailsService.updateTransactionDetail(this.selectedTransactionDetail, this.selectedTransactionDetail.id).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.messageService.add({ severity: 'info', summary: this.translatePipe.transform('File Delete'), detail: this.translatePipe.transform('The attached file has been removed') });
        }
      });
    }
  }

  openViewHistory() {
    this.historyFor = {};
    this.showHistoryUploadDialog = true;
    this.historySelectedField = 'fileUrl';
    this.fieldsName = [
      { label: 'Document', value: 'fileUrl' },
    ];
    this.loadDataSource();
  }

  loadDataSource(event?: any) {
    this.historySize = event && event.rows ? event.rows : (this.historySize ? this.historySize : 25);
    this.historyPage = event && event.first && event.rows ? (event.first / event.rows) : (this.historyPage ? this.historyPage : 0);
    const options: any = {
      size: this.historySize,
      page: this.historyPage,
    };
    options.fromDate = this.historyFromDate ? new Date(this.historyFromDate) : null;
    options.toDate = this.historyToDate ? new Date(this.historyToDate) : null;
    options.transactionDetailsId = this.selectedTransactionDetail.id;
    options.fieldName = this.historySelectedField;
    if (!options.transactionDetailsId || !this.historySelectedField) {
      this.dataSourceSubject.next([]);
      return;
    }
    this.transactionDetailsHistoryService.getTransactionDetailsHistory(options).subscribe(
        (data: any) => {
          if (data.data.content) {
            data.data.content.forEach(ele => {
              if (ele.fieldName === 'fileUrl') {
                if (ele.oldValue) {
                  this.documentsService.getUploadedFile(ele.oldValue).subscribe((res: any) => {
                    ele.oldValue = res.data.fileUrl;
                  });
                }
                if (ele.newValue) {
                  this.documentsService.getUploadedFile(ele.newValue).subscribe((res: any) => {
                    ele.newValue = res.data.fileUrl;
                  });
                }
              }
            });
            this.dataSourceSubject.next(data.data);
          }
        }
    );
  }

  getAdminName(username) {
    if (username) {
      const admin = this.adminList.find(el => el.userName === username);
      if (admin) { return admin.name; }
    }
    return username;
  }

  loadAllAdminNameList() {
    const params: any = {};
    if (!this.isPlatformAdmin) {
      params.companyId = this.authService.getCurrentCompanyId();
    }
    this.companyAdminService.getAllAdminList(params).subscribe((res) => {
      this.adminList = res.data;
    });
  }
  viewFullFile(url) {
    if (url.endsWith('.pdf')) {
      window.open(url);
    } else if (url.endsWith('.xlsx')) {
      window.location.href = url;
    } else {
      this.fileUrl = url;
      this.showViewFileDialog = true;
    }
  }

  async loadLazyTransaction(invoiceId) {
    this.transactionLoading = true;
    const options: any = {
      invoiceId: invoiceId,
    };

    this.transactionDetailsService.getByInvoiceId(options).subscribe(res => {
      this.transactions = (res?.data || [])
      this.transactions.forEach(ele => {
        ele.showHideActions = (moment(moment().toDate()).diff(ele.date, 'minutes') >= 1440);
        ele.date = new Date(ele.date);
        ele.transactionTypeName = ele.transactionType ? ele.transactionType.transactionType : ele.paymentMethod;
        if (ele.transactionTypeName === 'cash') {
          ele.transactionTypeName = 'Cash';
        }
        if (ele.transactionTypeName === 'credit/Debit') {
          ele.transactionTypeName = 'Credit/Debit';
        }

        if (ele.transactionTypeName === 'zelle') {
          ele.transactionTypeName = 'Zelle';
        }

        if (ele.transactionTypeName === 'check') {
          ele.transactionTypeName = 'Check';
        }

        if (ele.transactionTypeName === 'other') {
          ele.transactionTypeName = 'Other';
        }
      });
      this.totalTransactions = res?.data?.length || 0;
      this.getClientBase();
      this.transactionLoading = false;
    });
  }

  async getClientBase() {
    if (this.transactions && this.transactions.length > 0) {
      this.transactions.forEach(data => {
        const nameClient = this.clientList.filter(item => {
          return item.key === data.transaction.crmClientId;
        });

        data.nameClient = nameClient && nameClient.length > 0 ? nameClient[0].fullName : '';
        let options: any = {};
        options = {
          page: 0,
          size: 9999
        };
        if (data.transaction != null && data.transaction.crmClientId != null) {
          options.clientId = data.transaction.crmClientId;
          options.crmClientIds = [data.transaction.crmClientId];
        }
        options.sortField = 'id';
        options.sortOrder = 'DESC';
        let invoiceIds: number[] = [];
        if (data.invoiceLst != null) {
          invoiceIds = data.invoiceLst.split(",").map(Number);
        }
        options.invoiceIds = invoiceIds;

        this.contentService.filter(options).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            const contentList = resObj.data.content;
            let arr = [];
            contentList.forEach(invoice => {
              if (data?.type == 'group') {
                arr.push(invoice.invoiceNumber);
              } else {
                if ((data.invoiceId === invoice.id)) {
                  data.invoiceNumber = invoice.invoiceNumber;
                }
              }
            });
            if (arr.length > 0) {
              data.invoiceNumber = arr.join(',');
            }
          }
        });
      });
    }
  }

  confirmTransaction() {
    if (this.confirmationId && this.confirmationType) {
      const verification1st = this.confirmationType === '1' ? this.authService.getCurrentUsername() : '';
      const verification2st = this.confirmationType === '2' ? this.authService.getCurrentUsername() : '';
      this.transactionDetailsService.updateTransactionConfirm({ id: this.confirmationId, verification1st, verification2st }).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'success',
            summary: this.translatePipe.transform('Success'),
            detail: this.translatePipe.transform('Transaction confirm successfully')
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Error'),
            detail: this.translatePipe.transform('Transaction confirm failure')
          });
        }
        this.loadLazyTransaction(this.invoiceIdTmp)
        this.openConfirmationPopup();
      }, (error) => {
        this.loadLazyTransaction(this.invoiceIdTmp)
        this.openConfirmationPopup();
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: error.error.message
        });
      })
    }
  }

  getAllInvoices() {
    const option = {
      companyId: !this.isPlatformAdmin ? this.authService.getCurrentCompanyId() : null
    }
    this.contentService.getAllInvoicesDropdown(option).subscribe(res => {

      this.allInvoicesDropdownData = (res?.data || []).filter(i => i.invoiceNumber).map(i => {
        const companyName = this.allCompanyList.find(c => c.value === i.companyId)?.label
        return {
          label: i.invoiceNumber + (companyName ? ' (' + companyName + ')' : ''),
          value: i.invoiceNumber
        }
      })
    })
  }
}
