<p-tabMenu [model]="employeeDashboardTabs" [activeItem]="activeItem">
	<ng-template pTemplate="item" let-item let-i="index">
		<span class="text-nowrap"><i class="fa-solid {{item.icon}} text-primary mr-2"></i> {{item.label | translate}}</span>
	</ng-template>
</p-tabMenu>
<div class="card add-shadow p-4">
    <h1 class="m-0 ml-2 my-4">{{'Welcome' | translate}}</h1>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12">
			<p-panel [toggleable]="true" id="hoursGraph">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-pie-chart text-primary mr-2"></i>{{'Hours' | translate}}:  {{getDate(fromDate)}} - {{getDate(toDate)}}</span>
				</p-header>
				<div class="h-100 d-flex align-items-center justify-content-center">
					<canvas id="payHourChart" [style]="{'width':'100%', 'max-height':'260px', 'max-width':'500px'}"></canvas>
				</div>
			</p-panel>
		</div>
    <div class="ui-g-6 ui-sm-12">
		<p-panel [toggleable]="true">
            <p-header class="flex-grow-1">
                <span><i class="fa-solid fa-chart-column text-primary mr-2"></i>{{'Job Reports' | translate}} ({{jobReports?.length || 0}} {{'latest' | translate}})</span>
            </p-header>
			<p-table #dt [value]="jobReports" dataKey="id" selectionMode="single" [lazy]="true" (onLazyLoad)="loadDataSourceJobReport($event)" [rows]="6" [paginator]="false" [totalRecords]="(jobReports || []).length">
				<ng-template pTemplate="header">
					<tr>
						<th>
							<span class="d-flex align-items-center justify-content-start w-100">
								<span class="one-liner" pTooltip="{{'Date' | translate}}" tooltipPosition="left">{{'Date' | translate}}</span>
							</span>
						</th>
						<th>
							<span class="d-flex align-items-center justify-content-start w-100">
								<span class="one-liner" pTooltip="{{'Note' | translate}}" tooltipPosition="left">{{'Note' | translate}}</span>
							</span>
						</th>
						<th>
							<span class="d-flex align-items-center justify-content-start w-100">
								<span class="one-liner" pTooltip="{{'Updated' | translate}}" tooltipPosition="left">{{'Updated' | translate}}</span>
							</span>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
					<tr [pSelectableRow]="rowData" (click)="gotoJobReport()" style="cursor: pointer">
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span *ngIf="rowData.reportDate" pTooltip="{{ rowData.createdAt | date : 'M/d/yyy h:mm:ss a'}}" tooltipPosition="left">{{ rowData.createdAt | date : 'M/d/yyy h:mm:ss a'}}</span>
								<span *ngIf="!rowData.reportDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="d-flex">
								<span class="mobile-table-label text-nowrap">{{'Note' | translate}}: </span>
								<span *ngIf="rowData.latestNote" [innerHTML]="rowData.latestNote" class="one-liner"></span>
								<span *ngIf="!rowData.latestNote" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span pTooltip="{{rowData.createdAt | date: 'M/d/yyy h:mm:ss a'}}" tooltipPosition="left">{{rowData.createdAt | date: 'M/d/yyy h:mm a'}}</span>
								<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="jobReports?.length == 0 && !loadingJobReports">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5" style="margin: 67px 0px !important">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="loadingJobReports">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5" style="margin: 67px 0px !important"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
        </p-panel>
    </div>
	<div class="ui-g-6 ui-md-12 ui-sm-12">
		<p-panel [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Compaliance/Attendance' | translate}} <span *ngIf="totalRecords">({{totalRecords}})</span></span>
			</p-header>
			<p-table #dt header="Index" [value]="data" dataKey="id" [customSort]="true" [lazy]="true" (onLazyLoad)="loadComplianceReport($event)" [totalRecords]="totalRecords" [responsive]="true" [columns]="complianceReportColumns" [autoLayout]="true">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th style="width: 80px !important;">#</th>
						<th pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': showPrevent ? col.preventDisplay:col.OTdisplay  }">
							<ng-container>
								<span class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								</span>
							</ng-container>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-i="rowIndex">
					<tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
						<td id="date-column">
							{{page * size + i + 1}}
						</td>
						<td id="expected-column">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span *ngIf="rowData.date" pTooltip="{{rowData.date | date:'mediumDate'}}">{{rowData.date | date:'mediumDate'}}</span>
								<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td (click)="detailClick(rowData, 'onTimeIds', getDate(rowData.date) + ' Employee On Time')" class="link">
							<span class="one-liner">
								<span class="mobile-table-label">{{'OnTime' | translate}}: </span>
								<span *ngIf="rowData.onTimeStr" pTooltip="{{rowData.onTimeStr}}">{{rowData.onTimeStr}}</span>
								<span *ngIf="!rowData.onTimeStr" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td (click)="detailClick(rowData, 'lateIds', getDate(rowData.date) + ' Employee Late')" class="link">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Late' | translate}}: </span>
								<span *ngIf="rowData.lateCount" pTooltip="{{rowData.lateCount}}">{{rowData.lateCount}}</span>
								<span *ngIf="!rowData.lateCount" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td *ngIf="!isEmployee" (click)="detailClick(rowData, 'totalIds', getDate(rowData.date) + ' Employee Total')" class="link">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>
								<span *ngIf="rowData.totalEmployee"
									pTooltip="{{rowData.totalEmployee}}">{{rowData.totalEmployee}}</span>
								<span *ngIf="!rowData.totalEmployee" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td (click)="detailClick(rowData, 'missedclockIds', getDate(rowData.date) + ' Employee Miss Clock Count')" class="link">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Missed Clock' | translate}}: </span>
								<span *ngIf="rowData.missedclockCount" pTooltip="{{rowData.missedclockCount}}">{{rowData.missedclockCount}}</span>
								<span *ngIf="!rowData.missedclockCount" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td id="without-lunch-column" (click)="detailClick(rowData, 'withoutLunchIds', getDate(rowData.date) + ' Employee Without Lunch')" class="link">
							<span class="one-liner">
								<span class="mobile-table-label">{{'No Lunches' | translate}}: </span>
								<span *ngIf="rowData.withoutLunchCount" pTooltip="{{rowData.withoutLunchCount}}">{{rowData.withoutLunchCount}}</span>
								<span *ngIf="!rowData.withoutLunchCount" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Scheduled' | translate}}: </span>
								<span *ngIf="rowData.timeWorkDay && rowData.isHoliday" pTooltip="{{rowData.timeWorkDay}}">{{convertMinutesToHours(rowData.timeWorkDay)}}</span>
								<span *ngIf="!rowData.timeWorkDay && rowData.isHoliday" class="text-muted">{{'no data' | translate}}</span>
								<span *ngIf="!rowData.isHoliday" class="text-muted">{{'00:00' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total Worked' | translate}}: </span>
								<span *ngIf="rowData.regularTimeHHMM" pTooltip="{{rowData.regularTimeHHMM}}">{{rowData.regularTimeHHMM}}</span>
								<span *ngIf="!rowData.regularTimeHHMM" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
					</tr>
				</ng-template>


				<ng-template pTemplate="footer" let-columns>
					<ng-container>
						<tr id="table-data-row">
							<td class="text-right" colspan="2">
								<span class="desktop-table-label">{{'Totals' | translate}}: </span>
							</td>
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'On-Time' | translate}}: </span>{{complianceReport?.totalOntime}}
								</span>
							</td>
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'Late' | translate}}: </span>{{complianceReport?.totalLate}}
								</span>
							</td>
							<td *ngIf="!isEmployee">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Employees' | translate}}: </span>{{complianceReport?.totalEmployee}}
								</span>
							</td>
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'No Punch' | translate}}: </span>{{complianceReport?.totalMissed}}
								</span>
							</td>
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'Missed Lunch' | translate}}: </span>{{complianceReport?.totalEmployeeWithoutLunch}}
								</span>
							</td>
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'Scheduled Hours' | translate}}: </span>{{complianceReport?.totalWorkTimeHHMM}}
								</span>
							</td>
							<td>
								<span class="one-liner">
									<span class="mobile-table-label">{{'Hours Worked' | translate}}: </span>{{complianceReport?.totalWorkHourHHMM}}
								</span>
							</td>
						</tr>
					</ng-container>
				</ng-template>

				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="data?.length == 0 && !loading">
						<td [attr.colspan]="columns.length + 1" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="loading">
						<td [attr.colspan]="columns.length + 1" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>

			</p-table>
		</p-panel>
	</div>
    <div class="ui-g-6 ui-md-12 ui-sm-12">
		<p-panel [toggleable]="true" [collapsed]="true">
            <p-header class="flex-grow-1">
                <span><i class="fa-solid fa-chart-column text-primary mr-2"></i>{{'Plan/Assignment' | translate}}</span>
            </p-header>
            <div class="plan-assigment p-4 text-center">
               <p-chart type="horizontalBar" id="assignmentChart" [data]="dataPlanAssignment" [options]="horizontalOptions"></p-chart>
               <span class="text-center text-sm" style="font-style: italic; font-weight: 200;"> ({{'Chart reflects the last 14 days of data.' | translate}})</span>
            </div>
        </p-panel>
    </div>
</div>
<div class="ui-g">
    <div class="ui-g-12">
        <p-panel [toggleable]="true">
			<p-header class="flex-grow-1">
                <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Time-Off Accruals' | translate}} ({{absences?.length}})</span>
            </p-header>
			<p-table #table class="w-100" [value]="absences" [rows]="5" [paginator]="false" [totalRecords]="absences?.length" [lazy]="false" [responsive]="true">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of absenceCols" [pSortableColumn]="col?.field">
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchCase="'absenceType'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}">{{col.label | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'ptoAccrued'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}">{{col.label | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'toPeriod'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}">{{col.label | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'pendingHours'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}">{{col.label | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'availablePtoHours'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}">{{col.label | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'usedHours'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									</span>
								</span>
							</ng-container>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
					<tr [pSelectableRow]="rowData">
						<td *ngFor="let col of absenceCols">
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchCase="'absenceType'" >
									<span class="one-liner">
										<span class="mobile-table-label">{{'Type' | translate}}: </span>
										<span *ngIf="rowData.absenceType" pTooltip="{{rowData.absenceType}}">{{rowData.absenceType}}</span>
										<span *ngIf="!rowData.absenceType" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'ptoAccrued'">
									<span class="one-liner">
										<span class="mobile-table-label">{{'Accrued PTO' | translate}}: </span>
										<span *ngIf="rowData.ptoAccrued" pTooltip="{{(getHoursFormat(rowData.ptoAccrued))}}">{{(getHoursFormat(rowData.ptoAccrued))}}</span>
										<span *ngIf="!rowData.ptoAccrued" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'toPeriod'">
									<span class="one-liner">
										<span class="mobile-table-label">{{'Time-Off' | translate}}: </span>
										<span *ngIf="rowData.toPeriod" pTooltip="{{rowData.toPeriod | date: 'M/d/y'}}">{{rowData.toPeriod | date: 'M/d/y'}}</span>
										<span *ngIf="!rowData.toPeriod" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'pendingHours'">
									<span class="one-liner">
										<span class="mobile-table-label">{{'Accrued PTO' | translate}}: </span>
										<span *ngIf="rowData.pendingHours" [ngClass]="{'text-success': checkHoursPositive(rowData.usedHours, rowData.pendingHours)}" pTooltip="{{getHHMMFormat(rowData.pendingHours)}}">{{getHHMMFormat(rowData.pendingHours)}}</span>
										<span *ngIf="!rowData.pendingHours" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'availablePtoHours'" >
									<span class="one-liner">
										<span class="mobile-table-label">{{'Available PTO' | translate}}: </span>
										<span *ngIf="rowData.availablePtoHours" pTooltip="{{getHoursFormat(rowData.availablePtoHours)}}">{{getHoursFormat(rowData.availablePtoHours)}}</span>
										<span *ngIf="!rowData.availablePtoHours" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'usedHours'">
									<span class="one-liner">
										<span class="mobile-table-label">{{'Used PTO' | translate}}: </span>
										<span *ngIf="rowData.usedHours" pTooltip="{{getHHMMFormat(rowData.usedHours)}}">{{getHHMMFormat(rowData.usedHours)}}</span>
										<span *ngIf="!rowData.usedHours" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
							</ng-container>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="data?.length == 0 && !loading">
						<td [attr.colspan]="6" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="loading">
						<td [attr.colspan]="6" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
        </p-panel>
    </div>
</div>
<div class="ui-g">
    <div class="ui-g-4 ui-md-12 ui-sm-12">
		<p-panel [toggleable]="true">
			<p-header class="flex-grow-1">
                <span><i class="fa-solid fa-share-from-square text-primary mr-2"></i>{{'Sent Notebook Items' | translate}} ({{sharedNotes?.length}})</span>
            </p-header>
			<p-table #tableShare class="w-100" [value]="sharedNotes" [rows]="5" [paginator]="true" [totalRecords]="sharedNotes?.length" [lazy]="false">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of shareDocumentCols" [pSortableColumn]="col?.field">
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchCase="'name'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}">{{col.label | translate}}</span>
									</span>
								</span>
								<!-- <span *ngSwitchCase="'createdBy'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}">{{col.label | translate}}</span>
									</span>
								</span> -->
								<span *ngSwitchCase="'createdAt'" >
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									</span>
								</span>
							</ng-container>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
					<tr [pSelectableRow]="rowData">
						<td *ngFor="let col of shareDocumentCols">
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchCase="'name'" >
									<span class="one-liner">
										<span class="mobile-table-label">{{'Document' | translate}}: </span>
										<a href="javascript:void(0);" *ngIf="rowData.name" pTooltip="{{rowData.name}}" class="text-primary" (click)="clickViewDetailNote($event, rowData)">{{rowData.name}}</a>
										<span *ngIf="!rowData.name" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
								<!-- <span *ngSwitchCase="'createdBy'" >
									<span class="one-liner">
										<span class="mobile-table-label">{{'Send to' | translate}}: </span>
										<span *ngIf="rowData.userIds" pTooltip="{{rowData.email? rowData.email : getEmailByEmployeeId(rowData.userIds)}}">{{rowData.email? rowData.email :getEmailByEmployeeId(rowData.userIds)}}</span>
										<span *ngIf="!rowData.userIds" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span> -->
								<span *ngSwitchCase="'createdAt'" >
									<span class="one-liner">
										<span class="mobile-table-label">{{'Created' | translate}}: </span>
										<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt}}" tooltipPosition="left">{{rowData.createdAt}}</span>
										<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</span>
							</ng-container>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="sharedNotes?.length == 0 && !sharedLoading">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="sharedLoading">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
          
        </p-panel>
    </div>
    <div class="ui-g-6 ui-md-6 ui-sm-12">
        <p-panel [toggleable]="true">
			<p-header class="flex-grow-1">
                <span><i class="fa-solid fa-calendar-xmark text-primary mr-2"></i>{{'Time-Off Requests' | translate}} ({{absenceList?.length || 0}})</span>
            </p-header>
            <div class="ui-g">
                <p-table #table class="w-100" [value]="absenceList" [rows]="5" [paginator]="true" [totalRecords]="absenceList?.length" [lazy]="false">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of timeOffCols" [pSortableColumn]="col?.field">
								<span class="d-flex justify-content-between align-items-center w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								</span>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData" style="cursor: pointer" (click)="gotoRequestTimeOff()">
                            <td *ngFor="let col of timeOffCols; let i = index">
                                <span class="one-liner">
									<span class="mobile-table-label">{{this.col[i]?.label | translate}}: </span>
									<span *ngIf="rowData[col.field]" pTooltip="{{rowData[col.field]}}">{{rowData[col.field]}}</span>
									<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
								</span>
                            </td>
                        </tr>
                    </ng-template>
					<ng-template pTemplate="emptymessage" let-columns>
						<tr *ngIf="!absenceList && !requestsLoading">
							<td [attr.colspan]="3" class="text-center py-5">
								<div class="text-muted my-5">{{'no data' | translate}}</div>
							</td>
						</tr>
						<tr *ngIf="requestsLoading">
							<td [attr.colspan]="3" class="text-center py-5">
								<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
							</td>
						</tr>
					</ng-template>
                </p-table>
            </div>
        </p-panel>
    </div>
    
    <div class="ui-g-6 ui-sm-12">
        <p-panel [toggleable]="true" id="table-ppanel">
            <p-header class="flex-grow-1">
                <span><i class="fa-solid fa-bullhorn text-primary mr-2"></i>{{'Announcements' | translate}} ({{ holidays.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) }})</span>
            </p-header>
			<p-table *ngIf="holidays.length" [value]="holidays" [paginator]="false">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th colspan="1">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{'Holiday' | translate}}" tooltipPosition="left">{{'Holiday' | translate}}</span>
							</span>
						</th>
						<th colspan="1">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{'Date' | translate}}" tooltipPosition="left">{{'Date' | translate}}</span>
							</span>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
					<tr>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Holiday' | translate}}: </span>
								<span *ngIf="rowData.holidayName" pTooltip="{{rowData?.holidayName}}">{{rowData?.holidayName}}</span>
								<span *ngIf="!rowData.holidayName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span *ngIf="rowData.holidayDate" pTooltip="{{rowData.holidayDate | date : 'MMMM dd' | translate}}">{{rowData.holidayDate | date : 'MMMM dd' | translate}}</span>
								<span *ngIf="!rowData.holidayDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="(!holidays || isBeforeToday(rowData.holidayDate)) && !holidaysLoading">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="requestsLoading">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
            <div class="p-3" style="height: 184px;overflow-y: auto;overflow-x: hidden;">
				<div *ngFor="let ann of anniversaries; let index = index">
					<ng-container *ngIf="employeeId == ann.id; else anCoworker">
						<span><i class="fa-solid fa-thumbs-up text-info mr-1"></i>{{ 'CONGRATS_MESSAGE' | translate:{year: ann.year} }}</span>
					</ng-container>
					<ng-template #anCoworker>
						<span><i class="fa-solid fa-thumbs-up text-info mr-1"></i><span [innerHTML]="'CONGRATS_MESSAGE2' | translate:{name: ann.fullName, title: ann.title, year: ann.year}"></span></span>
					</ng-template>
				</div>
				<div *ngFor="let bd of birthdays; let idx = index">
					<ng-container *ngIf="employeeId == bd.id; else birthdayCoworker">
						<span><i class="fa-solid fa-birthday-cake text-purple mr-1"></i>{{'Happy Birthday To YOU!' | translate}}</span>
					</ng-container>
					<ng-template #birthdayCoworker>
						<span><i class="fa-solid fa-birthday-cake text-purple mr-1"></i>{{ 'BIRTHDAY_MESSAGE' | translate:{name: bd.fullName} }}</span>
					</ng-template>
				</div>
				<div *ngFor="let message of absencesAprroved; let indx = index">
					<span><i class="fa-solid fa-circle-check text-success mr-1"></i>{{message}}</span>
				</div>
				<div *ngIf="isDelayedArrival">
					<span><i class="fa-solid fa-triangle-exclamation text-warning mr-1"></i><span [innerHTML]="'DELAYED_ARRIVAL_MESSAGE' | translate:{name: fullName, time: delayedTimeStr, startWork: startWork}"></span></span>
				</div>
            </div>
        </p-panel>
    </div>
</div>


<p-dialog [(visible)]="showViewNoteDetailDialog" [modal]="true" [style]="{'width': '90%', 'max-height': '85vh', 'max-width':'600px'}">
	<p-header>
		<span><i class="fa-solid fa-image mr-2"></i>{{'Note Detail' | translate}}: {{noteDetail.name}}</span>
	</p-header>
    <div class="ui-g pb-3">
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Recipients' | translate}}</label>
			<div style="font-size: 16px">{{getEmailByEmployeeId(noteDetail.userIds)}}</div>

		</div>
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Content' | translate}}</label>
			<div [innerHTML]="noteDetail.content | safeHtml" class="ql-editor bg-light p-2" id="note_details" style="font-size: 16px !important"></div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showViewNoteDetailDialog = false">
			<i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}
		</button>
	</p-footer>
</p-dialog>
