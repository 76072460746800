<div class="p-0 m-0 w-100" [ngClass]="isPunchInOut ? 'card add-shadow' : ''" >
	<div class="card-header text-start text-left bg-light" style="font-size: 18px">
		<span>
			<i *ngIf="!personName && !stopped" class="fa-solid fa-video text-primary mr-2"></i><span class="text-dark" *ngIf="!personName  && !stopped">...</span>
			<span *ngIf="(!personName && stopped) || (personName && personName === 'Unrecognized' && stopped)" class="text-dark d-flex align-items-center" style="line-height:.7">
				<i class="fa-solid fa-circle-exclamation text-danger mr-2"></i>
				<div>
					<div><b>{{'Paused'}}</b></div>
					<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap text-muted" style="font-size: 10px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Paused', lang) }}</span>
				</div>
			</span>
			<i *ngIf="personName && personName !== 'Unrecognized'" class="fa-solid fa-user text-primary mr-2"></i>
			<span class="text-dark" *ngIf="personName && personName !== 'Unrecognized'"><b>{{getDetectionName()}}</b></span>
			<span class="text-dark d-flex align-items-center" *ngIf="personName && personName === 'Unrecognized' && !stopped" style="line-height: .7">
				<i *ngIf="personName && personName === 'Unrecognized'" class="fa-solid fa-circle-exclamation text-orange mr-2"></i>
				<div>
					<div><b>{{getDetectionName()}}</b></div>
					<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap text-muted" style="font-size: 10px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Unrecognized', lang) }}</span>
				</div>
			</span>
		</span>
	</div>
	<div class="card-body p-0">
		<ng-container *ngIf="stopped">
			<div class="text-secondary d-flex flex-column py-5 my-5"><i class="fa-solid fa-circle-pause mr-2 fa-2x"></i>
				<span>Timed out due to inactivity</span>
				<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap text-muted" style="font-size: 12px">{{ getTranslation('Timed out due to inactivity', lang) }}</span>
			</div>
		</ng-container>
		<ng-container *ngIf="!stopped">
			<ng-container *ngIf="paused">
				<i class="fa-solid fa-refresh cursor-pointer text-light" style="font-size: 56px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);text-shadow: -1px 7px 8px black;" (click)="onResumeVideo();"></i>
			</ng-container>
			<canvas class="origin-windows" #originCanvas></canvas>
			<canvas class="recognition-windows" #canvas></canvas>
			<video #video autoplay></video>
			<canvas [ngClass]="isPunchInOut ? 'face-windows' : 'face-windows-2'" #faceCanvas [ngStyle]="{'display': showFaceCanvas ? 'block' : 'none'}"></canvas>
		</ng-container>
	
		<div *ngIf="!modelLoaded" class="d-flex justify-content-center align-items-center h-100 w-100 pb-5 mb-5">
			<div class="text-muted m-auto pb-5 mb-5 d-flex flex-column"><i class="fa-solid fa-spinner text-primary mr-2 loadingHourglass"></i>{{'Loading...'}}</div>
		</div>
		<div *ngIf="personName && personName !== 'Unrecognized'" class="text-left text-dark" [ngClass]="(getMatchingRate() > 0) && takePhoto ? 'pl-3 py-4' : 'pl-3 pb-2'"  style="font-size: 16px">
			<h5 class="m-0 text-truncate"><b>{{getDetectionName()}}</b></h5>
			<div *ngIf="getMatchingRate() > 0"><b>{{'Certainty'}}: </b>{{getMatchingRate()}}</div>
			<div *ngIf="getMatchingRate() > 0" class="progress" style="height: 7px">
				<div class="progress-bar progress-bar-striped" role="progressbar" [style]="{'width': ((getMatchingRate() || '0%'))}" aria-valuenow="{{getMatchingRate() || '0'}}" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		</div>
	</div>
	<div *ngIf="stopped" class="card-footer">
		<button class="btn btn-lg w-100" (click)="startVideo()" [ngClass]="{
			'btn-danger': isPunchInOut && selectedColor === 'red',
			'btn-orange': isPunchInOut && selectedColor === 'orange',
			'btn-warning': isPunchInOut && selectedColor === 'yellow',
			'btn-success': isPunchInOut && selectedColor === 'green',
			'btn-info': isPunchInOut && selectedColor === 'teal',
			'btn-primary': isPunchInOut && selectedColor === 'blue',
			'btn-purple': isPunchInOut && selectedColor === 'purple',
			'btn-dark': isPunchInOut && selectedColor === 'dark',
			'btn-light' : !isPunchInOut
		 }">
		 <span class="d-flex align-items-center m-auto">
			<i class="fa-solid fa-play mr-3" [ngClass]="isPunchInOut ? 'text-warning' : 'text-primary'" style="font-size: 29px"></i>
			<div class="text-left text-start" style="line-height: 1.2">
				<span><b>{{ 'Re-Start' }}</b></span>
				<div class="d-flex justify-content-start" style="opacity: 0.6">
					<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 12px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Start', lang) }}</span>
				</div>
			</div>
		</span>
		
		</button>
	</div>
	<div class="card-footer" *ngIf="modelLoaded && takePhoto && !stopped && personName === 'Unrecognized'">
		<button class="btn btn-lg w-100"  [ngClass]="{
			'btn-danger': isPunchInOut && selectedColor === 'red',
			'btn-orange': isPunchInOut && selectedColor === 'orange',
			'btn-warning': isPunchInOut && selectedColor === 'yellow',
			'btn-success': isPunchInOut && selectedColor === 'green',
			'btn-info': isPunchInOut && selectedColor === 'teal',
			'btn-primary': isPunchInOut && selectedColor === 'blue',
			'btn-purple': isPunchInOut && selectedColor === 'purple',
			'btn-dark': isPunchInOut && selectedColor === 'dark',
			'btn-light' : !isPunchInOut
		 }" (click)="showAlertInSelectedLanguages()" [disabled]="!canvasPhotoDetect || disabledTakePhoto">
			<span class="d-flex align-items-center m-auto">
				<i class="fa-solid fa-camera mr-3" [ngClass]="isPunchInOut ? 'text-warning' : 'text-primary'" style="font-size: 29px"></i>
				<div class="text-left text-start" style="line-height: 1.2">
					<span><b>{{ 'Capture' }}</b></span>
					<div class="d-flex justify-content-start" style="opacity: 0.6">
						<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 12px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Capture', lang) }}</span>
					</div>
				</div>
			</span>
		</button>
	</div>
</div>