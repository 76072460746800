<div class="ui-g pb-4">
	<div class="ui-g-6 ui-sm-12">
		<label>{{'Field' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-mouse-pointer"></i>
				</button>
			</div>
			<p-dropdown [options]="fieldNames" [filter]="true" [showClear]="true" [(ngModel)]="selectedField" placeholder="{{'pleaseSelect' | translate}}">
				<ng-template pTemplate="item" let-item>
					<span class="text-truncate">{{item.label | translate}}</span>
				</ng-template>
				<ng-template let-item pTemplate="selectedItem">
					<span class="one-liner">{{item.label | translate}}</span>
				</ng-template>
			</p-dropdown>
		</div>
	</div>
	<div class="ui-g-6 ui-sm-12">
		<label>{{'Date' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-calendar-week"></i>
				</button>
			</div>
			<p-calendar [(ngModel)]="rangeDates"  [inputStyle]="{'width': '100%'}" selectionMode="range" readonlyInput="true" placeholder="{{'cols.date' | translate}}"></p-calendar>
			<div class="input-group-append pl-3">
				<button class="btn btn-white" (click)="reset()" pTooltip="{{'button.reset' | translate}}" tooltipPosition="left"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
				<button class="btn btn-white"  (click)="filterClientHistory()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
			</div>
		</div>
	</div>
</div>
<p-panel id="table-ppanel" [toggleable]="true" class="mb-5">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History:' | translate}} {{getHistoryFieldName(selectedField)}} <span *ngIf="payrollSettingHistory">({{payrollSettingHistory?.length}})</span></span>
	</p-header>
    <p-table #table [value]="payrollSettingHistory" [rows]="10" [paginator]="true" [totalRecords]="payrollSettingHistory?.length" (onLazyLoad)="loadDataSource($event)" [lazy]=false [responsive]="true">
		<ng-template pTemplate="header" let-columns>
			<tr  style="border:1px solid #e4e4e4 !important">
				<th *ngFor="let col of cols" [pSortableColumn]="col?.field" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''">
					<span class="d-flex align-items-center justify-content-between w-100">
						<span class="one-liner" pTooltip="{{ col?.label | translate}}" tooltipPlacement="left">{{ col?.label | translate}}</span>
						<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort"
									ariaLabelDesc="Activate to sort in descending order"
									ariaLabelAsc="Activate to sort in ascending order">
						</p-sortIcon>
					</span>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-rowData>
			<tr [pSelectableRow]="rowData">
				<td *ngFor="let col of cols">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'updatedDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData[col.field]" pTooltip="{{ rowData[col.field] | date : 'M/d/yyyy h:mm:ss a'}}">{{ rowData[col.field] | date : 'M/d/yyyy h:mm:ss a'}}</span>
								<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
							</span>

							</span>
						<span *ngSwitchCase="'userName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Username' | translate}}: </span>
								<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
								<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'oldValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Old Value' | translate}}: </span>
								<span *ngIf="rowData.oldValue" pTooltip="{{checkField(rowData.oldValue, rowData.fieldName)}}">{{checkField(rowData.oldValue, rowData.fieldName)}}</span>
								<span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'newValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'New Value' | translate}}: </span>
								<span *ngIf="rowData.newValue" pTooltip="{{checkField(rowData.newValue, rowData.fieldName)}}">{{checkField(rowData.newValue, rowData.fieldName)}}</span>
								<span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'action'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Action' | translate}}: </span>
								<span *ngIf="rowData[col.field] " pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
								<span *ngIf="!rowData[col.field] " class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<br>