import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {CommService} from '../../../employee/performance/service/comm.service';
import {TranslatePipe} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {PaymentInvoiceService} from '../../../payment/service/payment-invoice.service';
import {CompanyService} from '../../../company/service/company.service';
import * as _ from 'lodash';
import {Table} from 'primeng/table';
import { OverlayPanel } from 'primeng';
import { FileUtility } from 'app/shared/utility/file.utility';
import {MonitoringDetailsService} from '../../../admin/process-monitor/service/monitoring-details.service';
import {CurrencyPipe, DatePipe} from '@angular/common';

@Component({
    selector: 'app-transaction-discount-list',
    templateUrl: './transaction-discount-list.component.html',
    styleUrls: ['./transaction-discount-list.component.scss']
})
export class TransactionDiscountListComponent implements OnInit {

    @ViewChild('dt', {static: true}) table: Table;

    listData: any = [];
    allCompanyList: any = [];
    filterBody;
    isPlatformAdmin;
    selectedCompany;
    selectedType = null;
    fromDate;
    toDate;
    totalRecords = 0;
    loading = false;
    filterText = '';
    timespent = new Date();
    cols = [
        {field: 'invoiceNumber', label: 'Invoice #', sortOptions: '', sort: 'text', width: '20'},
        {field: 'date', label: 'Date', sortOptions: '', sort: 'number', width: '20%'},
        {field: 'addedBy', label: 'Creator', sortOptions: '', sort: 'text', width: '20%'},
        {field: 'type', label: 'Type', sortOptions: '', sort: 'text', width: '15%'},
        {field: 'amount', label: 'Amount', sortOptions: '', sort: 'number', width: '20%'}
    ];

    typeDropdown = [
        {
            value: null,
            label: 'All'
        },
        {
            value: '0',
            label: 'Fee Waivers'
        },
        {
            value: '1',
            label: 'Discounts'
        }
    ];

    @ViewChild('op3', { static: true }) op3: OverlayPanel;

    constructor(
        private messageService: MessageService,
        private authService: AuthService,
        private commService: CommService,
        private translatePipe: TranslatePipe,
        private router: Router,
        private paymentInvoiceService: PaymentInvoiceService,
        private companyService: CompanyService,
        private monitoringDetailsService: MonitoringDetailsService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes('app/transaction/discount')) {
                    this.monitoringDetailsService.monitorAction(
                        `Navigated to Discount & Fee Waived`,
                        this.timespent,
                        {
                            navigated_to_discount_fee_waived_by: this.authService.getCurrentLoggedInName()
                        },
                        'complete',
                        `Navigated to Discount & Fee Waived`,
                        0
                    );
                }
            }
        });
    }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.filterBody = {
            fromDate: '',
            toDate: '',
            page: 0,
            size: 20,
            companyId: '',
            invoiceType: '1',
            employeeUserName: null,
        };
        this.loadAllCompanies();
        this.search();
    }

    loadAllCompanies() {
        this.companyService.getCompaniesDropdown({}).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.allCompanyList = resObj.data;
                this.allCompanyList = _.sortBy(resObj.data, 'value');
            }
        });
    }

    reset() {
        this.filterText = null;
        this.selectedType = null;
        this.table.reset();
        this.filterBody = {
            fromDate: '',
            toDate: '',
            page: 0,
            size: 20,
            companyId: '',
            invoiceType: '',
            employeeUserName: null,
        };
        this.search();
    }
    searchData () {
        this.table.first = 0;
        this.monitoringDetailsService.monitorAction(
            `Searched Discount & Fee Waived`,
            this.timespent,
            {
                search_to_discount_fee_waived_by: this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Searched Discount & Fee Waived`,
            0
        );
        this.search();
    }

    search(event?: any) {
        this.listData = [];
        this.loading = true;
        this.filterBody = {
            fromDate: this.fromDate,
            toDate: this.toDate,
            size: event?.rows || 10,
            page: event && event.first ? (event.first / this.filterBody.size) : 0,
            companyId: this.isPlatformAdmin ? this.selectedCompany?.key || -1 : this.authService.getCurrentCompanyId(),
            invoiceType: this.selectedType,
            employeeUserName: null,
            sortOrder: 'DESC',
            searchText: this.filterText ? this.filterText : null
        };

        this.paymentInvoiceService.searchDiscountAndWaiveFee(this.filterBody).subscribe(res => {
            const resObj: any = res;
            this.loading = false;
            this.listData = (resObj.data.content || []);
            this.totalRecords = resObj.data.totalElements || 0;
        }, () => {
            this.loading = false;
            this.listData = [];
            this.totalRecords = 0;
        });
    }

    getDiscountType(discountType) {
        return this.typeDropdown.find(t => discountType === t.value)?.label;
    }
    
    invoiceRef: any = {};
    exportOptions = [
        { name: this.translatePipe.transform('View Details'), value: 'view' },
        { name: this.translatePipe.transform('Download Invoice'), value: 'download' },
    ];
    exportAs: any;
    clickInvoiceRef(event: any, rowData) {
        console.log('clickInvoiceRef: ', rowData)
        this.op3.toggle(event);
        this.invoiceRef = rowData;
    }

    invoiceRefAction(type: string) {
        switch (type) {
          case 'view': this.exportPdf(this.invoiceRef);
            break;
          case 'download': this.downloadInvoiceRefPdf();
            break;
        }
    }

    exportPdf(invoice) {
        this.op3.hide();
        if (invoice && invoice.pdfLink) {
            window.open(invoice.pdfLink, '_blank');
        } else {
            this.paymentInvoiceService.exportPdf(invoice.invoiceId).subscribe(res => {
                if (res.data) {
                    const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                    this.monitoringDetailsService.monitorAction(
                        `Exported Discount & Fee Waived`,
                        this.timespent,
                        {
                            exported_to_discount_fee_waived_by: this.authService.getCurrentLoggedInName()
                        },
                        'complete',
                        `Exported Discount & Fee Waived`,
                        0
                    );
                }
            });
        }
    }

    downloadInvoiceRefPdf() {
        this.op3.hide();
        this.paymentInvoiceService.exportPdfWithName(this.invoiceRef.invoiceId).subscribe(res => {
            if (res.data) {
                const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobUrl;
                a.target = '_blank';
                let fileName = 'Dummy.pdf';
                if (res.data.fileName && res.data.fileName.length > 0) {
                    fileName = res.data.fileName;
                }
                a.download = fileName;
                a.click();
                const args = {
                    ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
                    Ticket_id: this.invoiceRef.invoiceId,
                };
            }
        });
    }

    exportPdfDiscountAndWaiveFee() {
        this.loading = true;
        this.filterBody = {
            fromDate: this.fromDate,
            toDate: this.toDate,
            size: 9999,
            page: 0,
            companyId: this.isPlatformAdmin ? this.selectedCompany?.key || -1 : this.authService.getCurrentCompanyId(),
            invoiceType: this.selectedType,
            employeeUserName: null,
            sortOrder: 'DESC',
            searchText: this.filterText ? this.filterText : null
        };
        this.paymentInvoiceService.searchDiscountAndWaiveFee(this.filterBody).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    const transactions = resObj.data.content;
                    if (transactions && transactions.length) {
                        const data: any = {
                            contentList: transactions.map((value, idx) => {
                                return {
                                    index: idx + 1,
                                    date: this.datePipe.transform(value.date, 'MM/dd/yyyy HH:mm:ss a'),
                                    addedBy: value.addedBy,
                                    type: this.getDiscountType(value.discountType),
                                    amount: this.currencyPipe.transform(value.amount, 'USD', true, '1.2-2'),
                                    invoiceNumber: value.invoiceNumber
                                };
                            })
                        };
                        if (!this.isPlatformAdmin) {
                            data.companyId = this.authService.getCurrentCompany();
                        }
                        data.username = this.authService.getCurrentUsername();
                        data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
                        this.paymentInvoiceService.exportPdfDiscountAndWaiveFee(data).subscribe(res => {
                            if (res.data) {
                                const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                                const blobUrl = URL.createObjectURL(blob);
                                window.open(blobUrl, '_blank');
                            } else {
                                this.messageService.add({ severity: 'error',
                                    summary: this.translatePipe.transform('Problem exporting'),
                                    detail: this.translatePipe.transform('Problem exporting wallet transaction pdf list') });
                            }
                        });
                    }
                }
                this.loading = false;
            },
            () => (this.loading = false)
        );
    }
}
