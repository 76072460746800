import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { environment, environmentLocal } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Employee } from '../../model/employee.model';
import { Constants } from '../../../shared/model/constants';
import { EmployeeSearch } from '../../,,/../../shared/model/search/employee.search';
import { from, Observable } from 'rxjs';
import { Page } from 'app/shared/model/page.model';
import { map } from 'rxjs/operators';
import { ProtestHour } from 'app/employee/model/protest-hour.model';
import { PageResponse } from 'app/shared/model/response';
import { EmployeePayment } from 'app/employee/model/employee-payment/employee-payment.model';
import { ToDo } from 'app/todo/model/todo.model';
import { EmployeePunchResponse } from "../../../employee-clocking/model/v2/employee.clocking";

@Injectable({ providedIn: 'root' })
export class OperatorService extends AbstractServiceV2<Employee, EmployeeSearch> {

  notificationUrl;
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR, '');
    this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }

  getEmployeePayment(params: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + '/employee-payment/search', params).pipe(map(resp => resp));
  }

  saveAllPayment(entities: EmployeePayment[]): Observable<EmployeePayment[]> {
    return this._http.post<EmployeePayment[]>(this.baseUrlV2 + '/employee-payment/saved-all', entities).pipe(map(res => res));
  }

  getEmployeePaymentByEmployeeId(employeeId: number): Observable<any> {
    return this._http.get<any>(this.baseUrlV2 + '/employee-payment/employee/' + employeeId).pipe(map(resp => resp));
  }

  searchProtectHour(options: any): Observable<PageResponse<ProtestHour>> {
    return this._http.post<PageResponse<ProtestHour>>(`${this.baseUrlV2}/protest-hour/search`, options).pipe(map(res => res));
  }

  getProtectHour(params: any): Observable<PageResponse<ProtestHour>> {
    return this._http.get<PageResponse<ProtestHour>>(this.baseUrlV2 + '/protest-hour', { params: params }).pipe(map(resp => resp));
  }

  getProtectHourByEmployee(employeeId: any, params: any): Observable<PageResponse<ProtestHour>> {
    return this._http.get<PageResponse<ProtestHour>>(this.baseUrlV2 + `/protest-hour/employee/${employeeId}`, { params: params }).pipe(map(resp => resp));
  }

  createProtectHour(entity: ProtestHour): Observable<ProtestHour> {
    // return this._http.post<ProtestHour>('http://localhost:8007/api/v2/operation/protest-hour', entity).pipe(map(res => res));
    return this._http.post<ProtestHour>(this.baseUrlV2 + '/protest-hour', entity).pipe(map(res => res));
  }

  updateProtectHour(entity: ProtestHour, id: number): Observable<ProtestHour> {
    // return this._http.put<ProtestHour>('http://localhost:8007/api/v2/operation/protest-hour', entity).pipe(map(res => res));
    return this._http.put<ProtestHour>(this.baseUrlV2 + '/protest-hour', entity).pipe(map(res => res));
  }

  getProtestHourHistory(entity) {
    // return this._http.post(`http://localhost:8007/api/v2/operation/protest-hour/history-search`, entity).pipe(map(res => res));
    return this._http.post(`${this.baseUrlV2}protest-hour/history-search`, entity).pipe(map(res => res));
  }

  getDepartmentsDropdownByCompanyId(companyId: any) {
    return this._http.get<ProtestHour>(this.baseUrlV2 + `/department/dropdown?companyId=${companyId}`).pipe(map(res => res));
  }

  searchTodo(options: any, getSimpleObject: boolean = false): Observable<Page<ToDo>> {
    const baseUrl = this.baseUrlV2;
    if (getSimpleObject) {
      return this._http.post<Page<ToDo>>(`${baseUrl}/todo/search/get-simple-todo  `, options).pipe(map(res => res));
    }
    return this._http.post<Page<ToDo>>(`${baseUrl}/todo/search`, options).pipe(map(res => res));
  }

  getToDoById(todoId: any) {
    return this._http.get<ProtestHour>(this.baseUrlV2 + `/todo/${todoId}`).pipe(map(res => res));
  }

  getGlobalDashboard(payload) {
      return this._http.post<ProtestHour>(this.baseUrlV2 + `/todo/search/dashboard`, payload).pipe(map(res => res));
  }

  createTodo(options: any): Observable<Page<ToDo>> {
    // return this._http.post<Page<ToDo>>(`http://localhost:8007/api/v2/operation/todo`, options).pipe(map(res => res));
    return this._http.post<Page<ToDo>>(`${this.baseUrlV2}/todo`, options).pipe(map(res => res));
  }

  updateTodo(options: any): Observable<Page<ToDo>> {
    return this._http.put<Page<ToDo>>(`${this.baseUrlV2}/todo`, options).pipe(map(res => res));
  }

  deleteTodo(id: Number) {
    return this._http.delete(`${this.baseUrlV2}/todo/${id}`).pipe(map(data => data));
  }

  deleteProtestHour(id) {
    return this._http.delete(`${this.baseUrlV2}/protest-hour/${id}`).pipe(map(data => data));
  }

  setStatusProtestHour(data) {
    return this._http.put<Page<ToDo>>(`${this.baseUrlV2}/protest-hour/set-status`, data).pipe(map(res => res));
  }

  updateTimeCorrection(options: any): Observable<EmployeePunchResponse> {
    const reqUrl = `${this.baseUrlV2}/time-correction`;
    return this._http.put<any>(reqUrl, options).pipe(map(resp => resp));
  }

  exportPaymentRecord(option: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE_PAYMENT.EXPORT_PAYMENT_RECORD, option).pipe(map(res => res));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + Constants.URI.EMPLOYEE_PAYMENT.EXPORT_PDF, data).pipe(map(resp => resp));
  }

  updateSupportTeamApproval(options: any) {
    return this._http.get(`${this.baseUrlV2}/todo/support-status`, { params: options }).pipe(map(res => res));
  }

  getTodoPlanNotification(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}/bell-notification/dropdown`, data).pipe(map(resp => resp));
  }

  deleteTodoNotification(options: any) {
    return this._http.post(`${this.baseUrlV2}/bell-notification/delete`, options).pipe(map(data => data));
  }

  deleteMentionedNotification(id: number) {
    return this._http.delete(`${this.baseUrlV2}/bell-notification/${id}`);
  }

  addBellNotification(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}/bell-notification`, data).pipe(map(resp => resp));
  }

  updateStatusMentionedNotification(id: number, status: number) {
    return this._http.delete(`${this.baseUrlV2}/bell-notification/${id}/status/${status}`);
  }

  sendEmailNotification(payload) {
    return this._http.post<any>(`${this.notificationUrl}/protest-hour-approve-reject-email`, payload).pipe(map(resp => resp));
  }

  exportPayStubStub(data?: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + '/employee-payment/exportPayStubPdf', data).pipe(map(resp => resp));
  }

  exportPayStubStubZip(payload: any, firebaseAddr: string): Observable<ArrayBuffer> {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/employee-payment/exportPayStubPdfZip?firebaseAddr=${firebaseAddr}`, payload, 
    // { responseType: 'arraybuffer' as 'json'});
    return this._http.post<any>(`${this.baseUrlV2}/employee-payment/exportPayStubPdfZip?firebaseAddr=${firebaseAddr}`, payload, { responseType: 'arraybuffer' as 'json'});
  }

  generateReport(id: number, reportDate?): Observable<any> {
    return this._http.post(`${this.baseUrlV2}/protest-hour/pdf/${id}`, {reportDate}).pipe(map(resp => resp));
  }

  getOptionsByCompany(options?: any): Observable<any> {
    const params = this.createParams(options);
    return this._http.get(this.baseUrlV2 + '/todo/get-options-by-company', { params: params }).pipe(map(res => res));
  }

    exportProtestPdf(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}/protest-hour/exportPdf`, data).pipe(map(resp => resp));
    }

    createMultipleTodo(options: any): Observable<Page<ToDo>> {
        // return this._http.post<Page<ToDo>>(`http://localhost:8007/api/v2/operation/todo`, options).pipe(map(res => res));
        return this._http.post<Page<ToDo>>(`${this.baseUrlV2}/todo/multiple`, options).pipe(map(res => res));
    }

    printPdf(data): Observable<any> {
        return this._http.post(this.baseUrlV2 + `/todo/printPdf`, data).pipe(map(res => res));
    }
}
