import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    ViewChildren,
    ViewChild,
    QueryList,
    ElementRef
} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DocumentSettings} from '../../model/document-settings';
import {Router} from '@angular/router';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {Message, MessageService} from 'primeng/api';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {DocumentSettingsService} from '../../service/document-settings.service';
import {OverlayPanel} from "primeng";
import {DocumentsServicev2} from "../../../shared/service/documents-upload/document.v2.service";
import {DocumentsService} from "../../../shared/service/documents-upload/documents.service";
import * as _ from 'lodash';
import { Editor } from 'primeng';


@Component({
  selector: 'app-document-settings-form',
  templateUrl: './document-settings-form.component.html',
  styleUrls: ['./document-settings-form.component.scss'],
  providers: [DocumentSettingsService]
})
export class DocumentSettingsFormComponent implements OnInit, OnChanges {
  @Input() documentSettings: DocumentSettings[] = [];
  @Input() documentSettingsId: number;
  // @Input() documentSetting: DocumentSettings = new DocumentSettings();
  @Output() onDocumentSettingsHide: EventEmitter<any> = new EventEmitter<boolean>();
  showAddDocumentPopup: boolean = false;
  addDocumentForm: UntypedFormGroup;
  documentSetting: DocumentSettings = new DocumentSettings();
  documentRoles: any = [];
  @Input() companyId: number;
  charactersLength = 0;
  operationalPolicies = [
    { label: 'Office', value: 'Office' },
    { label: 'Restaurant/Bar', value: 'Restaurant/Bar' },
  ];
  msgs: Message[] = [];
  companies = [];
  historyShow;
  editor: any;
  @ViewChild('detailsEditor', { static: true })
  detailsEditor: Editor;
  @ViewChildren('upload') uploadFileRef: QueryList<ElementRef>;
  constructor(private router: Router,
              private authService: AuthService,
              private messageService: MessageService,
              private translatePipe: TranslatePipe,
              private formBuilder: UntypedFormBuilder,
              private dropDownsService: DropDownsService,
              private documentServicev2: DocumentsServicev2,
              private documentsService: DocumentsService,
              private documentSettingsService: DocumentSettingsService) {
  }

  ngOnInit(): void {
    this.documentRoles = [
      {label: 'Employee', value: 'employee'},
      {label: 'Intern (Paid)', value: 'inter_paid'},
      {label: 'Intern (Not Paid)', value: 'inter_not_paid'},
      {label: 'Volunteer', value: 'volunteer'},
      {label: 'Contractor', value: 'contractor'},
      {label: 'Public', value: 'public'},
    ];


    this.addDocumentForm = this.formBuilder.group({
      role: new UntypedFormControl('', [Validators.required]),
      documentName: new UntypedFormControl('', [Validators.required]),
      required: new UntypedFormControl(''),
      addExpDate: new UntypedFormControl(''),
      operationalPolicy: new UntypedFormControl('', [Validators.required]),
      information: new UntypedFormControl(''),
      confirmSetting: new UntypedFormControl(''),
    });
  }

  loadAllCompanyList() {
      this.dropDownsService.getAllCompanyList().subscribe((res) => {
          const resObj: any = res;
          this.companies = [];
          if (resObj.status === 'SUCCESS') {
              resObj.data.forEach(data => {
                  this.companies.push({ value: data.key, label: data.value });
              });
              console.log(this.companies);
          }
      });
  }

  ngOnChanges() {
    if (this.documentSettingsId) {
      const documentSettingFilter = this.documentSettings.filter(documentSetting => documentSetting.id === this.documentSettingsId);
      if (documentSettingFilter.length > 0) {
        this.documentSetting = _.cloneDeep(documentSettingFilter[0]);
        this.documentSetting.isRequiredEdit = this.documentSetting.isRequired;
        this.documentSetting.selectedRoleEdit = this.documentSetting.selectedRole;
        this.documentSetting.documentNameEdit = this.documentSetting.documentName;
        this.charactersLength = (this.documentSetting.documentName||'').length;
        if (this.documentSetting.documentIds == null || this.documentSetting.documentIds.length == 0) {
              this.documentSetting.documentIds = [];
            } else {
              if (this.documentSetting.documentIds.length > 0) {
                const options = {
                  ids: this.documentSetting.documentIds.map(Number)
                };
                this.getAllFiles(options);
                this.documentSetting.documentIds = [];
              }
            }
      }
    } else {
      this.documentSetting = new DocumentSettings();
      this.documentSetting.roleEdit = 'employee';
      this.documentSetting.isRequiredEdit = false;
      this.documentSetting.selectedRoleEdit = [{label: 'Employee', value: 'employee'}];
    }
  }

  closeAddDocumentPopup() {
    this.onDocumentSettingsHide.emit(false);
  }

  saveDocument() {
    this.documentSettingsService.showSpin();
    let update = false;
    if (this.documentSetting.id) {
      update = true;
    }
    this.documentSetting.companyId = this.companyId;
    this.documentSetting.isVisible = this.documentSetting.isRequiredEdit;
    this.documentSetting.isRequired = this.documentSetting.isRequiredEdit;
    this.documentSetting.documentName = (this.documentSetting.documentNameEdit || '').trim();
    this.documentSetting.createdBy = this.authService.getCurrentUsername();
    let documentIds = [];
    this.fileUploaded.forEach(file => {
      documentIds.push("" + file.id);
    });
    if (documentIds.length) {
        this.documentSetting.documentIds = documentIds;
    } else {
        this.documentSetting.documentIds = null;
    }
    this.documentSetting.role = this.documentSetting.selectedRoleEdit.map(r => r.value).join(',');
    this.documentSetting.documentNameEdit = (this.documentSetting.documentNameEdit || '').trim();
    if (this.documentSetting.information) {
        let div = document.createElement("div");
        div.innerHTML = this.documentSetting.information;
        let text = (div.textContent || div.innerText || "").trim();
        if (!text || text == '') this.documentSetting.information = null;
    }
    if (this.addDocumentForm.invalid) {
        this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('InvalidData'),
            detail: this.translatePipe.transform('PleaseEnterRequiredFields')
        });
        return;
    }

    if (!this.documentSetting.documentName || this.documentSetting.documentName == '') {
      this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('InvalidData'),
          detail: this.translatePipe.transform('Document name should not be empty')
      });
      return;
    }
    this.documentSettingsService.create(this.documentSetting).subscribe(res => {
      const resObj: any = res;
      this.documentSettingsService.hideSpin();
      if (resObj.status === 'SUCCESS') {
        this.onDocumentSettingsHide.emit(true);
        if (!update) {
          this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Created'),
            detail: this.translatePipe.transform('createNewDocumentSuccess')
          });
        } else {
          this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Updated'),
            detail: this.translatePipe.transform('updateNewDocumentSuccess')
          });
        }

      } else {
        if (!update) {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('error'),
            detail: resObj.message? resObj.message: this.translatePipe.transform('createNewDocumentFailed')
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('error'),
            detail: resObj.message? resObj.message: this.translatePipe.transform('updateNewDocumentFailed')
          });
        }
      }
    });
  }
  onchangeText() {
    this.charactersLength = (this.documentSetting.documentNameEdit||'').length;
  }
    actionFile;
    fileUrl;
    fileName;
    showViewFileDialog;
//     uploadFile(event) {
//         this.actionFile = 'Updated';
//         if (event.target.files.length === 0) {
//             return;
//         }
//         const mimeType = event.target.files[0].type;
//         if (mimeType.match(/image\/*/) == null
//             && mimeType.match(/pdf\/*/) == null
//             && mimeType.match(/docx\/*/) == null
//             && mimeType.match(/doc\/*/) == null
//             && mimeType.match(/xlsx\/*/) == null
//             && mimeType.match(/xls\/*/) == null
//             && mimeType !== 'text/plain'
//         ) {
//             return;
//         }
//         const reader = new FileReader();
//         reader.readAsDataURL(event.target.files[0]);
//         this.documentServicev2.uploadFile(event.target.files[0], 'to_do_file', this.authService.getCurrentCompanyId(), 'Company')
//             .subscribe(res => {
//                 const resObj: any = res;
//                 this.documentSetting.material = resObj.data.id;
//                 this.fileUrl = resObj.data.fileUrl;
//                 this.fileName = resObj.data.fileName;
//                 if (this.fileName) {
//                     this.fileName = this.fileName.substring(this.fileName.lastIndexOf('__') + 2, this.fileName.length);
//                 }
//                 this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('SuccessfullyUploaded') });
//             });
//     }
//
//     onUploadDocument(uploadFilePanel: OverlayPanel) {
//         this.uploadFileRef.toArray()[0].nativeElement.click();
//         uploadFilePanel.hide();
//     }
//
//     onViewDocument(uploadFilePanel: OverlayPanel) {
//         this.documentsService.getUploadedFile(this.documentSetting.material).subscribe(res => {
//             const resObj: any = res;
//             if (resObj.status === 'SUCCESS') {
//                 if (resObj.data.fileType === 'pdf'
//                     || resObj.data.fileType === 'txt'
//                     || resObj.data.fileType === 'docx'
//                     || resObj.data.fileType === 'doc'
//                     || resObj.data.fileType === 'xls'
//                     || resObj.data.fileType === 'xlsx'
//                 ) {
//                     window.open(resObj.data.fileUrl);
//                 } else {
//                     this.fileUrl = resObj.data.fileUrl;
//                     this.fileName = resObj.data.fileName;
//                     if (this.fileName) {
//                         this.fileName = this.fileName.substring(this.fileName.lastIndexOf('__') + 2, this.fileName.length);
//                     }
//                     this.showViewFileDialog = true;
//                 }
//                 uploadFilePanel.hide();
//             }
//         }, error => {
//             // error
//         });
//     }

    onChangeDocument(uploadFilePanel: OverlayPanel) {
        this.actionFile = 'Updated';
        this.uploadFileRef.toArray()[0].nativeElement.click();
        uploadFilePanel.hide();
    }


    onRemoveDocument(uploadFilePanel: OverlayPanel) {
        this.actionFile = 'Remove';
        if (this.documentSetting.material) {
            this.documentSetting.material = null;
            this.fileName = null;
            this.fileUrl = null;
            uploadFilePanel.hide();
        }
    }

    setEditor($event: any, type?) {
        this.editor = $event.editor;
    }

     documents = [];
     showFileUpload = false;
     processing = false;
     files = [];
     fileUploaded = [];
     percentage = 0;
     onUploadDocument(uploadFilePanel: OverlayPanel) {
        this.uploadFileRef.toArray()[0].nativeElement.click();
        uploadFilePanel.hide();
      }

      getAllFiles(options: any) {
        this.documentServicev2.searchFile(options)
          .subscribe(res => {
            const resObj: any = res;
            this.documents = resObj.data.content;
            this.showFileUpload = this.documents.length > 0;
            for (let i = 0; i < this.documents.length; i++) {
              this.fileUploaded.push(this.documents[i]);
            }
            this.processing = false;
          });
      }

      uploadFile(event) {
        this.percentage = 0;
        let filesToUpload = [];
        if (event.target.files.length === 0) {
          return;
        }
        this.messageService.add({
          severity: 'info',
          summary: this.translatePipe.transform('Uploading'),
          detail: this.translatePipe.transform('Please wait for uploading...')
        });
        filesToUpload = event.target.files;
        for (let i = 0; i < filesToUpload.length; i++) {
          this.files.push(filesToUpload[i]);
          const reader = new FileReader();
          reader.readAsDataURL(filesToUpload[i]);
          this.uploadMultipleFiles(filesToUpload[i]);
        }
        this.showFileUpload = true;
      }

      onViewDocument(file) {
        if (file && file.fileUrl) {
          if (file.fileUrl.match(/\.(xlsx)$/) != null) {
            window.location.href = file.fileUrl;
          } else {
            window.open(file.fileUrl);
          }
        }
      }

      uploadMultipleFiles(file) {
        this.processing = true;
        this.documentServicev2.uploadFile(file, 'document_setting_file',
          this.authService.getCurrentCompanyId(), 'Company')
          .subscribe(res => {
            const resObj: any = res;
            this.processing = false;
            if (resObj.status === 'SUCCESS') {
              const options = {
                ids: [resObj.data.id]
              };
              this.getAllFiles(options);
            }
          });
      }

      removeFile(id) {
        this.fileUploaded = this.fileUploaded.filter(file => file.id !== id);
        this.showFileUpload = this.fileUploaded.length > 0;
      }

    getFileName(url) {
        return url.split('/').pop().split('__').pop();
    }

}
